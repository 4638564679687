

h1 {
  font-size: 55px;
  margin: 0;
  line-height: normal;
}

h2 {
  font-size: 40px;
  margin: 0;
  line-height: normal;
}

h3 {
  font-size: 35px;
  margin: 0;
  line-height: normal;
}

h4 {
  font-size: 30px;
  margin: 0;
  line-height: normal;
}

h5 {
  font-size: 25px;
  margin: 0;
  line-height: normal;
}

h6 {
  font-size: 18px;
  margin: 0;
  line-height: normal;
}

button:focus {
  text-decoration: none;
  outline: none;
  box-shadow: none;
  box-shadow: none !important;
}

button:hover {
  text-decoration: none;
  outline: none;
  box-shadow: none;
  box-shadow: none !important;
}

a:focus {
  text-decoration: none;
  outline: none;
  box-shadow: none;
}

a:hover {
  text-decoration: none;
  outline: none;
  box-shadow: none;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-10 {
  margin-top: 10px !important;
}

.mt-15 {
  margin-top: 15px !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.mt-25 {
  margin-top: 25px !important;
}

.mt-30 {
  margin-top: 30px !important;
}

.mt-35 {
  margin-top: 35px !important;
}

.mt-40 {
  margin-top: 40px !important;
}

.mt-45 {
  margin-top: 45px !important;
}

.mt-50 {
  margin-top: 50px !important;
}

.mt-55 {
  margin-top: 55px !important;
}

.mt-60 {
  margin-top: 60px !important;
}

.mt-62 {
  margin-top: 62px !important;
}

.mt-65 {
  margin-top: 65px !important;
}

.mt-70 {
  margin-top: 70px !important;
}

.mt-75 {
  margin-top: 75px !important;
}

.mt-80 {
  margin-top: 80px !important;
}

.mt-85 {
  margin-top: 85px !important;
}

.mt-90 {
  margin-top: 90px !important;
}

.mt-95 {
  margin-top: 95px !important;
}

.mt-100 {
  margin-top: 100px !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.mb-15 {
  margin-bottom: 15px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.mb-25 {
  margin-bottom: 25px !important;
}

.mb-30 {
  margin-bottom: 30px !important;
}

.mb-35 {
  margin-bottom: 35px !important;
}

.mb-40 {
  margin-bottom: 40px !important;
}

.mb-45 {
  margin-bottom: 45px !important;
}

.mb-50 {
  margin-bottom: 50px !important;
}

.mb-55 {
  margin-bottom: 55px !important;
}

.mb-60 {
  margin-bottom: 60px !important;
}

.mb-62 {
  margin-bottom: 62px !important;
}

.mb-65 {
  margin-bottom: 65px !important;
}

.mb-70 {
  margin-bottom: 70px !important;
}

.mb-75 {
  margin-bottom: 75px !important;
}

.mb-80 {
  margin-bottom: 80px !important;
}

.mb-85 {
  margin-bottom: 85px !important;
}

.mb-90 {
  margin-bottom: 90px !important;
}

.mb-95 {
  margin-bottom: 95px !important;
}

.mb-100 {
  margin-bottom: 100px !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-10 {
  padding-top: 10px !important;
}

.pt-15 {
  padding-top: 15px !important;
}

.pt-20 {
  padding-top: 20px !important;
}

.pt-25 {
  padding-top: 25px !important;
}

.pt-30 {
  padding-top: 30px !important;
}

.pt-35 {
  padding-top: 35px !important;
}

.pt-40 {
  padding-top: 40px !important;
}

.pt-45 {
  padding-top: 45px !important;
}

.pt-50 {
  padding-top: 50px !important;
}

.pt-55 {
  padding-top: 55px !important;
}

.pt-60 {
  padding-top: 60px !important;
}

.pt-65 {
  padding-top: 65px !important;
}

.pt-70 {
  padding-top: 70px !important;
}

.pt-75 {
  padding-top: 75px !important;
}

.pt-80 {
  padding-top: 80px !important;
}

.pt-85 {
  padding-top: 85px !important;
}

.pt-90 {
  padding-top: 90px !important;
}

.pt-95 {
  padding-top: 95px !important;
}

.pt-100 {
  padding-top: 100px !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-10 {
  padding-bottom: 10px !important;
}

.pb-15 {
  padding-bottom: 15px !important;
}

.pb-20 {
  padding-bottom: 20px !important;
}

.pb-25 {
  padding-bottom: 25px !important;
}

.pb-30 {
  padding-bottom: 30px !important;
}

.pb-35 {
  padding-bottom: 35px !important;
}

.pb-40 {
  padding-bottom: 40px !important;
}

.pb-45 {
  padding-bottom: 45px !important;
}

.pb-50 {
  padding-bottom: 50px !important;
}

.pb-55 {
  padding-bottom: 55px !important;
}

.pb-60 {
  padding-bottom: 60px !important;
}

.pb-65 {
  padding-bottom: 65px !important;
}

.pb-70 {
  padding-bottom: 70px !important;
}

.pb-75 {
  padding-bottom: 75px !important;
}

.pb-80 {
  padding-bottom: 80px !important;
}

.pb-85 {
  padding-bottom: 85px !important;
}

.pb-90 {
  padding-bottom: 90px !important;
}

.pb-95 {
  padding-bottom: 95px !important;
}

.pb-100 {
  padding-bottom: 100px !important;
}

.no-padding {
  padding: 0 !important;
}

@media screen and (min-width: 1200px) {
  .mt-xl-0 {
    margin-top: 0 !important;
  }
  .mb-xl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xl-0 {
    margin-left: 0 !important;
  }
  .mr-xl-0 {
    margin-right: 0 !important;
  }
  .pt-xl-0 {
    padding-top: 0 !important;
  }
  .pb-xl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xl-0 {
    padding-left: 0 !important;
  }
  .pr-xl-0 {
    padding-right: 0 !important;
  }
}

@media screen and (min-width: 992px) {
  .mt-lg-0 {
    margin-top: 0 !important;
  }
  .mb-lg-0 {
    margin-bottom: 0 !important;
  }
  .ml-lg-0 {
    margin-left: 0 !important;
  }
  .mr-lg-0 {
    margin-right: 0 !important;
  }
  .pt-lg-0 {
    padding-top: 0 !important;
  }
  .pb-lg-0 {
    padding-bottom: 0 !important;
  }
  .pl-lg-0 {
    padding-left: 0 !important;
  }
  .pr-lg-0 {
    padding-right: 0 !important;
  }
}

@media screen and (min-width: 768px) {
  .mt-md-0 {
    margin-top: 0 !important;
  }
  .mb-md-0 {
    margin-bottom: 0 !important;
  }
  .ml-md-0 {
    margin-left: 0 !important;
  }
  .mr-md-0 {
    margin-right: 0 !important;
  }
  .pt-md-0 {
    padding-top: 0 !important;
  }
  .pb-md-0 {
    padding-bottom: 0 !important;
  }
  .pl-md-0 {
    padding-left: 0 !important;
  }
  .pr-md-0 {
    padding-right: 0 !important;
  }
}

@media screen and (min-width: 576px) {
  .mt-sm-0 {
    margin-top: 0 !important;
  }
  .mb-sm-0 {
    margin-bottom: 0 !important;
  }
  .ml-sm-0 {
    margin-left: 0 !important;
  }
  .mr-sm-0 {
    margin-right: 0 !important;
  }
  .pt-sm-0 {
    padding-top: 0 !important;
  }
  .pb-sm-0 {
    padding-bottom: 0 !important;
  }
  .pl-sm-0 {
    padding-left: 0 !important;
  }
  .pr-sm-0 {
    padding-right: 0 !important;
  }
}


.col-xxl,
.col-xxl-10,
.col-xxl-11,
.col-xxl-12,
.col-xxl-2,
.col-xxl-3,
.col-xxl-4,
.col-xxl-5,
.col-xxl-6,
.col-xxl-7,
.col-xxl-8,
.col-xxl-9,
.col-xxl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

@media (min-width: 1600px) {
  .col-xxl {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-xxl-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-xxl-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-xxl-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-xxl-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xxl-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-xxl-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-xxl-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xxl-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-xxl-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-xxl-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xxl-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-xxl-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-xxl-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    -ms-flex-order: -1;
    order: -1;
  }
  .order-xl-last {
    -ms-flex-order: 13;
    order: 13;
  }
  .order-xl-0 {
    -ms-flex-order: 0;
    order: 0;
  }
  .order-xl-1 {
    -ms-flex-order: 1;
    order: 1;
  }
  .order-xl-2 {
    -ms-flex-order: 2;
    order: 2;
  }
  .order-xl-3 {
    -ms-flex-order: 3;
    order: 3;
  }
  .order-xl-4 {
    -ms-flex-order: 4;
    order: 4;
  }
  .order-xl-5 {
    -ms-flex-order: 5;
    order: 5;
  }
  .order-xl-6 {
    -ms-flex-order: 6;
    order: 6;
  }
  .order-xl-7 {
    -ms-flex-order: 7;
    order: 7;
  }
  .order-xl-8 {
    -ms-flex-order: 8;
    order: 8;
  }
  .order-xl-9 {
    -ms-flex-order: 9;
    order: 9;
  }
  .order-xl-10 {
    -ms-flex-order: 10;
    order: 10;
  }
  .order-xl-11 {
    -ms-flex-order: 11;
    order: 11;
  }
  .order-xl-12 {
    -ms-flex-order: 12;
    order: 12;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.333333%;
  }
  .offset-xl-2 {
    margin-left: 16.666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.333333%;
  }
  .offset-xl-5 {
    margin-left: 41.666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.333333%;
  }
  .offset-xl-8 {
    margin-left: 66.666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.333333%;
  }
  .offset-xl-11 {
    margin-left: 91.666667%;
  }
  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }
  .d-xxl-block {
    display: block !important;
  }
}

.section-pb {
  padding-bottom: 60px !important;
}
.section-pt {
  padding-top: 60px !important;
}
.section-ptb {
  padding-top: 60px !important;
  padding-bottom: 60px !important;
}

@media (max-width: 991px) {
  .section-pb {
    padding-bottom: 40px !important;
  }
  .section-ptb {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
  }
}

@media screen and (max-width: 1650px) {
  .max-container {
  	max-width: 1400px !important;
  }
}
@media screen and (max-width: 1920px) {
  .max-container {
    max-width: 1575px;
  }
}
.lh-normal {
  line-height: normal;
}

.font-10 {
  font-size: 10px !important;
}

.font-12 {
  font-size: 12px !important;
}
.font-13 {
	font-size: 13px;
}
.font-14 {
  font-size: 14px !important;
}
.font-15{
  font-size:15px;
}
.font-16 {
  font-size: 16px !important;
}

.font-18 {
  font-size: 18px !important;
}

.font-20 {
  font-size: 20px !important;
}

.font-22 {
  font-size: 22px !important;
}

.font-24 {
  font-size: 24px !important;
}

.font-26 {
  font-size: 26px !important;
}

.font-28 {
  font-size: 28px !important;
}

.font-30 {
  font-size: 30px !important;
}

.font-32 {
  font-size: 32px !important;
}

.font-34 {
  font-size: 34px !important;
}

.font-36 {
  font-size: 36px !important;
}

.font-38 {
  font-size: 38px !important;
}

.font-40 {
  font-size: 40px !important;
}

.font-42 {
  font-size: 42px !important;
}

.font-44 {
  font-size: 44px !important;
}

.font-46 {
  font-size: 46px !important;
}

.font-48 {
  font-size: 48px !important;
}

.font_300 {
  font-weight: 300 !important;
}

.font_500 {
  font-weight: 500 !important;
}

.font_600 {
  font-weight: 600 !important;
}

.font_700 {
  font-weight: 700 !important;
}

@media (max-width: 767px) {
  .font-10 {
    font-size: 8px !important;
  }

  .font-12 {
    font-size: 10px !important;
  }

  .font-14 {
    font-size: 13px !important;
  }

  .font-16 {
    font-size: 14px !important;
  }

  .font-18 {
    font-size: 15px !important;
  }

  .font-20 {
    font-size: 17px !important;
  }

  .font-22 {
    font-size: 18px !important;
  }

  .font-24 {
    font-size: 20px !important;
  }

  .font-26 {
    font-size: 22px !important;
  }

  .font-28 {
    font-size: 24px !important;
  }

  .font-30 {
    font-size: 26px !important;
  }

  .font-32 {
    font-size: 28px !important;
  }

  .font-34 {
    font-size: 30px !important;
  }

  .font-36 {
    font-size: 30px !important;
  }

  .font-38 {
    font-size: 32px !important;
  }

  .font-40 {
    font-size: 34px !important;
  }

  .font-42 {
    font-size: 36px !important;
  }

  .font-44 {
    font-size: 36px !important;
  }

  .font-46 {
    font-size: 38px !important;
  }

  .font-48 {
    font-size: 40px !important;
  }
}

#dropdownMenuButton::after {
	content: "\f078";
	font-family: "Font Awesome 5 Pro";
	border: none;
}
.custom-checkbox .custom-control-label, .custom-radio .custom-control-label {
	width: 100%;
	cursor: pointer;
	color: #615d5d;
	font-size: 16px;
	font-weight: 400;
	line-height: 30px;
	padding-left: 25px;
}

.custom-checkbox .custom-control-label a,
.custom-radio .custom-control-label a {
  color: #4d4d4d;
}

.custom-checkbox .custom-control-label::before {
	content: '';
	width: 18px;
	height: 18px;
	background: #ffffff !important;
	border: 1px solid #615d5d;
	border-radius: 0;
	top: 5px;
	position: absolute !important;
	left: 0;
}
.custom-checkbox .custom-control-label::after {
  content: '';
  width: 20px;
  height: 20px;
  position: absolute !important;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
	background: #f81c96;
	width: 12px;
	height: 12px;
	top: 8px;
	left: 3px;
}

.custom-checkbox .custom-control-label.sm-size {
  line-height: 24px;
}

.custom-checkbox .custom-control-label.sm-size::before {
  width: 24px;
  height: 24px;
}

.custom-checkbox .custom-control-input:checked~.custom-control-label.sm-size::after {
  width: 16px;
  height: 16px;
  top: 4px;
  left: -20px;
}

.box-checked {
  margin: 0;
  padding: 0;
  font-size: large;
  float: left;
  margin-right: 20px;
}

.custom-control-input:checked ~ .custom-control-label::before {
	color: #fff;
	border-color: #615d5d;
	background-color: #007bff;
}

.box-checked .custom-control-label {
  width: auto;
  cursor: pointer;
  background: #ffffff;
  border: 1px solid #000000;
  box-sizing: border-box;
  color: #4c4c4c;
  padding: 10px 15px !important;
  min-width: 100px;
  text-align: center;
  font-weight: 500;
  font-size: 16px;
}

.box-checked .custom-control-label::before,
.box-checked .custom-control-label::after {
  display: none;
}

.box-checked .custom-control-input:checked~.custom-control-label {
  background: #76ACAD;
  color: #fff;
  border-color: #76ACAD;
}

.custom-control.custom-radio .custom-control-label {
  padding-left: 25px;
}

.custom-control.custom-radio .custom-control-label::before {
	content: '';
	position: absolute;
	background: #ffffff;
	border: 1px solid #000000;
	box-sizing: border-box;
	border-radius: 100px;
	width: 20px;
	height: 20px;
	top: 5px;
	left: 0;
}
.custom-control.custom-radio .custom-control-label::after {
	content: '';
	position: absolute;
	width: 12px;
	height: 12px;
	top: 9px;
	left: 4px;
}
.custom-radio .custom-control-input:checked~.custom-control-label::before {
  background-color: #ffffff;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
	background-image: none;
	background-color: #441e9f;
	border-radius: 100px;
}
.custom-checkbox.cc-min-box .custom-control-label::before {
  width: 15px;
  height: 15px;
  top: 8px;
}

.custom-checkbox.cc-min-box .custom-control-input:checked ~ .custom-control-label::after {
	background: #441e9f;
	width: 9px;
	height: 9px;
	top: 11px;
	left: 3px;
}

@media (min-width: 1400px) {
  .custom-checkbox .custom-control-label, .custom-radio .custom-control-label {
	font-size: 15px;
	font-weight: 400;
	vertical-align: middle;
	font-family: 'Montserrat', sans-serif;
}
}
.custome-radio .form-check-input,
.custome-checkbox .form-check-input {
  display: none;
}

.custome-radio .form-check-label::before {
  content: "";
  border: 1px solid #908f8f;
  height: 16px;
  width: 16px;
  display: inline-block;
  border-radius: 100%;
  vertical-align: middle;
  margin-right: 8px;
}

.custome-radio input[type="radio"] + .form-check-label::after {
	content: "";
	background-color: #ff106f;
	height: 10px;
	width: 10px;
	border-radius: 100%;
	position: absolute;
	top: 6px;
	left: 3px;
	opacity: 0;
}

.custome-radio .form-check-label, .custome-checkbox .form-check-label {
	position: relative;
	cursor: pointer;
	font-family: 'Montserrat', sans-serif;
}

.custome-radio input[type="radio"]:checked+.form-check-label::before {
  border-color: #ff106f;
}

.custome-radio input[type="radio"]:checked+.form-check-label::after {
  opacity: 1;
}
