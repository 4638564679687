body {
  height: 100%;
  position: relative;
  max-width: 100%;
  font-size: 1rem;
  background-color: #f9f9f9 !important;
}

@media only screen and (max-width: 991px) {
  body {
    font-size: 0.875rem;
  }
}

@media only screen and (max-width: 1400px) {
  h2 {
    font-size: 1.5rem;
  }
}

p {
  line-height: 1.8;
}

.box-shadow-none {
  box-shadow: none !important;
}

#main-wrapper {
  opacity: 0;
  transition: all 0.25s ease-in;
  overflow: hidden;
  position: relative;
}

#main-wrapper.show {
  opacity: 1;
}

.rounded-lg {
  border-radius: 1.25rem;
}

.rounded-xl {
  border-radius: 12px !important;
}

ul {
  padding: 0;
  margin: 0;
}

li {
  list-style: none;
}

a {
  color: #0b2a97;
  /* //#6aacff */
}

a:hover,
a:focus,
a.active {
  text-decoration: none;
}

.btn-link:hover,
.btn-link:focus,
.btn-link.active {
  text-decoration: none;
}

.w-space-no {
  white-space: nowrap;
}

.content-body .container {
  margin-top: 40px;
}

.content-body .container-fluid,
.content-body .container-sm,
.content-body .container-md,
.content-body .container-lg,
.content-body .container-xl {
  padding-top: 40px;
  padding-right: 60px;
  padding-left: 60px;
}

@media only screen and (max-width: 1600px) {
  .content-body .container-fluid,
  .content-body .container-sm,
  .content-body .container-md,
  .content-body .container-lg,
  .content-body .container-xl {
    padding-top: 30px;
    padding-right: 40px;
    padding-left: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .content-body .container-fluid,
  .content-body .container-sm,
  .content-body .container-md,
  .content-body .container-lg,
  .content-body .container-xl {
    padding-top: 20px;
    padding-right: 20px;
    padding-left: 20px;
  }
}

@media only screen and (max-width: 575px) {
  .content-body .container-fluid,
  .content-body .container-sm,
  .content-body .container-md,
  .content-body .container-lg,
  .content-body .container-xl {
    padding-top: 15px;
    padding-right: 15px;
    padding-left: 15px;
  }
}

/* sp */

.row.sp80,
.sp80 {
  margin-left: -40px;
  margin-right: -40px;
}

.row.sp80 [class*='col-'],
.sp80 [class*='col-'] {
  padding-left: 40px;
  padding-right: 40px;
}

.row.sp60,
.sp60 {
  margin-left: -30px;
  margin-right: -30px;
}

.row.sp60 [class*='col-'],
.sp60 [class*='col-'] {
  padding-left: 30px;
  padding-right: 30px;
}

.row.sp40,
.sp40 {
  margin-left: -20px;
  margin-right: -20px;
}

.row.sp40 [class*='col-'],
.sp40 [class*='col-'] {
  padding-left: 20px;
  padding-right: 20px;
}

.row.sp20,
.sp20 {
  margin-left: -10px;
  margin-right: -10px;
}

.row.sp20 [class*='col-'],
.sp20 [class*='col-'] {
  padding-left: 10px;
  padding-right: 10px;
}

.row.sp16,
.sp16 {
  margin-left: -8px;
  margin-right: -8px;
}

.row.sp16 [class*='col-'],
.sp16 [class*='col-'] {
  padding-left: 8px;
  padding-right: 8px;
}

.row.sp10,
.sp10 {
  margin-left: -5px;
  margin-right: -5px;
}

.row.sp10 [class*='col-'],
.sp10 [class*='col-'] {
  padding-left: 5px;
  padding-right: 5px;
}

.row.sp4,
.sp4 {
  margin-left: -2px;
  margin-right: -2px;
}

.row.sp4 [class*='col-'],
.sp4 [class*='col-'] {
  padding-left: 2px;
  padding-right: 2px;
}

.row.spno,
.spno {
  margin-left: 0;
  margin-right: 0;
}

.row.spno [class*='col-'],
.spno [class*='col-'] {
  padding-left: 0;
  padding-right: 0;
}

.content-heading {
  font-size: 16px;
  margin-bottom: 1.875rem;
  margin-top: 3.125rem;
  border-bottom: 1px solid #f0f1f5;
  padding-bottom: 10px;
}

[direction='rtl'] .content-heading {
  text-align: right;
}

.text-ov {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-primary:not(:disabled):not(.disabled).active:focus {
  box-shadow: none;
}

.op1 {
  opacity: 0.1;
}

.op2 {
  opacity: 0.2;
}

.op3 {
  opacity: 0.3;
}

.op4 {
  opacity: 0.4;
}

.op5 {
  opacity: 0.5;
}

.op6 {
  opacity: 0.6;
}

.op7 {
  opacity: 0.7;
}

.op8 {
  opacity: 0.8;
}

.op9 {
  opacity: 0.9;
}

.wspace-no {
  white-space: nowrap;
}

.text-blue {
  color: #5e72e4;
}

.text-indigo {
  color: #6610f2;
}

.text-purple {
  color: #6f42c1;
}

.text-pink {
  color: #e83e8c;
}

.text-red {
  color: #ee3232;
}

.text-orange {
  color: #ff9900;
}

.text-yellow {
  color: #fffa6f;
}

.text-green {
  color: #297f00;
}

.text-teal {
  color: #20c997;
}

.text-cyan {
  color: #3065d0;
}

.bg-gray-dark {
  background: #143b64 !important;
}

.bg-purpal {
  background: #4527a0 !important;
}

.bg-red {
  background: #c62828 !important;
}

.bg-blue-dark {
  background: #283593 !important;
}

.bg-blue {
  background: #7356f1 !important;
}

.bg-blue-light {
  background: #3695eb !important;
}

.bg-green-light {
  background: #00838f !important;
}

.bg-green {
  background: #ff8f16 !important;
}

.bg-black {
  background: #000;
}

.text-black {
  color: #000 !important;
}

.dz-scroll {
  position: relative;
}

.h-auto {
  height: auto !important;
}

.list-inline li {
  display: inline-block;
}

.fs-12 {
  font-size: 12px !important;
  line-height: 1.3;
}

.fs-13 {
  font-size: 13px !important;
  line-height: 1.4;
}

.fs-14 {
  font-size: 14px !important;
  line-height: 1.5;
}

.fs-15 {
  font-size: 14px !important;
  line-height: 1.5;
}

.fs-16 {
  font-size: 16px !important;
  line-height: 1.5;
}

.fs-18 {
  font-size: 18px !important;
  line-height: 1.5;
}

.fs-20 {
  font-size: 20px !important;
  line-height: 1.5;
}

.fs-22 {
  font-size: 22px !important;
  line-height: 1.5;
}

.fs-24 {
  font-size: 24px !important;
  line-height: 1.4;
}

.fs-26 {
  font-size: 26px !important;
  line-height: 1.4;
}

.fs-28 {
  font-size: 28px !important;
  line-height: 1.4;
}

.fs-30 {
  font-size: 30px !important;
  line-height: 1.4;
}

.fs-32 {
  font-size: 32px !important;
  line-height: 1.25;
}

.fs-34 {
  font-size: 34px !important;
  line-height: 1.25;
}

.fs-35 {
  font-size: 35px !important;
  line-height: 1.25;
}

.fs-36 {
  font-size: 36px !important;
  line-height: 1.25;
}

.fs-38 {
  font-size: 38px !important;
  line-height: 1.25;
}

.fs-46 {
  font-size: 46px !important;
  line-height: 1.25;
}

.fs-48 {
  font-size: 48px !important;
  line-height: 1.25;
}

.font-w100 {
  font-weight: 100;
}

.font-w200 {
  font-weight: 200;
}

.font-w300 {
  font-weight: 300;
}

.font-w400 {
  font-weight: 400;
}

.font-w500 {
  font-weight: 500;
}

.font-w600 {
  font-weight: 600;
}

.font-w700 {
  font-weight: 700;
}

.font-w800 {
  font-weight: 800;
}

.font-w900 {
  font-weight: 900;
}

.scale1 {
  transform: scale(1.1);
  -moz-transform: scale(1.1);
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  -o-transform: scale(1.1);
  display: inline-block;
}

.scale2 {
  transform: scale(1.2);
  -moz-transform: scale(1.2);
  -webkit-transform: scale(1.2);
  -ms-transform: scale(1.2);
  -o-transform: scale(1.2);
  display: inline-block;
}

.scale3 {
  transform: scale(1.3);
  -moz-transform: scale(1.3);
  -webkit-transform: scale(1.3);
  -ms-transform: scale(1.3);
  -o-transform: scale(1.3);
  display: inline-block;
}

.scale4 {
  transform: scale(1.4);
  -moz-transform: scale(1.4);
  -webkit-transform: scale(1.4);
  -ms-transform: scale(1.4);
  -o-transform: scale(1.4);
  display: inline-block;
}

.scale5 {
  transform: scale(1.5);
  -moz-transform: scale(1.5);
  -webkit-transform: scale(1.5);
  -ms-transform: scale(1.5);
  -o-transform: scale(1.5);
  display: inline-block;
}

.scale-2 {
  transform: scale(2);
  -moz-transform: scale(2);
  -webkit-transform: scale(2);
  -ms-transform: scale(2);
  -o-transform: scale(2);
  display: inline-block;
}

.height10 {
  height: 10px;
}

.height20 {
  height: 20px;
}

.height30 {
  height: 30px;
}

.height40 {
  height: 40px;
}

.height50 {
  height: 50px;
}

.height60 {
  height: 60px;
}

.height70 {
  height: 70px;
}

.height80 {
  height: 80px;
}

.height90 {
  height: 90px;
}

.height100 {
  height: 100px;
}

.height110 {
  height: 110px;
}

.height120 {
  height: 120px;
}

.height130 {
  height: 130px;
}

.height140 {
  height: 140px;
}

.height150 {
  height: 150px;
}

.height160 {
  height: 160px;
}

.height170 {
  height: 170px;
}

.height180 {
  height: 180px;
}

.height190 {
  height: 190px;
}

.height200 {
  height: 200px;
}

.height210 {
  height: 210px;
}

.height220 {
  height: 220px;
}

.height230 {
  height: 230px;
}

.height240 {
  height: 240px;
}

.height250 {
  height: 250px;
}

.height260 {
  height: 260px;
}

.height270 {
  height: 270px;
}

.height280 {
  height: 280px;
}

.height290 {
  height: 290px;
}

.height300 {
  height: 300px;
}

.height310 {
  height: 310px;
}

.height320 {
  height: 320px;
}

.height330 {
  height: 330px;
}

.height340 {
  height: 340px;
}

.height350 {
  height: 350px;
}

.height360 {
  height: 360px;
}

.height370 {
  height: 370px;
}

.height380 {
  height: 380px;
}

.height390 {
  height: 390px;
}

.height400 {
  height: 400px;
}

.height415 {
  height: 415px;
}

.height720 {
  height: 720px;
}

.height750 {
  height: 750px;
}

.height800 {
  height: 800px;
}

.width10 {
  width: 10px;
}

.width20 {
  width: 20px;
}

.width30 {
  width: 30px;
}

.width40 {
  width: 40px;
}

.width50 {
  width: 50px;
}

.width60 {
  width: 60px;
}

.width70 {
  width: 70px;
}

.width80 {
  width: 80px;
}

.width90 {
  width: 90px;
}

.width100 {
  width: 100px;
}

.width110 {
  width: 110px;
}

.width120 {
  width: 120px;
}

.width130 {
  width: 130px;
}

.width140 {
  width: 140px;
}

.width150 {
  width: 150px;
}

.width160 {
  width: 160px;
}

.width170 {
  width: 170px;
}

.width180 {
  width: 180px;
}

.width190 {
  width: 190px;
}

.width200 {
  width: 200px;
}

.width210 {
  width: 210px;
}

.width220 {
  width: 220px;
}

.width230 {
  width: 230px;
}

.width240 {
  width: 240px;
}

.width250 {
  width: 250px;
}

.width260 {
  width: 260px;
}

.width270 {
  width: 270px;
}

.width280 {
  width: 280px;
}

.width290 {
  width: 290px;
}

.width300 {
  width: 300px;
}

.width310 {
  width: 310px;
}

.width320 {
  width: 320px;
}

.width330 {
  width: 330px;
}

.width340 {
  width: 340px;
}

.width350 {
  width: 350px;
}

.width360 {
  width: 360px;
}

.width370 {
  width: 370px;
}

.width380 {
  width: 380px;
}

.width390 {
  width: 390px;
}

.width400 {
  width: 400px;
}

@-webkit-keyframes crescendo {
  from {
    -webkit-transform: translateY(5px) scale(0.8);
    -ms-transform: translateY(5px) scale(0.8);
    transform: translateY(5px) scale(0.8);
  }
  to {
    -webkit-transform: translateY(0px) scale(1);
    -ms-transform: translateY(0px) scale(1);
    transform: translateY(0px) scale(1);
  }
}

@keyframes gXGDoR {
  from {
    -webkit-transform: translateY(5px) scale(0.8);
    -ms-transform: translateY(5px) scale(0.8);
    transform: translateY(5px) scale(0.8);
  }
  to {
    -webkit-transform: translateY(0px) scale(1);
    -ms-transform: translateY(0px) scale(1);
    transform: translateY(0px) scale(1);
  }
}

@keyframes crescendo {
  from {
    -webkit-transform: translateY(5px) scale(0.8);
    -ms-transform: translateY(5px) scale(0.8);
    transform: translateY(5px) scale(0.8);
  }
  to {
    -webkit-transform: translateY(0px) scale(1);
    -ms-transform: translateY(0px) scale(1);
    transform: translateY(0px) scale(1);
  }
}

@keyframes gXGDoR {
  from {
    -webkit-transform: translateY(5px) scale(0.8);
    -ms-transform: translateY(5px) scale(0.8);
    transform: translateY(5px) scale(0.8);
  }
  to {
    -webkit-transform: translateY(0px) scale(1);
    -ms-transform: translateY(0px) scale(1);
    transform: translateY(0px) scale(1);
  }
}

.border-2 {
  border-width: 2px !important;
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .col-xxl-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-xxl-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-xxl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xxl-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-xxl-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-xxl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xxl-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-xxl-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-xxl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xxl-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-xxl-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-xxl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .mb-xxl-4 {
    margin-bottom: 1.5rem !important;
  }
}

/* Editable */

#preloader {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: #fff;
}

.sk-three-bounce {
  margin: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  background-color: white;
}

.sk-three-bounce .sk-child {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  background-color: #0b2a97;
  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-three-bounce 1.4s ease-in-out 0s infinite both;
  animation: sk-three-bounce 1.4s ease-in-out 0s infinite both;
}

.sk-three-bounce .sk-bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.sk-three-bounce .sk-bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@-webkit-keyframes sk-three-bounce {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes sk-three-bounce {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.footer {
  padding-left: 21.563rem;
  background-color: transparent;
}

@media only screen and (max-width: 1400px) {
  .footer {
    padding-left: 17rem;
  }
}

.footer .copyright {
  padding: 0.9375rem;
}

.footer .copyright p {
  text-align: center;
  margin: 0;
}

.footer .copyright a {
  color: #0b2a97;
}

@media only screen and (min-width: 991px) {
  .footer .copyright {
    border-radius: 0 0 0 50px;
  }
}

[data-sidebar-style='mini'] .nav-control,
[data-layout='horizontal'] .nav-control {
  display: none;
}

@media only screen and (max-width: 767px) {
  [data-sidebar-style='overlay'] .nav-header .logo-abbr {
    display: block;
  }
}
@media screen and (max-width: 800px) {
  .footer{
    display: none;
  }
}
[data-header-position='fixed'] .nav-header {
  position: fixed;
  left: auto;
}

.nav-header {
  height: 7.5rem;
  width: 21.563rem;
  display: inline-block;
  text-align: left;
  position: absolute;
  left: 0;
  top: 0;
  background-color: #fff;
  transition: all 0.2s ease;
  z-index: 5;
}

.nav-header .logo-abbr {
  /* max-width: 130px; */
}

@media only screen and (max-width: 575px) {
  .nav-header .logo-abbr {
    max-width: 40px;
  }
}

.nav-header .logo-compact {
  display: none;
}

@media only screen and (min-width: 1200px) and (max-width: 1400px) {
  .nav-header {
    width: 17rem;
  }
}

.nav-header .brand-logo {
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  font-size: 1.125rem;
  color: #fff;
  text-decoration: none;
  padding-left: 50px;
  padding-right: 50px;
  font-weight: 700;
}

@media only screen and (max-width: 1400px) {
  .nav-header .brand-logo {
    padding-left: 25px;
    padding-right: 25px;
  }
}

[data-sidebar-style='compact'] .nav-header .brand-logo,
[data-sidebar-style='mini'] .nav-header .brand-logo {
  padding-left: 0;
  padding-right: 0;
  justify-content: center;
}

@media only screen and (max-width: 767px) {
  .nav-header .brand-logo {
    padding-left: 0;
    padding-right: 0;
    justify-content: center;
  }
}

.nav-header .brand-title {
  margin-left: 10px;
  max-width: 120px;
  margin-top: 0px;
}

[data-theme-version='dark'] .nav-header .brand-title {
  background-position: 0 120%;
}

@media only screen and (max-width: 1199px) {
  .nav-header {
    width: 5rem;
    height: 5rem;
  }
  .nav-header .brand-title {
    display: none;
  }
}

.nav-control {
  cursor: pointer;
  position: absolute;
  right: -4.0625rem;
  text-align: center;
  top: 50%;
  transform: translateY(-50%);
  z-index: 9999;
  font-size: 1.4rem;
  padding: 2px 0.5rem 0;
  border-radius: 2px;
}

@media only screen and (max-width: 767px) {
  .nav-control {
    right: -2.75rem;
  }
}

@media only screen and (max-width: 575px) {
  .nav-control {
    right: -2.75rem;
  }
}

.hamburger {
  display: inline-block;
  left: 0px;
  position: relative;
  top: 3px;
  -webkit-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
  width: 26px;
  z-index: 999;
}

.hamburger .line {
  background: #0b2a97;
  display: block;
  height: 3px;
  border-radius: 3px;
  margin-top: 6px;
  margin-bottom: 6px;
  margin-left: auto;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.hamburger .line:nth-child(1) {
  width: 20px;
}

.hamburger .line:nth-child(2) {
  width: 26px;
}

.hamburger .line:nth-child(3) {
  width: 22px;
}

.hamburger:hover {
  cursor: pointer;
}

.hamburger:hover .line {
  width: 26px;
}

/*
.hamburger.is-active {
  left: 60px;
}
*/

.hamburger.is-active .line:nth-child(1),
.hamburger.is-active .line:nth-child(3) {
  width: 10px;
  height: 2px;
}

.hamburger.is-active .line:nth-child(2) {
  -webkit-transform: translateX(0px);
  transform: translateX(0px);
  width: 22px;
  height: 2px;
}

.hamburger.is-active .line:nth-child(1) {
  -webkit-transform: translateY(4px) rotate(45deg);
  transform: translateY(4px) rotate(45deg);
}

.hamburger.is-active .line:nth-child(3) {
  -webkit-transform: translateY(-4px) rotate(-45deg);
  transform: translateY(-4px) rotate(-45deg);
}

@media (min-width: 767px) {
  [data-sidebar-style='compact'] .nav-control {
    display: none;
  }
  [data-sidebar-style='compact'] .nav-header {
    width: 15rem;
  }
}

[data-sidebar-style='full'][data-layout='vertical'] .menu-toggle .brand-title {
  display: none;
}

[data-sidebar-style='full'][data-layout='vertical']
  .menu-toggle
  .nav-header
  .logo-abbr {
  display: block;
}

[data-sidebar-style='full'][data-layout='horizontal'] .logo-compact {
  display: none;
}

[data-sidebar-style='mini'] .nav-header .logo-abbr {
  display: block;
}

[data-sidebar-style='compact'][data-layout='vertical']
  .nav-header
  .brand-title {
  /* display: none; */
}

[data-sidebar-style='compact'][data-layout='vertical']
  .nav-header
  .logo-compact {
  max-width: 75px;
}

[data-sidebar-style='compact'][data-layout='horizontal']
  .nav-header
  .brand-logo {
  padding-left: 30px;
  padding-right: 30px;
  justify-content: start;
}

[data-sidebar-style='modern'][data-layout='vertical'] .nav-header {
  width: 9.375rem;
}

[data-sidebar-style='modern'][data-layout='vertical'] .nav-header .brand-title {
  display: none;
}

[data-sidebar-style='modern'][data-layout='vertical']
  .nav-header
  .logo-compact {
  display: none;
}

.header {
  height: 7.5rem;
  z-index: 1;
  position: relative;
  padding: 0rem;
  background-color: #fff;
  z-index: 4;
  padding-left: 21.563rem;
  transition: all 0.2s ease;
}

@media only screen and (max-width: 1400px) {
  .header {
    padding-left: 17rem;
  }
}

.header .header-content {
  height: 100%;
  padding-left: 5.3125rem;
  padding-right: 2.4rem;
  align-items: center;
  display: flex;
}

@media only screen and (max-width: 767px) {
  .header .header-content {
    padding-left: 3.75rem;
    padding-right: 0.938rem;
    border-radius: 0;
  }
}

.header .navbar {
  padding: 0;
  height: 100%;
  width: 100%;
}

.header .navbar .navbar-collapse {
  height: 100%;
  width: 100%;
}

@media only screen and (max-width: 1199px) {
  .header {
    height: 5rem;
  }
}

/* pulse in SVG */

svg.pulse-svg {
  overflow: visible;
}

svg.pulse-svg .first-circle,
svg.pulse-svg .second-circle,
svg.pulse-svg .third-circle {
  -webkit-transform: scale(0.3);
  transform: scale(0.3);
  -webkit-transform-origin: center center;
  transform-origin: center center;
  -webkit-animation: pulse-me 3s linear infinite;
  animation: pulse-me 3s linear infinite;
  fill: #0b2a97;
}

svg.pulse-svg .second-circle {
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}

svg.pulse-svg .third-circle {
  -webkit-animation-delay: 2s;
  animation-delay: 2s;
}

/* pulse in CSS */

.pulse-css {
  width: 1rem;
  height: 1rem;
  border-radius: 0.5rem;
  border-radius: 3.5rem;
  height: 1rem;
  position: absolute;
  background: #ffbc11;
  right: 0px;
  top: 0px;
  width: 1rem;
}

.pulse-css:after,
.pulse-css:before {
  content: '';
  width: 1rem;
  height: 1rem;
  border-radius: 0.5rem;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: -0.2rem;
  background-color: #ffbc11;
  margin: auto;
  -webkit-transform: scale(0.3);
  transform: scale(0.3);
  -webkit-transform-origin: center center;
  transform-origin: center center;
  -webkit-animation: pulse-me 3s linear infinite;
  animation: pulse-me 3s linear infinite;
}

[direction='rtl'] .pulse-css:after,
[direction='rtl'] .pulse-css:before {
  left: auto;
  right: -0.2rem;
}

@media only screen and (max-width: 1400px) {
  .pulse-css {
    height: 0.5rem;
    width: 0.5rem;
  }
}

@-webkit-keyframes pulse-me {
  0% {
    -webkit-transform: scale(0.3);
    transform: scale(0.3);
    opacity: 0;
  }
  50% {
    opacity: 0.1;
  }
  70% {
    opacity: 0.09;
  }
  100% {
    -webkit-transform: scale(3);
    transform: scale(3);
    opacity: 0;
  }
}

@keyframes pulse-me {
  0% {
    -webkit-transform: scale(0.3);
    transform: scale(0.3);
    opacity: 0;
  }
  50% {
    opacity: 0.1;
  }
  70% {
    opacity: 0.09;
  }
  100% {
    -webkit-transform: scale(3);
    transform: scale(3);
    opacity: 0;
  }
}

[data-sidebar-style='full'] .header,
[data-sidebar-style='overlay'] .header {
  width: 100%;
}

@media only screen and (min-width: 1200px) and (max-width: 1350px) {
  [data-sidebar-style='full'] .header,
  [data-sidebar-style='overlay'] .header {
    width: 100%;
    padding-left: 17rem;
  }
}

@media only screen and (max-width: 1199px) {
  [data-sidebar-style='full'] .header,
  [data-sidebar-style='overlay'] .header {
    width: 100%;
    padding-left: 5rem;
  }
}

[data-sidebar-style='mini'] .header {
  width: 100%;
  padding-left: 5rem;
}

[data-sidebar-style='compact'] .header {
  width: 100%;
  padding-left: 15rem;
}

[data-sidebar-style='compact'] .header .header-content {
  padding-left: 2.4rem;
}

[data-header-position='fixed'] .header {
  position: fixed;
  top: 0;
  width: 100%;
}

[data-header-position='fixed'] .content-body {
  padding-top: 7.5rem;
}

@media only screen and (max-width: 1199px) {
  [data-header-position='fixed'] .content-body {
    padding-top: 5rem;
  }
}

[data-header-position='fixed'] .deznav {
  margin-top: 0;
}

[data-sidebar-style='compact'][data-header-position='fixed'][data-container='boxed'][data-layout='vertical']
  .header {
  width: 1199px;
}

[data-sidebar-style='modern'] .header {
  width: 100%;
  padding-left: 9.375rem;
}

[data-sidebar-style='modern'][data-layout='horizontal']
  .nav-header
  .brand-logo {
  justify-content: start;
}

[data-sidebar-style='modern'][data-layout='horizontal']
  .header
  .header-content {
  padding-left: 30px;
}

.header-left {
  height: 100%;
  display: flex;
  align-items: center;
}

.header-left .dashboard_bar {
  font-size: 28px;
  font-weight: 600;
  color: #000;
}

@media only screen and (max-width: 1400px) {
  .header-left .dashboard_bar {
    font-size: 24px;
  }
}

@media only screen and (max-width: 767px) {
  .header-left .dashboard_bar {
    display: none;
  }
}

.header-left input {
  background: #f9f9f9 !important;
  min-width: 170px;
  min-height: 40px;
  color: #b1b1b1 !important;
  border-top-left-radius: 1.25rem;
  border-bottom-left-radius: 1.25rem;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  box-shadow: none;
  padding: 10px 20px;
  font-size: 16px;
  border-right-width: 0 !important;
  border: 1px solid #ebebeb;
  border-width: 1px;
  font-weight: 300;
}

[direction='rtl'] .header-left input {
  border-top-right-radius: 1.25rem;
  border-bottom-right-radius: 1.25rem;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.header-left input:focus,
.header-left input:active,
.header-left input.active {
  border-color: #ebebeb;
  box-shadow: none;
}

.header-left input::-webkit-input-placeholder {
  /* Edge */
  color: #b1b1b1;
}

.header-left input:-ms-input-placeholder {
  /* Internet Explorer */
  color: #b1b1b1;
}

.header-left input::placeholder {
  color: #b1b1b1;
}

.header-left .search_bar {
  display: flex;
  align-items: center;
  height: 100%;
}

@media only screen and (max-width: 767px) {
  .header-left .search_bar {
    display: none;
  }
}

.header-left .search_bar .dropdown-menu {
  box-shadow: none;
}

.header-left .search_bar .search_icon {
  background: #f9f9f9 !important;
  height: 56px;
  line-height: 22px;
  padding: 15px 20px 15px 0 !important;
  border-top-right-radius: 1.25rem;
  border-bottom-right-radius: 1.25rem;
  border: 1px solid #ebebeb;
  border-width: 1px;
  border-left-width: 0;
}

.header-left .search_bar .search_icon i {
  font-size: 24px;
  color: #b1b1b1;
  line-height: 1;
}

@media only screen and (max-width: 1400px) {
  .header-left .search_bar .search_icon {
    height: 41px;
    padding: 8px 15px 8px 0 !important;
  }
  .header-left .search_bar .search_icon svg {
    width: 18px;
    height: 18px;
  }
}

@media only screen and (max-width: 767px) {
  .header-left .search_bar {
    position: static;
  }
  .header-left .search_bar .dropdown-menu {
    width: 300px;
    left: -15vw;
    box-shadow: 0px 5px 10px 0 rgba(0, 0, 0, 0.1);
    border-radius: 4px !important;
  }
}

@media only screen and (max-width: 767px) and (max-width: 575px) {
  .header-left .search_bar .dropdown-menu {
    width: 250px;
    left: -25vw;
  }
}

@media only screen and (max-width: 767px) {
  .header-left .search_bar .dropdown-menu .form-control {
    border-radius: 4px !important;
    width: 100%;
  }
  [direction='rtl'] .header-left .search_bar .dropdown-menu {
    right: -98px;
  }
}

.header-left .search_bar .dropdown-menu,
.header-left .search_bar .dropdown-menu.show {
  border: 0px;
  background-color: transparent;
  border-top-right-radius: 5rem;
  border-bottom-right-radius: 5rem;
}

@media only screen and (min-width: 768px) {
  .header-left .search_bar .dropdown-menu,
  .header-left .search_bar .dropdown-menu.show {
    left: 0;
    top: 0;
    transform: none;
    display: block;
    position: unset;
  }
}

[data-sidebar-style='compact'] .header-left {
  margin-left: 0;
}

.header-right {
  height: 100%;
}

.header-right .nav-item {
  height: 100%;
  display: flex;
  align-items: center;
}

.header-right .nav-item .nav-link {
  color: #464a53;
  font-size: 18px;
}

.header-right .right-sidebar {
  margin-right: -30px;
}

.header-right .right-sidebar a {
  height: 80px;
  width: 80px;
  text-align: center;
  justify-content: center;
  display: flex;
  align-items: center;
  border-left: 1px solid #f4f5f9;
}

.header-right > li:not(:first-child) {
  padding-left: 2rem;
}

@media only screen and (max-width: 1600px) {
  .header-right > li:not(:first-child) {
    padding-left: 1.3rem;
  }
}

@media only screen and (max-width: 1400px) {
  .header-right > li:not(:first-child) {
    padding-left: 1rem;
  }
}

@media only screen and (max-width: 575px) {
  .header-right > li:not(:first-child) {
    padding-left: 0.5rem;
  }
}

@media only screen and (max-width: 767px) {
  .header-right .notification_dropdown {
    position: static;
  }
}

.header-right .notification_dropdown .nav-link {
  position: relative;
  color: #0b2a97;
  background: rgba(11, 42, 151, 0.1);
  border-radius: 1.25rem;
  padding: 12px;
  line-height: 1;
}

@media only screen and (max-width: 1400px) {
  .header-right .notification_dropdown .nav-link {
    padding: 10px;
  }
}

.header-right .notification_dropdown .nav-link.secondary {
  background: #f1dffe;
  color: #a02cfa;
}

.header-right .notification_dropdown .nav-link.secondary [fill] {
  fill: #a02cfa;
}

.header-right .notification_dropdown .nav-link.primary {
  background: #d8e0fc;
  color: #0b2a97;
}

.header-right .notification_dropdown .nav-link.primary [fill] {
  fill: #0b2a97;
}

.header-right .notification_dropdown .nav-link.warning {
  background: #fff5dd;
  color: #ffbc11;
}

.header-right .notification_dropdown .nav-link.warning [fill] {
  fill: #ffbc11;
}

.header-right .notification_dropdown .nav-link.success {
  background: #e3f9e9;
  color: #2bc155;
}

.header-right .notification_dropdown .nav-link.success [fill] {
  fill: #2bc155;
}

.header-right .notification_dropdown .nav-link i {
  font-size: 24px;
}

@media only screen and (max-width: 1400px) {
  .header-right .notification_dropdown .nav-link i {
    font-size: 18px;
  }
}

@media only screen and (max-width: 1400px) {
  .header-right .notification_dropdown .nav-link svg {
    width: 24px;
    height: 24px;
  }
}

.header-right .notification_dropdown .nav-link .badge {
  position: absolute;
  font-size: 14px;
  border-radius: 40px;
  right: -1px;
  top: 3px;
  box-shadow: 0px 0px 10px 3px rgba(11, 42, 151, 0.2);
  font-weight: normal;
  height: 26px;
  width: 26px;
  line-height: 26px;
  text-align: center;
  padding: 0px;
}

@media only screen and (max-width: 1400px) {
  .header-right .notification_dropdown .nav-link .badge {
    right: 3px;
    top: 3px;
    font-size: 12px;
    height: 18px;
    width: 18px;
    line-height: 18px;
  }
}

@media only screen and (max-width: 575px) {
  .header-right .notification_dropdown .nav-link .badge {
    right: -1px;
    height: 15px;
    width: 15px;
    font-size: 10px;
    line-height: 15px;
  }
}

.header-right .notification_dropdown .dropdown-item:focus a,
.header-right .notification_dropdown .dropdown-item:active a {
  color: #fff;
}

.header-right .notification_dropdown .dropdown-item a {
  color: #b1b1b1;
}

.header-right .notification_dropdown .dropdown-item a:hover {
  text-decoration: none;
}

.header-right .dropdown-menu {
  border-width: 0;
  box-shadow: 0 0 37px rgba(8, 21, 66, 0.05);
}

[data-theme-version='dark'] .header-right .dropdown-menu {
  box-shadow: none;
}

.header-right .header-profile > a.nav-link {
  padding: 0 0 0 0;
  display: flex;
  align-items: center;
  border-radius: 1.25rem 28px 28px 1.25rem;
  margin: 0;
}

.header-right .notification_dropdown .nav-link {
  margin: 0;
}

@media only screen and (max-width: 575px) {
  .header-right .header-profile > a.nav-link {
    background: transparent;
    padding: 0;
  }
}

.header-right .header-profile > a.nav-link i {
  font-weight: 700;
}

.header-right .header-profile > a.nav-link .header-info {
  text-align: left;
  padding-left: 15px;
}

@media only screen and (max-width: 1199px) {
  .header-right .header-profile > a.nav-link .header-info {
    padding-left: 15px;
  }
}

@media only screen and (max-width: 575px) {
  .header-right .header-profile > a.nav-link .header-info {
    display: none;
  }
}

.header-right .header-profile > a.nav-link .header-info span {
  font-size: 16px;
  display: block;
  font-weight: 400;
}

.header-right .header-profile > a.nav-link .header-info strong {
  font-weight: 600;
}

.header-right .header-profile > a.nav-link .header-info small {
  display: block;
  font-size: 13px;
  color: #89879f;
  font-weight: 400;
  line-height: 1.2;
}

.header-right .header-profile .dropdown-menu {
  padding: 15px 0;
  min-width: 12.5rem;
}

.header-right .header-profile .dropdown-menu a:hover,
.header-right .header-profile .dropdown-menu a:focus,
.header-right .header-profile .dropdown-menu a.active {
  color: #0b2a97;
}

.header-right .header-profile img {
  width: 57px;
  height: 57px;
  border-radius: 60px;
  margin-left: 0px;
  border: 2px solid #e8ecef;
}

@media only screen and (max-width: 1400px) {
  .header-right .header-profile img {
    width: 39px;
    height: 39px;
  }
}

.header-right .header-profile .dropdown-toggle i {
  font-size: 1.25rem;
}

@media only screen and (max-width: 575px) {
  .header-right .header-profile .dropdown-toggle span {
    display: none;
  }
}

.header-right .header-profile .profile_title {
  background: #0b2a97;
  color: #fff;
  padding: 10px 20px;
}

.header-right .header-profile .profile_title h5 {
  color: #fff;
  margin-bottom: 3px;
}

.header-right .header-profile .dropdown-item {
  padding: 8px 24px;
}

.dz-fullscreen #icon-minimize {
  display: none;
}

.dz-fullscreen.active #icon-full {
  display: none;
}

.dz-fullscreen.active #icon-minimize {
  display: inline-block;
}

.notification_dropdown .dropdown-menu-right {
  min-width: 310px;
  padding: 0rem 0 1rem;
  top: 100%;
}

.notification_dropdown .dropdown-menu-right .notification_title {
  background: #0b2a97;
  color: #fff;
  padding: 10px 20px;
}

.notification_dropdown .dropdown-menu-right .notification_title h5 {
  color: #fff;
  margin-bottom: 3px;
}

.notification_dropdown .dropdown-menu-right .media {
  width: 45px !important;
  height: 45px !important;
  font-size: 18px !important;
}

[data-theme-version='dark'] .notification_dropdown .dropdown-menu-right .media {
  border-color: #333a54;
}

.notification_dropdown .dropdown-menu-right .media > span {
  width: 35px;
  height: 35px;
  border-radius: 50px;
  display: inline-block;
  padding: 7px 9px;
  margin-right: 10px;
}

[direction='rtl'].notification_dropdown .dropdown-menu-right .media > span {
  margin-right: 0;
  margin-left: 10px;
}

.notification_dropdown .dropdown-menu-right .media > span.success {
  background: #e3f9e9;
  color: #2bc155;
}

.notification_dropdown .dropdown-menu-right .media > span.success [fill] {
  fill: #2bc155;
}

.notification_dropdown .dropdown-menu-right .media > span.primary {
  background: #d8e0fc;
  color: #0b2a97;
}

.notification_dropdown .dropdown-menu-right .media > span.primary svg [fill] {
  fill: #0b2a97;
}

.notification_dropdown .dropdown-menu-right .media > span.danger {
  background: #fff3f7;
  color: #f94687;
}

.notification_dropdown .dropdown-menu-right .media > span.danger [fill] {
  fill: #f94687;
}

.notification_dropdown .dropdown-menu-right .media > span [fill] {
  fill: #0b2a97;
}

.notification_dropdown .dropdown-menu-right .media .notify-time {
  width: 100% !important;
  margin-right: 0 !important;
  color: #828690;
}

.notification_dropdown .dropdown-menu-right .media p {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 200px;
  margin-bottom: 0;
  margin-top: 5px;
}

@media only screen and (max-width: 575px) {
  .notification_dropdown .dropdown-menu-right .media p {
    max-width: 100px;
  }
}

.notification_dropdown .dropdown-menu-right .all-notification {
  display: block;
  padding: 15px 30px 0;
  text-align: center;
  border-top: 1px solid #f4f5f9;
}

.notification_dropdown .dropdown-menu-right .all-notification i {
  margin-left: 10px;
}

.rtl {
  text-align: right;
  direction: rtl;
}

.rtl .nav {
  padding-right: 0;
}

.rtl .navbar-nav .nav-item {
  float: right;
}

.rtl .navbar-nav .nav-item + .nav-item {
  margin-right: 1rem;
  margin-left: inherit;
}

.rtl th {
  text-align: right;
}

.rtl .alert-dismissible {
  padding-right: 1.25rem;
  padding-left: 4rem;
}

.rtl .dropdown-menu {
  right: 0;
  text-align: right;
}

.rtl .checkbox label {
  padding-right: 1.25rem;
  padding-left: inherit;
}

.rtl .btn-group > .btn:first-child:not(:last-child):not(.dropdown-toggle) {
  border-radius: 0 0.75rem 0.75rem 0;
}

.rtl .btn-group > .btn:last-child:not(:first-child),
.rtl .btn-group > .dropdown-toggle:not(:first-child) {
  border-radius: 0.75rem 0 0 0.75rem;
}

.rtl .btn-group > .btn-group:last-child:not(:first-child) > .btn:first-child {
  border-radius: 0.75rem 0 0 0.75rem;
}

.rtl .custom-control-label::after,
.rtl .custom-control-label::before {
  right: 0;
  left: inherit;
}

.rtl .custom-select {
  padding: 0.375rem 0.75rem 0.375rem 1.75rem;
}

.rtl
  .input-group
  > .input-group-append:last-child
  > .btn:not(:last-child):not(.dropdown-toggle),
.rtl
  .input-group
  > .input-group-append:last-child
  > .input-group-text:not(:last-child),
.rtl .input-group > .input-group-append:not(:last-child) > .btn,
.rtl .input-group > .input-group-append:not(:last-child) > .input-group-text,
.rtl .input-group > .input-group-prepend > .btn,
.rtl .input-group > .input-group-prepend > .input-group-text {
  border-radius: 0 0.75rem 0.75rem 0;
}

.rtl .input-group > .input-group-append > .btn,
.rtl .input-group > .input-group-append > .input-group-text,
.rtl .input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.rtl
  .input-group
  > .input-group-prepend:first-child
  > .input-group-text:not(:first-child),
.rtl .input-group > .input-group-prepend:not(:first-child) > .btn,
.rtl .input-group > .input-group-prepend:not(:first-child) > .input-group-text {
  border-radius: 0.75rem 0 0 0.75rem;
}

.rtl .input-group > .custom-select:not(:first-child),
.rtl .input-group > .form-control:not(:first-child) {
  border-radius: 0.75rem 0 0 0.75rem;
}

.rtl .input-group > .custom-select:not(:last-child),
.rtl .input-group > .form-control:not(:last-child) {
  border-radius: 0 0.75rem 0.75rem 0;
}

.rtl .input-group > .custom-select:not(:last-child):not(:first-child),
.rtl .input-group > .form-control:not(:last-child):not(:first-child) {
  border-radius: 0;
}

.rtl .custom-control {
  padding-right: 1.5rem;
  padding-left: inherit;
  margin-right: inherit;
  margin-left: 1rem;
}

.rtl .custom-control-indicator {
  right: 0;
  left: inherit;
}

.rtl .custom-file-label::after {
  right: initial;
  left: -1px;
  border-radius: 0.25rem 0 0 0.25rem;
}

.rtl .radio input,
.rtl .radio-inline,
.rtl .checkbox input,
.rtl .checkbox-inline input {
  margin-right: -1.25rem;
  margin-left: inherit;
}

.rtl .list-group {
  padding-right: 0;
  padding-left: 40px;
}

.rtl .close {
  float: left;
}

.rtl .modal-header .close {
  margin: -15px auto -15px -15px;
}

.rtl .modal-footer > :not(:first-child) {
  margin-right: 0.25rem;
}

.rtl .alert-dismissible .close {
  right: inherit;
  left: 0;
}

.rtl .dropdown-toggle::after {
  margin-right: 0.255em;
  margin-left: 0;
}

.rtl .form-check-input {
  margin-right: -1.25rem;
  margin-left: inherit;
}

.rtl .form-check-label {
  padding-right: 1.25rem;
  padding-left: inherit;
}

.rtl .offset-1 {
  margin-right: 8.3333333333%;
  margin-left: 0;
}

.rtl .offset-2 {
  margin-right: 16.6666666667%;
  margin-left: 0;
}

.rtl .offset-3 {
  margin-right: 25%;
  margin-left: 0;
}

.rtl .offset-4 {
  margin-right: 33.3333333333%;
  margin-left: 0;
}

.rtl .offset-5 {
  margin-right: 41.6666666667%;
  margin-left: 0;
}

.rtl .offset-6 {
  margin-right: 50%;
  margin-left: 0;
}

.rtl .offset-7 {
  margin-right: 58.3333333333%;
  margin-left: 0;
}

.rtl .offset-8 {
  margin-right: 66.6666666667%;
  margin-left: 0;
}

.rtl .offset-9 {
  margin-right: 75%;
  margin-left: 0;
}

.rtl .offset-10 {
  margin-right: 83.3333333333%;
  margin-left: 0;
}

.rtl .offset-11 {
  margin-right: 91.6666666667%;
  margin-left: 0;
}

@media (min-width: 576px) {
  .rtl .offset-sm-0 {
    margin-right: 0;
    margin-left: 0;
  }
  .rtl .offset-sm-1 {
    margin-right: 8.3333333333%;
    margin-left: 0;
  }
  .rtl .offset-sm-2 {
    margin-right: 16.6666666667%;
    margin-left: 0;
  }
  .rtl .offset-sm-3 {
    margin-right: 25%;
    margin-left: 0;
  }
  .rtl .offset-sm-4 {
    margin-right: 33.3333333333%;
    margin-left: 0;
  }
  .rtl .offset-sm-5 {
    margin-right: 41.6666666667%;
    margin-left: 0;
  }
  .rtl .offset-sm-6 {
    margin-right: 50%;
    margin-left: 0;
  }
  .rtl .offset-sm-7 {
    margin-right: 58.3333333333%;
    margin-left: 0;
  }
  .rtl .offset-sm-8 {
    margin-right: 66.6666666667%;
    margin-left: 0;
  }
  .rtl .offset-sm-9 {
    margin-right: 75%;
    margin-left: 0;
  }
  .rtl .offset-sm-10 {
    margin-right: 83.3333333333%;
    margin-left: 0;
  }
  .rtl .offset-sm-11 {
    margin-right: 91.6666666667%;
    margin-left: 0;
  }
}

@media (min-width: 768px) {
  .rtl .offset-md-0 {
    margin-right: 0;
    margin-left: 0;
  }
  .rtl .offset-md-1 {
    margin-right: 8.3333333333%;
    margin-left: 0;
  }
  .rtl .offset-md-2 {
    margin-right: 16.6666666667%;
    margin-left: 0;
  }
  .rtl .offset-md-3 {
    margin-right: 25%;
    margin-left: 0;
  }
  .rtl .offset-md-4 {
    margin-right: 33.3333333333%;
    margin-left: 0;
  }
  .rtl .offset-md-5 {
    margin-right: 41.6666666667%;
    margin-left: 0;
  }
  .rtl .offset-md-6 {
    margin-right: 50%;
    margin-left: 0;
  }
  .rtl .offset-md-7 {
    margin-right: 58.3333333333%;
    margin-left: 0;
  }
  .rtl .offset-md-8 {
    margin-right: 66.6666666667%;
    margin-left: 0;
  }
  .rtl .offset-md-9 {
    margin-right: 75%;
    margin-left: 0;
  }
  .rtl .offset-md-10 {
    margin-right: 83.3333333333%;
    margin-left: 0;
  }
  .rtl .offset-md-11 {
    margin-right: 91.6666666667%;
    margin-left: 0;
  }
}

@media (min-width: 992px) {
  .rtl .offset-lg-0 {
    margin-right: 0;
    margin-left: 0;
  }
  .rtl .offset-lg-1 {
    margin-right: 8.3333333333%;
    margin-left: 0;
  }
  .rtl .offset-lg-2 {
    margin-right: 16.6666666667%;
    margin-left: 0;
  }
  .rtl .offset-lg-3 {
    margin-right: 25%;
    margin-left: 0;
  }
  .rtl .offset-lg-4 {
    margin-right: 33.3333333333%;
    margin-left: 0;
  }
  .rtl .offset-lg-5 {
    margin-right: 41.6666666667%;
    margin-left: 0;
  }
  .rtl .offset-lg-6 {
    margin-right: 50%;
    margin-left: 0;
  }
  .rtl .offset-lg-7 {
    margin-right: 58.3333333333%;
    margin-left: 0;
  }
  .rtl .offset-lg-8 {
    margin-right: 66.6666666667%;
    margin-left: 0;
  }
  .rtl .offset-lg-9 {
    margin-right: 75%;
    margin-left: 0;
  }
  .rtl .offset-lg-10 {
    margin-right: 83.3333333333%;
    margin-left: 0;
  }
  .rtl .offset-lg-11 {
    margin-right: 91.6666666667%;
    margin-left: 0;
  }
}

@media (min-width: 1200px) {
  .rtl .offset-xl-0 {
    margin-right: 0;
    margin-left: 0;
  }
  .rtl .offset-xl-1 {
    margin-right: 8.3333333333%;
    margin-left: 0;
  }
  .rtl .offset-xl-2 {
    margin-right: 16.6666666667%;
    margin-left: 0;
  }
  .rtl .offset-xl-3 {
    margin-right: 25%;
    margin-left: 0;
  }
  .rtl .offset-xl-4 {
    margin-right: 33.3333333333%;
    margin-left: 0;
  }
  .rtl .offset-xl-5 {
    margin-right: 41.6666666667%;
    margin-left: 0;
  }
  .rtl .offset-xl-6 {
    margin-right: 50%;
    margin-left: 0;
  }
  .rtl .offset-xl-7 {
    margin-right: 58.3333333333%;
    margin-left: 0;
  }
  .rtl .offset-xl-8 {
    margin-right: 66.6666666667%;
    margin-left: 0;
  }
  .rtl .offset-xl-9 {
    margin-right: 75%;
    margin-left: 0;
  }
  .rtl .offset-xl-10 {
    margin-right: 83.3333333333%;
    margin-left: 0;
  }
  .rtl .offset-xl-11 {
    margin-right: 91.6666666667%;
    margin-left: 0;
  }
}

@media (min-width: 1440) {
  .rtl .offset-xxl-0 {
    margin-right: 0;
    margin-left: 0;
  }
  .rtl .offset-xxl-1 {
    margin-right: 8.3333333333%;
    margin-left: 0;
  }
  .rtl .offset-xxl-2 {
    margin-right: 16.6666666667%;
    margin-left: 0;
  }
  .rtl .offset-xxl-3 {
    margin-right: 25%;
    margin-left: 0;
  }
  .rtl .offset-xxl-4 {
    margin-right: 33.3333333333%;
    margin-left: 0;
  }
  .rtl .offset-xxl-5 {
    margin-right: 41.6666666667%;
    margin-left: 0;
  }
  .rtl .offset-xxl-6 {
    margin-right: 50%;
    margin-left: 0;
  }
  .rtl .offset-xxl-7 {
    margin-right: 58.3333333333%;
    margin-left: 0;
  }
  .rtl .offset-xxl-8 {
    margin-right: 66.6666666667%;
    margin-left: 0;
  }
  .rtl .offset-xxl-9 {
    margin-right: 75%;
    margin-left: 0;
  }
  .rtl .offset-xxl-10 {
    margin-right: 83.3333333333%;
    margin-left: 0;
  }
  .rtl .offset-xxl-11 {
    margin-right: 91.6666666667%;
    margin-left: 0;
  }
}

.rtl .mr-0,
.rtl .mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.rtl .ml-0,
.rtl .mx-0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.rtl .mr-1,
.rtl .mx-1 {
  margin-right: 0 !important;
  margin-left: 0.25rem !important;
}

.rtl .ml-1,
.rtl .mx-1 {
  margin-left: 0 !important;
  margin-right: 0.25rem !important;
}

.rtl .mr-2,
.rtl .mx-2 {
  margin-right: 0 !important;
  margin-left: 0.5rem !important;
}

.rtl .ml-2,
.rtl .mx-2 {
  margin-left: 0 !important;
  margin-right: 0.5rem !important;
}

.rtl .mr-3,
.rtl .mx-3 {
  margin-right: 0 !important;
  margin-left: 1rem !important;
}

.rtl .ml-3,
.rtl .mx-3 {
  margin-left: 0 !important;
  margin-right: 1rem !important;
}

.rtl .mr-4,
.rtl .mx-4 {
  margin-right: 0 !important;
  margin-left: 1.5rem !important;
}

.rtl .ml-4,
.rtl .mx-4 {
  margin-left: 0 !important;
  margin-right: 1.5rem !important;
}

.rtl .mr-5,
.rtl .mx-5 {
  margin-right: 0 !important;
  margin-left: 3rem !important;
}

.rtl .ml-5,
.rtl .mx-5 {
  margin-left: 0 !important;
  margin-right: 3rem !important;
}

.rtl .pr-0,
.rtl .px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.rtl .pl-0,
.rtl .px-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.rtl .pr-1,
.rtl .px-1 {
  padding-right: 0 !important;
  padding-left: 0.25rem !important;
}

.rtl .pl-1,
.rtl .px-1 {
  padding-left: 0 !important;
  padding-right: 0.25rem !important;
}

.rtl .pr-2,
.rtl .px-2 {
  padding-right: 0 !important;
  padding-left: 0.5rem !important;
}

.rtl .pl-2,
.rtl .px-2 {
  padding-left: 0 !important;
  padding-right: 0.5rem !important;
}

.rtl .pr-3,
.rtl .px-3 {
  padding-right: 0 !important;
  padding-left: 1rem !important;
}

.rtl .pl-3,
.rtl .px-3 {
  padding-left: 0 !important;
  padding-right: 1rem !important;
}

.rtl .pr-4,
.rtl .px-4 {
  padding-right: 0 !important;
  padding-left: 1.5rem !important;
}

.rtl .pl-4,
.rtl .px-4 {
  padding-left: 0 !important;
  padding-right: 1.5rem !important;
}

.rtl .pr-5,
.rtl .px-5 {
  padding-right: 0 !important;
  padding-left: 3rem !important;
}

.rtl .pl-5,
.rtl .px-5 {
  padding-left: 0 !important;
  padding-right: 3rem !important;
}

.rtl .mr-auto {
  margin-right: 0 !important;
  margin-left: auto !important;
}

.rtl .ml-auto {
  margin-right: auto !important;
  margin-left: 0 !important;
}

.rtl .mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .rtl .mr-sm-0,
  .rtl .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .rtl .ml-sm-0,
  .rtl .mx-sm-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .rtl .mr-sm-1,
  .rtl .mx-sm-1 {
    margin-right: 0 !important;
    margin-left: 0.25rem !important;
  }
  .rtl .ml-sm-1,
  .rtl .mx-sm-1 {
    margin-left: 0 !important;
    margin-right: 0.25rem !important;
  }
  .rtl .mr-sm-2,
  .rtl .mx-sm-2 {
    margin-right: 0 !important;
    margin-left: 0.5rem !important;
  }
  .rtl .ml-sm-2,
  .rtl .mx-sm-2 {
    margin-left: 0 !important;
    margin-right: 0.5rem !important;
  }
  .rtl .mr-sm-3,
  .rtl .mx-sm-3 {
    margin-right: 0 !important;
    margin-left: 1rem !important;
  }
  .rtl .ml-sm-3,
  .rtl .mx-sm-3 {
    margin-left: 0 !important;
    margin-right: 1rem !important;
  }
  .rtl .mr-sm-4,
  .rtl .mx-sm-4 {
    margin-right: 0 !important;
    margin-left: 1.5rem !important;
  }
  .rtl .ml-sm-4,
  .rtl .mx-sm-4 {
    margin-left: 0 !important;
    margin-right: 1.5rem !important;
  }
  .rtl .mr-sm-5,
  .rtl .mx-sm-5 {
    margin-right: 0 !important;
    margin-left: 3rem !important;
  }
  .rtl .ml-sm-5,
  .rtl .mx-sm-5 {
    margin-left: 0 !important;
    margin-right: 3rem !important;
  }
  .rtl .pr-sm-0,
  .rtl .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .rtl .pl-sm-0,
  .rtl .px-sm-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .rtl .pr-sm-1,
  .rtl .px-sm-1 {
    padding-right: 0 !important;
    padding-left: 0.25rem !important;
  }
  .rtl .pl-sm-1,
  .rtl .px-sm-1 {
    padding-left: 0 !important;
    padding-right: 0.25rem !important;
  }
  .rtl .pr-sm-2,
  .rtl .px-sm-2 {
    padding-right: 0 !important;
    padding-left: 0.5rem !important;
  }
  .rtl .pl-sm-2,
  .rtl .px-sm-2 {
    padding-left: 0 !important;
    padding-right: 0.5rem !important;
  }
  .rtl .pr-sm-3,
  .rtl .px-sm-3 {
    padding-right: 0 !important;
    padding-left: 1rem !important;
  }
  .rtl .pl-sm-3,
  .rtl .px-sm-3 {
    padding-left: 0 !important;
    padding-right: 1rem !important;
  }
  .rtl .pr-sm-4,
  .rtl .px-sm-4 {
    padding-right: 0 !important;
    padding-left: 1.5rem !important;
  }
  .rtl .pl-sm-4,
  .rtl .px-sm-4 {
    padding-left: 0 !important;
    padding-right: 1.5rem !important;
  }
  .rtl .pr-sm-5,
  .rtl .px-sm-5 {
    padding-right: 0 !important;
    padding-left: 3rem !important;
  }
  .rtl .pl-sm-5,
  .rtl .px-sm-5 {
    padding-left: 0 !important;
    padding-right: 3rem !important;
  }
  .rtl .mr-sm-auto {
    margin-right: 0 !important;
    margin-left: auto !important;
  }
  .rtl .ml-sm-auto {
    margin-right: auto !important;
    margin-left: 0 !important;
  }
  .rtl .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
}

@media (min-width: 768px) {
  .rtl .mr-md-0,
  .rtl .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .rtl .ml-md-0,
  .rtl .mx-md-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .rtl .mr-md-1,
  .rtl .mx-md-1 {
    margin-right: 0 !important;
    margin-left: 0.25rem !important;
  }
  .rtl .ml-md-1,
  .rtl .mx-md-1 {
    margin-left: 0 !important;
    margin-right: 0.25rem !important;
  }
  .rtl .mr-md-2,
  .rtl .mx-md-2 {
    margin-right: 0 !important;
    margin-left: 0.5rem !important;
  }
  .rtl .ml-md-2,
  .rtl .mx-md-2 {
    margin-left: 0 !important;
    margin-right: 0.5rem !important;
  }
  .rtl .mr-md-3,
  .rtl .mx-md-3 {
    margin-right: 0 !important;
    margin-left: 1rem !important;
  }
  .rtl .ml-md-3,
  .rtl .mx-md-3 {
    margin-left: 0 !important;
    margin-right: 1rem !important;
  }
  .rtl .mr-md-4,
  .rtl .mx-md-4 {
    margin-right: 0 !important;
    margin-left: 1.5rem !important;
  }
  .rtl .ml-md-4,
  .rtl .mx-md-4 {
    margin-left: 0 !important;
    margin-right: 1.5rem !important;
  }
  .rtl .mr-md-5,
  .rtl .mx-md-5 {
    margin-right: 0 !important;
    margin-left: 3rem !important;
  }
  .rtl .ml-md-5,
  .rtl .mx-md-5 {
    margin-left: 0 !important;
    margin-right: 3rem !important;
  }
  .rtl .pr-md-0,
  .rtl .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .rtl .pl-md-0,
  .rtl .px-md-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .rtl .pr-md-1,
  .rtl .px-md-1 {
    padding-right: 0 !important;
    padding-left: 0.25rem !important;
  }
  .rtl .pl-md-1,
  .rtl .px-md-1 {
    padding-left: 0 !important;
    padding-right: 0.25rem !important;
  }
  .rtl .pr-md-2,
  .rtl .px-md-2 {
    padding-right: 0 !important;
    padding-left: 0.5rem !important;
  }
  .rtl .pl-md-2,
  .rtl .px-md-2 {
    padding-left: 0 !important;
    padding-right: 0.5rem !important;
  }
  .rtl .pr-md-3,
  .rtl .px-md-3 {
    padding-right: 0 !important;
    padding-left: 1rem !important;
  }
  .rtl .pl-md-3,
  .rtl .px-md-3 {
    padding-left: 0 !important;
    padding-right: 1rem !important;
  }
  .rtl .pr-md-4,
  .rtl .px-md-4 {
    padding-right: 0 !important;
    padding-left: 1.5rem !important;
  }
  .rtl .pl-md-4,
  .rtl .px-md-4 {
    padding-left: 0 !important;
    padding-right: 1.5rem !important;
  }
  .rtl .pr-md-5,
  .rtl .px-md-5 {
    padding-right: 0 !important;
    padding-left: 3rem !important;
  }
  .rtl .pl-md-5,
  .rtl .px-md-5 {
    padding-left: 0 !important;
    padding-right: 3rem !important;
  }
  .rtl .mr-md-auto {
    margin-right: 0 !important;
    margin-left: auto !important;
  }
  .rtl .ml-md-auto {
    margin-right: auto !important;
    margin-left: 0 !important;
  }
  .rtl .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
}

@media (min-width: 992px) {
  .rtl .mr-lg-0,
  .rtl .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .rtl .ml-lg-0,
  .rtl .mx-lg-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .rtl .mr-lg-1,
  .rtl .mx-lg-1 {
    margin-right: 0 !important;
    margin-left: 0.25rem !important;
  }
  .rtl .ml-lg-1,
  .rtl .mx-lg-1 {
    margin-left: 0 !important;
    margin-right: 0.25rem !important;
  }
  .rtl .mr-lg-2,
  .rtl .mx-lg-2 {
    margin-right: 0 !important;
    margin-left: 0.5rem !important;
  }
  .rtl .ml-lg-2,
  .rtl .mx-lg-2 {
    margin-left: 0 !important;
    margin-right: 0.5rem !important;
  }
  .rtl .mr-lg-3,
  .rtl .mx-lg-3 {
    margin-right: 0 !important;
    margin-left: 1rem !important;
  }
  .rtl .ml-lg-3,
  .rtl .mx-lg-3 {
    margin-left: 0 !important;
    margin-right: 1rem !important;
  }
  .rtl .mr-lg-4,
  .rtl .mx-lg-4 {
    margin-right: 0 !important;
    margin-left: 1.5rem !important;
  }
  .rtl .ml-lg-4,
  .rtl .mx-lg-4 {
    margin-left: 0 !important;
    margin-right: 1.5rem !important;
  }
  .rtl .mr-lg-5,
  .rtl .mx-lg-5 {
    margin-right: 0 !important;
    margin-left: 3rem !important;
  }
  .rtl .ml-lg-5,
  .rtl .mx-lg-5 {
    margin-left: 0 !important;
    margin-right: 3rem !important;
  }
  .rtl .pr-lg-0,
  .rtl .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .rtl .pl-lg-0,
  .rtl .px-lg-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .rtl .pr-lg-1,
  .rtl .px-lg-1 {
    padding-right: 0 !important;
    padding-left: 0.25rem !important;
  }
  .rtl .pl-lg-1,
  .rtl .px-lg-1 {
    padding-left: 0 !important;
    padding-right: 0.25rem !important;
  }
  .rtl .pr-lg-2,
  .rtl .px-lg-2 {
    padding-right: 0 !important;
    padding-left: 0.5rem !important;
  }
  .rtl .pl-lg-2,
  .rtl .px-lg-2 {
    padding-left: 0 !important;
    padding-right: 0.5rem !important;
  }
  .rtl .pr-lg-3,
  .rtl .px-lg-3 {
    padding-right: 0 !important;
    padding-left: 1rem !important;
  }
  .rtl .pl-lg-3,
  .rtl .px-lg-3 {
    padding-left: 0 !important;
    padding-right: 1rem !important;
  }
  .rtl .pr-lg-4,
  .rtl .px-lg-4 {
    padding-right: 0 !important;
    padding-left: 1.5rem !important;
  }
  .rtl .pl-lg-4,
  .rtl .px-lg-4 {
    padding-left: 0 !important;
    padding-right: 1.5rem !important;
  }
  .rtl .pr-lg-5,
  .rtl .px-lg-5 {
    padding-right: 0 !important;
    padding-left: 3rem !important;
  }
  .rtl .pl-lg-5,
  .rtl .px-lg-5 {
    padding-left: 0 !important;
    padding-right: 3rem !important;
  }
  .rtl .mr-lg-auto {
    margin-right: 0 !important;
    margin-left: auto !important;
  }
  .rtl .ml-lg-auto {
    margin-right: auto !important;
    margin-left: 0 !important;
  }
  .rtl .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
}

@media (min-width: 1200px) {
  .rtl .mr-xl-0,
  .rtl .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .rtl .ml-xl-0,
  .rtl .mx-xl-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .rtl .mr-xl-1,
  .rtl .mx-xl-1 {
    margin-right: 0 !important;
    margin-left: 0.25rem !important;
  }
  .rtl .ml-xl-1,
  .rtl .mx-xl-1 {
    margin-left: 0 !important;
    margin-right: 0.25rem !important;
  }
  .rtl .mr-xl-2,
  .rtl .mx-xl-2 {
    margin-right: 0 !important;
    margin-left: 0.5rem !important;
  }
  .rtl .ml-xl-2,
  .rtl .mx-xl-2 {
    margin-left: 0 !important;
    margin-right: 0.5rem !important;
  }
  .rtl .mr-xl-3,
  .rtl .mx-xl-3 {
    margin-right: 0 !important;
    margin-left: 1rem !important;
  }
  .rtl .ml-xl-3,
  .rtl .mx-xl-3 {
    margin-left: 0 !important;
    margin-right: 1rem !important;
  }
  .rtl .mr-xl-4,
  .rtl .mx-xl-4 {
    margin-right: 0 !important;
    margin-left: 1.5rem !important;
  }
  .rtl .ml-xl-4,
  .rtl .mx-xl-4 {
    margin-left: 0 !important;
    margin-right: 1.5rem !important;
  }
  .rtl .mr-xl-5,
  .rtl .mx-xl-5 {
    margin-right: 0 !important;
    margin-left: 3rem !important;
  }
  .rtl .ml-xl-5,
  .rtl .mx-xl-5 {
    margin-left: 0 !important;
    margin-right: 3rem !important;
  }
  .rtl .pr-xl-0,
  .rtl .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .rtl .pl-xl-0,
  .rtl .px-xl-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .rtl .pr-xl-1,
  .rtl .px-xl-1 {
    padding-right: 0 !important;
    padding-left: 0.25rem !important;
  }
  .rtl .pl-xl-1,
  .rtl .px-xl-1 {
    padding-left: 0 !important;
    padding-right: 0.25rem !important;
  }
  .rtl .pr-xl-2,
  .rtl .px-xl-2 {
    padding-right: 0 !important;
    padding-left: 0.5rem !important;
  }
  .rtl .pl-xl-2,
  .rtl .px-xl-2 {
    padding-left: 0 !important;
    padding-right: 0.5rem !important;
  }
  .rtl .pr-xl-3,
  .rtl .px-xl-3 {
    padding-right: 0 !important;
    padding-left: 1rem !important;
  }
  .rtl .pl-xl-3,
  .rtl .px-xl-3 {
    padding-left: 0 !important;
    padding-right: 1rem !important;
  }
  .rtl .pr-xl-4,
  .rtl .px-xl-4 {
    padding-right: 0 !important;
    padding-left: 1.5rem !important;
  }
  .rtl .pl-xl-4,
  .rtl .px-xl-4 {
    padding-left: 0 !important;
    padding-right: 1.5rem !important;
  }
  .rtl .pr-xl-5,
  .rtl .px-xl-5 {
    padding-right: 0 !important;
    padding-left: 3rem !important;
  }
  .rtl .pl-xl-5,
  .rtl .px-xl-5 {
    padding-left: 0 !important;
    padding-right: 3rem !important;
  }
  .rtl .mr-xl-auto {
    margin-right: 0 !important;
    margin-left: auto !important;
  }
  .rtl .ml-xl-auto {
    margin-right: auto !important;
    margin-left: 0 !important;
  }
  .rtl .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
}

@media (min-width: 1440) {
  .rtl .mr-xxl-0,
  .rtl .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .rtl .ml-xxl-0,
  .rtl .mx-xxl-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .rtl .mr-xxl-1,
  .rtl .mx-xxl-1 {
    margin-right: 0 !important;
    margin-left: 0.25rem !important;
  }
  .rtl .ml-xxl-1,
  .rtl .mx-xxl-1 {
    margin-left: 0 !important;
    margin-right: 0.25rem !important;
  }
  .rtl .mr-xxl-2,
  .rtl .mx-xxl-2 {
    margin-right: 0 !important;
    margin-left: 0.5rem !important;
  }
  .rtl .ml-xxl-2,
  .rtl .mx-xxl-2 {
    margin-left: 0 !important;
    margin-right: 0.5rem !important;
  }
  .rtl .mr-xxl-3,
  .rtl .mx-xxl-3 {
    margin-right: 0 !important;
    margin-left: 1rem !important;
  }
  .rtl .ml-xxl-3,
  .rtl .mx-xxl-3 {
    margin-left: 0 !important;
    margin-right: 1rem !important;
  }
  .rtl .mr-xxl-4,
  .rtl .mx-xxl-4 {
    margin-right: 0 !important;
    margin-left: 1.5rem !important;
  }
  .rtl .ml-xxl-4,
  .rtl .mx-xxl-4 {
    margin-left: 0 !important;
    margin-right: 1.5rem !important;
  }
  .rtl .mr-xxl-5,
  .rtl .mx-xxl-5 {
    margin-right: 0 !important;
    margin-left: 3rem !important;
  }
  .rtl .ml-xxl-5,
  .rtl .mx-xxl-5 {
    margin-left: 0 !important;
    margin-right: 3rem !important;
  }
  .rtl .pr-xxl-0,
  .rtl .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .rtl .pl-xxl-0,
  .rtl .px-xxl-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .rtl .pr-xxl-1,
  .rtl .px-xxl-1 {
    padding-right: 0 !important;
    padding-left: 0.25rem !important;
  }
  .rtl .pl-xxl-1,
  .rtl .px-xxl-1 {
    padding-left: 0 !important;
    padding-right: 0.25rem !important;
  }
  .rtl .pr-xxl-2,
  .rtl .px-xxl-2 {
    padding-right: 0 !important;
    padding-left: 0.5rem !important;
  }
  .rtl .pl-xxl-2,
  .rtl .px-xxl-2 {
    padding-left: 0 !important;
    padding-right: 0.5rem !important;
  }
  .rtl .pr-xxl-3,
  .rtl .px-xxl-3 {
    padding-right: 0 !important;
    padding-left: 1rem !important;
  }
  .rtl .pl-xxl-3,
  .rtl .px-xxl-3 {
    padding-left: 0 !important;
    padding-right: 1rem !important;
  }
  .rtl .pr-xxl-4,
  .rtl .px-xxl-4 {
    padding-right: 0 !important;
    padding-left: 1.5rem !important;
  }
  .rtl .pl-xxl-4,
  .rtl .px-xxl-4 {
    padding-left: 0 !important;
    padding-right: 1.5rem !important;
  }
  .rtl .pr-xxl-5,
  .rtl .px-xxl-5 {
    padding-right: 0 !important;
    padding-left: 3rem !important;
  }
  .rtl .pl-xxl-5,
  .rtl .px-xxl-5 {
    padding-left: 0 !important;
    padding-right: 3rem !important;
  }
  .rtl .mr-xxl-auto {
    margin-right: 0 !important;
    margin-left: auto !important;
  }
  .rtl .ml-xxl-auto {
    margin-right: auto !important;
    margin-left: 0 !important;
  }
  .rtl .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
}

.rtl .text-right {
  text-align: left !important;
}

.rtl .text-left {
  text-align: right !important;
}

@media (min-width: 576px) {
  .rtl .text-sm-right {
    text-align: left !important;
  }
  .rtl .text-sm-left {
    text-align: right !important;
  }
}

@media (min-width: 768px) {
  .rtl .text-md-right {
    text-align: left !important;
  }
  .rtl .text-md-left {
    text-align: right !important;
  }
}

@media (min-width: 992px) {
  .rtl .text-lg-right {
    text-align: left !important;
  }
  .rtl .text-lg-left {
    text-align: right !important;
  }
}

@media (min-width: 1200px) {
  .rtl .text-xl-right {
    text-align: left !important;
  }
  .rtl .text-xl-left {
    text-align: right !important;
  }
}

@media (min-width: 1440) {
  .rtl .text-xxl-right {
    text-align: left !important;
  }
  .rtl .text-xxl-left {
    text-align: right !important;
  }
}

.rtl .mx-0 {
  margin-right: auto;
  margin-left: 0 !important;
}

.rtl .mx-0 {
  margin-left: auto;
  margin-right: 0 !important;
}

.rtl .mx-1 {
  margin-right: auto;
  margin-left: 0.25rem !important;
}

.rtl .mx-1 {
  margin-left: auto;
  margin-right: 0.25rem !important;
}

.rtl .mx-2 {
  margin-right: auto;
  margin-left: 0.5rem !important;
}

.rtl .mx-2 {
  margin-left: auto;
  margin-right: 0.5rem !important;
}

.rtl .mx-3 {
  margin-right: auto;
  margin-left: 1rem !important;
}

.rtl .mx-3 {
  margin-left: auto;
  margin-right: 1rem !important;
}

.rtl .mx-4 {
  margin-right: auto;
  margin-left: 1.5rem !important;
}

.rtl .mx-4 {
  margin-left: auto;
  margin-right: 1.5rem !important;
}

.rtl .mx-5 {
  margin-right: auto;
  margin-left: 3rem !important;
}

.rtl .mx-5 {
  margin-left: auto;
  margin-right: 3rem !important;
}

.rtl .px-0 {
  padding-right: auto;
  padding-left: 0 !important;
}

.rtl .px-0 {
  padding-left: auto;
  padding-right: 0 !important;
}

.rtl .px-1 {
  padding-right: auto;
  padding-left: 0.25rem !important;
}

.rtl .px-1 {
  padding-left: auto;
  padding-right: 0.25rem !important;
}

.rtl .px-2 {
  padding-right: auto;
  padding-left: 0.5rem !important;
}

.rtl .px-2 {
  padding-left: auto;
  padding-right: 0.5rem !important;
}

.rtl .px-3 {
  padding-right: auto;
  padding-left: 1rem !important;
}

.rtl .px-3 {
  padding-left: auto;
  padding-right: 1rem !important;
}

.rtl .px-4 {
  padding-right: auto;
  padding-left: 1.5rem !important;
}

.rtl .px-4 {
  padding-left: auto;
  padding-right: 1.5rem !important;
}

.rtl .px-5 {
  padding-right: auto;
  padding-left: 3rem !important;
}

.rtl .px-5 {
  padding-left: auto;
  padding-right: 3rem !important;
}

.rtl .mr-auto {
  margin-right: 0 !important;
  margin-left: auto !important;
}

.rtl .mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.rtl .ml-auto {
  margin-right: auto !important;
  margin-left: 0 !important;
}

.rtl .mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .rtl .mx-sm-0 {
    margin-right: auto;
    margin-left: 0 !important;
  }
  .rtl .mx-sm-0 {
    margin-left: auto;
    margin-right: 0 !important;
  }
  .rtl .mx-sm-1 {
    margin-right: auto;
    margin-left: 0.25rem !important;
  }
  .rtl .mx-sm-1 {
    margin-left: auto;
    margin-right: 0.25rem !important;
  }
  .rtl .mx-sm-2 {
    margin-right: auto;
    margin-left: 0.5rem !important;
  }
  .rtl .mx-sm-2 {
    margin-left: auto;
    margin-right: 0.5rem !important;
  }
  .rtl .mx-sm-3 {
    margin-right: auto;
    margin-left: 1rem !important;
  }
  .rtl .mx-sm-3 {
    margin-left: auto;
    margin-right: 1rem !important;
  }
  .rtl .mx-sm-4 {
    margin-right: auto;
    margin-left: 1.5rem !important;
  }
  .rtl .mx-sm-4 {
    margin-left: auto;
    margin-right: 1.5rem !important;
  }
  .rtl .mx-sm-5 {
    margin-right: auto;
    margin-left: 3rem !important;
  }
  .rtl .mx-sm-5 {
    margin-left: auto;
    margin-right: 3rem !important;
  }
  .rtl .px-sm-0 {
    padding-right: auto;
    padding-left: 0 !important;
  }
  .rtl .px-sm-0 {
    padding-left: auto;
    padding-right: 0 !important;
  }
  .rtl .px-sm-1 {
    padding-right: auto;
    padding-left: 0.25rem !important;
  }
  .rtl .px-sm-1 {
    padding-left: auto;
    padding-right: 0.25rem !important;
  }
  .rtl .px-sm-2 {
    padding-right: auto;
    padding-left: 0.5rem !important;
  }
  .rtl .px-sm-2 {
    padding-left: auto;
    padding-right: 0.5rem !important;
  }
  .rtl .px-sm-3 {
    padding-right: auto;
    padding-left: 1rem !important;
  }
  .rtl .px-sm-3 {
    padding-left: auto;
    padding-right: 1rem !important;
  }
  .rtl .px-sm-4 {
    padding-right: auto;
    padding-left: 1.5rem !important;
  }
  .rtl .px-sm-4 {
    padding-left: auto;
    padding-right: 1.5rem !important;
  }
  .rtl .px-sm-5 {
    padding-right: auto;
    padding-left: 3rem !important;
  }
  .rtl .px-sm-5 {
    padding-left: auto;
    padding-right: 3rem !important;
  }
  .rtl .mr-sm-auto {
    margin-right: 0 !important;
    margin-left: auto !important;
  }
  .rtl .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .rtl .ml-sm-auto {
    margin-right: auto !important;
    margin-left: 0 !important;
  }
  .rtl .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
}

@media (min-width: 768px) {
  .rtl .mx-md-0 {
    margin-right: auto;
    margin-left: 0 !important;
  }
  .rtl .mx-md-0 {
    margin-left: auto;
    margin-right: 0 !important;
  }
  .rtl .mx-md-1 {
    margin-right: auto;
    margin-left: 0.25rem !important;
  }
  .rtl .mx-md-1 {
    margin-left: auto;
    margin-right: 0.25rem !important;
  }
  .rtl .mx-md-2 {
    margin-right: auto;
    margin-left: 0.5rem !important;
  }
  .rtl .mx-md-2 {
    margin-left: auto;
    margin-right: 0.5rem !important;
  }
  .rtl .mx-md-3 {
    margin-right: auto;
    margin-left: 1rem !important;
  }
  .rtl .mx-md-3 {
    margin-left: auto;
    margin-right: 1rem !important;
  }
  .rtl .mx-md-4 {
    margin-right: auto;
    margin-left: 1.5rem !important;
  }
  .rtl .mx-md-4 {
    margin-left: auto;
    margin-right: 1.5rem !important;
  }
  .rtl .mx-md-5 {
    margin-right: auto;
    margin-left: 3rem !important;
  }
  .rtl .mx-md-5 {
    margin-left: auto;
    margin-right: 3rem !important;
  }
  .rtl .px-md-0 {
    padding-right: auto;
    padding-left: 0 !important;
  }
  .rtl .px-md-0 {
    padding-left: auto;
    padding-right: 0 !important;
  }
  .rtl .px-md-1 {
    padding-right: auto;
    padding-left: 0.25rem !important;
  }
  .rtl .px-md-1 {
    padding-left: auto;
    padding-right: 0.25rem !important;
  }
  .rtl .px-md-2 {
    padding-right: auto;
    padding-left: 0.5rem !important;
  }
  .rtl .px-md-2 {
    padding-left: auto;
    padding-right: 0.5rem !important;
  }
  .rtl .px-md-3 {
    padding-right: auto;
    padding-left: 1rem !important;
  }
  .rtl .px-md-3 {
    padding-left: auto;
    padding-right: 1rem !important;
  }
  .rtl .px-md-4 {
    padding-right: auto;
    padding-left: 1.5rem !important;
  }
  .rtl .px-md-4 {
    padding-left: auto;
    padding-right: 1.5rem !important;
  }
  .rtl .px-md-5 {
    padding-right: auto;
    padding-left: 3rem !important;
  }
  .rtl .px-md-5 {
    padding-left: auto;
    padding-right: 3rem !important;
  }
  .rtl .mr-md-auto {
    margin-right: 0 !important;
    margin-left: auto !important;
  }
  .rtl .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .rtl .ml-md-auto {
    margin-right: auto !important;
    margin-left: 0 !important;
  }
  .rtl .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
}

@media (min-width: 992px) {
  .rtl .mx-lg-0 {
    margin-right: auto;
    margin-left: 0 !important;
  }
  .rtl .mx-lg-0 {
    margin-left: auto;
    margin-right: 0 !important;
  }
  .rtl .mx-lg-1 {
    margin-right: auto;
    margin-left: 0.25rem !important;
  }
  .rtl .mx-lg-1 {
    margin-left: auto;
    margin-right: 0.25rem !important;
  }
  .rtl .mx-lg-2 {
    margin-right: auto;
    margin-left: 0.5rem !important;
  }
  .rtl .mx-lg-2 {
    margin-left: auto;
    margin-right: 0.5rem !important;
  }
  .rtl .mx-lg-3 {
    margin-right: auto;
    margin-left: 1rem !important;
  }
  .rtl .mx-lg-3 {
    margin-left: auto;
    margin-right: 1rem !important;
  }
  .rtl .mx-lg-4 {
    margin-right: auto;
    margin-left: 1.5rem !important;
  }
  .rtl .mx-lg-4 {
    margin-left: auto;
    margin-right: 1.5rem !important;
  }
  .rtl .mx-lg-5 {
    margin-right: auto;
    margin-left: 3rem !important;
  }
  .rtl .mx-lg-5 {
    margin-left: auto;
    margin-right: 3rem !important;
  }
  .rtl .px-lg-0 {
    padding-right: auto;
    padding-left: 0 !important;
  }
  .rtl .px-lg-0 {
    padding-left: auto;
    padding-right: 0 !important;
  }
  .rtl .px-lg-1 {
    padding-right: auto;
    padding-left: 0.25rem !important;
  }
  .rtl .px-lg-1 {
    padding-left: auto;
    padding-right: 0.25rem !important;
  }
  .rtl .px-lg-2 {
    padding-right: auto;
    padding-left: 0.5rem !important;
  }
  .rtl .px-lg-2 {
    padding-left: auto;
    padding-right: 0.5rem !important;
  }
  .rtl .px-lg-3 {
    padding-right: auto;
    padding-left: 1rem !important;
  }
  .rtl .px-lg-3 {
    padding-left: auto;
    padding-right: 1rem !important;
  }
  .rtl .px-lg-4 {
    padding-right: auto;
    padding-left: 1.5rem !important;
  }
  .rtl .px-lg-4 {
    padding-left: auto;
    padding-right: 1.5rem !important;
  }
  .rtl .px-lg-5 {
    padding-right: auto;
    padding-left: 3rem !important;
  }
  .rtl .px-lg-5 {
    padding-left: auto;
    padding-right: 3rem !important;
  }
  .rtl .mr-lg-auto {
    margin-right: 0 !important;
    margin-left: auto !important;
  }
  .rtl .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .rtl .ml-lg-auto {
    margin-right: auto !important;
    margin-left: 0 !important;
  }
  .rtl .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
}

@media (min-width: 1200px) {
  .rtl .mx-xl-0 {
    margin-right: auto;
    margin-left: 0 !important;
  }
  .rtl .mx-xl-0 {
    margin-left: auto;
    margin-right: 0 !important;
  }
  .rtl .mx-xl-1 {
    margin-right: auto;
    margin-left: 0.25rem !important;
  }
  .rtl .mx-xl-1 {
    margin-left: auto;
    margin-right: 0.25rem !important;
  }
  .rtl .mx-xl-2 {
    margin-right: auto;
    margin-left: 0.5rem !important;
  }
  .rtl .mx-xl-2 {
    margin-left: auto;
    margin-right: 0.5rem !important;
  }
  .rtl .mx-xl-3 {
    margin-right: auto;
    margin-left: 1rem !important;
  }
  .rtl .mx-xl-3 {
    margin-left: auto;
    margin-right: 1rem !important;
  }
  .rtl .mx-xl-4 {
    margin-right: auto;
    margin-left: 1.5rem !important;
  }
  .rtl .mx-xl-4 {
    margin-left: auto;
    margin-right: 1.5rem !important;
  }
  .rtl .mx-xl-5 {
    margin-right: auto;
    margin-left: 3rem !important;
  }
  .rtl .mx-xl-5 {
    margin-left: auto;
    margin-right: 3rem !important;
  }
  .rtl .px-xl-0 {
    padding-right: auto;
    padding-left: 0 !important;
  }
  .rtl .px-xl-0 {
    padding-left: auto;
    padding-right: 0 !important;
  }
  .rtl .px-xl-1 {
    padding-right: auto;
    padding-left: 0.25rem !important;
  }
  .rtl .px-xl-1 {
    padding-left: auto;
    padding-right: 0.25rem !important;
  }
  .rtl .px-xl-2 {
    padding-right: auto;
    padding-left: 0.5rem !important;
  }
  .rtl .px-xl-2 {
    padding-left: auto;
    padding-right: 0.5rem !important;
  }
  .rtl .px-xl-3 {
    padding-right: auto;
    padding-left: 1rem !important;
  }
  .rtl .px-xl-3 {
    padding-left: auto;
    padding-right: 1rem !important;
  }
  .rtl .px-xl-4 {
    padding-right: auto;
    padding-left: 1.5rem !important;
  }
  .rtl .px-xl-4 {
    padding-left: auto;
    padding-right: 1.5rem !important;
  }
  .rtl .px-xl-5 {
    padding-right: auto;
    padding-left: 3rem !important;
  }
  .rtl .px-xl-5 {
    padding-left: auto;
    padding-right: 3rem !important;
  }
  .rtl .mr-xl-auto {
    margin-right: 0 !important;
    margin-left: auto !important;
  }
  .rtl .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .rtl .ml-xl-auto {
    margin-right: auto !important;
    margin-left: 0 !important;
  }
  .rtl .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
}

@media (min-width: 1440) {
  .rtl .mx-xxl-0 {
    margin-right: auto;
    margin-left: 0 !important;
  }
  .rtl .mx-xxl-0 {
    margin-left: auto;
    margin-right: 0 !important;
  }
  .rtl .mx-xxl-1 {
    margin-right: auto;
    margin-left: 0.25rem !important;
  }
  .rtl .mx-xxl-1 {
    margin-left: auto;
    margin-right: 0.25rem !important;
  }
  .rtl .mx-xxl-2 {
    margin-right: auto;
    margin-left: 0.5rem !important;
  }
  .rtl .mx-xxl-2 {
    margin-left: auto;
    margin-right: 0.5rem !important;
  }
  .rtl .mx-xxl-3 {
    margin-right: auto;
    margin-left: 1rem !important;
  }
  .rtl .mx-xxl-3 {
    margin-left: auto;
    margin-right: 1rem !important;
  }
  .rtl .mx-xxl-4 {
    margin-right: auto;
    margin-left: 1.5rem !important;
  }
  .rtl .mx-xxl-4 {
    margin-left: auto;
    margin-right: 1.5rem !important;
  }
  .rtl .mx-xxl-5 {
    margin-right: auto;
    margin-left: 3rem !important;
  }
  .rtl .mx-xxl-5 {
    margin-left: auto;
    margin-right: 3rem !important;
  }
  .rtl .px-xxl-0 {
    padding-right: auto;
    padding-left: 0 !important;
  }
  .rtl .px-xxl-0 {
    padding-left: auto;
    padding-right: 0 !important;
  }
  .rtl .px-xxl-1 {
    padding-right: auto;
    padding-left: 0.25rem !important;
  }
  .rtl .px-xxl-1 {
    padding-left: auto;
    padding-right: 0.25rem !important;
  }
  .rtl .px-xxl-2 {
    padding-right: auto;
    padding-left: 0.5rem !important;
  }
  .rtl .px-xxl-2 {
    padding-left: auto;
    padding-right: 0.5rem !important;
  }
  .rtl .px-xxl-3 {
    padding-right: auto;
    padding-left: 1rem !important;
  }
  .rtl .px-xxl-3 {
    padding-left: auto;
    padding-right: 1rem !important;
  }
  .rtl .px-xxl-4 {
    padding-right: auto;
    padding-left: 1.5rem !important;
  }
  .rtl .px-xxl-4 {
    padding-left: auto;
    padding-right: 1.5rem !important;
  }
  .rtl .px-xxl-5 {
    padding-right: auto;
    padding-left: 3rem !important;
  }
  .rtl .px-xxl-5 {
    padding-left: auto;
    padding-right: 3rem !important;
  }
  .rtl .mr-xxl-auto {
    margin-right: 0 !important;
    margin-left: auto !important;
  }
  .rtl .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .rtl .ml-xxl-auto {
    margin-right: auto !important;
    margin-left: 0 !important;
  }
  .rtl .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
}

.rtl .float-left {
  float: right !important;
}

.rtl .float-right {
  float: left !important;
}

.rtl .float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .rtl .float-sm-left {
    float: right !important;
  }
  .rtl .float-sm-right {
    float: left !important;
  }
  .rtl .float-sm-none {
    float: none !important;
  }
}

@media (min-width: 768px) {
  .rtl .float-md-left {
    float: right !important;
  }
  .rtl .float-md-right {
    float: left !important;
  }
  .rtl .float-md-none {
    float: none !important;
  }
}

@media (min-width: 992px) {
  .rtl .float-lg-left {
    float: right !important;
  }
  .rtl .float-lg-right {
    float: left !important;
  }
  .rtl .float-lg-none {
    float: none !important;
  }
}

@media (min-width: 1200px) {
  .rtl .float-xl-left {
    float: right !important;
  }
  .rtl .float-xl-right {
    float: left !important;
  }
  .rtl .float-xl-none {
    float: none !important;
  }
}

@media (min-width: 1440) {
  .rtl .float-xxl-left {
    float: right !important;
  }
  .rtl .float-xxl-right {
    float: left !important;
  }
  .rtl .float-xxl-none {
    float: none !important;
  }
}

[direction='rtl'][data-theme-version='dark'] .border,
[direction='rtl'][data-theme-version='dark'] .border-left,
[direction='rtl'][data-theme-version='dark'] .border-right {
  border-color: #333a54 !important;
}

[direction='rtl'] .text-right {
  text-align: left !important;
}

[direction='rtl'] .text-left {
  text-align: right !important;
}

[direction='rtl'] .border-right {
  border-left: 1px solid #f0f1f5 !important;
  border-right: 0 !important;
}

[direction='rtl'] .border-left {
  border-right: 1px solid #f0f1f5 !important;
  border-left: 0 !important;
}

[direction='rtl'] .dropdown-menu {
  left: auto;
}

[direction='rtl'] .dropdown-menu-right {
  left: 0;
  right: auto;
}

@media only screen and (max-width: 575px) {
  [direction='rtl'] .dropdown-menu-right {
    left: 15px;
  }
}

[direction='rtl'] .notification_dropdown .dropdown-menu-right .media > span {
  margin-left: 10px;
  margin-right: 0;
}

[direction='rtl']:not([data-container='boxed']) .nav-header {
  left: auto;
  right: 0;
}

[direction='rtl'][data-container='wide-boxed'] .nav-header {
  left: auto;
  right: 0;
}

[direction='rtl'] .nav-header {
  text-align: right;
  right: auto;
}

[direction='rtl'] .nav-header .brand-title {
  margin-left: 0;
  margin-right: 15px;
}

[direction='rtl'] .nav-header .brand-logo {
  padding-left: 0;
  padding-right: 1.75rem;
}

[data-sidebar-style='compact'][direction='rtl'] .nav-header .brand-logo {
  padding-right: 0;
}

[data-sidebar-style='compact'][direction='rtl']
  .nav-header
  .brand-logo[data-layout='horizontal'] {
  padding-right: 30px;
}

[data-sidebar-style='mini'][direction='rtl'] .nav-header .brand-logo {
  padding-right: 0;
}

[data-sidebar-style='modern'][direction='rtl'] .nav-header .brand-logo {
  padding-right: 0;
}

[data-layout='horizontal'][data-sidebar-style='modern'][direction='rtl']
  .nav-header
  .brand-logo {
  padding-right: 30px;
}

@media (max-width: 767.98px) {
  [direction='rtl'] .nav-header .brand-logo {
    padding-right: 0;
  }
}

[direction='rtl'] .nav-control {
  right: auto;
  left: -4.0625rem;
}

@media (max-width: 767.98px) {
  [direction='rtl'] .nav-control {
    left: -4.0625rem;
  }
}

@media (max-width: 575.98px) {
  [direction='rtl'] .nav-control {
    left: -4.0625rem;
  }
}

[direction='rtl'][data-sidebar-style='overlay']
  .nav-header
  .hamburger.is-active {
  right: 0;
}

[direction='rtl'][data-sidebar-style='compact'][data-layout='horizontal']
  .nav-header
  .brand-logo {
  padding-right: 30px;
}

[direction='rtl'][data-sidebar-style='modern'][data-layout='horizontal']
  .nav-header {
  width: 16rem;
}

[direction='rtl'] .header {
  padding: 0 0.9375rem;
  padding-right: 17.1875rem;
}

@media (max-width: 767.98px) {
  [direction='rtl'] .header {
    padding-right: 3.75rem;
    padding-left: 0;
  }
}

[direction='rtl'] .header .header-content {
  padding-left: 0;
  padding-right: 5.3125rem;
}

@media only screen and (max-width: 575px) {
  [direction='rtl'] .header .header-content {
    padding-right: 4.5rem;
  }
}

[data-sidebar-style='compact'][direction='rtl'] .header .header-content {
  padding-right: 1.875rem;
}

[data-sidebar-style='modern'][direction='rtl'] .header .header-content {
  padding-right: 5.3125rem;
}

[data-sidebar-style='overlay'][direction='rtl'] .header .header-content {
  padding-right: 5.3125rem;
}

[direction='rtl'] .header .nav-control {
  right: 0.4375rem;
  left: auto;
}

[direction='rtl'] .header-left .search_bar .dropdown-menu,
[direction='rtl'] .header-left .search_bar .dropdown-menu.show {
  right: 40px !important;
}

@media only screen and (max-width: 575px) {
  [direction='rtl'] .header-left .search_bar .dropdown-menu,
  [direction='rtl'] .header-left .search_bar .dropdown-menu.show {
    right: -100px !important;
  }
}

[direction='rtl'] .header-left .search_bar .search_icon {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 5rem;
  border-bottom-right-radius: 5rem;
}

@media only screen and (max-width: 575px) {
  [direction='rtl'] .header-left .search_bar .search_icon {
    border-radius: 5rem;
  }
}

[direction='rtl'][data-layout='horizontal'] .header {
  padding: 0;
  padding-right: 16rem;
}

[direction='rtl'][data-layout='horizontal'] .header .header-content {
  padding-right: 1.875rem;
  padding-left: 1.875rem;
}

[direction='rtl'][data-layout='horizontal'][data-sidebar-style='mini'] .header {
  padding-right: 6rem;
}

[direction='rtl'][data-sidebar-style='mini'] .header {
  padding: 0 0.9375rem;
  padding-right: 3.75rem;
}

[direction='rtl'][data-sidebar-style='compact'] .header {
  padding: 0 0.9375rem;
  padding-right: 9.375rem;
}

[direction='rtl'][data-sidebar-style='compact'][data-layout='horizontal']
  .header {
  padding: 0;
  padding-right: 16rem;
}

[direction='rtl'][data-sidebar-style='modern'] .header {
  padding: 0 0.9375rem;
  padding-right: 9.375rem;
}

[direction='rtl'][data-sidebar-style='modern'][data-layout='horizontal']
  .header {
  padding: 0;
  padding-right: 16rem;
}

[direction='rtl'] {
  text-align: right;
}

[direction='rtl'] .deznav {
  text-align: right;
}

[direction='rtl'] .deznav .metismenu ul {
  padding: 0 25px 0 0;
}

[direction='rtl'] .deznav .metismenu ul:after {
  left: auto;
  right: 25px;
}

[direction='rtl'] .deznav .metismenu ul a:before {
  left: auto;
  right: -5px;
}

[direction='rtl'] .deznav .metismenu li > a svg {
  margin-left: 5px;
  margin-right: 0;
}

[data-sidebar-style='compact'][direction='rtl'] .deznav .metismenu li > a svg {
  left: auto;
  margin-left: auto;
  margin-right: auto;
}

[data-sidebar-style='icon-hover'][direction='rtl']
  .deznav
  .metismenu
  li
  > a
  svg {
  margin-left: 0;
}

[direction='rtl'] .deznav .metismenu li.active > .has-arrow:after {
  transform: rotate(45deg) translateY(-50%);
}

[direction='rtl'] .deznav .metismenu .has-arrow:after {
  left: 1.5625rem;
  right: auto;
}

[data-layout='horizontal'][direction='rtl']
  .deznav
  .metismenu
  .has-arrow:after {
  left: 1.125rem;
}

[data-sidebar-style='modern'][direction='rtl']
  .deznav
  .metismenu
  .has-arrow:after {
  -webkit-transform: rotate(-45deg) translateY(-50%);
  transform: rotate(-45deg) translateY(-50%);
}

[direction='rtl'][data-sidebar-style='full'][data-layout='vertical']
  .menu-toggle
  .deznav
  .metismenu
  li
  > ul
  li:hover
  ul {
  right: 11.8125rem;
  left: 0;
}

[direction='rtl'] .list-group {
  padding-left: 0;
}

[direction='rtl'] .form-check-input {
  margin-left: -1.25rem;
  margin-right: inherit;
}

[direction='rtl'] .form-check-inline .form-check-input {
  margin-right: 0;
  margin-left: 10px;
}

[direction='rtl'] .radio input,
[direction='rtl'] .radio-inline,
[direction='rtl'] .checkbox input,
[direction='rtl'] .checkbox-inline input {
  margin-left: 0;
  margin-right: 0;
}

[direction='rtl'] .content-body {
  margin-right: 17.1875rem;
  margin-left: auto;
}

[data-sidebar-style='modern'][direction='rtl'] .content-body {
  margin-right: 9.375rem;
}

[direction='rtl'] .content-body .page-titles {
  text-align: right;
}

[direction='rtl'][data-layout='horizontal'] .content-body {
  margin-right: 0;
}

[direction='rtl'][data-layout='horizontal']
  .deznav
  .metismenu
  li
  li
  .has-arrow:after {
  -webkit-transform: rotate(-4deg) translateY(-50%);
  transform: rotate(-45deg) translateY(-50%);
}

[direction='rtl'][data-sidebar-style='mini']:not([data-layout='horizontal'])
  .content-body {
  margin-right: 3.75rem;
}

[direction='rtl'][data-sidebar-style='compact']:not([data-layout='horizontal'])
  .content-body {
  margin-right: 9.375rem;
}

[direction='rtl'][data-sidebar-style='overlay'] .content-body {
  margin-right: 0;
}

[direction='rtl'] #external-events .external-event:before {
  margin-right: 0;
  margin-left: 0.9rem;
}

[direction='rtl'] .post-input a i {
  margin-left: 15px;
  margin-right: 0;
}

@media only screen and (max-width: 1350px) and (min-width: 1200px) {
  [direction='rtl'] .content-body {
    margin-right: 14.375rem;
  }
}

[direction='rtl'] .sidebar-right {
  left: -15.625rem;
  right: auto;
  box-shadow: 2px 3px 10px 0px rgba(119, 119, 119, 0.1);
}

[direction='rtl'] .sidebar-right.show {
  left: 0;
  right: auto;
}

[direction='rtl'] .sidebar-right .sidebar-right-trigger {
  left: 100%;
  right: auto;
  border-radius: 0 5px 5px 0;
  box-shadow: 5px 3px 5px 0px rgba(119, 119, 119, 0.15);
}

[direction='rtl'] .bootstrap-select .dropdown-toggle .filter-option {
  text-align: right;
}

html[dir='rtl'] [direction='rtl'] .footer {
  padding-right: 17.1875rem;
  padding-left: 0;
}

@media (max-width: 767.98px) {
  html[dir='rtl'] [direction='rtl'] .footer {
    padding-right: 0;
  }
}

html[dir='rtl'] [direction='rtl'][data-sidebar-style='overlay'] .footer {
  padding-right: 0;
}

html[dir='rtl'] [direction='rtl'] .menu-toggle .footer {
  padding-right: 3.75rem;
}

html[dir='rtl'] [direction='rtl'][data-container='boxed'] .footer {
  padding-right: 0;
}

html[dir='rtl']
  [direction='rtl'][data-sidebar-style='mini']:not([data-layout='horizontal'])
  .footer {
  padding-right: 3.75rem;
}

html[dir='rtl']
  [direction='rtl'][data-sidebar-style='compact']:not([data-layout='horizontal'])
  .footer {
  padding-right: 9.375rem;
}

.nav-label {
  margin: 10px 30px 0;
  padding: 1.5625rem 0 10px;
  text-transform: uppercase;
  font-size: 0.75rem;
  letter-spacing: 0.05rem;
  border-top: 1px solid #eaeaea;
  color: #999999;
}

[data-theme-version='dark'] .nav-label {
  border-color: #333a54;
}

.nav-label.first {
  border: 0px;
  margin-top: 0px;
}

.nav-badge {
  position: absolute;
  right: 2.8125rem;
  top: 0.625rem;
}

.content-body {
  margin-left: 21.563rem;
  z-index: 0;
  transition: all 0.2s ease;
}

@media only screen and (min-width: 1200px) and (max-width: 1400px) {
  .content-body {
    margin-left: 17rem;
  }
}
@media screen and (max-width: 768px) {
  .content-body {
    margin-left: 0rem !important;
  }
}
.bell img {
  -webkit-animation: ring 8s 0.7s ease-in-out infinite;
  -webkit-transform-origin: 50% 4px;
  -moz-animation: ring 8s 0.7s ease-in-out infinite;
  -moz-transform-origin: 50% 4px;
  animation: ring 8s 0.7s ease-in-out infinite;
}

@-webkit-keyframes ring {
  0% {
    -webkit-transform: rotateZ(0);
  }
  1% {
    -webkit-transform: rotateZ(30deg);
  }
  3% {
    -webkit-transform: rotateZ(-28deg);
  }
  5% {
    -webkit-transform: rotateZ(34deg);
  }
  7% {
    -webkit-transform: rotateZ(-32deg);
  }
  9% {
    -webkit-transform: rotateZ(30deg);
  }
  11% {
    -webkit-transform: rotateZ(-28deg);
  }
  13% {
    -webkit-transform: rotateZ(26deg);
  }
  15% {
    -webkit-transform: rotateZ(-24deg);
  }
  17% {
    -webkit-transform: rotateZ(22deg);
  }
  19% {
    -webkit-transform: rotateZ(-20deg);
  }
  21% {
    -webkit-transform: rotateZ(18deg);
  }
  23% {
    -webkit-transform: rotateZ(-16deg);
  }
  25% {
    -webkit-transform: rotateZ(14deg);
  }
  27% {
    -webkit-transform: rotateZ(-12deg);
  }
  29% {
    -webkit-transform: rotateZ(10deg);
  }
  31% {
    -webkit-transform: rotateZ(-8deg);
  }
  33% {
    -webkit-transform: rotateZ(6deg);
  }
  35% {
    -webkit-transform: rotateZ(-4deg);
  }
  37% {
    -webkit-transform: rotateZ(2deg);
  }
  39% {
    -webkit-transform: rotateZ(-1deg);
  }
  41% {
    -webkit-transform: rotateZ(1deg);
  }
  43% {
    -webkit-transform: rotateZ(0);
  }
  100% {
    -webkit-transform: rotateZ(0);
  }
}

@-moz-keyframes ring {
  0% {
    -moz-transform: rotate(0);
  }
  1% {
    -moz-transform: rotate(30deg);
  }
  3% {
    -moz-transform: rotate(-28deg);
  }
  5% {
    -moz-transform: rotate(34deg);
  }
  7% {
    -moz-transform: rotate(-32deg);
  }
  9% {
    -moz-transform: rotate(30deg);
  }
  11% {
    -moz-transform: rotate(-28deg);
  }
  13% {
    -moz-transform: rotate(26deg);
  }
  15% {
    -moz-transform: rotate(-24deg);
  }
  17% {
    -moz-transform: rotate(22deg);
  }
  19% {
    -moz-transform: rotate(-20deg);
  }
  21% {
    -moz-transform: rotate(18deg);
  }
  23% {
    -moz-transform: rotate(-16deg);
  }
  25% {
    -moz-transform: rotate(14deg);
  }
  27% {
    -moz-transform: rotate(-12deg);
  }
  29% {
    -moz-transform: rotate(10deg);
  }
  31% {
    -moz-transform: rotate(-8deg);
  }
  33% {
    -moz-transform: rotate(6deg);
  }
  35% {
    -moz-transform: rotate(-4deg);
  }
  37% {
    -moz-transform: rotate(2deg);
  }
  39% {
    -moz-transform: rotate(-1deg);
  }
  41% {
    -moz-transform: rotate(1deg);
  }
  43% {
    -moz-transform: rotate(0);
  }
  100% {
    -moz-transform: rotate(0);
  }
}

@keyframes ring {
  0% {
    transform: rotate(0);
  }
  1% {
    transform: rotate(30deg);
  }
  3% {
    transform: rotate(-28deg);
  }
  5% {
    transform: rotate(34deg);
  }
  7% {
    transform: rotate(-32deg);
  }
  9% {
    transform: rotate(30deg);
  }
  11% {
    transform: rotate(-28deg);
  }
  13% {
    transform: rotate(26deg);
  }
  15% {
    transform: rotate(-24deg);
  }
  17% {
    transform: rotate(22deg);
  }
  19% {
    transform: rotate(-20deg);
  }
  21% {
    transform: rotate(18deg);
  }
  23% {
    transform: rotate(-16deg);
  }
  25% {
    transform: rotate(14deg);
  }
  27% {
    transform: rotate(-12deg);
  }
  29% {
    transform: rotate(10deg);
  }
  31% {
    transform: rotate(-8deg);
  }
  33% {
    transform: rotate(6deg);
  }
  35% {
    transform: rotate(-4deg);
  }
  37% {
    transform: rotate(2deg);
  }
  39% {
    transform: rotate(-1deg);
  }
  41% {
    transform: rotate(1deg);
  }
  43% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(0);
  }
}

.deznav {
  width: 21.563rem;
  padding-bottom: 0;
  height: 100%;
  position: absolute;
  top: 0;
  padding-top: 120px;
  z-index: 4;
  background-color: #fff;
  box-shadow: 0px 0px 10px rgba(120, 130, 140, 0.13);
  transition: all 0.2s ease;
}

@media only screen and (max-width: 1199px) {
  .deznav {
    padding-top: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .deznav {
    width: 18rem;
  }
}

.deznav .deznav-scroll {
  position: relative;
  height: 100%;
}

@media only screen and (min-width: 1200px) and (max-width: 1400px) {
  .deznav {
    width: 17rem;
  }
}

.deznav ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

.deznav .metismenu {
  display: flex;
  flex-direction: column;
  padding-top: 15px;
}

@media only screen and (max-width: 767px) {
  .deznav .metismenu {
    padding-top: 0;
  }
}

.deznav .metismenu.fixed {
  position: fixed;
  top: 0;
  width: 100%;
  left: 0;
}

.deznav .metismenu > li {
  display: flex;
  flex-direction: column;
}

.deznav .metismenu > li a > i {
  font-size: 1.4rem;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  top: 0;
  height: auto;
  width: auto;
  border-radius: 1.25rem;
  line-height: 1;
  text-align: center;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  margin-right: 15px;
  width: 25px;
}

[data-sidebar-style='compact'] .deznav .metismenu > li a > i {
  display: block;
  padding: 0;
}

@media only screen and (max-width: 1300px) {
  .deznav .metismenu > li a > i {
    height: auto;
    width: auto;
    margin-right: 0;
    line-height: 1;
    padding: 0;
  }
}

@media only screen and (max-width: 767px) {
  .deznav .metismenu > li a > i {
    font-size: 1.25rem;
    padding: 0 0.75rem 0 0;
    margin-right: 0px;
  }
}

.deznav .metismenu > li > a {
  font-weight: 500;
  display: inline-block;
  font-size: 15px;
}

.deznav .metismenu > li > a svg {
  max-width: 24px;
  max-height: 24px;
  height: 100%;
  margin-right: 5px;
  margin-top: -3px;
  color: #0b2a97;
}

.deznav .metismenu > li > a g [fill] {
  fill: #0b2a97;
}

.deznav .metismenu > li:hover > a,
.deznav .metismenu > li:focus > a {
  color: #0b2a97;
}

.deznav .metismenu > li:hover > a g [fill],
.deznav .metismenu > li:focus > a g [fill] {
  fill: #0b2a97;
}

.deznav .metismenu > li.mm-active > a {
  color: #0b2a97;
}

.deznav .metismenu > li.mm-active > a g [fill] {
  fill: #0b2a97;
}

.deznav .metismenu li {
  position: relative;
}

.deznav .metismenu ul {
  transition: all 0.2s ease-in-out;
  position: relative;
  z-index: 1;
  padding: 5px 0;
}

.deznav .metismenu ul a {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  position: relative;
  font-size: 16px;
  padding-left: 3.9rem;
}

@media only screen and (max-width: 1400px) {
  .btn-xxs {
    padding: 7px 10px !important;
  }
  .deznav .metismenu ul a {
    padding-left: 3.9rem;
    font-size: 14px;
  }
}

@media only screen and (max-width: 767px) {
  .deznav .metismenu ul a {
    padding-left: 3.5rem;
  }
}

.deznav .metismenu ul a:hover,
.deznav .metismenu ul a:focus,
.deznav .metismenu ul a.mm-active {
  text-decoration: none;
  color: #0b2a97;
}

.deznav .metismenu a {
  position: relative;
  display: block;
  padding: 20px;
  outline-width: 0;
  color: #7e7e7e;
  text-decoration: none;
}

@media only screen and (max-width: 767px) {
  .deznav .metismenu a {
    padding: 0.625rem 1.25rem;
  }
}

.deznav .metismenu .has-arrow:after {
  width: 0.5rem;
  height: 0.5rem;
  right: 1.875rem;
  top: 48%;
  border-color: inherit;
  -webkit-transform: rotate(-225deg) translateY(-50%);
  transform: rotate(-225deg) translateY(-50%);
}

.deznav .metismenu .has-arrow[aria-expanded='true']:after,
.deznav .metismenu .mm-active > .has-arrow:after {
  -webkit-transform: rotate(-135deg) translateY(-50%);
  transform: rotate(-135deg) translateY(-50%);
}

@media only screen and (max-width: 1199px) {
  .nav-header {
    width: 5rem;
  }
}

@media (max-width: 767px) {
  .brand-title {
    display: none;
  }
  .footer {
    padding-left: 0;
  }
  .deznav {
    left: 0;
    top: 5rem;
    padding-top: 1rem;
  }
}

[data-sidebar-style='full'][data-layout='vertical']
  .menu-toggle
  .deznav
  .metismenu
  > li.mega-menu
  > ul.collapse:not(.in),
[data-sidebar-style='mini'][data-layout='vertical']
  .deznav
  .metismenu
  > li.mega-menu
  > ul.collapse:not(.in) {
  height: 252px !important;
}

[data-sidebar-style='full'][data-layout='vertical']
  .menu-toggle
  .deznav
  .metismenu
  > li.mega-menu:hover
  > a,
[data-sidebar-style='mini'][data-layout='vertical']
  .deznav
  .metismenu
  > li.mega-menu:hover
  > a {
  width: calc(70vw + 3.75rem);
}

[data-sidebar-style='full'][data-layout='vertical']
  .menu-toggle
  .deznav
  .metismenu
  > li.mega-menu:hover
  > ul,
[data-sidebar-style='mini'][data-layout='vertical']
  .deznav
  .metismenu
  > li.mega-menu:hover
  > ul {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  max-height: 13.75rem;
  width: 70vw;
}

[data-sidebar-style='full'][data-layout='vertical']
  .menu-toggle
  .deznav
  .metismenu
  > li.mega-menu:hover
  > ul
  ul
  a,
[data-sidebar-style='mini'][data-layout='vertical']
  .deznav
  .metismenu
  > li.mega-menu:hover
  > ul
  ul
  a {
  width: 101%;
}

[data-sidebar-style='full'][data-layout='vertical']
  .menu-toggle
  .deznav
  .metismenu
  > li.mega-menu-xl:hover
  > a,
[data-sidebar-style='mini'][data-layout='vertical']
  .deznav
  .metismenu
  > li.mega-menu-xl:hover
  > a {
  width: calc(70vw + 3rem);
}

[data-sidebar-style='full'][data-layout='vertical']
  .menu-toggle
  .deznav
  .metismenu
  > li.mega-menu-xl:hover
  > ul,
[data-sidebar-style='mini'][data-layout='vertical']
  .deznav
  .metismenu
  > li.mega-menu-xl:hover
  > ul {
  max-height: 200px;
  width: 70vw;
}

[data-sidebar-style='full'][data-layout='vertical']
  .menu-toggle
  .deznav
  .metismenu
  > li.mega-menu-xl:hover
  > ul
  ul
  a,
[data-sidebar-style='mini'][data-layout='vertical']
  .deznav
  .metismenu
  > li.mega-menu-xl:hover
  > ul
  ul
  a {
  width: 101%;
}

[data-sidebar-style='full'][data-layout='vertical']
  .menu-toggle
  .deznav
  .metismenu
  > li.mega-menu-lg:hover
  > a,
[data-sidebar-style='mini'][data-layout='vertical']
  .deznav
  .metismenu
  > li.mega-menu-lg:hover
  > a {
  width: calc(55vw + 3rem);
}

[data-sidebar-style='full'][data-layout='vertical']
  .menu-toggle
  .deznav
  .metismenu
  > li.mega-menu-lg:hover
  > ul,
[data-sidebar-style='mini'][data-layout='vertical']
  .deznav
  .metismenu
  > li.mega-menu-lg:hover
  > ul {
  max-height: 200px;
  width: 55vw;
}

[data-sidebar-style='full'][data-layout='vertical']
  .menu-toggle
  .deznav
  .metismenu
  > li.mega-menu-lg:hover
  > ul
  ul
  a,
[data-sidebar-style='mini'][data-layout='vertical']
  .deznav
  .metismenu
  > li.mega-menu-lg:hover
  > ul
  ul
  a {
  width: 101%;
}

[data-sidebar-style='full'][data-layout='vertical']
  .menu-toggle
  .deznav
  .metismenu
  > li.mega-menu-md:hover
  > a,
[data-sidebar-style='mini'][data-layout='vertical']
  .deznav
  .metismenu
  > li.mega-menu-md:hover
  > a {
  width: calc(45vw + 3);
}

[data-sidebar-style='full'][data-layout='vertical']
  .menu-toggle
  .deznav
  .metismenu
  > li.mega-menu-md:hover
  > ul,
[data-sidebar-style='mini'][data-layout='vertical']
  .deznav
  .metismenu
  > li.mega-menu-md:hover
  > ul {
  max-height: 18.75rem;
  width: 45vw;
}

[data-sidebar-style='full'][data-layout='vertical']
  .menu-toggle
  .deznav
  .metismenu
  > li.mega-menu-md:hover
  > ul
  ul
  a,
[data-sidebar-style='mini'][data-layout='vertical']
  .deznav
  .metismenu
  > li.mega-menu-md:hover
  > ul
  ul
  a {
  width: 101%;
}

[data-sidebar-style='full'][data-layout='vertical']
  .menu-toggle
  .deznav
  .metismenu
  > li.mega-menu-sm:hover
  > a,
[data-sidebar-style='mini'][data-layout='vertical']
  .deznav
  .metismenu
  > li.mega-menu-sm:hover
  > a {
  width: calc(30vw + 3);
}

[data-sidebar-style='full'][data-layout='vertical']
  .menu-toggle
  .deznav
  .metismenu
  > li.mega-menu-sm:hover
  > ul,
[data-sidebar-style='mini'][data-layout='vertical']
  .deznav
  .metismenu
  > li.mega-menu-sm:hover
  > ul {
  max-height: 18.125rem;
  width: 30vw;
}

[data-sidebar-style='full'][data-layout='vertical']
  .menu-toggle
  .deznav
  .metismenu
  > li.mega-menu-sm:hover
  > ul
  ul
  a,
[data-sidebar-style='mini'][data-layout='vertical']
  .deznav
  .metismenu
  > li.mega-menu-sm:hover
  > ul
  ul
  a {
  width: 101%;
}

[data-sidebar-style='mini'][data-layout='vertical'][data-container='boxed']
  .deznav
  .metismenu
  > li.mega-menu:hover
  > a {
  width: calc(60vw + 3.75rem);
}

[data-sidebar-style='mini'][data-layout='vertical'][data-container='boxed']
  .deznav
  .metismenu
  > li.mega-menu:hover
  > ul {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  max-height: 25rem;
  width: 60vw;
}

[data-sidebar-style='mini'][data-layout='vertical'][data-container='boxed']
  .deznav
  .metismenu
  > li.mega-menu:hover
  > ul
  ul
  a {
  width: 101%;
}

[data-sidebar-style='mini'][data-layout='vertical'][data-container='boxed']
  .deznav
  .metismenu
  > li.mega-menu-xl:hover
  > a {
  width: calc(60vw + 3.75rem);
}

[data-sidebar-style='mini'][data-layout='vertical'][data-container='boxed']
  .deznav
  .metismenu
  > li.mega-menu-xl:hover
  > ul {
  max-height: 25.625rem;
  width: 60vw;
}

[data-sidebar-style='mini'][data-layout='vertical'][data-container='boxed']
  .deznav
  .metismenu
  > li.mega-menu-lg:hover
  > a {
  width: calc(50vw + 3.75rem);
}

[data-sidebar-style='mini'][data-layout='vertical'][data-container='boxed']
  .deznav
  .metismenu
  > li.mega-menu-lg:hover
  > ul {
  max-height: 16.25rem;
  width: 50vw;
}

[data-sidebar-style='mini'][data-layout='vertical'][data-container='boxed']
  .deznav
  .metismenu
  > li.mega-menu-md:hover
  > a {
  width: calc(40vw + 3.75rem);
}

[data-sidebar-style='mini'][data-layout='vertical'][data-container='boxed']
  .deznav
  .metismenu
  > li.mega-menu-md:hover
  > ul {
  max-height: 18.75rem;
  width: 40vw;
}

[data-sidebar-style='mini'][data-layout='vertical'][data-container='boxed']
  .deznav
  .metismenu
  > li.mega-menu-sm:hover
  > a {
  width: calc(22vw + 3.75rem);
}

[data-sidebar-style='mini'][data-layout='vertical'][data-container='boxed']
  .deznav
  .metismenu
  > li.mega-menu-sm:hover
  > ul {
  max-height: 18.125rem;
  width: 22vw;
}

[data-layout='horizontal'] .deznav .metismenu > li.mega-menu:not(:last-child) {
  position: static;
}

[data-layout='horizontal'] .deznav .metismenu > li.mega-menu ul {
  left: 0;
  right: 0;
}

[data-theme-version='dark'][data-layout='horizontal']
  .deznav
  .metismenu
  > li.mega-menu
  ul
  li {
  border-color: #333a54;
}

[data-sibebarbg='color_2'][data-layout='horizontal']
  .deznav
  .metismenu
  > li.mega-menu
  ul
  li {
  border-color: #03090f;
}

[data-sibebarbg='color_3'][data-layout='horizontal']
  .deznav
  .metismenu
  > li.mega-menu
  ul
  li {
  border-color: #041038;
}

[data-sibebarbg='color_4'][data-layout='horizontal']
  .deznav
  .metismenu
  > li.mega-menu
  ul
  li {
  border-color: #22134e;
}

[data-sibebarbg='color_5'][data-layout='horizontal']
  .deznav
  .metismenu
  > li.mega-menu
  ul
  li {
  border-color: #711717;
}

[data-sibebarbg='color_6'][data-layout='horizontal']
  .deznav
  .metismenu
  > li.mega-menu
  ul
  li {
  border-color: #121843;
}

[data-sibebarbg='color_7'][data-layout='horizontal']
  .deznav
  .metismenu
  > li.mega-menu
  ul
  li {
  border-color: #3511d0;
}

[data-sibebarbg='color_8'][data-layout='horizontal']
  .deznav
  .metismenu
  > li.mega-menu
  ul
  li {
  border-color: #1161aa;
}

[data-sibebarbg='color_9'][data-layout='horizontal']
  .deznav
  .metismenu
  > li.mega-menu
  ul
  li {
  border-color: #002629;
}

[data-sibebarbg='color_10'][data-layout='horizontal']
  .deznav
  .metismenu
  > li.mega-menu
  ul
  li {
  border-color: #af5b00;
}

[data-layout='horizontal'] .deznav .metismenu > li.mega-menu ul li a {
  transition: all 0.4s ease-in-out;
}

[data-layout='horizontal'] .deznav .metismenu > li.mega-menu ul li a:hover {
  border-radius: 0.25rem;
}

[data-layout='horizontal'] .deznav .metismenu > li.mega-menu:hover > ul {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  max-height: 13.75rem;
  width: 70vw;
  z-index: 99;
}

[data-layout='horizontal'] .deznav .metismenu > li.mega-menu:hover > ul::after {
  content: '';
  height: 100%;
  width: 1px;
  position: absolute;
  background-color: #fff;
  right: 2.8125rem;
  top: 0;
}

[data-theme-version='dark'][data-layout='horizontal']
  .deznav
  .metismenu
  > li.mega-menu:hover
  > ul::after {
  background-color: #1d2544;
}

[data-sibebarbg='color_2'][data-layout='horizontal']
  .deznav
  .metismenu
  > li.mega-menu:hover
  > ul::after {
  background-color: #0c223a;
}

[data-sibebarbg='color_3'][data-layout='horizontal']
  .deznav
  .metismenu
  > li.mega-menu:hover
  > ul::after {
  background-color: #081d67;
}

[data-sibebarbg='color_4'][data-layout='horizontal']
  .deznav
  .metismenu
  > li.mega-menu:hover
  > ul::after {
  background-color: #331d77;
}

[data-sibebarbg='color_5'][data-layout='horizontal']
  .deznav
  .metismenu
  > li.mega-menu:hover
  > ul::after {
  background-color: #9c1f1f;
}

[data-sibebarbg='color_6'][data-layout='horizontal']
  .deznav
  .metismenu
  > li.mega-menu:hover
  > ul::after {
  background-color: #1d276b;
}

[data-sibebarbg='color_7'][data-layout='horizontal']
  .deznav
  .metismenu
  > li.mega-menu:hover
  > ul::after {
  background-color: #4c27ed;
}

[data-sibebarbg='color_8'][data-layout='horizontal']
  .deznav
  .metismenu
  > li.mega-menu:hover
  > ul::after {
  background-color: #167cd8;
}

[data-sibebarbg='color_9'][data-layout='horizontal']
  .deznav
  .metismenu
  > li.mega-menu:hover
  > ul::after {
  background-color: #00545c;
}

[data-sibebarbg='color_10'][data-layout='horizontal']
  .deznav
  .metismenu
  > li.mega-menu:hover
  > ul::after {
  background-color: #e27500;
}

[data-layout='horizontal'] .deznav .metismenu > li.mega-menu:hover > ul ul a {
  width: 101%;
}

[data-layout='horizontal'] .deznav .metismenu > li.mega-menu-xl:hover > ul {
  max-height: 210px;
  width: 70vw;
}

[data-layout='horizontal'] .deznav .metismenu > li.mega-menu-lg:hover > ul {
  max-height: 210px;
  width: 700px;
  height: 210px !important;
}

@media only screen and (min-width: 1200px) and (max-width: 1500px) {
  [data-layout='horizontal'] .deznav .metismenu > li.mega-menu-lg:hover > ul {
    width: 700px;
  }
}

[data-layout='horizontal'] .deznav .metismenu > li.mega-menu-md:hover > ul {
  max-height: 20rem;
  width: 54vw;
}

@media only screen and (min-width: 1200px) and (max-width: 1500px) {
  [data-layout='horizontal'] .deznav .metismenu > li.mega-menu-md:hover > ul {
    width: 60vw;
  }
}

[data-layout='horizontal'] .deznav .metismenu > li.mega-menu-sm:hover > ul {
  max-height: 20rem;
  width: 25vw;
}

@media only screen and (min-width: 1200px) and (max-width: 1500px) {
  [data-layout='horizontal'] .deznav .metismenu > li.mega-menu-sm:hover > ul {
    width: 35vw;
  }
}

[data-layout='horizontal'][data-container='boxed']
  .deznav
  .metismenu
  > li.mega-menu:hover
  > ul {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}

[data-layout='horizontal'][data-container='boxed']
  .deznav
  .metismenu
  > li.mega-menu-xl:hover
  > ul {
  max-height: 21.875rem;
  width: 100%;
}

[data-layout='horizontal'][data-container='boxed']
  .deznav
  .metismenu
  > li.mega-menu-lg:hover
  > ul {
  max-height: 21.875rem;
  width: 55vw;
}

[data-layout='horizontal'][data-container='boxed']
  .deznav
  .metismenu
  > li.mega-menu-md:hover
  > ul {
  max-height: 18.75rem;
  width: 45vw;
}

[data-layout='horizontal'][data-container='boxed']
  .deznav
  .metismenu
  > li.mega-menu-sm:hover
  > ul {
  max-height: 18.125rem;
  width: 50vw;
}

/*$primary  : #7356f1 !default;*/

/*
0 - 600: Phone
600 - 900: Tablet portrait
900 - 1200: Tablet landscape
1200 - 1800: Normal styles
1800+ : Big Desktop
1em = 16px
The smaller device rules always should write below the bigger device rules
Fixing Order => Base + Typography >> General Layout + Grid >> Page Layout + Component
*/

[data-sidebar-style='full'][data-layout='vertical'] .deznav .metismenu > li {
  padding: 0 0 0 40px;
}

[data-sidebar-style='full'][data-layout='vertical']
  .deznav
  .metismenu
  > li
  > a {
  font-size: 16px;
  padding: 20px 20px;
  border-top-left-radius: 1.25rem;
  border-bottom-left-radius: 1.25rem;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  position: relative;
}

@media only screen and (max-width: 1400px) {
  [data-sidebar-style='full'][data-layout='vertical']
    .deznav
    .metismenu
    > li
    > a {
    padding: 20px 20px;
    font-size: 16px;
  }
}

[data-sidebar-style='full'][data-layout='vertical']
  .deznav
  .metismenu
  > li
  > a:before {
  height: 100%;
  position: absolute;
  border-radius: 4px;
  width: 6px;
  background: #0b2a97;
  top: 0;
  right: 0;
  opacity: 0;
  content: '';
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}

[data-sidebar-style='full'][data-layout='vertical']
  .deznav
  .metismenu
  > li.mm-active
  > a {
  color: #0b2a97;
  background: rgba(11, 42, 151, 0.1);
}

[data-sidebar-style='full'][data-layout='vertical']
  .deznav
  .metismenu
  > li.mm-active
  > a
  i {
  color: #0b2a97;
}

[data-sidebar-style='full'][data-layout='vertical']
  .deznav
  .metismenu
  > li.mm-active
  > a:before {
  opacity: 1;
}

[data-sidebar-style='full'][data-layout='vertical']
  .deznav
  .metismenu
  > li
  .has-arrow:after {
  right: 2.5rem;
}

@media only screen and (max-width: 1400px) {
  [data-sidebar-style='full'][data-layout='vertical']
    .deznav
    .metismenu
    > li
    .has-arrow:after {
    right: 1.5rem;
  }
}

@media only screen and (max-width: 1400px) {
  [data-sidebar-style='full'][data-layout='vertical'] .deznav .metismenu > li {
    padding: 0 0 0 20px;
  }
}

[data-sidebar-style='full'][data-layout='vertical'] .menu-toggle .nav-header {
  width: 5rem;
  z-index: 999;
}

[data-sidebar-style='full'][data-layout='vertical']
  .menu-toggle
  .nav-header
  .brand-logo {
  padding-left: 0;
  padding-right: 0;
  justify-content: center;
}

[data-sidebar-style='full'][data-layout='vertical']
  .menu-toggle
  .nav-header
  .nav-control
  .hamburger
  .line {
  background-color: #0b2a97 !important;
}

[data-sidebar-style='full'][data-layout='vertical'] .menu-toggle .header {
  padding-left: 6rem;
  width: 100%;
}

[direction='rtl'][data-sidebar-style='full'][data-layout='vertical']
  .menu-toggle
  .header {
  padding: 0 0.9375rem;
  padding-right: 5rem;
}

[data-sidebar-style='full'][data-layout='vertical'] .menu-toggle .deznav {
  width: 5rem;
  overflow: visible;
  position: absolute;
}

[data-sidebar-style='full'][data-layout='vertical']
  .menu-toggle
  .deznav
  .nav-text {
  display: none;
}

[data-sidebar-style='full'][data-layout='vertical']
  .menu-toggle
  .deznav
  .slimScrollDiv,
[data-sidebar-style='full'][data-layout='vertical']
  .menu-toggle
  .deznav
  .deznav-scroll {
  overflow: visible !important;
}

[data-sidebar-style='full'][data-layout='vertical']
  .menu-toggle
  .deznav
  .metismenu
  li {
  position: relative;
}

[data-sidebar-style='full'][data-layout='vertical']
  .menu-toggle
  .deznav
  .metismenu
  li
  a {
  padding: 13px;
  border-radius: 1.25rem;
  margin: 2px 0;
}

[data-sidebar-style='full'][data-layout='vertical']
  .menu-toggle
  .deznav
  .metismenu
  li
  a
  svg {
  max-width: 24px;
  max-height: 24px;
  margin-right: 0;
}

[data-sidebar-style='full'][data-layout='vertical']
  .menu-toggle
  .deznav
  .metismenu
  li
  a:before {
  content: none;
}

[data-sidebar-style='full'][data-layout='vertical']
  .menu-toggle
  .deznav
  .metismenu
  li
  > ul {
  position: absolute;
  left: 5rem;
  top: 0;
  width: 12rem;
  z-index: 1001;
  display: none;
  padding-left: 1px;
  height: auto !important;
  box-shadow: 0px 0px 40px 0px rgba(82, 63, 105, 0.1);
  border-radius: 6px;
  margin-left: 0;
  border: 0;
  background: #fff;
}

[data-sidebar-style='full'][data-layout='vertical']
  .menu-toggle
  .deznav
  .metismenu
  li
  > ul:after {
  content: '';
  position: absolute;
  background: inherit;
  width: 10px;
  height: 10px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  left: -5px;
  top: 20px;
}

[direction='rtl'][data-sidebar-style='full'][data-layout='vertical']
  .menu-toggle
  .deznav
  .metismenu
  li
  > ul {
  left: auto;
  right: 3rem;
}

[data-sidebar-style='full'][data-layout='vertical']
  .menu-toggle
  .deznav
  .metismenu
  li
  > ul
  li:hover
  ul {
  left: 11.8125rem;
  top: 0;
}

[data-sidebar-style='full'][data-layout='vertical']
  .menu-toggle
  .deznav
  .metismenu
  li
  > ul
  li:hover
  ul:after {
  content: none;
}

[data-sidebar-style='full'][data-layout='vertical']
  .menu-toggle
  .deznav
  .metismenu
  li:hover
  > ul {
  display: block;
  height: auto;
  overflow: visible;
}

[data-sidebar-style='full'][data-layout='vertical']
  .menu-toggle
  .deznav
  .metismenu
  > li {
  transition: all 0.4s ease-in-out;
  padding: 0 13px;
}

[data-sidebar-style='full'][data-layout='vertical']
  .menu-toggle
  .deznav
  .metismenu
  > li
  > a {
  text-align: center;
}

[data-sidebar-style='full'][data-layout='vertical']
  .menu-toggle
  .deznav
  .metismenu
  > li
  > a.has-arrow:after {
  display: none;
}

[data-sidebar-style='full'][data-layout='vertical']
  .menu-toggle
  .deznav
  .metismenu
  > li
  > a
  i {
  padding: 0;
  width: auto;
  height: auto;
  background: transparent;
  margin: 0;
  line-height: 1;
}

[data-sidebar-style='full'][data-layout='vertical']
  .menu-toggle
  .deznav
  .metismenu
  > li.mm-active
  > a {
  background: #0b2a97;
  border-radius: 1.25rem;
  color: #fff;
}

[data-sidebar-style='full'][data-layout='vertical']
  .menu-toggle
  .deznav
  .metismenu
  > li.mm-active
  > a
  i {
  color: #fff;
  padding: 0;
}

[data-sidebar-style='full'][data-layout='vertical']
  .menu-toggle
  .deznav
  .metismenu
  > li:hover:nth-last-child(-n + 1)
  > ul {
  bottom: 0;
  top: auto;
}

[data-sidebar-style='full'][data-layout='vertical']
  .menu-toggle
  .deznav
  .metismenu
  > li:hover
  > a {
  border-radius: 1.25rem;
  background: #0b2a97;
  color: #fff;
}

[data-theme-version='dark'][data-sidebar-style='full'][data-layout='vertical']
  .menu-toggle
  .deznav
  .metismenu
  > li:hover
  > a {
  background: #1e2746;
}

[data-sidebar-style='full'][data-layout='vertical']
  .menu-toggle
  .deznav
  .metismenu
  > li:hover
  > a
  i {
  color: #fff;
}

[data-sidebar-style='full'][data-layout='vertical']
  .menu-toggle
  .deznav
  .metismenu
  > li:hover
  > ul {
  height: auto !important;
  padding: 10px 0;
}

[data-sidebar-style='full'][data-layout='vertical']
  .menu-toggle
  .deznav
  .metismenu
  > li:hover
  > ul
  a {
  padding: 6px 20px 6px 20px;
  margin-left: -0.1rem;
}

[data-sidebar-style='full'][data-layout='vertical']
  .menu-toggle
  .deznav
  .metismenu
  > li:hover
  > ul
  ul {
  padding: 10px 0;
}

[data-sidebar-style='full'][data-layout='vertical']
  .menu-toggle
  .deznav
  .metismenu
  > li:hover
  > ul
  ul
  a {
  padding: 6px 20px 6px 20px;
  margin-left: -0.1rem;
}

[data-sidebar-style='full'][data-layout='vertical']
  .menu-toggle
  .deznav
  .metismenu
  .nav-label,
[data-sidebar-style='full'][data-layout='vertical']
  .menu-toggle
  .deznav
  .metismenu
  .nav-badge {
  display: none;
}

[data-sidebar-style='full'][data-layout='vertical'] .menu-toggle .content-body {
  margin-left: 5rem;
}

[direction='rtl'][data-sidebar-style='full'][data-layout='vertical']
  .menu-toggle
  .content-body {
  margin-right: 5rem;
  margin-left: auto;
  border: 0;
}

[data-sidebar-style='full'][data-layout='vertical'] .menu-toggle + .footer {
  padding-left: 5rem;
}

[direction='rtl'][data-sidebar-style='full'][data-layout='vertical']
  .menu-toggle
  + .footer {
  padding-left: 0;
  padding-right: 5rem;
}

[data-sidebar-style='full'][data-layout='horizontal'] .deznav .metismenu {
  padding: 0 30px;
}

[data-sidebar-style='full'][data-layout='horizontal'] .header .header-content {
  padding-left: 1.875rem;
}

/*
0 - 600: Phone
600 - 900: Tablet portrait
900 - 1200: Tablet landscape
1200 - 1800: Normal styles
1800+ : Big Desktop
1em = 16px
The smaller device rules always should write below the bigger device rules
Fixing Order => Base + Typography >> General Layout + Grid >> Page Layout + Component
*/

/*$primary  : #7356f1 !default;*/

@media only screen and (min-width: 768px) {
  [data-sidebar-style='mini'] .nav-header {
    width: 5rem;
  }
  [data-sidebar-style='mini'] .nav-header .nav-control {
    z-index: -1;
  }
  [data-sidebar-style='mini'] .nav-header .nav-control .hamburger {
    left: 5rem !important;
  }
  [data-sidebar-style='mini'] .nav-header .nav-control .hamburger .line {
    background-color: #b1b1b1 !important;
  }
  [data-sidebar-style='mini'] .nav-header .brand-title {
    display: none;
  }
  [data-sidebar-style='mini'] .nav-header .hamburger {
    display: none;
  }
  [data-sidebar-style='mini'] .header .header-content {
    padding-left: 1.875rem;
  }
  [direction='rtl'][data-sidebar-style='mini'] .header .header-content {
    padding-right: 1.875rem;
  }
  [data-sidebar-style='mini'] .deznav {
    width: 5rem;
    overflow: auto;
  }
  [data-sidebar-style='mini'] .deznav .slimScrollDiv,
  [data-sidebar-style='mini'] .deznav .deznav-scroll {
    overflow: visible !important;
  }
  [data-sidebar-style='mini'] .deznav .nav-user {
    padding: 11px;
  }
  [data-sidebar-style='mini'] .deznav .nav-user .media-body {
    display: none;
  }
  [data-sidebar-style='mini'] .deznav .metismenu li a {
    padding: 0.813rem 0.875rem;
  }
  [data-sidebar-style='mini'] .deznav .metismenu li a svg {
    margin-right: 0;
  }
  [data-sidebar-style='mini'] .deznav .metismenu li > ul {
    position: absolute;
    left: 5rem;
    top: 2.9375rem;
    width: 11.875rem;
    z-index: 1001;
    display: none;
    padding-left: 1px;
    box-shadow: 0px 0px 40px 0px rgba(82, 63, 105, 0.1);
    height: auto !important;
    border-radius: 6px;
    background: #fff;
  }
  [direction='rtl']:not([data-layout='horizontal'])[data-sidebar-style='mini']
    .deznav
    .metismenu
    li
    > ul {
    left: auto;
    right: 5rem;
    box-shadow: -6px 6px 10px rgba(0, 0, 0, 0.15);
  }
  [data-sidebar-style='mini'] .deznav .metismenu > li {
    padding: 2px 15px;
  }
  [data-sidebar-style='mini'] .deznav .metismenu > li > a.has-arrow:after {
    display: none;
  }
  [data-sidebar-style='mini'] .deznav .metismenu .nav-label,
  [data-sidebar-style='mini'] .deznav .metismenu .nav-badge {
    display: none;
  }
  [data-sidebar-style='mini'] .content-body {
    margin-left: 5rem;
  }
  [data-sidebar-style='mini'] .footer {
    padding-left: 5rem;
  }
  [data-sidebar-style='mini'][data-layout='vertical']
    .deznav
    .metismenu
    li:hover
    > ul {
    display: block;
  }
  [data-sidebar-style='mini'][data-layout='vertical']
    .deznav
    .metismenu
    > li:nth-last-child(-n + 1)
    > ul {
    bottom: 0;
    top: auto !important;
  }
  [data-sidebar-style='mini'][data-layout='vertical']
    .deznav
    .metismenu
    > li:nth-last-child(-n + 1)
    > ul:after {
    top: auto;
    bottom: 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1199px) {
  [data-sidebar-style='mini'][data-layout='vertical']
    .deznav
    .metismenu
    > li:nth-last-child(-n + 1)
    > ul {
    bottom: 0;
    top: auto !important;
  }
}

@media only screen and (min-width: 768px) {
  [data-sidebar-style='mini'][data-layout='vertical']
    .deznav
    .metismenu
    > li
    > ul {
    overflow: visible;
  }
  [data-sidebar-style='mini'][data-layout='vertical']
    .deznav
    .metismenu
    > li
    > ul:after {
    content: none;
  }
  [data-sidebar-style='mini'][data-layout='vertical']
    .deznav
    .metismenu
    > li
    > ul
    li:hover
    ul {
    padding: 10px 0;
    width: 13rem;
    left: 13rem;
    top: -10px;
    border: 0;
    margin: 0;
  }
  [data-sidebar-style='mini'][data-layout='vertical']
    .deznav
    .metismenu
    > li
    > ul
    li:hover
    ul:after {
    content: none;
  }
  [direction='rtl'][data-sidebar-style='mini'][data-layout='vertical']
    .deznav
    .metismenu
    > li
    > ul
    li:hover
    ul {
    left: auto;
    right: 13rem;
  }
  [data-sidebar-style='mini'][data-layout='vertical']
    .deznav
    .metismenu
    > li.mm-active
    > a {
    background: #0b2a97;
    color: #fff;
    border-radius: 1.25rem;
  }
  [data-theme-version='dark'][data-sidebar-style='mini'][data-layout='vertical']
    .deznav
    .metismenu
    > li.mm-active
    > a {
    background: #1e2746;
  }
  [data-sidebar-style='mini'][data-layout='vertical']
    .deznav
    .metismenu
    > li:hover
    > a {
    background: #0b2a97;
    color: #fff;
    border-radius: 1.25rem;
    position: unset;
  }
  [data-theme-version='dark'][data-sidebar-style='mini'][data-layout='vertical']
    .deznav
    .metismenu
    > li:hover
    > a {
    background: #1e2746;
  }
  [direction='rtl'][data-sidebar-style='mini'][data-layout='vertical']
    .deznav
    .metismenu
    > li:hover
    > a
    .nav-text {
    padding-left: auto;
    padding-right: 1.6875rem;
  }
  [data-sidebar-style='mini'][data-layout='vertical']
    .deznav
    .metismenu
    > li:hover
    > ul {
    height: auto !important;
    overflow: visible;
    border: 0;
    margin-left: 0;
    left: 5rem;
    width: 13rem;
    border-radius: 12px;
    border: 0;
    padding: 10px 0;
    top: 0;
  }
  [data-sidebar-style='mini'][data-layout='vertical']
    .deznav
    .metismenu
    > li:hover
    > ul:after {
    content: '';
    position: absolute;
    background: inherit;
    width: 10px;
    height: 10px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    left: -5px;
    top: 20px;
  }
  [data-theme-version='dark'][data-sidebar-style='mini'][data-layout='vertical']
    .deznav
    .metismenu
    > li:hover
    > ul {
    box-shadow: 0px 9px 10px 0px rgba(0, 0, 0, 0.1);
  }
  [direction='rtl'][data-sidebar-style='mini'][data-layout='vertical']
    .deznav
    .metismenu
    > li:hover
    > ul {
    left: auto;
    right: 5rem;
  }
  [data-sidebar-style='mini'][data-layout='vertical']
    .deznav
    .metismenu
    > li:hover
    > ul
    a {
    padding: 6px 20px 6px 20px;
  }
  [data-sidebar-style='mini'][data-layout='vertical']
    .deznav
    .metismenu
    > li:hover
    > ul
    a:before {
    content: none;
  }
  [data-sidebar-style='mini'][data-layout='vertical']
    .deznav
    .metismenu
    > li:hover
    > ul
    a.has-arrow:after {
    right: 1.25rem;
  }
  [data-sidebar-style='mini'][data-layout='vertical']
    .deznav
    .metismenu
    > li:hover
    > ul
    ul
    a {
    padding: 6px 20px 6px 20px;
    margin-left: -1.6px;
  }
  [data-sidebar-style='mini'][data-layout='vertical']
    .deznav
    .metismenu
    > li:hover
    > ul
    ul
    a:before {
    content: none;
  }
  [data-sidebar-style='mini'][data-header-position='fixed'][data-container='boxed'][data-layout='vertical']
    .header {
    width: 1199px;
  }
  [data-sidebar-style='mini'][data-layout='horizontal'] .deznav .metismenu {
    padding: 0 30px;
  }
  [data-sidebar-style='mini'][data-layout='horizontal']
    .deznav
    .metismenu
    > li
    > a {
    padding: 15px 20px;
  }
  [direction='rtl'][data-sidebar-style='mini'][data-layout='horizontal']
    .deznav
    .metismenu
    > li
    > a {
    padding: 15px 20px;
  }
  [direction='rtl'][data-sidebar-style='mini'][data-layout='horizontal']
    .deznav
    .metismenu
    > li
    > a
    svg {
    margin-left: 0;
  }
  [data-sidebar-style='mini'][data-layout='horizontal']
    .deznav
    .metismenu
    > li
    > a
    svg {
    margin-right: 0;
    margin-top: 0;
  }
}

@media only screen and (max-width: 1199px) {
  [data-sidebar-style='mini'] .deznav li.mm-active ul {
    height: auto !important;
  }
  [data-sidebar-style='mini'] .deznav li a.has-arrow::after {
    transform: rotate(-45deg) translateY(-50%);
  }
}

/*$primary  : #7356f1 !default;*/

@media (min-width: 1199px) {
  [data-layout='horizontal'] .nav-header {
    width: 16rem;
  }
  [data-layout='horizontal'] .nav-header .nav-control {
    display: none;
  }
  [data-layout='horizontal'] .nav-header .brand-logo {
    /*  padding-left: 0;
   justify-content: center; */
  }
  [data-layout='horizontal'] .header {
    width: 100%;
    padding-left: 16rem;
  }
  [data-layout='horizontal'] .deznav {
    width: 100%;
    position: relative;
    height: auto;
    padding-bottom: 0;
    top: 0;
    z-index: 2;
  }
  [data-layout='horizontal'] .deznav .slimScrollDiv {
    overflow: visible !important;
  }
  [data-layout='horizontal'] .deznav .slimScrollDiv .deznav-scroll {
    overflow: visible !important;
  }
  [data-layout='horizontal'] .deznav .deznav-scroll {
    overflow: visible !important;
  }
  [data-layout='horizontal'] .deznav .slimScrollBar {
    display: none !important;
  }
  [data-layout='horizontal'] .deznav .nav-user,
  [data-layout='horizontal'] .deznav .nav-label {
    display: none;
  }
  [data-layout='horizontal'] .deznav .metismenu {
    flex-direction: row;
  }
  [data-layout='horizontal'] .deznav .metismenu .collapse.in {
    display: none;
  }
  [data-layout='horizontal'] .deznav .metismenu ul {
    border-left: 0;
  }
  [data-theme-version='dark'][data-layout='horizontal'] .deznav .metismenu ul {
    box-shadow: 0px 10px 13px 0px rgba(0, 0, 0, 0.1);
  }
  [data-layout='horizontal'] .deznav .metismenu li {
    flex-direction: column;
    position: relative;
  }
  [data-layout='horizontal'] .deznav .metismenu li:hover > ul {
    display: block;
  }
  [data-layout='horizontal'] .deznav .metismenu li > ul {
    position: absolute;
    height: auto !important;
    top: 3.25rem;
    width: 100%;
    min-width: 13.75rem;
    z-index: 999;
    left: auto;
    right: auto;
    padding: 0.5rem 0;
    display: none;
    box-shadow: 0px 0px 40px 0px rgba(82, 63, 105, 0.1);
    margin: 0;
    background: #fff;
  }
  [data-theme-version='dark'][data-layout='horizontal']
    .deznav
    .metismenu
    li
    > ul {
    box-shadow: 0px 10px 13px 0px rgba(0, 0, 0, 0.1);
  }
  [data-layout='horizontal'] .deznav .metismenu li > ul li {
    padding: 0;
  }
  [data-layout='horizontal'] .deznav .metismenu li > ul li a {
    transition: all 0.4s ease-in-out;
    padding: 8px 20px 8px 45px;
    margin-left: -0.1rem;
  }
  [direction='rtl'][data-layout='horizontal'] .deznav .metismenu li > ul li a {
    padding: 8px 45px 8px 20px;
    text-align: right;
  }
  [data-layout='horizontal'] .deznav .metismenu li > ul li a:hover {
    border-radius: 0.4rem;
  }
  [data-layout='horizontal'] .deznav .metismenu li > ul li a:before {
    left: 22px;
  }
  [direction='rtl'][data-layout='horizontal']
    .deznav
    .metismenu
    li
    > ul
    li
    a:before {
    left: auto;
    right: 21px;
  }
  [data-layout='horizontal'] .deznav .metismenu li > ul ul {
    left: 100%;
    top: 0;
    box-shadow: 0px 10px 13px 0px rgba(82, 63, 105, 0.05);
  }
  [direction='rtl'][data-layout='horizontal'] .deznav .metismenu li > ul ul {
    left: auto;
    right: 100%;
  }
  [data-layout='horizontal'] .deznav .metismenu > li {
    flex: 0 0 auto;
    position: relative;
  }
  [data-theme-version='dark'][data-layout='horizontal']
    .deznav
    .metismenu
    > li {
    border-color: rgba(255, 255, 255, 0.07);
  }
  [data-theme-version='dark'][data-layout='horizontal']
    .deznav
    .metismenu
    > li.mm-active {
    border-color: transparent;
  }
  [data-layout='horizontal'] .deznav .metismenu > li.mm-active {
    padding: 0;
  }
  [data-layout='horizontal'] .deznav .metismenu > li.mm-active > a {
    background: #fff;
    box-shadow: 0px 0px 13px 0px rgba(82, 63, 105, 0.05);
  }
  [direction='rtl'][data-layout='horizontal']
    .deznav
    .metismenu
    > li:first-child {
    border-right: 0;
  }
  [data-theme-version='dark'][direction='rtl'][data-layout='horizontal']
    .deznav
    .metismenu
    > li {
    border-color: #333a54;
  }
  [data-layout='horizontal'] .deznav .metismenu > li > a {
    padding: 15px 40px 15px 15px;
  }
  [direction='rtl'][data-layout='horizontal'] .deznav .metismenu > li > a {
    padding: 15px 15px 15px 40px;
  }
  [data-layout='horizontal'] .deznav .metismenu > li > a i {
    padding: 0 0.4375rem 0 0;
  }
  [direction='rtl'][data-layout='horizontal'] .deznav .metismenu > li > a i {
    padding: 0 0 0 0.4375rem;
  }
  [data-layout='horizontal'] .deznav .metismenu > li > a .nav-badge {
    display: none;
  }
  [data-layout='horizontal'] .deznav .metismenu > li > a:after {
    right: 20px;
    transform: rotate(-135deg) translateY(-50%);
  }
  [data-layout='horizontal'] .deznav .metismenu > li:hover {
    border-color: transparent;
  }
  [data-layout='horizontal'] .deznav .metismenu > li:hover > ul {
    display: flex !important;
    flex-direction: column;
    flex-wrap: wrap;
    height: auto !important;
    box-shadow: 0px 10px 13px 0px rgba(82, 63, 105, 0.05);
  }
  [data-theme-version='dark'][data-layout='horizontal']
    .deznav
    .metismenu
    > li:hover
    > ul {
    box-shadow: 0px 0 13px 0px rgba(0, 0, 0, 0.1);
  }
  [data-layout='horizontal']
    .deznav
    .metismenu
    > li
    > ul
    > li:hover
    ul.collapse {
    display: block !important;
    position: absolute;
    left: auto !important;
    right: -100% !important;
    top: 0 !important;
  }
  [data-layout='horizontal']
    .deznav
    .metismenu
    > li:nth-last-child(-n + 5)
    > ul {
    left: auto;
    right: 0;
  }
  [data-layout='horizontal']
    .deznav
    .metismenu
    > li:nth-last-child(-n + 5)
    > ul
    > li:hover
    ul.collapse {
    right: auto !important;
    left: -100% !important;
  }
  [data-layout='horizontal'] .deznav .metismenu > li:last-child > ul ul {
    left: -100%;
  }
  [direction='rtl'][data-layout='horizontal']
    .deznav
    .metismenu
    > li:nth-last-child(-n + 3)
    > ul {
    left: 0;
    right: auto;
  }
  [direction='rtl'][data-layout='horizontal']
    .deznav
    .metismenu
    > li:nth-last-child(-n + 3)
    > ul
    > li:hover
    ul.collapse {
    right: -100% !important;
    left: auto !important;
  }
  [data-layout='horizontal'] .content-body {
    margin-left: 0;
  }
  [data-layout='horizontal'] .content-body .page-titles {
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 1.875rem;
  }
  [data-layout='horizontal'] .footer {
    margin-left: 0;
    margin: 0 auto;
    padding-left: 0;
  }
  [data-layout='horizontal'] .sidebar-right {
    z-index: 1;
  }
  [data-header-position='fixed'][data-layout='horizontal'] .deznav {
    top: 5rem;
  }
  [data-header-position='fixed'][data-sidebar-position='fixed'] .deznav {
    position: fixed;
  }
  [data-header-position='fixed'][data-layout='horizontal'][data-sidebar-position='fixed']
    .content-body {
    padding-top: 8.3rem;
  }
  [data-layout='horizontal'][data-container='boxed'] .footer {
    margin-left: 0;
    max-width: 1199px;
    margin: 0 auto;
  }
  [data-layout='horizontal'][data-container='wide'] .page-titles {
    margin-left: -30px;
    margin-right: -30px;
  }
  [data-layout='horizontal'][data-sidebar-style='compact'] .page-titles {
    margin-top: 0;
  }
  [data-layout='horizontal'][data-sidebar-style='compact']
    .deznav
    .metismenu
    > li
    > ul {
    top: 4.315rem;
  }
  [data-layout='horizontal'][data-sidebar-style='compact']
    .deznav
    .metismenu
    > li
    > a {
    padding: 0.8125rem 2.1rem;
  }
  [data-layout='horizontal'][data-sidebar-style='compact']
    .deznav
    .metismenu
    > li
    > a::after {
    display: none;
  }
  [data-layout='horizontal'][data-sidebar-style='compact']
    .deznav
    .metismenu
    > li
    li {
    text-align: left;
  }
  [data-sidebar-style='mini'][data-layout='horizontal'] .nav-header {
    width: 6rem;
    padding-left: 30px;
    padding-right: 30px;
  }
  [data-sidebar-style='mini'][data-layout='horizontal']
    .nav-header
    .brand-logo {
    justify-content: start;
  }
  [data-sidebar-style='mini'][data-layout='horizontal'] .header {
    width: 100%;
    padding-left: 6rem;
  }
  [data-sidebar-style='mini'][data-layout='horizontal']
    .metismenu
    > li:hover
    a {
    width: auto;
  }
  [data-sidebar-style='mini'][data-layout='horizontal']
    .metismenu
    > li:hover
    a
    .nav-text {
    display: none;
  }
  [data-header-position='fixed'][data-layout='horizontal'][data-sidebar-position='fixed'][data-sidebar-style='compact']
    .content-body {
    padding-top: 8.75rem;
  }
  [data-sidebar-position='fixed'][data-layout='horizontal'] .deznav.fixed {
    position: fixed;
    left: 0;
    top: 0;
  }
}

@media (min-width: 767px) {
  [data-sidebar-style='compact'] .deznav .nav-user {
    display: none;
  }
  [data-sidebar-style='compact'] .deznav .metismenu > li > a {
    padding: 1.5rem 1.625rem 1.5rem 1.5rem;
  }
  [data-sidebar-style='compact'] .deznav .metismenu > li ul {
    padding: 1rem 0;
  }
  [data-sidebar-style='compact'] .deznav .metismenu > li ul li > a {
    padding: 0.5rem 1rem;
  }
  [data-sidebar-style='compact']
    .deznav
    .metismenu
    > li
    ul
    li
    > a.has-arrow:after {
    content: '';
  }
  [data-sidebar-style='compact'] .deznav .metismenu li {
    text-align: center;
  }
  [data-sidebar-style='compact'] .deznav .metismenu li a svg {
    max-width: 21px;
    max-height: 21px;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  [data-sidebar-style='compact'] .deznav .metismenu li a i {
    font-size: 2.2rem;
  }
  [data-sidebar-style='compact'] .deznav .metismenu li a:after {
    content: none;
  }
  [data-sidebar-style='compact'] .deznav .metismenu li a:before {
    content: '';
    height: 75px;
    width: 0;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translatey(-50%);
    background: #0b2a97;
    border-radius: 0 1.25rem 1.25rem 0;
    -webkit-transition: all 0.5s;
    -ms-transition: all 0.5s;
    transition: all 0.5s;
  }
  [data-sidebar-style='compact'] .deznav .metismenu li ul:after {
    content: none;
  }
  [data-sidebar-style='compact'] .deznav .metismenu li.mm-active > a,
  [data-sidebar-style='compact'] .deznav .metismenu li:hover > a {
    background: -moz-linear-gradient(
      left,
      #e0e0e4 0%,
      rgba(125, 185, 232, 0) 100%
    );
    background: -webkit-linear-gradient(
      left,
      #e0e0e4 0%,
      rgba(125, 185, 232, 0) 100%
    );
    background: linear-gradient(
      to right,
      #e0e0e4 0%,
      rgba(125, 185, 232, 0) 100%
    );
  }
  [data-sidebar-style='compact'] .deznav .metismenu li.mm-active > a:before,
  [data-sidebar-style='compact'] .deznav .metismenu li:hover > a:before {
    width: 10px;
  }
  [data-sidebar-style='compact'] .nav-text {
    display: inline-block;
    margin-top: 0.7125rem;
  }
  [data-sidebar-style='compact'] .nav-label.first {
    display: none;
  }
  [data-sidebar-style='compact'] .nav-badge {
    display: none;
  }
  [data-sidebar-style='compact'] .footer {
    padding-left: 9.375rem;
  }
  [data-sidebar-style='compact'] .content-body {
    margin-left: 15rem;
  }
}

[data-layout='horizontal'][data-sidebar-style='compact'] .footer {
  padding-left: 0;
}

[data-layout='horizontal'][data-sidebar-style='compact'] .content-body {
  margin-left: 0;
}

[data-layout='horizontal'][data-sidebar-style='compact'] .deznav .metismenu {
  padding: 0 30px;
}

[data-layout='vertical'][data-sidebar-style='compact'] .deznav {
  width: 15rem;
}

[data-layout='vertical'][data-sidebar-style='compact'] .deznav .metismenu ul {
  background: rgba(0, 0, 0, 0.02);
}

[data-theme-version='dark'][data-layout='vertical'][data-sidebar-style='compact']
  .deznav
  .metismenu
  ul {
  background: 255, 255, 255, 0.05;
}

[data-layout='vertical'][data-sidebar-style='compact']
  .deznav
  .metismenu
  ul
  a:before {
  content: none;
}

[data-layout='vertical'][data-sidebar-style='compact']
  .deznav
  .metismenu
  ul
  ul
  a {
  padding: 0.625rem 0.9375rem;
}

[data-layout='vertical'][data-sidebar-style='compact']
  .deznav
  .metismenu
  > li
  > a.has-arrow::after {
  top: 1px;
  display: inline-block;
  right: auto;
  margin-left: 5px;
  position: relative;
  width: 7px;
  height: 7px;
  border-width: 2px 0px 0px 2px;
}

[direction='rtl'][data-layout='vertical'][data-sidebar-style='compact']
  .deznav
  .metismenu
  > li
  > a.has-arrow::after {
  left: auto;
  margin-left: 0;
  margin-right: 5px;
}

@media (min-width: 767px) {
  [data-sidebar-style='icon-hover'][data-layout='horizontal'] .metismenu {
    padding: 0 30px;
  }
  [data-sidebar-style='icon-hover'][data-layout='horizontal']
    .header
    .header-content {
    padding-left: 1.875rem;
  }
  [data-sidebar-style='icon-hover'][data-layout='vertical'] .nav-header {
    width: 5.063rem;
  }
  [data-sidebar-style='icon-hover'][data-layout='vertical']
    .nav-header
    .brand-logo {
    padding-left: 20px;
  }
  [direction='rtl'][data-sidebar-style='icon-hover'][data-layout='vertical']
    .nav-header
    .brand-logo {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }
  [data-sidebar-style='icon-hover'][data-layout='vertical']
    .nav-header
    .brand-logo
    .logo-abbr {
    display: block;
  }
  [data-sidebar-style='icon-hover'][data-layout='vertical']
    .nav-header
    .brand-logo
    .brand-title {
    display: none;
  }
  [data-sidebar-style='icon-hover'][data-layout='vertical']
    .nav-header
    .nav-control {
    display: none;
  }
  [data-sidebar-style='icon-hover'][data-layout='vertical'] .header {
    padding-left: 5.063rem;
  }
  [direction='rtl'][data-sidebar-style='icon-hover'][data-layout='vertical']
    .header {
    padding-right: 4.38rem;
    padding-left: 0.9375rem;
  }
  [data-sidebar-style='icon-hover'][data-layout='vertical']
    .header
    .header-content {
    padding-left: 1.375rem;
  }
  [direction='rtl'][data-sidebar-style='icon-hover'][data-layout='vertical']
    .header
    .header-content {
    padding-right: 1.375rem;
    padding-left: 0;
  }
  [data-sidebar-style='icon-hover'][data-layout='vertical'] .deznav {
    overflow: visible;
    position: absolute;
    left: -12.125rem;
  }
}

@media only screen and (min-width: 767px) and (min-width: 1200px) and (max-width: 1350px) {
  [data-sidebar-style='icon-hover'][data-layout='vertical'] .deznav {
    left: -10rem;
  }
}

@media (min-width: 767px) {
  [direction='rtl'][data-sidebar-style='icon-hover'][data-layout='vertical']
    .deznav {
    left: auto;
    right: -12.125rem;
  }
  [data-sidebar-style='icon-hover'][data-layout='vertical'] .deznav .nav-label {
    display: none;
  }
  [data-sidebar-style='icon-hover'][data-layout='vertical']
    .deznav
    .metismenu
    > li
    > a {
    display: flex;
    justify-content: space-between;
  }
  [data-sidebar-style='icon-hover'][data-layout='vertical']
    .deznav
    .metismenu
    > li
    > a
    > svg,
  [data-sidebar-style='icon-hover'][data-layout='vertical']
    .deznav
    .metismenu
    > li
    > a
    > i {
    order: 1;
    margin-right: 0;
    margin-top: 0;
  }
  [data-sidebar-style='icon-hover'][data-layout='vertical']
    .deznav
    .metismenu
    ul {
    border-left: 0;
    padding-left: 0;
    padding-right: 28px;
  }
  [direction='rtl'][data-sidebar-style='icon-hover'][data-layout='vertical']
    .deznav
    .metismenu
    ul {
    padding-right: 0;
    padding-left: 28px;
  }
  [data-theme-version='dark'][data-sidebar-style='icon-hover'][data-layout='vertical']
    .deznav
    .metismenu
    ul {
    border-color: #333a54;
  }
  [data-sidebar-style='icon-hover'][data-layout='vertical']
    .deznav
    .metismenu
    ul:after {
    left: auto;
    right: 28px;
  }
  [direction='rtl'][data-sidebar-style='icon-hover'][data-layout='vertical']
    .deznav
    .metismenu
    ul:after {
    left: 28px;
    right: auto;
  }
  [data-sidebar-style='icon-hover'][data-layout='vertical']
    .deznav
    .metismenu
    ul
    a {
    position: relative;
  }
  [data-sidebar-style='icon-hover'][data-layout='vertical']
    .deznav
    .metismenu
    ul
    a::before {
    left: auto;
    right: -5px;
  }
  [direction='rtl'][data-sidebar-style='icon-hover'][data-layout='vertical']
    .deznav
    .metismenu
    ul
    a::before {
    right: auto;
    left: -5px;
  }
  [data-sidebar-style='icon-hover'][data-layout='vertical']
    .deznav
    .metismenu
    .has-arrow::after {
    right: 5rem;
  }
  [direction='rtl'][data-sidebar-style='icon-hover'][data-layout='vertical']
    .deznav
    .metismenu
    .has-arrow::after {
    right: auto;
    left: 5rem;
  }
  [data-sidebar-style='icon-hover'][data-layout='vertical']
    .icon-hover-toggle
    .nav-header {
    width: 17.1875rem;
  }
  [data-sidebar-style='icon-hover'][data-layout='vertical']
    .icon-hover-toggle
    .nav-header
    .brand-logo {
    padding-left: 1.6rem;
  }
  [data-sidebar-style='icon-hover'][data-layout='vertical']
    .icon-hover-toggle
    .nav-header
    .brand-logo
    .brand-title {
    display: block;
  }
  [data-sidebar-style='icon-hover'][data-layout='vertical']
    .icon-hover-toggle
    .header {
    padding-left: 4.38rem;
  }
  [direction='rtl'][data-sidebar-style='icon-hover'][data-layout='vertical']
    .icon-hover-toggle
    .header {
    padding-right: 4.38rem;
    padding-left: 0.9375rem;
  }
  [data-sidebar-style='icon-hover'][data-layout='vertical']
    .icon-hover-toggle
    .deznav {
    left: 0;
  }
  [direction='rtl'][data-sidebar-style='icon-hover'][data-layout='vertical']
    .icon-hover-toggle
    .deznav {
    left: auto;
    right: 0;
  }
  [data-sidebar-style='icon-hover'][data-layout='vertical'] .content-body {
    margin-left: 5rem;
  }
  [direction='rtl'][data-sidebar-style='icon-hover'][data-layout='vertical']
    .content-body {
    margin-left: 0;
    margin-right: 5rem;
  }
  [data-sidebar-style='icon-hover'][data-layout='vertical'] .footer {
    padding-left: 4.375rem;
  }
  [direction='rtl'][data-sidebar-style='icon-hover'][data-layout='vertical']
    .footer {
    margin-left: 0;
    margin-right: 4.375rem;
  }
}

@media (min-width: 767px) {
  [data-sidebar-style='modern'] .nav-header {
    width: 9.375rem;
  }
  [data-sidebar-style='modern'] .nav-header .brand-logo {
    justify-content: center;
  }
  [data-sidebar-style='modern'] .deznav .metismenu > li {
    text-align: center;
  }
  [data-sidebar-style='modern'] .deznav .metismenu > li > a {
    padding: 20px 15px 20px 15px !important;
  }
  [data-sidebar-style='modern'] .deznav .metismenu > li > a::after {
    display: none;
  }
  [data-sidebar-style='modern'] .deznav .metismenu > li > a:hover > a,
  [data-sidebar-style='modern'] .deznav .metismenu > li > a:focus > a,
  [data-sidebar-style='modern'] .deznav .metismenu > li > a:active > a,
  [data-sidebar-style='modern'] .deznav .metismenu > li > a.mm-active > a {
    background-color: #a8baf9;
  }
  [data-sidebar-style='modern'] .deznav .metismenu > li.mm-active {
    padding: 0;
  }
  [data-sidebar-style='modern'] .deznav .metismenu > li li {
    text-align: left;
  }
  [direction='rtl'][data-sidebar-style='modern'] .deznav .metismenu > li li {
    text-align: right;
  }
  [data-sidebar-style='modern'] .deznav .metismenu li a {
    padding: 0.625rem 0.9375rem 0.625rem 0.9375rem;
  }
  [data-sidebar-style='modern'] .deznav .metismenu li ul:after {
    content: none;
  }
  [data-sidebar-style='modern'] .deznav .metismenu li > ul {
    height: auto !important;
  }
  [data-sidebar-style='modern'] .deznav .metismenu .nav-label {
    display: none;
  }
  [data-sidebar-style='modern'] .deznav .nav-label {
    display: none;
  }
  [data-sidebar-style='modern'] .deznav .nav-text {
    display: block;
    margin-top: 0.3125rem;
  }
  [data-sidebar-style='modern'] .footer {
    padding-left: 9.375rem;
  }
  [data-sidebar-style='modern'] .content-body {
    margin-left: 9.375rem;
  }
  [data-sidebar-style='modern'][data-layout='vertical'] .deznav {
    width: 9.375rem;
    left: 0;
  }
  [direction='rtl'][data-sidebar-style='modern'][data-layout='vertical']
    .deznav {
    left: auto;
    right: 0;
  }
  [data-sidebar-style='modern'][data-layout='vertical'] .deznav .slimScrollDiv,
  [data-sidebar-style='modern'][data-layout='vertical'] .deznav .deznav-scroll {
    overflow: visible !important;
  }
  [data-sidebar-style='modern'][data-layout='vertical']
    .deznav
    .metismenu
    > li
    > a
    i {
    font-size: 20px;
  }
  [data-sidebar-style='modern'][data-layout='vertical']
    .deznav
    .metismenu
    > li
    > ul {
    display: none;
    padding: 1.875rem 0.9375rem;
  }
  [data-sidebar-style='modern'][data-layout='vertical'] .deznav .metismenu li {
    position: relative;
  }
  [data-sidebar-style='modern'][data-layout='vertical']
    .deznav
    .metismenu
    li
    a {
    padding: 0.625rem 1.5rem;
  }
  [data-sidebar-style='modern'][data-layout='vertical']
    .deznav
    .metismenu
    li
    a:before {
    content: none;
  }
  [data-sidebar-style='modern'][data-layout='vertical']
    .deznav
    .metismenu
    li
    ul {
    position: absolute;
    left: 105%;
    top: 0;
    bottom: auto;
    background-color: #fff;
    border: 1px solid #f0f1f5;
    width: 200px;
  }
  [data-theme-version='dark'][data-sidebar-style='modern'][data-layout='vertical']
    .deznav
    .metismenu
    li
    ul {
    background: #1e2746;
    box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.1);
  }
  [direction='rtl'][data-sidebar-style='modern'][data-layout='vertical']
    .deznav
    .metismenu
    li
    ul {
    left: auto;
    right: 105%;
  }
  [data-sidebar-style='modern'][data-layout='vertical']
    .deznav
    .metismenu
    li:hover
    > ul {
    display: block;
    left: 100%;
    padding: 1rem 0;
    margin-left: -10px;
    border: 0;
    box-shadow: 5px 0px 13px 0px rgba(82, 63, 105, 0.05);
  }
  [data-theme-version='dark'][data-sidebar-style='modern'][data-layout='vertical']
    .deznav
    .metismenu
    li:hover
    > ul {
    box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.1);
  }
  [direction='rtl'][data-sidebar-style='modern'][data-layout='vertical']
    .deznav
    .metismenu
    li:hover
    > ul {
    left: auto;
    right: 100%;
    box-shadow: -5px 0px 13px 0px rgba(82, 63, 105, 0.05);
  }
  [data-sidebar-style='modern'][data-layout='vertical'] .deznav .nav-label {
    display: none;
  }
  [data-sidebar-style='modern'][data-layout='vertical'] .deznav .nav-text {
    display: block;
    margin-top: 0;
  }
  [data-sidebar-style='modern'][data-layout='vertical'] .menu-toggle .deznav {
    left: -9.375rem;
  }
  [direction='rtl'][data-sidebar-style='modern'][data-layout='vertical']
    .menu-toggle
    .deznav {
    left: auto;
    right: -9.375rem;
  }
  [data-sidebar-style='modern'][data-layout='vertical']
    .menu-toggle
    .content-body {
    margin-left: 0;
  }
  [direction='rtl'][data-sidebar-style='modern'][data-layout='vertical']
    .menu-toggle
    .content-body {
    margin-right: 0;
  }
  [data-sidebar-style='modern'][data-layout='horizontal'] .footer,
  [data-sidebar-style='modern'][data-layout='horizontal'] .content-body {
    margin-left: 0;
  }
  [data-sidebar-style='modern'][data-layout='horizontal'] .deznav .metismenu {
    padding: 0 30px;
  }
  [data-sidebar-style='modern'][data-layout='horizontal']
    .deznav
    .metismenu
    > li
    > a {
    padding: 0.8125rem 2.25rem;
  }
  [data-sidebar-style='modern'][data-layout='horizontal']
    .deznav
    .metismenu
    > li
    > ul {
    top: 4.5625rem;
  }
  [data-sidebar-style='modern'][data-layout='horizontal'][data-container='boxed']
    .deznav
    .metismenu
    > li
    > a {
    padding: 0.8125rem 1.25rem;
  }
}

[data-sidebar-style='overlay'] .deznav {
  left: -100%;
}

[direction='rtl'][data-sidebar-style='overlay'] .deznav {
  left: auto;
  right: -100%;
}

[data-sidebar-style='overlay'] .content-body {
  margin-left: 0;
}

[data-sidebar-style='overlay'] .nav-header {
  position: absolute;
}

[data-sidebar-style='overlay'] .nav-header .hamburger.is-active {
  left: 0;
}

[data-sidebar-style='overlay'] .nav-header .hamburger.is-active .line {
  background-color: #0b2a97;
}

[data-sidebar-style='overlay'] .menu-toggle .nav-header {
  position: absolute;
  left: auto;
}

[data-sidebar-style='overlay'] .menu-toggle .deznav {
  left: 0;
}

[direction='rtl'][data-sidebar-style='overlay'] .menu-toggle .deznav {
  left: auto;
  right: 0;
}

[data-sidebar-style='overlay'] .footer {
  padding-left: 0;
}

[data-sidebar-style='overlay'][data-header-position='fixed'] .nav-header {
  position: fixed;
}

[data-sidebar-position='fixed'][data-header-position='fixed'] .nav-header {
  position: fixed;
}

[data-sidebar-position='fixed'][data-layout='vertical'] .nav-header {
  position: fixed;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}

[data-sidebar-position='fixed'][data-layout='vertical'] .deznav {
  position: fixed;
}

[data-sidebar-position='fixed'][data-layout='vertical'] .deznav .deznav-scroll {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}

[data-sidebar-position='fixed'][data-layout='vertical'] .menu-toggle .deznav {
  position: fixed;
}

[data-header-position='fixed'][data-sidebar-position='fixed'][data-sidebar-style='overlay'][data-layout='vertical'][data-container='boxed']
  .deznav {
  position: absolute;
}

.sidebar-right {
  right: -15.625rem;
  position: fixed;
  top: 0;
  width: 15.625rem;
  background-color: #fff;
  height: calc(100% - 7.0625rem);
  margin-top: 5rem;
  transition: all 0.5s ease-in-out;
  padding-bottom: 1.875rem;
  box-shadow: -2px 3px 10px 0px rgba(119, 119, 119, 0.1);
}

[direction='rtl'] .sidebar-right .slimScrollDiv {
  overflow: visible !important;
}

.sidebar-right .sidebar-right-trigger {
  position: absolute;
  z-index: 9;
  top: 4.75rem;
  right: 100%;
  background-color: #fff;
  color: #0b2a97;
  display: inline-block;
  height: 3rem;
  width: 3rem;
  text-align: center;
  font-size: 1.75rem;
  line-height: 3rem;
  border-radius: 5px 0 0 5px;
  box-shadow: -5px 3px 5px 0px rgba(119, 119, 119, 0.15);
}

[data-theme-version='dark'] .sidebar-right .sidebar-right-trigger {
  background-color: #31343b;
  color: #fff;
}

.sidebar-right .sidebar-right-trigger:hover {
  color: #0b2a97;
}

.sidebar-right.show {
  right: 0;
  z-index: 999;
}

.sidebar-right .nav-tabs {
  justify-content: space-between;
  position: sticky;
  top: 0;
  background-color: #fff;
  z-index: 2;
}

[data-theme-version='dark'] .sidebar-right .nav-tabs {
  background-color: #181f39;
}

.sidebar-right .nav-tabs .nav-item {
  margin-bottom: 0;
  flex: 1;
}

.sidebar-right .nav-tabs .nav-item .nav-link {
  border: 0;
  font-size: 1.125rem;
  position: relative;
  text-align: center;
  background-color: #fff;
}

.sidebar-right .nav-tabs .nav-item .nav-link::after {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 2px;
  content: '';
  background: transparent;
}

[data-theme-version='dark'] .sidebar-right .nav-tabs .nav-item .nav-link {
  background-color: #181f39;
}

[data-theme-version='dark']
  .sidebar-right
  .nav-tabs
  .nav-item
  .nav-link.active {
  border-right: none;
  border-left: none;
  border-top: none;
}

.sidebar-right .tab-content {
  padding: 1.25rem;
}

.sidebar-right .tab-content .tab-pane .admin-settings > div {
  margin-bottom: 10px;
}

.sidebar-right .tab-content .tab-pane .admin-settings p {
  margin-bottom: 0.125rem;
}

.sidebar-right .tab-content .tab-pane .admin-settings input[type='radio'] {
  display: none;
}

.sidebar-right
  .tab-content
  .tab-pane
  .admin-settings
  input[type='radio']
  + label {
  display: inline-block;
  width: 25px;
  height: 25px;
  cursor: pointer;
  transition: all 0.1s ease;
  border-radius: 4px;
  margin-right: 5px;
  margin-bottom: 3px;
}

.sidebar-right
  .tab-content
  .tab-pane
  .admin-settings
  input[type='radio']:checked
  + label {
  position: relative;
}

.sidebar-right
  .tab-content
  .tab-pane
  .admin-settings
  input[type='radio']:checked
  + label::after {
  height: 33px;
  width: 33px;
  left: -4px;
  top: -4px;
  content: '';
  position: absolute;
  background-color: inherit;
  border-radius: 6px;
  opacity: 0.4;
}

.sidebar-right #nav_header_color_1 + label,
.sidebar-right #header_color_1 + label,
.sidebar-right #sidebar_color_1 + label,
.sidebar-right #primary_color_1 + label {
  background-color: #fff;
}

.sidebar-right #nav_header_color_2 + label,
.sidebar-right #header_color_2 + label,
.sidebar-right #sidebar_color_2 + label,
.sidebar-right #primary_color_2 + label {
  background-color: #143b64;
}

.sidebar-right #nav_header_color_3 + label,
.sidebar-right #header_color_3 + label,
.sidebar-right #sidebar_color_3 + label,
.sidebar-right #primary_color_3 + label {
  background-color: #0b2a97;
}

.sidebar-right #nav_header_color_4 + label,
.sidebar-right #header_color_4 + label,
.sidebar-right #sidebar_color_4 + label,
.sidebar-right #primary_color_4 + label {
  background-color: #4527a0;
}

.sidebar-right #nav_header_color_5 + label,
.sidebar-right #header_color_5 + label,
.sidebar-right #sidebar_color_5 + label,
.sidebar-right #primary_color_5 + label {
  background-color: #c62828;
}

.sidebar-right #nav_header_color_6 + label,
.sidebar-right #header_color_6 + label,
.sidebar-right #sidebar_color_6 + label,
.sidebar-right #primary_color_6 + label {
  background-color: #283593;
}

.sidebar-right #nav_header_color_7 + label,
.sidebar-right #header_color_7 + label,
.sidebar-right #sidebar_color_7 + label,
.sidebar-right #primary_color_7 + label {
  background-color: #7356f1;
}

.sidebar-right #nav_header_color_8 + label,
.sidebar-right #header_color_8 + label,
.sidebar-right #sidebar_color_8 + label,
.sidebar-right #primary_color_8 + label {
  background-color: #3695eb;
}

.sidebar-right #nav_header_color_9 + label,
.sidebar-right #header_color_9 + label,
.sidebar-right #sidebar_color_9 + label,
.sidebar-right #primary_color_9 + label {
  background-color: #00838f;
}

.sidebar-right #nav_header_color_10 + label,
.sidebar-right #header_color_10 + label,
.sidebar-right #sidebar_color_10 + label,
.sidebar-right #primary_color_10 + label {
  background-color: #ff8f16;
}

.sidebar-right #nav_header_color_11 + label,
.sidebar-right #header_color_11 + label,
.sidebar-right #sidebar_color_11 + label,
.sidebar-right #primary_color_11 + label {
  background-color: #6673fd;
}

.sidebar-right #nav_header_color_12 + label,
.sidebar-right #header_color_12 + label,
.sidebar-right #sidebar_color_12 + label,
.sidebar-right #primary_color_12 + label {
  background-color: #558b2f;
}

.sidebar-right #nav_header_color_13 + label,
.sidebar-right #header_color_13 + label,
.sidebar-right #sidebar_color_13 + label,
.sidebar-right #primary_color_13 + label {
  background-color: #2a2a2a;
}

.sidebar-right #nav_header_color_14 + label,
.sidebar-right #header_color_14 + label,
.sidebar-right #sidebar_color_14 + label,
.sidebar-right #primary_color_14 + label {
  background-color: #1367c8;
}

.sidebar-right #nav_header_color_15 + label,
.sidebar-right #header_color_15 + label,
.sidebar-right #sidebar_color_15 + label,
.sidebar-right #primary_color_15 + label {
  background-color: #ed0b4c;
}

.sidebar-right #nav_header_color_1 + label,
.sidebar-right #header_color_1 + label,
.sidebar-right #sidebar_color_1 + label,
.sidebar-right #primary_color_1 + label {
  border: 1px solid #89879f;
}

@keyframes bounce {
  0% {
    transform: translateX(-8%);
    -webkit-transform: translateX(-8%);
  }
  50% {
    transform: translateX(8%);
    -webkit-transform: translateX(8%);
  }
  100% {
    transform: translateX(-8%);
    -webkit-transform: translateX(-8%);
  }
}

@-webkit-keyframes bounce {
  0% {
    transform: translateX(-8%);
    -webkit-transform: translateX(-8%);
  }
  50% {
    transform: translateX(8%);
    -webkit-transform: translateX(8%);
  }
  100% {
    transform: translateY(-8%);
    -webkit-transform: translateY(-8%);
  }
}

.nav-user {
  background: #0b2a97;
  margin-bottom: 10px;
  padding: 20px 25px 15px;
}

@media only screen and (min-width: 768px) and (max-width: 1199px) {
  .nav-user {
    padding: 20px 15px 15px;
  }
}

.nav-user img {
  width: 35px;
  height: 35px;
}

@media only screen and (min-width: 768px) and (max-width: 1199px) {
  .nav-user img {
    width: 35px;
    height: 35px;
    margin-bottom: 10px;
  }
}

.nav-user h5 {
  margin-left: 10px;
  margin-bottom: 3px;
  color: #fff;
}

@media only screen and (min-width: 768px) and (max-width: 1199px) {
  .nav-user h5 {
    display: none;
  }
}

[data-sibebarbg='color_2'] .nav-user h5 {
  color: #fff;
}

.nav-user p {
  margin-left: 10px;
  margin-bottom: 8px;
  color: #afcff7;
}

@media only screen and (min-width: 768px) and (max-width: 1199px) {
  .nav-user p {
    display: none;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1199px) {
  .nav-user i {
    margin-top: 15px;
    display: block;
  }
}

.menu-toggle .nav-user {
  padding: 20px 15px 15px;
}

.menu-toggle .nav-user img {
  width: 35px;
  height: 35px;
  margin-bottom: 10px;
}

.menu-toggle .nav-user h5,
.menu-toggle .nav-user p {
  display: none;
}

.menu-toggle .nav-user i {
  margin-top: 15px;
  display: block;
}

.menu-toggle .nav-user .dropdown-menu {
  left: 45px !important;
  top: 22px !important;
}

.chatbox {
  width: 340px;
  height: 100vh;
  position: fixed;
  right: -500px;
  top: 0;
  z-index: 999;
  background: #fff;
  box-shadow: 0px 0px 30px 0px rgba(82, 63, 105, 0.15);
  -webkit-transition: all 0.8s;
  -ms-transition: all 0.8s;
  transition: all 0.8s;
}

[data-theme-version='dark'] .chatbox {
  background: #1e2746;
}

.chatbox .chatbox-close {
  position: absolute;
  -webkit-transition: all 0.2s;
  -ms-transition: all 0.2s;
  transition: all 0.2s;
  width: 0;
  height: 100%;
  right: 340px;
  background: #000;
  z-index: 1;
  opacity: 0.1;
  cursor: pointer;
}

.chatbox .card-fotter {
  padding: 0.75rem 1rem;
}

.chatbox .card-body {
  padding: 1rem;
}

.chatbox.active {
  right: 0;
}

.chatbox.active .chatbox-close {
  width: 100vw;
}

.chatbox .type_msg {
  padding-top: 10px;
}

.chatbox .nav {
  padding: 1rem 1rem 0 1rem;
  background: #0b2a97;
  border: 0;
  justify-content: space-between;
}

.chatbox .nav .nav-link {
  color: #fff;
  opacity: 0.7;
  text-transform: uppercase;
}

.chatbox .nav .nav-link:hover,
.chatbox .nav .nav-link.active {
  background: transparent;
  color: #fff;
  opacity: 1;
  border-color: #fff;
}

.chatbox .img_cont {
  width: 40px;
  border-radius: 40px;
  margin-right: 10px;
  position: relative;
  height: 40px;
  background: #eee;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 14px;
  min-width: 40px;
  min-height: 40px;
}

.chatbox .img_cont .icon {
  color: #fff;
}

.chatbox .img_cont.primary {
  background: #d8e0fc;
  color: #0b2a97;
}

[data-theme-version='dark'] .chatbox .img_cont.primary {
  background: rgba(11, 42, 151, 0.2);
}

.chatbox .img_cont.warning {
  background: #fff5dd;
  color: #ffbc11;
}

[data-theme-version='dark'] .chatbox .img_cont.warning {
  background: rgba(255, 188, 17, 0.1);
}

.chatbox .img_cont.success {
  background: #e3f9e9;
  color: #2bc155;
}

[data-theme-version='dark'] .chatbox .img_cont.success {
  background: rgba(43, 193, 85, 0.1);
}

.chatbox .img_cont.info {
  background: #a4e3f1;
  color: #1ea7c5;
}

[data-theme-version='dark'] .chatbox .img_cont.info {
  background: rgba(30, 167, 197, 0.1);
}

.chatbox .img_cont img {
  width: 100%;
}

.chatbox .img_cont .online_icon {
  background: #2bc155;
  position: absolute;
  width: 12px;
  height: 12px;
  border-radius: 15px;
  right: -1px;
  bottom: 0px;
  border: 2px solid #fff;
}

.chatbox .img_cont .online_icon.offline {
  background: #f94687;
}

.chatbox .card {
  box-shadow: none;
}

.chatbox .search {
  height: 40px;
}

.chatbox .user_info span {
  font-size: 15px;
  color: #000;
  display: block;
  font-weight: 500;
  line-height: 1;
  margin-bottom: 5px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: block;
  max-width: 170px;
}

[data-theme-version='dark'] .chatbox .user_info span {
  color: #fff;
}

.chatbox .user_info p {
  font-size: 13px;
  margin-bottom: 0;
  line-height: 1;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: block;
  max-width: 170px;
}

.chatbox .contacts li {
  padding: 7px 1rem;
  border-bottom: 1px solid #eee;
  cursor: pointer;
}

[data-theme-version='dark'] .chatbox .contacts li {
  border-color: #333a54;
}

.chatbox .contacts li > div {
  display: flex;
  align-items: center;
}

.chatbox .contacts li:hover {
  background: #f4f7ff;
}

[data-theme-version='dark'] .chatbox .contacts li:hover {
  background-color: #181f39;
}

.chatbox .contacts .name-first-letter {
  background: #f9f9f9;
  padding: 4px 1rem;
  font-weight: 700;
  color: #000;
  position: sticky;
  top: 0;
  z-index: 1;
}

[data-theme-version='dark'] .chatbox .contacts .name-first-letter {
  color: #fff;
  background: #181f39;
}

.chatbox .msg_card_body {
  height: calc(100vh - 195px);
}

.chatbox .contacts_body {
  height: calc(100vh - 120px);
}

.chatbox .card-header {
  background: #f4f7ff;
  padding: 15px 20px;
  justify-content: center;
}

.chatbox .card-header h6 {
  font-size: 15px;
}

.chatbox .card-header p {
  line-height: 1.2;
  font-size: 12px;
  color: #7e7e7e;
}

.chatbox .chat-list-header {
  justify-content: space-between;
  background: #fff;
}

[data-theme-version='dark'] .chatbox .chat-list-header {
  background: #1e2746;
}

.chatbox .chat-list-header a {
  text-align: center;
  width: 30px;
  height: 30px;
  background: #f9f9f9;
  border-radius: 6px;
  line-height: 30px;
  display: block;
}

[data-theme-version='dark'] .chatbox .chat-list-header a {
  background: rgba(11, 42, 151, 0.2);
}

[data-theme-version='dark'] .chatbox .chat-list-header a svg g [fill] {
  fill: #fff;
}

.chatbox .img_cont_msg {
  width: 30px;
  height: 30px;
  display: block;
  max-width: 30px;
  min-width: 30px;
}

.chatbox .img_cont_msg img {
  width: 100%;
}

.chatbox .msg_cotainer {
  background: #0b2a97;
  margin-left: 10px;
  border-radius: 0 1.25rem 1.25rem 1.25rem;
  padding: 10px 15px;
  color: #fff;
  position: relative;
}

.chatbox .msg_cotainer .msg_time {
  display: block;
  font-size: 11px;
  color: #fff;
  margin-top: 5px;
  opacity: 0.5;
}

.chatbox .msg_cotainer:after {
  content: '';
  position: absolute;
  left: -10px;
  border-right: 10px solid #0b2a97;
  border-bottom: 10px solid transparent;
  border-top: 0px solid;
  top: 0;
}

.chatbox .msg_cotainer_send {
  background: #f9f9f9;
  padding: 10px 15px;
  border-radius: 6px 0px 6px 6px;
  margin-right: 10px;
  color: #222;
  position: relative;
  text-align: right;
}

[data-theme-version='dark'] .chatbox .msg_cotainer_send {
  background: #181f39;
  color: #fff;
}

.chatbox .msg_cotainer_send .msg_time_send {
  display: block;
  font-size: 11px;
  text-align: right;
  margin-top: 5px;
  opacity: 0.6;
}

.chatbox .msg_cotainer_send:after {
  content: '';
  position: absolute;
  right: -10px;
  border-left: 10px solid #f9f9f9;
  border-bottom: 10px solid transparent;
  border-top: 0px solid;
  top: 0;
}

[data-theme-version='dark'] .chatbox .msg_cotainer_send:after {
  border-left: 10px solid #181f39;
}

.chatbox .type_msg .form-control {
  padding: 10px 0;
  height: 50px;
  border: 0;
  resize: none;
}

.chatbox .type_msg .btn {
  font-size: 18px;
  border-radius: 38px !important;
  width: 38px;
  height: 38px;
  padding: 0;
  margin-top: 6px;
}

.chatbox .video_cam {
  margin-left: 15px;
}

.chatbox .video_cam span {
  width: 35px;
  height: 35px;
  background: #10ca93;
  text-align: center;
  line-height: 35px;
  border-radius: 35px;
  color: #fff;
  margin-right: 5px;
  align-self: center;
  font-size: 16px;
  padding: 0 3px;
  display: inline-block;
}

.chatbox .note_card .contacts li {
  padding: 12px 1rem;
}

@media only screen and (max-width: 576px) {
  .chatbox {
    width: 280px;
  }
  .chatbox .chatbox-close {
    right: 280px;
  }
}

[data-nav-headerbg='color_2'][data-theme-version='dark'] .nav-header,
[data-nav-headerbg='color_2'] .nav-header {
  background-color: #143b64;
}

[data-nav-headerbg='color_2'][data-theme-version='dark']
  .nav-header
  .brand-logo,
[data-nav-headerbg='color_2'] .nav-header .brand-logo {
  color: #fff;
}

[data-nav-headerbg='color_2'][data-theme-version='dark']
  .nav-header
  .brand-logo:hover,
[data-nav-headerbg='color_2'] .nav-header .brand-logo:hover {
  color: #fff;
}

[data-nav-headerbg='color_2'][data-theme-version='dark']
  .nav-header
  .hamburger
  .line,
[data-nav-headerbg='color_2'] .nav-header .hamburger .line {
  background: #fff;
}

[data-nav-headerbg='color_2'][data-theme-version='dark'][data-sidebar-style='full'][data-layout='vertical']
  .menu-toggle
  .nav-header
  .nav-control
  .hamburger
  .line,
[data-nav-headerbg='color_2'][data-sidebar-style='full'][data-layout='vertical']
  .menu-toggle
  .nav-header
  .nav-control
  .hamburger
  .line {
  background: #fff;
}

[data-nav-headerbg='color_3'][data-theme-version='dark'] .nav-header,
[data-nav-headerbg='color_3'] .nav-header {
  background-color: #0b2a97;
}

[data-nav-headerbg='color_3'][data-theme-version='dark']
  .nav-header
  .brand-logo,
[data-nav-headerbg='color_3'] .nav-header .brand-logo {
  color: #fff;
}

[data-nav-headerbg='color_3'][data-theme-version='dark']
  .nav-header
  .brand-logo:hover,
[data-nav-headerbg='color_3'] .nav-header .brand-logo:hover {
  color: #fff;
}

[data-nav-headerbg='color_3'][data-theme-version='dark']
  .nav-header
  .hamburger
  .line,
[data-nav-headerbg='color_3'] .nav-header .hamburger .line {
  background: #fff;
}

[data-nav-headerbg='color_3'][data-theme-version='dark'][data-sidebar-style='full'][data-layout='vertical']
  .menu-toggle
  .nav-header
  .nav-control
  .hamburger
  .line,
[data-nav-headerbg='color_3'][data-sidebar-style='full'][data-layout='vertical']
  .menu-toggle
  .nav-header
  .nav-control
  .hamburger
  .line {
  background: #fff;
}

[data-nav-headerbg='color_4'][data-theme-version='dark'] .nav-header,
[data-nav-headerbg='color_4'] .nav-header {
  background-color: #4527a0;
}

[data-nav-headerbg='color_4'][data-theme-version='dark']
  .nav-header
  .brand-logo,
[data-nav-headerbg='color_4'] .nav-header .brand-logo {
  color: #fff;
}

[data-nav-headerbg='color_4'][data-theme-version='dark']
  .nav-header
  .brand-logo:hover,
[data-nav-headerbg='color_4'] .nav-header .brand-logo:hover {
  color: #fff;
}

[data-nav-headerbg='color_4'][data-theme-version='dark']
  .nav-header
  .hamburger
  .line,
[data-nav-headerbg='color_4'] .nav-header .hamburger .line {
  background: #fff;
}

[data-nav-headerbg='color_4'][data-theme-version='dark'][data-sidebar-style='full'][data-layout='vertical']
  .menu-toggle
  .nav-header
  .nav-control
  .hamburger
  .line,
[data-nav-headerbg='color_4'][data-sidebar-style='full'][data-layout='vertical']
  .menu-toggle
  .nav-header
  .nav-control
  .hamburger
  .line {
  background: #fff;
}

[data-nav-headerbg='color_5'][data-theme-version='dark'] .nav-header,
[data-nav-headerbg='color_5'] .nav-header {
  background-color: #c62828;
}

[data-nav-headerbg='color_5'][data-theme-version='dark']
  .nav-header
  .brand-logo,
[data-nav-headerbg='color_5'] .nav-header .brand-logo {
  color: #fff;
}

[data-nav-headerbg='color_5'][data-theme-version='dark']
  .nav-header
  .brand-logo:hover,
[data-nav-headerbg='color_5'] .nav-header .brand-logo:hover {
  color: #fff;
}

[data-nav-headerbg='color_5'][data-theme-version='dark']
  .nav-header
  .hamburger
  .line,
[data-nav-headerbg='color_5'] .nav-header .hamburger .line {
  background: #fff;
}

[data-nav-headerbg='color_5'][data-theme-version='dark'][data-sidebar-style='full'][data-layout='vertical']
  .menu-toggle
  .nav-header
  .nav-control
  .hamburger
  .line,
[data-nav-headerbg='color_5'][data-sidebar-style='full'][data-layout='vertical']
  .menu-toggle
  .nav-header
  .nav-control
  .hamburger
  .line {
  background: #fff;
}

[data-nav-headerbg='color_6'][data-theme-version='dark'] .nav-header,
[data-nav-headerbg='color_6'] .nav-header {
  background-color: #283593;
}

[data-nav-headerbg='color_6'][data-theme-version='dark']
  .nav-header
  .brand-logo,
[data-nav-headerbg='color_6'] .nav-header .brand-logo {
  color: #fff;
}

[data-nav-headerbg='color_6'][data-theme-version='dark']
  .nav-header
  .brand-logo:hover,
[data-nav-headerbg='color_6'] .nav-header .brand-logo:hover {
  color: #fff;
}

[data-nav-headerbg='color_6'][data-theme-version='dark']
  .nav-header
  .hamburger
  .line,
[data-nav-headerbg='color_6'] .nav-header .hamburger .line {
  background: #fff;
}

[data-nav-headerbg='color_6'][data-theme-version='dark'][data-sidebar-style='full'][data-layout='vertical']
  .menu-toggle
  .nav-header
  .nav-control
  .hamburger
  .line,
[data-nav-headerbg='color_6'][data-sidebar-style='full'][data-layout='vertical']
  .menu-toggle
  .nav-header
  .nav-control
  .hamburger
  .line {
  background: #fff;
}

[data-nav-headerbg='color_7'][data-theme-version='dark'] .nav-header,
[data-nav-headerbg='color_7'] .nav-header {
  background-color: #7356f1;
}

[data-nav-headerbg='color_7'][data-theme-version='dark']
  .nav-header
  .brand-logo,
[data-nav-headerbg='color_7'] .nav-header .brand-logo {
  color: #fff;
}

[data-nav-headerbg='color_7'][data-theme-version='dark']
  .nav-header
  .brand-logo:hover,
[data-nav-headerbg='color_7'] .nav-header .brand-logo:hover {
  color: #fff;
}

[data-nav-headerbg='color_7'][data-theme-version='dark']
  .nav-header
  .hamburger
  .line,
[data-nav-headerbg='color_7'] .nav-header .hamburger .line {
  background: #fff;
}

[data-nav-headerbg='color_7'][data-theme-version='dark'][data-sidebar-style='full'][data-layout='vertical']
  .menu-toggle
  .nav-header
  .nav-control
  .hamburger
  .line,
[data-nav-headerbg='color_7'][data-sidebar-style='full'][data-layout='vertical']
  .menu-toggle
  .nav-header
  .nav-control
  .hamburger
  .line {
  background: #fff;
}

[data-nav-headerbg='color_8'][data-theme-version='dark'] .nav-header,
[data-nav-headerbg='color_8'] .nav-header {
  background-color: #3695eb;
}

[data-nav-headerbg='color_8'][data-theme-version='dark']
  .nav-header
  .brand-logo,
[data-nav-headerbg='color_8'] .nav-header .brand-logo {
  color: #fff;
}

[data-nav-headerbg='color_8'][data-theme-version='dark']
  .nav-header
  .brand-logo:hover,
[data-nav-headerbg='color_8'] .nav-header .brand-logo:hover {
  color: #fff;
}

[data-nav-headerbg='color_8'][data-theme-version='dark']
  .nav-header
  .hamburger
  .line,
[data-nav-headerbg='color_8'] .nav-header .hamburger .line {
  background: #fff;
}

[data-nav-headerbg='color_8'][data-theme-version='dark'][data-sidebar-style='full'][data-layout='vertical']
  .menu-toggle
  .nav-header
  .nav-control
  .hamburger
  .line,
[data-nav-headerbg='color_8'][data-sidebar-style='full'][data-layout='vertical']
  .menu-toggle
  .nav-header
  .nav-control
  .hamburger
  .line {
  background: #fff;
}

[data-nav-headerbg='color_9'][data-theme-version='dark'] .nav-header,
[data-nav-headerbg='color_9'] .nav-header {
  background-color: #00838f;
}

[data-nav-headerbg='color_9'][data-theme-version='dark']
  .nav-header
  .brand-logo,
[data-nav-headerbg='color_9'] .nav-header .brand-logo {
  color: #fff;
}

[data-nav-headerbg='color_9'][data-theme-version='dark']
  .nav-header
  .brand-logo:hover,
[data-nav-headerbg='color_9'] .nav-header .brand-logo:hover {
  color: #fff;
}

[data-nav-headerbg='color_9'][data-theme-version='dark']
  .nav-header
  .hamburger
  .line,
[data-nav-headerbg='color_9'] .nav-header .hamburger .line {
  background: #fff;
}

[data-nav-headerbg='color_9'][data-theme-version='dark'][data-sidebar-style='full'][data-layout='vertical']
  .menu-toggle
  .nav-header
  .nav-control
  .hamburger
  .line,
[data-nav-headerbg='color_9'][data-sidebar-style='full'][data-layout='vertical']
  .menu-toggle
  .nav-header
  .nav-control
  .hamburger
  .line {
  background: #fff;
}

[data-nav-headerbg='color_10'][data-theme-version='dark'] .nav-header,
[data-nav-headerbg='color_10'] .nav-header {
  background-color: #ff8f16;
}

[data-nav-headerbg='color_10'][data-theme-version='dark']
  .nav-header
  .brand-logo,
[data-nav-headerbg='color_10'] .nav-header .brand-logo {
  color: #fff;
}

[data-nav-headerbg='color_10'][data-theme-version='dark']
  .nav-header
  .brand-logo:hover,
[data-nav-headerbg='color_10'] .nav-header .brand-logo:hover {
  color: #fff;
}

[data-nav-headerbg='color_10'][data-theme-version='dark']
  .nav-header
  .hamburger
  .line,
[data-nav-headerbg='color_10'] .nav-header .hamburger .line {
  background: #fff;
}

[data-nav-headerbg='color_10'][data-theme-version='dark'][data-sidebar-style='full'][data-layout='vertical']
  .menu-toggle
  .nav-header
  .nav-control
  .hamburger
  .line,
[data-nav-headerbg='color_10'][data-sidebar-style='full'][data-layout='vertical']
  .menu-toggle
  .nav-header
  .nav-control
  .hamburger
  .line {
  background: #fff;
}

[data-nav-headerbg='color_11'][data-theme-version='dark'] .nav-header,
[data-nav-headerbg='color_11'] .nav-header {
  background-color: #6673fd;
}

[data-nav-headerbg='color_11'][data-theme-version='dark']
  .nav-header
  .brand-logo,
[data-nav-headerbg='color_11'] .nav-header .brand-logo {
  color: #fff;
}

[data-nav-headerbg='color_11'][data-theme-version='dark']
  .nav-header
  .brand-logo:hover,
[data-nav-headerbg='color_11'] .nav-header .brand-logo:hover {
  color: #fff;
}

[data-nav-headerbg='color_11'][data-theme-version='dark']
  .nav-header
  .hamburger
  .line,
[data-nav-headerbg='color_11'] .nav-header .hamburger .line {
  background: #fff;
}

[data-nav-headerbg='color_11'][data-theme-version='dark'][data-sidebar-style='full'][data-layout='vertical']
  .menu-toggle
  .nav-header
  .nav-control
  .hamburger
  .line,
[data-nav-headerbg='color_11'][data-sidebar-style='full'][data-layout='vertical']
  .menu-toggle
  .nav-header
  .nav-control
  .hamburger
  .line {
  background: #fff;
}

[data-nav-headerbg='color_12'][data-theme-version='dark'] .nav-header,
[data-nav-headerbg='color_12'] .nav-header {
  background-color: #558b2f;
}

[data-nav-headerbg='color_12'][data-theme-version='dark']
  .nav-header
  .brand-logo,
[data-nav-headerbg='color_12'] .nav-header .brand-logo {
  color: #fff;
}

[data-nav-headerbg='color_12'][data-theme-version='dark']
  .nav-header
  .brand-logo:hover,
[data-nav-headerbg='color_12'] .nav-header .brand-logo:hover {
  color: #fff;
}

[data-nav-headerbg='color_12'][data-theme-version='dark']
  .nav-header
  .hamburger
  .line,
[data-nav-headerbg='color_12'] .nav-header .hamburger .line {
  background: #fff;
}

[data-nav-headerbg='color_12'][data-theme-version='dark'][data-sidebar-style='full'][data-layout='vertical']
  .menu-toggle
  .nav-header
  .nav-control
  .hamburger
  .line,
[data-nav-headerbg='color_12'][data-sidebar-style='full'][data-layout='vertical']
  .menu-toggle
  .nav-header
  .nav-control
  .hamburger
  .line {
  background: #fff;
}

[data-nav-headerbg='color_13'][data-theme-version='dark'] .nav-header,
[data-nav-headerbg='color_13'] .nav-header {
  background-color: #2a2a2a;
}

[data-nav-headerbg='color_13'][data-theme-version='dark']
  .nav-header
  .brand-logo,
[data-nav-headerbg='color_13'] .nav-header .brand-logo {
  color: #fff;
}

[data-nav-headerbg='color_13'][data-theme-version='dark']
  .nav-header
  .brand-logo:hover,
[data-nav-headerbg='color_13'] .nav-header .brand-logo:hover {
  color: #fff;
}

[data-nav-headerbg='color_13'][data-theme-version='dark']
  .nav-header
  .hamburger
  .line,
[data-nav-headerbg='color_13'] .nav-header .hamburger .line {
  background: #fff;
}

[data-nav-headerbg='color_13'][data-theme-version='dark'][data-sidebar-style='full'][data-layout='vertical']
  .menu-toggle
  .nav-header
  .nav-control
  .hamburger
  .line,
[data-nav-headerbg='color_13'][data-sidebar-style='full'][data-layout='vertical']
  .menu-toggle
  .nav-header
  .nav-control
  .hamburger
  .line {
  background: #fff;
}

[data-nav-headerbg='color_14'][data-theme-version='dark'] .nav-header,
[data-nav-headerbg='color_14'] .nav-header {
  background-color: #1367c8;
}

[data-nav-headerbg='color_14'][data-theme-version='dark']
  .nav-header
  .brand-logo,
[data-nav-headerbg='color_14'] .nav-header .brand-logo {
  color: #fff;
}

[data-nav-headerbg='color_14'][data-theme-version='dark']
  .nav-header
  .brand-logo:hover,
[data-nav-headerbg='color_14'] .nav-header .brand-logo:hover {
  color: #fff;
}

[data-nav-headerbg='color_14'][data-theme-version='dark']
  .nav-header
  .hamburger
  .line,
[data-nav-headerbg='color_14'] .nav-header .hamburger .line {
  background: #fff;
}

[data-nav-headerbg='color_14'][data-theme-version='dark'][data-sidebar-style='full'][data-layout='vertical']
  .menu-toggle
  .nav-header
  .nav-control
  .hamburger
  .line,
[data-nav-headerbg='color_14'][data-sidebar-style='full'][data-layout='vertical']
  .menu-toggle
  .nav-header
  .nav-control
  .hamburger
  .line {
  background: #fff;
}

[data-nav-headerbg='color_15'][data-theme-version='dark'] .nav-header,
[data-nav-headerbg='color_15'] .nav-header {
  background-color: #ed0b4c;
}

[data-nav-headerbg='color_15'][data-theme-version='dark']
  .nav-header
  .brand-logo,
[data-nav-headerbg='color_15'] .nav-header .brand-logo {
  color: #fff;
}

[data-nav-headerbg='color_15'][data-theme-version='dark']
  .nav-header
  .brand-logo:hover,
[data-nav-headerbg='color_15'] .nav-header .brand-logo:hover {
  color: #fff;
}

[data-nav-headerbg='color_15'][data-theme-version='dark']
  .nav-header
  .hamburger
  .line,
[data-nav-headerbg='color_15'] .nav-header .hamburger .line {
  background: #fff;
}

[data-nav-headerbg='color_15'][data-theme-version='dark'][data-sidebar-style='full'][data-layout='vertical']
  .menu-toggle
  .nav-header
  .nav-control
  .hamburger
  .line,
[data-nav-headerbg='color_15'][data-sidebar-style='full'][data-layout='vertical']
  .menu-toggle
  .nav-header
  .nav-control
  .hamburger
  .line {
  background: #fff;
}

[data-headerbg='color_2'][data-theme-version='dark'] .header,
[data-headerbg='color_2'] .header {
  background-color: #143b64;
  color: #fff;
}

[data-headerbg='color_2'][data-theme-version='dark'] .header-left input,
[data-headerbg='color_2'] .header-left input {
  border-color: #1d548f;
  background-color: #1d548f !important;
  color: #fff;
}

[data-headerbg='color_2'][data-theme-version='dark']
  .header-left
  input::placeholder,
[data-headerbg='color_2'] .header-left input::placeholder {
  color: #fff;
}

[data-headerbg='color_2'][data-theme-version='dark'] .header-left input:focus,
[data-headerbg='color_2'] .header-left input:focus {
  border-color: #0c223a;
}

[data-headerbg='color_2'][data-theme-version='dark'] .header-left .search_icon,
[data-headerbg='color_2'] .header-left .search_icon {
  background-color: #1d548f !important;
}

[data-headerbg='color_2'][data-theme-version='dark']
  .header-left
  .search_icon
  i,
[data-headerbg='color_2'] .header-left .search_icon i {
  color: #fff;
}

[data-headerbg='color_2'][data-theme-version='dark'] .header-left .btn,
[data-headerbg='color_2'] .header-left .btn {
  background-color: #0c223a;
  border-color: #0c223a;
}

[data-headerbg='color_2'][data-theme-version='dark'] .header-left .btn:hover,
[data-headerbg='color_2'][data-theme-version='dark'] .header-left .btn:focus,
[data-headerbg='color_2'][data-theme-version='dark'] .header-left .btn:active,
[data-headerbg='color_2'] .header-left .btn:hover,
[data-headerbg='color_2'] .header-left .btn:focus,
[data-headerbg='color_2'] .header-left .btn:active {
  background-color: #03090f;
  border-color: #03090f;
}

[data-headerbg='color_2'][data-theme-version='dark']
  .header-right
  .dropdown
  > a,
[data-headerbg='color_2'] .header-right .dropdown > a {
  color: #fff;
}

[data-headerbg='color_2'][data-theme-version='dark'] .header-right .pulse-css,
[data-headerbg='color_2'] .header-right .pulse-css {
  background-color: #fff;
}

[data-headerbg='color_2'][data-theme-version='dark']
  .header-right
  .pulse-css:after,
[data-headerbg='color_2'][data-theme-version='dark']
  .header-right
  .pulse-css:before,
[data-headerbg='color_2'] .header-right .pulse-css:after,
[data-headerbg='color_2'] .header-right .pulse-css:before {
  background-color: inherit;
}

[data-headerbg='color_2'][data-theme-version='dark']
  .header-right
  .notification_dropdown
  .nav-link,
[data-headerbg='color_2'] .header-right .notification_dropdown .nav-link {
  background-color: #1d548f !important;
}

[data-headerbg='color_2'][data-theme-version='dark']
  .header-right
  .right-sidebar
  a,
[data-headerbg='color_2'] .header-right .right-sidebar a {
  border-color: rgba(255, 255, 255, 0.1);
  color: #fff;
}

[data-headerbg='color_2'][data-theme-version='dark']
  .header-right
  .header-profile
  > a.nav-link
  .header-info
  strong,
[data-headerbg='color_2']
  .header-right
  .header-profile
  > a.nav-link
  .header-info
  strong {
  color: #fff;
}

[data-headerbg='color_2'][data-theme-version='dark']
  .header-right
  .header-profile
  > a.nav-link
  .header-info
  span,
[data-headerbg='color_2'][data-theme-version='dark']
  .header-right
  .header-profile
  > a.nav-link
  .header-info
  small,
[data-headerbg='color_2']
  .header-right
  .header-profile
  > a.nav-link
  .header-info
  span,
[data-headerbg='color_2']
  .header-right
  .header-profile
  > a.nav-link
  .header-info
  small {
  color: rgba(255, 255, 255, 0.7);
}

[data-headerbg='color_2'][data-theme-version='dark'] .nav-control,
[data-headerbg='color_2'] .nav-control {
  background-color: #1d548f;
  color: #fff;
}

[data-headerbg='color_3'][data-theme-version='dark'] .header,
[data-headerbg='color_3'] .header {
  background-color: #0b2a97;
  color: #fff;
}

[data-headerbg='color_3'][data-theme-version='dark'] .header-left input,
[data-headerbg='color_3'] .header-left input {
  border-color: #0e37c7;
  background-color: #0e37c7 !important;
  color: #fff;
}

[data-headerbg='color_3'][data-theme-version='dark']
  .header-left
  input::placeholder,
[data-headerbg='color_3'] .header-left input::placeholder {
  color: #fff;
}

[data-headerbg='color_3'][data-theme-version='dark'] .header-left input:focus,
[data-headerbg='color_3'] .header-left input:focus {
  border-color: #081d67;
}

[data-headerbg='color_3'][data-theme-version='dark'] .header-left .search_icon,
[data-headerbg='color_3'] .header-left .search_icon {
  background-color: #0e37c7 !important;
}

[data-headerbg='color_3'][data-theme-version='dark']
  .header-left
  .search_icon
  i,
[data-headerbg='color_3'] .header-left .search_icon i {
  color: #fff;
}

[data-headerbg='color_3'][data-theme-version='dark'] .header-left .btn,
[data-headerbg='color_3'] .header-left .btn {
  background-color: #081d67;
  border-color: #081d67;
}

[data-headerbg='color_3'][data-theme-version='dark'] .header-left .btn:hover,
[data-headerbg='color_3'][data-theme-version='dark'] .header-left .btn:focus,
[data-headerbg='color_3'][data-theme-version='dark'] .header-left .btn:active,
[data-headerbg='color_3'] .header-left .btn:hover,
[data-headerbg='color_3'] .header-left .btn:focus,
[data-headerbg='color_3'] .header-left .btn:active {
  background-color: #041038;
  border-color: #041038;
}

[data-headerbg='color_3'][data-theme-version='dark']
  .header-right
  .dropdown
  > a,
[data-headerbg='color_3'] .header-right .dropdown > a {
  color: #fff;
}

[data-headerbg='color_3'][data-theme-version='dark'] .header-right .pulse-css,
[data-headerbg='color_3'] .header-right .pulse-css {
  background-color: #fff;
}

[data-headerbg='color_3'][data-theme-version='dark']
  .header-right
  .pulse-css:after,
[data-headerbg='color_3'][data-theme-version='dark']
  .header-right
  .pulse-css:before,
[data-headerbg='color_3'] .header-right .pulse-css:after,
[data-headerbg='color_3'] .header-right .pulse-css:before {
  background-color: inherit;
}

[data-headerbg='color_3'][data-theme-version='dark']
  .header-right
  .notification_dropdown
  .nav-link,
[data-headerbg='color_3'] .header-right .notification_dropdown .nav-link {
  background-color: #0e37c7 !important;
}

[data-headerbg='color_3'][data-theme-version='dark']
  .header-right
  .right-sidebar
  a,
[data-headerbg='color_3'] .header-right .right-sidebar a {
  border-color: rgba(255, 255, 255, 0.1);
  color: #fff;
}

[data-headerbg='color_3'][data-theme-version='dark']
  .header-right
  .header-profile
  > a.nav-link
  .header-info
  strong,
[data-headerbg='color_3']
  .header-right
  .header-profile
  > a.nav-link
  .header-info
  strong {
  color: #fff;
}

[data-headerbg='color_3'][data-theme-version='dark']
  .header-right
  .header-profile
  > a.nav-link
  .header-info
  span,
[data-headerbg='color_3'][data-theme-version='dark']
  .header-right
  .header-profile
  > a.nav-link
  .header-info
  small,
[data-headerbg='color_3']
  .header-right
  .header-profile
  > a.nav-link
  .header-info
  span,
[data-headerbg='color_3']
  .header-right
  .header-profile
  > a.nav-link
  .header-info
  small {
  color: rgba(255, 255, 255, 0.7);
}

[data-headerbg='color_3'][data-theme-version='dark'] .nav-control,
[data-headerbg='color_3'] .nav-control {
  background-color: #0e37c7;
  color: #fff;
}

[data-headerbg='color_4'][data-theme-version='dark'] .header,
[data-headerbg='color_4'] .header {
  background-color: #4527a0;
  color: #fff;
}

[data-headerbg='color_4'][data-theme-version='dark'] .header-left input,
[data-headerbg='color_4'] .header-left input {
  border-color: #5731c9;
  background-color: #5731c9 !important;
  color: #fff;
}

[data-headerbg='color_4'][data-theme-version='dark']
  .header-left
  input::placeholder,
[data-headerbg='color_4'] .header-left input::placeholder {
  color: #fff;
}

[data-headerbg='color_4'][data-theme-version='dark'] .header-left input:focus,
[data-headerbg='color_4'] .header-left input:focus {
  border-color: #331d77;
}

[data-headerbg='color_4'][data-theme-version='dark'] .header-left .search_icon,
[data-headerbg='color_4'] .header-left .search_icon {
  background-color: #5731c9 !important;
}

[data-headerbg='color_4'][data-theme-version='dark']
  .header-left
  .search_icon
  i,
[data-headerbg='color_4'] .header-left .search_icon i {
  color: #fff;
}

[data-headerbg='color_4'][data-theme-version='dark'] .header-left .btn,
[data-headerbg='color_4'] .header-left .btn {
  background-color: #331d77;
  border-color: #331d77;
}

[data-headerbg='color_4'][data-theme-version='dark'] .header-left .btn:hover,
[data-headerbg='color_4'][data-theme-version='dark'] .header-left .btn:focus,
[data-headerbg='color_4'][data-theme-version='dark'] .header-left .btn:active,
[data-headerbg='color_4'] .header-left .btn:hover,
[data-headerbg='color_4'] .header-left .btn:focus,
[data-headerbg='color_4'] .header-left .btn:active {
  background-color: #22134e;
  border-color: #22134e;
}

[data-headerbg='color_4'][data-theme-version='dark']
  .header-right
  .dropdown
  > a,
[data-headerbg='color_4'] .header-right .dropdown > a {
  color: #fff;
}

[data-headerbg='color_4'][data-theme-version='dark'] .header-right .pulse-css,
[data-headerbg='color_4'] .header-right .pulse-css {
  background-color: #fff;
}

[data-headerbg='color_4'][data-theme-version='dark']
  .header-right
  .pulse-css:after,
[data-headerbg='color_4'][data-theme-version='dark']
  .header-right
  .pulse-css:before,
[data-headerbg='color_4'] .header-right .pulse-css:after,
[data-headerbg='color_4'] .header-right .pulse-css:before {
  background-color: inherit;
}

[data-headerbg='color_4'][data-theme-version='dark']
  .header-right
  .notification_dropdown
  .nav-link,
[data-headerbg='color_4'] .header-right .notification_dropdown .nav-link {
  background-color: #5731c9 !important;
}

[data-headerbg='color_4'][data-theme-version='dark']
  .header-right
  .right-sidebar
  a,
[data-headerbg='color_4'] .header-right .right-sidebar a {
  border-color: rgba(255, 255, 255, 0.1);
  color: #fff;
}

[data-headerbg='color_4'][data-theme-version='dark']
  .header-right
  .header-profile
  > a.nav-link
  .header-info
  strong,
[data-headerbg='color_4']
  .header-right
  .header-profile
  > a.nav-link
  .header-info
  strong {
  color: #fff;
}

[data-headerbg='color_4'][data-theme-version='dark']
  .header-right
  .header-profile
  > a.nav-link
  .header-info
  span,
[data-headerbg='color_4'][data-theme-version='dark']
  .header-right
  .header-profile
  > a.nav-link
  .header-info
  small,
[data-headerbg='color_4']
  .header-right
  .header-profile
  > a.nav-link
  .header-info
  span,
[data-headerbg='color_4']
  .header-right
  .header-profile
  > a.nav-link
  .header-info
  small {
  color: rgba(255, 255, 255, 0.7);
}

[data-headerbg='color_4'][data-theme-version='dark'] .nav-control,
[data-headerbg='color_4'] .nav-control {
  background-color: #5731c9;
  color: #fff;
}

[data-headerbg='color_5'][data-theme-version='dark'] .header,
[data-headerbg='color_5'] .header {
  background-color: #c62828;
  color: #fff;
}

[data-headerbg='color_5'][data-theme-version='dark'] .header-left input,
[data-headerbg='color_5'] .header-left input {
  border-color: #da4747;
  background-color: #da4747 !important;
  color: #fff;
}

[data-headerbg='color_5'][data-theme-version='dark']
  .header-left
  input::placeholder,
[data-headerbg='color_5'] .header-left input::placeholder {
  color: #fff;
}

[data-headerbg='color_5'][data-theme-version='dark'] .header-left input:focus,
[data-headerbg='color_5'] .header-left input:focus {
  border-color: #9c1f1f;
}

[data-headerbg='color_5'][data-theme-version='dark'] .header-left .search_icon,
[data-headerbg='color_5'] .header-left .search_icon {
  background-color: #da4747 !important;
}

[data-headerbg='color_5'][data-theme-version='dark']
  .header-left
  .search_icon
  i,
[data-headerbg='color_5'] .header-left .search_icon i {
  color: #fff;
}

[data-headerbg='color_5'][data-theme-version='dark'] .header-left .btn,
[data-headerbg='color_5'] .header-left .btn {
  background-color: #9c1f1f;
  border-color: #9c1f1f;
}

[data-headerbg='color_5'][data-theme-version='dark'] .header-left .btn:hover,
[data-headerbg='color_5'][data-theme-version='dark'] .header-left .btn:focus,
[data-headerbg='color_5'][data-theme-version='dark'] .header-left .btn:active,
[data-headerbg='color_5'] .header-left .btn:hover,
[data-headerbg='color_5'] .header-left .btn:focus,
[data-headerbg='color_5'] .header-left .btn:active {
  background-color: #711717;
  border-color: #711717;
}

[data-headerbg='color_5'][data-theme-version='dark']
  .header-right
  .dropdown
  > a,
[data-headerbg='color_5'] .header-right .dropdown > a {
  color: #fff;
}

[data-headerbg='color_5'][data-theme-version='dark'] .header-right .pulse-css,
[data-headerbg='color_5'] .header-right .pulse-css {
  background-color: #fff;
}

[data-headerbg='color_5'][data-theme-version='dark']
  .header-right
  .pulse-css:after,
[data-headerbg='color_5'][data-theme-version='dark']
  .header-right
  .pulse-css:before,
[data-headerbg='color_5'] .header-right .pulse-css:after,
[data-headerbg='color_5'] .header-right .pulse-css:before {
  background-color: inherit;
}

[data-headerbg='color_5'][data-theme-version='dark']
  .header-right
  .notification_dropdown
  .nav-link,
[data-headerbg='color_5'] .header-right .notification_dropdown .nav-link {
  background-color: #da4747 !important;
}

[data-headerbg='color_5'][data-theme-version='dark']
  .header-right
  .right-sidebar
  a,
[data-headerbg='color_5'] .header-right .right-sidebar a {
  border-color: rgba(255, 255, 255, 0.1);
  color: #fff;
}

[data-headerbg='color_5'][data-theme-version='dark']
  .header-right
  .header-profile
  > a.nav-link
  .header-info
  strong,
[data-headerbg='color_5']
  .header-right
  .header-profile
  > a.nav-link
  .header-info
  strong {
  color: #fff;
}

[data-headerbg='color_5'][data-theme-version='dark']
  .header-right
  .header-profile
  > a.nav-link
  .header-info
  span,
[data-headerbg='color_5'][data-theme-version='dark']
  .header-right
  .header-profile
  > a.nav-link
  .header-info
  small,
[data-headerbg='color_5']
  .header-right
  .header-profile
  > a.nav-link
  .header-info
  span,
[data-headerbg='color_5']
  .header-right
  .header-profile
  > a.nav-link
  .header-info
  small {
  color: rgba(255, 255, 255, 0.7);
}

[data-headerbg='color_5'][data-theme-version='dark'] .nav-control,
[data-headerbg='color_5'] .nav-control {
  background-color: #da4747;
  color: #fff;
}

[data-headerbg='color_6'][data-theme-version='dark'] .header,
[data-headerbg='color_6'] .header {
  background-color: #283593;
  color: #fff;
}

[data-headerbg='color_6'][data-theme-version='dark'] .header-left input,
[data-headerbg='color_6'] .header-left input {
  border-color: #3343bb;
  background-color: #3343bb !important;
  color: #fff;
}

[data-headerbg='color_6'][data-theme-version='dark']
  .header-left
  input::placeholder,
[data-headerbg='color_6'] .header-left input::placeholder {
  color: #fff;
}

[data-headerbg='color_6'][data-theme-version='dark'] .header-left input:focus,
[data-headerbg='color_6'] .header-left input:focus {
  border-color: #1d276b;
}

[data-headerbg='color_6'][data-theme-version='dark'] .header-left .search_icon,
[data-headerbg='color_6'] .header-left .search_icon {
  background-color: #3343bb !important;
}

[data-headerbg='color_6'][data-theme-version='dark']
  .header-left
  .search_icon
  i,
[data-headerbg='color_6'] .header-left .search_icon i {
  color: #fff;
}

[data-headerbg='color_6'][data-theme-version='dark'] .header-left .btn,
[data-headerbg='color_6'] .header-left .btn {
  background-color: #1d276b;
  border-color: #1d276b;
}

[data-headerbg='color_6'][data-theme-version='dark'] .header-left .btn:hover,
[data-headerbg='color_6'][data-theme-version='dark'] .header-left .btn:focus,
[data-headerbg='color_6'][data-theme-version='dark'] .header-left .btn:active,
[data-headerbg='color_6'] .header-left .btn:hover,
[data-headerbg='color_6'] .header-left .btn:focus,
[data-headerbg='color_6'] .header-left .btn:active {
  background-color: #121843;
  border-color: #121843;
}

[data-headerbg='color_6'][data-theme-version='dark']
  .header-right
  .dropdown
  > a,
[data-headerbg='color_6'] .header-right .dropdown > a {
  color: #fff;
}

[data-headerbg='color_6'][data-theme-version='dark'] .header-right .pulse-css,
[data-headerbg='color_6'] .header-right .pulse-css {
  background-color: #fff;
}

[data-headerbg='color_6'][data-theme-version='dark']
  .header-right
  .pulse-css:after,
[data-headerbg='color_6'][data-theme-version='dark']
  .header-right
  .pulse-css:before,
[data-headerbg='color_6'] .header-right .pulse-css:after,
[data-headerbg='color_6'] .header-right .pulse-css:before {
  background-color: inherit;
}

[data-headerbg='color_6'][data-theme-version='dark']
  .header-right
  .notification_dropdown
  .nav-link,
[data-headerbg='color_6'] .header-right .notification_dropdown .nav-link {
  background-color: #3343bb !important;
}

[data-headerbg='color_6'][data-theme-version='dark']
  .header-right
  .right-sidebar
  a,
[data-headerbg='color_6'] .header-right .right-sidebar a {
  border-color: rgba(255, 255, 255, 0.1);
  color: #fff;
}

[data-headerbg='color_6'][data-theme-version='dark']
  .header-right
  .header-profile
  > a.nav-link
  .header-info
  strong,
[data-headerbg='color_6']
  .header-right
  .header-profile
  > a.nav-link
  .header-info
  strong {
  color: #fff;
}

[data-headerbg='color_6'][data-theme-version='dark']
  .header-right
  .header-profile
  > a.nav-link
  .header-info
  span,
[data-headerbg='color_6'][data-theme-version='dark']
  .header-right
  .header-profile
  > a.nav-link
  .header-info
  small,
[data-headerbg='color_6']
  .header-right
  .header-profile
  > a.nav-link
  .header-info
  span,
[data-headerbg='color_6']
  .header-right
  .header-profile
  > a.nav-link
  .header-info
  small {
  color: rgba(255, 255, 255, 0.7);
}

[data-headerbg='color_6'][data-theme-version='dark'] .nav-control,
[data-headerbg='color_6'] .nav-control {
  background-color: #3343bb;
  color: #fff;
}

[data-headerbg='color_7'][data-theme-version='dark'] .header,
[data-headerbg='color_7'] .header {
  background-color: #7356f1;
  color: #fff;
}

[data-headerbg='color_7'][data-theme-version='dark'] .header-left input,
[data-headerbg='color_7'] .header-left input {
  border-color: #9a85f5;
  background-color: #9a85f5 !important;
  color: #fff;
}

[data-headerbg='color_7'][data-theme-version='dark']
  .header-left
  input::placeholder,
[data-headerbg='color_7'] .header-left input::placeholder {
  color: #fff;
}

[data-headerbg='color_7'][data-theme-version='dark'] .header-left input:focus,
[data-headerbg='color_7'] .header-left input:focus {
  border-color: #4c27ed;
}

[data-headerbg='color_7'][data-theme-version='dark'] .header-left .search_icon,
[data-headerbg='color_7'] .header-left .search_icon {
  background-color: #9a85f5 !important;
}

[data-headerbg='color_7'][data-theme-version='dark']
  .header-left
  .search_icon
  i,
[data-headerbg='color_7'] .header-left .search_icon i {
  color: #fff;
}

[data-headerbg='color_7'][data-theme-version='dark'] .header-left .btn,
[data-headerbg='color_7'] .header-left .btn {
  background-color: #4c27ed;
  border-color: #4c27ed;
}

[data-headerbg='color_7'][data-theme-version='dark'] .header-left .btn:hover,
[data-headerbg='color_7'][data-theme-version='dark'] .header-left .btn:focus,
[data-headerbg='color_7'][data-theme-version='dark'] .header-left .btn:active,
[data-headerbg='color_7'] .header-left .btn:hover,
[data-headerbg='color_7'] .header-left .btn:focus,
[data-headerbg='color_7'] .header-left .btn:active {
  background-color: #3511d0;
  border-color: #3511d0;
}

[data-headerbg='color_7'][data-theme-version='dark']
  .header-right
  .dropdown
  > a,
[data-headerbg='color_7'] .header-right .dropdown > a {
  color: #fff;
}

[data-headerbg='color_7'][data-theme-version='dark'] .header-right .pulse-css,
[data-headerbg='color_7'] .header-right .pulse-css {
  background-color: #fff;
}

[data-headerbg='color_7'][data-theme-version='dark']
  .header-right
  .pulse-css:after,
[data-headerbg='color_7'][data-theme-version='dark']
  .header-right
  .pulse-css:before,
[data-headerbg='color_7'] .header-right .pulse-css:after,
[data-headerbg='color_7'] .header-right .pulse-css:before {
  background-color: inherit;
}

[data-headerbg='color_7'][data-theme-version='dark']
  .header-right
  .notification_dropdown
  .nav-link,
[data-headerbg='color_7'] .header-right .notification_dropdown .nav-link {
  background-color: #9a85f5 !important;
}

[data-headerbg='color_7'][data-theme-version='dark']
  .header-right
  .right-sidebar
  a,
[data-headerbg='color_7'] .header-right .right-sidebar a {
  border-color: rgba(255, 255, 255, 0.1);
  color: #fff;
}

[data-headerbg='color_7'][data-theme-version='dark']
  .header-right
  .header-profile
  > a.nav-link
  .header-info
  strong,
[data-headerbg='color_7']
  .header-right
  .header-profile
  > a.nav-link
  .header-info
  strong {
  color: #fff;
}

[data-headerbg='color_7'][data-theme-version='dark']
  .header-right
  .header-profile
  > a.nav-link
  .header-info
  span,
[data-headerbg='color_7'][data-theme-version='dark']
  .header-right
  .header-profile
  > a.nav-link
  .header-info
  small,
[data-headerbg='color_7']
  .header-right
  .header-profile
  > a.nav-link
  .header-info
  span,
[data-headerbg='color_7']
  .header-right
  .header-profile
  > a.nav-link
  .header-info
  small {
  color: rgba(255, 255, 255, 0.7);
}

[data-headerbg='color_7'][data-theme-version='dark'] .nav-control,
[data-headerbg='color_7'] .nav-control {
  background-color: #9a85f5;
  color: #fff;
}

[data-headerbg='color_8'][data-theme-version='dark'] .header,
[data-headerbg='color_8'] .header {
  background-color: #3695eb;
  color: #fff;
}

[data-headerbg='color_8'][data-theme-version='dark'] .header-left input,
[data-headerbg='color_8'] .header-left input {
  border-color: #64adf0;
  background-color: #64adf0 !important;
  color: #fff;
}

[data-headerbg='color_8'][data-theme-version='dark']
  .header-left
  input::placeholder,
[data-headerbg='color_8'] .header-left input::placeholder {
  color: #fff;
}

[data-headerbg='color_8'][data-theme-version='dark'] .header-left input:focus,
[data-headerbg='color_8'] .header-left input:focus {
  border-color: #167cd8;
}

[data-headerbg='color_8'][data-theme-version='dark'] .header-left .search_icon,
[data-headerbg='color_8'] .header-left .search_icon {
  background-color: #64adf0 !important;
}

[data-headerbg='color_8'][data-theme-version='dark']
  .header-left
  .search_icon
  i,
[data-headerbg='color_8'] .header-left .search_icon i {
  color: #fff;
}

[data-headerbg='color_8'][data-theme-version='dark'] .header-left .btn,
[data-headerbg='color_8'] .header-left .btn {
  background-color: #167cd8;
  border-color: #167cd8;
}

[data-headerbg='color_8'][data-theme-version='dark'] .header-left .btn:hover,
[data-headerbg='color_8'][data-theme-version='dark'] .header-left .btn:focus,
[data-headerbg='color_8'][data-theme-version='dark'] .header-left .btn:active,
[data-headerbg='color_8'] .header-left .btn:hover,
[data-headerbg='color_8'] .header-left .btn:focus,
[data-headerbg='color_8'] .header-left .btn:active {
  background-color: #1161aa;
  border-color: #1161aa;
}

[data-headerbg='color_8'][data-theme-version='dark']
  .header-right
  .dropdown
  > a,
[data-headerbg='color_8'] .header-right .dropdown > a {
  color: #fff;
}

[data-headerbg='color_8'][data-theme-version='dark'] .header-right .pulse-css,
[data-headerbg='color_8'] .header-right .pulse-css {
  background-color: #fff;
}

[data-headerbg='color_8'][data-theme-version='dark']
  .header-right
  .pulse-css:after,
[data-headerbg='color_8'][data-theme-version='dark']
  .header-right
  .pulse-css:before,
[data-headerbg='color_8'] .header-right .pulse-css:after,
[data-headerbg='color_8'] .header-right .pulse-css:before {
  background-color: inherit;
}

[data-headerbg='color_8'][data-theme-version='dark']
  .header-right
  .notification_dropdown
  .nav-link,
[data-headerbg='color_8'] .header-right .notification_dropdown .nav-link {
  background-color: #64adf0 !important;
}

[data-headerbg='color_8'][data-theme-version='dark']
  .header-right
  .right-sidebar
  a,
[data-headerbg='color_8'] .header-right .right-sidebar a {
  border-color: rgba(255, 255, 255, 0.1);
  color: #fff;
}

[data-headerbg='color_8'][data-theme-version='dark']
  .header-right
  .header-profile
  > a.nav-link
  .header-info
  strong,
[data-headerbg='color_8']
  .header-right
  .header-profile
  > a.nav-link
  .header-info
  strong {
  color: #fff;
}

[data-headerbg='color_8'][data-theme-version='dark']
  .header-right
  .header-profile
  > a.nav-link
  .header-info
  span,
[data-headerbg='color_8'][data-theme-version='dark']
  .header-right
  .header-profile
  > a.nav-link
  .header-info
  small,
[data-headerbg='color_8']
  .header-right
  .header-profile
  > a.nav-link
  .header-info
  span,
[data-headerbg='color_8']
  .header-right
  .header-profile
  > a.nav-link
  .header-info
  small {
  color: rgba(255, 255, 255, 0.7);
}

[data-headerbg='color_8'][data-theme-version='dark'] .nav-control,
[data-headerbg='color_8'] .nav-control {
  background-color: #64adf0;
  color: #fff;
}

[data-headerbg='color_9'][data-theme-version='dark'] .header,
[data-headerbg='color_9'] .header {
  background-color: #00838f;
  color: #fff;
}

[data-headerbg='color_9'][data-theme-version='dark'] .header-left input,
[data-headerbg='color_9'] .header-left input {
  border-color: #00b2c2;
  background-color: #00b2c2 !important;
  color: #fff;
}

[data-headerbg='color_9'][data-theme-version='dark']
  .header-left
  input::placeholder,
[data-headerbg='color_9'] .header-left input::placeholder {
  color: #fff;
}

[data-headerbg='color_9'][data-theme-version='dark'] .header-left input:focus,
[data-headerbg='color_9'] .header-left input:focus {
  border-color: #00545c;
}

[data-headerbg='color_9'][data-theme-version='dark'] .header-left .search_icon,
[data-headerbg='color_9'] .header-left .search_icon {
  background-color: #00b2c2 !important;
}

[data-headerbg='color_9'][data-theme-version='dark']
  .header-left
  .search_icon
  i,
[data-headerbg='color_9'] .header-left .search_icon i {
  color: #fff;
}

[data-headerbg='color_9'][data-theme-version='dark'] .header-left .btn,
[data-headerbg='color_9'] .header-left .btn {
  background-color: #00545c;
  border-color: #00545c;
}

[data-headerbg='color_9'][data-theme-version='dark'] .header-left .btn:hover,
[data-headerbg='color_9'][data-theme-version='dark'] .header-left .btn:focus,
[data-headerbg='color_9'][data-theme-version='dark'] .header-left .btn:active,
[data-headerbg='color_9'] .header-left .btn:hover,
[data-headerbg='color_9'] .header-left .btn:focus,
[data-headerbg='color_9'] .header-left .btn:active {
  background-color: #002629;
  border-color: #002629;
}

[data-headerbg='color_9'][data-theme-version='dark']
  .header-right
  .dropdown
  > a,
[data-headerbg='color_9'] .header-right .dropdown > a {
  color: #fff;
}

[data-headerbg='color_9'][data-theme-version='dark'] .header-right .pulse-css,
[data-headerbg='color_9'] .header-right .pulse-css {
  background-color: #fff;
}

[data-headerbg='color_9'][data-theme-version='dark']
  .header-right
  .pulse-css:after,
[data-headerbg='color_9'][data-theme-version='dark']
  .header-right
  .pulse-css:before,
[data-headerbg='color_9'] .header-right .pulse-css:after,
[data-headerbg='color_9'] .header-right .pulse-css:before {
  background-color: inherit;
}

[data-headerbg='color_9'][data-theme-version='dark']
  .header-right
  .notification_dropdown
  .nav-link,
[data-headerbg='color_9'] .header-right .notification_dropdown .nav-link {
  background-color: #00b2c2 !important;
}

[data-headerbg='color_9'][data-theme-version='dark']
  .header-right
  .right-sidebar
  a,
[data-headerbg='color_9'] .header-right .right-sidebar a {
  border-color: rgba(255, 255, 255, 0.1);
  color: #fff;
}

[data-headerbg='color_9'][data-theme-version='dark']
  .header-right
  .header-profile
  > a.nav-link
  .header-info
  strong,
[data-headerbg='color_9']
  .header-right
  .header-profile
  > a.nav-link
  .header-info
  strong {
  color: #fff;
}

[data-headerbg='color_9'][data-theme-version='dark']
  .header-right
  .header-profile
  > a.nav-link
  .header-info
  span,
[data-headerbg='color_9'][data-theme-version='dark']
  .header-right
  .header-profile
  > a.nav-link
  .header-info
  small,
[data-headerbg='color_9']
  .header-right
  .header-profile
  > a.nav-link
  .header-info
  span,
[data-headerbg='color_9']
  .header-right
  .header-profile
  > a.nav-link
  .header-info
  small {
  color: rgba(255, 255, 255, 0.7);
}

[data-headerbg='color_9'][data-theme-version='dark'] .nav-control,
[data-headerbg='color_9'] .nav-control {
  background-color: #00b2c2;
  color: #fff;
}

[data-headerbg='color_10'][data-theme-version='dark'] .header,
[data-headerbg='color_10'] .header {
  background-color: #ff8f16;
  color: #fff;
}

[data-headerbg='color_10'][data-theme-version='dark'] .header-left input,
[data-headerbg='color_10'] .header-left input {
  border-color: #ffa849;
  background-color: #ffa849 !important;
  color: #fff;
}

[data-headerbg='color_10'][data-theme-version='dark']
  .header-left
  input::placeholder,
[data-headerbg='color_10'] .header-left input::placeholder {
  color: #fff;
}

[data-headerbg='color_10'][data-theme-version='dark'] .header-left input:focus,
[data-headerbg='color_10'] .header-left input:focus {
  border-color: #e27500;
}

[data-headerbg='color_10'][data-theme-version='dark'] .header-left .search_icon,
[data-headerbg='color_10'] .header-left .search_icon {
  background-color: #ffa849 !important;
}

[data-headerbg='color_10'][data-theme-version='dark']
  .header-left
  .search_icon
  i,
[data-headerbg='color_10'] .header-left .search_icon i {
  color: #fff;
}

[data-headerbg='color_10'][data-theme-version='dark'] .header-left .btn,
[data-headerbg='color_10'] .header-left .btn {
  background-color: #e27500;
  border-color: #e27500;
}

[data-headerbg='color_10'][data-theme-version='dark'] .header-left .btn:hover,
[data-headerbg='color_10'][data-theme-version='dark'] .header-left .btn:focus,
[data-headerbg='color_10'][data-theme-version='dark'] .header-left .btn:active,
[data-headerbg='color_10'] .header-left .btn:hover,
[data-headerbg='color_10'] .header-left .btn:focus,
[data-headerbg='color_10'] .header-left .btn:active {
  background-color: #af5b00;
  border-color: #af5b00;
}

[data-headerbg='color_10'][data-theme-version='dark']
  .header-right
  .dropdown
  > a,
[data-headerbg='color_10'] .header-right .dropdown > a {
  color: #fff;
}

[data-headerbg='color_10'][data-theme-version='dark'] .header-right .pulse-css,
[data-headerbg='color_10'] .header-right .pulse-css {
  background-color: #fff;
}

[data-headerbg='color_10'][data-theme-version='dark']
  .header-right
  .pulse-css:after,
[data-headerbg='color_10'][data-theme-version='dark']
  .header-right
  .pulse-css:before,
[data-headerbg='color_10'] .header-right .pulse-css:after,
[data-headerbg='color_10'] .header-right .pulse-css:before {
  background-color: inherit;
}

[data-headerbg='color_10'][data-theme-version='dark']
  .header-right
  .notification_dropdown
  .nav-link,
[data-headerbg='color_10'] .header-right .notification_dropdown .nav-link {
  background-color: #ffa849 !important;
}

[data-headerbg='color_10'][data-theme-version='dark']
  .header-right
  .right-sidebar
  a,
[data-headerbg='color_10'] .header-right .right-sidebar a {
  border-color: rgba(255, 255, 255, 0.1);
  color: #fff;
}

[data-headerbg='color_10'][data-theme-version='dark']
  .header-right
  .header-profile
  > a.nav-link
  .header-info
  strong,
[data-headerbg='color_10']
  .header-right
  .header-profile
  > a.nav-link
  .header-info
  strong {
  color: #fff;
}

[data-headerbg='color_10'][data-theme-version='dark']
  .header-right
  .header-profile
  > a.nav-link
  .header-info
  span,
[data-headerbg='color_10'][data-theme-version='dark']
  .header-right
  .header-profile
  > a.nav-link
  .header-info
  small,
[data-headerbg='color_10']
  .header-right
  .header-profile
  > a.nav-link
  .header-info
  span,
[data-headerbg='color_10']
  .header-right
  .header-profile
  > a.nav-link
  .header-info
  small {
  color: rgba(255, 255, 255, 0.7);
}

[data-headerbg='color_10'][data-theme-version='dark'] .nav-control,
[data-headerbg='color_10'] .nav-control {
  background-color: #ffa849;
  color: #fff;
}

[data-headerbg='color_11'][data-theme-version='dark'] .header,
[data-headerbg='color_11'] .header {
  background-color: #6673fd;
  color: #fff;
}

[data-headerbg='color_11'][data-theme-version='dark'] .header-left input,
[data-headerbg='color_11'] .header-left input {
  border-color: #98a1fe;
  background-color: #98a1fe !important;
  color: #fff;
}

[data-headerbg='color_11'][data-theme-version='dark']
  .header-left
  input::placeholder,
[data-headerbg='color_11'] .header-left input::placeholder {
  color: #fff;
}

[data-headerbg='color_11'][data-theme-version='dark'] .header-left input:focus,
[data-headerbg='color_11'] .header-left input:focus {
  border-color: #3445fc;
}

[data-headerbg='color_11'][data-theme-version='dark'] .header-left .search_icon,
[data-headerbg='color_11'] .header-left .search_icon {
  background-color: #98a1fe !important;
}

[data-headerbg='color_11'][data-theme-version='dark']
  .header-left
  .search_icon
  i,
[data-headerbg='color_11'] .header-left .search_icon i {
  color: #fff;
}

[data-headerbg='color_11'][data-theme-version='dark'] .header-left .btn,
[data-headerbg='color_11'] .header-left .btn {
  background-color: #3445fc;
  border-color: #3445fc;
}

[data-headerbg='color_11'][data-theme-version='dark'] .header-left .btn:hover,
[data-headerbg='color_11'][data-theme-version='dark'] .header-left .btn:focus,
[data-headerbg='color_11'][data-theme-version='dark'] .header-left .btn:active,
[data-headerbg='color_11'] .header-left .btn:hover,
[data-headerbg='color_11'] .header-left .btn:focus,
[data-headerbg='color_11'] .header-left .btn:active {
  background-color: #0318fa;
  border-color: #0318fa;
}

[data-headerbg='color_11'][data-theme-version='dark']
  .header-right
  .dropdown
  > a,
[data-headerbg='color_11'] .header-right .dropdown > a {
  color: #fff;
}

[data-headerbg='color_11'][data-theme-version='dark'] .header-right .pulse-css,
[data-headerbg='color_11'] .header-right .pulse-css {
  background-color: #fff;
}

[data-headerbg='color_11'][data-theme-version='dark']
  .header-right
  .pulse-css:after,
[data-headerbg='color_11'][data-theme-version='dark']
  .header-right
  .pulse-css:before,
[data-headerbg='color_11'] .header-right .pulse-css:after,
[data-headerbg='color_11'] .header-right .pulse-css:before {
  background-color: inherit;
}

[data-headerbg='color_11'][data-theme-version='dark']
  .header-right
  .notification_dropdown
  .nav-link,
[data-headerbg='color_11'] .header-right .notification_dropdown .nav-link {
  background-color: #98a1fe !important;
}

[data-headerbg='color_11'][data-theme-version='dark']
  .header-right
  .right-sidebar
  a,
[data-headerbg='color_11'] .header-right .right-sidebar a {
  border-color: rgba(255, 255, 255, 0.1);
  color: #fff;
}

[data-headerbg='color_11'][data-theme-version='dark']
  .header-right
  .header-profile
  > a.nav-link
  .header-info
  strong,
[data-headerbg='color_11']
  .header-right
  .header-profile
  > a.nav-link
  .header-info
  strong {
  color: #fff;
}

[data-headerbg='color_11'][data-theme-version='dark']
  .header-right
  .header-profile
  > a.nav-link
  .header-info
  span,
[data-headerbg='color_11'][data-theme-version='dark']
  .header-right
  .header-profile
  > a.nav-link
  .header-info
  small,
[data-headerbg='color_11']
  .header-right
  .header-profile
  > a.nav-link
  .header-info
  span,
[data-headerbg='color_11']
  .header-right
  .header-profile
  > a.nav-link
  .header-info
  small {
  color: rgba(255, 255, 255, 0.7);
}

[data-headerbg='color_11'][data-theme-version='dark'] .nav-control,
[data-headerbg='color_11'] .nav-control {
  background-color: #98a1fe;
  color: #fff;
}

[data-headerbg='color_12'][data-theme-version='dark'] .header,
[data-headerbg='color_12'] .header {
  background-color: #558b2f;
  color: #fff;
}

[data-headerbg='color_12'][data-theme-version='dark'] .header-left input,
[data-headerbg='color_12'] .header-left input {
  border-color: #6cb13c;
  background-color: #6cb13c !important;
  color: #fff;
}

[data-headerbg='color_12'][data-theme-version='dark']
  .header-left
  input::placeholder,
[data-headerbg='color_12'] .header-left input::placeholder {
  color: #fff;
}

[data-headerbg='color_12'][data-theme-version='dark'] .header-left input:focus,
[data-headerbg='color_12'] .header-left input:focus {
  border-color: #3e6522;
}

[data-headerbg='color_12'][data-theme-version='dark'] .header-left .search_icon,
[data-headerbg='color_12'] .header-left .search_icon {
  background-color: #6cb13c !important;
}

[data-headerbg='color_12'][data-theme-version='dark']
  .header-left
  .search_icon
  i,
[data-headerbg='color_12'] .header-left .search_icon i {
  color: #fff;
}

[data-headerbg='color_12'][data-theme-version='dark'] .header-left .btn,
[data-headerbg='color_12'] .header-left .btn {
  background-color: #3e6522;
  border-color: #3e6522;
}

[data-headerbg='color_12'][data-theme-version='dark'] .header-left .btn:hover,
[data-headerbg='color_12'][data-theme-version='dark'] .header-left .btn:focus,
[data-headerbg='color_12'][data-theme-version='dark'] .header-left .btn:active,
[data-headerbg='color_12'] .header-left .btn:hover,
[data-headerbg='color_12'] .header-left .btn:focus,
[data-headerbg='color_12'] .header-left .btn:active {
  background-color: #263f15;
  border-color: #263f15;
}

[data-headerbg='color_12'][data-theme-version='dark']
  .header-right
  .dropdown
  > a,
[data-headerbg='color_12'] .header-right .dropdown > a {
  color: #fff;
}

[data-headerbg='color_12'][data-theme-version='dark'] .header-right .pulse-css,
[data-headerbg='color_12'] .header-right .pulse-css {
  background-color: #fff;
}

[data-headerbg='color_12'][data-theme-version='dark']
  .header-right
  .pulse-css:after,
[data-headerbg='color_12'][data-theme-version='dark']
  .header-right
  .pulse-css:before,
[data-headerbg='color_12'] .header-right .pulse-css:after,
[data-headerbg='color_12'] .header-right .pulse-css:before {
  background-color: inherit;
}

[data-headerbg='color_12'][data-theme-version='dark']
  .header-right
  .notification_dropdown
  .nav-link,
[data-headerbg='color_12'] .header-right .notification_dropdown .nav-link {
  background-color: #6cb13c !important;
}

[data-headerbg='color_12'][data-theme-version='dark']
  .header-right
  .right-sidebar
  a,
[data-headerbg='color_12'] .header-right .right-sidebar a {
  border-color: rgba(255, 255, 255, 0.1);
  color: #fff;
}

[data-headerbg='color_12'][data-theme-version='dark']
  .header-right
  .header-profile
  > a.nav-link
  .header-info
  strong,
[data-headerbg='color_12']
  .header-right
  .header-profile
  > a.nav-link
  .header-info
  strong {
  color: #fff;
}

[data-headerbg='color_12'][data-theme-version='dark']
  .header-right
  .header-profile
  > a.nav-link
  .header-info
  span,
[data-headerbg='color_12'][data-theme-version='dark']
  .header-right
  .header-profile
  > a.nav-link
  .header-info
  small,
[data-headerbg='color_12']
  .header-right
  .header-profile
  > a.nav-link
  .header-info
  span,
[data-headerbg='color_12']
  .header-right
  .header-profile
  > a.nav-link
  .header-info
  small {
  color: rgba(255, 255, 255, 0.7);
}

[data-headerbg='color_12'][data-theme-version='dark'] .nav-control,
[data-headerbg='color_12'] .nav-control {
  background-color: #6cb13c;
  color: #fff;
}

[data-headerbg='color_13'][data-theme-version='dark'] .header,
[data-headerbg='color_13'] .header {
  background-color: #2a2a2a;
  color: #fff;
}

[data-headerbg='color_13'][data-theme-version='dark'] .header-left input,
[data-headerbg='color_13'] .header-left input {
  border-color: #444444;
  background-color: #444444 !important;
  color: #fff;
}

[data-headerbg='color_13'][data-theme-version='dark']
  .header-left
  input::placeholder,
[data-headerbg='color_13'] .header-left input::placeholder {
  color: #fff;
}

[data-headerbg='color_13'][data-theme-version='dark'] .header-left input:focus,
[data-headerbg='color_13'] .header-left input:focus {
  border-color: #111111;
}

[data-headerbg='color_13'][data-theme-version='dark'] .header-left .search_icon,
[data-headerbg='color_13'] .header-left .search_icon {
  background-color: #444444 !important;
}

[data-headerbg='color_13'][data-theme-version='dark']
  .header-left
  .search_icon
  i,
[data-headerbg='color_13'] .header-left .search_icon i {
  color: #fff;
}

[data-headerbg='color_13'][data-theme-version='dark'] .header-left .btn,
[data-headerbg='color_13'] .header-left .btn {
  background-color: #111111;
  border-color: #111111;
}

[data-headerbg='color_13'][data-theme-version='dark'] .header-left .btn:hover,
[data-headerbg='color_13'][data-theme-version='dark'] .header-left .btn:focus,
[data-headerbg='color_13'][data-theme-version='dark'] .header-left .btn:active,
[data-headerbg='color_13'] .header-left .btn:hover,
[data-headerbg='color_13'] .header-left .btn:focus,
[data-headerbg='color_13'] .header-left .btn:active {
  background-color: black;
  border-color: black;
}

[data-headerbg='color_13'][data-theme-version='dark']
  .header-right
  .dropdown
  > a,
[data-headerbg='color_13'] .header-right .dropdown > a {
  color: #fff;
}

[data-headerbg='color_13'][data-theme-version='dark'] .header-right .pulse-css,
[data-headerbg='color_13'] .header-right .pulse-css {
  background-color: #fff;
}

[data-headerbg='color_13'][data-theme-version='dark']
  .header-right
  .pulse-css:after,
[data-headerbg='color_13'][data-theme-version='dark']
  .header-right
  .pulse-css:before,
[data-headerbg='color_13'] .header-right .pulse-css:after,
[data-headerbg='color_13'] .header-right .pulse-css:before {
  background-color: inherit;
}

[data-headerbg='color_13'][data-theme-version='dark']
  .header-right
  .notification_dropdown
  .nav-link,
[data-headerbg='color_13'] .header-right .notification_dropdown .nav-link {
  background-color: #444444 !important;
}

[data-headerbg='color_13'][data-theme-version='dark']
  .header-right
  .right-sidebar
  a,
[data-headerbg='color_13'] .header-right .right-sidebar a {
  border-color: rgba(255, 255, 255, 0.1);
  color: #fff;
}

[data-headerbg='color_13'][data-theme-version='dark']
  .header-right
  .header-profile
  > a.nav-link
  .header-info
  strong,
[data-headerbg='color_13']
  .header-right
  .header-profile
  > a.nav-link
  .header-info
  strong {
  color: #fff;
}

[data-headerbg='color_13'][data-theme-version='dark']
  .header-right
  .header-profile
  > a.nav-link
  .header-info
  span,
[data-headerbg='color_13'][data-theme-version='dark']
  .header-right
  .header-profile
  > a.nav-link
  .header-info
  small,
[data-headerbg='color_13']
  .header-right
  .header-profile
  > a.nav-link
  .header-info
  span,
[data-headerbg='color_13']
  .header-right
  .header-profile
  > a.nav-link
  .header-info
  small {
  color: rgba(255, 255, 255, 0.7);
}

[data-headerbg='color_13'][data-theme-version='dark'] .nav-control,
[data-headerbg='color_13'] .nav-control {
  background-color: #444444;
  color: #fff;
}

[data-headerbg='color_14'][data-theme-version='dark'] .header,
[data-headerbg='color_14'] .header {
  background-color: #1367c8;
  color: #fff;
}

[data-headerbg='color_14'][data-theme-version='dark'] .header-left input,
[data-headerbg='color_14'] .header-left input {
  border-color: #2480ea;
  background-color: #2480ea !important;
  color: #fff;
}

[data-headerbg='color_14'][data-theme-version='dark']
  .header-left
  input::placeholder,
[data-headerbg='color_14'] .header-left input::placeholder {
  color: #fff;
}

[data-headerbg='color_14'][data-theme-version='dark'] .header-left input:focus,
[data-headerbg='color_14'] .header-left input:focus {
  border-color: #0f4f99;
}

[data-headerbg='color_14'][data-theme-version='dark'] .header-left .search_icon,
[data-headerbg='color_14'] .header-left .search_icon {
  background-color: #2480ea !important;
}

[data-headerbg='color_14'][data-theme-version='dark']
  .header-left
  .search_icon
  i,
[data-headerbg='color_14'] .header-left .search_icon i {
  color: #fff;
}

[data-headerbg='color_14'][data-theme-version='dark'] .header-left .btn,
[data-headerbg='color_14'] .header-left .btn {
  background-color: #0f4f99;
  border-color: #0f4f99;
}

[data-headerbg='color_14'][data-theme-version='dark'] .header-left .btn:hover,
[data-headerbg='color_14'][data-theme-version='dark'] .header-left .btn:focus,
[data-headerbg='color_14'][data-theme-version='dark'] .header-left .btn:active,
[data-headerbg='color_14'] .header-left .btn:hover,
[data-headerbg='color_14'] .header-left .btn:focus,
[data-headerbg='color_14'] .header-left .btn:active {
  background-color: #0a376b;
  border-color: #0a376b;
}

[data-headerbg='color_14'][data-theme-version='dark']
  .header-right
  .dropdown
  > a,
[data-headerbg='color_14'] .header-right .dropdown > a {
  color: #fff;
}

[data-headerbg='color_14'][data-theme-version='dark'] .header-right .pulse-css,
[data-headerbg='color_14'] .header-right .pulse-css {
  background-color: #fff;
}

[data-headerbg='color_14'][data-theme-version='dark']
  .header-right
  .pulse-css:after,
[data-headerbg='color_14'][data-theme-version='dark']
  .header-right
  .pulse-css:before,
[data-headerbg='color_14'] .header-right .pulse-css:after,
[data-headerbg='color_14'] .header-right .pulse-css:before {
  background-color: inherit;
}

[data-headerbg='color_14'][data-theme-version='dark']
  .header-right
  .notification_dropdown
  .nav-link,
[data-headerbg='color_14'] .header-right .notification_dropdown .nav-link {
  background-color: #2480ea !important;
}

[data-headerbg='color_14'][data-theme-version='dark']
  .header-right
  .right-sidebar
  a,
[data-headerbg='color_14'] .header-right .right-sidebar a {
  border-color: rgba(255, 255, 255, 0.1);
  color: #fff;
}

[data-headerbg='color_14'][data-theme-version='dark']
  .header-right
  .header-profile
  > a.nav-link
  .header-info
  strong,
[data-headerbg='color_14']
  .header-right
  .header-profile
  > a.nav-link
  .header-info
  strong {
  color: #fff;
}

[data-headerbg='color_14'][data-theme-version='dark']
  .header-right
  .header-profile
  > a.nav-link
  .header-info
  span,
[data-headerbg='color_14'][data-theme-version='dark']
  .header-right
  .header-profile
  > a.nav-link
  .header-info
  small,
[data-headerbg='color_14']
  .header-right
  .header-profile
  > a.nav-link
  .header-info
  span,
[data-headerbg='color_14']
  .header-right
  .header-profile
  > a.nav-link
  .header-info
  small {
  color: rgba(255, 255, 255, 0.7);
}

[data-headerbg='color_14'][data-theme-version='dark'] .nav-control,
[data-headerbg='color_14'] .nav-control {
  background-color: #2480ea;
  color: #fff;
}

[data-headerbg='color_15'][data-theme-version='dark'] .header,
[data-headerbg='color_15'] .header {
  background-color: #ed0b4c;
  color: #fff;
}

[data-headerbg='color_15'][data-theme-version='dark'] .header-left input,
[data-headerbg='color_15'] .header-left input {
  border-color: #f6356d;
  background-color: #f6356d !important;
  color: #fff;
}

[data-headerbg='color_15'][data-theme-version='dark']
  .header-left
  input::placeholder,
[data-headerbg='color_15'] .header-left input::placeholder {
  color: #fff;
}

[data-headerbg='color_15'][data-theme-version='dark'] .header-left input:focus,
[data-headerbg='color_15'] .header-left input:focus {
  border-color: #bc093c;
}

[data-headerbg='color_15'][data-theme-version='dark'] .header-left .search_icon,
[data-headerbg='color_15'] .header-left .search_icon {
  background-color: #f6356d !important;
}

[data-headerbg='color_15'][data-theme-version='dark']
  .header-left
  .search_icon
  i,
[data-headerbg='color_15'] .header-left .search_icon i {
  color: #fff;
}

[data-headerbg='color_15'][data-theme-version='dark'] .header-left .btn,
[data-headerbg='color_15'] .header-left .btn {
  background-color: #bc093c;
  border-color: #bc093c;
}

[data-headerbg='color_15'][data-theme-version='dark'] .header-left .btn:hover,
[data-headerbg='color_15'][data-theme-version='dark'] .header-left .btn:focus,
[data-headerbg='color_15'][data-theme-version='dark'] .header-left .btn:active,
[data-headerbg='color_15'] .header-left .btn:hover,
[data-headerbg='color_15'] .header-left .btn:focus,
[data-headerbg='color_15'] .header-left .btn:active {
  background-color: #8c062d;
  border-color: #8c062d;
}

[data-headerbg='color_15'][data-theme-version='dark']
  .header-right
  .dropdown
  > a,
[data-headerbg='color_15'] .header-right .dropdown > a {
  color: #fff;
}

[data-headerbg='color_15'][data-theme-version='dark'] .header-right .pulse-css,
[data-headerbg='color_15'] .header-right .pulse-css {
  background-color: #fff;
}

[data-headerbg='color_15'][data-theme-version='dark']
  .header-right
  .pulse-css:after,
[data-headerbg='color_15'][data-theme-version='dark']
  .header-right
  .pulse-css:before,
[data-headerbg='color_15'] .header-right .pulse-css:after,
[data-headerbg='color_15'] .header-right .pulse-css:before {
  background-color: inherit;
}

[data-headerbg='color_15'][data-theme-version='dark']
  .header-right
  .notification_dropdown
  .nav-link,
[data-headerbg='color_15'] .header-right .notification_dropdown .nav-link {
  background-color: #f6356d !important;
}

[data-headerbg='color_15'][data-theme-version='dark']
  .header-right
  .right-sidebar
  a,
[data-headerbg='color_15'] .header-right .right-sidebar a {
  border-color: rgba(255, 255, 255, 0.1);
  color: #fff;
}

[data-headerbg='color_15'][data-theme-version='dark']
  .header-right
  .header-profile
  > a.nav-link
  .header-info
  strong,
[data-headerbg='color_15']
  .header-right
  .header-profile
  > a.nav-link
  .header-info
  strong {
  color: #fff;
}

[data-headerbg='color_15'][data-theme-version='dark']
  .header-right
  .header-profile
  > a.nav-link
  .header-info
  span,
[data-headerbg='color_15'][data-theme-version='dark']
  .header-right
  .header-profile
  > a.nav-link
  .header-info
  small,
[data-headerbg='color_15']
  .header-right
  .header-profile
  > a.nav-link
  .header-info
  span,
[data-headerbg='color_15']
  .header-right
  .header-profile
  > a.nav-link
  .header-info
  small {
  color: rgba(255, 255, 255, 0.7);
}

[data-headerbg='color_15'][data-theme-version='dark'] .nav-control,
[data-headerbg='color_15'] .nav-control {
  background-color: #f6356d;
  color: #fff;
}

[data-sibebarbg='color_2'][data-theme-version='dark'] .deznav,
[data-sibebarbg='color_2'] .deznav {
  background-color: #143b64;
}

[data-sibebarbg='color_2'][data-theme-version='dark']
  .deznav
  .menu-tabs
  li
  a.active,
[data-sibebarbg='color_2'] .deznav .menu-tabs li a.active {
  color: #143b64;
}

[data-sibebarbg='color_2'][data-theme-version='dark']
  .deznav
  .metismenu
  .nav-label,
[data-sibebarbg='color_2'] .deznav .metismenu .nav-label {
  color: rgba(255, 255, 255, 0.7);
  border-color: rgba(255, 255, 255, 0.2);
}

[data-sibebarbg='color_2'][data-theme-version='dark']
  .deznav
  .metismenu
  > li
  > a,
[data-sibebarbg='color_2'] .deznav .metismenu > li > a {
  color: rgba(255, 255, 255, 0.7);
}

[data-sibebarbg='color_2'][data-theme-version='dark']
  .deznav
  .metismenu
  > li
  > a
  svg,
[data-sibebarbg='color_2'] .deznav .metismenu > li > a svg {
  color: rgba(255, 255, 255, 0.7);
}

[data-sibebarbg='color_2'][data-theme-version='dark']
  .deznav
  .metismenu
  > li
  > a
  g
  [fill],
[data-sibebarbg='color_2'] .deznav .metismenu > li > a g [fill] {
  fill: rgba(255, 255, 255, 0.7);
}

[data-sibebarbg='color_2'][data-theme-version='dark']
  .deznav
  .metismenu
  > li:hover
  > a,
[data-sibebarbg='color_2'][data-theme-version='dark']
  .deznav
  .metismenu
  > li:focus
  > a,
[data-sibebarbg='color_2'][data-theme-version='dark']
  .deznav
  .metismenu
  > li.mm-active
  > a,
[data-sibebarbg='color_2'] .deznav .metismenu > li:hover > a,
[data-sibebarbg='color_2'] .deznav .metismenu > li:focus > a,
[data-sibebarbg='color_2'] .deznav .metismenu > li.mm-active > a {
  color: #fff;
}

[data-sibebarbg='color_2'][data-theme-version='dark']
  .deznav
  .metismenu
  > li:hover
  > a
  g
  [fill],
[data-sibebarbg='color_2'][data-theme-version='dark']
  .deznav
  .metismenu
  > li:focus
  > a
  g
  [fill],
[data-sibebarbg='color_2'][data-theme-version='dark']
  .deznav
  .metismenu
  > li.mm-active
  > a
  g
  [fill],
[data-sibebarbg='color_2'] .deznav .metismenu > li:hover > a g [fill],
[data-sibebarbg='color_2'] .deznav .metismenu > li:focus > a g [fill],
[data-sibebarbg='color_2'] .deznav .metismenu > li.mm-active > a g [fill] {
  fill: #fff;
}

[data-sibebarbg='color_2'][data-theme-version='dark']
  .deznav
  .metismenu
  > li:hover
  > a
  svg,
[data-sibebarbg='color_2'][data-theme-version='dark']
  .deznav
  .metismenu
  > li:focus
  > a
  svg,
[data-sibebarbg='color_2'][data-theme-version='dark']
  .deznav
  .metismenu
  > li.mm-active
  > a
  svg,
[data-sibebarbg='color_2'] .deznav .metismenu > li:hover > a svg,
[data-sibebarbg='color_2'] .deznav .metismenu > li:focus > a svg,
[data-sibebarbg='color_2'] .deznav .metismenu > li.mm-active > a svg {
  color: #fff;
}

[data-layout='horizontal'][data-sibebarbg='color_2'][data-theme-version='dark']
  .deznav
  .metismenu
  > li,
[data-sibebarbg='color_2'] .deznav .metismenu > li {
  border-color: #1d548f;
}

[data-sibebarbg='color_2'][data-theme-version='dark'] .deznav .metismenu a,
[data-sibebarbg='color_2'] .deznav .metismenu a {
  color: rgba(255, 255, 255, 0.7);
}

[data-sibebarbg='color_2'][data-theme-version='dark']
  .deznav
  .metismenu
  a:hover,
[data-sibebarbg='color_2'][data-theme-version='dark']
  .deznav
  .metismenu
  a:focus,
[data-sibebarbg='color_2'][data-theme-version='dark']
  .deznav
  .metismenu
  a.mm-active,
[data-sibebarbg='color_2'] .deznav .metismenu a:hover,
[data-sibebarbg='color_2'] .deznav .metismenu a:focus,
[data-sibebarbg='color_2'] .deznav .metismenu a.mm-active {
  color: #fff;
}

[data-sibebarbg='color_2'][data-theme-version='dark'] .deznav .metismenu ul,
[data-sibebarbg='color_2'] .deznav .metismenu ul {
  border-color: rgba(255, 255, 255, 0.2);
}

[data-sibebarbg='color_2'][data-theme-version='dark']
  .deznav
  .metismenu
  ul:after,
[data-sibebarbg='color_2'] .deznav .metismenu ul:after {
  background-color: rgba(255, 255, 255, 0.2);
}

[data-sibebarbg='color_2'][data-theme-version='dark'][data-sidebar-style='mini'][data-layout='vertical']
  .deznav
  .metismenu
  li
  > ul,
[data-sibebarbg='color_2'][data-sidebar-style='mini'][data-layout='vertical']
  .deznav
  .metismenu
  li
  > ul {
  background-color: #1d548f;
}

[data-sibebarbg='color_2'][data-theme-version='dark'][data-sidebar-style='mini'][data-layout='vertical']
  .deznav
  .metismenu
  > li.mm-active
  > a,
[data-sibebarbg='color_2'][data-theme-version='dark'][data-sidebar-style='mini'][data-layout='vertical']
  .deznav
  .metismenu
  > li:hover
  > a,
[data-sibebarbg='color_2'][data-sidebar-style='mini'][data-layout='vertical']
  .deznav
  .metismenu
  > li.mm-active
  > a,
[data-sibebarbg='color_2'][data-sidebar-style='mini'][data-layout='vertical']
  .deznav
  .metismenu
  > li:hover
  > a {
  background-color: #1d548f;
}

[data-sibebarbg='color_2'][data-theme-version='dark'][data-sidebar-style='full'][data-layout='vertical']
  .menu-toggle
  .deznav
  .metismenu
  li
  > ul,
[data-sibebarbg='color_2'][data-theme-version='dark'][data-sidebar-style='full'][data-layout='vertical']
  .menu-toggle
  .deznav
  .metismenu
  > li.mm-active
  > a,
[data-sibebarbg='color_2'][data-theme-version='dark'][data-sidebar-style='full'][data-layout='vertical']
  .menu-toggle
  .deznav
  .metismenu
  > li:hover
  > a,
[data-sibebarbg='color_2'][data-sidebar-style='full'][data-layout='vertical']
  .menu-toggle
  .deznav
  .metismenu
  li
  > ul,
[data-sibebarbg='color_2'][data-sidebar-style='full'][data-layout='vertical']
  .menu-toggle
  .deznav
  .metismenu
  > li.mm-active
  > a,
[data-sibebarbg='color_2'][data-sidebar-style='full'][data-layout='vertical']
  .menu-toggle
  .deznav
  .metismenu
  > li:hover
  > a {
  background-color: #1d548f;
}

[data-sibebarbg='color_2'][data-theme-version='dark'][data-sidebar-style='icon-hover'][data-layout='vertical']
  .deznav
  .metismenu
  ul,
[data-sibebarbg='color_2'][data-sidebar-style='icon-hover'][data-layout='vertical']
  .deznav
  .metismenu
  ul {
  border-color: rgba(255, 255, 255, 0.2);
}

[data-sibebarbg='color_2'][data-theme-version='dark'][data-layout='horizontal']
  .deznav
  .metismenu
  > li,
[data-sibebarbg='color_2'][data-layout='horizontal'] .deznav .metismenu > li {
  border-color: rgba(255, 255, 255, 0.2);
}

[data-sibebarbg='color_3'][data-theme-version='dark'] .deznav,
[data-sibebarbg='color_3'] .deznav {
  background-color: #0b2a97;
}

[data-sibebarbg='color_3'][data-theme-version='dark']
  .deznav
  .menu-tabs
  li
  a.active,
[data-sibebarbg='color_3'] .deznav .menu-tabs li a.active {
  color: #0b2a97;
}

[data-sibebarbg='color_3'][data-theme-version='dark']
  .deznav
  .metismenu
  .nav-label,
[data-sibebarbg='color_3'] .deznav .metismenu .nav-label {
  color: rgba(255, 255, 255, 0.7);
  border-color: rgba(255, 255, 255, 0.2);
}

[data-sibebarbg='color_3'][data-theme-version='dark']
  .deznav
  .metismenu
  > li
  > a,
[data-sibebarbg='color_3'] .deznav .metismenu > li > a {
  color: rgba(255, 255, 255, 0.7);
}

[data-sibebarbg='color_3'][data-theme-version='dark']
  .deznav
  .metismenu
  > li
  > a
  svg,
[data-sibebarbg='color_3'] .deznav .metismenu > li > a svg {
  color: rgba(255, 255, 255, 0.7);
}

[data-sibebarbg='color_3'][data-theme-version='dark']
  .deznav
  .metismenu
  > li
  > a
  g
  [fill],
[data-sibebarbg='color_3'] .deznav .metismenu > li > a g [fill] {
  fill: rgba(255, 255, 255, 0.7);
}

[data-sibebarbg='color_3'][data-theme-version='dark']
  .deznav
  .metismenu
  > li:hover
  > a,
[data-sibebarbg='color_3'][data-theme-version='dark']
  .deznav
  .metismenu
  > li:focus
  > a,
[data-sibebarbg='color_3'][data-theme-version='dark']
  .deznav
  .metismenu
  > li.mm-active
  > a,
[data-sibebarbg='color_3'] .deznav .metismenu > li:hover > a,
[data-sibebarbg='color_3'] .deznav .metismenu > li:focus > a,
[data-sibebarbg='color_3'] .deznav .metismenu > li.mm-active > a {
  color: #fff;
}

[data-sibebarbg='color_3'][data-theme-version='dark']
  .deznav
  .metismenu
  > li:hover
  > a
  g
  [fill],
[data-sibebarbg='color_3'][data-theme-version='dark']
  .deznav
  .metismenu
  > li:focus
  > a
  g
  [fill],
[data-sibebarbg='color_3'][data-theme-version='dark']
  .deznav
  .metismenu
  > li.mm-active
  > a
  g
  [fill],
[data-sibebarbg='color_3'] .deznav .metismenu > li:hover > a g [fill],
[data-sibebarbg='color_3'] .deznav .metismenu > li:focus > a g [fill],
[data-sibebarbg='color_3'] .deznav .metismenu > li.mm-active > a g [fill] {
  fill: #fff;
}

[data-sibebarbg='color_3'][data-theme-version='dark']
  .deznav
  .metismenu
  > li:hover
  > a
  svg,
[data-sibebarbg='color_3'][data-theme-version='dark']
  .deznav
  .metismenu
  > li:focus
  > a
  svg,
[data-sibebarbg='color_3'][data-theme-version='dark']
  .deznav
  .metismenu
  > li.mm-active
  > a
  svg,
[data-sibebarbg='color_3'] .deznav .metismenu > li:hover > a svg,
[data-sibebarbg='color_3'] .deznav .metismenu > li:focus > a svg,
[data-sibebarbg='color_3'] .deznav .metismenu > li.mm-active > a svg {
  color: #fff;
}

[data-layout='horizontal'][data-sibebarbg='color_3'][data-theme-version='dark']
  .deznav
  .metismenu
  > li,
[data-sibebarbg='color_3'] .deznav .metismenu > li {
  border-color: #0e37c7;
}

[data-sibebarbg='color_3'][data-theme-version='dark'] .deznav .metismenu a,
[data-sibebarbg='color_3'] .deznav .metismenu a {
  color: rgba(255, 255, 255, 0.7);
}

[data-sibebarbg='color_3'][data-theme-version='dark']
  .deznav
  .metismenu
  a:hover,
[data-sibebarbg='color_3'][data-theme-version='dark']
  .deznav
  .metismenu
  a:focus,
[data-sibebarbg='color_3'][data-theme-version='dark']
  .deznav
  .metismenu
  a.mm-active,
[data-sibebarbg='color_3'] .deznav .metismenu a:hover,
[data-sibebarbg='color_3'] .deznav .metismenu a:focus,
[data-sibebarbg='color_3'] .deznav .metismenu a.mm-active {
  color: #fff;
}

[data-sibebarbg='color_3'][data-theme-version='dark'] .deznav .metismenu ul,
[data-sibebarbg='color_3'] .deznav .metismenu ul {
  border-color: rgba(255, 255, 255, 0.2);
}

[data-sibebarbg='color_3'][data-theme-version='dark']
  .deznav
  .metismenu
  ul:after,
[data-sibebarbg='color_3'] .deznav .metismenu ul:after {
  background-color: rgba(255, 255, 255, 0.2);
}

[data-sibebarbg='color_3'][data-theme-version='dark'][data-sidebar-style='mini'][data-layout='vertical']
  .deznav
  .metismenu
  li
  > ul,
[data-sibebarbg='color_3'][data-sidebar-style='mini'][data-layout='vertical']
  .deznav
  .metismenu
  li
  > ul {
  background-color: #0e37c7;
}

[data-sibebarbg='color_3'][data-theme-version='dark'][data-sidebar-style='mini'][data-layout='vertical']
  .deznav
  .metismenu
  > li.mm-active
  > a,
[data-sibebarbg='color_3'][data-theme-version='dark'][data-sidebar-style='mini'][data-layout='vertical']
  .deznav
  .metismenu
  > li:hover
  > a,
[data-sibebarbg='color_3'][data-sidebar-style='mini'][data-layout='vertical']
  .deznav
  .metismenu
  > li.mm-active
  > a,
[data-sibebarbg='color_3'][data-sidebar-style='mini'][data-layout='vertical']
  .deznav
  .metismenu
  > li:hover
  > a {
  background-color: #0e37c7;
}

[data-sibebarbg='color_3'][data-theme-version='dark'][data-sidebar-style='full'][data-layout='vertical']
  .menu-toggle
  .deznav
  .metismenu
  li
  > ul,
[data-sibebarbg='color_3'][data-theme-version='dark'][data-sidebar-style='full'][data-layout='vertical']
  .menu-toggle
  .deznav
  .metismenu
  > li.mm-active
  > a,
[data-sibebarbg='color_3'][data-theme-version='dark'][data-sidebar-style='full'][data-layout='vertical']
  .menu-toggle
  .deznav
  .metismenu
  > li:hover
  > a,
[data-sibebarbg='color_3'][data-sidebar-style='full'][data-layout='vertical']
  .menu-toggle
  .deznav
  .metismenu
  li
  > ul,
[data-sibebarbg='color_3'][data-sidebar-style='full'][data-layout='vertical']
  .menu-toggle
  .deznav
  .metismenu
  > li.mm-active
  > a,
[data-sibebarbg='color_3'][data-sidebar-style='full'][data-layout='vertical']
  .menu-toggle
  .deznav
  .metismenu
  > li:hover
  > a {
  background-color: #0e37c7;
}

[data-sibebarbg='color_3'][data-theme-version='dark'][data-sidebar-style='icon-hover'][data-layout='vertical']
  .deznav
  .metismenu
  ul,
[data-sibebarbg='color_3'][data-sidebar-style='icon-hover'][data-layout='vertical']
  .deznav
  .metismenu
  ul {
  border-color: rgba(255, 255, 255, 0.2);
}

[data-sibebarbg='color_3'][data-theme-version='dark'][data-layout='horizontal']
  .deznav
  .metismenu
  > li,
[data-sibebarbg='color_3'][data-layout='horizontal'] .deznav .metismenu > li {
  border-color: rgba(255, 255, 255, 0.2);
}

[data-sibebarbg='color_4'][data-theme-version='dark'] .deznav,
[data-sibebarbg='color_4'] .deznav {
  background-color: #4527a0;
}

[data-sibebarbg='color_4'][data-theme-version='dark']
  .deznav
  .menu-tabs
  li
  a.active,
[data-sibebarbg='color_4'] .deznav .menu-tabs li a.active {
  color: #4527a0;
}

[data-sibebarbg='color_4'][data-theme-version='dark']
  .deznav
  .metismenu
  .nav-label,
[data-sibebarbg='color_4'] .deznav .metismenu .nav-label {
  color: rgba(255, 255, 255, 0.7);
  border-color: rgba(255, 255, 255, 0.2);
}

[data-sibebarbg='color_4'][data-theme-version='dark']
  .deznav
  .metismenu
  > li
  > a,
[data-sibebarbg='color_4'] .deznav .metismenu > li > a {
  color: rgba(255, 255, 255, 0.7);
}

[data-sibebarbg='color_4'][data-theme-version='dark']
  .deznav
  .metismenu
  > li
  > a
  svg,
[data-sibebarbg='color_4'] .deznav .metismenu > li > a svg {
  color: rgba(255, 255, 255, 0.7);
}

[data-sibebarbg='color_4'][data-theme-version='dark']
  .deznav
  .metismenu
  > li
  > a
  g
  [fill],
[data-sibebarbg='color_4'] .deznav .metismenu > li > a g [fill] {
  fill: rgba(255, 255, 255, 0.7);
}

[data-sibebarbg='color_4'][data-theme-version='dark']
  .deznav
  .metismenu
  > li:hover
  > a,
[data-sibebarbg='color_4'][data-theme-version='dark']
  .deznav
  .metismenu
  > li:focus
  > a,
[data-sibebarbg='color_4'][data-theme-version='dark']
  .deznav
  .metismenu
  > li.mm-active
  > a,
[data-sibebarbg='color_4'] .deznav .metismenu > li:hover > a,
[data-sibebarbg='color_4'] .deznav .metismenu > li:focus > a,
[data-sibebarbg='color_4'] .deznav .metismenu > li.mm-active > a {
  color: #fff;
}

[data-sibebarbg='color_4'][data-theme-version='dark']
  .deznav
  .metismenu
  > li:hover
  > a
  g
  [fill],
[data-sibebarbg='color_4'][data-theme-version='dark']
  .deznav
  .metismenu
  > li:focus
  > a
  g
  [fill],
[data-sibebarbg='color_4'][data-theme-version='dark']
  .deznav
  .metismenu
  > li.mm-active
  > a
  g
  [fill],
[data-sibebarbg='color_4'] .deznav .metismenu > li:hover > a g [fill],
[data-sibebarbg='color_4'] .deznav .metismenu > li:focus > a g [fill],
[data-sibebarbg='color_4'] .deznav .metismenu > li.mm-active > a g [fill] {
  fill: #fff;
}

[data-sibebarbg='color_4'][data-theme-version='dark']
  .deznav
  .metismenu
  > li:hover
  > a
  svg,
[data-sibebarbg='color_4'][data-theme-version='dark']
  .deznav
  .metismenu
  > li:focus
  > a
  svg,
[data-sibebarbg='color_4'][data-theme-version='dark']
  .deznav
  .metismenu
  > li.mm-active
  > a
  svg,
[data-sibebarbg='color_4'] .deznav .metismenu > li:hover > a svg,
[data-sibebarbg='color_4'] .deznav .metismenu > li:focus > a svg,
[data-sibebarbg='color_4'] .deznav .metismenu > li.mm-active > a svg {
  color: #fff;
}

[data-layout='horizontal'][data-sibebarbg='color_4'][data-theme-version='dark']
  .deznav
  .metismenu
  > li,
[data-sibebarbg='color_4'] .deznav .metismenu > li {
  border-color: #5731c9;
}

[data-sibebarbg='color_4'][data-theme-version='dark'] .deznav .metismenu a,
[data-sibebarbg='color_4'] .deznav .metismenu a {
  color: rgba(255, 255, 255, 0.7);
}

[data-sibebarbg='color_4'][data-theme-version='dark']
  .deznav
  .metismenu
  a:hover,
[data-sibebarbg='color_4'][data-theme-version='dark']
  .deznav
  .metismenu
  a:focus,
[data-sibebarbg='color_4'][data-theme-version='dark']
  .deznav
  .metismenu
  a.mm-active,
[data-sibebarbg='color_4'] .deznav .metismenu a:hover,
[data-sibebarbg='color_4'] .deznav .metismenu a:focus,
[data-sibebarbg='color_4'] .deznav .metismenu a.mm-active {
  color: #fff;
}

[data-sibebarbg='color_4'][data-theme-version='dark'] .deznav .metismenu ul,
[data-sibebarbg='color_4'] .deznav .metismenu ul {
  border-color: rgba(255, 255, 255, 0.2);
}

[data-sibebarbg='color_4'][data-theme-version='dark']
  .deznav
  .metismenu
  ul:after,
[data-sibebarbg='color_4'] .deznav .metismenu ul:after {
  background-color: rgba(255, 255, 255, 0.2);
}

[data-sibebarbg='color_4'][data-theme-version='dark'][data-sidebar-style='mini'][data-layout='vertical']
  .deznav
  .metismenu
  li
  > ul,
[data-sibebarbg='color_4'][data-sidebar-style='mini'][data-layout='vertical']
  .deznav
  .metismenu
  li
  > ul {
  background-color: #5731c9;
}

[data-sibebarbg='color_4'][data-theme-version='dark'][data-sidebar-style='mini'][data-layout='vertical']
  .deznav
  .metismenu
  > li.mm-active
  > a,
[data-sibebarbg='color_4'][data-theme-version='dark'][data-sidebar-style='mini'][data-layout='vertical']
  .deznav
  .metismenu
  > li:hover
  > a,
[data-sibebarbg='color_4'][data-sidebar-style='mini'][data-layout='vertical']
  .deznav
  .metismenu
  > li.mm-active
  > a,
[data-sibebarbg='color_4'][data-sidebar-style='mini'][data-layout='vertical']
  .deznav
  .metismenu
  > li:hover
  > a {
  background-color: #5731c9;
}

[data-sibebarbg='color_4'][data-theme-version='dark'][data-sidebar-style='full'][data-layout='vertical']
  .menu-toggle
  .deznav
  .metismenu
  li
  > ul,
[data-sibebarbg='color_4'][data-theme-version='dark'][data-sidebar-style='full'][data-layout='vertical']
  .menu-toggle
  .deznav
  .metismenu
  > li.mm-active
  > a,
[data-sibebarbg='color_4'][data-theme-version='dark'][data-sidebar-style='full'][data-layout='vertical']
  .menu-toggle
  .deznav
  .metismenu
  > li:hover
  > a,
[data-sibebarbg='color_4'][data-sidebar-style='full'][data-layout='vertical']
  .menu-toggle
  .deznav
  .metismenu
  li
  > ul,
[data-sibebarbg='color_4'][data-sidebar-style='full'][data-layout='vertical']
  .menu-toggle
  .deznav
  .metismenu
  > li.mm-active
  > a,
[data-sibebarbg='color_4'][data-sidebar-style='full'][data-layout='vertical']
  .menu-toggle
  .deznav
  .metismenu
  > li:hover
  > a {
  background-color: #5731c9;
}

[data-sibebarbg='color_4'][data-theme-version='dark'][data-sidebar-style='icon-hover'][data-layout='vertical']
  .deznav
  .metismenu
  ul,
[data-sibebarbg='color_4'][data-sidebar-style='icon-hover'][data-layout='vertical']
  .deznav
  .metismenu
  ul {
  border-color: rgba(255, 255, 255, 0.2);
}

[data-sibebarbg='color_4'][data-theme-version='dark'][data-layout='horizontal']
  .deznav
  .metismenu
  > li,
[data-sibebarbg='color_4'][data-layout='horizontal'] .deznav .metismenu > li {
  border-color: rgba(255, 255, 255, 0.2);
}

[data-sibebarbg='color_5'][data-theme-version='dark'] .deznav,
[data-sibebarbg='color_5'] .deznav {
  background-color: #c62828;
}

[data-sibebarbg='color_5'][data-theme-version='dark']
  .deznav
  .menu-tabs
  li
  a.active,
[data-sibebarbg='color_5'] .deznav .menu-tabs li a.active {
  color: #c62828;
}

[data-sibebarbg='color_5'][data-theme-version='dark']
  .deznav
  .metismenu
  .nav-label,
[data-sibebarbg='color_5'] .deznav .metismenu .nav-label {
  color: rgba(255, 255, 255, 0.7);
  border-color: rgba(255, 255, 255, 0.2);
}

[data-sibebarbg='color_5'][data-theme-version='dark']
  .deznav
  .metismenu
  > li
  > a,
[data-sibebarbg='color_5'] .deznav .metismenu > li > a {
  color: rgba(255, 255, 255, 0.7);
}

[data-sibebarbg='color_5'][data-theme-version='dark']
  .deznav
  .metismenu
  > li
  > a
  svg,
[data-sibebarbg='color_5'] .deznav .metismenu > li > a svg {
  color: rgba(255, 255, 255, 0.7);
}

[data-sibebarbg='color_5'][data-theme-version='dark']
  .deznav
  .metismenu
  > li
  > a
  g
  [fill],
[data-sibebarbg='color_5'] .deznav .metismenu > li > a g [fill] {
  fill: rgba(255, 255, 255, 0.7);
}

[data-sibebarbg='color_5'][data-theme-version='dark']
  .deznav
  .metismenu
  > li:hover
  > a,
[data-sibebarbg='color_5'][data-theme-version='dark']
  .deznav
  .metismenu
  > li:focus
  > a,
[data-sibebarbg='color_5'][data-theme-version='dark']
  .deznav
  .metismenu
  > li.mm-active
  > a,
[data-sibebarbg='color_5'] .deznav .metismenu > li:hover > a,
[data-sibebarbg='color_5'] .deznav .metismenu > li:focus > a,
[data-sibebarbg='color_5'] .deznav .metismenu > li.mm-active > a {
  color: #fff;
}

[data-sibebarbg='color_5'][data-theme-version='dark']
  .deznav
  .metismenu
  > li:hover
  > a
  g
  [fill],
[data-sibebarbg='color_5'][data-theme-version='dark']
  .deznav
  .metismenu
  > li:focus
  > a
  g
  [fill],
[data-sibebarbg='color_5'][data-theme-version='dark']
  .deznav
  .metismenu
  > li.mm-active
  > a
  g
  [fill],
[data-sibebarbg='color_5'] .deznav .metismenu > li:hover > a g [fill],
[data-sibebarbg='color_5'] .deznav .metismenu > li:focus > a g [fill],
[data-sibebarbg='color_5'] .deznav .metismenu > li.mm-active > a g [fill] {
  fill: #fff;
}

[data-sibebarbg='color_5'][data-theme-version='dark']
  .deznav
  .metismenu
  > li:hover
  > a
  svg,
[data-sibebarbg='color_5'][data-theme-version='dark']
  .deznav
  .metismenu
  > li:focus
  > a
  svg,
[data-sibebarbg='color_5'][data-theme-version='dark']
  .deznav
  .metismenu
  > li.mm-active
  > a
  svg,
[data-sibebarbg='color_5'] .deznav .metismenu > li:hover > a svg,
[data-sibebarbg='color_5'] .deznav .metismenu > li:focus > a svg,
[data-sibebarbg='color_5'] .deznav .metismenu > li.mm-active > a svg {
  color: #fff;
}

[data-layout='horizontal'][data-sibebarbg='color_5'][data-theme-version='dark']
  .deznav
  .metismenu
  > li,
[data-sibebarbg='color_5'] .deznav .metismenu > li {
  border-color: #da4747;
}

[data-sibebarbg='color_5'][data-theme-version='dark'] .deznav .metismenu a,
[data-sibebarbg='color_5'] .deznav .metismenu a {
  color: rgba(255, 255, 255, 0.7);
}

[data-sibebarbg='color_5'][data-theme-version='dark']
  .deznav
  .metismenu
  a:hover,
[data-sibebarbg='color_5'][data-theme-version='dark']
  .deznav
  .metismenu
  a:focus,
[data-sibebarbg='color_5'][data-theme-version='dark']
  .deznav
  .metismenu
  a.mm-active,
[data-sibebarbg='color_5'] .deznav .metismenu a:hover,
[data-sibebarbg='color_5'] .deznav .metismenu a:focus,
[data-sibebarbg='color_5'] .deznav .metismenu a.mm-active {
  color: #fff;
}

[data-sibebarbg='color_5'][data-theme-version='dark'] .deznav .metismenu ul,
[data-sibebarbg='color_5'] .deznav .metismenu ul {
  border-color: rgba(255, 255, 255, 0.2);
}

[data-sibebarbg='color_5'][data-theme-version='dark']
  .deznav
  .metismenu
  ul:after,
[data-sibebarbg='color_5'] .deznav .metismenu ul:after {
  background-color: rgba(255, 255, 255, 0.2);
}

[data-sibebarbg='color_5'][data-theme-version='dark'][data-sidebar-style='mini'][data-layout='vertical']
  .deznav
  .metismenu
  li
  > ul,
[data-sibebarbg='color_5'][data-sidebar-style='mini'][data-layout='vertical']
  .deznav
  .metismenu
  li
  > ul {
  background-color: #da4747;
}

[data-sibebarbg='color_5'][data-theme-version='dark'][data-sidebar-style='mini'][data-layout='vertical']
  .deznav
  .metismenu
  > li.mm-active
  > a,
[data-sibebarbg='color_5'][data-theme-version='dark'][data-sidebar-style='mini'][data-layout='vertical']
  .deznav
  .metismenu
  > li:hover
  > a,
[data-sibebarbg='color_5'][data-sidebar-style='mini'][data-layout='vertical']
  .deznav
  .metismenu
  > li.mm-active
  > a,
[data-sibebarbg='color_5'][data-sidebar-style='mini'][data-layout='vertical']
  .deznav
  .metismenu
  > li:hover
  > a {
  background-color: #da4747;
}

[data-sibebarbg='color_5'][data-theme-version='dark'][data-sidebar-style='full'][data-layout='vertical']
  .menu-toggle
  .deznav
  .metismenu
  li
  > ul,
[data-sibebarbg='color_5'][data-theme-version='dark'][data-sidebar-style='full'][data-layout='vertical']
  .menu-toggle
  .deznav
  .metismenu
  > li.mm-active
  > a,
[data-sibebarbg='color_5'][data-theme-version='dark'][data-sidebar-style='full'][data-layout='vertical']
  .menu-toggle
  .deznav
  .metismenu
  > li:hover
  > a,
[data-sibebarbg='color_5'][data-sidebar-style='full'][data-layout='vertical']
  .menu-toggle
  .deznav
  .metismenu
  li
  > ul,
[data-sibebarbg='color_5'][data-sidebar-style='full'][data-layout='vertical']
  .menu-toggle
  .deznav
  .metismenu
  > li.mm-active
  > a,
[data-sibebarbg='color_5'][data-sidebar-style='full'][data-layout='vertical']
  .menu-toggle
  .deznav
  .metismenu
  > li:hover
  > a {
  background-color: #da4747;
}

[data-sibebarbg='color_5'][data-theme-version='dark'][data-sidebar-style='icon-hover'][data-layout='vertical']
  .deznav
  .metismenu
  ul,
[data-sibebarbg='color_5'][data-sidebar-style='icon-hover'][data-layout='vertical']
  .deznav
  .metismenu
  ul {
  border-color: rgba(255, 255, 255, 0.2);
}

[data-sibebarbg='color_5'][data-theme-version='dark'][data-layout='horizontal']
  .deznav
  .metismenu
  > li,
[data-sibebarbg='color_5'][data-layout='horizontal'] .deznav .metismenu > li {
  border-color: rgba(255, 255, 255, 0.2);
}

[data-sibebarbg='color_6'][data-theme-version='dark'] .deznav,
[data-sibebarbg='color_6'] .deznav {
  background-color: #283593;
}

[data-sibebarbg='color_6'][data-theme-version='dark']
  .deznav
  .menu-tabs
  li
  a.active,
[data-sibebarbg='color_6'] .deznav .menu-tabs li a.active {
  color: #283593;
}

[data-sibebarbg='color_6'][data-theme-version='dark']
  .deznav
  .metismenu
  .nav-label,
[data-sibebarbg='color_6'] .deznav .metismenu .nav-label {
  color: rgba(255, 255, 255, 0.7);
  border-color: rgba(255, 255, 255, 0.2);
}

[data-sibebarbg='color_6'][data-theme-version='dark']
  .deznav
  .metismenu
  > li
  > a,
[data-sibebarbg='color_6'] .deznav .metismenu > li > a {
  color: rgba(255, 255, 255, 0.7);
}

[data-sibebarbg='color_6'][data-theme-version='dark']
  .deznav
  .metismenu
  > li
  > a
  svg,
[data-sibebarbg='color_6'] .deznav .metismenu > li > a svg {
  color: rgba(255, 255, 255, 0.7);
}

[data-sibebarbg='color_6'][data-theme-version='dark']
  .deznav
  .metismenu
  > li
  > a
  g
  [fill],
[data-sibebarbg='color_6'] .deznav .metismenu > li > a g [fill] {
  fill: rgba(255, 255, 255, 0.7);
}

[data-sibebarbg='color_6'][data-theme-version='dark']
  .deznav
  .metismenu
  > li:hover
  > a,
[data-sibebarbg='color_6'][data-theme-version='dark']
  .deznav
  .metismenu
  > li:focus
  > a,
[data-sibebarbg='color_6'][data-theme-version='dark']
  .deznav
  .metismenu
  > li.mm-active
  > a,
[data-sibebarbg='color_6'] .deznav .metismenu > li:hover > a,
[data-sibebarbg='color_6'] .deznav .metismenu > li:focus > a,
[data-sibebarbg='color_6'] .deznav .metismenu > li.mm-active > a {
  color: #fff;
}

[data-sibebarbg='color_6'][data-theme-version='dark']
  .deznav
  .metismenu
  > li:hover
  > a
  g
  [fill],
[data-sibebarbg='color_6'][data-theme-version='dark']
  .deznav
  .metismenu
  > li:focus
  > a
  g
  [fill],
[data-sibebarbg='color_6'][data-theme-version='dark']
  .deznav
  .metismenu
  > li.mm-active
  > a
  g
  [fill],
[data-sibebarbg='color_6'] .deznav .metismenu > li:hover > a g [fill],
[data-sibebarbg='color_6'] .deznav .metismenu > li:focus > a g [fill],
[data-sibebarbg='color_6'] .deznav .metismenu > li.mm-active > a g [fill] {
  fill: #fff;
}

[data-sibebarbg='color_6'][data-theme-version='dark']
  .deznav
  .metismenu
  > li:hover
  > a
  svg,
[data-sibebarbg='color_6'][data-theme-version='dark']
  .deznav
  .metismenu
  > li:focus
  > a
  svg,
[data-sibebarbg='color_6'][data-theme-version='dark']
  .deznav
  .metismenu
  > li.mm-active
  > a
  svg,
[data-sibebarbg='color_6'] .deznav .metismenu > li:hover > a svg,
[data-sibebarbg='color_6'] .deznav .metismenu > li:focus > a svg,
[data-sibebarbg='color_6'] .deznav .metismenu > li.mm-active > a svg {
  color: #fff;
}

[data-layout='horizontal'][data-sibebarbg='color_6'][data-theme-version='dark']
  .deznav
  .metismenu
  > li,
[data-sibebarbg='color_6'] .deznav .metismenu > li {
  border-color: #3343bb;
}

[data-sibebarbg='color_6'][data-theme-version='dark'] .deznav .metismenu a,
[data-sibebarbg='color_6'] .deznav .metismenu a {
  color: rgba(255, 255, 255, 0.7);
}

[data-sibebarbg='color_6'][data-theme-version='dark']
  .deznav
  .metismenu
  a:hover,
[data-sibebarbg='color_6'][data-theme-version='dark']
  .deznav
  .metismenu
  a:focus,
[data-sibebarbg='color_6'][data-theme-version='dark']
  .deznav
  .metismenu
  a.mm-active,
[data-sibebarbg='color_6'] .deznav .metismenu a:hover,
[data-sibebarbg='color_6'] .deznav .metismenu a:focus,
[data-sibebarbg='color_6'] .deznav .metismenu a.mm-active {
  color: #fff;
}

[data-sibebarbg='color_6'][data-theme-version='dark'] .deznav .metismenu ul,
[data-sibebarbg='color_6'] .deznav .metismenu ul {
  border-color: rgba(255, 255, 255, 0.2);
}

[data-sibebarbg='color_6'][data-theme-version='dark']
  .deznav
  .metismenu
  ul:after,
[data-sibebarbg='color_6'] .deznav .metismenu ul:after {
  background-color: rgba(255, 255, 255, 0.2);
}

[data-sibebarbg='color_6'][data-theme-version='dark'][data-sidebar-style='mini'][data-layout='vertical']
  .deznav
  .metismenu
  li
  > ul,
[data-sibebarbg='color_6'][data-sidebar-style='mini'][data-layout='vertical']
  .deznav
  .metismenu
  li
  > ul {
  background-color: #3343bb;
}

[data-sibebarbg='color_6'][data-theme-version='dark'][data-sidebar-style='mini'][data-layout='vertical']
  .deznav
  .metismenu
  > li.mm-active
  > a,
[data-sibebarbg='color_6'][data-theme-version='dark'][data-sidebar-style='mini'][data-layout='vertical']
  .deznav
  .metismenu
  > li:hover
  > a,
[data-sibebarbg='color_6'][data-sidebar-style='mini'][data-layout='vertical']
  .deznav
  .metismenu
  > li.mm-active
  > a,
[data-sibebarbg='color_6'][data-sidebar-style='mini'][data-layout='vertical']
  .deznav
  .metismenu
  > li:hover
  > a {
  background-color: #3343bb;
}

[data-sibebarbg='color_6'][data-theme-version='dark'][data-sidebar-style='full'][data-layout='vertical']
  .menu-toggle
  .deznav
  .metismenu
  li
  > ul,
[data-sibebarbg='color_6'][data-theme-version='dark'][data-sidebar-style='full'][data-layout='vertical']
  .menu-toggle
  .deznav
  .metismenu
  > li.mm-active
  > a,
[data-sibebarbg='color_6'][data-theme-version='dark'][data-sidebar-style='full'][data-layout='vertical']
  .menu-toggle
  .deznav
  .metismenu
  > li:hover
  > a,
[data-sibebarbg='color_6'][data-sidebar-style='full'][data-layout='vertical']
  .menu-toggle
  .deznav
  .metismenu
  li
  > ul,
[data-sibebarbg='color_6'][data-sidebar-style='full'][data-layout='vertical']
  .menu-toggle
  .deznav
  .metismenu
  > li.mm-active
  > a,
[data-sibebarbg='color_6'][data-sidebar-style='full'][data-layout='vertical']
  .menu-toggle
  .deznav
  .metismenu
  > li:hover
  > a {
  background-color: #3343bb;
}

[data-sibebarbg='color_6'][data-theme-version='dark'][data-sidebar-style='icon-hover'][data-layout='vertical']
  .deznav
  .metismenu
  ul,
[data-sibebarbg='color_6'][data-sidebar-style='icon-hover'][data-layout='vertical']
  .deznav
  .metismenu
  ul {
  border-color: rgba(255, 255, 255, 0.2);
}

[data-sibebarbg='color_6'][data-theme-version='dark'][data-layout='horizontal']
  .deznav
  .metismenu
  > li,
[data-sibebarbg='color_6'][data-layout='horizontal'] .deznav .metismenu > li {
  border-color: rgba(255, 255, 255, 0.2);
}

[data-sibebarbg='color_7'][data-theme-version='dark'] .deznav,
[data-sibebarbg='color_7'] .deznav {
  background-color: #7356f1;
}

[data-sibebarbg='color_7'][data-theme-version='dark']
  .deznav
  .menu-tabs
  li
  a.active,
[data-sibebarbg='color_7'] .deznav .menu-tabs li a.active {
  color: #7356f1;
}

[data-sibebarbg='color_7'][data-theme-version='dark']
  .deznav
  .metismenu
  .nav-label,
[data-sibebarbg='color_7'] .deznav .metismenu .nav-label {
  color: rgba(255, 255, 255, 0.7);
  border-color: rgba(255, 255, 255, 0.2);
}

[data-sibebarbg='color_7'][data-theme-version='dark']
  .deznav
  .metismenu
  > li
  > a,
[data-sibebarbg='color_7'] .deznav .metismenu > li > a {
  color: rgba(255, 255, 255, 0.7);
}

[data-sibebarbg='color_7'][data-theme-version='dark']
  .deznav
  .metismenu
  > li
  > a
  svg,
[data-sibebarbg='color_7'] .deznav .metismenu > li > a svg {
  color: rgba(255, 255, 255, 0.7);
}

[data-sibebarbg='color_7'][data-theme-version='dark']
  .deznav
  .metismenu
  > li
  > a
  g
  [fill],
[data-sibebarbg='color_7'] .deznav .metismenu > li > a g [fill] {
  fill: rgba(255, 255, 255, 0.7);
}

[data-sibebarbg='color_7'][data-theme-version='dark']
  .deznav
  .metismenu
  > li:hover
  > a,
[data-sibebarbg='color_7'][data-theme-version='dark']
  .deznav
  .metismenu
  > li:focus
  > a,
[data-sibebarbg='color_7'][data-theme-version='dark']
  .deznav
  .metismenu
  > li.mm-active
  > a,
[data-sibebarbg='color_7'] .deznav .metismenu > li:hover > a,
[data-sibebarbg='color_7'] .deznav .metismenu > li:focus > a,
[data-sibebarbg='color_7'] .deznav .metismenu > li.mm-active > a {
  color: #fff;
}

[data-sibebarbg='color_7'][data-theme-version='dark']
  .deznav
  .metismenu
  > li:hover
  > a
  g
  [fill],
[data-sibebarbg='color_7'][data-theme-version='dark']
  .deznav
  .metismenu
  > li:focus
  > a
  g
  [fill],
[data-sibebarbg='color_7'][data-theme-version='dark']
  .deznav
  .metismenu
  > li.mm-active
  > a
  g
  [fill],
[data-sibebarbg='color_7'] .deznav .metismenu > li:hover > a g [fill],
[data-sibebarbg='color_7'] .deznav .metismenu > li:focus > a g [fill],
[data-sibebarbg='color_7'] .deznav .metismenu > li.mm-active > a g [fill] {
  fill: #fff;
}

[data-sibebarbg='color_7'][data-theme-version='dark']
  .deznav
  .metismenu
  > li:hover
  > a
  svg,
[data-sibebarbg='color_7'][data-theme-version='dark']
  .deznav
  .metismenu
  > li:focus
  > a
  svg,
[data-sibebarbg='color_7'][data-theme-version='dark']
  .deznav
  .metismenu
  > li.mm-active
  > a
  svg,
[data-sibebarbg='color_7'] .deznav .metismenu > li:hover > a svg,
[data-sibebarbg='color_7'] .deznav .metismenu > li:focus > a svg,
[data-sibebarbg='color_7'] .deznav .metismenu > li.mm-active > a svg {
  color: #fff;
}

[data-layout='horizontal'][data-sibebarbg='color_7'][data-theme-version='dark']
  .deznav
  .metismenu
  > li,
[data-sibebarbg='color_7'] .deznav .metismenu > li {
  border-color: #9a85f5;
}

[data-sibebarbg='color_7'][data-theme-version='dark'] .deznav .metismenu a,
[data-sibebarbg='color_7'] .deznav .metismenu a {
  color: rgba(255, 255, 255, 0.7);
}

[data-sibebarbg='color_7'][data-theme-version='dark']
  .deznav
  .metismenu
  a:hover,
[data-sibebarbg='color_7'][data-theme-version='dark']
  .deznav
  .metismenu
  a:focus,
[data-sibebarbg='color_7'][data-theme-version='dark']
  .deznav
  .metismenu
  a.mm-active,
[data-sibebarbg='color_7'] .deznav .metismenu a:hover,
[data-sibebarbg='color_7'] .deznav .metismenu a:focus,
[data-sibebarbg='color_7'] .deznav .metismenu a.mm-active {
  color: #fff;
}

[data-sibebarbg='color_7'][data-theme-version='dark'] .deznav .metismenu ul,
[data-sibebarbg='color_7'] .deznav .metismenu ul {
  border-color: rgba(255, 255, 255, 0.2);
}

[data-sibebarbg='color_7'][data-theme-version='dark']
  .deznav
  .metismenu
  ul:after,
[data-sibebarbg='color_7'] .deznav .metismenu ul:after {
  background-color: rgba(255, 255, 255, 0.2);
}

[data-sibebarbg='color_7'][data-theme-version='dark'][data-sidebar-style='mini'][data-layout='vertical']
  .deznav
  .metismenu
  li
  > ul,
[data-sibebarbg='color_7'][data-sidebar-style='mini'][data-layout='vertical']
  .deznav
  .metismenu
  li
  > ul {
  background-color: #9a85f5;
}

[data-sibebarbg='color_7'][data-theme-version='dark'][data-sidebar-style='mini'][data-layout='vertical']
  .deznav
  .metismenu
  > li.mm-active
  > a,
[data-sibebarbg='color_7'][data-theme-version='dark'][data-sidebar-style='mini'][data-layout='vertical']
  .deznav
  .metismenu
  > li:hover
  > a,
[data-sibebarbg='color_7'][data-sidebar-style='mini'][data-layout='vertical']
  .deznav
  .metismenu
  > li.mm-active
  > a,
[data-sibebarbg='color_7'][data-sidebar-style='mini'][data-layout='vertical']
  .deznav
  .metismenu
  > li:hover
  > a {
  background-color: #9a85f5;
}

[data-sibebarbg='color_7'][data-theme-version='dark'][data-sidebar-style='full'][data-layout='vertical']
  .menu-toggle
  .deznav
  .metismenu
  li
  > ul,
[data-sibebarbg='color_7'][data-theme-version='dark'][data-sidebar-style='full'][data-layout='vertical']
  .menu-toggle
  .deznav
  .metismenu
  > li.mm-active
  > a,
[data-sibebarbg='color_7'][data-theme-version='dark'][data-sidebar-style='full'][data-layout='vertical']
  .menu-toggle
  .deznav
  .metismenu
  > li:hover
  > a,
[data-sibebarbg='color_7'][data-sidebar-style='full'][data-layout='vertical']
  .menu-toggle
  .deznav
  .metismenu
  li
  > ul,
[data-sibebarbg='color_7'][data-sidebar-style='full'][data-layout='vertical']
  .menu-toggle
  .deznav
  .metismenu
  > li.mm-active
  > a,
[data-sibebarbg='color_7'][data-sidebar-style='full'][data-layout='vertical']
  .menu-toggle
  .deznav
  .metismenu
  > li:hover
  > a {
  background-color: #9a85f5;
}

[data-sibebarbg='color_7'][data-theme-version='dark'][data-sidebar-style='icon-hover'][data-layout='vertical']
  .deznav
  .metismenu
  ul,
[data-sibebarbg='color_7'][data-sidebar-style='icon-hover'][data-layout='vertical']
  .deznav
  .metismenu
  ul {
  border-color: rgba(255, 255, 255, 0.2);
}

[data-sibebarbg='color_7'][data-theme-version='dark'][data-layout='horizontal']
  .deznav
  .metismenu
  > li,
[data-sibebarbg='color_7'][data-layout='horizontal'] .deznav .metismenu > li {
  border-color: rgba(255, 255, 255, 0.2);
}

[data-sibebarbg='color_8'][data-theme-version='dark'] .deznav,
[data-sibebarbg='color_8'] .deznav {
  background-color: #3695eb;
}

[data-sibebarbg='color_8'][data-theme-version='dark']
  .deznav
  .menu-tabs
  li
  a.active,
[data-sibebarbg='color_8'] .deznav .menu-tabs li a.active {
  color: #3695eb;
}

[data-sibebarbg='color_8'][data-theme-version='dark']
  .deznav
  .metismenu
  .nav-label,
[data-sibebarbg='color_8'] .deznav .metismenu .nav-label {
  color: rgba(255, 255, 255, 0.7);
  border-color: rgba(255, 255, 255, 0.2);
}

[data-sibebarbg='color_8'][data-theme-version='dark']
  .deznav
  .metismenu
  > li
  > a,
[data-sibebarbg='color_8'] .deznav .metismenu > li > a {
  color: rgba(255, 255, 255, 0.7);
}

[data-sibebarbg='color_8'][data-theme-version='dark']
  .deznav
  .metismenu
  > li
  > a
  svg,
[data-sibebarbg='color_8'] .deznav .metismenu > li > a svg {
  color: rgba(255, 255, 255, 0.7);
}

[data-sibebarbg='color_8'][data-theme-version='dark']
  .deznav
  .metismenu
  > li
  > a
  g
  [fill],
[data-sibebarbg='color_8'] .deznav .metismenu > li > a g [fill] {
  fill: rgba(255, 255, 255, 0.7);
}

[data-sibebarbg='color_8'][data-theme-version='dark']
  .deznav
  .metismenu
  > li:hover
  > a,
[data-sibebarbg='color_8'][data-theme-version='dark']
  .deznav
  .metismenu
  > li:focus
  > a,
[data-sibebarbg='color_8'][data-theme-version='dark']
  .deznav
  .metismenu
  > li.mm-active
  > a,
[data-sibebarbg='color_8'] .deznav .metismenu > li:hover > a,
[data-sibebarbg='color_8'] .deznav .metismenu > li:focus > a,
[data-sibebarbg='color_8'] .deznav .metismenu > li.mm-active > a {
  color: #fff;
}

[data-sibebarbg='color_8'][data-theme-version='dark']
  .deznav
  .metismenu
  > li:hover
  > a
  g
  [fill],
[data-sibebarbg='color_8'][data-theme-version='dark']
  .deznav
  .metismenu
  > li:focus
  > a
  g
  [fill],
[data-sibebarbg='color_8'][data-theme-version='dark']
  .deznav
  .metismenu
  > li.mm-active
  > a
  g
  [fill],
[data-sibebarbg='color_8'] .deznav .metismenu > li:hover > a g [fill],
[data-sibebarbg='color_8'] .deznav .metismenu > li:focus > a g [fill],
[data-sibebarbg='color_8'] .deznav .metismenu > li.mm-active > a g [fill] {
  fill: #fff;
}

[data-sibebarbg='color_8'][data-theme-version='dark']
  .deznav
  .metismenu
  > li:hover
  > a
  svg,
[data-sibebarbg='color_8'][data-theme-version='dark']
  .deznav
  .metismenu
  > li:focus
  > a
  svg,
[data-sibebarbg='color_8'][data-theme-version='dark']
  .deznav
  .metismenu
  > li.mm-active
  > a
  svg,
[data-sibebarbg='color_8'] .deznav .metismenu > li:hover > a svg,
[data-sibebarbg='color_8'] .deznav .metismenu > li:focus > a svg,
[data-sibebarbg='color_8'] .deznav .metismenu > li.mm-active > a svg {
  color: #fff;
}

[data-layout='horizontal'][data-sibebarbg='color_8'][data-theme-version='dark']
  .deznav
  .metismenu
  > li,
[data-sibebarbg='color_8'] .deznav .metismenu > li {
  border-color: #64adf0;
}

[data-sibebarbg='color_8'][data-theme-version='dark'] .deznav .metismenu a,
[data-sibebarbg='color_8'] .deznav .metismenu a {
  color: rgba(255, 255, 255, 0.7);
}

[data-sibebarbg='color_8'][data-theme-version='dark']
  .deznav
  .metismenu
  a:hover,
[data-sibebarbg='color_8'][data-theme-version='dark']
  .deznav
  .metismenu
  a:focus,
[data-sibebarbg='color_8'][data-theme-version='dark']
  .deznav
  .metismenu
  a.mm-active,
[data-sibebarbg='color_8'] .deznav .metismenu a:hover,
[data-sibebarbg='color_8'] .deznav .metismenu a:focus,
[data-sibebarbg='color_8'] .deznav .metismenu a.mm-active {
  color: #fff;
}

[data-sibebarbg='color_8'][data-theme-version='dark'] .deznav .metismenu ul,
[data-sibebarbg='color_8'] .deznav .metismenu ul {
  border-color: rgba(255, 255, 255, 0.2);
}

[data-sibebarbg='color_8'][data-theme-version='dark']
  .deznav
  .metismenu
  ul:after,
[data-sibebarbg='color_8'] .deznav .metismenu ul:after {
  background-color: rgba(255, 255, 255, 0.2);
}

[data-sibebarbg='color_8'][data-theme-version='dark'][data-sidebar-style='mini'][data-layout='vertical']
  .deznav
  .metismenu
  li
  > ul,
[data-sibebarbg='color_8'][data-sidebar-style='mini'][data-layout='vertical']
  .deznav
  .metismenu
  li
  > ul {
  background-color: #64adf0;
}

[data-sibebarbg='color_8'][data-theme-version='dark'][data-sidebar-style='mini'][data-layout='vertical']
  .deznav
  .metismenu
  > li.mm-active
  > a,
[data-sibebarbg='color_8'][data-theme-version='dark'][data-sidebar-style='mini'][data-layout='vertical']
  .deznav
  .metismenu
  > li:hover
  > a,
[data-sibebarbg='color_8'][data-sidebar-style='mini'][data-layout='vertical']
  .deznav
  .metismenu
  > li.mm-active
  > a,
[data-sibebarbg='color_8'][data-sidebar-style='mini'][data-layout='vertical']
  .deznav
  .metismenu
  > li:hover
  > a {
  background-color: #64adf0;
}

[data-sibebarbg='color_8'][data-theme-version='dark'][data-sidebar-style='full'][data-layout='vertical']
  .menu-toggle
  .deznav
  .metismenu
  li
  > ul,
[data-sibebarbg='color_8'][data-theme-version='dark'][data-sidebar-style='full'][data-layout='vertical']
  .menu-toggle
  .deznav
  .metismenu
  > li.mm-active
  > a,
[data-sibebarbg='color_8'][data-theme-version='dark'][data-sidebar-style='full'][data-layout='vertical']
  .menu-toggle
  .deznav
  .metismenu
  > li:hover
  > a,
[data-sibebarbg='color_8'][data-sidebar-style='full'][data-layout='vertical']
  .menu-toggle
  .deznav
  .metismenu
  li
  > ul,
[data-sibebarbg='color_8'][data-sidebar-style='full'][data-layout='vertical']
  .menu-toggle
  .deznav
  .metismenu
  > li.mm-active
  > a,
[data-sibebarbg='color_8'][data-sidebar-style='full'][data-layout='vertical']
  .menu-toggle
  .deznav
  .metismenu
  > li:hover
  > a {
  background-color: #64adf0;
}

[data-sibebarbg='color_8'][data-theme-version='dark'][data-sidebar-style='icon-hover'][data-layout='vertical']
  .deznav
  .metismenu
  ul,
[data-sibebarbg='color_8'][data-sidebar-style='icon-hover'][data-layout='vertical']
  .deznav
  .metismenu
  ul {
  border-color: rgba(255, 255, 255, 0.2);
}

[data-sibebarbg='color_8'][data-theme-version='dark'][data-layout='horizontal']
  .deznav
  .metismenu
  > li,
[data-sibebarbg='color_8'][data-layout='horizontal'] .deznav .metismenu > li {
  border-color: rgba(255, 255, 255, 0.2);
}

[data-sibebarbg='color_9'][data-theme-version='dark'] .deznav,
[data-sibebarbg='color_9'] .deznav {
  background-color: #00838f;
}

[data-sibebarbg='color_9'][data-theme-version='dark']
  .deznav
  .menu-tabs
  li
  a.active,
[data-sibebarbg='color_9'] .deznav .menu-tabs li a.active {
  color: #00838f;
}

[data-sibebarbg='color_9'][data-theme-version='dark']
  .deznav
  .metismenu
  .nav-label,
[data-sibebarbg='color_9'] .deznav .metismenu .nav-label {
  color: rgba(255, 255, 255, 0.7);
  border-color: rgba(255, 255, 255, 0.2);
}

[data-sibebarbg='color_9'][data-theme-version='dark']
  .deznav
  .metismenu
  > li
  > a,
[data-sibebarbg='color_9'] .deznav .metismenu > li > a {
  color: rgba(255, 255, 255, 0.7);
}

[data-sibebarbg='color_9'][data-theme-version='dark']
  .deznav
  .metismenu
  > li
  > a
  svg,
[data-sibebarbg='color_9'] .deznav .metismenu > li > a svg {
  color: rgba(255, 255, 255, 0.7);
}

[data-sibebarbg='color_9'][data-theme-version='dark']
  .deznav
  .metismenu
  > li
  > a
  g
  [fill],
[data-sibebarbg='color_9'] .deznav .metismenu > li > a g [fill] {
  fill: rgba(255, 255, 255, 0.7);
}

[data-sibebarbg='color_9'][data-theme-version='dark']
  .deznav
  .metismenu
  > li:hover
  > a,
[data-sibebarbg='color_9'][data-theme-version='dark']
  .deznav
  .metismenu
  > li:focus
  > a,
[data-sibebarbg='color_9'][data-theme-version='dark']
  .deznav
  .metismenu
  > li.mm-active
  > a,
[data-sibebarbg='color_9'] .deznav .metismenu > li:hover > a,
[data-sibebarbg='color_9'] .deznav .metismenu > li:focus > a,
[data-sibebarbg='color_9'] .deznav .metismenu > li.mm-active > a {
  color: #fff;
}

[data-sibebarbg='color_9'][data-theme-version='dark']
  .deznav
  .metismenu
  > li:hover
  > a
  g
  [fill],
[data-sibebarbg='color_9'][data-theme-version='dark']
  .deznav
  .metismenu
  > li:focus
  > a
  g
  [fill],
[data-sibebarbg='color_9'][data-theme-version='dark']
  .deznav
  .metismenu
  > li.mm-active
  > a
  g
  [fill],
[data-sibebarbg='color_9'] .deznav .metismenu > li:hover > a g [fill],
[data-sibebarbg='color_9'] .deznav .metismenu > li:focus > a g [fill],
[data-sibebarbg='color_9'] .deznav .metismenu > li.mm-active > a g [fill] {
  fill: #fff;
}

[data-sibebarbg='color_9'][data-theme-version='dark']
  .deznav
  .metismenu
  > li:hover
  > a
  svg,
[data-sibebarbg='color_9'][data-theme-version='dark']
  .deznav
  .metismenu
  > li:focus
  > a
  svg,
[data-sibebarbg='color_9'][data-theme-version='dark']
  .deznav
  .metismenu
  > li.mm-active
  > a
  svg,
[data-sibebarbg='color_9'] .deznav .metismenu > li:hover > a svg,
[data-sibebarbg='color_9'] .deznav .metismenu > li:focus > a svg,
[data-sibebarbg='color_9'] .deznav .metismenu > li.mm-active > a svg {
  color: #fff;
}

[data-layout='horizontal'][data-sibebarbg='color_9'][data-theme-version='dark']
  .deznav
  .metismenu
  > li,
[data-sibebarbg='color_9'] .deznav .metismenu > li {
  border-color: #00b2c2;
}

[data-sibebarbg='color_9'][data-theme-version='dark'] .deznav .metismenu a,
[data-sibebarbg='color_9'] .deznav .metismenu a {
  color: rgba(255, 255, 255, 0.7);
}

[data-sibebarbg='color_9'][data-theme-version='dark']
  .deznav
  .metismenu
  a:hover,
[data-sibebarbg='color_9'][data-theme-version='dark']
  .deznav
  .metismenu
  a:focus,
[data-sibebarbg='color_9'][data-theme-version='dark']
  .deznav
  .metismenu
  a.mm-active,
[data-sibebarbg='color_9'] .deznav .metismenu a:hover,
[data-sibebarbg='color_9'] .deznav .metismenu a:focus,
[data-sibebarbg='color_9'] .deznav .metismenu a.mm-active {
  color: #fff;
}

[data-sibebarbg='color_9'][data-theme-version='dark'] .deznav .metismenu ul,
[data-sibebarbg='color_9'] .deznav .metismenu ul {
  border-color: rgba(255, 255, 255, 0.2);
}

[data-sibebarbg='color_9'][data-theme-version='dark']
  .deznav
  .metismenu
  ul:after,
[data-sibebarbg='color_9'] .deznav .metismenu ul:after {
  background-color: rgba(255, 255, 255, 0.2);
}

[data-sibebarbg='color_9'][data-theme-version='dark'][data-sidebar-style='mini'][data-layout='vertical']
  .deznav
  .metismenu
  li
  > ul,
[data-sibebarbg='color_9'][data-sidebar-style='mini'][data-layout='vertical']
  .deznav
  .metismenu
  li
  > ul {
  background-color: #00b2c2;
}

[data-sibebarbg='color_9'][data-theme-version='dark'][data-sidebar-style='mini'][data-layout='vertical']
  .deznav
  .metismenu
  > li.mm-active
  > a,
[data-sibebarbg='color_9'][data-theme-version='dark'][data-sidebar-style='mini'][data-layout='vertical']
  .deznav
  .metismenu
  > li:hover
  > a,
[data-sibebarbg='color_9'][data-sidebar-style='mini'][data-layout='vertical']
  .deznav
  .metismenu
  > li.mm-active
  > a,
[data-sibebarbg='color_9'][data-sidebar-style='mini'][data-layout='vertical']
  .deznav
  .metismenu
  > li:hover
  > a {
  background-color: #00b2c2;
}

[data-sibebarbg='color_9'][data-theme-version='dark'][data-sidebar-style='full'][data-layout='vertical']
  .menu-toggle
  .deznav
  .metismenu
  li
  > ul,
[data-sibebarbg='color_9'][data-theme-version='dark'][data-sidebar-style='full'][data-layout='vertical']
  .menu-toggle
  .deznav
  .metismenu
  > li.mm-active
  > a,
[data-sibebarbg='color_9'][data-theme-version='dark'][data-sidebar-style='full'][data-layout='vertical']
  .menu-toggle
  .deznav
  .metismenu
  > li:hover
  > a,
[data-sibebarbg='color_9'][data-sidebar-style='full'][data-layout='vertical']
  .menu-toggle
  .deznav
  .metismenu
  li
  > ul,
[data-sibebarbg='color_9'][data-sidebar-style='full'][data-layout='vertical']
  .menu-toggle
  .deznav
  .metismenu
  > li.mm-active
  > a,
[data-sibebarbg='color_9'][data-sidebar-style='full'][data-layout='vertical']
  .menu-toggle
  .deznav
  .metismenu
  > li:hover
  > a {
  background-color: #00b2c2;
}

[data-sibebarbg='color_9'][data-theme-version='dark'][data-sidebar-style='icon-hover'][data-layout='vertical']
  .deznav
  .metismenu
  ul,
[data-sibebarbg='color_9'][data-sidebar-style='icon-hover'][data-layout='vertical']
  .deznav
  .metismenu
  ul {
  border-color: rgba(255, 255, 255, 0.2);
}

[data-sibebarbg='color_9'][data-theme-version='dark'][data-layout='horizontal']
  .deznav
  .metismenu
  > li,
[data-sibebarbg='color_9'][data-layout='horizontal'] .deznav .metismenu > li {
  border-color: rgba(255, 255, 255, 0.2);
}

[data-sibebarbg='color_10'][data-theme-version='dark'] .deznav,
[data-sibebarbg='color_10'] .deznav {
  background-color: #ff8f16;
}

[data-sibebarbg='color_10'][data-theme-version='dark']
  .deznav
  .menu-tabs
  li
  a.active,
[data-sibebarbg='color_10'] .deznav .menu-tabs li a.active {
  color: #ff8f16;
}

[data-sibebarbg='color_10'][data-theme-version='dark']
  .deznav
  .metismenu
  .nav-label,
[data-sibebarbg='color_10'] .deznav .metismenu .nav-label {
  color: rgba(255, 255, 255, 0.7);
  border-color: rgba(255, 255, 255, 0.2);
}

[data-sibebarbg='color_10'][data-theme-version='dark']
  .deznav
  .metismenu
  > li
  > a,
[data-sibebarbg='color_10'] .deznav .metismenu > li > a {
  color: rgba(255, 255, 255, 0.7);
}

[data-sibebarbg='color_10'][data-theme-version='dark']
  .deznav
  .metismenu
  > li
  > a
  svg,
[data-sibebarbg='color_10'] .deznav .metismenu > li > a svg {
  color: rgba(255, 255, 255, 0.7);
}

[data-sibebarbg='color_10'][data-theme-version='dark']
  .deznav
  .metismenu
  > li
  > a
  g
  [fill],
[data-sibebarbg='color_10'] .deznav .metismenu > li > a g [fill] {
  fill: rgba(255, 255, 255, 0.7);
}

[data-sibebarbg='color_10'][data-theme-version='dark']
  .deznav
  .metismenu
  > li:hover
  > a,
[data-sibebarbg='color_10'][data-theme-version='dark']
  .deznav
  .metismenu
  > li:focus
  > a,
[data-sibebarbg='color_10'][data-theme-version='dark']
  .deznav
  .metismenu
  > li.mm-active
  > a,
[data-sibebarbg='color_10'] .deznav .metismenu > li:hover > a,
[data-sibebarbg='color_10'] .deznav .metismenu > li:focus > a,
[data-sibebarbg='color_10'] .deznav .metismenu > li.mm-active > a {
  color: #fff;
}

[data-sibebarbg='color_10'][data-theme-version='dark']
  .deznav
  .metismenu
  > li:hover
  > a
  g
  [fill],
[data-sibebarbg='color_10'][data-theme-version='dark']
  .deznav
  .metismenu
  > li:focus
  > a
  g
  [fill],
[data-sibebarbg='color_10'][data-theme-version='dark']
  .deznav
  .metismenu
  > li.mm-active
  > a
  g
  [fill],
[data-sibebarbg='color_10'] .deznav .metismenu > li:hover > a g [fill],
[data-sibebarbg='color_10'] .deznav .metismenu > li:focus > a g [fill],
[data-sibebarbg='color_10'] .deznav .metismenu > li.mm-active > a g [fill] {
  fill: #fff;
}

[data-sibebarbg='color_10'][data-theme-version='dark']
  .deznav
  .metismenu
  > li:hover
  > a
  svg,
[data-sibebarbg='color_10'][data-theme-version='dark']
  .deznav
  .metismenu
  > li:focus
  > a
  svg,
[data-sibebarbg='color_10'][data-theme-version='dark']
  .deznav
  .metismenu
  > li.mm-active
  > a
  svg,
[data-sibebarbg='color_10'] .deznav .metismenu > li:hover > a svg,
[data-sibebarbg='color_10'] .deznav .metismenu > li:focus > a svg,
[data-sibebarbg='color_10'] .deznav .metismenu > li.mm-active > a svg {
  color: #fff;
}

[data-layout='horizontal'][data-sibebarbg='color_10'][data-theme-version='dark']
  .deznav
  .metismenu
  > li,
[data-sibebarbg='color_10'] .deznav .metismenu > li {
  border-color: #ffa849;
}

[data-sibebarbg='color_10'][data-theme-version='dark'] .deznav .metismenu a,
[data-sibebarbg='color_10'] .deznav .metismenu a {
  color: rgba(255, 255, 255, 0.7);
}

[data-sibebarbg='color_10'][data-theme-version='dark']
  .deznav
  .metismenu
  a:hover,
[data-sibebarbg='color_10'][data-theme-version='dark']
  .deznav
  .metismenu
  a:focus,
[data-sibebarbg='color_10'][data-theme-version='dark']
  .deznav
  .metismenu
  a.mm-active,
[data-sibebarbg='color_10'] .deznav .metismenu a:hover,
[data-sibebarbg='color_10'] .deznav .metismenu a:focus,
[data-sibebarbg='color_10'] .deznav .metismenu a.mm-active {
  color: #fff;
}

[data-sibebarbg='color_10'][data-theme-version='dark'] .deznav .metismenu ul,
[data-sibebarbg='color_10'] .deznav .metismenu ul {
  border-color: rgba(255, 255, 255, 0.2);
}

[data-sibebarbg='color_10'][data-theme-version='dark']
  .deznav
  .metismenu
  ul:after,
[data-sibebarbg='color_10'] .deznav .metismenu ul:after {
  background-color: rgba(255, 255, 255, 0.2);
}

[data-sibebarbg='color_10'][data-theme-version='dark'][data-sidebar-style='mini'][data-layout='vertical']
  .deznav
  .metismenu
  li
  > ul,
[data-sibebarbg='color_10'][data-sidebar-style='mini'][data-layout='vertical']
  .deznav
  .metismenu
  li
  > ul {
  background-color: #ffa849;
}

[data-sibebarbg='color_10'][data-theme-version='dark'][data-sidebar-style='mini'][data-layout='vertical']
  .deznav
  .metismenu
  > li.mm-active
  > a,
[data-sibebarbg='color_10'][data-theme-version='dark'][data-sidebar-style='mini'][data-layout='vertical']
  .deznav
  .metismenu
  > li:hover
  > a,
[data-sibebarbg='color_10'][data-sidebar-style='mini'][data-layout='vertical']
  .deznav
  .metismenu
  > li.mm-active
  > a,
[data-sibebarbg='color_10'][data-sidebar-style='mini'][data-layout='vertical']
  .deznav
  .metismenu
  > li:hover
  > a {
  background-color: #ffa849;
}

[data-sibebarbg='color_10'][data-theme-version='dark'][data-sidebar-style='full'][data-layout='vertical']
  .menu-toggle
  .deznav
  .metismenu
  li
  > ul,
[data-sibebarbg='color_10'][data-theme-version='dark'][data-sidebar-style='full'][data-layout='vertical']
  .menu-toggle
  .deznav
  .metismenu
  > li.mm-active
  > a,
[data-sibebarbg='color_10'][data-theme-version='dark'][data-sidebar-style='full'][data-layout='vertical']
  .menu-toggle
  .deznav
  .metismenu
  > li:hover
  > a,
[data-sibebarbg='color_10'][data-sidebar-style='full'][data-layout='vertical']
  .menu-toggle
  .deznav
  .metismenu
  li
  > ul,
[data-sibebarbg='color_10'][data-sidebar-style='full'][data-layout='vertical']
  .menu-toggle
  .deznav
  .metismenu
  > li.mm-active
  > a,
[data-sibebarbg='color_10'][data-sidebar-style='full'][data-layout='vertical']
  .menu-toggle
  .deznav
  .metismenu
  > li:hover
  > a {
  background-color: #ffa849;
}

[data-sibebarbg='color_10'][data-theme-version='dark'][data-sidebar-style='icon-hover'][data-layout='vertical']
  .deznav
  .metismenu
  ul,
[data-sibebarbg='color_10'][data-sidebar-style='icon-hover'][data-layout='vertical']
  .deznav
  .metismenu
  ul {
  border-color: rgba(255, 255, 255, 0.2);
}

[data-sibebarbg='color_10'][data-theme-version='dark'][data-layout='horizontal']
  .deznav
  .metismenu
  > li,
[data-sibebarbg='color_10'][data-layout='horizontal'] .deznav .metismenu > li {
  border-color: rgba(255, 255, 255, 0.2);
}

[data-sibebarbg='color_11'][data-theme-version='dark'] .deznav,
[data-sibebarbg='color_11'] .deznav {
  background-color: #6673fd;
}

[data-sibebarbg='color_11'][data-theme-version='dark']
  .deznav
  .menu-tabs
  li
  a.active,
[data-sibebarbg='color_11'] .deznav .menu-tabs li a.active {
  color: #6673fd;
}

[data-sibebarbg='color_11'][data-theme-version='dark']
  .deznav
  .metismenu
  .nav-label,
[data-sibebarbg='color_11'] .deznav .metismenu .nav-label {
  color: rgba(255, 255, 255, 0.7);
  border-color: rgba(255, 255, 255, 0.2);
}

[data-sibebarbg='color_11'][data-theme-version='dark']
  .deznav
  .metismenu
  > li
  > a,
[data-sibebarbg='color_11'] .deznav .metismenu > li > a {
  color: rgba(255, 255, 255, 0.7);
}

[data-sibebarbg='color_11'][data-theme-version='dark']
  .deznav
  .metismenu
  > li
  > a
  svg,
[data-sibebarbg='color_11'] .deznav .metismenu > li > a svg {
  color: rgba(255, 255, 255, 0.7);
}

[data-sibebarbg='color_11'][data-theme-version='dark']
  .deznav
  .metismenu
  > li
  > a
  g
  [fill],
[data-sibebarbg='color_11'] .deznav .metismenu > li > a g [fill] {
  fill: rgba(255, 255, 255, 0.7);
}

[data-sibebarbg='color_11'][data-theme-version='dark']
  .deznav
  .metismenu
  > li:hover
  > a,
[data-sibebarbg='color_11'][data-theme-version='dark']
  .deznav
  .metismenu
  > li:focus
  > a,
[data-sibebarbg='color_11'][data-theme-version='dark']
  .deznav
  .metismenu
  > li.mm-active
  > a,
[data-sibebarbg='color_11'] .deznav .metismenu > li:hover > a,
[data-sibebarbg='color_11'] .deznav .metismenu > li:focus > a,
[data-sibebarbg='color_11'] .deznav .metismenu > li.mm-active > a {
  color: #fff;
}

[data-sibebarbg='color_11'][data-theme-version='dark']
  .deznav
  .metismenu
  > li:hover
  > a
  g
  [fill],
[data-sibebarbg='color_11'][data-theme-version='dark']
  .deznav
  .metismenu
  > li:focus
  > a
  g
  [fill],
[data-sibebarbg='color_11'][data-theme-version='dark']
  .deznav
  .metismenu
  > li.mm-active
  > a
  g
  [fill],
[data-sibebarbg='color_11'] .deznav .metismenu > li:hover > a g [fill],
[data-sibebarbg='color_11'] .deznav .metismenu > li:focus > a g [fill],
[data-sibebarbg='color_11'] .deznav .metismenu > li.mm-active > a g [fill] {
  fill: #fff;
}

[data-sibebarbg='color_11'][data-theme-version='dark']
  .deznav
  .metismenu
  > li:hover
  > a
  svg,
[data-sibebarbg='color_11'][data-theme-version='dark']
  .deznav
  .metismenu
  > li:focus
  > a
  svg,
[data-sibebarbg='color_11'][data-theme-version='dark']
  .deznav
  .metismenu
  > li.mm-active
  > a
  svg,
[data-sibebarbg='color_11'] .deznav .metismenu > li:hover > a svg,
[data-sibebarbg='color_11'] .deznav .metismenu > li:focus > a svg,
[data-sibebarbg='color_11'] .deznav .metismenu > li.mm-active > a svg {
  color: #fff;
}

[data-layout='horizontal'][data-sibebarbg='color_11'][data-theme-version='dark']
  .deznav
  .metismenu
  > li,
[data-sibebarbg='color_11'] .deznav .metismenu > li {
  border-color: #98a1fe;
}

[data-sibebarbg='color_11'][data-theme-version='dark'] .deznav .metismenu a,
[data-sibebarbg='color_11'] .deznav .metismenu a {
  color: rgba(255, 255, 255, 0.7);
}

[data-sibebarbg='color_11'][data-theme-version='dark']
  .deznav
  .metismenu
  a:hover,
[data-sibebarbg='color_11'][data-theme-version='dark']
  .deznav
  .metismenu
  a:focus,
[data-sibebarbg='color_11'][data-theme-version='dark']
  .deznav
  .metismenu
  a.mm-active,
[data-sibebarbg='color_11'] .deznav .metismenu a:hover,
[data-sibebarbg='color_11'] .deznav .metismenu a:focus,
[data-sibebarbg='color_11'] .deznav .metismenu a.mm-active {
  color: #fff;
}

[data-sibebarbg='color_11'][data-theme-version='dark'] .deznav .metismenu ul,
[data-sibebarbg='color_11'] .deznav .metismenu ul {
  border-color: rgba(255, 255, 255, 0.2);
}

[data-sibebarbg='color_11'][data-theme-version='dark']
  .deznav
  .metismenu
  ul:after,
[data-sibebarbg='color_11'] .deznav .metismenu ul:after {
  background-color: rgba(255, 255, 255, 0.2);
}

[data-sibebarbg='color_11'][data-theme-version='dark'][data-sidebar-style='mini'][data-layout='vertical']
  .deznav
  .metismenu
  li
  > ul,
[data-sibebarbg='color_11'][data-sidebar-style='mini'][data-layout='vertical']
  .deznav
  .metismenu
  li
  > ul {
  background-color: #98a1fe;
}

[data-sibebarbg='color_11'][data-theme-version='dark'][data-sidebar-style='mini'][data-layout='vertical']
  .deznav
  .metismenu
  > li.mm-active
  > a,
[data-sibebarbg='color_11'][data-theme-version='dark'][data-sidebar-style='mini'][data-layout='vertical']
  .deznav
  .metismenu
  > li:hover
  > a,
[data-sibebarbg='color_11'][data-sidebar-style='mini'][data-layout='vertical']
  .deznav
  .metismenu
  > li.mm-active
  > a,
[data-sibebarbg='color_11'][data-sidebar-style='mini'][data-layout='vertical']
  .deznav
  .metismenu
  > li:hover
  > a {
  background-color: #98a1fe;
}

[data-sibebarbg='color_11'][data-theme-version='dark'][data-sidebar-style='full'][data-layout='vertical']
  .menu-toggle
  .deznav
  .metismenu
  li
  > ul,
[data-sibebarbg='color_11'][data-theme-version='dark'][data-sidebar-style='full'][data-layout='vertical']
  .menu-toggle
  .deznav
  .metismenu
  > li.mm-active
  > a,
[data-sibebarbg='color_11'][data-theme-version='dark'][data-sidebar-style='full'][data-layout='vertical']
  .menu-toggle
  .deznav
  .metismenu
  > li:hover
  > a,
[data-sibebarbg='color_11'][data-sidebar-style='full'][data-layout='vertical']
  .menu-toggle
  .deznav
  .metismenu
  li
  > ul,
[data-sibebarbg='color_11'][data-sidebar-style='full'][data-layout='vertical']
  .menu-toggle
  .deznav
  .metismenu
  > li.mm-active
  > a,
[data-sibebarbg='color_11'][data-sidebar-style='full'][data-layout='vertical']
  .menu-toggle
  .deznav
  .metismenu
  > li:hover
  > a {
  background-color: #98a1fe;
}

[data-sibebarbg='color_11'][data-theme-version='dark'][data-sidebar-style='icon-hover'][data-layout='vertical']
  .deznav
  .metismenu
  ul,
[data-sibebarbg='color_11'][data-sidebar-style='icon-hover'][data-layout='vertical']
  .deznav
  .metismenu
  ul {
  border-color: rgba(255, 255, 255, 0.2);
}

[data-sibebarbg='color_11'][data-theme-version='dark'][data-layout='horizontal']
  .deznav
  .metismenu
  > li,
[data-sibebarbg='color_11'][data-layout='horizontal'] .deznav .metismenu > li {
  border-color: rgba(255, 255, 255, 0.2);
}

[data-sibebarbg='color_12'][data-theme-version='dark'] .deznav,
[data-sibebarbg='color_12'] .deznav {
  background-color: #558b2f;
}

[data-sibebarbg='color_12'][data-theme-version='dark']
  .deznav
  .menu-tabs
  li
  a.active,
[data-sibebarbg='color_12'] .deznav .menu-tabs li a.active {
  color: #558b2f;
}

[data-sibebarbg='color_12'][data-theme-version='dark']
  .deznav
  .metismenu
  .nav-label,
[data-sibebarbg='color_12'] .deznav .metismenu .nav-label {
  color: rgba(255, 255, 255, 0.7);
  border-color: rgba(255, 255, 255, 0.2);
}

[data-sibebarbg='color_12'][data-theme-version='dark']
  .deznav
  .metismenu
  > li
  > a,
[data-sibebarbg='color_12'] .deznav .metismenu > li > a {
  color: rgba(255, 255, 255, 0.7);
}

[data-sibebarbg='color_12'][data-theme-version='dark']
  .deznav
  .metismenu
  > li
  > a
  svg,
[data-sibebarbg='color_12'] .deznav .metismenu > li > a svg {
  color: rgba(255, 255, 255, 0.7);
}

[data-sibebarbg='color_12'][data-theme-version='dark']
  .deznav
  .metismenu
  > li
  > a
  g
  [fill],
[data-sibebarbg='color_12'] .deznav .metismenu > li > a g [fill] {
  fill: rgba(255, 255, 255, 0.7);
}

[data-sibebarbg='color_12'][data-theme-version='dark']
  .deznav
  .metismenu
  > li:hover
  > a,
[data-sibebarbg='color_12'][data-theme-version='dark']
  .deznav
  .metismenu
  > li:focus
  > a,
[data-sibebarbg='color_12'][data-theme-version='dark']
  .deznav
  .metismenu
  > li.mm-active
  > a,
[data-sibebarbg='color_12'] .deznav .metismenu > li:hover > a,
[data-sibebarbg='color_12'] .deznav .metismenu > li:focus > a,
[data-sibebarbg='color_12'] .deznav .metismenu > li.mm-active > a {
  color: #fff;
}

[data-sibebarbg='color_12'][data-theme-version='dark']
  .deznav
  .metismenu
  > li:hover
  > a
  g
  [fill],
[data-sibebarbg='color_12'][data-theme-version='dark']
  .deznav
  .metismenu
  > li:focus
  > a
  g
  [fill],
[data-sibebarbg='color_12'][data-theme-version='dark']
  .deznav
  .metismenu
  > li.mm-active
  > a
  g
  [fill],
[data-sibebarbg='color_12'] .deznav .metismenu > li:hover > a g [fill],
[data-sibebarbg='color_12'] .deznav .metismenu > li:focus > a g [fill],
[data-sibebarbg='color_12'] .deznav .metismenu > li.mm-active > a g [fill] {
  fill: #fff;
}

[data-sibebarbg='color_12'][data-theme-version='dark']
  .deznav
  .metismenu
  > li:hover
  > a
  svg,
[data-sibebarbg='color_12'][data-theme-version='dark']
  .deznav
  .metismenu
  > li:focus
  > a
  svg,
[data-sibebarbg='color_12'][data-theme-version='dark']
  .deznav
  .metismenu
  > li.mm-active
  > a
  svg,
[data-sibebarbg='color_12'] .deznav .metismenu > li:hover > a svg,
[data-sibebarbg='color_12'] .deznav .metismenu > li:focus > a svg,
[data-sibebarbg='color_12'] .deznav .metismenu > li.mm-active > a svg {
  color: #fff;
}

[data-layout='horizontal'][data-sibebarbg='color_12'][data-theme-version='dark']
  .deznav
  .metismenu
  > li,
[data-sibebarbg='color_12'] .deznav .metismenu > li {
  border-color: #6cb13c;
}

[data-sibebarbg='color_12'][data-theme-version='dark'] .deznav .metismenu a,
[data-sibebarbg='color_12'] .deznav .metismenu a {
  color: rgba(255, 255, 255, 0.7);
}

[data-sibebarbg='color_12'][data-theme-version='dark']
  .deznav
  .metismenu
  a:hover,
[data-sibebarbg='color_12'][data-theme-version='dark']
  .deznav
  .metismenu
  a:focus,
[data-sibebarbg='color_12'][data-theme-version='dark']
  .deznav
  .metismenu
  a.mm-active,
[data-sibebarbg='color_12'] .deznav .metismenu a:hover,
[data-sibebarbg='color_12'] .deznav .metismenu a:focus,
[data-sibebarbg='color_12'] .deznav .metismenu a.mm-active {
  color: #fff;
}

[data-sibebarbg='color_12'][data-theme-version='dark'] .deznav .metismenu ul,
[data-sibebarbg='color_12'] .deznav .metismenu ul {
  border-color: rgba(255, 255, 255, 0.2);
}

[data-sibebarbg='color_12'][data-theme-version='dark']
  .deznav
  .metismenu
  ul:after,
[data-sibebarbg='color_12'] .deznav .metismenu ul:after {
  background-color: rgba(255, 255, 255, 0.2);
}

[data-sibebarbg='color_12'][data-theme-version='dark'][data-sidebar-style='mini'][data-layout='vertical']
  .deznav
  .metismenu
  li
  > ul,
[data-sibebarbg='color_12'][data-sidebar-style='mini'][data-layout='vertical']
  .deznav
  .metismenu
  li
  > ul {
  background-color: #6cb13c;
}

[data-sibebarbg='color_12'][data-theme-version='dark'][data-sidebar-style='mini'][data-layout='vertical']
  .deznav
  .metismenu
  > li.mm-active
  > a,
[data-sibebarbg='color_12'][data-theme-version='dark'][data-sidebar-style='mini'][data-layout='vertical']
  .deznav
  .metismenu
  > li:hover
  > a,
[data-sibebarbg='color_12'][data-sidebar-style='mini'][data-layout='vertical']
  .deznav
  .metismenu
  > li.mm-active
  > a,
[data-sibebarbg='color_12'][data-sidebar-style='mini'][data-layout='vertical']
  .deznav
  .metismenu
  > li:hover
  > a {
  background-color: #6cb13c;
}

[data-sibebarbg='color_12'][data-theme-version='dark'][data-sidebar-style='full'][data-layout='vertical']
  .menu-toggle
  .deznav
  .metismenu
  li
  > ul,
[data-sibebarbg='color_12'][data-theme-version='dark'][data-sidebar-style='full'][data-layout='vertical']
  .menu-toggle
  .deznav
  .metismenu
  > li.mm-active
  > a,
[data-sibebarbg='color_12'][data-theme-version='dark'][data-sidebar-style='full'][data-layout='vertical']
  .menu-toggle
  .deznav
  .metismenu
  > li:hover
  > a,
[data-sibebarbg='color_12'][data-sidebar-style='full'][data-layout='vertical']
  .menu-toggle
  .deznav
  .metismenu
  li
  > ul,
[data-sibebarbg='color_12'][data-sidebar-style='full'][data-layout='vertical']
  .menu-toggle
  .deznav
  .metismenu
  > li.mm-active
  > a,
[data-sibebarbg='color_12'][data-sidebar-style='full'][data-layout='vertical']
  .menu-toggle
  .deznav
  .metismenu
  > li:hover
  > a {
  background-color: #6cb13c;
}

[data-sibebarbg='color_12'][data-theme-version='dark'][data-sidebar-style='icon-hover'][data-layout='vertical']
  .deznav
  .metismenu
  ul,
[data-sibebarbg='color_12'][data-sidebar-style='icon-hover'][data-layout='vertical']
  .deznav
  .metismenu
  ul {
  border-color: rgba(255, 255, 255, 0.2);
}

[data-sibebarbg='color_12'][data-theme-version='dark'][data-layout='horizontal']
  .deznav
  .metismenu
  > li,
[data-sibebarbg='color_12'][data-layout='horizontal'] .deznav .metismenu > li {
  border-color: rgba(255, 255, 255, 0.2);
}

[data-sibebarbg='color_13'][data-theme-version='dark'] .deznav,
[data-sibebarbg='color_13'] .deznav {
  background-color: #2a2a2a;
}

[data-sibebarbg='color_13'][data-theme-version='dark']
  .deznav
  .menu-tabs
  li
  a.active,
[data-sibebarbg='color_13'] .deznav .menu-tabs li a.active {
  color: #2a2a2a;
}

[data-sibebarbg='color_13'][data-theme-version='dark']
  .deznav
  .metismenu
  .nav-label,
[data-sibebarbg='color_13'] .deznav .metismenu .nav-label {
  color: rgba(255, 255, 255, 0.7);
  border-color: rgba(255, 255, 255, 0.2);
}

[data-sibebarbg='color_13'][data-theme-version='dark']
  .deznav
  .metismenu
  > li
  > a,
[data-sibebarbg='color_13'] .deznav .metismenu > li > a {
  color: rgba(255, 255, 255, 0.7);
}

[data-sibebarbg='color_13'][data-theme-version='dark']
  .deznav
  .metismenu
  > li
  > a
  svg,
[data-sibebarbg='color_13'] .deznav .metismenu > li > a svg {
  color: rgba(255, 255, 255, 0.7);
}

[data-sibebarbg='color_13'][data-theme-version='dark']
  .deznav
  .metismenu
  > li
  > a
  g
  [fill],
[data-sibebarbg='color_13'] .deznav .metismenu > li > a g [fill] {
  fill: rgba(255, 255, 255, 0.7);
}

[data-sibebarbg='color_13'][data-theme-version='dark']
  .deznav
  .metismenu
  > li:hover
  > a,
[data-sibebarbg='color_13'][data-theme-version='dark']
  .deznav
  .metismenu
  > li:focus
  > a,
[data-sibebarbg='color_13'][data-theme-version='dark']
  .deznav
  .metismenu
  > li.mm-active
  > a,
[data-sibebarbg='color_13'] .deznav .metismenu > li:hover > a,
[data-sibebarbg='color_13'] .deznav .metismenu > li:focus > a,
[data-sibebarbg='color_13'] .deznav .metismenu > li.mm-active > a {
  color: #fff;
}

[data-sibebarbg='color_13'][data-theme-version='dark']
  .deznav
  .metismenu
  > li:hover
  > a
  g
  [fill],
[data-sibebarbg='color_13'][data-theme-version='dark']
  .deznav
  .metismenu
  > li:focus
  > a
  g
  [fill],
[data-sibebarbg='color_13'][data-theme-version='dark']
  .deznav
  .metismenu
  > li.mm-active
  > a
  g
  [fill],
[data-sibebarbg='color_13'] .deznav .metismenu > li:hover > a g [fill],
[data-sibebarbg='color_13'] .deznav .metismenu > li:focus > a g [fill],
[data-sibebarbg='color_13'] .deznav .metismenu > li.mm-active > a g [fill] {
  fill: #fff;
}

[data-sibebarbg='color_13'][data-theme-version='dark']
  .deznav
  .metismenu
  > li:hover
  > a
  svg,
[data-sibebarbg='color_13'][data-theme-version='dark']
  .deznav
  .metismenu
  > li:focus
  > a
  svg,
[data-sibebarbg='color_13'][data-theme-version='dark']
  .deznav
  .metismenu
  > li.mm-active
  > a
  svg,
[data-sibebarbg='color_13'] .deznav .metismenu > li:hover > a svg,
[data-sibebarbg='color_13'] .deznav .metismenu > li:focus > a svg,
[data-sibebarbg='color_13'] .deznav .metismenu > li.mm-active > a svg {
  color: #fff;
}

[data-layout='horizontal'][data-sibebarbg='color_13'][data-theme-version='dark']
  .deznav
  .metismenu
  > li,
[data-sibebarbg='color_13'] .deznav .metismenu > li {
  border-color: #444444;
}

[data-sibebarbg='color_13'][data-theme-version='dark'] .deznav .metismenu a,
[data-sibebarbg='color_13'] .deznav .metismenu a {
  color: rgba(255, 255, 255, 0.7);
}

[data-sibebarbg='color_13'][data-theme-version='dark']
  .deznav
  .metismenu
  a:hover,
[data-sibebarbg='color_13'][data-theme-version='dark']
  .deznav
  .metismenu
  a:focus,
[data-sibebarbg='color_13'][data-theme-version='dark']
  .deznav
  .metismenu
  a.mm-active,
[data-sibebarbg='color_13'] .deznav .metismenu a:hover,
[data-sibebarbg='color_13'] .deznav .metismenu a:focus,
[data-sibebarbg='color_13'] .deznav .metismenu a.mm-active {
  color: #fff;
}

[data-sibebarbg='color_13'][data-theme-version='dark'] .deznav .metismenu ul,
[data-sibebarbg='color_13'] .deznav .metismenu ul {
  border-color: rgba(255, 255, 255, 0.2);
}

[data-sibebarbg='color_13'][data-theme-version='dark']
  .deznav
  .metismenu
  ul:after,
[data-sibebarbg='color_13'] .deznav .metismenu ul:after {
  background-color: rgba(255, 255, 255, 0.2);
}

[data-sibebarbg='color_13'][data-theme-version='dark'][data-sidebar-style='mini'][data-layout='vertical']
  .deznav
  .metismenu
  li
  > ul,
[data-sibebarbg='color_13'][data-sidebar-style='mini'][data-layout='vertical']
  .deznav
  .metismenu
  li
  > ul {
  background-color: #444444;
}

[data-sibebarbg='color_13'][data-theme-version='dark'][data-sidebar-style='mini'][data-layout='vertical']
  .deznav
  .metismenu
  > li.mm-active
  > a,
[data-sibebarbg='color_13'][data-theme-version='dark'][data-sidebar-style='mini'][data-layout='vertical']
  .deznav
  .metismenu
  > li:hover
  > a,
[data-sibebarbg='color_13'][data-sidebar-style='mini'][data-layout='vertical']
  .deznav
  .metismenu
  > li.mm-active
  > a,
[data-sibebarbg='color_13'][data-sidebar-style='mini'][data-layout='vertical']
  .deznav
  .metismenu
  > li:hover
  > a {
  background-color: #444444;
}

[data-sibebarbg='color_13'][data-theme-version='dark'][data-sidebar-style='full'][data-layout='vertical']
  .menu-toggle
  .deznav
  .metismenu
  li
  > ul,
[data-sibebarbg='color_13'][data-theme-version='dark'][data-sidebar-style='full'][data-layout='vertical']
  .menu-toggle
  .deznav
  .metismenu
  > li.mm-active
  > a,
[data-sibebarbg='color_13'][data-theme-version='dark'][data-sidebar-style='full'][data-layout='vertical']
  .menu-toggle
  .deznav
  .metismenu
  > li:hover
  > a,
[data-sibebarbg='color_13'][data-sidebar-style='full'][data-layout='vertical']
  .menu-toggle
  .deznav
  .metismenu
  li
  > ul,
[data-sibebarbg='color_13'][data-sidebar-style='full'][data-layout='vertical']
  .menu-toggle
  .deznav
  .metismenu
  > li.mm-active
  > a,
[data-sibebarbg='color_13'][data-sidebar-style='full'][data-layout='vertical']
  .menu-toggle
  .deznav
  .metismenu
  > li:hover
  > a {
  background-color: #444444;
}

[data-sibebarbg='color_13'][data-theme-version='dark'][data-sidebar-style='icon-hover'][data-layout='vertical']
  .deznav
  .metismenu
  ul,
[data-sibebarbg='color_13'][data-sidebar-style='icon-hover'][data-layout='vertical']
  .deznav
  .metismenu
  ul {
  border-color: rgba(255, 255, 255, 0.2);
}

[data-sibebarbg='color_13'][data-theme-version='dark'][data-layout='horizontal']
  .deznav
  .metismenu
  > li,
[data-sibebarbg='color_13'][data-layout='horizontal'] .deznav .metismenu > li {
  border-color: rgba(255, 255, 255, 0.2);
}

[data-sibebarbg='color_14'][data-theme-version='dark'] .deznav,
[data-sibebarbg='color_14'] .deznav {
  background-color: #1367c8;
}

[data-sibebarbg='color_14'][data-theme-version='dark']
  .deznav
  .menu-tabs
  li
  a.active,
[data-sibebarbg='color_14'] .deznav .menu-tabs li a.active {
  color: #1367c8;
}

[data-sibebarbg='color_14'][data-theme-version='dark']
  .deznav
  .metismenu
  .nav-label,
[data-sibebarbg='color_14'] .deznav .metismenu .nav-label {
  color: rgba(255, 255, 255, 0.7);
  border-color: rgba(255, 255, 255, 0.2);
}

[data-sibebarbg='color_14'][data-theme-version='dark']
  .deznav
  .metismenu
  > li
  > a,
[data-sibebarbg='color_14'] .deznav .metismenu > li > a {
  color: rgba(255, 255, 255, 0.7);
}

[data-sibebarbg='color_14'][data-theme-version='dark']
  .deznav
  .metismenu
  > li
  > a
  svg,
[data-sibebarbg='color_14'] .deznav .metismenu > li > a svg {
  color: rgba(255, 255, 255, 0.7);
}

[data-sibebarbg='color_14'][data-theme-version='dark']
  .deznav
  .metismenu
  > li
  > a
  g
  [fill],
[data-sibebarbg='color_14'] .deznav .metismenu > li > a g [fill] {
  fill: rgba(255, 255, 255, 0.7);
}

[data-sibebarbg='color_14'][data-theme-version='dark']
  .deznav
  .metismenu
  > li:hover
  > a,
[data-sibebarbg='color_14'][data-theme-version='dark']
  .deznav
  .metismenu
  > li:focus
  > a,
[data-sibebarbg='color_14'][data-theme-version='dark']
  .deznav
  .metismenu
  > li.mm-active
  > a,
[data-sibebarbg='color_14'] .deznav .metismenu > li:hover > a,
[data-sibebarbg='color_14'] .deznav .metismenu > li:focus > a,
[data-sibebarbg='color_14'] .deznav .metismenu > li.mm-active > a {
  color: #fff;
}

[data-sibebarbg='color_14'][data-theme-version='dark']
  .deznav
  .metismenu
  > li:hover
  > a
  g
  [fill],
[data-sibebarbg='color_14'][data-theme-version='dark']
  .deznav
  .metismenu
  > li:focus
  > a
  g
  [fill],
[data-sibebarbg='color_14'][data-theme-version='dark']
  .deznav
  .metismenu
  > li.mm-active
  > a
  g
  [fill],
[data-sibebarbg='color_14'] .deznav .metismenu > li:hover > a g [fill],
[data-sibebarbg='color_14'] .deznav .metismenu > li:focus > a g [fill],
[data-sibebarbg='color_14'] .deznav .metismenu > li.mm-active > a g [fill] {
  fill: #fff;
}

[data-sibebarbg='color_14'][data-theme-version='dark']
  .deznav
  .metismenu
  > li:hover
  > a
  svg,
[data-sibebarbg='color_14'][data-theme-version='dark']
  .deznav
  .metismenu
  > li:focus
  > a
  svg,
[data-sibebarbg='color_14'][data-theme-version='dark']
  .deznav
  .metismenu
  > li.mm-active
  > a
  svg,
[data-sibebarbg='color_14'] .deznav .metismenu > li:hover > a svg,
[data-sibebarbg='color_14'] .deznav .metismenu > li:focus > a svg,
[data-sibebarbg='color_14'] .deznav .metismenu > li.mm-active > a svg {
  color: #fff;
}

[data-layout='horizontal'][data-sibebarbg='color_14'][data-theme-version='dark']
  .deznav
  .metismenu
  > li,
[data-sibebarbg='color_14'] .deznav .metismenu > li {
  border-color: #2480ea;
}

[data-sibebarbg='color_14'][data-theme-version='dark'] .deznav .metismenu a,
[data-sibebarbg='color_14'] .deznav .metismenu a {
  color: rgba(255, 255, 255, 0.7);
}

[data-sibebarbg='color_14'][data-theme-version='dark']
  .deznav
  .metismenu
  a:hover,
[data-sibebarbg='color_14'][data-theme-version='dark']
  .deznav
  .metismenu
  a:focus,
[data-sibebarbg='color_14'][data-theme-version='dark']
  .deznav
  .metismenu
  a.mm-active,
[data-sibebarbg='color_14'] .deznav .metismenu a:hover,
[data-sibebarbg='color_14'] .deznav .metismenu a:focus,
[data-sibebarbg='color_14'] .deznav .metismenu a.mm-active {
  color: #fff;
}

[data-sibebarbg='color_14'][data-theme-version='dark'] .deznav .metismenu ul,
[data-sibebarbg='color_14'] .deznav .metismenu ul {
  border-color: rgba(255, 255, 255, 0.2);
}

[data-sibebarbg='color_14'][data-theme-version='dark']
  .deznav
  .metismenu
  ul:after,
[data-sibebarbg='color_14'] .deznav .metismenu ul:after {
  background-color: rgba(255, 255, 255, 0.2);
}

[data-sibebarbg='color_14'][data-theme-version='dark'][data-sidebar-style='mini'][data-layout='vertical']
  .deznav
  .metismenu
  li
  > ul,
[data-sibebarbg='color_14'][data-sidebar-style='mini'][data-layout='vertical']
  .deznav
  .metismenu
  li
  > ul {
  background-color: #2480ea;
}

[data-sibebarbg='color_14'][data-theme-version='dark'][data-sidebar-style='mini'][data-layout='vertical']
  .deznav
  .metismenu
  > li.mm-active
  > a,
[data-sibebarbg='color_14'][data-theme-version='dark'][data-sidebar-style='mini'][data-layout='vertical']
  .deznav
  .metismenu
  > li:hover
  > a,
[data-sibebarbg='color_14'][data-sidebar-style='mini'][data-layout='vertical']
  .deznav
  .metismenu
  > li.mm-active
  > a,
[data-sibebarbg='color_14'][data-sidebar-style='mini'][data-layout='vertical']
  .deznav
  .metismenu
  > li:hover
  > a {
  background-color: #2480ea;
}

[data-sibebarbg='color_14'][data-theme-version='dark'][data-sidebar-style='full'][data-layout='vertical']
  .menu-toggle
  .deznav
  .metismenu
  li
  > ul,
[data-sibebarbg='color_14'][data-theme-version='dark'][data-sidebar-style='full'][data-layout='vertical']
  .menu-toggle
  .deznav
  .metismenu
  > li.mm-active
  > a,
[data-sibebarbg='color_14'][data-theme-version='dark'][data-sidebar-style='full'][data-layout='vertical']
  .menu-toggle
  .deznav
  .metismenu
  > li:hover
  > a,
[data-sibebarbg='color_14'][data-sidebar-style='full'][data-layout='vertical']
  .menu-toggle
  .deznav
  .metismenu
  li
  > ul,
[data-sibebarbg='color_14'][data-sidebar-style='full'][data-layout='vertical']
  .menu-toggle
  .deznav
  .metismenu
  > li.mm-active
  > a,
[data-sibebarbg='color_14'][data-sidebar-style='full'][data-layout='vertical']
  .menu-toggle
  .deznav
  .metismenu
  > li:hover
  > a {
  background-color: #2480ea;
}

[data-sibebarbg='color_14'][data-theme-version='dark'][data-sidebar-style='icon-hover'][data-layout='vertical']
  .deznav
  .metismenu
  ul,
[data-sibebarbg='color_14'][data-sidebar-style='icon-hover'][data-layout='vertical']
  .deznav
  .metismenu
  ul {
  border-color: rgba(255, 255, 255, 0.2);
}

[data-sibebarbg='color_14'][data-theme-version='dark'][data-layout='horizontal']
  .deznav
  .metismenu
  > li,
[data-sibebarbg='color_14'][data-layout='horizontal'] .deznav .metismenu > li {
  border-color: rgba(255, 255, 255, 0.2);
}

[data-sibebarbg='color_15'][data-theme-version='dark'] .deznav,
[data-sibebarbg='color_15'] .deznav {
  background-color: #ed0b4c;
}

[data-sibebarbg='color_15'][data-theme-version='dark']
  .deznav
  .menu-tabs
  li
  a.active,
[data-sibebarbg='color_15'] .deznav .menu-tabs li a.active {
  color: #ed0b4c;
}

[data-sibebarbg='color_15'][data-theme-version='dark']
  .deznav
  .metismenu
  .nav-label,
[data-sibebarbg='color_15'] .deznav .metismenu .nav-label {
  color: rgba(255, 255, 255, 0.7);
  border-color: rgba(255, 255, 255, 0.2);
}

[data-sibebarbg='color_15'][data-theme-version='dark']
  .deznav
  .metismenu
  > li
  > a,
[data-sibebarbg='color_15'] .deznav .metismenu > li > a {
  color: rgba(255, 255, 255, 0.7);
}

[data-sibebarbg='color_15'][data-theme-version='dark']
  .deznav
  .metismenu
  > li
  > a
  svg,
[data-sibebarbg='color_15'] .deznav .metismenu > li > a svg {
  color: rgba(255, 255, 255, 0.7);
}

[data-sibebarbg='color_15'][data-theme-version='dark']
  .deznav
  .metismenu
  > li
  > a
  g
  [fill],
[data-sibebarbg='color_15'] .deznav .metismenu > li > a g [fill] {
  fill: rgba(255, 255, 255, 0.7);
}

[data-sibebarbg='color_15'][data-theme-version='dark']
  .deznav
  .metismenu
  > li:hover
  > a,
[data-sibebarbg='color_15'][data-theme-version='dark']
  .deznav
  .metismenu
  > li:focus
  > a,
[data-sibebarbg='color_15'][data-theme-version='dark']
  .deznav
  .metismenu
  > li.mm-active
  > a,
[data-sibebarbg='color_15'] .deznav .metismenu > li:hover > a,
[data-sibebarbg='color_15'] .deznav .metismenu > li:focus > a,
[data-sibebarbg='color_15'] .deznav .metismenu > li.mm-active > a {
  color: #fff;
}

[data-sibebarbg='color_15'][data-theme-version='dark']
  .deznav
  .metismenu
  > li:hover
  > a
  g
  [fill],
[data-sibebarbg='color_15'][data-theme-version='dark']
  .deznav
  .metismenu
  > li:focus
  > a
  g
  [fill],
[data-sibebarbg='color_15'][data-theme-version='dark']
  .deznav
  .metismenu
  > li.mm-active
  > a
  g
  [fill],
[data-sibebarbg='color_15'] .deznav .metismenu > li:hover > a g [fill],
[data-sibebarbg='color_15'] .deznav .metismenu > li:focus > a g [fill],
[data-sibebarbg='color_15'] .deznav .metismenu > li.mm-active > a g [fill] {
  fill: #fff;
}

[data-sibebarbg='color_15'][data-theme-version='dark']
  .deznav
  .metismenu
  > li:hover
  > a
  svg,
[data-sibebarbg='color_15'][data-theme-version='dark']
  .deznav
  .metismenu
  > li:focus
  > a
  svg,
[data-sibebarbg='color_15'][data-theme-version='dark']
  .deznav
  .metismenu
  > li.mm-active
  > a
  svg,
[data-sibebarbg='color_15'] .deznav .metismenu > li:hover > a svg,
[data-sibebarbg='color_15'] .deznav .metismenu > li:focus > a svg,
[data-sibebarbg='color_15'] .deznav .metismenu > li.mm-active > a svg {
  color: #fff;
}

[data-layout='horizontal'][data-sibebarbg='color_15'][data-theme-version='dark']
  .deznav
  .metismenu
  > li,
[data-sibebarbg='color_15'] .deznav .metismenu > li {
  border-color: #f6356d;
}

[data-sibebarbg='color_15'][data-theme-version='dark'] .deznav .metismenu a,
[data-sibebarbg='color_15'] .deznav .metismenu a {
  color: rgba(255, 255, 255, 0.7);
}

[data-sibebarbg='color_15'][data-theme-version='dark']
  .deznav
  .metismenu
  a:hover,
[data-sibebarbg='color_15'][data-theme-version='dark']
  .deznav
  .metismenu
  a:focus,
[data-sibebarbg='color_15'][data-theme-version='dark']
  .deznav
  .metismenu
  a.mm-active,
[data-sibebarbg='color_15'] .deznav .metismenu a:hover,
[data-sibebarbg='color_15'] .deznav .metismenu a:focus,
[data-sibebarbg='color_15'] .deznav .metismenu a.mm-active {
  color: #fff;
}

[data-sibebarbg='color_15'][data-theme-version='dark'] .deznav .metismenu ul,
[data-sibebarbg='color_15'] .deznav .metismenu ul {
  border-color: rgba(255, 255, 255, 0.2);
}

[data-sibebarbg='color_15'][data-theme-version='dark']
  .deznav
  .metismenu
  ul:after,
[data-sibebarbg='color_15'] .deznav .metismenu ul:after {
  background-color: rgba(255, 255, 255, 0.2);
}

[data-sibebarbg='color_15'][data-theme-version='dark'][data-sidebar-style='mini'][data-layout='vertical']
  .deznav
  .metismenu
  li
  > ul,
[data-sibebarbg='color_15'][data-sidebar-style='mini'][data-layout='vertical']
  .deznav
  .metismenu
  li
  > ul {
  background-color: #f6356d;
}

[data-sibebarbg='color_15'][data-theme-version='dark'][data-sidebar-style='mini'][data-layout='vertical']
  .deznav
  .metismenu
  > li.mm-active
  > a,
[data-sibebarbg='color_15'][data-theme-version='dark'][data-sidebar-style='mini'][data-layout='vertical']
  .deznav
  .metismenu
  > li:hover
  > a,
[data-sibebarbg='color_15'][data-sidebar-style='mini'][data-layout='vertical']
  .deznav
  .metismenu
  > li.mm-active
  > a,
[data-sibebarbg='color_15'][data-sidebar-style='mini'][data-layout='vertical']
  .deznav
  .metismenu
  > li:hover
  > a {
  background-color: #f6356d;
}

[data-sibebarbg='color_15'][data-theme-version='dark'][data-sidebar-style='full'][data-layout='vertical']
  .menu-toggle
  .deznav
  .metismenu
  li
  > ul,
[data-sibebarbg='color_15'][data-theme-version='dark'][data-sidebar-style='full'][data-layout='vertical']
  .menu-toggle
  .deznav
  .metismenu
  > li.mm-active
  > a,
[data-sibebarbg='color_15'][data-theme-version='dark'][data-sidebar-style='full'][data-layout='vertical']
  .menu-toggle
  .deznav
  .metismenu
  > li:hover
  > a,
[data-sibebarbg='color_15'][data-sidebar-style='full'][data-layout='vertical']
  .menu-toggle
  .deznav
  .metismenu
  li
  > ul,
[data-sibebarbg='color_15'][data-sidebar-style='full'][data-layout='vertical']
  .menu-toggle
  .deznav
  .metismenu
  > li.mm-active
  > a,
[data-sibebarbg='color_15'][data-sidebar-style='full'][data-layout='vertical']
  .menu-toggle
  .deznav
  .metismenu
  > li:hover
  > a {
  background-color: #f6356d;
}

[data-sibebarbg='color_15'][data-theme-version='dark'][data-sidebar-style='icon-hover'][data-layout='vertical']
  .deznav
  .metismenu
  ul,
[data-sibebarbg='color_15'][data-sidebar-style='icon-hover'][data-layout='vertical']
  .deznav
  .metismenu
  ul {
  border-color: rgba(255, 255, 255, 0.2);
}

[data-sibebarbg='color_15'][data-theme-version='dark'][data-layout='horizontal']
  .deznav
  .metismenu
  > li,
[data-sibebarbg='color_15'][data-layout='horizontal'] .deznav .metismenu > li {
  border-color: rgba(255, 255, 255, 0.2);
}

[data-headerbg='transparent'] .header {
  box-shadow: none;
  background: transparent;
}

[data-headerbg='color_1'] .nav-header .hamburger.is-active .line,
[data-headerbg='color_1'] .nav-header .hamburger .line {
  background: #0b2a97 !important;
}

@media (min-width: 992px) {
  [data-container='boxed'] #main-wrapper {
    max-width: 1199px;
    margin: 0 auto;
  }
  [direction='rtl'][data-container='boxed'] #main-wrapper {
    text-align: right;
  }
}

@media only screen and (min-width: 1350px) {
  [data-layout='vertical'][data-sidebar-style='overlay'][data-container='boxed'][data-header-position='fixed']
    .header {
    width: 1199px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1349px) {
  [data-layout='vertical'][data-sidebar-style='overlay'][data-container='boxed'][data-header-position='fixed']
    .header {
    width: 1199px;
  }
}

[data-header-position='fixed'][data-layout='horizontal'][data-container='boxed']
  .deznav {
  max-width: 1199px;
}

[data-layout='horizontal'][data-container='boxed'][data-header-position='fixed']
  .header {
  width: 1199px;
}

[data-layout='horizontal'][data-container='boxed'][data-header-position='fixed'][data-sidebar-style='mini']
  .header {
  width: 1199px;
}

[data-container='boxed'] .metismenu.fixed {
  left: auto;
  max-width: 1199px;
}

[data-container='boxed'] .page-titles {
  margin-bottom: 3rem;
  padding: 15px 15px;
}

[data-container='boxed'] .content-body .container-fluid,
[data-container='boxed'] .content-body .container-sm,
[data-container='boxed'] .content-body .container-md,
[data-container='boxed'] .content-body .container-lg,
[data-container='boxed'] .content-body .container-xl {
  padding: 0.9375rem 0.9375rem 0 0.9375rem;
}

[data-container='boxed'][data-layout='vertical'] .page-titles {
  margin-left: 0;
  margin-right: 0;
}

[data-layout='vertical'][data-container='boxed'][data-sidebar-position='fixed'][data-header-position='static'][data-sidebar-style='overlay']
  .nav-header {
  position: absolute;
}

[data-layout='vertical'][data-container='boxed'][data-sidebar-position='fixed'][data-header-position='static'][data-sidebar-style='overlay']
  .menu-toggle
  .deznav {
  position: absolute;
}

[data-container='boxed'][data-sidebar-position='fixed'][data-layout='horizontal']
  .deznav.fixed {
  left: auto;
  max-width: 1199px;
}

@media (min-width: 992px) {
  [data-container='wide-boxed'] #main-wrapper {
    max-width: 1480px;
    margin: 0 auto;
  }
}

@media (min-width: 992px) {
  [data-container='wide-boxed'][data-sidebar-style='full'] .header {
    width: 100%;
  }
  [data-container='wide-boxed'][data-sidebar-style='full']
    .menu-toggle
    .header {
    width: 100%;
  }
  [data-header-position='fixed'][data-container='wide-boxed'][data-sidebar-style='full']
    .header {
    max-width: 1480px;
  }
  [data-header-position='fixed'][data-container='wide-boxed'][data-sidebar-style='full']
    .menu-toggle
    .header {
    max-width: 1480px;
  }
}

@media (min-width: 992px) {
  [data-container='wide-boxed'][data-sidebar-style='mini'] .header {
    width: 100%;
  }
  [data-header-position='fixed'][data-container='wide-boxed'][data-sidebar-style='mini']
    .header {
    max-width: 1480px;
  }
  [data-header-position='fixed'][data-layout='horizontal'][data-container='wide-boxed'][data-sidebar-style='mini']
    .header {
    max-width: 1480px;
  }
}

@media (min-width: 992px) {
  [data-container='wide-boxed'][data-sidebar-style='compact'] .header {
    width: 100%;
  }
  [data-header-position='fixed'][data-container='wide-boxed'][data-sidebar-style='compact']
    .header {
    max-width: 1480px;
  }
}

@media (min-width: 992px) {
  [data-container='wide-boxed'][data-sidebar-style='overlay'] .header {
    width: 100%;
  }
  [data-header-position='fixed'][data-container='wide-boxed'][data-sidebar-style='overlay']
    .header {
    max-width: 1480px;
  }
}

[data-layout='horizontal'][data-container='wide-boxed'] .header {
  width: 100%;
}

[data-sidebar-style='full'][data-header-position='fixed'][data-layout='horizontal'][data-container='wide-boxed']
  .header {
  max-width: 1480px;
}

[data-sidebar-style='mini'][data-layout='horizontal'][data-container='wide-boxed']
  .header {
  width: 100%;
}

[data-layout='horizontal'][data-container='wide-boxed'][data-sidebar-position='fixed']
  .deznav.fixed {
  max-width: 1480px;
  transition: none;
}

[data-layout='horizontal'][data-container='wide-boxed'][data-sidebar-position='fixed'][direction='rtl']
  .deznav.fixed {
  right: 5%;
}

[data-layout='horizontal'][data-container='wide-boxed'][data-sidebar-position='fixed'][data-header-position='fixed']
  .deznav {
  max-width: 1480px;
  left: 5%;
}

[data-layout='horizontal'][data-container='wide-boxed'][data-sidebar-position='fixed'][data-header-position='fixed'][direction='rtl']
  .deznav {
  right: 5%;
}

[data-primary='color_2'] a:hover,
[data-primary='color_2'] a:focus,
[data-primary='color_2'] a.active {
  color: #143b64;
}

[data-primary='color_2'] [data-class='bg-primary']:before {
  background: #143b64;
}

[data-primary='color_2'] .email-left-box .intro-title {
  background: rgba(20, 59, 100, 0.1);
}

[data-primary='color_2'] .email-left-box .intro-title i {
  color: #143b64;
}

[data-primary='color_2'] .widget-stat .media .media-body h4 {
  color: #143b64 !important;
}

[data-primary='color_2'] .email-right-box .right-box-border {
  border-right: 2px solid rgba(20, 59, 100, 0.1);
}

[data-primary='color_2'] .mail-list .list-group-item.active i {
  color: #143b64;
}

[data-primary='color_2'] .single-mail.active {
  background: #143b64;
}

[data-primary='color_2'] .profile-info h4.text-primary {
  color: #143b64 !important;
}

[data-primary='color_2'] .profile-tab .nav-item .nav-link:hover,
[data-primary='color_2'] .profile-tab .nav-item .nav-link.active {
  border-bottom: 0.2px solid #143b64;
  color: #143b64;
}

[data-primary='color_2'] .amChartsInputField {
  border: 0;
  background: #143b64;
}

[data-primary='color_2'] .amcharts-period-input,
[data-primary='color_2'] .amcharts-period-input-selected {
  background: #143b64;
}

[data-primary='color_2'] .morris-hover {
  background: #143b64;
}

[data-primary='color_2']
  .select2-container--default
  .select2-results__option--highlighted[aria-selected] {
  background-color: #143b64;
}

[data-primary='color_2'] .custom-select:focus {
  border-color: #143b64;
  color: #143b64;
}

[data-primary='color_2'] .daterangepicker td.active {
  background-color: #143b64;
}

[data-primary='color_2'] .daterangepicker td.active:hover {
  background-color: #143b64;
}

[data-primary='color_2'] .daterangepicker button.applyBtn {
  background-color: #143b64;
  border-color: #143b64;
}

[data-primary='color_2'] .wizard > .steps li.current a {
  background-color: #143b64;
}

[data-primary='color_2'] .wizard .skip-email a {
  color: #143b64;
}

[data-primary='color_2'] .wizard > .actions li:not(.disabled) a {
  background-color: #143b64;
}

[data-primary='color_2']
  .step-form-horizontal
  .wizard
  .steps
  li.done
  a
  .number {
  background: #143b64;
}

[data-primary='color_2']
  .step-form-horizontal
  .wizard
  .steps
  li.current
  a
  .number {
  color: #143b64;
  border-color: #143b64;
}

[data-primary='color_2']
  .step-form-horizontal
  .wizard
  .steps
  li.disabled
  a
  .number {
  color: #143b64;
}

[data-primary='color_2']
  .step-form-horizontal
  .wizard
  .steps
  li:not(:last-child)::after {
  background-color: #143b64;
}

[data-primary='color_2'] .is-invalid .input-group-prepend .input-group-text i {
  color: #2161a4;
}

[data-primary='color_2'] .datamaps-hoverover {
  color: #143b64;
  border: 1px solid rgba(20, 59, 100, 0.3);
}

[data-primary='color_2'] .jqvmap-zoomin,
[data-primary='color_2'] .jqvmap-zoomout {
  background-color: #143b64;
}

[data-primary='color_2'] .table .thead-primary th {
  background-color: #143b64;
}

[data-primary='color_2'] .table.primary-table-bg-hover thead th {
  background-color: #102e4f;
}

[data-primary='color_2'] .table.primary-table-bg-hover tbody tr {
  background-color: #143b64;
}

[data-primary='color_2'] .table.primary-table-bg-hover tbody tr:hover {
  background-color: #184879;
}

[data-primary='color_2']
  .table.primary-table-bg-hover
  tbody
  tr:not(:last-child)
  td,
[data-primary='color_2']
  .table.primary-table-bg-hover
  tbody
  tr:not(:last-child)
  th {
  border-bottom: 1px solid #102e4f;
}

[data-primary='color_2'] table.dataTable tr.selected {
  color: #143b64;
}

[data-primary='color_2']
  .dataTables_wrapper
  .dataTables_paginate
  .paginate_button.current {
  color: #143b64 !important;
  background: rgba(20, 59, 100, 0.1);
}

[data-primary='color_2']
  .dataTables_wrapper
  .dataTables_paginate
  .paginate_button:hover {
  color: #143b64 !important;
  background: rgba(20, 59, 100, 0.1);
}

[data-primary='color_2'] .clipboard-btn:hover {
  background-color: #143b64;
}

[data-primary='color_2'] .cd-h-timeline__dates::before {
  background: #143b64;
}

[data-primary='color_2'] .cd-h-timeline__dates::after {
  background: #143b64;
}

[data-primary='color_2'] .cd-h-timeline__line {
  background-color: #143b64;
}

[data-primary='color_2'] .cd-h-timeline__date:after {
  border-color: #153d68;
  background-color: #143b64;
}

[data-primary='color_2'] .cd-h-timeline__navigation {
  border-color: #153d68;
}

[data-primary='color_2'] .cd-h-timeline__navigation--inactive:hover {
  border-color: #153d68;
}

[data-primary='color_2'] .dd-handle {
  background: #143b64;
}

[data-primary='color_2'] .dd-handle:hover {
  background: #143b64;
}

[data-primary='color_2'] .dd3-content:hover {
  background: #143b64;
}

[data-primary='color_2'] .noUi-connect {
  background-color: #143b64;
}

[data-primary='color_2'] .noUi-connect.c-3-color {
  background-color: #143b64;
}

[data-primary='color_2'] .noUi-horizontal .noUi-handle,
[data-primary='color_2'] .noUi-vertical .noUi-handle {
  background-color: #143b64;
}

[data-primary='color_2'] #slider-toggle.off .noUi-handle {
  border-color: #143b64;
}

[data-primary='color_2'] .pignose-calendar {
  border-color: #143b64;
}

[data-primary='color_2'] .pignose-calendar .pignose-calendar-top-date {
  background-color: #143b64;
}

[data-primary='color_2']
  .pignose-calendar.pignose-calendar-blue
  .pignose-calendar-body
  .pignose-calendar-row
  .pignose-calendar-unit.pignose-calendar-unit-active
  a {
  background-color: #143b64;
}

[data-primary='color_2'] .bootstrap-tagsinput .tag {
  background-color: #143b64;
}

[data-primary='color_2'] .toast-success {
  background-color: #143b64;
}

[data-primary='color_2'] .twitter-typeahead .tt-menu .tt-suggestion:hover {
  background-color: #143b64;
}

[data-primary='color_2'] .accordion-header-bg .accordion__header--primary {
  background-color: #143b64;
}

[data-primary='color_2'] .alert-primary {
  background: #297ace;
  border-color: #297ace;
  color: #143b64;
}

[data-primary='color_2'] .alert-alt.alert-primary {
  border-left: 4px solid #143b64;
}

[data-primary='color_2'] .alert-alt.alert-primary.solid {
  border-left: 4px solid black !important;
}

[data-primary='color_2'] .alert.alert-primary.solid {
  background: #143b64;
  border-color: #143b64;
}

[data-primary='color_2'] .alert.alert-outline-primary {
  color: #143b64;
  border-color: #143b64;
}

[data-primary='color_2'] .badge-outline-primary {
  border: 1px solid #143b64;
  color: #143b64;
}

[data-primary='color_2'] .badge-primary {
  background-color: #143b64;
}

[data-primary='color_2'] .page-titles h4 {
  color: #143b64;
}

[data-primary='color_2'] .card-action > a {
  background: black;
}

[data-primary='color_2'] .card-action .dropdown {
  background: black;
  color: #143b64;
}

[data-primary='color_2'] .card-action .dropdown:hover,
[data-primary='color_2'] .card-action .dropdown:focus {
  background: black;
}

[data-primary='color_2'] .card-loader i {
  background: #0e2946;
}

[data-primary='color_2'] .dropdown-outline {
  border: 0.1rem solid #143b64;
}

[data-primary='color_2'] .custom-dropdown .dropdown-menu .dropdown-item:hover {
  color: #143b64;
}

[data-primary='color_2'] .card-action .custom-dropdown {
  background: #3a87d8;
}

[data-primary='color_2'] .card-action .custom-dropdown.show,
[data-primary='color_2'] .card-action .custom-dropdown:focus,
[data-primary='color_2'] .card-action .custom-dropdown:hover {
  background: #143b64;
}

[data-primary='color_2'] .label-primary {
  background: #143b64;
}

[data-primary='color_2'] .pagination .page-item .page-link:hover {
  background: #143b64;
  border-color: #143b64;
}

[data-primary='color_2'] .pagination .page-item.active .page-link {
  background-color: #143b64;
  border-color: #143b64;
}

[data-primary='color_2']
  .bootstrap-popover-wrapper
  .bootstrap-popover
  button:hover,
[data-primary='color_2']
  .bootstrap-popover-wrapper
  .bootstrap-popover
  button:focus {
  background: #143b64;
}

[data-primary='color_2'] .progress-bar {
  background-color: #143b64;
}

[data-primary='color_2'] .progress-bar-primary {
  background-color: #143b64;
}

[data-primary='color_2'] .ribbon__four {
  background-color: #143b64;
}

[data-primary='color_2'] .ribbon__four:after,
[data-primary='color_2'] .ribbon__four:before {
  background-color: #256db9;
}

[data-primary='color_2'] .ribbon__five {
  background-color: #143b64;
}

[data-primary='color_2'] .ribbon__five::before {
  border-color: transparent transparent #143b64 transparent;
}

[data-primary='color_2'] .ribbon__six {
  background-color: #143b64;
}

[data-primary='color_2'] .multi-steps > li {
  color: #143b64;
}

[data-primary='color_2'] .multi-steps > li:after {
  background-color: #143b64;
}

[data-primary='color_2'] .multi-steps > li.is-active:before {
  border-color: #143b64;
}

[data-primary='color_2'] .timeline-badge.primary {
  background-color: #143b64 !important;
}

[data-primary='color_2'] .tooltip-wrapper button:hover {
  background: #143b64;
}

[data-primary='color_2'] .chart_widget_tab_one .nav-link.active {
  background-color: #143b64;
  border: 1px solid #143b64;
}

[data-primary='color_2'] .chart_widget_tab_one .nav-link.active:hover {
  border: 1px solid #143b64;
}

[data-primary='color_2'] .social-icon2 a {
  border: 0.1rem solid #143b64;
}

[data-primary='color_2'] .social-icon2 i {
  color: #143b64;
}

[data-primary='color_2'] .social-icon3 ul li a:hover i {
  color: #143b64;
}

[data-primary='color_2'] .bgl-primary {
  background: #3a87d8;
  border-color: #3a87d8;
  color: #143b64;
}

[data-primary='color_2'] .tdl-holder input[type='checkbox']:checked + i {
  background: #143b64;
}

[data-primary='color_2'] .footer .copyright a {
  color: #143b64;
}

[data-primary='color_2'] .hamburger .line {
  background: #143b64;
}

[data-primary='color_2'] svg.pulse-svg .first-circle,
[data-primary='color_2'] svg.pulse-svg .second-circle,
[data-primary='color_2'] svg.pulse-svg .third-circle {
  fill: #143b64;
}

[data-primary='color_2'] .pulse-css {
  background: #143b64;
}

[data-primary='color_2'] .pulse-css:after,
[data-primary='color_2'] .pulse-css:before {
  background-color: #143b64;
}

[data-primary='color_2']
  .notification_dropdown
  .dropdown-menu-right
  .notification_title {
  background: #143b64;
}

[data-primary='color_2'] .header-right .header-profile .dropdown-menu a:hover,
[data-primary='color_2'] .header-right .header-profile .dropdown-menu a:focus,
[data-primary='color_2'] .header-right .header-profile .dropdown-menu a.active {
  color: #143b64;
}

[data-primary='color_2'] .header-right .header-profile .profile_title {
  background: #143b64;
}

[data-primary='color_2'][data-sidebar-style='full'][data-layout='vertical']
  .menu-toggle
  .nav-header
  .nav-control
  .hamburger
  .line {
  background-color: #143b64 !important;
}

[data-primary='color_2'] .deznav .metismenu > li > a svg {
  color: #143b64;
}

[data-primary='color_2'] .deznav .metismenu > li:hover > a,
[data-primary='color_2'] .deznav .metismenu > li:focus > a {
  color: #143b64;
}

[data-primary='color_2'] .deznav .metismenu > li.mm-active > a {
  color: #143b64;
}

[data-primary='color_2'] .deznav .metismenu ul a:hover,
[data-primary='color_2'] .deznav .metismenu ul a:focus,
[data-primary='color_2'] .deznav .metismenu ul a.mm-active {
  color: #143b64;
}

@media (min-width: 767px) {
  [data-primary='color_2']
    [data-sidebar-style='modern']
    .deznav
    .metismenu
    > li
    > a:hover
    > a,
  [data-primary='color_2']
    [data-sidebar-style='modern']
    .deznav
    .metismenu
    > li
    > a:focus
    > a,
  [data-primary='color_2']
    [data-sidebar-style='modern']
    .deznav
    .metismenu
    > li
    > a:active
    > a,
  [data-primary='color_2']
    [data-sidebar-style='modern']
    .deznav
    .metismenu
    > li
    > a.mm-active
    > a {
    background-color: #8fbae9;
  }
}

[data-primary='color_2'][data-sidebar-style='overlay']
  .nav-header
  .hamburger.is-active
  .line {
  background-color: #143b64;
}

[data-primary='color_2'] .nav-user {
  background: #143b64;
}

[data-primary='color_2'] .sidebar-right .sidebar-right .sidebar-right-trigger {
  color: #143b64;
}

[data-primary='color_2']
  .sidebar-right
  .sidebar-right
  .sidebar-right-trigger:hover {
  color: #143b64;
}

[data-primary='color_2'][data-theme-version='dark']
  .pagination
  .page-item
  .page-link:hover {
  background: #143b64;
  border-color: #143b64;
}

[data-primary='color_2'][data-theme-version='dark']
  .pagination
  .page-item.active
  .page-link {
  background: #143b64;
  border-color: #143b64;
}

[data-primary='color_2'][data-theme-version='dark'] .header-left input:focus {
  border-color: #143b64;
}

[data-primary='color_2'][data-theme-version='dark'] .loader__bar {
  background: #143b64;
}

[data-primary='color_2'][data-theme-version='dark'] .loader__ball {
  background: #143b64;
}

[data-primary='color_2'][data-theme-version='transparent']
  .header-left
  input:focus {
  border-color: #143b64;
}

[data-primary='color_2'] .new-arrival-content .price {
  color: #143b64;
}

[data-primary='color_2'] .chart-link a i.text-primary {
  color: #143b64;
}

[data-primary='color_2'] #user-activity .nav-tabs .nav-link.active {
  background: #143b64;
  border-color: #143b64;
}

[data-primary='color_2'] span#counter {
  color: #143b64;
}

[data-primary='color_2'] .welcome-content:after {
  background: #143b64;
}

[data-primary='color_2'] .timeline-badge {
  background-color: #143b64;
}

[data-primary='color_2']
  .page-timeline
  .timeline-workplan[data-primary='color_2']
  .page-timeline
  .timeline
  .timeline-badge:after {
  background-color: rgba(20, 59, 100, 0.4);
}

[data-primary='color_2'] .sk-three-bounce .sk-child {
  background-color: #143b64;
}

[data-primary='color_2'] .dropdown-item.active,
[data-primary='color_2'] .dropdown-item:active {
  color: #fff;
  background-color: #143b64;
}

[data-primary='color_2'] .overlay-box:after {
  background: #143b64;
}

[data-primary='color_2'] .btn-primary {
  background-color: #143b64;
  border-color: #143b64;
}

[data-primary='color_2'] .bg-primary {
  background-color: #143b64 !important;
}

[data-primary='color_2'] .text-primary {
  color: #143b64 !important;
}

[data-primary='color_2'] .btn-primary:hover {
  background-color: #071524;
  border-color: #071524;
}

[data-primary='color_2'] .btn-outline-primary {
  color: #143b64;
  border-color: #143b64;
}

[data-primary='color_2'] .btn-outline-primary:hover {
  background-color: #143b64;
  border-color: #143b64;
}

[data-primary='color_2'] .btn-primary:not(:disabled):not(.disabled):active,
[data-primary='color_2'] .btn-primary:not(:disabled):not(.disabled).active,
[data-primary='color_2'] .show > .btn-primary.dropdown-toggle,
[data-primary='color_2']
  .btn-outline-primary:not(:disabled):not(.disabled):active,
[data-primary='color_2']
  .btn-outline-primary:not(:disabled):not(.disabled).active,
[data-primary='color_2'] .show > .btn-outline-primary.dropdown-toggle {
  background-color: #071524;
  border-color: #071524;
}

[data-primary='color_3'] a:hover,
[data-primary='color_3'] a:focus,
[data-primary='color_3'] a.active {
  color: #0b2a97;
}

[data-primary='color_3'] [data-class='bg-primary']:before {
  background: #0b2a97;
}

[data-primary='color_3'] .email-left-box .intro-title {
  background: rgba(11, 42, 151, 0.1);
}

[data-primary='color_3'] .email-left-box .intro-title i {
  color: #0b2a97;
}

[data-primary='color_3'] .widget-stat .media .media-body h4 {
  color: #0b2a97 !important;
}

[data-primary='color_3'] .email-right-box .right-box-border {
  border-right: 2px solid rgba(11, 42, 151, 0.1);
}

[data-primary='color_3'] .mail-list .list-group-item.active i {
  color: #0b2a97;
}

[data-primary='color_3'] .single-mail.active {
  background: #0b2a97;
}

[data-primary='color_3'] .profile-info h4.text-primary {
  color: #0b2a97 !important;
}

[data-primary='color_3'] .profile-tab .nav-item .nav-link:hover,
[data-primary='color_3'] .profile-tab .nav-item .nav-link.active {
  border-bottom: 0.2px solid #0b2a97;
  color: #0b2a97;
}

[data-primary='color_3'] .amChartsInputField {
  border: 0;
  background: #0b2a97;
}

[data-primary='color_3'] .amcharts-period-input,
[data-primary='color_3'] .amcharts-period-input-selected {
  background: #0b2a97;
}

[data-primary='color_3'] .morris-hover {
  background: #0b2a97;
}

[data-primary='color_3']
  .select2-container--default
  .select2-results__option--highlighted[aria-selected] {
  background-color: #0b2a97;
}

[data-primary='color_3'] .custom-select:focus {
  border-color: #0b2a97;
  color: #0b2a97;
}

[data-primary='color_3'] .daterangepicker td.active {
  background-color: #0b2a97;
}

[data-primary='color_3'] .daterangepicker td.active:hover {
  background-color: #0b2a97;
}

[data-primary='color_3'] .daterangepicker button.applyBtn {
  background-color: #0b2a97;
  border-color: #0b2a97;
}

[data-primary='color_3'] .wizard > .steps li.current a {
  background-color: #0b2a97;
}

[data-primary='color_3'] .wizard .skip-email a {
  color: #0b2a97;
}

[data-primary='color_3'] .wizard > .actions li:not(.disabled) a {
  background-color: #0b2a97;
}

[data-primary='color_3']
  .step-form-horizontal
  .wizard
  .steps
  li.done
  a
  .number {
  background: #0b2a97;
}

[data-primary='color_3']
  .step-form-horizontal
  .wizard
  .steps
  li.current
  a
  .number {
  color: #0b2a97;
  border-color: #0b2a97;
}

[data-primary='color_3']
  .step-form-horizontal
  .wizard
  .steps
  li.disabled
  a
  .number {
  color: #0b2a97;
}

[data-primary='color_3']
  .step-form-horizontal
  .wizard
  .steps
  li:not(:last-child)::after {
  background-color: #0b2a97;
}

[data-primary='color_3'] .is-invalid .input-group-prepend .input-group-text i {
  color: #103ede;
}

[data-primary='color_3'] .datamaps-hoverover {
  color: #0b2a97;
  border: 1px solid rgba(11, 42, 151, 0.3);
}

[data-primary='color_3'] .jqvmap-zoomin,
[data-primary='color_3'] .jqvmap-zoomout {
  background-color: #0b2a97;
}

[data-primary='color_3'] .table .thead-primary th {
  background-color: #0b2a97;
}

[data-primary='color_3'] .table.primary-table-bg-hover thead th {
  background-color: #09237f;
}

[data-primary='color_3'] .table.primary-table-bg-hover tbody tr {
  background-color: #0b2a97;
}

[data-primary='color_3'] .table.primary-table-bg-hover tbody tr:hover {
  background-color: #0d31af;
}

[data-primary='color_3']
  .table.primary-table-bg-hover
  tbody
  tr:not(:last-child)
  td,
[data-primary='color_3']
  .table.primary-table-bg-hover
  tbody
  tr:not(:last-child)
  th {
  border-bottom: 1px solid #09237f;
}

[data-primary='color_3'] table.dataTable tr.selected {
  color: #0b2a97;
}

[data-primary='color_3']
  .dataTables_wrapper
  .dataTables_paginate
  .paginate_button.current {
  color: #0b2a97 !important;
  background: rgba(11, 42, 151, 0.1);
}

[data-primary='color_3']
  .dataTables_wrapper
  .dataTables_paginate
  .paginate_button:hover {
  color: #0b2a97 !important;
  background: rgba(11, 42, 151, 0.1);
}

[data-primary='color_3'] .clipboard-btn:hover {
  background-color: #0b2a97;
}

[data-primary='color_3'] .cd-h-timeline__dates::before {
  background: #0b2a97;
}

[data-primary='color_3'] .cd-h-timeline__dates::after {
  background: #0b2a97;
}

[data-primary='color_3'] .cd-h-timeline__line {
  background-color: #0b2a97;
}

[data-primary='color_3'] .cd-h-timeline__date:after {
  border-color: #0b2b9b;
  background-color: #0b2a97;
}

[data-primary='color_3'] .cd-h-timeline__navigation {
  border-color: #0b2b9b;
}

[data-primary='color_3'] .cd-h-timeline__navigation--inactive:hover {
  border-color: #0b2b9b;
}

[data-primary='color_3'] .dd-handle {
  background: #0b2a97;
}

[data-primary='color_3'] .dd-handle:hover {
  background: #0b2a97;
}

[data-primary='color_3'] .dd3-content:hover {
  background: #0b2a97;
}

[data-primary='color_3'] .noUi-connect {
  background-color: #0b2a97;
}

[data-primary='color_3'] .noUi-connect.c-3-color {
  background-color: #0b2a97;
}

[data-primary='color_3'] .noUi-horizontal .noUi-handle,
[data-primary='color_3'] .noUi-vertical .noUi-handle {
  background-color: #0b2a97;
}

[data-primary='color_3'] #slider-toggle.off .noUi-handle {
  border-color: #0b2a97;
}

[data-primary='color_3'] .pignose-calendar {
  border-color: #0b2a97;
}

[data-primary='color_3'] .pignose-calendar .pignose-calendar-top-date {
  background-color: #0b2a97;
}

[data-primary='color_3']
  .pignose-calendar.pignose-calendar-blue
  .pignose-calendar-body
  .pignose-calendar-row
  .pignose-calendar-unit.pignose-calendar-unit-active
  a {
  background-color: #0b2a97;
}

[data-primary='color_3'] .bootstrap-tagsinput .tag {
  background-color: #0b2a97;
}

[data-primary='color_3'] .toast-success {
  background-color: #0b2a97;
}

[data-primary='color_3'] .twitter-typeahead .tt-menu .tt-suggestion:hover {
  background-color: #0b2a97;
}

[data-primary='color_3'] .accordion-header-bg .accordion__header--primary {
  background-color: #0b2a97;
}

[data-primary='color_3'] .alert-primary {
  background: #315cf0;
  border-color: #315cf0;
  color: #0b2a97;
}

[data-primary='color_3'] .alert-alt.alert-primary {
  border-left: 4px solid #0b2a97;
}

[data-primary='color_3'] .alert-alt.alert-primary.solid {
  border-left: 4px solid #020920 !important;
}

[data-primary='color_3'] .alert.alert-primary.solid {
  background: #0b2a97;
  border-color: #0b2a97;
}

[data-primary='color_3'] .alert.alert-outline-primary {
  color: #0b2a97;
  border-color: #0b2a97;
}

[data-primary='color_3'] .badge-outline-primary {
  border: 1px solid #0b2a97;
  color: #0b2a97;
}

[data-primary='color_3'] .badge-primary {
  background-color: #0b2a97;
}

[data-primary='color_3'] .page-titles h4 {
  color: #0b2a97;
}

[data-primary='color_3'] .card-action > a {
  background: black;
}

[data-primary='color_3'] .card-action .dropdown {
  background: black;
  color: #0b2a97;
}

[data-primary='color_3'] .card-action .dropdown:hover,
[data-primary='color_3'] .card-action .dropdown:focus {
  background: black;
}

[data-primary='color_3'] .card-loader i {
  background: #092176;
}

[data-primary='color_3'] .dropdown-outline {
  border: 0.1rem solid #0b2a97;
}

[data-primary='color_3'] .custom-dropdown .dropdown-menu .dropdown-item:hover {
  color: #0b2a97;
}

[data-primary='color_3'] .card-action .custom-dropdown {
  background: #496ff2;
}

[data-primary='color_3'] .card-action .custom-dropdown.show,
[data-primary='color_3'] .card-action .custom-dropdown:focus,
[data-primary='color_3'] .card-action .custom-dropdown:hover {
  background: #0b2a97;
}

[data-primary='color_3'] .label-primary {
  background: #0b2a97;
}

[data-primary='color_3'] .pagination .page-item .page-link:hover {
  background: #0b2a97;
  border-color: #0b2a97;
}

[data-primary='color_3'] .pagination .page-item.active .page-link {
  background-color: #0b2a97;
  border-color: #0b2a97;
}

[data-primary='color_3']
  .bootstrap-popover-wrapper
  .bootstrap-popover
  button:hover,
[data-primary='color_3']
  .bootstrap-popover-wrapper
  .bootstrap-popover
  button:focus {
  background: #0b2a97;
}

[data-primary='color_3'] .progress-bar {
  background-color: #0b2a97;
}

[data-primary='color_3'] .progress-bar-primary {
  background-color: #0b2a97;
}

[data-primary='color_3'] .ribbon__four {
  background-color: #0b2a97;
}

[data-primary='color_3'] .ribbon__four:after,
[data-primary='color_3'] .ribbon__four:before {
  background-color: #1a49ee;
}

[data-primary='color_3'] .ribbon__five {
  background-color: #0b2a97;
}

[data-primary='color_3'] .ribbon__five::before {
  border-color: transparent transparent #0b2a97 transparent;
}

[data-primary='color_3'] .ribbon__six {
  background-color: #0b2a97;
}

[data-primary='color_3'] .multi-steps > li {
  color: #0b2a97;
}

[data-primary='color_3'] .multi-steps > li:after {
  background-color: #0b2a97;
}

[data-primary='color_3'] .multi-steps > li.is-active:before {
  border-color: #0b2a97;
}

[data-primary='color_3'] .timeline-badge.primary {
  background-color: #0b2a97 !important;
}

[data-primary='color_3'] .tooltip-wrapper button:hover {
  background: #0b2a97;
}

[data-primary='color_3'] .chart_widget_tab_one .nav-link.active {
  background-color: #0b2a97;
  border: 1px solid #0b2a97;
}

[data-primary='color_3'] .chart_widget_tab_one .nav-link.active:hover {
  border: 1px solid #0b2a97;
}

[data-primary='color_3'] .social-icon2 a {
  border: 0.1rem solid #0b2a97;
}

[data-primary='color_3'] .social-icon2 i {
  color: #0b2a97;
}

[data-primary='color_3'] .social-icon3 ul li a:hover i {
  color: #0b2a97;
}

[data-primary='color_3'] .bgl-primary {
  background: #496ff2;
  border-color: #496ff2;
  color: #0b2a97;
}

[data-primary='color_3'] .tdl-holder input[type='checkbox']:checked + i {
  background: #0b2a97;
}

[data-primary='color_3'] .footer .copyright a {
  color: #0b2a97;
}

[data-primary='color_3'] .hamburger .line {
  background: #0b2a97;
}

[data-primary='color_3'] svg.pulse-svg .first-circle,
[data-primary='color_3'] svg.pulse-svg .second-circle,
[data-primary='color_3'] svg.pulse-svg .third-circle {
  fill: #0b2a97;
}

[data-primary='color_3'] .pulse-css {
  background: #0b2a97;
}

[data-primary='color_3'] .pulse-css:after,
[data-primary='color_3'] .pulse-css:before {
  background-color: #0b2a97;
}

[data-primary='color_3']
  .notification_dropdown
  .dropdown-menu-right
  .notification_title {
  background: #0b2a97;
}

[data-primary='color_3'] .header-right .header-profile .dropdown-menu a:hover,
[data-primary='color_3'] .header-right .header-profile .dropdown-menu a:focus,
[data-primary='color_3'] .header-right .header-profile .dropdown-menu a.active {
  color: #0b2a97;
}

[data-primary='color_3'] .header-right .header-profile .profile_title {
  background: #0b2a97;
}

[data-primary='color_3'][data-sidebar-style='full'][data-layout='vertical']
  .menu-toggle
  .nav-header
  .nav-control
  .hamburger
  .line {
  background-color: #0b2a97 !important;
}

[data-primary='color_3'] .deznav .metismenu > li > a svg {
  color: #0b2a97;
}

[data-primary='color_3'] .deznav .metismenu > li:hover > a,
[data-primary='color_3'] .deznav .metismenu > li:focus > a {
  color: #0b2a97;
}

[data-primary='color_3'] .deznav .metismenu > li.mm-active > a {
  color: #0b2a97;
}

[data-primary='color_3'] .deznav .metismenu ul a:hover,
[data-primary='color_3'] .deznav .metismenu ul a:focus,
[data-primary='color_3'] .deznav .metismenu ul a.mm-active {
  color: #0b2a97;
}

@media (min-width: 767px) {
  [data-primary='color_3']
    [data-sidebar-style='modern']
    .deznav
    .metismenu
    > li
    > a:hover
    > a,
  [data-primary='color_3']
    [data-sidebar-style='modern']
    .deznav
    .metismenu
    > li
    > a:focus
    > a,
  [data-primary='color_3']
    [data-sidebar-style='modern']
    .deznav
    .metismenu
    > li
    > a:active
    > a,
  [data-primary='color_3']
    [data-sidebar-style='modern']
    .deznav
    .metismenu
    > li
    > a.mm-active
    > a {
    background-color: #a8baf9;
  }
}

[data-primary='color_3'][data-sidebar-style='overlay']
  .nav-header
  .hamburger.is-active
  .line {
  background-color: #0b2a97;
}

[data-primary='color_3'] .nav-user {
  background: #0b2a97;
}

[data-primary='color_3'] .sidebar-right .sidebar-right .sidebar-right-trigger {
  color: #0b2a97;
}

[data-primary='color_3']
  .sidebar-right
  .sidebar-right
  .sidebar-right-trigger:hover {
  color: #0b2a97;
}

[data-primary='color_3'][data-theme-version='dark']
  .pagination
  .page-item
  .page-link:hover {
  background: #0b2a97;
  border-color: #0b2a97;
}

[data-primary='color_3'][data-theme-version='dark']
  .pagination
  .page-item.active
  .page-link {
  background: #0b2a97;
  border-color: #0b2a97;
}

[data-primary='color_3'][data-theme-version='dark'] .header-left input:focus {
  border-color: #0b2a97;
}

[data-primary='color_3'][data-theme-version='dark'] .loader__bar {
  background: #0b2a97;
}

[data-primary='color_3'][data-theme-version='dark'] .loader__ball {
  background: #0b2a97;
}

[data-primary='color_3'][data-theme-version='transparent']
  .header-left
  input:focus {
  border-color: #0b2a97;
}

[data-primary='color_3'] .new-arrival-content .price {
  color: #0b2a97;
}

[data-primary='color_3'] .chart-link a i.text-primary {
  color: #0b2a97;
}

[data-primary='color_3'] #user-activity .nav-tabs .nav-link.active {
  background: #0b2a97;
  border-color: #0b2a97;
}

[data-primary='color_3'] span#counter {
  color: #0b2a97;
}

[data-primary='color_3'] .welcome-content:after {
  background: #0b2a97;
}

[data-primary='color_3'] .timeline-badge {
  background-color: #0b2a97;
}

[data-primary='color_3']
  .page-timeline
  .timeline-workplan[data-primary='color_3']
  .page-timeline
  .timeline
  .timeline-badge:after {
  background-color: rgba(11, 42, 151, 0.4);
}

[data-primary='color_3'] .sk-three-bounce .sk-child {
  background-color: #0b2a97;
}

[data-primary='color_3'] .dropdown-item.active,
[data-primary='color_3'] .dropdown-item:active {
  color: #fff;
  background-color: #0b2a97;
}

[data-primary='color_3'] .overlay-box:after {
  background: #0b2a97;
}

[data-primary='color_3'] .btn-primary {
  background-color: #0b2a97;
  border-color: #0b2a97;
}

[data-primary='color_3'] .bg-primary {
  background-color: #0b2a97 !important;
}

[data-primary='color_3'] .text-primary {
  color: #0b2a97 !important;
}

[data-primary='color_3'] .btn-primary:hover {
  background-color: #061650;
  border-color: #061650;
}

[data-primary='color_3'] .btn-outline-primary {
  color: #0b2a97;
  border-color: #0b2a97;
}

[data-primary='color_3'] .btn-outline-primary:hover {
  background-color: #0b2a97;
  border-color: #0b2a97;
}

[data-primary='color_3'] .btn-primary:not(:disabled):not(.disabled):active,
[data-primary='color_3'] .btn-primary:not(:disabled):not(.disabled).active,
[data-primary='color_3'] .show > .btn-primary.dropdown-toggle,
[data-primary='color_3']
  .btn-outline-primary:not(:disabled):not(.disabled):active,
[data-primary='color_3']
  .btn-outline-primary:not(:disabled):not(.disabled).active,
[data-primary='color_3'] .show > .btn-outline-primary.dropdown-toggle {
  background-color: #061650;
  border-color: #061650;
}

[data-primary='color_4'] a:hover,
[data-primary='color_4'] a:focus,
[data-primary='color_4'] a.active {
  color: #4527a0;
}

[data-primary='color_4'] [data-class='bg-primary']:before {
  background: #4527a0;
}

[data-primary='color_4'] .email-left-box .intro-title {
  background: rgba(69, 39, 160, 0.1);
}

[data-primary='color_4'] .email-left-box .intro-title i {
  color: #4527a0;
}

[data-primary='color_4'] .widget-stat .media .media-body h4 {
  color: #4527a0 !important;
}

[data-primary='color_4'] .email-right-box .right-box-border {
  border-right: 2px solid rgba(69, 39, 160, 0.1);
}

[data-primary='color_4'] .mail-list .list-group-item.active i {
  color: #4527a0;
}

[data-primary='color_4'] .single-mail.active {
  background: #4527a0;
}

[data-primary='color_4'] .profile-info h4.text-primary {
  color: #4527a0 !important;
}

[data-primary='color_4'] .profile-tab .nav-item .nav-link:hover,
[data-primary='color_4'] .profile-tab .nav-item .nav-link.active {
  border-bottom: 0.2px solid #4527a0;
  color: #4527a0;
}

[data-primary='color_4'] .amChartsInputField {
  border: 0;
  background: #4527a0;
}

[data-primary='color_4'] .amcharts-period-input,
[data-primary='color_4'] .amcharts-period-input-selected {
  background: #4527a0;
}

[data-primary='color_4'] .morris-hover {
  background: #4527a0;
}

[data-primary='color_4']
  .select2-container--default
  .select2-results__option--highlighted[aria-selected] {
  background-color: #4527a0;
}

[data-primary='color_4'] .custom-select:focus {
  border-color: #4527a0;
  color: #4527a0;
}

[data-primary='color_4'] .daterangepicker td.active {
  background-color: #4527a0;
}

[data-primary='color_4'] .daterangepicker td.active:hover {
  background-color: #4527a0;
}

[data-primary='color_4'] .daterangepicker button.applyBtn {
  background-color: #4527a0;
  border-color: #4527a0;
}

[data-primary='color_4'] .wizard > .steps li.current a {
  background-color: #4527a0;
}

[data-primary='color_4'] .wizard .skip-email a {
  color: #4527a0;
}

[data-primary='color_4'] .wizard > .actions li:not(.disabled) a {
  background-color: #4527a0;
}

[data-primary='color_4']
  .step-form-horizontal
  .wizard
  .steps
  li.done
  a
  .number {
  background: #4527a0;
}

[data-primary='color_4']
  .step-form-horizontal
  .wizard
  .steps
  li.current
  a
  .number {
  color: #4527a0;
  border-color: #4527a0;
}

[data-primary='color_4']
  .step-form-horizontal
  .wizard
  .steps
  li.disabled
  a
  .number {
  color: #4527a0;
}

[data-primary='color_4']
  .step-form-horizontal
  .wizard
  .steps
  li:not(:last-child)::after {
  background-color: #4527a0;
}

[data-primary='color_4'] .is-invalid .input-group-prepend .input-group-text i {
  color: #6642d1;
}

[data-primary='color_4'] .datamaps-hoverover {
  color: #4527a0;
  border: 1px solid rgba(69, 39, 160, 0.3);
}

[data-primary='color_4'] .jqvmap-zoomin,
[data-primary='color_4'] .jqvmap-zoomout {
  background-color: #4527a0;
}

[data-primary='color_4'] .table .thead-primary th {
  background-color: #4527a0;
}

[data-primary='color_4'] .table.primary-table-bg-hover thead th {
  background-color: #3c228b;
}

[data-primary='color_4'] .table.primary-table-bg-hover tbody tr {
  background-color: #4527a0;
}

[data-primary='color_4'] .table.primary-table-bg-hover tbody tr:hover {
  background-color: #4e2cb5;
}

[data-primary='color_4']
  .table.primary-table-bg-hover
  tbody
  tr:not(:last-child)
  td,
[data-primary='color_4']
  .table.primary-table-bg-hover
  tbody
  tr:not(:last-child)
  th {
  border-bottom: 1px solid #3c228b;
}

[data-primary='color_4'] table.dataTable tr.selected {
  color: #4527a0;
}

[data-primary='color_4']
  .dataTables_wrapper
  .dataTables_paginate
  .paginate_button.current {
  color: #4527a0 !important;
  background: rgba(69, 39, 160, 0.1);
}

[data-primary='color_4']
  .dataTables_wrapper
  .dataTables_paginate
  .paginate_button:hover {
  color: #4527a0 !important;
  background: rgba(69, 39, 160, 0.1);
}

[data-primary='color_4'] .clipboard-btn:hover {
  background-color: #4527a0;
}

[data-primary='color_4'] .cd-h-timeline__dates::before {
  background: #4527a0;
}

[data-primary='color_4'] .cd-h-timeline__dates::after {
  background: #4527a0;
}

[data-primary='color_4'] .cd-h-timeline__line {
  background-color: #4527a0;
}

[data-primary='color_4'] .cd-h-timeline__date:after {
  border-color: #4728a4;
  background-color: #4527a0;
}

[data-primary='color_4'] .cd-h-timeline__navigation {
  border-color: #4728a4;
}

[data-primary='color_4'] .cd-h-timeline__navigation--inactive:hover {
  border-color: #4728a4;
}

[data-primary='color_4'] .dd-handle {
  background: #4527a0;
}

[data-primary='color_4'] .dd-handle:hover {
  background: #4527a0;
}

[data-primary='color_4'] .dd3-content:hover {
  background: #4527a0;
}

[data-primary='color_4'] .noUi-connect {
  background-color: #4527a0;
}

[data-primary='color_4'] .noUi-connect.c-3-color {
  background-color: #4527a0;
}

[data-primary='color_4'] .noUi-horizontal .noUi-handle,
[data-primary='color_4'] .noUi-vertical .noUi-handle {
  background-color: #4527a0;
}

[data-primary='color_4'] #slider-toggle.off .noUi-handle {
  border-color: #4527a0;
}

[data-primary='color_4'] .pignose-calendar {
  border-color: #4527a0;
}

[data-primary='color_4'] .pignose-calendar .pignose-calendar-top-date {
  background-color: #4527a0;
}

[data-primary='color_4']
  .pignose-calendar.pignose-calendar-blue
  .pignose-calendar-body
  .pignose-calendar-row
  .pignose-calendar-unit.pignose-calendar-unit-active
  a {
  background-color: #4527a0;
}

[data-primary='color_4'] .bootstrap-tagsinput .tag {
  background-color: #4527a0;
}

[data-primary='color_4'] .toast-success {
  background-color: #4527a0;
}

[data-primary='color_4'] .twitter-typeahead .tt-menu .tt-suggestion:hover {
  background-color: #4527a0;
}

[data-primary='color_4'] .accordion-header-bg .accordion__header--primary {
  background-color: #4527a0;
}

[data-primary='color_4'] .alert-primary {
  background: #876bdb;
  border-color: #876bdb;
  color: #4527a0;
}

[data-primary='color_4'] .alert-alt.alert-primary {
  border-left: 4px solid #4527a0;
}

[data-primary='color_4'] .alert-alt.alert-primary.solid {
  border-left: 4px solid #190e39 !important;
}

[data-primary='color_4'] .alert.alert-primary.solid {
  background: #4527a0;
  border-color: #4527a0;
}

[data-primary='color_4'] .alert.alert-outline-primary {
  color: #4527a0;
  border-color: #4527a0;
}

[data-primary='color_4'] .badge-outline-primary {
  border: 1px solid #4527a0;
  color: #4527a0;
}

[data-primary='color_4'] .badge-primary {
  background-color: #4527a0;
}

[data-primary='color_4'] .page-titles h4 {
  color: #4527a0;
}

[data-primary='color_4'] .card-action > a {
  background: black;
}

[data-primary='color_4'] .card-action .dropdown {
  background: black;
  color: #4527a0;
}

[data-primary='color_4'] .card-action .dropdown:hover,
[data-primary='color_4'] .card-action .dropdown:focus {
  background: black;
}

[data-primary='color_4'] .card-loader i {
  background: #392083;
}

[data-primary='color_4'] .dropdown-outline {
  border: 0.1rem solid #4527a0;
}

[data-primary='color_4'] .custom-dropdown .dropdown-menu .dropdown-item:hover {
  color: #4527a0;
}

[data-primary='color_4'] .card-action .custom-dropdown {
  background: #9880e0;
}

[data-primary='color_4'] .card-action .custom-dropdown.show,
[data-primary='color_4'] .card-action .custom-dropdown:focus,
[data-primary='color_4'] .card-action .custom-dropdown:hover {
  background: #4527a0;
}

[data-primary='color_4'] .label-primary {
  background: #4527a0;
}

[data-primary='color_4'] .pagination .page-item .page-link:hover {
  background: #4527a0;
  border-color: #4527a0;
}

[data-primary='color_4'] .pagination .page-item.active .page-link {
  background-color: #4527a0;
  border-color: #4527a0;
}

[data-primary='color_4']
  .bootstrap-popover-wrapper
  .bootstrap-popover
  button:hover,
[data-primary='color_4']
  .bootstrap-popover-wrapper
  .bootstrap-popover
  button:focus {
  background: #4527a0;
}

[data-primary='color_4'] .progress-bar {
  background-color: #4527a0;
}

[data-primary='color_4'] .progress-bar-primary {
  background-color: #4527a0;
}

[data-primary='color_4'] .ribbon__four {
  background-color: #4527a0;
}

[data-primary='color_4'] .ribbon__four:after,
[data-primary='color_4'] .ribbon__four:before {
  background-color: #7657d6;
}

[data-primary='color_4'] .ribbon__five {
  background-color: #4527a0;
}

[data-primary='color_4'] .ribbon__five::before {
  border-color: transparent transparent #4527a0 transparent;
}

[data-primary='color_4'] .ribbon__six {
  background-color: #4527a0;
}

[data-primary='color_4'] .multi-steps > li {
  color: #4527a0;
}

[data-primary='color_4'] .multi-steps > li:after {
  background-color: #4527a0;
}

[data-primary='color_4'] .multi-steps > li.is-active:before {
  border-color: #4527a0;
}

[data-primary='color_4'] .timeline-badge.primary {
  background-color: #4527a0 !important;
}

[data-primary='color_4'] .tooltip-wrapper button:hover {
  background: #4527a0;
}

[data-primary='color_4'] .chart_widget_tab_one .nav-link.active {
  background-color: #4527a0;
  border: 1px solid #4527a0;
}

[data-primary='color_4'] .chart_widget_tab_one .nav-link.active:hover {
  border: 1px solid #4527a0;
}

[data-primary='color_4'] .social-icon2 a {
  border: 0.1rem solid #4527a0;
}

[data-primary='color_4'] .social-icon2 i {
  color: #4527a0;
}

[data-primary='color_4'] .social-icon3 ul li a:hover i {
  color: #4527a0;
}

[data-primary='color_4'] .bgl-primary {
  background: #9880e0;
  border-color: #9880e0;
  color: #4527a0;
}

[data-primary='color_4'] .tdl-holder input[type='checkbox']:checked + i {
  background: #4527a0;
}

[data-primary='color_4'] .footer .copyright a {
  color: #4527a0;
}

[data-primary='color_4'] .hamburger .line {
  background: #4527a0;
}

[data-primary='color_4'] svg.pulse-svg .first-circle,
[data-primary='color_4'] svg.pulse-svg .second-circle,
[data-primary='color_4'] svg.pulse-svg .third-circle {
  fill: #4527a0;
}

[data-primary='color_4'] .pulse-css {
  background: #4527a0;
}

[data-primary='color_4'] .pulse-css:after,
[data-primary='color_4'] .pulse-css:before {
  background-color: #4527a0;
}

[data-primary='color_4']
  .notification_dropdown
  .dropdown-menu-right
  .notification_title {
  background: #4527a0;
}

[data-primary='color_4'] .header-right .header-profile .dropdown-menu a:hover,
[data-primary='color_4'] .header-right .header-profile .dropdown-menu a:focus,
[data-primary='color_4'] .header-right .header-profile .dropdown-menu a.active {
  color: #4527a0;
}

[data-primary='color_4'] .header-right .header-profile .profile_title {
  background: #4527a0;
}

[data-primary='color_4'][data-sidebar-style='full'][data-layout='vertical']
  .menu-toggle
  .nav-header
  .nav-control
  .hamburger
  .line {
  background-color: #4527a0 !important;
}

[data-primary='color_4'] .deznav .metismenu > li > a svg {
  color: #4527a0;
}

[data-primary='color_4'] .deznav .metismenu > li:hover > a,
[data-primary='color_4'] .deznav .metismenu > li:focus > a {
  color: #4527a0;
}

[data-primary='color_4'] .deznav .metismenu > li.mm-active > a {
  color: #4527a0;
}

[data-primary='color_4'] .deznav .metismenu ul a:hover,
[data-primary='color_4'] .deznav .metismenu ul a:focus,
[data-primary='color_4'] .deznav .metismenu ul a.mm-active {
  color: #4527a0;
}

@media (min-width: 767px) {
  [data-primary='color_4']
    [data-sidebar-style='modern']
    .deznav
    .metismenu
    > li
    > a:hover
    > a,
  [data-primary='color_4']
    [data-sidebar-style='modern']
    .deznav
    .metismenu
    > li
    > a:focus
    > a,
  [data-primary='color_4']
    [data-sidebar-style='modern']
    .deznav
    .metismenu
    > li
    > a:active
    > a,
  [data-primary='color_4']
    [data-sidebar-style='modern']
    .deznav
    .metismenu
    > li
    > a.mm-active
    > a {
    background-color: #dad2f4;
  }
}

[data-primary='color_4'][data-sidebar-style='overlay']
  .nav-header
  .hamburger.is-active
  .line {
  background-color: #4527a0;
}

[data-primary='color_4'] .nav-user {
  background: #4527a0;
}

[data-primary='color_4'] .sidebar-right .sidebar-right .sidebar-right-trigger {
  color: #4527a0;
}

[data-primary='color_4']
  .sidebar-right
  .sidebar-right
  .sidebar-right-trigger:hover {
  color: #4527a0;
}

[data-primary='color_4'][data-theme-version='dark']
  .pagination
  .page-item
  .page-link:hover {
  background: #4527a0;
  border-color: #4527a0;
}

[data-primary='color_4'][data-theme-version='dark']
  .pagination
  .page-item.active
  .page-link {
  background: #4527a0;
  border-color: #4527a0;
}

[data-primary='color_4'][data-theme-version='dark'] .header-left input:focus {
  border-color: #4527a0;
}

[data-primary='color_4'][data-theme-version='dark'] .loader__bar {
  background: #4527a0;
}

[data-primary='color_4'][data-theme-version='dark'] .loader__ball {
  background: #4527a0;
}

[data-primary='color_4'][data-theme-version='transparent']
  .header-left
  input:focus {
  border-color: #4527a0;
}

[data-primary='color_4'] .new-arrival-content .price {
  color: #4527a0;
}

[data-primary='color_4'] .chart-link a i.text-primary {
  color: #4527a0;
}

[data-primary='color_4'] #user-activity .nav-tabs .nav-link.active {
  background: #4527a0;
  border-color: #4527a0;
}

[data-primary='color_4'] span#counter {
  color: #4527a0;
}

[data-primary='color_4'] .welcome-content:after {
  background: #4527a0;
}

[data-primary='color_4'] .timeline-badge {
  background-color: #4527a0;
}

[data-primary='color_4']
  .page-timeline
  .timeline-workplan[data-primary='color_4']
  .page-timeline
  .timeline
  .timeline-badge:after {
  background-color: rgba(69, 39, 160, 0.4);
}

[data-primary='color_4'] .sk-three-bounce .sk-child {
  background-color: #4527a0;
}

[data-primary='color_4'] .dropdown-item.active,
[data-primary='color_4'] .dropdown-item:active {
  color: #fff;
  background-color: #4527a0;
}

[data-primary='color_4'] .overlay-box:after {
  background: #4527a0;
}

[data-primary='color_4'] .btn-primary {
  background-color: #4527a0;
  border-color: #4527a0;
}

[data-primary='color_4'] .bg-primary {
  background-color: #4527a0 !important;
}

[data-primary='color_4'] .text-primary {
  color: #4527a0 !important;
}

[data-primary='color_4'] .btn-primary:hover {
  background-color: #2a1862;
  border-color: #2a1862;
}

[data-primary='color_4'] .btn-outline-primary {
  color: #4527a0;
  border-color: #4527a0;
}

[data-primary='color_4'] .btn-outline-primary:hover {
  background-color: #4527a0;
  border-color: #4527a0;
}

[data-primary='color_4'] .btn-primary:not(:disabled):not(.disabled):active,
[data-primary='color_4'] .btn-primary:not(:disabled):not(.disabled).active,
[data-primary='color_4'] .show > .btn-primary.dropdown-toggle,
[data-primary='color_4']
  .btn-outline-primary:not(:disabled):not(.disabled):active,
[data-primary='color_4']
  .btn-outline-primary:not(:disabled):not(.disabled).active,
[data-primary='color_4'] .show > .btn-outline-primary.dropdown-toggle {
  background-color: #2a1862;
  border-color: #2a1862;
}

[data-primary='color_5'] a:hover,
[data-primary='color_5'] a:focus,
[data-primary='color_5'] a.active {
  color: #c62828;
}

[data-primary='color_5'] [data-class='bg-primary']:before {
  background: #c62828;
}

[data-primary='color_5'] .email-left-box .intro-title {
  background: rgba(198, 40, 40, 0.1);
}

[data-primary='color_5'] .email-left-box .intro-title i {
  color: #c62828;
}

[data-primary='color_5'] .widget-stat .media .media-body h4 {
  color: #c62828 !important;
}

[data-primary='color_5'] .email-right-box .right-box-border {
  border-right: 2px solid rgba(198, 40, 40, 0.1);
}

[data-primary='color_5'] .mail-list .list-group-item.active i {
  color: #c62828;
}

[data-primary='color_5'] .single-mail.active {
  background: #c62828;
}

[data-primary='color_5'] .profile-info h4.text-primary {
  color: #c62828 !important;
}

[data-primary='color_5'] .profile-tab .nav-item .nav-link:hover,
[data-primary='color_5'] .profile-tab .nav-item .nav-link.active {
  border-bottom: 0.2px solid #c62828;
  color: #c62828;
}

[data-primary='color_5'] .amChartsInputField {
  border: 0;
  background: #c62828;
}

[data-primary='color_5'] .amcharts-period-input,
[data-primary='color_5'] .amcharts-period-input-selected {
  background: #c62828;
}

[data-primary='color_5'] .morris-hover {
  background: #c62828;
}

[data-primary='color_5']
  .select2-container--default
  .select2-results__option--highlighted[aria-selected] {
  background-color: #c62828;
}

[data-primary='color_5'] .custom-select:focus {
  border-color: #c62828;
  color: #c62828;
}

[data-primary='color_5'] .daterangepicker td.active {
  background-color: #c62828;
}

[data-primary='color_5'] .daterangepicker td.active:hover {
  background-color: #c62828;
}

[data-primary='color_5'] .daterangepicker button.applyBtn {
  background-color: #c62828;
  border-color: #c62828;
}

[data-primary='color_5'] .wizard > .steps li.current a {
  background-color: #c62828;
}

[data-primary='color_5'] .wizard .skip-email a {
  color: #c62828;
}

[data-primary='color_5'] .wizard > .actions li:not(.disabled) a {
  background-color: #c62828;
}

[data-primary='color_5']
  .step-form-horizontal
  .wizard
  .steps
  li.done
  a
  .number {
  background: #c62828;
}

[data-primary='color_5']
  .step-form-horizontal
  .wizard
  .steps
  li.current
  a
  .number {
  color: #c62828;
  border-color: #c62828;
}

[data-primary='color_5']
  .step-form-horizontal
  .wizard
  .steps
  li.disabled
  a
  .number {
  color: #c62828;
}

[data-primary='color_5']
  .step-form-horizontal
  .wizard
  .steps
  li:not(:last-child)::after {
  background-color: #c62828;
}

[data-primary='color_5'] .is-invalid .input-group-prepend .input-group-text i {
  color: #de5c5c;
}

[data-primary='color_5'] .datamaps-hoverover {
  color: #c62828;
  border: 1px solid rgba(198, 40, 40, 0.3);
}

[data-primary='color_5'] .jqvmap-zoomin,
[data-primary='color_5'] .jqvmap-zoomout {
  background-color: #c62828;
}

[data-primary='color_5'] .table .thead-primary th {
  background-color: #c62828;
}

[data-primary='color_5'] .table.primary-table-bg-hover thead th {
  background-color: #b12424;
}

[data-primary='color_5'] .table.primary-table-bg-hover tbody tr {
  background-color: #c62828;
}

[data-primary='color_5'] .table.primary-table-bg-hover tbody tr:hover {
  background-color: #d63232;
}

[data-primary='color_5']
  .table.primary-table-bg-hover
  tbody
  tr:not(:last-child)
  td,
[data-primary='color_5']
  .table.primary-table-bg-hover
  tbody
  tr:not(:last-child)
  th {
  border-bottom: 1px solid #b12424;
}

[data-primary='color_5'] table.dataTable tr.selected {
  color: #c62828;
}

[data-primary='color_5']
  .dataTables_wrapper
  .dataTables_paginate
  .paginate_button.current {
  color: #c62828 !important;
  background: rgba(198, 40, 40, 0.1);
}

[data-primary='color_5']
  .dataTables_wrapper
  .dataTables_paginate
  .paginate_button:hover {
  color: #c62828 !important;
  background: rgba(198, 40, 40, 0.1);
}

[data-primary='color_5'] .clipboard-btn:hover {
  background-color: #c62828;
}

[data-primary='color_5'] .cd-h-timeline__dates::before {
  background: #c62828;
}

[data-primary='color_5'] .cd-h-timeline__dates::after {
  background: #c62828;
}

[data-primary='color_5'] .cd-h-timeline__line {
  background-color: #c62828;
}

[data-primary='color_5'] .cd-h-timeline__date:after {
  border-color: #ca2929;
  background-color: #c62828;
}

[data-primary='color_5'] .cd-h-timeline__navigation {
  border-color: #ca2929;
}

[data-primary='color_5'] .cd-h-timeline__navigation--inactive:hover {
  border-color: #ca2929;
}

[data-primary='color_5'] .dd-handle {
  background: #c62828;
}

[data-primary='color_5'] .dd-handle:hover {
  background: #c62828;
}

[data-primary='color_5'] .dd3-content:hover {
  background: #c62828;
}

[data-primary='color_5'] .noUi-connect {
  background-color: #c62828;
}

[data-primary='color_5'] .noUi-connect.c-3-color {
  background-color: #c62828;
}

[data-primary='color_5'] .noUi-horizontal .noUi-handle,
[data-primary='color_5'] .noUi-vertical .noUi-handle {
  background-color: #c62828;
}

[data-primary='color_5'] #slider-toggle.off .noUi-handle {
  border-color: #c62828;
}

[data-primary='color_5'] .pignose-calendar {
  border-color: #c62828;
}

[data-primary='color_5'] .pignose-calendar .pignose-calendar-top-date {
  background-color: #c62828;
}

[data-primary='color_5']
  .pignose-calendar.pignose-calendar-blue
  .pignose-calendar-body
  .pignose-calendar-row
  .pignose-calendar-unit.pignose-calendar-unit-active
  a {
  background-color: #c62828;
}

[data-primary='color_5'] .bootstrap-tagsinput .tag {
  background-color: #c62828;
}

[data-primary='color_5'] .toast-success {
  background-color: #c62828;
}

[data-primary='color_5'] .twitter-typeahead .tt-menu .tt-suggestion:hover {
  background-color: #c62828;
}

[data-primary='color_5'] .accordion-header-bg .accordion__header--primary {
  background-color: #c62828;
}

[data-primary='color_5'] .alert-primary {
  background: #e78787;
  border-color: #e78787;
  color: #c62828;
}

[data-primary='color_5'] .alert-alt.alert-primary {
  border-left: 4px solid #c62828;
}

[data-primary='color_5'] .alert-alt.alert-primary.solid {
  border-left: 4px solid #5c1313 !important;
}

[data-primary='color_5'] .alert.alert-primary.solid {
  background: #c62828;
  border-color: #c62828;
}

[data-primary='color_5'] .alert.alert-outline-primary {
  color: #c62828;
  border-color: #c62828;
}

[data-primary='color_5'] .badge-outline-primary {
  border: 1px solid #c62828;
  color: #c62828;
}

[data-primary='color_5'] .badge-primary {
  background-color: #c62828;
}

[data-primary='color_5'] .page-titles h4 {
  color: #c62828;
}

[data-primary='color_5'] .card-action > a {
  background: black;
}

[data-primary='color_5'] .card-action .dropdown {
  background: black;
  color: #c62828;
}

[data-primary='color_5'] .card-action .dropdown:hover,
[data-primary='color_5'] .card-action .dropdown:focus {
  background: black;
}

[data-primary='color_5'] .card-loader i {
  background: #a82222;
}

[data-primary='color_5'] .dropdown-outline {
  border: 0.1rem solid #c62828;
}

[data-primary='color_5'] .custom-dropdown .dropdown-menu .dropdown-item:hover {
  color: #c62828;
}

[data-primary='color_5'] .card-action .custom-dropdown {
  background: #eb9c9c;
}

[data-primary='color_5'] .card-action .custom-dropdown.show,
[data-primary='color_5'] .card-action .custom-dropdown:focus,
[data-primary='color_5'] .card-action .custom-dropdown:hover {
  background: #c62828;
}

[data-primary='color_5'] .label-primary {
  background: #c62828;
}

[data-primary='color_5'] .pagination .page-item .page-link:hover {
  background: #c62828;
  border-color: #c62828;
}

[data-primary='color_5'] .pagination .page-item.active .page-link {
  background-color: #c62828;
  border-color: #c62828;
}

[data-primary='color_5']
  .bootstrap-popover-wrapper
  .bootstrap-popover
  button:hover,
[data-primary='color_5']
  .bootstrap-popover-wrapper
  .bootstrap-popover
  button:focus {
  background: #c62828;
}

[data-primary='color_5'] .progress-bar {
  background-color: #c62828;
}

[data-primary='color_5'] .progress-bar-primary {
  background-color: #c62828;
}

[data-primary='color_5'] .ribbon__four {
  background-color: #c62828;
}

[data-primary='color_5'] .ribbon__four:after,
[data-primary='color_5'] .ribbon__four:before {
  background-color: #e27272;
}

[data-primary='color_5'] .ribbon__five {
  background-color: #c62828;
}

[data-primary='color_5'] .ribbon__five::before {
  border-color: transparent transparent #c62828 transparent;
}

[data-primary='color_5'] .ribbon__six {
  background-color: #c62828;
}

[data-primary='color_5'] .multi-steps > li {
  color: #c62828;
}

[data-primary='color_5'] .multi-steps > li:after {
  background-color: #c62828;
}

[data-primary='color_5'] .multi-steps > li.is-active:before {
  border-color: #c62828;
}

[data-primary='color_5'] .timeline-badge.primary {
  background-color: #c62828 !important;
}

[data-primary='color_5'] .tooltip-wrapper button:hover {
  background: #c62828;
}

[data-primary='color_5'] .chart_widget_tab_one .nav-link.active {
  background-color: #c62828;
  border: 1px solid #c62828;
}

[data-primary='color_5'] .chart_widget_tab_one .nav-link.active:hover {
  border: 1px solid #c62828;
}

[data-primary='color_5'] .social-icon2 a {
  border: 0.1rem solid #c62828;
}

[data-primary='color_5'] .social-icon2 i {
  color: #c62828;
}

[data-primary='color_5'] .social-icon3 ul li a:hover i {
  color: #c62828;
}

[data-primary='color_5'] .bgl-primary {
  background: #eb9c9c;
  border-color: #eb9c9c;
  color: #c62828;
}

[data-primary='color_5'] .tdl-holder input[type='checkbox']:checked + i {
  background: #c62828;
}

[data-primary='color_5'] .footer .copyright a {
  color: #c62828;
}

[data-primary='color_5'] .hamburger .line {
  background: #c62828;
}

[data-primary='color_5'] svg.pulse-svg .first-circle,
[data-primary='color_5'] svg.pulse-svg .second-circle,
[data-primary='color_5'] svg.pulse-svg .third-circle {
  fill: #c62828;
}

[data-primary='color_5'] .pulse-css {
  background: #c62828;
}

[data-primary='color_5'] .pulse-css:after,
[data-primary='color_5'] .pulse-css:before {
  background-color: #c62828;
}

[data-primary='color_5']
  .notification_dropdown
  .dropdown-menu-right
  .notification_title {
  background: #c62828;
}

[data-primary='color_5'] .header-right .header-profile .dropdown-menu a:hover,
[data-primary='color_5'] .header-right .header-profile .dropdown-menu a:focus,
[data-primary='color_5'] .header-right .header-profile .dropdown-menu a.active {
  color: #c62828;
}

[data-primary='color_5'] .header-right .header-profile .profile_title {
  background: #c62828;
}

[data-primary='color_5'][data-sidebar-style='full'][data-layout='vertical']
  .menu-toggle
  .nav-header
  .nav-control
  .hamburger
  .line {
  background-color: #c62828 !important;
}

[data-primary='color_5'] .deznav .metismenu > li > a svg {
  color: #c62828;
}

[data-primary='color_5'] .deznav .metismenu > li:hover > a,
[data-primary='color_5'] .deznav .metismenu > li:focus > a {
  color: #c62828;
}

[data-primary='color_5'] .deznav .metismenu > li.mm-active > a {
  color: #c62828;
}

[data-primary='color_5'] .deznav .metismenu ul a:hover,
[data-primary='color_5'] .deznav .metismenu ul a:focus,
[data-primary='color_5'] .deznav .metismenu ul a.mm-active {
  color: #c62828;
}

@media (min-width: 767px) {
  [data-primary='color_5']
    [data-sidebar-style='modern']
    .deznav
    .metismenu
    > li
    > a:hover
    > a,
  [data-primary='color_5']
    [data-sidebar-style='modern']
    .deznav
    .metismenu
    > li
    > a:focus
    > a,
  [data-primary='color_5']
    [data-sidebar-style='modern']
    .deznav
    .metismenu
    > li
    > a:active
    > a,
  [data-primary='color_5']
    [data-sidebar-style='modern']
    .deznav
    .metismenu
    > li
    > a.mm-active
    > a {
    background-color: #fcf1f1;
  }
}

[data-primary='color_5'][data-sidebar-style='overlay']
  .nav-header
  .hamburger.is-active
  .line {
  background-color: #c62828;
}

[data-primary='color_5'] .nav-user {
  background: #c62828;
}

[data-primary='color_5'] .sidebar-right .sidebar-right .sidebar-right-trigger {
  color: #c62828;
}

[data-primary='color_5']
  .sidebar-right
  .sidebar-right
  .sidebar-right-trigger:hover {
  color: #c62828;
}

[data-primary='color_5'][data-theme-version='dark']
  .pagination
  .page-item
  .page-link:hover {
  background: #c62828;
  border-color: #c62828;
}

[data-primary='color_5'][data-theme-version='dark']
  .pagination
  .page-item.active
  .page-link {
  background: #c62828;
  border-color: #c62828;
}

[data-primary='color_5'][data-theme-version='dark'] .header-left input:focus {
  border-color: #c62828;
}

[data-primary='color_5'][data-theme-version='dark'] .loader__bar {
  background: #c62828;
}

[data-primary='color_5'][data-theme-version='dark'] .loader__ball {
  background: #c62828;
}

[data-primary='color_5'][data-theme-version='transparent']
  .header-left
  input:focus {
  border-color: #c62828;
}

[data-primary='color_5'] .new-arrival-content .price {
  color: #c62828;
}

[data-primary='color_5'] .chart-link a i.text-primary {
  color: #c62828;
}

[data-primary='color_5'] #user-activity .nav-tabs .nav-link.active {
  background: #c62828;
  border-color: #c62828;
}

[data-primary='color_5'] span#counter {
  color: #c62828;
}

[data-primary='color_5'] .welcome-content:after {
  background: #c62828;
}

[data-primary='color_5'] .timeline-badge {
  background-color: #c62828;
}

[data-primary='color_5']
  .page-timeline
  .timeline-workplan[data-primary='color_5']
  .page-timeline
  .timeline
  .timeline-badge:after {
  background-color: rgba(198, 40, 40, 0.4);
}

[data-primary='color_5'] .sk-three-bounce .sk-child {
  background-color: #c62828;
}

[data-primary='color_5'] .dropdown-item.active,
[data-primary='color_5'] .dropdown-item:active {
  color: #fff;
  background-color: #c62828;
}

[data-primary='color_5'] .overlay-box:after {
  background: #c62828;
}

[data-primary='color_5'] .btn-primary {
  background-color: #c62828;
  border-color: #c62828;
}

[data-primary='color_5'] .bg-primary {
  background-color: #c62828 !important;
}

[data-primary='color_5'] .text-primary {
  color: #c62828 !important;
}

[data-primary='color_5'] .btn-primary:hover {
  background-color: #861b1b;
  border-color: #861b1b;
}

[data-primary='color_5'] .btn-outline-primary {
  color: #c62828;
  border-color: #c62828;
}

[data-primary='color_5'] .btn-outline-primary:hover {
  background-color: #c62828;
  border-color: #c62828;
}

[data-primary='color_5'] .btn-primary:not(:disabled):not(.disabled):active,
[data-primary='color_5'] .btn-primary:not(:disabled):not(.disabled).active,
[data-primary='color_5'] .show > .btn-primary.dropdown-toggle,
[data-primary='color_5']
  .btn-outline-primary:not(:disabled):not(.disabled):active,
[data-primary='color_5']
  .btn-outline-primary:not(:disabled):not(.disabled).active,
[data-primary='color_5'] .show > .btn-outline-primary.dropdown-toggle {
  background-color: #861b1b;
  border-color: #861b1b;
}

[data-primary='color_6'] a:hover,
[data-primary='color_6'] a:focus,
[data-primary='color_6'] a.active {
  color: #283593;
}

[data-primary='color_6'] [data-class='bg-primary']:before {
  background: #283593;
}

[data-primary='color_6'] .email-left-box .intro-title {
  background: rgba(40, 53, 147, 0.1);
}

[data-primary='color_6'] .email-left-box .intro-title i {
  color: #283593;
}

[data-primary='color_6'] .widget-stat .media .media-body h4 {
  color: #283593 !important;
}

[data-primary='color_6'] .email-right-box .right-box-border {
  border-right: 2px solid rgba(40, 53, 147, 0.1);
}

[data-primary='color_6'] .mail-list .list-group-item.active i {
  color: #283593;
}

[data-primary='color_6'] .single-mail.active {
  background: #283593;
}

[data-primary='color_6'] .profile-info h4.text-primary {
  color: #283593 !important;
}

[data-primary='color_6'] .profile-tab .nav-item .nav-link:hover,
[data-primary='color_6'] .profile-tab .nav-item .nav-link.active {
  border-bottom: 0.2px solid #283593;
  color: #283593;
}

[data-primary='color_6'] .amChartsInputField {
  border: 0;
  background: #283593;
}

[data-primary='color_6'] .amcharts-period-input,
[data-primary='color_6'] .amcharts-period-input-selected {
  background: #283593;
}

[data-primary='color_6'] .morris-hover {
  background: #283593;
}

[data-primary='color_6']
  .select2-container--default
  .select2-results__option--highlighted[aria-selected] {
  background-color: #283593;
}

[data-primary='color_6'] .custom-select:focus {
  border-color: #283593;
  color: #283593;
}

[data-primary='color_6'] .daterangepicker td.active {
  background-color: #283593;
}

[data-primary='color_6'] .daterangepicker td.active:hover {
  background-color: #283593;
}

[data-primary='color_6'] .daterangepicker button.applyBtn {
  background-color: #283593;
  border-color: #283593;
}

[data-primary='color_6'] .wizard > .steps li.current a {
  background-color: #283593;
}

[data-primary='color_6'] .wizard .skip-email a {
  color: #283593;
}

[data-primary='color_6'] .wizard > .actions li:not(.disabled) a {
  background-color: #283593;
}

[data-primary='color_6']
  .step-form-horizontal
  .wizard
  .steps
  li.done
  a
  .number {
  background: #283593;
}

[data-primary='color_6']
  .step-form-horizontal
  .wizard
  .steps
  li.current
  a
  .number {
  color: #283593;
  border-color: #283593;
}

[data-primary='color_6']
  .step-form-horizontal
  .wizard
  .steps
  li.disabled
  a
  .number {
  color: #283593;
}

[data-primary='color_6']
  .step-form-horizontal
  .wizard
  .steps
  li:not(:last-child)::after {
  background-color: #283593;
}

[data-primary='color_6'] .is-invalid .input-group-prepend .input-group-text i {
  color: #3d4eca;
}

[data-primary='color_6'] .datamaps-hoverover {
  color: #283593;
  border: 1px solid rgba(40, 53, 147, 0.3);
}

[data-primary='color_6'] .jqvmap-zoomin,
[data-primary='color_6'] .jqvmap-zoomout {
  background-color: #283593;
}

[data-primary='color_6'] .table .thead-primary th {
  background-color: #283593;
}

[data-primary='color_6'] .table.primary-table-bg-hover thead th {
  background-color: #232e7f;
}

[data-primary='color_6'] .table.primary-table-bg-hover tbody tr {
  background-color: #283593;
}

[data-primary='color_6'] .table.primary-table-bg-hover tbody tr:hover {
  background-color: #2d3ca7;
}

[data-primary='color_6']
  .table.primary-table-bg-hover
  tbody
  tr:not(:last-child)
  td,
[data-primary='color_6']
  .table.primary-table-bg-hover
  tbody
  tr:not(:last-child)
  th {
  border-bottom: 1px solid #232e7f;
}

[data-primary='color_6'] table.dataTable tr.selected {
  color: #283593;
}

[data-primary='color_6']
  .dataTables_wrapper
  .dataTables_paginate
  .paginate_button.current {
  color: #283593 !important;
  background: rgba(40, 53, 147, 0.1);
}

[data-primary='color_6']
  .dataTables_wrapper
  .dataTables_paginate
  .paginate_button:hover {
  color: #283593 !important;
  background: rgba(40, 53, 147, 0.1);
}

[data-primary='color_6'] .clipboard-btn:hover {
  background-color: #283593;
}

[data-primary='color_6'] .cd-h-timeline__dates::before {
  background: #283593;
}

[data-primary='color_6'] .cd-h-timeline__dates::after {
  background: #283593;
}

[data-primary='color_6'] .cd-h-timeline__line {
  background-color: #283593;
}

[data-primary='color_6'] .cd-h-timeline__date:after {
  border-color: #293697;
  background-color: #283593;
}

[data-primary='color_6'] .cd-h-timeline__navigation {
  border-color: #293697;
}

[data-primary='color_6'] .cd-h-timeline__navigation--inactive:hover {
  border-color: #293697;
}

[data-primary='color_6'] .dd-handle {
  background: #283593;
}

[data-primary='color_6'] .dd-handle:hover {
  background: #283593;
}

[data-primary='color_6'] .dd3-content:hover {
  background: #283593;
}

[data-primary='color_6'] .noUi-connect {
  background-color: #283593;
}

[data-primary='color_6'] .noUi-connect.c-3-color {
  background-color: #283593;
}

[data-primary='color_6'] .noUi-horizontal .noUi-handle,
[data-primary='color_6'] .noUi-vertical .noUi-handle {
  background-color: #283593;
}

[data-primary='color_6'] #slider-toggle.off .noUi-handle {
  border-color: #283593;
}

[data-primary='color_6'] .pignose-calendar {
  border-color: #283593;
}

[data-primary='color_6'] .pignose-calendar .pignose-calendar-top-date {
  background-color: #283593;
}

[data-primary='color_6']
  .pignose-calendar.pignose-calendar-blue
  .pignose-calendar-body
  .pignose-calendar-row
  .pignose-calendar-unit.pignose-calendar-unit-active
  a {
  background-color: #283593;
}

[data-primary='color_6'] .bootstrap-tagsinput .tag {
  background-color: #283593;
}

[data-primary='color_6'] .toast-success {
  background-color: #283593;
}

[data-primary='color_6'] .twitter-typeahead .tt-menu .tt-suggestion:hover {
  background-color: #283593;
}

[data-primary='color_6'] .accordion-header-bg .accordion__header--primary {
  background-color: #283593;
}

[data-primary='color_6'] .alert-primary {
  background: #6573d5;
  border-color: #6573d5;
  color: #283593;
}

[data-primary='color_6'] .alert-alt.alert-primary {
  border-left: 4px solid #283593;
}

[data-primary='color_6'] .alert-alt.alert-primary.solid {
  border-left: 4px solid #0d112f !important;
}

[data-primary='color_6'] .alert.alert-primary.solid {
  background: #283593;
  border-color: #283593;
}

[data-primary='color_6'] .alert.alert-outline-primary {
  color: #283593;
  border-color: #283593;
}

[data-primary='color_6'] .badge-outline-primary {
  border: 1px solid #283593;
  color: #283593;
}

[data-primary='color_6'] .badge-primary {
  background-color: #283593;
}

[data-primary='color_6'] .page-titles h4 {
  color: #283593;
}

[data-primary='color_6'] .card-action > a {
  background: black;
}

[data-primary='color_6'] .card-action .dropdown {
  background: black;
  color: #283593;
}

[data-primary='color_6'] .card-action .dropdown:hover,
[data-primary='color_6'] .card-action .dropdown:focus {
  background: black;
}

[data-primary='color_6'] .card-loader i {
  background: #202b77;
}

[data-primary='color_6'] .dropdown-outline {
  border: 0.1rem solid #283593;
}

[data-primary='color_6'] .custom-dropdown .dropdown-menu .dropdown-item:hover {
  color: #283593;
}

[data-primary='color_6'] .card-action .custom-dropdown {
  background: #7985db;
}

[data-primary='color_6'] .card-action .custom-dropdown.show,
[data-primary='color_6'] .card-action .custom-dropdown:focus,
[data-primary='color_6'] .card-action .custom-dropdown:hover {
  background: #283593;
}

[data-primary='color_6'] .label-primary {
  background: #283593;
}

[data-primary='color_6'] .pagination .page-item .page-link:hover {
  background: #283593;
  border-color: #283593;
}

[data-primary='color_6'] .pagination .page-item.active .page-link {
  background-color: #283593;
  border-color: #283593;
}

[data-primary='color_6']
  .bootstrap-popover-wrapper
  .bootstrap-popover
  button:hover,
[data-primary='color_6']
  .bootstrap-popover-wrapper
  .bootstrap-popover
  button:focus {
  background: #283593;
}

[data-primary='color_6'] .progress-bar {
  background-color: #283593;
}

[data-primary='color_6'] .progress-bar-primary {
  background-color: #283593;
}

[data-primary='color_6'] .ribbon__four {
  background-color: #283593;
}

[data-primary='color_6'] .ribbon__four:after,
[data-primary='color_6'] .ribbon__four:before {
  background-color: #5161d0;
}

[data-primary='color_6'] .ribbon__five {
  background-color: #283593;
}

[data-primary='color_6'] .ribbon__five::before {
  border-color: transparent transparent #283593 transparent;
}

[data-primary='color_6'] .ribbon__six {
  background-color: #283593;
}

[data-primary='color_6'] .multi-steps > li {
  color: #283593;
}

[data-primary='color_6'] .multi-steps > li:after {
  background-color: #283593;
}

[data-primary='color_6'] .multi-steps > li.is-active:before {
  border-color: #283593;
}

[data-primary='color_6'] .timeline-badge.primary {
  background-color: #283593 !important;
}

[data-primary='color_6'] .tooltip-wrapper button:hover {
  background: #283593;
}

[data-primary='color_6'] .chart_widget_tab_one .nav-link.active {
  background-color: #283593;
  border: 1px solid #283593;
}

[data-primary='color_6'] .chart_widget_tab_one .nav-link.active:hover {
  border: 1px solid #283593;
}

[data-primary='color_6'] .social-icon2 a {
  border: 0.1rem solid #283593;
}

[data-primary='color_6'] .social-icon2 i {
  color: #283593;
}

[data-primary='color_6'] .social-icon3 ul li a:hover i {
  color: #283593;
}

[data-primary='color_6'] .bgl-primary {
  background: #7985db;
  border-color: #7985db;
  color: #283593;
}

[data-primary='color_6'] .tdl-holder input[type='checkbox']:checked + i {
  background: #283593;
}

[data-primary='color_6'] .footer .copyright a {
  color: #283593;
}

[data-primary='color_6'] .hamburger .line {
  background: #283593;
}

[data-primary='color_6'] svg.pulse-svg .first-circle,
[data-primary='color_6'] svg.pulse-svg .second-circle,
[data-primary='color_6'] svg.pulse-svg .third-circle {
  fill: #283593;
}

[data-primary='color_6'] .pulse-css {
  background: #283593;
}

[data-primary='color_6'] .pulse-css:after,
[data-primary='color_6'] .pulse-css:before {
  background-color: #283593;
}

[data-primary='color_6']
  .notification_dropdown
  .dropdown-menu-right
  .notification_title {
  background: #283593;
}

[data-primary='color_6'] .header-right .header-profile .dropdown-menu a:hover,
[data-primary='color_6'] .header-right .header-profile .dropdown-menu a:focus,
[data-primary='color_6'] .header-right .header-profile .dropdown-menu a.active {
  color: #283593;
}

[data-primary='color_6'] .header-right .header-profile .profile_title {
  background: #283593;
}

[data-primary='color_6'][data-sidebar-style='full'][data-layout='vertical']
  .menu-toggle
  .nav-header
  .nav-control
  .hamburger
  .line {
  background-color: #283593 !important;
}

[data-primary='color_6'] .deznav .metismenu > li > a svg {
  color: #283593;
}

[data-primary='color_6'] .deznav .metismenu > li:hover > a,
[data-primary='color_6'] .deznav .metismenu > li:focus > a {
  color: #283593;
}

[data-primary='color_6'] .deznav .metismenu > li.mm-active > a {
  color: #283593;
}

[data-primary='color_6'] .deznav .metismenu ul a:hover,
[data-primary='color_6'] .deznav .metismenu ul a:focus,
[data-primary='color_6'] .deznav .metismenu ul a.mm-active {
  color: #283593;
}

@media (min-width: 767px) {
  [data-primary='color_6']
    [data-sidebar-style='modern']
    .deznav
    .metismenu
    > li
    > a:hover
    > a,
  [data-primary='color_6']
    [data-sidebar-style='modern']
    .deznav
    .metismenu
    > li
    > a:focus
    > a,
  [data-primary='color_6']
    [data-sidebar-style='modern']
    .deznav
    .metismenu
    > li
    > a:active
    > a,
  [data-primary='color_6']
    [data-sidebar-style='modern']
    .deznav
    .metismenu
    > li
    > a.mm-active
    > a {
    background-color: #cacef0;
  }
}

[data-primary='color_6'][data-sidebar-style='overlay']
  .nav-header
  .hamburger.is-active
  .line {
  background-color: #283593;
}

[data-primary='color_6'] .nav-user {
  background: #283593;
}

[data-primary='color_6'] .sidebar-right .sidebar-right .sidebar-right-trigger {
  color: #283593;
}

[data-primary='color_6']
  .sidebar-right
  .sidebar-right
  .sidebar-right-trigger:hover {
  color: #283593;
}

[data-primary='color_6'][data-theme-version='dark']
  .pagination
  .page-item
  .page-link:hover {
  background: #283593;
  border-color: #283593;
}

[data-primary='color_6'][data-theme-version='dark']
  .pagination
  .page-item.active
  .page-link {
  background: #283593;
  border-color: #283593;
}

[data-primary='color_6'][data-theme-version='dark'] .header-left input:focus {
  border-color: #283593;
}

[data-primary='color_6'][data-theme-version='dark'] .loader__bar {
  background: #283593;
}

[data-primary='color_6'][data-theme-version='dark'] .loader__ball {
  background: #283593;
}

[data-primary='color_6'][data-theme-version='transparent']
  .header-left
  input:focus {
  border-color: #283593;
}

[data-primary='color_6'] .new-arrival-content .price {
  color: #283593;
}

[data-primary='color_6'] .chart-link a i.text-primary {
  color: #283593;
}

[data-primary='color_6'] #user-activity .nav-tabs .nav-link.active {
  background: #283593;
  border-color: #283593;
}

[data-primary='color_6'] span#counter {
  color: #283593;
}

[data-primary='color_6'] .welcome-content:after {
  background: #283593;
}

[data-primary='color_6'] .timeline-badge {
  background-color: #283593;
}

[data-primary='color_6']
  .page-timeline
  .timeline-workplan[data-primary='color_6']
  .page-timeline
  .timeline
  .timeline-badge:after {
  background-color: rgba(40, 53, 147, 0.4);
}

[data-primary='color_6'] .sk-three-bounce .sk-child {
  background-color: #283593;
}

[data-primary='color_6'] .dropdown-item.active,
[data-primary='color_6'] .dropdown-item:active {
  color: #fff;
  background-color: #283593;
}

[data-primary='color_6'] .overlay-box:after {
  background: #283593;
}

[data-primary='color_6'] .btn-primary {
  background-color: #283593;
  border-color: #283593;
}

[data-primary='color_6'] .bg-primary {
  background-color: #283593 !important;
}

[data-primary='color_6'] .text-primary {
  color: #283593 !important;
}

[data-primary='color_6'] .btn-primary:hover {
  background-color: #181f57;
  border-color: #181f57;
}

[data-primary='color_6'] .btn-outline-primary {
  color: #283593;
  border-color: #283593;
}

[data-primary='color_6'] .btn-outline-primary:hover {
  background-color: #283593;
  border-color: #283593;
}

[data-primary='color_6'] .btn-primary:not(:disabled):not(.disabled):active,
[data-primary='color_6'] .btn-primary:not(:disabled):not(.disabled).active,
[data-primary='color_6'] .show > .btn-primary.dropdown-toggle,
[data-primary='color_6']
  .btn-outline-primary:not(:disabled):not(.disabled):active,
[data-primary='color_6']
  .btn-outline-primary:not(:disabled):not(.disabled).active,
[data-primary='color_6'] .show > .btn-outline-primary.dropdown-toggle {
  background-color: #181f57;
  border-color: #181f57;
}

[data-primary='color_7'] a:hover,
[data-primary='color_7'] a:focus,
[data-primary='color_7'] a.active {
  color: #7356f1;
}

[data-primary='color_7'] [data-class='bg-primary']:before {
  background: #7356f1;
}

[data-primary='color_7'] .email-left-box .intro-title {
  background: rgba(115, 86, 241, 0.1);
}

[data-primary='color_7'] .email-left-box .intro-title i {
  color: #7356f1;
}

[data-primary='color_7'] .widget-stat .media .media-body h4 {
  color: #7356f1 !important;
}

[data-primary='color_7'] .email-right-box .right-box-border {
  border-right: 2px solid rgba(115, 86, 241, 0.1);
}

[data-primary='color_7'] .mail-list .list-group-item.active i {
  color: #7356f1;
}

[data-primary='color_7'] .single-mail.active {
  background: #7356f1;
}

[data-primary='color_7'] .profile-info h4.text-primary {
  color: #7356f1 !important;
}

[data-primary='color_7'] .profile-tab .nav-item .nav-link:hover,
[data-primary='color_7'] .profile-tab .nav-item .nav-link.active {
  border-bottom: 0.2px solid #7356f1;
  color: #7356f1;
}

[data-primary='color_7'] .amChartsInputField {
  border: 0;
  background: #7356f1;
}

[data-primary='color_7'] .amcharts-period-input,
[data-primary='color_7'] .amcharts-period-input-selected {
  background: #7356f1;
}

[data-primary='color_7'] .morris-hover {
  background: #7356f1;
}

[data-primary='color_7']
  .select2-container--default
  .select2-results__option--highlighted[aria-selected] {
  background-color: #7356f1;
}

[data-primary='color_7'] .custom-select:focus {
  border-color: #7356f1;
  color: #7356f1;
}

[data-primary='color_7'] .daterangepicker td.active {
  background-color: #7356f1;
}

[data-primary='color_7'] .daterangepicker td.active:hover {
  background-color: #7356f1;
}

[data-primary='color_7'] .daterangepicker button.applyBtn {
  background-color: #7356f1;
  border-color: #7356f1;
}

[data-primary='color_7'] .wizard > .steps li.current a {
  background-color: #7356f1;
}

[data-primary='color_7'] .wizard .skip-email a {
  color: #7356f1;
}

[data-primary='color_7'] .wizard > .actions li:not(.disabled) a {
  background-color: #7356f1;
}

[data-primary='color_7']
  .step-form-horizontal
  .wizard
  .steps
  li.done
  a
  .number {
  background: #7356f1;
}

[data-primary='color_7']
  .step-form-horizontal
  .wizard
  .steps
  li.current
  a
  .number {
  color: #7356f1;
  border-color: #7356f1;
}

[data-primary='color_7']
  .step-form-horizontal
  .wizard
  .steps
  li.disabled
  a
  .number {
  color: #7356f1;
}

[data-primary='color_7']
  .step-form-horizontal
  .wizard
  .steps
  li:not(:last-child)::after {
  background-color: #7356f1;
}

[data-primary='color_7'] .is-invalid .input-group-prepend .input-group-text i {
  color: #ae9df7;
}

[data-primary='color_7'] .datamaps-hoverover {
  color: #7356f1;
  border: 1px solid rgba(115, 86, 241, 0.3);
}

[data-primary='color_7'] .jqvmap-zoomin,
[data-primary='color_7'] .jqvmap-zoomout {
  background-color: #7356f1;
}

[data-primary='color_7'] .table .thead-primary th {
  background-color: #7356f1;
}

[data-primary='color_7'] .table.primary-table-bg-hover thead th {
  background-color: #5f3eef;
}

[data-primary='color_7'] .table.primary-table-bg-hover tbody tr {
  background-color: #7356f1;
}

[data-primary='color_7'] .table.primary-table-bg-hover tbody tr:hover {
  background-color: #876ef3;
}

[data-primary='color_7']
  .table.primary-table-bg-hover
  tbody
  tr:not(:last-child)
  td,
[data-primary='color_7']
  .table.primary-table-bg-hover
  tbody
  tr:not(:last-child)
  th {
  border-bottom: 1px solid #5f3eef;
}

[data-primary='color_7'] table.dataTable tr.selected {
  color: #7356f1;
}

[data-primary='color_7']
  .dataTables_wrapper
  .dataTables_paginate
  .paginate_button.current {
  color: #7356f1 !important;
  background: rgba(115, 86, 241, 0.1);
}

[data-primary='color_7']
  .dataTables_wrapper
  .dataTables_paginate
  .paginate_button:hover {
  color: #7356f1 !important;
  background: rgba(115, 86, 241, 0.1);
}

[data-primary='color_7'] .clipboard-btn:hover {
  background-color: #7356f1;
}

[data-primary='color_7'] .cd-h-timeline__dates::before {
  background: #7356f1;
}

[data-primary='color_7'] .cd-h-timeline__dates::after {
  background: #7356f1;
}

[data-primary='color_7'] .cd-h-timeline__line {
  background-color: #7356f1;
}

[data-primary='color_7'] .cd-h-timeline__date:after {
  border-color: #775af1;
  background-color: #7356f1;
}

[data-primary='color_7'] .cd-h-timeline__navigation {
  border-color: #775af1;
}

[data-primary='color_7'] .cd-h-timeline__navigation--inactive:hover {
  border-color: #775af1;
}

[data-primary='color_7'] .dd-handle {
  background: #7356f1;
}

[data-primary='color_7'] .dd-handle:hover {
  background: #7356f1;
}

[data-primary='color_7'] .dd3-content:hover {
  background: #7356f1;
}

[data-primary='color_7'] .noUi-connect {
  background-color: #7356f1;
}

[data-primary='color_7'] .noUi-connect.c-3-color {
  background-color: #7356f1;
}

[data-primary='color_7'] .noUi-horizontal .noUi-handle,
[data-primary='color_7'] .noUi-vertical .noUi-handle {
  background-color: #7356f1;
}

[data-primary='color_7'] #slider-toggle.off .noUi-handle {
  border-color: #7356f1;
}

[data-primary='color_7'] .pignose-calendar {
  border-color: #7356f1;
}

[data-primary='color_7'] .pignose-calendar .pignose-calendar-top-date {
  background-color: #7356f1;
}

[data-primary='color_7']
  .pignose-calendar.pignose-calendar-blue
  .pignose-calendar-body
  .pignose-calendar-row
  .pignose-calendar-unit.pignose-calendar-unit-active
  a {
  background-color: #7356f1;
}

[data-primary='color_7'] .bootstrap-tagsinput .tag {
  background-color: #7356f1;
}

[data-primary='color_7'] .toast-success {
  background-color: #7356f1;
}

[data-primary='color_7'] .twitter-typeahead .tt-menu .tt-suggestion:hover {
  background-color: #7356f1;
}

[data-primary='color_7'] .accordion-header-bg .accordion__header--primary {
  background-color: #7356f1;
}

[data-primary='color_7'] .alert-primary {
  background: #d5ccfb;
  border-color: #d5ccfb;
  color: #7356f1;
}

[data-primary='color_7'] .alert-alt.alert-primary {
  border-left: 4px solid #7356f1;
}

[data-primary='color_7'] .alert-alt.alert-primary.solid {
  border-left: 4px solid #2f0fb8 !important;
}

[data-primary='color_7'] .alert.alert-primary.solid {
  background: #7356f1;
  border-color: #7356f1;
}

[data-primary='color_7'] .alert.alert-outline-primary {
  color: #7356f1;
  border-color: #7356f1;
}

[data-primary='color_7'] .badge-outline-primary {
  border: 1px solid #7356f1;
  color: #7356f1;
}

[data-primary='color_7'] .badge-primary {
  background-color: #7356f1;
}

[data-primary='color_7'] .page-titles h4 {
  color: #7356f1;
}

[data-primary='color_7'] .card-action > a {
  background: #090322;
}

[data-primary='color_7'] .card-action .dropdown {
  background: #090322;
  color: #7356f1;
}

[data-primary='color_7'] .card-action .dropdown:hover,
[data-primary='color_7'] .card-action .dropdown:focus {
  background: #090322;
}

[data-primary='color_7'] .card-loader i {
  background: #5835ee;
}

[data-primary='color_7'] .dropdown-outline {
  border: 0.1rem solid #7356f1;
}

[data-primary='color_7'] .custom-dropdown .dropdown-menu .dropdown-item:hover {
  color: #7356f1;
}

[data-primary='color_7'] .card-action .custom-dropdown {
  background: #e8e3fd;
}

[data-primary='color_7'] .card-action .custom-dropdown.show,
[data-primary='color_7'] .card-action .custom-dropdown:focus,
[data-primary='color_7'] .card-action .custom-dropdown:hover {
  background: #7356f1;
}

[data-primary='color_7'] .label-primary {
  background: #7356f1;
}

[data-primary='color_7'] .pagination .page-item .page-link:hover {
  background: #7356f1;
  border-color: #7356f1;
}

[data-primary='color_7'] .pagination .page-item.active .page-link {
  background-color: #7356f1;
  border-color: #7356f1;
}

[data-primary='color_7']
  .bootstrap-popover-wrapper
  .bootstrap-popover
  button:hover,
[data-primary='color_7']
  .bootstrap-popover-wrapper
  .bootstrap-popover
  button:focus {
  background: #7356f1;
}

[data-primary='color_7'] .progress-bar {
  background-color: #7356f1;
}

[data-primary='color_7'] .progress-bar-primary {
  background-color: #7356f1;
}

[data-primary='color_7'] .ribbon__four {
  background-color: #7356f1;
}

[data-primary='color_7'] .ribbon__four:after,
[data-primary='color_7'] .ribbon__four:before {
  background-color: #c1b4f9;
}

[data-primary='color_7'] .ribbon__five {
  background-color: #7356f1;
}

[data-primary='color_7'] .ribbon__five::before {
  border-color: transparent transparent #7356f1 transparent;
}

[data-primary='color_7'] .ribbon__six {
  background-color: #7356f1;
}

[data-primary='color_7'] .multi-steps > li {
  color: #7356f1;
}

[data-primary='color_7'] .multi-steps > li:after {
  background-color: #7356f1;
}

[data-primary='color_7'] .multi-steps > li.is-active:before {
  border-color: #7356f1;
}

[data-primary='color_7'] .timeline-badge.primary {
  background-color: #7356f1 !important;
}

[data-primary='color_7'] .tooltip-wrapper button:hover {
  background: #7356f1;
}

[data-primary='color_7'] .chart_widget_tab_one .nav-link.active {
  background-color: #7356f1;
  border: 1px solid #7356f1;
}

[data-primary='color_7'] .chart_widget_tab_one .nav-link.active:hover {
  border: 1px solid #7356f1;
}

[data-primary='color_7'] .social-icon2 a {
  border: 0.1rem solid #7356f1;
}

[data-primary='color_7'] .social-icon2 i {
  color: #7356f1;
}

[data-primary='color_7'] .social-icon3 ul li a:hover i {
  color: #7356f1;
}

[data-primary='color_7'] .bgl-primary {
  background: #e8e3fd;
  border-color: #e8e3fd;
  color: #7356f1;
}

[data-primary='color_7'] .tdl-holder input[type='checkbox']:checked + i {
  background: #7356f1;
}

[data-primary='color_7'] .footer .copyright a {
  color: #7356f1;
}

[data-primary='color_7'] .hamburger .line {
  background: #7356f1;
}

[data-primary='color_7'] svg.pulse-svg .first-circle,
[data-primary='color_7'] svg.pulse-svg .second-circle,
[data-primary='color_7'] svg.pulse-svg .third-circle {
  fill: #7356f1;
}

[data-primary='color_7'] .pulse-css {
  background: #7356f1;
}

[data-primary='color_7'] .pulse-css:after,
[data-primary='color_7'] .pulse-css:before {
  background-color: #7356f1;
}

[data-primary='color_7']
  .notification_dropdown
  .dropdown-menu-right
  .notification_title {
  background: #7356f1;
}

[data-primary='color_7'] .header-right .header-profile .dropdown-menu a:hover,
[data-primary='color_7'] .header-right .header-profile .dropdown-menu a:focus,
[data-primary='color_7'] .header-right .header-profile .dropdown-menu a.active {
  color: #7356f1;
}

[data-primary='color_7'] .header-right .header-profile .profile_title {
  background: #7356f1;
}

[data-primary='color_7'][data-sidebar-style='full'][data-layout='vertical']
  .menu-toggle
  .nav-header
  .nav-control
  .hamburger
  .line {
  background-color: #7356f1 !important;
}

[data-primary='color_7'] .deznav .metismenu > li > a svg {
  color: #7356f1;
}

[data-primary='color_7'] .deznav .metismenu > li:hover > a,
[data-primary='color_7'] .deznav .metismenu > li:focus > a {
  color: #7356f1;
}

[data-primary='color_7'] .deznav .metismenu > li.mm-active > a {
  color: #7356f1;
}

[data-primary='color_7'] .deznav .metismenu ul a:hover,
[data-primary='color_7'] .deznav .metismenu ul a:focus,
[data-primary='color_7'] .deznav .metismenu ul a.mm-active {
  color: #7356f1;
}

@media (min-width: 767px) {
  [data-primary='color_7']
    [data-sidebar-style='modern']
    .deznav
    .metismenu
    > li
    > a:hover
    > a,
  [data-primary='color_7']
    [data-sidebar-style='modern']
    .deznav
    .metismenu
    > li
    > a:focus
    > a,
  [data-primary='color_7']
    [data-sidebar-style='modern']
    .deznav
    .metismenu
    > li
    > a:active
    > a,
  [data-primary='color_7']
    [data-sidebar-style='modern']
    .deznav
    .metismenu
    > li
    > a.mm-active
    > a {
    background-color: white;
  }
}

[data-primary='color_7'][data-sidebar-style='overlay']
  .nav-header
  .hamburger.is-active
  .line {
  background-color: #7356f1;
}

[data-primary='color_7'] .nav-user {
  background: #7356f1;
}

[data-primary='color_7'] .sidebar-right .sidebar-right .sidebar-right-trigger {
  color: #7356f1;
}

[data-primary='color_7']
  .sidebar-right
  .sidebar-right
  .sidebar-right-trigger:hover {
  color: #7356f1;
}

[data-primary='color_7'][data-theme-version='dark']
  .pagination
  .page-item
  .page-link:hover {
  background: #7356f1;
  border-color: #7356f1;
}

[data-primary='color_7'][data-theme-version='dark']
  .pagination
  .page-item.active
  .page-link {
  background: #7356f1;
  border-color: #7356f1;
}

[data-primary='color_7'][data-theme-version='dark'] .header-left input:focus {
  border-color: #7356f1;
}

[data-primary='color_7'][data-theme-version='dark'] .loader__bar {
  background: #7356f1;
}

[data-primary='color_7'][data-theme-version='dark'] .loader__ball {
  background: #7356f1;
}

[data-primary='color_7'][data-theme-version='transparent']
  .header-left
  input:focus {
  border-color: #7356f1;
}

[data-primary='color_7'] .new-arrival-content .price {
  color: #7356f1;
}

[data-primary='color_7'] .chart-link a i.text-primary {
  color: #7356f1;
}

[data-primary='color_7'] #user-activity .nav-tabs .nav-link.active {
  background: #7356f1;
  border-color: #7356f1;
}

[data-primary='color_7'] span#counter {
  color: #7356f1;
}

[data-primary='color_7'] .welcome-content:after {
  background: #7356f1;
}

[data-primary='color_7'] .timeline-badge {
  background-color: #7356f1;
}

[data-primary='color_7']
  .page-timeline
  .timeline-workplan[data-primary='color_7']
  .page-timeline
  .timeline
  .timeline-badge:after {
  background-color: rgba(115, 86, 241, 0.4);
}

[data-primary='color_7'] .sk-three-bounce .sk-child {
  background-color: #7356f1;
}

[data-primary='color_7'] .dropdown-item.active,
[data-primary='color_7'] .dropdown-item:active {
  color: #fff;
  background-color: #7356f1;
}

[data-primary='color_7'] .overlay-box:after {
  background: #7356f1;
}

[data-primary='color_7'] .btn-primary {
  background-color: #7356f1;
  border-color: #7356f1;
}

[data-primary='color_7'] .bg-primary {
  background-color: #7356f1 !important;
}

[data-primary='color_7'] .text-primary {
  color: #7356f1 !important;
}

[data-primary='color_7'] .btn-primary:hover {
  background-color: #3b13e7;
  border-color: #3b13e7;
}

[data-primary='color_7'] .btn-outline-primary {
  color: #7356f1;
  border-color: #7356f1;
}

[data-primary='color_7'] .btn-outline-primary:hover {
  background-color: #7356f1;
  border-color: #7356f1;
}

[data-primary='color_7'] .btn-primary:not(:disabled):not(.disabled):active,
[data-primary='color_7'] .btn-primary:not(:disabled):not(.disabled).active,
[data-primary='color_7'] .show > .btn-primary.dropdown-toggle,
[data-primary='color_7']
  .btn-outline-primary:not(:disabled):not(.disabled):active,
[data-primary='color_7']
  .btn-outline-primary:not(:disabled):not(.disabled).active,
[data-primary='color_7'] .show > .btn-outline-primary.dropdown-toggle {
  background-color: #3b13e7;
  border-color: #3b13e7;
}

[data-primary='color_8'] a:hover,
[data-primary='color_8'] a:focus,
[data-primary='color_8'] a.active {
  color: #3695eb;
}

[data-primary='color_8'] [data-class='bg-primary']:before {
  background: #3695eb;
}

[data-primary='color_8'] .email-left-box .intro-title {
  background: rgba(54, 149, 235, 0.1);
}

[data-primary='color_8'] .email-left-box .intro-title i {
  color: #3695eb;
}

[data-primary='color_8'] .widget-stat .media .media-body h4 {
  color: #3695eb !important;
}

[data-primary='color_8'] .email-right-box .right-box-border {
  border-right: 2px solid rgba(54, 149, 235, 0.1);
}

[data-primary='color_8'] .mail-list .list-group-item.active i {
  color: #3695eb;
}

[data-primary='color_8'] .single-mail.active {
  background: #3695eb;
}

[data-primary='color_8'] .profile-info h4.text-primary {
  color: #3695eb !important;
}

[data-primary='color_8'] .profile-tab .nav-item .nav-link:hover,
[data-primary='color_8'] .profile-tab .nav-item .nav-link.active {
  border-bottom: 0.2px solid #3695eb;
  color: #3695eb;
}

[data-primary='color_8'] .amChartsInputField {
  border: 0;
  background: #3695eb;
}

[data-primary='color_8'] .amcharts-period-input,
[data-primary='color_8'] .amcharts-period-input-selected {
  background: #3695eb;
}

[data-primary='color_8'] .morris-hover {
  background: #3695eb;
}

[data-primary='color_8']
  .select2-container--default
  .select2-results__option--highlighted[aria-selected] {
  background-color: #3695eb;
}

[data-primary='color_8'] .custom-select:focus {
  border-color: #3695eb;
  color: #3695eb;
}

[data-primary='color_8'] .daterangepicker td.active {
  background-color: #3695eb;
}

[data-primary='color_8'] .daterangepicker td.active:hover {
  background-color: #3695eb;
}

[data-primary='color_8'] .daterangepicker button.applyBtn {
  background-color: #3695eb;
  border-color: #3695eb;
}

[data-primary='color_8'] .wizard > .steps li.current a {
  background-color: #3695eb;
}

[data-primary='color_8'] .wizard .skip-email a {
  color: #3695eb;
}

[data-primary='color_8'] .wizard > .actions li:not(.disabled) a {
  background-color: #3695eb;
}

[data-primary='color_8']
  .step-form-horizontal
  .wizard
  .steps
  li.done
  a
  .number {
  background: #3695eb;
}

[data-primary='color_8']
  .step-form-horizontal
  .wizard
  .steps
  li.current
  a
  .number {
  color: #3695eb;
  border-color: #3695eb;
}

[data-primary='color_8']
  .step-form-horizontal
  .wizard
  .steps
  li.disabled
  a
  .number {
  color: #3695eb;
}

[data-primary='color_8']
  .step-form-horizontal
  .wizard
  .steps
  li:not(:last-child)::after {
  background-color: #3695eb;
}

[data-primary='color_8'] .is-invalid .input-group-prepend .input-group-text i {
  color: #7cbaf2;
}

[data-primary='color_8'] .datamaps-hoverover {
  color: #3695eb;
  border: 1px solid rgba(54, 149, 235, 0.3);
}

[data-primary='color_8'] .jqvmap-zoomin,
[data-primary='color_8'] .jqvmap-zoomout {
  background-color: #3695eb;
}

[data-primary='color_8'] .table .thead-primary th {
  background-color: #3695eb;
}

[data-primary='color_8'] .table.primary-table-bg-hover thead th {
  background-color: #1f89e9;
}

[data-primary='color_8'] .table.primary-table-bg-hover tbody tr {
  background-color: #3695eb;
}

[data-primary='color_8'] .table.primary-table-bg-hover tbody tr:hover {
  background-color: #4da1ed;
}

[data-primary='color_8']
  .table.primary-table-bg-hover
  tbody
  tr:not(:last-child)
  td,
[data-primary='color_8']
  .table.primary-table-bg-hover
  tbody
  tr:not(:last-child)
  th {
  border-bottom: 1px solid #1f89e9;
}

[data-primary='color_8'] table.dataTable tr.selected {
  color: #3695eb;
}

[data-primary='color_8']
  .dataTables_wrapper
  .dataTables_paginate
  .paginate_button.current {
  color: #3695eb !important;
  background: rgba(54, 149, 235, 0.1);
}

[data-primary='color_8']
  .dataTables_wrapper
  .dataTables_paginate
  .paginate_button:hover {
  color: #3695eb !important;
  background: rgba(54, 149, 235, 0.1);
}

[data-primary='color_8'] .clipboard-btn:hover {
  background-color: #3695eb;
}

[data-primary='color_8'] .cd-h-timeline__dates::before {
  background: #3695eb;
}

[data-primary='color_8'] .cd-h-timeline__dates::after {
  background: #3695eb;
}

[data-primary='color_8'] .cd-h-timeline__line {
  background-color: #3695eb;
}

[data-primary='color_8'] .cd-h-timeline__date:after {
  border-color: #3a97eb;
  background-color: #3695eb;
}

[data-primary='color_8'] .cd-h-timeline__navigation {
  border-color: #3a97eb;
}

[data-primary='color_8'] .cd-h-timeline__navigation--inactive:hover {
  border-color: #3a97eb;
}

[data-primary='color_8'] .dd-handle {
  background: #3695eb;
}

[data-primary='color_8'] .dd-handle:hover {
  background: #3695eb;
}

[data-primary='color_8'] .dd3-content:hover {
  background: #3695eb;
}

[data-primary='color_8'] .noUi-connect {
  background-color: #3695eb;
}

[data-primary='color_8'] .noUi-connect.c-3-color {
  background-color: #3695eb;
}

[data-primary='color_8'] .noUi-horizontal .noUi-handle,
[data-primary='color_8'] .noUi-vertical .noUi-handle {
  background-color: #3695eb;
}

[data-primary='color_8'] #slider-toggle.off .noUi-handle {
  border-color: #3695eb;
}

[data-primary='color_8'] .pignose-calendar {
  border-color: #3695eb;
}

[data-primary='color_8'] .pignose-calendar .pignose-calendar-top-date {
  background-color: #3695eb;
}

[data-primary='color_8']
  .pignose-calendar.pignose-calendar-blue
  .pignose-calendar-body
  .pignose-calendar-row
  .pignose-calendar-unit.pignose-calendar-unit-active
  a {
  background-color: #3695eb;
}

[data-primary='color_8'] .bootstrap-tagsinput .tag {
  background-color: #3695eb;
}

[data-primary='color_8'] .toast-success {
  background-color: #3695eb;
}

[data-primary='color_8'] .twitter-typeahead .tt-menu .tt-suggestion:hover {
  background-color: #3695eb;
}

[data-primary='color_8'] .accordion-header-bg .accordion__header--primary {
  background-color: #3695eb;
}

[data-primary='color_8'] .alert-primary {
  background: #aad2f7;
  border-color: #aad2f7;
  color: #3695eb;
}

[data-primary='color_8'] .alert-alt.alert-primary {
  border-left: 4px solid #3695eb;
}

[data-primary='color_8'] .alert-alt.alert-primary.solid {
  border-left: 4px solid #0f5493 !important;
}

[data-primary='color_8'] .alert.alert-primary.solid {
  background: #3695eb;
  border-color: #3695eb;
}

[data-primary='color_8'] .alert.alert-outline-primary {
  color: #3695eb;
  border-color: #3695eb;
}

[data-primary='color_8'] .badge-outline-primary {
  border: 1px solid #3695eb;
  color: #3695eb;
}

[data-primary='color_8'] .badge-primary {
  background-color: #3695eb;
}

[data-primary='color_8'] .page-titles h4 {
  color: #3695eb;
}

[data-primary='color_8'] .card-action > a {
  background: black;
}

[data-primary='color_8'] .card-action .dropdown {
  background: black;
  color: #3695eb;
}

[data-primary='color_8'] .card-action .dropdown:hover,
[data-primary='color_8'] .card-action .dropdown:focus {
  background: black;
}

[data-primary='color_8'] .card-loader i {
  background: #1784e6;
}

[data-primary='color_8'] .dropdown-outline {
  border: 0.1rem solid #3695eb;
}

[data-primary='color_8'] .custom-dropdown .dropdown-menu .dropdown-item:hover {
  color: #3695eb;
}

[data-primary='color_8'] .card-action .custom-dropdown {
  background: #c1def9;
}

[data-primary='color_8'] .card-action .custom-dropdown.show,
[data-primary='color_8'] .card-action .custom-dropdown:focus,
[data-primary='color_8'] .card-action .custom-dropdown:hover {
  background: #3695eb;
}

[data-primary='color_8'] .label-primary {
  background: #3695eb;
}

[data-primary='color_8'] .pagination .page-item .page-link:hover {
  background: #3695eb;
  border-color: #3695eb;
}

[data-primary='color_8'] .pagination .page-item.active .page-link {
  background-color: #3695eb;
  border-color: #3695eb;
}

[data-primary='color_8']
  .bootstrap-popover-wrapper
  .bootstrap-popover
  button:hover,
[data-primary='color_8']
  .bootstrap-popover-wrapper
  .bootstrap-popover
  button:focus {
  background: #3695eb;
}

[data-primary='color_8'] .progress-bar {
  background-color: #3695eb;
}

[data-primary='color_8'] .progress-bar-primary {
  background-color: #3695eb;
}

[data-primary='color_8'] .ribbon__four {
  background-color: #3695eb;
}

[data-primary='color_8'] .ribbon__four:after,
[data-primary='color_8'] .ribbon__four:before {
  background-color: #93c6f4;
}

[data-primary='color_8'] .ribbon__five {
  background-color: #3695eb;
}

[data-primary='color_8'] .ribbon__five::before {
  border-color: transparent transparent #3695eb transparent;
}

[data-primary='color_8'] .ribbon__six {
  background-color: #3695eb;
}

[data-primary='color_8'] .multi-steps > li {
  color: #3695eb;
}

[data-primary='color_8'] .multi-steps > li:after {
  background-color: #3695eb;
}

[data-primary='color_8'] .multi-steps > li.is-active:before {
  border-color: #3695eb;
}

[data-primary='color_8'] .timeline-badge.primary {
  background-color: #3695eb !important;
}

[data-primary='color_8'] .tooltip-wrapper button:hover {
  background: #3695eb;
}

[data-primary='color_8'] .chart_widget_tab_one .nav-link.active {
  background-color: #3695eb;
  border: 1px solid #3695eb;
}

[data-primary='color_8'] .chart_widget_tab_one .nav-link.active:hover {
  border: 1px solid #3695eb;
}

[data-primary='color_8'] .social-icon2 a {
  border: 0.1rem solid #3695eb;
}

[data-primary='color_8'] .social-icon2 i {
  color: #3695eb;
}

[data-primary='color_8'] .social-icon3 ul li a:hover i {
  color: #3695eb;
}

[data-primary='color_8'] .bgl-primary {
  background: #c1def9;
  border-color: #c1def9;
  color: #3695eb;
}

[data-primary='color_8'] .tdl-holder input[type='checkbox']:checked + i {
  background: #3695eb;
}

[data-primary='color_8'] .footer .copyright a {
  color: #3695eb;
}

[data-primary='color_8'] .hamburger .line {
  background: #3695eb;
}

[data-primary='color_8'] svg.pulse-svg .first-circle,
[data-primary='color_8'] svg.pulse-svg .second-circle,
[data-primary='color_8'] svg.pulse-svg .third-circle {
  fill: #3695eb;
}

[data-primary='color_8'] .pulse-css {
  background: #3695eb;
}

[data-primary='color_8'] .pulse-css:after,
[data-primary='color_8'] .pulse-css:before {
  background-color: #3695eb;
}

[data-primary='color_8']
  .notification_dropdown
  .dropdown-menu-right
  .notification_title {
  background: #3695eb;
}

[data-primary='color_8'] .header-right .header-profile .dropdown-menu a:hover,
[data-primary='color_8'] .header-right .header-profile .dropdown-menu a:focus,
[data-primary='color_8'] .header-right .header-profile .dropdown-menu a.active {
  color: #3695eb;
}

[data-primary='color_8'] .header-right .header-profile .profile_title {
  background: #3695eb;
}

[data-primary='color_8'][data-sidebar-style='full'][data-layout='vertical']
  .menu-toggle
  .nav-header
  .nav-control
  .hamburger
  .line {
  background-color: #3695eb !important;
}

[data-primary='color_8'] .deznav .metismenu > li > a svg {
  color: #3695eb;
}

[data-primary='color_8'] .deznav .metismenu > li:hover > a,
[data-primary='color_8'] .deznav .metismenu > li:focus > a {
  color: #3695eb;
}

[data-primary='color_8'] .deznav .metismenu > li.mm-active > a {
  color: #3695eb;
}

[data-primary='color_8'] .deznav .metismenu ul a:hover,
[data-primary='color_8'] .deznav .metismenu ul a:focus,
[data-primary='color_8'] .deznav .metismenu ul a.mm-active {
  color: #3695eb;
}

@media (min-width: 767px) {
  [data-primary='color_8']
    [data-sidebar-style='modern']
    .deznav
    .metismenu
    > li
    > a:hover
    > a,
  [data-primary='color_8']
    [data-sidebar-style='modern']
    .deznav
    .metismenu
    > li
    > a:focus
    > a,
  [data-primary='color_8']
    [data-sidebar-style='modern']
    .deznav
    .metismenu
    > li
    > a:active
    > a,
  [data-primary='color_8']
    [data-sidebar-style='modern']
    .deznav
    .metismenu
    > li
    > a.mm-active
    > a {
    background-color: white;
  }
}

[data-primary='color_8'][data-sidebar-style='overlay']
  .nav-header
  .hamburger.is-active
  .line {
  background-color: #3695eb;
}

[data-primary='color_8'] .nav-user {
  background: #3695eb;
}

[data-primary='color_8'] .sidebar-right .sidebar-right .sidebar-right-trigger {
  color: #3695eb;
}

[data-primary='color_8']
  .sidebar-right
  .sidebar-right
  .sidebar-right-trigger:hover {
  color: #3695eb;
}

[data-primary='color_8'][data-theme-version='dark']
  .pagination
  .page-item
  .page-link:hover {
  background: #3695eb;
  border-color: #3695eb;
}

[data-primary='color_8'][data-theme-version='dark']
  .pagination
  .page-item.active
  .page-link {
  background: #3695eb;
  border-color: #3695eb;
}

[data-primary='color_8'][data-theme-version='dark'] .header-left input:focus {
  border-color: #3695eb;
}

[data-primary='color_8'][data-theme-version='dark'] .loader__bar {
  background: #3695eb;
}

[data-primary='color_8'][data-theme-version='dark'] .loader__ball {
  background: #3695eb;
}

[data-primary='color_8'][data-theme-version='transparent']
  .header-left
  input:focus {
  border-color: #3695eb;
}

[data-primary='color_8'] .new-arrival-content .price {
  color: #3695eb;
}

[data-primary='color_8'] .chart-link a i.text-primary {
  color: #3695eb;
}

[data-primary='color_8'] #user-activity .nav-tabs .nav-link.active {
  background: #3695eb;
  border-color: #3695eb;
}

[data-primary='color_8'] span#counter {
  color: #3695eb;
}

[data-primary='color_8'] .welcome-content:after {
  background: #3695eb;
}

[data-primary='color_8'] .timeline-badge {
  background-color: #3695eb;
}

[data-primary='color_8']
  .page-timeline
  .timeline-workplan[data-primary='color_8']
  .page-timeline
  .timeline
  .timeline-badge:after {
  background-color: rgba(54, 149, 235, 0.4);
}

[data-primary='color_8'] .sk-three-bounce .sk-child {
  background-color: #3695eb;
}

[data-primary='color_8'] .dropdown-item.active,
[data-primary='color_8'] .dropdown-item:active {
  color: #fff;
  background-color: #3695eb;
}

[data-primary='color_8'] .overlay-box:after {
  background: #3695eb;
}

[data-primary='color_8'] .btn-primary {
  background-color: #3695eb;
  border-color: #3695eb;
}

[data-primary='color_8'] .bg-primary {
  background-color: #3695eb !important;
}

[data-primary='color_8'] .text-primary {
  color: #3695eb !important;
}

[data-primary='color_8'] .btn-primary:hover {
  background-color: #136fc1;
  border-color: #136fc1;
}

[data-primary='color_8'] .btn-outline-primary {
  color: #3695eb;
  border-color: #3695eb;
}

[data-primary='color_8'] .btn-outline-primary:hover {
  background-color: #3695eb;
  border-color: #3695eb;
}

[data-primary='color_8'] .btn-primary:not(:disabled):not(.disabled):active,
[data-primary='color_8'] .btn-primary:not(:disabled):not(.disabled).active,
[data-primary='color_8'] .show > .btn-primary.dropdown-toggle,
[data-primary='color_8']
  .btn-outline-primary:not(:disabled):not(.disabled):active,
[data-primary='color_8']
  .btn-outline-primary:not(:disabled):not(.disabled).active,
[data-primary='color_8'] .show > .btn-outline-primary.dropdown-toggle {
  background-color: #136fc1;
  border-color: #136fc1;
}

[data-primary='color_9'] a:hover,
[data-primary='color_9'] a:focus,
[data-primary='color_9'] a.active {
  color: #00838f;
}

[data-primary='color_9'] [data-class='bg-primary']:before {
  background: #00838f;
}

[data-primary='color_9'] .email-left-box .intro-title {
  background: rgba(0, 131, 143, 0.1);
}

[data-primary='color_9'] .email-left-box .intro-title i {
  color: #00838f;
}

[data-primary='color_9'] .widget-stat .media .media-body h4 {
  color: #00838f !important;
}

[data-primary='color_9'] .email-right-box .right-box-border {
  border-right: 2px solid rgba(0, 131, 143, 0.1);
}

[data-primary='color_9'] .mail-list .list-group-item.active i {
  color: #00838f;
}

[data-primary='color_9'] .single-mail.active {
  background: #00838f;
}

[data-primary='color_9'] .profile-info h4.text-primary {
  color: #00838f !important;
}

[data-primary='color_9'] .profile-tab .nav-item .nav-link:hover,
[data-primary='color_9'] .profile-tab .nav-item .nav-link.active {
  border-bottom: 0.2px solid #00838f;
  color: #00838f;
}

[data-primary='color_9'] .amChartsInputField {
  border: 0;
  background: #00838f;
}

[data-primary='color_9'] .amcharts-period-input,
[data-primary='color_9'] .amcharts-period-input-selected {
  background: #00838f;
}

[data-primary='color_9'] .morris-hover {
  background: #00838f;
}

[data-primary='color_9']
  .select2-container--default
  .select2-results__option--highlighted[aria-selected] {
  background-color: #00838f;
}

[data-primary='color_9'] .custom-select:focus {
  border-color: #00838f;
  color: #00838f;
}

[data-primary='color_9'] .daterangepicker td.active {
  background-color: #00838f;
}

[data-primary='color_9'] .daterangepicker td.active:hover {
  background-color: #00838f;
}

[data-primary='color_9'] .daterangepicker button.applyBtn {
  background-color: #00838f;
  border-color: #00838f;
}

[data-primary='color_9'] .wizard > .steps li.current a {
  background-color: #00838f;
}

[data-primary='color_9'] .wizard .skip-email a {
  color: #00838f;
}

[data-primary='color_9'] .wizard > .actions li:not(.disabled) a {
  background-color: #00838f;
}

[data-primary='color_9']
  .step-form-horizontal
  .wizard
  .steps
  li.done
  a
  .number {
  background: #00838f;
}

[data-primary='color_9']
  .step-form-horizontal
  .wizard
  .steps
  li.current
  a
  .number {
  color: #00838f;
  border-color: #00838f;
}

[data-primary='color_9']
  .step-form-horizontal
  .wizard
  .steps
  li.disabled
  a
  .number {
  color: #00838f;
}

[data-primary='color_9']
  .step-form-horizontal
  .wizard
  .steps
  li:not(:last-child)::after {
  background-color: #00838f;
}

[data-primary='color_9'] .is-invalid .input-group-prepend .input-group-text i {
  color: #00c9dc;
}

[data-primary='color_9'] .datamaps-hoverover {
  color: #00838f;
  border: 1px solid rgba(0, 131, 143, 0.3);
}

[data-primary='color_9'] .jqvmap-zoomin,
[data-primary='color_9'] .jqvmap-zoomout {
  background-color: #00838f;
}

[data-primary='color_9'] .table .thead-primary th {
  background-color: #00838f;
}

[data-primary='color_9'] .table.primary-table-bg-hover thead th {
  background-color: #006c76;
}

[data-primary='color_9'] .table.primary-table-bg-hover tbody tr {
  background-color: #00838f;
}

[data-primary='color_9'] .table.primary-table-bg-hover tbody tr:hover {
  background-color: #009aa9;
}

[data-primary='color_9']
  .table.primary-table-bg-hover
  tbody
  tr:not(:last-child)
  td,
[data-primary='color_9']
  .table.primary-table-bg-hover
  tbody
  tr:not(:last-child)
  th {
  border-bottom: 1px solid #006c76;
}

[data-primary='color_9'] table.dataTable tr.selected {
  color: #00838f;
}

[data-primary='color_9']
  .dataTables_wrapper
  .dataTables_paginate
  .paginate_button.current {
  color: #00838f !important;
  background: rgba(0, 131, 143, 0.1);
}

[data-primary='color_9']
  .dataTables_wrapper
  .dataTables_paginate
  .paginate_button:hover {
  color: #00838f !important;
  background: rgba(0, 131, 143, 0.1);
}

[data-primary='color_9'] .clipboard-btn:hover {
  background-color: #00838f;
}

[data-primary='color_9'] .cd-h-timeline__dates::before {
  background: #00838f;
}

[data-primary='color_9'] .cd-h-timeline__dates::after {
  background: #00838f;
}

[data-primary='color_9'] .cd-h-timeline__line {
  background-color: #00838f;
}

[data-primary='color_9'] .cd-h-timeline__date:after {
  border-color: #008794;
  background-color: #00838f;
}

[data-primary='color_9'] .cd-h-timeline__navigation {
  border-color: #008794;
}

[data-primary='color_9'] .cd-h-timeline__navigation--inactive:hover {
  border-color: #008794;
}

[data-primary='color_9'] .dd-handle {
  background: #00838f;
}

[data-primary='color_9'] .dd-handle:hover {
  background: #00838f;
}

[data-primary='color_9'] .dd3-content:hover {
  background: #00838f;
}

[data-primary='color_9'] .noUi-connect {
  background-color: #00838f;
}

[data-primary='color_9'] .noUi-connect.c-3-color {
  background-color: #00838f;
}

[data-primary='color_9'] .noUi-horizontal .noUi-handle,
[data-primary='color_9'] .noUi-vertical .noUi-handle {
  background-color: #00838f;
}

[data-primary='color_9'] #slider-toggle.off .noUi-handle {
  border-color: #00838f;
}

[data-primary='color_9'] .pignose-calendar {
  border-color: #00838f;
}

[data-primary='color_9'] .pignose-calendar .pignose-calendar-top-date {
  background-color: #00838f;
}

[data-primary='color_9']
  .pignose-calendar.pignose-calendar-blue
  .pignose-calendar-body
  .pignose-calendar-row
  .pignose-calendar-unit.pignose-calendar-unit-active
  a {
  background-color: #00838f;
}

[data-primary='color_9'] .bootstrap-tagsinput .tag {
  background-color: #00838f;
}

[data-primary='color_9'] .toast-success {
  background-color: #00838f;
}

[data-primary='color_9'] .twitter-typeahead .tt-menu .tt-suggestion:hover {
  background-color: #00838f;
}

[data-primary='color_9'] .accordion-header-bg .accordion__header--primary {
  background-color: #00838f;
}

[data-primary='color_9'] .alert-primary {
  background: #10ebff;
  border-color: #10ebff;
  color: #00838f;
}

[data-primary='color_9'] .alert-alt.alert-primary {
  border-left: 4px solid #00838f;
}

[data-primary='color_9'] .alert-alt.alert-primary.solid {
  border-left: 4px solid #000e10 !important;
}

[data-primary='color_9'] .alert.alert-primary.solid {
  background: #00838f;
  border-color: #00838f;
}

[data-primary='color_9'] .alert.alert-outline-primary {
  color: #00838f;
  border-color: #00838f;
}

[data-primary='color_9'] .badge-outline-primary {
  border: 1px solid #00838f;
  color: #00838f;
}

[data-primary='color_9'] .badge-primary {
  background-color: #00838f;
}

[data-primary='color_9'] .page-titles h4 {
  color: #00838f;
}

[data-primary='color_9'] .card-action > a {
  background: black;
}

[data-primary='color_9'] .card-action .dropdown {
  background: black;
  color: #00838f;
}

[data-primary='color_9'] .card-action .dropdown:hover,
[data-primary='color_9'] .card-action .dropdown:focus {
  background: black;
}

[data-primary='color_9'] .card-loader i {
  background: #00626b;
}

[data-primary='color_9'] .dropdown-outline {
  border: 0.1rem solid #00838f;
}

[data-primary='color_9'] .custom-dropdown .dropdown-menu .dropdown-item:hover {
  color: #00838f;
}

[data-primary='color_9'] .card-action .custom-dropdown {
  background: #29edff;
}

[data-primary='color_9'] .card-action .custom-dropdown.show,
[data-primary='color_9'] .card-action .custom-dropdown:focus,
[data-primary='color_9'] .card-action .custom-dropdown:hover {
  background: #00838f;
}

[data-primary='color_9'] .label-primary {
  background: #00838f;
}

[data-primary='color_9'] .pagination .page-item .page-link:hover {
  background: #00838f;
  border-color: #00838f;
}

[data-primary='color_9'] .pagination .page-item.active .page-link {
  background-color: #00838f;
  border-color: #00838f;
}

[data-primary='color_9']
  .bootstrap-popover-wrapper
  .bootstrap-popover
  button:hover,
[data-primary='color_9']
  .bootstrap-popover-wrapper
  .bootstrap-popover
  button:focus {
  background: #00838f;
}

[data-primary='color_9'] .progress-bar {
  background-color: #00838f;
}

[data-primary='color_9'] .progress-bar-primary {
  background-color: #00838f;
}

[data-primary='color_9'] .ribbon__four {
  background-color: #00838f;
}

[data-primary='color_9'] .ribbon__four:after,
[data-primary='color_9'] .ribbon__four:before {
  background-color: #00e0f5;
}

[data-primary='color_9'] .ribbon__five {
  background-color: #00838f;
}

[data-primary='color_9'] .ribbon__five::before {
  border-color: transparent transparent #00838f transparent;
}

[data-primary='color_9'] .ribbon__six {
  background-color: #00838f;
}

[data-primary='color_9'] .multi-steps > li {
  color: #00838f;
}

[data-primary='color_9'] .multi-steps > li:after {
  background-color: #00838f;
}

[data-primary='color_9'] .multi-steps > li.is-active:before {
  border-color: #00838f;
}

[data-primary='color_9'] .timeline-badge.primary {
  background-color: #00838f !important;
}

[data-primary='color_9'] .tooltip-wrapper button:hover {
  background: #00838f;
}

[data-primary='color_9'] .chart_widget_tab_one .nav-link.active {
  background-color: #00838f;
  border: 1px solid #00838f;
}

[data-primary='color_9'] .chart_widget_tab_one .nav-link.active:hover {
  border: 1px solid #00838f;
}

[data-primary='color_9'] .social-icon2 a {
  border: 0.1rem solid #00838f;
}

[data-primary='color_9'] .social-icon2 i {
  color: #00838f;
}

[data-primary='color_9'] .social-icon3 ul li a:hover i {
  color: #00838f;
}

[data-primary='color_9'] .bgl-primary {
  background: #29edff;
  border-color: #29edff;
  color: #00838f;
}

[data-primary='color_9'] .tdl-holder input[type='checkbox']:checked + i {
  background: #00838f;
}

[data-primary='color_9'] .footer .copyright a {
  color: #00838f;
}

[data-primary='color_9'] .hamburger .line {
  background: #00838f;
}

[data-primary='color_9'] svg.pulse-svg .first-circle,
[data-primary='color_9'] svg.pulse-svg .second-circle,
[data-primary='color_9'] svg.pulse-svg .third-circle {
  fill: #00838f;
}

[data-primary='color_9'] .pulse-css {
  background: #00838f;
}

[data-primary='color_9'] .pulse-css:after,
[data-primary='color_9'] .pulse-css:before {
  background-color: #00838f;
}

[data-primary='color_9']
  .notification_dropdown
  .dropdown-menu-right
  .notification_title {
  background: #00838f;
}

[data-primary='color_9'] .header-right .header-profile .dropdown-menu a:hover,
[data-primary='color_9'] .header-right .header-profile .dropdown-menu a:focus,
[data-primary='color_9'] .header-right .header-profile .dropdown-menu a.active {
  color: #00838f;
}

[data-primary='color_9'] .header-right .header-profile .profile_title {
  background: #00838f;
}

[data-primary='color_9'][data-sidebar-style='full'][data-layout='vertical']
  .menu-toggle
  .nav-header
  .nav-control
  .hamburger
  .line {
  background-color: #00838f !important;
}

[data-primary='color_9'] .deznav .metismenu > li > a svg {
  color: #00838f;
}

[data-primary='color_9'] .deznav .metismenu > li:hover > a,
[data-primary='color_9'] .deznav .metismenu > li:focus > a {
  color: #00838f;
}

[data-primary='color_9'] .deznav .metismenu > li.mm-active > a {
  color: #00838f;
}

[data-primary='color_9'] .deznav .metismenu ul a:hover,
[data-primary='color_9'] .deznav .metismenu ul a:focus,
[data-primary='color_9'] .deznav .metismenu ul a.mm-active {
  color: #00838f;
}

@media (min-width: 767px) {
  [data-primary='color_9']
    [data-sidebar-style='modern']
    .deznav
    .metismenu
    > li
    > a:hover
    > a,
  [data-primary='color_9']
    [data-sidebar-style='modern']
    .deznav
    .metismenu
    > li
    > a:focus
    > a,
  [data-primary='color_9']
    [data-sidebar-style='modern']
    .deznav
    .metismenu
    > li
    > a:active
    > a,
  [data-primary='color_9']
    [data-sidebar-style='modern']
    .deznav
    .metismenu
    > li
    > a.mm-active
    > a {
    background-color: #8ff6ff;
  }
}

[data-primary='color_9'][data-sidebar-style='overlay']
  .nav-header
  .hamburger.is-active
  .line {
  background-color: #00838f;
}

[data-primary='color_9'] .nav-user {
  background: #00838f;
}

[data-primary='color_9'] .sidebar-right .sidebar-right .sidebar-right-trigger {
  color: #00838f;
}

[data-primary='color_9']
  .sidebar-right
  .sidebar-right
  .sidebar-right-trigger:hover {
  color: #00838f;
}

[data-primary='color_9'][data-theme-version='dark']
  .pagination
  .page-item
  .page-link:hover {
  background: #00838f;
  border-color: #00838f;
}

[data-primary='color_9'][data-theme-version='dark']
  .pagination
  .page-item.active
  .page-link {
  background: #00838f;
  border-color: #00838f;
}

[data-primary='color_9'][data-theme-version='dark'] .header-left input:focus {
  border-color: #00838f;
}

[data-primary='color_9'][data-theme-version='dark'] .loader__bar {
  background: #00838f;
}

[data-primary='color_9'][data-theme-version='dark'] .loader__ball {
  background: #00838f;
}

[data-primary='color_9'][data-theme-version='transparent']
  .header-left
  input:focus {
  border-color: #00838f;
}

[data-primary='color_9'] .new-arrival-content .price {
  color: #00838f;
}

[data-primary='color_9'] .chart-link a i.text-primary {
  color: #00838f;
}

[data-primary='color_9'] #user-activity .nav-tabs .nav-link.active {
  background: #00838f;
  border-color: #00838f;
}

[data-primary='color_9'] span#counter {
  color: #00838f;
}

[data-primary='color_9'] .welcome-content:after {
  background: #00838f;
}

[data-primary='color_9'] .timeline-badge {
  background-color: #00838f;
}

[data-primary='color_9']
  .page-timeline
  .timeline-workplan[data-primary='color_9']
  .page-timeline
  .timeline
  .timeline-badge:after {
  background-color: rgba(0, 131, 143, 0.4);
}

[data-primary='color_9'] .sk-three-bounce .sk-child {
  background-color: #00838f;
}

[data-primary='color_9'] .dropdown-item.active,
[data-primary='color_9'] .dropdown-item:active {
  color: #fff;
  background-color: #00838f;
}

[data-primary='color_9'] .overlay-box:after {
  background: #00838f;
}

[data-primary='color_9'] .btn-primary {
  background-color: #00838f;
  border-color: #00838f;
}

[data-primary='color_9'] .bg-primary {
  background-color: #00838f !important;
}

[data-primary='color_9'] .text-primary {
  color: #00838f !important;
}

[data-primary='color_9'] .btn-primary:hover {
  background-color: #003d43;
  border-color: #003d43;
}

[data-primary='color_9'] .btn-outline-primary {
  color: #00838f;
  border-color: #00838f;
}

[data-primary='color_9'] .btn-outline-primary:hover {
  background-color: #00838f;
  border-color: #00838f;
}

[data-primary='color_9'] .btn-primary:not(:disabled):not(.disabled):active,
[data-primary='color_9'] .btn-primary:not(:disabled):not(.disabled).active,
[data-primary='color_9'] .show > .btn-primary.dropdown-toggle,
[data-primary='color_9']
  .btn-outline-primary:not(:disabled):not(.disabled):active,
[data-primary='color_9']
  .btn-outline-primary:not(:disabled):not(.disabled).active,
[data-primary='color_9'] .show > .btn-outline-primary.dropdown-toggle {
  background-color: #003d43;
  border-color: #003d43;
}

[data-primary='color_10'] a:hover,
[data-primary='color_10'] a:focus,
[data-primary='color_10'] a.active {
  color: #ff8f16;
}

[data-primary='color_10'] [data-class='bg-primary']:before {
  background: #ff8f16;
}

[data-primary='color_10'] .email-left-box .intro-title {
  background: rgba(255, 143, 22, 0.1);
}

[data-primary='color_10'] .email-left-box .intro-title i {
  color: #ff8f16;
}

[data-primary='color_10'] .widget-stat .media .media-body h4 {
  color: #ff8f16 !important;
}

[data-primary='color_10'] .email-right-box .right-box-border {
  border-right: 2px solid rgba(255, 143, 22, 0.1);
}

[data-primary='color_10'] .mail-list .list-group-item.active i {
  color: #ff8f16;
}

[data-primary='color_10'] .single-mail.active {
  background: #ff8f16;
}

[data-primary='color_10'] .profile-info h4.text-primary {
  color: #ff8f16 !important;
}

[data-primary='color_10'] .profile-tab .nav-item .nav-link:hover,
[data-primary='color_10'] .profile-tab .nav-item .nav-link.active {
  border-bottom: 0.2px solid #ff8f16;
  color: #ff8f16;
}

[data-primary='color_10'] .amChartsInputField {
  border: 0;
  background: #ff8f16;
}

[data-primary='color_10'] .amcharts-period-input,
[data-primary='color_10'] .amcharts-period-input-selected {
  background: #ff8f16;
}

[data-primary='color_10'] .morris-hover {
  background: #ff8f16;
}

[data-primary='color_10']
  .select2-container--default
  .select2-results__option--highlighted[aria-selected] {
  background-color: #ff8f16;
}

[data-primary='color_10'] .custom-select:focus {
  border-color: #ff8f16;
  color: #ff8f16;
}

[data-primary='color_10'] .daterangepicker td.active {
  background-color: #ff8f16;
}

[data-primary='color_10'] .daterangepicker td.active:hover {
  background-color: #ff8f16;
}

[data-primary='color_10'] .daterangepicker button.applyBtn {
  background-color: #ff8f16;
  border-color: #ff8f16;
}

[data-primary='color_10'] .wizard > .steps li.current a {
  background-color: #ff8f16;
}

[data-primary='color_10'] .wizard .skip-email a {
  color: #ff8f16;
}

[data-primary='color_10'] .wizard > .actions li:not(.disabled) a {
  background-color: #ff8f16;
}

[data-primary='color_10']
  .step-form-horizontal
  .wizard
  .steps
  li.done
  a
  .number {
  background: #ff8f16;
}

[data-primary='color_10']
  .step-form-horizontal
  .wizard
  .steps
  li.current
  a
  .number {
  color: #ff8f16;
  border-color: #ff8f16;
}

[data-primary='color_10']
  .step-form-horizontal
  .wizard
  .steps
  li.disabled
  a
  .number {
  color: #ff8f16;
}

[data-primary='color_10']
  .step-form-horizontal
  .wizard
  .steps
  li:not(:last-child)::after {
  background-color: #ff8f16;
}

[data-primary='color_10'] .is-invalid .input-group-prepend .input-group-text i {
  color: #ffb463;
}

[data-primary='color_10'] .datamaps-hoverover {
  color: #ff8f16;
  border: 1px solid rgba(255, 143, 22, 0.3);
}

[data-primary='color_10'] .jqvmap-zoomin,
[data-primary='color_10'] .jqvmap-zoomout {
  background-color: #ff8f16;
}

[data-primary='color_10'] .table .thead-primary th {
  background-color: #ff8f16;
}

[data-primary='color_10'] .table.primary-table-bg-hover thead th {
  background-color: #fc8300;
}

[data-primary='color_10'] .table.primary-table-bg-hover tbody tr {
  background-color: #ff8f16;
}

[data-primary='color_10'] .table.primary-table-bg-hover tbody tr:hover {
  background-color: #ff9b30;
}

[data-primary='color_10']
  .table.primary-table-bg-hover
  tbody
  tr:not(:last-child)
  td,
[data-primary='color_10']
  .table.primary-table-bg-hover
  tbody
  tr:not(:last-child)
  th {
  border-bottom: 1px solid #fc8300;
}

[data-primary='color_10'] table.dataTable tr.selected {
  color: #ff8f16;
}

[data-primary='color_10']
  .dataTables_wrapper
  .dataTables_paginate
  .paginate_button.current {
  color: #ff8f16 !important;
  background: rgba(255, 143, 22, 0.1);
}

[data-primary='color_10']
  .dataTables_wrapper
  .dataTables_paginate
  .paginate_button:hover {
  color: #ff8f16 !important;
  background: rgba(255, 143, 22, 0.1);
}

[data-primary='color_10'] .clipboard-btn:hover {
  background-color: #ff8f16;
}

[data-primary='color_10'] .cd-h-timeline__dates::before {
  background: #ff8f16;
}

[data-primary='color_10'] .cd-h-timeline__dates::after {
  background: #ff8f16;
}

[data-primary='color_10'] .cd-h-timeline__line {
  background-color: #ff8f16;
}

[data-primary='color_10'] .cd-h-timeline__date:after {
  border-color: #ff911b;
  background-color: #ff8f16;
}

[data-primary='color_10'] .cd-h-timeline__navigation {
  border-color: #ff911b;
}

[data-primary='color_10'] .cd-h-timeline__navigation--inactive:hover {
  border-color: #ff911b;
}

[data-primary='color_10'] .dd-handle {
  background: #ff8f16;
}

[data-primary='color_10'] .dd-handle:hover {
  background: #ff8f16;
}

[data-primary='color_10'] .dd3-content:hover {
  background: #ff8f16;
}

[data-primary='color_10'] .noUi-connect {
  background-color: #ff8f16;
}

[data-primary='color_10'] .noUi-connect.c-3-color {
  background-color: #ff8f16;
}

[data-primary='color_10'] .noUi-horizontal .noUi-handle,
[data-primary='color_10'] .noUi-vertical .noUi-handle {
  background-color: #ff8f16;
}

[data-primary='color_10'] #slider-toggle.off .noUi-handle {
  border-color: #ff8f16;
}

[data-primary='color_10'] .pignose-calendar {
  border-color: #ff8f16;
}

[data-primary='color_10'] .pignose-calendar .pignose-calendar-top-date {
  background-color: #ff8f16;
}

[data-primary='color_10']
  .pignose-calendar.pignose-calendar-blue
  .pignose-calendar-body
  .pignose-calendar-row
  .pignose-calendar-unit.pignose-calendar-unit-active
  a {
  background-color: #ff8f16;
}

[data-primary='color_10'] .bootstrap-tagsinput .tag {
  background-color: #ff8f16;
}

[data-primary='color_10'] .toast-success {
  background-color: #ff8f16;
}

[data-primary='color_10'] .twitter-typeahead .tt-menu .tt-suggestion:hover {
  background-color: #ff8f16;
}

[data-primary='color_10'] .accordion-header-bg .accordion__header--primary {
  background-color: #ff8f16;
}

[data-primary='color_10'] .alert-primary {
  background: #ffcc96;
  border-color: #ffcc96;
  color: #ff8f16;
}

[data-primary='color_10'] .alert-alt.alert-primary {
  border-left: 4px solid #ff8f16;
}

[data-primary='color_10'] .alert-alt.alert-primary.solid {
  border-left: 4px solid #964e00 !important;
}

[data-primary='color_10'] .alert.alert-primary.solid {
  background: #ff8f16;
  border-color: #ff8f16;
}

[data-primary='color_10'] .alert.alert-outline-primary {
  color: #ff8f16;
  border-color: #ff8f16;
}

[data-primary='color_10'] .badge-outline-primary {
  border: 1px solid #ff8f16;
  color: #ff8f16;
}

[data-primary='color_10'] .badge-primary {
  background-color: #ff8f16;
}

[data-primary='color_10'] .page-titles h4 {
  color: #ff8f16;
}

[data-primary='color_10'] .card-action > a {
  background: black;
}

[data-primary='color_10'] .card-action .dropdown {
  background: black;
  color: #ff8f16;
}

[data-primary='color_10'] .card-action .dropdown:hover,
[data-primary='color_10'] .card-action .dropdown:focus {
  background: black;
}

[data-primary='color_10'] .card-loader i {
  background: #f17d00;
}

[data-primary='color_10'] .dropdown-outline {
  border: 0.1rem solid #ff8f16;
}

[data-primary='color_10'] .custom-dropdown .dropdown-menu .dropdown-item:hover {
  color: #ff8f16;
}

[data-primary='color_10'] .card-action .custom-dropdown {
  background: #ffd9af;
}

[data-primary='color_10'] .card-action .custom-dropdown.show,
[data-primary='color_10'] .card-action .custom-dropdown:focus,
[data-primary='color_10'] .card-action .custom-dropdown:hover {
  background: #ff8f16;
}

[data-primary='color_10'] .label-primary {
  background: #ff8f16;
}

[data-primary='color_10'] .pagination .page-item .page-link:hover {
  background: #ff8f16;
  border-color: #ff8f16;
}

[data-primary='color_10'] .pagination .page-item.active .page-link {
  background-color: #ff8f16;
  border-color: #ff8f16;
}

[data-primary='color_10']
  .bootstrap-popover-wrapper
  .bootstrap-popover
  button:hover,
[data-primary='color_10']
  .bootstrap-popover-wrapper
  .bootstrap-popover
  button:focus {
  background: #ff8f16;
}

[data-primary='color_10'] .progress-bar {
  background-color: #ff8f16;
}

[data-primary='color_10'] .progress-bar-primary {
  background-color: #ff8f16;
}

[data-primary='color_10'] .ribbon__four {
  background-color: #ff8f16;
}

[data-primary='color_10'] .ribbon__four:after,
[data-primary='color_10'] .ribbon__four:before {
  background-color: #ffc07c;
}

[data-primary='color_10'] .ribbon__five {
  background-color: #ff8f16;
}

[data-primary='color_10'] .ribbon__five::before {
  border-color: transparent transparent #ff8f16 transparent;
}

[data-primary='color_10'] .ribbon__six {
  background-color: #ff8f16;
}

[data-primary='color_10'] .multi-steps > li {
  color: #ff8f16;
}

[data-primary='color_10'] .multi-steps > li:after {
  background-color: #ff8f16;
}

[data-primary='color_10'] .multi-steps > li.is-active:before {
  border-color: #ff8f16;
}

[data-primary='color_10'] .timeline-badge.primary {
  background-color: #ff8f16 !important;
}

[data-primary='color_10'] .tooltip-wrapper button:hover {
  background: #ff8f16;
}

[data-primary='color_10'] .chart_widget_tab_one .nav-link.active {
  background-color: #ff8f16;
  border: 1px solid #ff8f16;
}

[data-primary='color_10'] .chart_widget_tab_one .nav-link.active:hover {
  border: 1px solid #ff8f16;
}

[data-primary='color_10'] .social-icon2 a {
  border: 0.1rem solid #ff8f16;
}

[data-primary='color_10'] .social-icon2 i {
  color: #ff8f16;
}

[data-primary='color_10'] .social-icon3 ul li a:hover i {
  color: #ff8f16;
}

[data-primary='color_10'] .bgl-primary {
  background: #ffd9af;
  border-color: #ffd9af;
  color: #ff8f16;
}

[data-primary='color_10'] .tdl-holder input[type='checkbox']:checked + i {
  background: #ff8f16;
}

[data-primary='color_10'] .footer .copyright a {
  color: #ff8f16;
}

[data-primary='color_10'] .hamburger .line {
  background: #ff8f16;
}

[data-primary='color_10'] svg.pulse-svg .first-circle,
[data-primary='color_10'] svg.pulse-svg .second-circle,
[data-primary='color_10'] svg.pulse-svg .third-circle {
  fill: #ff8f16;
}

[data-primary='color_10'] .pulse-css {
  background: #ff8f16;
}

[data-primary='color_10'] .pulse-css:after,
[data-primary='color_10'] .pulse-css:before {
  background-color: #ff8f16;
}

[data-primary='color_10']
  .notification_dropdown
  .dropdown-menu-right
  .notification_title {
  background: #ff8f16;
}

[data-primary='color_10'] .header-right .header-profile .dropdown-menu a:hover,
[data-primary='color_10'] .header-right .header-profile .dropdown-menu a:focus,
[data-primary='color_10']
  .header-right
  .header-profile
  .dropdown-menu
  a.active {
  color: #ff8f16;
}

[data-primary='color_10'] .header-right .header-profile .profile_title {
  background: #ff8f16;
}

[data-primary='color_10'][data-sidebar-style='full'][data-layout='vertical']
  .menu-toggle
  .nav-header
  .nav-control
  .hamburger
  .line {
  background-color: #ff8f16 !important;
}

[data-primary='color_10'] .deznav .metismenu > li > a svg {
  color: #ff8f16;
}

[data-primary='color_10'] .deznav .metismenu > li:hover > a,
[data-primary='color_10'] .deznav .metismenu > li:focus > a {
  color: #ff8f16;
}

[data-primary='color_10'] .deznav .metismenu > li.mm-active > a {
  color: #ff8f16;
}

[data-primary='color_10'] .deznav .metismenu ul a:hover,
[data-primary='color_10'] .deznav .metismenu ul a:focus,
[data-primary='color_10'] .deznav .metismenu ul a.mm-active {
  color: #ff8f16;
}

@media (min-width: 767px) {
  [data-primary='color_10']
    [data-sidebar-style='modern']
    .deznav
    .metismenu
    > li
    > a:hover
    > a,
  [data-primary='color_10']
    [data-sidebar-style='modern']
    .deznav
    .metismenu
    > li
    > a:focus
    > a,
  [data-primary='color_10']
    [data-sidebar-style='modern']
    .deznav
    .metismenu
    > li
    > a:active
    > a,
  [data-primary='color_10']
    [data-sidebar-style='modern']
    .deznav
    .metismenu
    > li
    > a.mm-active
    > a {
    background-color: white;
  }
}

[data-primary='color_10'][data-sidebar-style='overlay']
  .nav-header
  .hamburger.is-active
  .line {
  background-color: #ff8f16;
}

[data-primary='color_10'] .nav-user {
  background: #ff8f16;
}

[data-primary='color_10'] .sidebar-right .sidebar-right .sidebar-right-trigger {
  color: #ff8f16;
}

[data-primary='color_10']
  .sidebar-right
  .sidebar-right
  .sidebar-right-trigger:hover {
  color: #ff8f16;
}

[data-primary='color_10'][data-theme-version='dark']
  .pagination
  .page-item
  .page-link:hover {
  background: #ff8f16;
  border-color: #ff8f16;
}

[data-primary='color_10'][data-theme-version='dark']
  .pagination
  .page-item.active
  .page-link {
  background: #ff8f16;
  border-color: #ff8f16;
}

[data-primary='color_10'][data-theme-version='dark'] .header-left input:focus {
  border-color: #ff8f16;
}

[data-primary='color_10'][data-theme-version='dark'] .loader__bar {
  background: #ff8f16;
}

[data-primary='color_10'][data-theme-version='dark'] .loader__ball {
  background: #ff8f16;
}

[data-primary='color_10'][data-theme-version='transparent']
  .header-left
  input:focus {
  border-color: #ff8f16;
}

[data-primary='color_10'] .new-arrival-content .price {
  color: #ff8f16;
}

[data-primary='color_10'] .chart-link a i.text-primary {
  color: #ff8f16;
}

[data-primary='color_10'] #user-activity .nav-tabs .nav-link.active {
  background: #ff8f16;
  border-color: #ff8f16;
}

[data-primary='color_10'] span#counter {
  color: #ff8f16;
}

[data-primary='color_10'] .welcome-content:after {
  background: #ff8f16;
}

[data-primary='color_10'] .timeline-badge {
  background-color: #ff8f16;
}

[data-primary='color_10']
  .page-timeline
  .timeline-workplan[data-primary='color_10']
  .page-timeline
  .timeline
  .timeline-badge:after {
  background-color: rgba(255, 143, 22, 0.4);
}

[data-primary='color_10'] .sk-three-bounce .sk-child {
  background-color: #ff8f16;
}

[data-primary='color_10'] .dropdown-item.active,
[data-primary='color_10'] .dropdown-item:active {
  color: #fff;
  background-color: #ff8f16;
}

[data-primary='color_10'] .overlay-box:after {
  background: #ff8f16;
}

[data-primary='color_10'] .btn-primary {
  background-color: #ff8f16;
  border-color: #ff8f16;
}

[data-primary='color_10'] .bg-primary {
  background-color: #ff8f16 !important;
}

[data-primary='color_10'] .text-primary {
  color: #ff8f16 !important;
}

[data-primary='color_10'] .btn-primary:hover {
  background-color: #c96800;
  border-color: #c96800;
}

[data-primary='color_10'] .btn-outline-primary {
  color: #ff8f16;
  border-color: #ff8f16;
}

[data-primary='color_10'] .btn-outline-primary:hover {
  background-color: #ff8f16;
  border-color: #ff8f16;
}

[data-primary='color_10'] .btn-primary:not(:disabled):not(.disabled):active,
[data-primary='color_10'] .btn-primary:not(:disabled):not(.disabled).active,
[data-primary='color_10'] .show > .btn-primary.dropdown-toggle,
[data-primary='color_10']
  .btn-outline-primary:not(:disabled):not(.disabled):active,
[data-primary='color_10']
  .btn-outline-primary:not(:disabled):not(.disabled).active,
[data-primary='color_10'] .show > .btn-outline-primary.dropdown-toggle {
  background-color: #c96800;
  border-color: #c96800;
}

[data-primary='color_11'] a:hover,
[data-primary='color_11'] a:focus,
[data-primary='color_11'] a.active {
  color: #6673fd;
}

[data-primary='color_11'] [data-class='bg-primary']:before {
  background: #6673fd;
}

[data-primary='color_11'] .email-left-box .intro-title {
  background: rgba(102, 115, 253, 0.1);
}

[data-primary='color_11'] .email-left-box .intro-title i {
  color: #6673fd;
}

[data-primary='color_11'] .widget-stat .media .media-body h4 {
  color: #6673fd !important;
}

[data-primary='color_11'] .email-right-box .right-box-border {
  border-right: 2px solid rgba(102, 115, 253, 0.1);
}

[data-primary='color_11'] .mail-list .list-group-item.active i {
  color: #6673fd;
}

[data-primary='color_11'] .single-mail.active {
  background: #6673fd;
}

[data-primary='color_11'] .profile-info h4.text-primary {
  color: #6673fd !important;
}

[data-primary='color_11'] .profile-tab .nav-item .nav-link:hover,
[data-primary='color_11'] .profile-tab .nav-item .nav-link.active {
  border-bottom: 0.2px solid #6673fd;
  color: #6673fd;
}

[data-primary='color_11'] .amChartsInputField {
  border: 0;
  background: #6673fd;
}

[data-primary='color_11'] .amcharts-period-input,
[data-primary='color_11'] .amcharts-period-input-selected {
  background: #6673fd;
}

[data-primary='color_11'] .morris-hover {
  background: #6673fd;
}

[data-primary='color_11']
  .select2-container--default
  .select2-results__option--highlighted[aria-selected] {
  background-color: #6673fd;
}

[data-primary='color_11'] .custom-select:focus {
  border-color: #6673fd;
  color: #6673fd;
}

[data-primary='color_11'] .daterangepicker td.active {
  background-color: #6673fd;
}

[data-primary='color_11'] .daterangepicker td.active:hover {
  background-color: #6673fd;
}

[data-primary='color_11'] .daterangepicker button.applyBtn {
  background-color: #6673fd;
  border-color: #6673fd;
}

[data-primary='color_11'] .wizard > .steps li.current a {
  background-color: #6673fd;
}

[data-primary='color_11'] .wizard .skip-email a {
  color: #6673fd;
}

[data-primary='color_11'] .wizard > .actions li:not(.disabled) a {
  background-color: #6673fd;
}

[data-primary='color_11']
  .step-form-horizontal
  .wizard
  .steps
  li.done
  a
  .number {
  background: #6673fd;
}

[data-primary='color_11']
  .step-form-horizontal
  .wizard
  .steps
  li.current
  a
  .number {
  color: #6673fd;
  border-color: #6673fd;
}

[data-primary='color_11']
  .step-form-horizontal
  .wizard
  .steps
  li.disabled
  a
  .number {
  color: #6673fd;
}

[data-primary='color_11']
  .step-form-horizontal
  .wizard
  .steps
  li:not(:last-child)::after {
  background-color: #6673fd;
}

[data-primary='color_11'] .is-invalid .input-group-prepend .input-group-text i {
  color: #b2b8fe;
}

[data-primary='color_11'] .datamaps-hoverover {
  color: #6673fd;
  border: 1px solid rgba(102, 115, 253, 0.3);
}

[data-primary='color_11'] .jqvmap-zoomin,
[data-primary='color_11'] .jqvmap-zoomout {
  background-color: #6673fd;
}

[data-primary='color_11'] .table .thead-primary th {
  background-color: #6673fd;
}

[data-primary='color_11'] .table.primary-table-bg-hover thead th {
  background-color: #4d5cfd;
}

[data-primary='color_11'] .table.primary-table-bg-hover tbody tr {
  background-color: #6673fd;
}

[data-primary='color_11'] .table.primary-table-bg-hover tbody tr:hover {
  background-color: #7f8afd;
}

[data-primary='color_11']
  .table.primary-table-bg-hover
  tbody
  tr:not(:last-child)
  td,
[data-primary='color_11']
  .table.primary-table-bg-hover
  tbody
  tr:not(:last-child)
  th {
  border-bottom: 1px solid #4d5cfd;
}

[data-primary='color_11'] table.dataTable tr.selected {
  color: #6673fd;
}

[data-primary='color_11']
  .dataTables_wrapper
  .dataTables_paginate
  .paginate_button.current {
  color: #6673fd !important;
  background: rgba(102, 115, 253, 0.1);
}

[data-primary='color_11']
  .dataTables_wrapper
  .dataTables_paginate
  .paginate_button:hover {
  color: #6673fd !important;
  background: rgba(102, 115, 253, 0.1);
}

[data-primary='color_11'] .clipboard-btn:hover {
  background-color: #6673fd;
}

[data-primary='color_11'] .cd-h-timeline__dates::before {
  background: #6673fd;
}

[data-primary='color_11'] .cd-h-timeline__dates::after {
  background: #6673fd;
}

[data-primary='color_11'] .cd-h-timeline__line {
  background-color: #6673fd;
}

[data-primary='color_11'] .cd-h-timeline__date:after {
  border-color: #6b77fd;
  background-color: #6673fd;
}

[data-primary='color_11'] .cd-h-timeline__navigation {
  border-color: #6b77fd;
}

[data-primary='color_11'] .cd-h-timeline__navigation--inactive:hover {
  border-color: #6b77fd;
}

[data-primary='color_11'] .dd-handle {
  background: #6673fd;
}

[data-primary='color_11'] .dd-handle:hover {
  background: #6673fd;
}

[data-primary='color_11'] .dd3-content:hover {
  background: #6673fd;
}

[data-primary='color_11'] .noUi-connect {
  background-color: #6673fd;
}

[data-primary='color_11'] .noUi-connect.c-3-color {
  background-color: #6673fd;
}

[data-primary='color_11'] .noUi-horizontal .noUi-handle,
[data-primary='color_11'] .noUi-vertical .noUi-handle {
  background-color: #6673fd;
}

[data-primary='color_11'] #slider-toggle.off .noUi-handle {
  border-color: #6673fd;
}

[data-primary='color_11'] .pignose-calendar {
  border-color: #6673fd;
}

[data-primary='color_11'] .pignose-calendar .pignose-calendar-top-date {
  background-color: #6673fd;
}

[data-primary='color_11']
  .pignose-calendar.pignose-calendar-blue
  .pignose-calendar-body
  .pignose-calendar-row
  .pignose-calendar-unit.pignose-calendar-unit-active
  a {
  background-color: #6673fd;
}

[data-primary='color_11'] .bootstrap-tagsinput .tag {
  background-color: #6673fd;
}

[data-primary='color_11'] .toast-success {
  background-color: #6673fd;
}

[data-primary='color_11'] .twitter-typeahead .tt-menu .tt-suggestion:hover {
  background-color: #6673fd;
}

[data-primary='color_11'] .accordion-header-bg .accordion__header--primary {
  background-color: #6673fd;
}

[data-primary='color_11'] .alert-primary {
  background: #e4e6ff;
  border-color: #e4e6ff;
  color: #6673fd;
}

[data-primary='color_11'] .alert-alt.alert-primary {
  border-left: 4px solid #6673fd;
}

[data-primary='color_11'] .alert-alt.alert-primary.solid {
  border-left: 4px solid #0316e1 !important;
}

[data-primary='color_11'] .alert.alert-primary.solid {
  background: #6673fd;
  border-color: #6673fd;
}

[data-primary='color_11'] .alert.alert-outline-primary {
  color: #6673fd;
  border-color: #6673fd;
}

[data-primary='color_11'] .badge-outline-primary {
  border: 1px solid #6673fd;
  color: #6673fd;
}

[data-primary='color_11'] .badge-primary {
  background-color: #6673fd;
}

[data-primary='color_11'] .page-titles h4 {
  color: #6673fd;
}

[data-primary='color_11'] .card-action > a {
  background: #01063f;
}

[data-primary='color_11'] .card-action .dropdown {
  background: #01063f;
  color: #6673fd;
}

[data-primary='color_11'] .card-action .dropdown:hover,
[data-primary='color_11'] .card-action .dropdown:focus {
  background: #01063f;
}

[data-primary='color_11'] .card-loader i {
  background: #4353fd;
}

[data-primary='color_11'] .dropdown-outline {
  border: 0.1rem solid #6673fd;
}

[data-primary='color_11'] .custom-dropdown .dropdown-menu .dropdown-item:hover {
  color: #6673fd;
}

[data-primary='color_11'] .card-action .custom-dropdown {
  background: #fdfdff;
}

[data-primary='color_11'] .card-action .custom-dropdown.show,
[data-primary='color_11'] .card-action .custom-dropdown:focus,
[data-primary='color_11'] .card-action .custom-dropdown:hover {
  background: #6673fd;
}

[data-primary='color_11'] .label-primary {
  background: #6673fd;
}

[data-primary='color_11'] .pagination .page-item .page-link:hover {
  background: #6673fd;
  border-color: #6673fd;
}

[data-primary='color_11'] .pagination .page-item.active .page-link {
  background-color: #6673fd;
  border-color: #6673fd;
}

[data-primary='color_11']
  .bootstrap-popover-wrapper
  .bootstrap-popover
  button:hover,
[data-primary='color_11']
  .bootstrap-popover-wrapper
  .bootstrap-popover
  button:focus {
  background: #6673fd;
}

[data-primary='color_11'] .progress-bar {
  background-color: #6673fd;
}

[data-primary='color_11'] .progress-bar-primary {
  background-color: #6673fd;
}

[data-primary='color_11'] .ribbon__four {
  background-color: #6673fd;
}

[data-primary='color_11'] .ribbon__four:after,
[data-primary='color_11'] .ribbon__four:before {
  background-color: #cbcffe;
}

[data-primary='color_11'] .ribbon__five {
  background-color: #6673fd;
}

[data-primary='color_11'] .ribbon__five::before {
  border-color: transparent transparent #6673fd transparent;
}

[data-primary='color_11'] .ribbon__six {
  background-color: #6673fd;
}

[data-primary='color_11'] .multi-steps > li {
  color: #6673fd;
}

[data-primary='color_11'] .multi-steps > li:after {
  background-color: #6673fd;
}

[data-primary='color_11'] .multi-steps > li.is-active:before {
  border-color: #6673fd;
}

[data-primary='color_11'] .timeline-badge.primary {
  background-color: #6673fd !important;
}

[data-primary='color_11'] .tooltip-wrapper button:hover {
  background: #6673fd;
}

[data-primary='color_11'] .chart_widget_tab_one .nav-link.active {
  background-color: #6673fd;
  border: 1px solid #6673fd;
}

[data-primary='color_11'] .chart_widget_tab_one .nav-link.active:hover {
  border: 1px solid #6673fd;
}

[data-primary='color_11'] .social-icon2 a {
  border: 0.1rem solid #6673fd;
}

[data-primary='color_11'] .social-icon2 i {
  color: #6673fd;
}

[data-primary='color_11'] .social-icon3 ul li a:hover i {
  color: #6673fd;
}

[data-primary='color_11'] .bgl-primary {
  background: #fdfdff;
  border-color: #fdfdff;
  color: #6673fd;
}

[data-primary='color_11'] .tdl-holder input[type='checkbox']:checked + i {
  background: #6673fd;
}

[data-primary='color_11'] .footer .copyright a {
  color: #6673fd;
}

[data-primary='color_11'] .hamburger .line {
  background: #6673fd;
}

[data-primary='color_11'] svg.pulse-svg .first-circle,
[data-primary='color_11'] svg.pulse-svg .second-circle,
[data-primary='color_11'] svg.pulse-svg .third-circle {
  fill: #6673fd;
}

[data-primary='color_11'] .pulse-css {
  background: #6673fd;
}

[data-primary='color_11'] .pulse-css:after,
[data-primary='color_11'] .pulse-css:before {
  background-color: #6673fd;
}

[data-primary='color_11']
  .notification_dropdown
  .dropdown-menu-right
  .notification_title {
  background: #6673fd;
}

[data-primary='color_11'] .header-right .header-profile .dropdown-menu a:hover,
[data-primary='color_11'] .header-right .header-profile .dropdown-menu a:focus,
[data-primary='color_11']
  .header-right
  .header-profile
  .dropdown-menu
  a.active {
  color: #6673fd;
}

[data-primary='color_11'] .header-right .header-profile .profile_title {
  background: #6673fd;
}

[data-primary='color_11'][data-sidebar-style='full'][data-layout='vertical']
  .menu-toggle
  .nav-header
  .nav-control
  .hamburger
  .line {
  background-color: #6673fd !important;
}

[data-primary='color_11'] .deznav .metismenu > li > a svg {
  color: #6673fd;
}

[data-primary='color_11'] .deznav .metismenu > li:hover > a,
[data-primary='color_11'] .deznav .metismenu > li:focus > a {
  color: #6673fd;
}

[data-primary='color_11'] .deznav .metismenu > li.mm-active > a {
  color: #6673fd;
}

[data-primary='color_11'] .deznav .metismenu ul a:hover,
[data-primary='color_11'] .deznav .metismenu ul a:focus,
[data-primary='color_11'] .deznav .metismenu ul a.mm-active {
  color: #6673fd;
}

@media (min-width: 767px) {
  [data-primary='color_11']
    [data-sidebar-style='modern']
    .deznav
    .metismenu
    > li
    > a:hover
    > a,
  [data-primary='color_11']
    [data-sidebar-style='modern']
    .deznav
    .metismenu
    > li
    > a:focus
    > a,
  [data-primary='color_11']
    [data-sidebar-style='modern']
    .deznav
    .metismenu
    > li
    > a:active
    > a,
  [data-primary='color_11']
    [data-sidebar-style='modern']
    .deznav
    .metismenu
    > li
    > a.mm-active
    > a {
    background-color: white;
  }
}

[data-primary='color_11'][data-sidebar-style='overlay']
  .nav-header
  .hamburger.is-active
  .line {
  background-color: #6673fd;
}

[data-primary='color_11'] .nav-user {
  background: #6673fd;
}

[data-primary='color_11'] .sidebar-right .sidebar-right .sidebar-right-trigger {
  color: #6673fd;
}

[data-primary='color_11']
  .sidebar-right
  .sidebar-right
  .sidebar-right-trigger:hover {
  color: #6673fd;
}

[data-primary='color_11'][data-theme-version='dark']
  .pagination
  .page-item
  .page-link:hover {
  background: #6673fd;
  border-color: #6673fd;
}

[data-primary='color_11'][data-theme-version='dark']
  .pagination
  .page-item.active
  .page-link {
  background: #6673fd;
  border-color: #6673fd;
}

[data-primary='color_11'][data-theme-version='dark'] .header-left input:focus {
  border-color: #6673fd;
}

[data-primary='color_11'][data-theme-version='dark'] .loader__bar {
  background: #6673fd;
}

[data-primary='color_11'][data-theme-version='dark'] .loader__ball {
  background: #6673fd;
}

[data-primary='color_11'][data-theme-version='transparent']
  .header-left
  input:focus {
  border-color: #6673fd;
}

[data-primary='color_11'] .new-arrival-content .price {
  color: #6673fd;
}

[data-primary='color_11'] .chart-link a i.text-primary {
  color: #6673fd;
}

[data-primary='color_11'] #user-activity .nav-tabs .nav-link.active {
  background: #6673fd;
  border-color: #6673fd;
}

[data-primary='color_11'] span#counter {
  color: #6673fd;
}

[data-primary='color_11'] .welcome-content:after {
  background: #6673fd;
}

[data-primary='color_11'] .timeline-badge {
  background-color: #6673fd;
}

[data-primary='color_11']
  .page-timeline
  .timeline-workplan[data-primary='color_11']
  .page-timeline
  .timeline
  .timeline-badge:after {
  background-color: rgba(102, 115, 253, 0.4);
}

[data-primary='color_11'] .sk-three-bounce .sk-child {
  background-color: #6673fd;
}

[data-primary='color_11'] .dropdown-item.active,
[data-primary='color_11'] .dropdown-item:active {
  color: #fff;
  background-color: #6673fd;
}

[data-primary='color_11'] .overlay-box:after {
  background: #6673fd;
}

[data-primary='color_11'] .btn-primary {
  background-color: #6673fd;
  border-color: #6673fd;
}

[data-primary='color_11'] .bg-primary {
  background-color: #6673fd !important;
}

[data-primary='color_11'] .text-primary {
  color: #6673fd !important;
}

[data-primary='color_11'] .btn-primary:hover {
  background-color: #1a2efc;
  border-color: #1a2efc;
}

[data-primary='color_11'] .btn-outline-primary {
  color: #6673fd;
  border-color: #6673fd;
}

[data-primary='color_11'] .btn-outline-primary:hover {
  background-color: #6673fd;
  border-color: #6673fd;
}

[data-primary='color_11'] .btn-primary:not(:disabled):not(.disabled):active,
[data-primary='color_11'] .btn-primary:not(:disabled):not(.disabled).active,
[data-primary='color_11'] .show > .btn-primary.dropdown-toggle,
[data-primary='color_11']
  .btn-outline-primary:not(:disabled):not(.disabled):active,
[data-primary='color_11']
  .btn-outline-primary:not(:disabled):not(.disabled).active,
[data-primary='color_11'] .show > .btn-outline-primary.dropdown-toggle {
  background-color: #1a2efc;
  border-color: #1a2efc;
}

[data-primary='color_12'] a:hover,
[data-primary='color_12'] a:focus,
[data-primary='color_12'] a.active {
  color: #558b2f;
}

[data-primary='color_12'] [data-class='bg-primary']:before {
  background: #558b2f;
}

[data-primary='color_12'] .email-left-box .intro-title {
  background: rgba(85, 139, 47, 0.1);
}

[data-primary='color_12'] .email-left-box .intro-title i {
  color: #558b2f;
}

[data-primary='color_12'] .widget-stat .media .media-body h4 {
  color: #558b2f !important;
}

[data-primary='color_12'] .email-right-box .right-box-border {
  border-right: 2px solid rgba(85, 139, 47, 0.1);
}

[data-primary='color_12'] .mail-list .list-group-item.active i {
  color: #558b2f;
}

[data-primary='color_12'] .single-mail.active {
  background: #558b2f;
}

[data-primary='color_12'] .profile-info h4.text-primary {
  color: #558b2f !important;
}

[data-primary='color_12'] .profile-tab .nav-item .nav-link:hover,
[data-primary='color_12'] .profile-tab .nav-item .nav-link.active {
  border-bottom: 0.2px solid #558b2f;
  color: #558b2f;
}

[data-primary='color_12'] .amChartsInputField {
  border: 0;
  background: #558b2f;
}

[data-primary='color_12'] .amcharts-period-input,
[data-primary='color_12'] .amcharts-period-input-selected {
  background: #558b2f;
}

[data-primary='color_12'] .morris-hover {
  background: #558b2f;
}

[data-primary='color_12']
  .select2-container--default
  .select2-results__option--highlighted[aria-selected] {
  background-color: #558b2f;
}

[data-primary='color_12'] .custom-select:focus {
  border-color: #558b2f;
  color: #558b2f;
}

[data-primary='color_12'] .daterangepicker td.active {
  background-color: #558b2f;
}

[data-primary='color_12'] .daterangepicker td.active:hover {
  background-color: #558b2f;
}

[data-primary='color_12'] .daterangepicker button.applyBtn {
  background-color: #558b2f;
  border-color: #558b2f;
}

[data-primary='color_12'] .wizard > .steps li.current a {
  background-color: #558b2f;
}

[data-primary='color_12'] .wizard .skip-email a {
  color: #558b2f;
}

[data-primary='color_12'] .wizard > .actions li:not(.disabled) a {
  background-color: #558b2f;
}

[data-primary='color_12']
  .step-form-horizontal
  .wizard
  .steps
  li.done
  a
  .number {
  background: #558b2f;
}

[data-primary='color_12']
  .step-form-horizontal
  .wizard
  .steps
  li.current
  a
  .number {
  color: #558b2f;
  border-color: #558b2f;
}

[data-primary='color_12']
  .step-form-horizontal
  .wizard
  .steps
  li.disabled
  a
  .number {
  color: #558b2f;
}

[data-primary='color_12']
  .step-form-horizontal
  .wizard
  .steps
  li:not(:last-child)::after {
  background-color: #558b2f;
}

[data-primary='color_12'] .is-invalid .input-group-prepend .input-group-text i {
  color: #79c046;
}

[data-primary='color_12'] .datamaps-hoverover {
  color: #558b2f;
  border: 1px solid rgba(85, 139, 47, 0.3);
}

[data-primary='color_12'] .jqvmap-zoomin,
[data-primary='color_12'] .jqvmap-zoomout {
  background-color: #558b2f;
}

[data-primary='color_12'] .table .thead-primary th {
  background-color: #558b2f;
}

[data-primary='color_12'] .table.primary-table-bg-hover thead th {
  background-color: #497829;
}

[data-primary='color_12'] .table.primary-table-bg-hover tbody tr {
  background-color: #558b2f;
}

[data-primary='color_12'] .table.primary-table-bg-hover tbody tr:hover {
  background-color: #619e35;
}

[data-primary='color_12']
  .table.primary-table-bg-hover
  tbody
  tr:not(:last-child)
  td,
[data-primary='color_12']
  .table.primary-table-bg-hover
  tbody
  tr:not(:last-child)
  th {
  border-bottom: 1px solid #497829;
}

[data-primary='color_12'] table.dataTable tr.selected {
  color: #558b2f;
}

[data-primary='color_12']
  .dataTables_wrapper
  .dataTables_paginate
  .paginate_button.current {
  color: #558b2f !important;
  background: rgba(85, 139, 47, 0.1);
}

[data-primary='color_12']
  .dataTables_wrapper
  .dataTables_paginate
  .paginate_button:hover {
  color: #558b2f !important;
  background: rgba(85, 139, 47, 0.1);
}

[data-primary='color_12'] .clipboard-btn:hover {
  background-color: #558b2f;
}

[data-primary='color_12'] .cd-h-timeline__dates::before {
  background: #558b2f;
}

[data-primary='color_12'] .cd-h-timeline__dates::after {
  background: #558b2f;
}

[data-primary='color_12'] .cd-h-timeline__line {
  background-color: #558b2f;
}

[data-primary='color_12'] .cd-h-timeline__date:after {
  border-color: #578e30;
  background-color: #558b2f;
}

[data-primary='color_12'] .cd-h-timeline__navigation {
  border-color: #578e30;
}

[data-primary='color_12'] .cd-h-timeline__navigation--inactive:hover {
  border-color: #578e30;
}

[data-primary='color_12'] .dd-handle {
  background: #558b2f;
}

[data-primary='color_12'] .dd-handle:hover {
  background: #558b2f;
}

[data-primary='color_12'] .dd3-content:hover {
  background: #558b2f;
}

[data-primary='color_12'] .noUi-connect {
  background-color: #558b2f;
}

[data-primary='color_12'] .noUi-connect.c-3-color {
  background-color: #558b2f;
}

[data-primary='color_12'] .noUi-horizontal .noUi-handle,
[data-primary='color_12'] .noUi-vertical .noUi-handle {
  background-color: #558b2f;
}

[data-primary='color_12'] #slider-toggle.off .noUi-handle {
  border-color: #558b2f;
}

[data-primary='color_12'] .pignose-calendar {
  border-color: #558b2f;
}

[data-primary='color_12'] .pignose-calendar .pignose-calendar-top-date {
  background-color: #558b2f;
}

[data-primary='color_12']
  .pignose-calendar.pignose-calendar-blue
  .pignose-calendar-body
  .pignose-calendar-row
  .pignose-calendar-unit.pignose-calendar-unit-active
  a {
  background-color: #558b2f;
}

[data-primary='color_12'] .bootstrap-tagsinput .tag {
  background-color: #558b2f;
}

[data-primary='color_12'] .toast-success {
  background-color: #558b2f;
}

[data-primary='color_12'] .twitter-typeahead .tt-menu .tt-suggestion:hover {
  background-color: #558b2f;
}

[data-primary='color_12'] .accordion-header-bg .accordion__header--primary {
  background-color: #558b2f;
}

[data-primary='color_12'] .alert-primary {
  background: #94cd6c;
  border-color: #94cd6c;
  color: #558b2f;
}

[data-primary='color_12'] .alert-alt.alert-primary {
  border-left: 4px solid #558b2f;
}

[data-primary='color_12'] .alert-alt.alert-primary.solid {
  border-left: 4px solid #1b2c0f !important;
}

[data-primary='color_12'] .alert.alert-primary.solid {
  background: #558b2f;
  border-color: #558b2f;
}

[data-primary='color_12'] .alert.alert-outline-primary {
  color: #558b2f;
  border-color: #558b2f;
}

[data-primary='color_12'] .badge-outline-primary {
  border: 1px solid #558b2f;
  color: #558b2f;
}

[data-primary='color_12'] .badge-primary {
  background-color: #558b2f;
}

[data-primary='color_12'] .page-titles h4 {
  color: #558b2f;
}

[data-primary='color_12'] .card-action > a {
  background: black;
}

[data-primary='color_12'] .card-action .dropdown {
  background: black;
  color: #558b2f;
}

[data-primary='color_12'] .card-action .dropdown:hover,
[data-primary='color_12'] .card-action .dropdown:focus {
  background: black;
}

[data-primary='color_12'] .card-loader i {
  background: #457026;
}

[data-primary='color_12'] .dropdown-outline {
  border: 0.1rem solid #558b2f;
}

[data-primary='color_12'] .custom-dropdown .dropdown-menu .dropdown-item:hover {
  color: #558b2f;
}

[data-primary='color_12'] .card-action .custom-dropdown {
  background: #a2d47f;
}

[data-primary='color_12'] .card-action .custom-dropdown.show,
[data-primary='color_12'] .card-action .custom-dropdown:focus,
[data-primary='color_12'] .card-action .custom-dropdown:hover {
  background: #558b2f;
}

[data-primary='color_12'] .label-primary {
  background: #558b2f;
}

[data-primary='color_12'] .pagination .page-item .page-link:hover {
  background: #558b2f;
  border-color: #558b2f;
}

[data-primary='color_12'] .pagination .page-item.active .page-link {
  background-color: #558b2f;
  border-color: #558b2f;
}

[data-primary='color_12']
  .bootstrap-popover-wrapper
  .bootstrap-popover
  button:hover,
[data-primary='color_12']
  .bootstrap-popover-wrapper
  .bootstrap-popover
  button:focus {
  background: #558b2f;
}

[data-primary='color_12'] .progress-bar {
  background-color: #558b2f;
}

[data-primary='color_12'] .progress-bar-primary {
  background-color: #558b2f;
}

[data-primary='color_12'] .ribbon__four {
  background-color: #558b2f;
}

[data-primary='color_12'] .ribbon__four:after,
[data-primary='color_12'] .ribbon__four:before {
  background-color: #86c759;
}

[data-primary='color_12'] .ribbon__five {
  background-color: #558b2f;
}

[data-primary='color_12'] .ribbon__five::before {
  border-color: transparent transparent #558b2f transparent;
}

[data-primary='color_12'] .ribbon__six {
  background-color: #558b2f;
}

[data-primary='color_12'] .multi-steps > li {
  color: #558b2f;
}

[data-primary='color_12'] .multi-steps > li:after {
  background-color: #558b2f;
}

[data-primary='color_12'] .multi-steps > li.is-active:before {
  border-color: #558b2f;
}

[data-primary='color_12'] .timeline-badge.primary {
  background-color: #558b2f !important;
}

[data-primary='color_12'] .tooltip-wrapper button:hover {
  background: #558b2f;
}

[data-primary='color_12'] .chart_widget_tab_one .nav-link.active {
  background-color: #558b2f;
  border: 1px solid #558b2f;
}

[data-primary='color_12'] .chart_widget_tab_one .nav-link.active:hover {
  border: 1px solid #558b2f;
}

[data-primary='color_12'] .social-icon2 a {
  border: 0.1rem solid #558b2f;
}

[data-primary='color_12'] .social-icon2 i {
  color: #558b2f;
}

[data-primary='color_12'] .social-icon3 ul li a:hover i {
  color: #558b2f;
}

[data-primary='color_12'] .bgl-primary {
  background: #a2d47f;
  border-color: #a2d47f;
  color: #558b2f;
}

[data-primary='color_12'] .tdl-holder input[type='checkbox']:checked + i {
  background: #558b2f;
}

[data-primary='color_12'] .footer .copyright a {
  color: #558b2f;
}

[data-primary='color_12'] .hamburger .line {
  background: #558b2f;
}

[data-primary='color_12'] svg.pulse-svg .first-circle,
[data-primary='color_12'] svg.pulse-svg .second-circle,
[data-primary='color_12'] svg.pulse-svg .third-circle {
  fill: #558b2f;
}

[data-primary='color_12'] .pulse-css {
  background: #558b2f;
}

[data-primary='color_12'] .pulse-css:after,
[data-primary='color_12'] .pulse-css:before {
  background-color: #558b2f;
}

[data-primary='color_12']
  .notification_dropdown
  .dropdown-menu-right
  .notification_title {
  background: #558b2f;
}

[data-primary='color_12'] .header-right .header-profile .dropdown-menu a:hover,
[data-primary='color_12'] .header-right .header-profile .dropdown-menu a:focus,
[data-primary='color_12']
  .header-right
  .header-profile
  .dropdown-menu
  a.active {
  color: #558b2f;
}

[data-primary='color_12'] .header-right .header-profile .profile_title {
  background: #558b2f;
}

[data-primary='color_12'][data-sidebar-style='full'][data-layout='vertical']
  .menu-toggle
  .nav-header
  .nav-control
  .hamburger
  .line {
  background-color: #558b2f !important;
}

[data-primary='color_12'] .deznav .metismenu > li > a svg {
  color: #558b2f;
}

[data-primary='color_12'] .deznav .metismenu > li:hover > a,
[data-primary='color_12'] .deznav .metismenu > li:focus > a {
  color: #558b2f;
}

[data-primary='color_12'] .deznav .metismenu > li.mm-active > a {
  color: #558b2f;
}

[data-primary='color_12'] .deznav .metismenu ul a:hover,
[data-primary='color_12'] .deznav .metismenu ul a:focus,
[data-primary='color_12'] .deznav .metismenu ul a.mm-active {
  color: #558b2f;
}

@media (min-width: 767px) {
  [data-primary='color_12']
    [data-sidebar-style='modern']
    .deznav
    .metismenu
    > li
    > a:hover
    > a,
  [data-primary='color_12']
    [data-sidebar-style='modern']
    .deznav
    .metismenu
    > li
    > a:focus
    > a,
  [data-primary='color_12']
    [data-sidebar-style='modern']
    .deznav
    .metismenu
    > li
    > a:active
    > a,
  [data-primary='color_12']
    [data-sidebar-style='modern']
    .deznav
    .metismenu
    > li
    > a.mm-active
    > a {
    background-color: #daeecb;
  }
}

[data-primary='color_12'][data-sidebar-style='overlay']
  .nav-header
  .hamburger.is-active
  .line {
  background-color: #558b2f;
}

[data-primary='color_12'] .nav-user {
  background: #558b2f;
}

[data-primary='color_12'] .sidebar-right .sidebar-right .sidebar-right-trigger {
  color: #558b2f;
}

[data-primary='color_12']
  .sidebar-right
  .sidebar-right
  .sidebar-right-trigger:hover {
  color: #558b2f;
}

[data-primary='color_12'][data-theme-version='dark']
  .pagination
  .page-item
  .page-link:hover {
  background: #558b2f;
  border-color: #558b2f;
}

[data-primary='color_12'][data-theme-version='dark']
  .pagination
  .page-item.active
  .page-link {
  background: #558b2f;
  border-color: #558b2f;
}

[data-primary='color_12'][data-theme-version='dark'] .header-left input:focus {
  border-color: #558b2f;
}

[data-primary='color_12'][data-theme-version='dark'] .loader__bar {
  background: #558b2f;
}

[data-primary='color_12'][data-theme-version='dark'] .loader__ball {
  background: #558b2f;
}

[data-primary='color_12'][data-theme-version='transparent']
  .header-left
  input:focus {
  border-color: #558b2f;
}

[data-primary='color_12'] .new-arrival-content .price {
  color: #558b2f;
}

[data-primary='color_12'] .chart-link a i.text-primary {
  color: #558b2f;
}

[data-primary='color_12'] #user-activity .nav-tabs .nav-link.active {
  background: #558b2f;
  border-color: #558b2f;
}

[data-primary='color_12'] span#counter {
  color: #558b2f;
}

[data-primary='color_12'] .welcome-content:after {
  background: #558b2f;
}

[data-primary='color_12'] .timeline-badge {
  background-color: #558b2f;
}

[data-primary='color_12']
  .page-timeline
  .timeline-workplan[data-primary='color_12']
  .page-timeline
  .timeline
  .timeline-badge:after {
  background-color: rgba(85, 139, 47, 0.4);
}

[data-primary='color_12'] .sk-three-bounce .sk-child {
  background-color: #558b2f;
}

[data-primary='color_12'] .dropdown-item.active,
[data-primary='color_12'] .dropdown-item:active {
  color: #fff;
  background-color: #558b2f;
}

[data-primary='color_12'] .overlay-box:after {
  background: #558b2f;
}

[data-primary='color_12'] .btn-primary {
  background-color: #558b2f;
  border-color: #558b2f;
}

[data-primary='color_12'] .bg-primary {
  background-color: #558b2f !important;
}

[data-primary='color_12'] .text-primary {
  color: #558b2f !important;
}

[data-primary='color_12'] .btn-primary:hover {
  background-color: #32521c;
  border-color: #32521c;
}

[data-primary='color_12'] .btn-outline-primary {
  color: #558b2f;
  border-color: #558b2f;
}

[data-primary='color_12'] .btn-outline-primary:hover {
  background-color: #558b2f;
  border-color: #558b2f;
}

[data-primary='color_12'] .btn-primary:not(:disabled):not(.disabled):active,
[data-primary='color_12'] .btn-primary:not(:disabled):not(.disabled).active,
[data-primary='color_12'] .show > .btn-primary.dropdown-toggle,
[data-primary='color_12']
  .btn-outline-primary:not(:disabled):not(.disabled):active,
[data-primary='color_12']
  .btn-outline-primary:not(:disabled):not(.disabled).active,
[data-primary='color_12'] .show > .btn-outline-primary.dropdown-toggle {
  background-color: #32521c;
  border-color: #32521c;
}

[data-primary='color_13'] a:hover,
[data-primary='color_13'] a:focus,
[data-primary='color_13'] a.active {
  color: #2a2a2a;
}

[data-primary='color_13'] [data-class='bg-primary']:before {
  background: #2a2a2a;
}

[data-primary='color_13'] .email-left-box .intro-title {
  background: rgba(42, 42, 42, 0.1);
}

[data-primary='color_13'] .email-left-box .intro-title i {
  color: #2a2a2a;
}

[data-primary='color_13'] .widget-stat .media .media-body h4 {
  color: #2a2a2a !important;
}

[data-primary='color_13'] .email-right-box .right-box-border {
  border-right: 2px solid rgba(42, 42, 42, 0.1);
}

[data-primary='color_13'] .mail-list .list-group-item.active i {
  color: #2a2a2a;
}

[data-primary='color_13'] .single-mail.active {
  background: #2a2a2a;
}

[data-primary='color_13'] .profile-info h4.text-primary {
  color: #2a2a2a !important;
}

[data-primary='color_13'] .profile-tab .nav-item .nav-link:hover,
[data-primary='color_13'] .profile-tab .nav-item .nav-link.active {
  border-bottom: 0.2px solid #2a2a2a;
  color: #2a2a2a;
}

[data-primary='color_13'] .amChartsInputField {
  border: 0;
  background: #2a2a2a;
}

[data-primary='color_13'] .amcharts-period-input,
[data-primary='color_13'] .amcharts-period-input-selected {
  background: #2a2a2a;
}

[data-primary='color_13'] .morris-hover {
  background: #2a2a2a;
}

[data-primary='color_13']
  .select2-container--default
  .select2-results__option--highlighted[aria-selected] {
  background-color: #2a2a2a;
}

[data-primary='color_13'] .custom-select:focus {
  border-color: #2a2a2a;
  color: #2a2a2a;
}

[data-primary='color_13'] .daterangepicker td.active {
  background-color: #2a2a2a;
}

[data-primary='color_13'] .daterangepicker td.active:hover {
  background-color: #2a2a2a;
}

[data-primary='color_13'] .daterangepicker button.applyBtn {
  background-color: #2a2a2a;
  border-color: #2a2a2a;
}

[data-primary='color_13'] .wizard > .steps li.current a {
  background-color: #2a2a2a;
}

[data-primary='color_13'] .wizard .skip-email a {
  color: #2a2a2a;
}

[data-primary='color_13'] .wizard > .actions li:not(.disabled) a {
  background-color: #2a2a2a;
}

[data-primary='color_13']
  .step-form-horizontal
  .wizard
  .steps
  li.done
  a
  .number {
  background: #2a2a2a;
}

[data-primary='color_13']
  .step-form-horizontal
  .wizard
  .steps
  li.current
  a
  .number {
  color: #2a2a2a;
  border-color: #2a2a2a;
}

[data-primary='color_13']
  .step-form-horizontal
  .wizard
  .steps
  li.disabled
  a
  .number {
  color: #2a2a2a;
}

[data-primary='color_13']
  .step-form-horizontal
  .wizard
  .steps
  li:not(:last-child)::after {
  background-color: #2a2a2a;
}

[data-primary='color_13'] .is-invalid .input-group-prepend .input-group-text i {
  color: #505050;
}

[data-primary='color_13'] .datamaps-hoverover {
  color: #2a2a2a;
  border: 1px solid rgba(42, 42, 42, 0.3);
}

[data-primary='color_13'] .jqvmap-zoomin,
[data-primary='color_13'] .jqvmap-zoomout {
  background-color: #2a2a2a;
}

[data-primary='color_13'] .table .thead-primary th {
  background-color: #2a2a2a;
}

[data-primary='color_13'] .table.primary-table-bg-hover thead th {
  background-color: #1d1d1d;
}

[data-primary='color_13'] .table.primary-table-bg-hover tbody tr {
  background-color: #2a2a2a;
}

[data-primary='color_13'] .table.primary-table-bg-hover tbody tr:hover {
  background-color: #373737;
}

[data-primary='color_13']
  .table.primary-table-bg-hover
  tbody
  tr:not(:last-child)
  td,
[data-primary='color_13']
  .table.primary-table-bg-hover
  tbody
  tr:not(:last-child)
  th {
  border-bottom: 1px solid #1d1d1d;
}

[data-primary='color_13'] table.dataTable tr.selected {
  color: #2a2a2a;
}

[data-primary='color_13']
  .dataTables_wrapper
  .dataTables_paginate
  .paginate_button.current {
  color: #2a2a2a !important;
  background: rgba(42, 42, 42, 0.1);
}

[data-primary='color_13']
  .dataTables_wrapper
  .dataTables_paginate
  .paginate_button:hover {
  color: #2a2a2a !important;
  background: rgba(42, 42, 42, 0.1);
}

[data-primary='color_13'] .clipboard-btn:hover {
  background-color: #2a2a2a;
}

[data-primary='color_13'] .cd-h-timeline__dates::before {
  background: #2a2a2a;
}

[data-primary='color_13'] .cd-h-timeline__dates::after {
  background: #2a2a2a;
}

[data-primary='color_13'] .cd-h-timeline__line {
  background-color: #2a2a2a;
}

[data-primary='color_13'] .cd-h-timeline__date:after {
  border-color: #2c2c2c;
  background-color: #2a2a2a;
}

[data-primary='color_13'] .cd-h-timeline__navigation {
  border-color: #2c2c2c;
}

[data-primary='color_13'] .cd-h-timeline__navigation--inactive:hover {
  border-color: #2c2c2c;
}

[data-primary='color_13'] .dd-handle {
  background: #2a2a2a;
}

[data-primary='color_13'] .dd-handle:hover {
  background: #2a2a2a;
}

[data-primary='color_13'] .dd3-content:hover {
  background: #2a2a2a;
}

[data-primary='color_13'] .noUi-connect {
  background-color: #2a2a2a;
}

[data-primary='color_13'] .noUi-connect.c-3-color {
  background-color: #2a2a2a;
}

[data-primary='color_13'] .noUi-horizontal .noUi-handle,
[data-primary='color_13'] .noUi-vertical .noUi-handle {
  background-color: #2a2a2a;
}

[data-primary='color_13'] #slider-toggle.off .noUi-handle {
  border-color: #2a2a2a;
}

[data-primary='color_13'] .pignose-calendar {
  border-color: #2a2a2a;
}

[data-primary='color_13'] .pignose-calendar .pignose-calendar-top-date {
  background-color: #2a2a2a;
}

[data-primary='color_13']
  .pignose-calendar.pignose-calendar-blue
  .pignose-calendar-body
  .pignose-calendar-row
  .pignose-calendar-unit.pignose-calendar-unit-active
  a {
  background-color: #2a2a2a;
}

[data-primary='color_13'] .bootstrap-tagsinput .tag {
  background-color: #2a2a2a;
}

[data-primary='color_13'] .toast-success {
  background-color: #2a2a2a;
}

[data-primary='color_13'] .twitter-typeahead .tt-menu .tt-suggestion:hover {
  background-color: #2a2a2a;
}

[data-primary='color_13'] .accordion-header-bg .accordion__header--primary {
  background-color: #2a2a2a;
}

[data-primary='color_13'] .alert-primary {
  background: #6a6a6a;
  border-color: #6a6a6a;
  color: #2a2a2a;
}

[data-primary='color_13'] .alert-alt.alert-primary {
  border-left: 4px solid #2a2a2a;
}

[data-primary='color_13'] .alert-alt.alert-primary.solid {
  border-left: 4px solid black !important;
}

[data-primary='color_13'] .alert.alert-primary.solid {
  background: #2a2a2a;
  border-color: #2a2a2a;
}

[data-primary='color_13'] .alert.alert-outline-primary {
  color: #2a2a2a;
  border-color: #2a2a2a;
}

[data-primary='color_13'] .badge-outline-primary {
  border: 1px solid #2a2a2a;
  color: #2a2a2a;
}

[data-primary='color_13'] .badge-primary {
  background-color: #2a2a2a;
}

[data-primary='color_13'] .page-titles h4 {
  color: #2a2a2a;
}

[data-primary='color_13'] .card-action > a {
  background: black;
}

[data-primary='color_13'] .card-action .dropdown {
  background: black;
  color: #2a2a2a;
}

[data-primary='color_13'] .card-action .dropdown:hover,
[data-primary='color_13'] .card-action .dropdown:focus {
  background: black;
}

[data-primary='color_13'] .card-loader i {
  background: #181818;
}

[data-primary='color_13'] .dropdown-outline {
  border: 0.1rem solid #2a2a2a;
}

[data-primary='color_13'] .custom-dropdown .dropdown-menu .dropdown-item:hover {
  color: #2a2a2a;
}

[data-primary='color_13'] .card-action .custom-dropdown {
  background: #777777;
}

[data-primary='color_13'] .card-action .custom-dropdown.show,
[data-primary='color_13'] .card-action .custom-dropdown:focus,
[data-primary='color_13'] .card-action .custom-dropdown:hover {
  background: #2a2a2a;
}

[data-primary='color_13'] .label-primary {
  background: #2a2a2a;
}

[data-primary='color_13'] .pagination .page-item .page-link:hover {
  background: #2a2a2a;
  border-color: #2a2a2a;
}

[data-primary='color_13'] .pagination .page-item.active .page-link {
  background-color: #2a2a2a;
  border-color: #2a2a2a;
}

[data-primary='color_13']
  .bootstrap-popover-wrapper
  .bootstrap-popover
  button:hover,
[data-primary='color_13']
  .bootstrap-popover-wrapper
  .bootstrap-popover
  button:focus {
  background: #2a2a2a;
}

[data-primary='color_13'] .progress-bar {
  background-color: #2a2a2a;
}

[data-primary='color_13'] .progress-bar-primary {
  background-color: #2a2a2a;
}

[data-primary='color_13'] .ribbon__four {
  background-color: #2a2a2a;
}

[data-primary='color_13'] .ribbon__four:after,
[data-primary='color_13'] .ribbon__four:before {
  background-color: #5d5d5d;
}

[data-primary='color_13'] .ribbon__five {
  background-color: #2a2a2a;
}

[data-primary='color_13'] .ribbon__five::before {
  border-color: transparent transparent #2a2a2a transparent;
}

[data-primary='color_13'] .ribbon__six {
  background-color: #2a2a2a;
}

[data-primary='color_13'] .multi-steps > li {
  color: #2a2a2a;
}

[data-primary='color_13'] .multi-steps > li:after {
  background-color: #2a2a2a;
}

[data-primary='color_13'] .multi-steps > li.is-active:before {
  border-color: #2a2a2a;
}

[data-primary='color_13'] .timeline-badge.primary {
  background-color: #2a2a2a !important;
}

[data-primary='color_13'] .tooltip-wrapper button:hover {
  background: #2a2a2a;
}

[data-primary='color_13'] .chart_widget_tab_one .nav-link.active {
  background-color: #2a2a2a;
  border: 1px solid #2a2a2a;
}

[data-primary='color_13'] .chart_widget_tab_one .nav-link.active:hover {
  border: 1px solid #2a2a2a;
}

[data-primary='color_13'] .social-icon2 a {
  border: 0.1rem solid #2a2a2a;
}

[data-primary='color_13'] .social-icon2 i {
  color: #2a2a2a;
}

[data-primary='color_13'] .social-icon3 ul li a:hover i {
  color: #2a2a2a;
}

[data-primary='color_13'] .bgl-primary {
  background: #777777;
  border-color: #777777;
  color: #2a2a2a;
}

[data-primary='color_13'] .tdl-holder input[type='checkbox']:checked + i {
  background: #2a2a2a;
}

[data-primary='color_13'] .footer .copyright a {
  color: #2a2a2a;
}

[data-primary='color_13'] .hamburger .line {
  background: #2a2a2a;
}

[data-primary='color_13'] svg.pulse-svg .first-circle,
[data-primary='color_13'] svg.pulse-svg .second-circle,
[data-primary='color_13'] svg.pulse-svg .third-circle {
  fill: #2a2a2a;
}

[data-primary='color_13'] .pulse-css {
  background: #2a2a2a;
}

[data-primary='color_13'] .pulse-css:after,
[data-primary='color_13'] .pulse-css:before {
  background-color: #2a2a2a;
}

[data-primary='color_13']
  .notification_dropdown
  .dropdown-menu-right
  .notification_title {
  background: #2a2a2a;
}

[data-primary='color_13'] .header-right .header-profile .dropdown-menu a:hover,
[data-primary='color_13'] .header-right .header-profile .dropdown-menu a:focus,
[data-primary='color_13']
  .header-right
  .header-profile
  .dropdown-menu
  a.active {
  color: #2a2a2a;
}

[data-primary='color_13'] .header-right .header-profile .profile_title {
  background: #2a2a2a;
}

[data-primary='color_13'][data-sidebar-style='full'][data-layout='vertical']
  .menu-toggle
  .nav-header
  .nav-control
  .hamburger
  .line {
  background-color: #2a2a2a !important;
}

[data-primary='color_13'] .deznav .metismenu > li > a svg {
  color: #2a2a2a;
}

[data-primary='color_13'] .deznav .metismenu > li:hover > a,
[data-primary='color_13'] .deznav .metismenu > li:focus > a {
  color: #2a2a2a;
}

[data-primary='color_13'] .deznav .metismenu > li.mm-active > a {
  color: #2a2a2a;
}

[data-primary='color_13'] .deznav .metismenu ul a:hover,
[data-primary='color_13'] .deznav .metismenu ul a:focus,
[data-primary='color_13'] .deznav .metismenu ul a.mm-active {
  color: #2a2a2a;
}

@media (min-width: 767px) {
  [data-primary='color_13']
    [data-sidebar-style='modern']
    .deznav
    .metismenu
    > li
    > a:hover
    > a,
  [data-primary='color_13']
    [data-sidebar-style='modern']
    .deznav
    .metismenu
    > li
    > a:focus
    > a,
  [data-primary='color_13']
    [data-sidebar-style='modern']
    .deznav
    .metismenu
    > li
    > a:active
    > a,
  [data-primary='color_13']
    [data-sidebar-style='modern']
    .deznav
    .metismenu
    > li
    > a.mm-active
    > a {
    background-color: #aaaaaa;
  }
}

[data-primary='color_13'][data-sidebar-style='overlay']
  .nav-header
  .hamburger.is-active
  .line {
  background-color: #2a2a2a;
}

[data-primary='color_13'] .nav-user {
  background: #2a2a2a;
}

[data-primary='color_13'] .sidebar-right .sidebar-right .sidebar-right-trigger {
  color: #2a2a2a;
}

[data-primary='color_13']
  .sidebar-right
  .sidebar-right
  .sidebar-right-trigger:hover {
  color: #2a2a2a;
}

[data-primary='color_13'][data-theme-version='dark']
  .pagination
  .page-item
  .page-link:hover {
  background: #2a2a2a;
  border-color: #2a2a2a;
}

[data-primary='color_13'][data-theme-version='dark']
  .pagination
  .page-item.active
  .page-link {
  background: #2a2a2a;
  border-color: #2a2a2a;
}

[data-primary='color_13'][data-theme-version='dark'] .header-left input:focus {
  border-color: #2a2a2a;
}

[data-primary='color_13'][data-theme-version='dark'] .loader__bar {
  background: #2a2a2a;
}

[data-primary='color_13'][data-theme-version='dark'] .loader__ball {
  background: #2a2a2a;
}

[data-primary='color_13'][data-theme-version='transparent']
  .header-left
  input:focus {
  border-color: #2a2a2a;
}

[data-primary='color_13'] .new-arrival-content .price {
  color: #2a2a2a;
}

[data-primary='color_13'] .chart-link a i.text-primary {
  color: #2a2a2a;
}

[data-primary='color_13'] #user-activity .nav-tabs .nav-link.active {
  background: #2a2a2a;
  border-color: #2a2a2a;
}

[data-primary='color_13'] span#counter {
  color: #2a2a2a;
}

[data-primary='color_13'] .welcome-content:after {
  background: #2a2a2a;
}

[data-primary='color_13'] .timeline-badge {
  background-color: #2a2a2a;
}

[data-primary='color_13']
  .page-timeline
  .timeline-workplan[data-primary='color_13']
  .page-timeline
  .timeline
  .timeline-badge:after {
  background-color: rgba(42, 42, 42, 0.4);
}

[data-primary='color_13'] .sk-three-bounce .sk-child {
  background-color: #2a2a2a;
}

[data-primary='color_13'] .dropdown-item.active,
[data-primary='color_13'] .dropdown-item:active {
  color: #fff;
  background-color: #2a2a2a;
}

[data-primary='color_13'] .overlay-box:after {
  background: #2a2a2a;
}

[data-primary='color_13'] .btn-primary {
  background-color: #2a2a2a;
  border-color: #2a2a2a;
}

[data-primary='color_13'] .bg-primary {
  background-color: #2a2a2a !important;
}

[data-primary='color_13'] .text-primary {
  color: #2a2a2a !important;
}

[data-primary='color_13'] .btn-primary:hover {
  background-color: #040404;
  border-color: #040404;
}

[data-primary='color_13'] .btn-outline-primary {
  color: #2a2a2a;
  border-color: #2a2a2a;
}

[data-primary='color_13'] .btn-outline-primary:hover {
  background-color: #2a2a2a;
  border-color: #2a2a2a;
}

[data-primary='color_13'] .btn-primary:not(:disabled):not(.disabled):active,
[data-primary='color_13'] .btn-primary:not(:disabled):not(.disabled).active,
[data-primary='color_13'] .show > .btn-primary.dropdown-toggle,
[data-primary='color_13']
  .btn-outline-primary:not(:disabled):not(.disabled):active,
[data-primary='color_13']
  .btn-outline-primary:not(:disabled):not(.disabled).active,
[data-primary='color_13'] .show > .btn-outline-primary.dropdown-toggle {
  background-color: #040404;
  border-color: #040404;
}

[data-primary='color_14'] a:hover,
[data-primary='color_14'] a:focus,
[data-primary='color_14'] a.active {
  color: #1367c8;
}

[data-primary='color_14'] [data-class='bg-primary']:before {
  background: #1367c8;
}

[data-primary='color_14'] .email-left-box .intro-title {
  background: rgba(19, 103, 200, 0.1);
}

[data-primary='color_14'] .email-left-box .intro-title i {
  color: #1367c8;
}

[data-primary='color_14'] .widget-stat .media .media-body h4 {
  color: #1367c8 !important;
}

[data-primary='color_14'] .email-right-box .right-box-border {
  border-right: 2px solid rgba(19, 103, 200, 0.1);
}

[data-primary='color_14'] .mail-list .list-group-item.active i {
  color: #1367c8;
}

[data-primary='color_14'] .single-mail.active {
  background: #1367c8;
}

[data-primary='color_14'] .profile-info h4.text-primary {
  color: #1367c8 !important;
}

[data-primary='color_14'] .profile-tab .nav-item .nav-link:hover,
[data-primary='color_14'] .profile-tab .nav-item .nav-link.active {
  border-bottom: 0.2px solid #1367c8;
  color: #1367c8;
}

[data-primary='color_14'] .amChartsInputField {
  border: 0;
  background: #1367c8;
}

[data-primary='color_14'] .amcharts-period-input,
[data-primary='color_14'] .amcharts-period-input-selected {
  background: #1367c8;
}

[data-primary='color_14'] .morris-hover {
  background: #1367c8;
}

[data-primary='color_14']
  .select2-container--default
  .select2-results__option--highlighted[aria-selected] {
  background-color: #1367c8;
}

[data-primary='color_14'] .custom-select:focus {
  border-color: #1367c8;
  color: #1367c8;
}

[data-primary='color_14'] .daterangepicker td.active {
  background-color: #1367c8;
}

[data-primary='color_14'] .daterangepicker td.active:hover {
  background-color: #1367c8;
}

[data-primary='color_14'] .daterangepicker button.applyBtn {
  background-color: #1367c8;
  border-color: #1367c8;
}

[data-primary='color_14'] .wizard > .steps li.current a {
  background-color: #1367c8;
}

[data-primary='color_14'] .wizard .skip-email a {
  color: #1367c8;
}

[data-primary='color_14'] .wizard > .actions li:not(.disabled) a {
  background-color: #1367c8;
}

[data-primary='color_14']
  .step-form-horizontal
  .wizard
  .steps
  li.done
  a
  .number {
  background: #1367c8;
}

[data-primary='color_14']
  .step-form-horizontal
  .wizard
  .steps
  li.current
  a
  .number {
  color: #1367c8;
  border-color: #1367c8;
}

[data-primary='color_14']
  .step-form-horizontal
  .wizard
  .steps
  li.disabled
  a
  .number {
  color: #1367c8;
}

[data-primary='color_14']
  .step-form-horizontal
  .wizard
  .steps
  li:not(:last-child)::after {
  background-color: #1367c8;
}

[data-primary='color_14'] .is-invalid .input-group-prepend .input-group-text i {
  color: #3b8dec;
}

[data-primary='color_14'] .datamaps-hoverover {
  color: #1367c8;
  border: 1px solid rgba(19, 103, 200, 0.3);
}

[data-primary='color_14'] .jqvmap-zoomin,
[data-primary='color_14'] .jqvmap-zoomout {
  background-color: #1367c8;
}

[data-primary='color_14'] .table .thead-primary th {
  background-color: #1367c8;
}

[data-primary='color_14'] .table.primary-table-bg-hover thead th {
  background-color: #115bb1;
}

[data-primary='color_14'] .table.primary-table-bg-hover tbody tr {
  background-color: #1367c8;
}

[data-primary='color_14'] .table.primary-table-bg-hover tbody tr:hover {
  background-color: #1573df;
}

[data-primary='color_14']
  .table.primary-table-bg-hover
  tbody
  tr:not(:last-child)
  td,
[data-primary='color_14']
  .table.primary-table-bg-hover
  tbody
  tr:not(:last-child)
  th {
  border-bottom: 1px solid #115bb1;
}

[data-primary='color_14'] table.dataTable tr.selected {
  color: #1367c8;
}

[data-primary='color_14']
  .dataTables_wrapper
  .dataTables_paginate
  .paginate_button.current {
  color: #1367c8 !important;
  background: rgba(19, 103, 200, 0.1);
}

[data-primary='color_14']
  .dataTables_wrapper
  .dataTables_paginate
  .paginate_button:hover {
  color: #1367c8 !important;
  background: rgba(19, 103, 200, 0.1);
}

[data-primary='color_14'] .clipboard-btn:hover {
  background-color: #1367c8;
}

[data-primary='color_14'] .cd-h-timeline__dates::before {
  background: #1367c8;
}

[data-primary='color_14'] .cd-h-timeline__dates::after {
  background: #1367c8;
}

[data-primary='color_14'] .cd-h-timeline__line {
  background-color: #1367c8;
}

[data-primary='color_14'] .cd-h-timeline__date:after {
  border-color: #1369cc;
  background-color: #1367c8;
}

[data-primary='color_14'] .cd-h-timeline__navigation {
  border-color: #1369cc;
}

[data-primary='color_14'] .cd-h-timeline__navigation--inactive:hover {
  border-color: #1369cc;
}

[data-primary='color_14'] .dd-handle {
  background: #1367c8;
}

[data-primary='color_14'] .dd-handle:hover {
  background: #1367c8;
}

[data-primary='color_14'] .dd3-content:hover {
  background: #1367c8;
}

[data-primary='color_14'] .noUi-connect {
  background-color: #1367c8;
}

[data-primary='color_14'] .noUi-connect.c-3-color {
  background-color: #1367c8;
}

[data-primary='color_14'] .noUi-horizontal .noUi-handle,
[data-primary='color_14'] .noUi-vertical .noUi-handle {
  background-color: #1367c8;
}

[data-primary='color_14'] #slider-toggle.off .noUi-handle {
  border-color: #1367c8;
}

[data-primary='color_14'] .pignose-calendar {
  border-color: #1367c8;
}

[data-primary='color_14'] .pignose-calendar .pignose-calendar-top-date {
  background-color: #1367c8;
}

[data-primary='color_14']
  .pignose-calendar.pignose-calendar-blue
  .pignose-calendar-body
  .pignose-calendar-row
  .pignose-calendar-unit.pignose-calendar-unit-active
  a {
  background-color: #1367c8;
}

[data-primary='color_14'] .bootstrap-tagsinput .tag {
  background-color: #1367c8;
}

[data-primary='color_14'] .toast-success {
  background-color: #1367c8;
}

[data-primary='color_14'] .twitter-typeahead .tt-menu .tt-suggestion:hover {
  background-color: #1367c8;
}

[data-primary='color_14'] .accordion-header-bg .accordion__header--primary {
  background-color: #1367c8;
}

[data-primary='color_14'] .alert-primary {
  background: #6aa8f1;
  border-color: #6aa8f1;
  color: #1367c8;
}

[data-primary='color_14'] .alert-alt.alert-primary {
  border-left: 4px solid #1367c8;
}

[data-primary='color_14'] .alert-alt.alert-primary.solid {
  border-left: 4px solid #082b54 !important;
}

[data-primary='color_14'] .alert.alert-primary.solid {
  background: #1367c8;
  border-color: #1367c8;
}

[data-primary='color_14'] .alert.alert-outline-primary {
  color: #1367c8;
  border-color: #1367c8;
}

[data-primary='color_14'] .badge-outline-primary {
  border: 1px solid #1367c8;
  color: #1367c8;
}

[data-primary='color_14'] .badge-primary {
  background-color: #1367c8;
}

[data-primary='color_14'] .page-titles h4 {
  color: #1367c8;
}

[data-primary='color_14'] .card-action > a {
  background: black;
}

[data-primary='color_14'] .card-action .dropdown {
  background: black;
  color: #1367c8;
}

[data-primary='color_14'] .card-action .dropdown:hover,
[data-primary='color_14'] .card-action .dropdown:focus {
  background: black;
}

[data-primary='color_14'] .card-loader i {
  background: #1056a7;
}

[data-primary='color_14'] .dropdown-outline {
  border: 0.1rem solid #1367c8;
}

[data-primary='color_14'] .custom-dropdown .dropdown-menu .dropdown-item:hover {
  color: #1367c8;
}

[data-primary='color_14'] .card-action .custom-dropdown {
  background: #81b6f3;
}

[data-primary='color_14'] .card-action .custom-dropdown.show,
[data-primary='color_14'] .card-action .custom-dropdown:focus,
[data-primary='color_14'] .card-action .custom-dropdown:hover {
  background: #1367c8;
}

[data-primary='color_14'] .label-primary {
  background: #1367c8;
}

[data-primary='color_14'] .pagination .page-item .page-link:hover {
  background: #1367c8;
  border-color: #1367c8;
}

[data-primary='color_14'] .pagination .page-item.active .page-link {
  background-color: #1367c8;
  border-color: #1367c8;
}

[data-primary='color_14']
  .bootstrap-popover-wrapper
  .bootstrap-popover
  button:hover,
[data-primary='color_14']
  .bootstrap-popover-wrapper
  .bootstrap-popover
  button:focus {
  background: #1367c8;
}

[data-primary='color_14'] .progress-bar {
  background-color: #1367c8;
}

[data-primary='color_14'] .progress-bar-primary {
  background-color: #1367c8;
}

[data-primary='color_14'] .ribbon__four {
  background-color: #1367c8;
}

[data-primary='color_14'] .ribbon__four:after,
[data-primary='color_14'] .ribbon__four:before {
  background-color: #529bef;
}

[data-primary='color_14'] .ribbon__five {
  background-color: #1367c8;
}

[data-primary='color_14'] .ribbon__five::before {
  border-color: transparent transparent #1367c8 transparent;
}

[data-primary='color_14'] .ribbon__six {
  background-color: #1367c8;
}

[data-primary='color_14'] .multi-steps > li {
  color: #1367c8;
}

[data-primary='color_14'] .multi-steps > li:after {
  background-color: #1367c8;
}

[data-primary='color_14'] .multi-steps > li.is-active:before {
  border-color: #1367c8;
}

[data-primary='color_14'] .timeline-badge.primary {
  background-color: #1367c8 !important;
}

[data-primary='color_14'] .tooltip-wrapper button:hover {
  background: #1367c8;
}

[data-primary='color_14'] .chart_widget_tab_one .nav-link.active {
  background-color: #1367c8;
  border: 1px solid #1367c8;
}

[data-primary='color_14'] .chart_widget_tab_one .nav-link.active:hover {
  border: 1px solid #1367c8;
}

[data-primary='color_14'] .social-icon2 a {
  border: 0.1rem solid #1367c8;
}

[data-primary='color_14'] .social-icon2 i {
  color: #1367c8;
}

[data-primary='color_14'] .social-icon3 ul li a:hover i {
  color: #1367c8;
}

[data-primary='color_14'] .bgl-primary {
  background: #81b6f3;
  border-color: #81b6f3;
  color: #1367c8;
}

[data-primary='color_14'] .tdl-holder input[type='checkbox']:checked + i {
  background: #1367c8;
}

[data-primary='color_14'] .footer .copyright a {
  color: #1367c8;
}

[data-primary='color_14'] .hamburger .line {
  background: #1367c8;
}

[data-primary='color_14'] svg.pulse-svg .first-circle,
[data-primary='color_14'] svg.pulse-svg .second-circle,
[data-primary='color_14'] svg.pulse-svg .third-circle {
  fill: #1367c8;
}

[data-primary='color_14'] .pulse-css {
  background: #1367c8;
}

[data-primary='color_14'] .pulse-css:after,
[data-primary='color_14'] .pulse-css:before {
  background-color: #1367c8;
}

[data-primary='color_14']
  .notification_dropdown
  .dropdown-menu-right
  .notification_title {
  background: #1367c8;
}

[data-primary='color_14'] .header-right .header-profile .dropdown-menu a:hover,
[data-primary='color_14'] .header-right .header-profile .dropdown-menu a:focus,
[data-primary='color_14']
  .header-right
  .header-profile
  .dropdown-menu
  a.active {
  color: #1367c8;
}

[data-primary='color_14'] .header-right .header-profile .profile_title {
  background: #1367c8;
}

[data-primary='color_14'][data-sidebar-style='full'][data-layout='vertical']
  .menu-toggle
  .nav-header
  .nav-control
  .hamburger
  .line {
  background-color: #1367c8 !important;
}

[data-primary='color_14'] .deznav .metismenu > li > a svg {
  color: #1367c8;
}

[data-primary='color_14'] .deznav .metismenu > li:hover > a,
[data-primary='color_14'] .deznav .metismenu > li:focus > a {
  color: #1367c8;
}

[data-primary='color_14'] .deznav .metismenu > li.mm-active > a {
  color: #1367c8;
}

[data-primary='color_14'] .deznav .metismenu ul a:hover,
[data-primary='color_14'] .deznav .metismenu ul a:focus,
[data-primary='color_14'] .deznav .metismenu ul a.mm-active {
  color: #1367c8;
}

@media (min-width: 767px) {
  [data-primary='color_14']
    [data-sidebar-style='modern']
    .deznav
    .metismenu
    > li
    > a:hover
    > a,
  [data-primary='color_14']
    [data-sidebar-style='modern']
    .deznav
    .metismenu
    > li
    > a:focus
    > a,
  [data-primary='color_14']
    [data-sidebar-style='modern']
    .deznav
    .metismenu
    > li
    > a:active
    > a,
  [data-primary='color_14']
    [data-sidebar-style='modern']
    .deznav
    .metismenu
    > li
    > a.mm-active
    > a {
    background-color: #deecfc;
  }
}

[data-primary='color_14'][data-sidebar-style='overlay']
  .nav-header
  .hamburger.is-active
  .line {
  background-color: #1367c8;
}

[data-primary='color_14'] .nav-user {
  background: #1367c8;
}

[data-primary='color_14'] .sidebar-right .sidebar-right .sidebar-right-trigger {
  color: #1367c8;
}

[data-primary='color_14']
  .sidebar-right
  .sidebar-right
  .sidebar-right-trigger:hover {
  color: #1367c8;
}

[data-primary='color_14'][data-theme-version='dark']
  .pagination
  .page-item
  .page-link:hover {
  background: #1367c8;
  border-color: #1367c8;
}

[data-primary='color_14'][data-theme-version='dark']
  .pagination
  .page-item.active
  .page-link {
  background: #1367c8;
  border-color: #1367c8;
}

[data-primary='color_14'][data-theme-version='dark'] .header-left input:focus {
  border-color: #1367c8;
}

[data-primary='color_14'][data-theme-version='dark'] .loader__bar {
  background: #1367c8;
}

[data-primary='color_14'][data-theme-version='dark'] .loader__ball {
  background: #1367c8;
}

[data-primary='color_14'][data-theme-version='transparent']
  .header-left
  input:focus {
  border-color: #1367c8;
}

[data-primary='color_14'] .new-arrival-content .price {
  color: #1367c8;
}

[data-primary='color_14'] .chart-link a i.text-primary {
  color: #1367c8;
}

[data-primary='color_14'] #user-activity .nav-tabs .nav-link.active {
  background: #1367c8;
  border-color: #1367c8;
}

[data-primary='color_14'] span#counter {
  color: #1367c8;
}

[data-primary='color_14'] .welcome-content:after {
  background: #1367c8;
}

[data-primary='color_14'] .timeline-badge {
  background-color: #1367c8;
}

[data-primary='color_14']
  .page-timeline
  .timeline-workplan[data-primary='color_14']
  .page-timeline
  .timeline
  .timeline-badge:after {
  background-color: rgba(19, 103, 200, 0.4);
}

[data-primary='color_14'] .sk-three-bounce .sk-child {
  background-color: #1367c8;
}

[data-primary='color_14'] .dropdown-item.active,
[data-primary='color_14'] .dropdown-item:active {
  color: #fff;
  background-color: #1367c8;
}

[data-primary='color_14'] .overlay-box:after {
  background: #1367c8;
}

[data-primary='color_14'] .btn-primary {
  background-color: #1367c8;
  border-color: #1367c8;
}

[data-primary='color_14'] .bg-primary {
  background-color: #1367c8 !important;
}

[data-primary='color_14'] .text-primary {
  color: #1367c8 !important;
}

[data-primary='color_14'] .btn-primary:hover {
  background-color: #0c4382;
  border-color: #0c4382;
}

[data-primary='color_14'] .btn-outline-primary {
  color: #1367c8;
  border-color: #1367c8;
}

[data-primary='color_14'] .btn-outline-primary:hover {
  background-color: #1367c8;
  border-color: #1367c8;
}

[data-primary='color_14'] .btn-primary:not(:disabled):not(.disabled):active,
[data-primary='color_14'] .btn-primary:not(:disabled):not(.disabled).active,
[data-primary='color_14'] .show > .btn-primary.dropdown-toggle,
[data-primary='color_14']
  .btn-outline-primary:not(:disabled):not(.disabled):active,
[data-primary='color_14']
  .btn-outline-primary:not(:disabled):not(.disabled).active,
[data-primary='color_14'] .show > .btn-outline-primary.dropdown-toggle {
  background-color: #0c4382;
  border-color: #0c4382;
}

[data-primary='color_15'] a:hover,
[data-primary='color_15'] a:focus,
[data-primary='color_15'] a.active {
  color: #ed0b4c;
}

[data-primary='color_15'] [data-class='bg-primary']:before {
  background: #ed0b4c;
}

[data-primary='color_15'] .email-left-box .intro-title {
  background: rgba(237, 11, 76, 0.1);
}

[data-primary='color_15'] .email-left-box .intro-title i {
  color: #ed0b4c;
}

[data-primary='color_15'] .widget-stat .media .media-body h4 {
  color: #ed0b4c !important;
}

[data-primary='color_15'] .email-right-box .right-box-border {
  border-right: 2px solid rgba(237, 11, 76, 0.1);
}

[data-primary='color_15'] .mail-list .list-group-item.active i {
  color: #ed0b4c;
}

[data-primary='color_15'] .single-mail.active {
  background: #ed0b4c;
}

[data-primary='color_15'] .profile-info h4.text-primary {
  color: #ed0b4c !important;
}

[data-primary='color_15'] .profile-tab .nav-item .nav-link:hover,
[data-primary='color_15'] .profile-tab .nav-item .nav-link.active {
  border-bottom: 0.2px solid #ed0b4c;
  color: #ed0b4c;
}

[data-primary='color_15'] .amChartsInputField {
  border: 0;
  background: #ed0b4c;
}

[data-primary='color_15'] .amcharts-period-input,
[data-primary='color_15'] .amcharts-period-input-selected {
  background: #ed0b4c;
}

[data-primary='color_15'] .morris-hover {
  background: #ed0b4c;
}

[data-primary='color_15']
  .select2-container--default
  .select2-results__option--highlighted[aria-selected] {
  background-color: #ed0b4c;
}

[data-primary='color_15'] .custom-select:focus {
  border-color: #ed0b4c;
  color: #ed0b4c;
}

[data-primary='color_15'] .daterangepicker td.active {
  background-color: #ed0b4c;
}

[data-primary='color_15'] .daterangepicker td.active:hover {
  background-color: #ed0b4c;
}

[data-primary='color_15'] .daterangepicker button.applyBtn {
  background-color: #ed0b4c;
  border-color: #ed0b4c;
}

[data-primary='color_15'] .wizard > .steps li.current a {
  background-color: #ed0b4c;
}

[data-primary='color_15'] .wizard .skip-email a {
  color: #ed0b4c;
}

[data-primary='color_15'] .wizard > .actions li:not(.disabled) a {
  background-color: #ed0b4c;
}

[data-primary='color_15']
  .step-form-horizontal
  .wizard
  .steps
  li.done
  a
  .number {
  background: #ed0b4c;
}

[data-primary='color_15']
  .step-form-horizontal
  .wizard
  .steps
  li.current
  a
  .number {
  color: #ed0b4c;
  border-color: #ed0b4c;
}

[data-primary='color_15']
  .step-form-horizontal
  .wizard
  .steps
  li.disabled
  a
  .number {
  color: #ed0b4c;
}

[data-primary='color_15']
  .step-form-horizontal
  .wizard
  .steps
  li:not(:last-child)::after {
  background-color: #ed0b4c;
}

[data-primary='color_15'] .is-invalid .input-group-prepend .input-group-text i {
  color: #f74e7e;
}

[data-primary='color_15'] .datamaps-hoverover {
  color: #ed0b4c;
  border: 1px solid rgba(237, 11, 76, 0.3);
}

[data-primary='color_15'] .jqvmap-zoomin,
[data-primary='color_15'] .jqvmap-zoomout {
  background-color: #ed0b4c;
}

[data-primary='color_15'] .table .thead-primary th {
  background-color: #ed0b4c;
}

[data-primary='color_15'] .table.primary-table-bg-hover thead th {
  background-color: #d50a44;
}

[data-primary='color_15'] .table.primary-table-bg-hover tbody tr {
  background-color: #ed0b4c;
}

[data-primary='color_15'] .table.primary-table-bg-hover tbody tr:hover {
  background-color: #f51d5b;
}

[data-primary='color_15']
  .table.primary-table-bg-hover
  tbody
  tr:not(:last-child)
  td,
[data-primary='color_15']
  .table.primary-table-bg-hover
  tbody
  tr:not(:last-child)
  th {
  border-bottom: 1px solid #d50a44;
}

[data-primary='color_15'] table.dataTable tr.selected {
  color: #ed0b4c;
}

[data-primary='color_15']
  .dataTables_wrapper
  .dataTables_paginate
  .paginate_button.current {
  color: #ed0b4c !important;
  background: rgba(237, 11, 76, 0.1);
}

[data-primary='color_15']
  .dataTables_wrapper
  .dataTables_paginate
  .paginate_button:hover {
  color: #ed0b4c !important;
  background: rgba(237, 11, 76, 0.1);
}

[data-primary='color_15'] .clipboard-btn:hover {
  background-color: #ed0b4c;
}

[data-primary='color_15'] .cd-h-timeline__dates::before {
  background: #ed0b4c;
}

[data-primary='color_15'] .cd-h-timeline__dates::after {
  background: #ed0b4c;
}

[data-primary='color_15'] .cd-h-timeline__line {
  background-color: #ed0b4c;
}

[data-primary='color_15'] .cd-h-timeline__date:after {
  border-color: #f10b4d;
  background-color: #ed0b4c;
}

[data-primary='color_15'] .cd-h-timeline__navigation {
  border-color: #f10b4d;
}

[data-primary='color_15'] .cd-h-timeline__navigation--inactive:hover {
  border-color: #f10b4d;
}

[data-primary='color_15'] .dd-handle {
  background: #ed0b4c;
}

[data-primary='color_15'] .dd-handle:hover {
  background: #ed0b4c;
}

[data-primary='color_15'] .dd3-content:hover {
  background: #ed0b4c;
}

[data-primary='color_15'] .noUi-connect {
  background-color: #ed0b4c;
}

[data-primary='color_15'] .noUi-connect.c-3-color {
  background-color: #ed0b4c;
}

[data-primary='color_15'] .noUi-horizontal .noUi-handle,
[data-primary='color_15'] .noUi-vertical .noUi-handle {
  background-color: #ed0b4c;
}

[data-primary='color_15'] #slider-toggle.off .noUi-handle {
  border-color: #ed0b4c;
}

[data-primary='color_15'] .pignose-calendar {
  border-color: #ed0b4c;
}

[data-primary='color_15'] .pignose-calendar .pignose-calendar-top-date {
  background-color: #ed0b4c;
}

[data-primary='color_15']
  .pignose-calendar.pignose-calendar-blue
  .pignose-calendar-body
  .pignose-calendar-row
  .pignose-calendar-unit.pignose-calendar-unit-active
  a {
  background-color: #ed0b4c;
}

[data-primary='color_15'] .bootstrap-tagsinput .tag {
  background-color: #ed0b4c;
}

[data-primary='color_15'] .toast-success {
  background-color: #ed0b4c;
}

[data-primary='color_15'] .twitter-typeahead .tt-menu .tt-suggestion:hover {
  background-color: #ed0b4c;
}

[data-primary='color_15'] .accordion-header-bg .accordion__header--primary {
  background-color: #ed0b4c;
}

[data-primary='color_15'] .alert-primary {
  background: #f97ea2;
  border-color: #f97ea2;
  color: #ed0b4c;
}

[data-primary='color_15'] .alert-alt.alert-primary {
  border-left: 4px solid #ed0b4c;
}

[data-primary='color_15'] .alert-alt.alert-primary.solid {
  border-left: 4px solid #730525 !important;
}

[data-primary='color_15'] .alert.alert-primary.solid {
  background: #ed0b4c;
  border-color: #ed0b4c;
}

[data-primary='color_15'] .alert.alert-outline-primary {
  color: #ed0b4c;
  border-color: #ed0b4c;
}

[data-primary='color_15'] .badge-outline-primary {
  border: 1px solid #ed0b4c;
  color: #ed0b4c;
}

[data-primary='color_15'] .badge-primary {
  background-color: #ed0b4c;
}

[data-primary='color_15'] .page-titles h4 {
  color: #ed0b4c;
}

[data-primary='color_15'] .card-action > a {
  background: black;
}

[data-primary='color_15'] .card-action .dropdown {
  background: black;
  color: #ed0b4c;
}

[data-primary='color_15'] .card-action .dropdown:hover,
[data-primary='color_15'] .card-action .dropdown:focus {
  background: black;
}

[data-primary='color_15'] .card-loader i {
  background: #cb0941;
}

[data-primary='color_15'] .dropdown-outline {
  border: 0.1rem solid #ed0b4c;
}

[data-primary='color_15'] .custom-dropdown .dropdown-menu .dropdown-item:hover {
  color: #ed0b4c;
}

[data-primary='color_15'] .card-action .custom-dropdown {
  background: #fa97b3;
}

[data-primary='color_15'] .card-action .custom-dropdown.show,
[data-primary='color_15'] .card-action .custom-dropdown:focus,
[data-primary='color_15'] .card-action .custom-dropdown:hover {
  background: #ed0b4c;
}

[data-primary='color_15'] .label-primary {
  background: #ed0b4c;
}

[data-primary='color_15'] .pagination .page-item .page-link:hover {
  background: #ed0b4c;
  border-color: #ed0b4c;
}

[data-primary='color_15'] .pagination .page-item.active .page-link {
  background-color: #ed0b4c;
  border-color: #ed0b4c;
}

[data-primary='color_15']
  .bootstrap-popover-wrapper
  .bootstrap-popover
  button:hover,
[data-primary='color_15']
  .bootstrap-popover-wrapper
  .bootstrap-popover
  button:focus {
  background: #ed0b4c;
}

[data-primary='color_15'] .progress-bar {
  background-color: #ed0b4c;
}

[data-primary='color_15'] .progress-bar-primary {
  background-color: #ed0b4c;
}

[data-primary='color_15'] .ribbon__four {
  background-color: #ed0b4c;
}

[data-primary='color_15'] .ribbon__four:after,
[data-primary='color_15'] .ribbon__four:before {
  background-color: #f86690;
}

[data-primary='color_15'] .ribbon__five {
  background-color: #ed0b4c;
}

[data-primary='color_15'] .ribbon__five::before {
  border-color: transparent transparent #ed0b4c transparent;
}

[data-primary='color_15'] .ribbon__six {
  background-color: #ed0b4c;
}

[data-primary='color_15'] .multi-steps > li {
  color: #ed0b4c;
}

[data-primary='color_15'] .multi-steps > li:after {
  background-color: #ed0b4c;
}

[data-primary='color_15'] .multi-steps > li.is-active:before {
  border-color: #ed0b4c;
}

[data-primary='color_15'] .timeline-badge.primary {
  background-color: #ed0b4c !important;
}

[data-primary='color_15'] .tooltip-wrapper button:hover {
  background: #ed0b4c;
}

[data-primary='color_15'] .chart_widget_tab_one .nav-link.active {
  background-color: #ed0b4c;
  border: 1px solid #ed0b4c;
}

[data-primary='color_15'] .chart_widget_tab_one .nav-link.active:hover {
  border: 1px solid #ed0b4c;
}

[data-primary='color_15'] .social-icon2 a {
  border: 0.1rem solid #ed0b4c;
}

[data-primary='color_15'] .social-icon2 i {
  color: #ed0b4c;
}

[data-primary='color_15'] .social-icon3 ul li a:hover i {
  color: #ed0b4c;
}

[data-primary='color_15'] .bgl-primary {
  background: #fa97b3;
  border-color: #fa97b3;
  color: #ed0b4c;
}

[data-primary='color_15'] .tdl-holder input[type='checkbox']:checked + i {
  background: #ed0b4c;
}

[data-primary='color_15'] .footer .copyright a {
  color: #ed0b4c;
}

[data-primary='color_15'] .hamburger .line {
  background: #ed0b4c;
}

[data-primary='color_15'] svg.pulse-svg .first-circle,
[data-primary='color_15'] svg.pulse-svg .second-circle,
[data-primary='color_15'] svg.pulse-svg .third-circle {
  fill: #ed0b4c;
}

[data-primary='color_15'] .pulse-css {
  background: #ed0b4c;
}

[data-primary='color_15'] .pulse-css:after,
[data-primary='color_15'] .pulse-css:before {
  background-color: #ed0b4c;
}

[data-primary='color_15']
  .notification_dropdown
  .dropdown-menu-right
  .notification_title {
  background: #ed0b4c;
}

[data-primary='color_15'] .header-right .header-profile .dropdown-menu a:hover,
[data-primary='color_15'] .header-right .header-profile .dropdown-menu a:focus,
[data-primary='color_15']
  .header-right
  .header-profile
  .dropdown-menu
  a.active {
  color: #ed0b4c;
}

[data-primary='color_15'] .header-right .header-profile .profile_title {
  background: #ed0b4c;
}

[data-primary='color_15'][data-sidebar-style='full'][data-layout='vertical']
  .menu-toggle
  .nav-header
  .nav-control
  .hamburger
  .line {
  background-color: #ed0b4c !important;
}

[data-primary='color_15'] .deznav .metismenu > li > a svg {
  color: #ed0b4c;
}

[data-primary='color_15'] .deznav .metismenu > li:hover > a,
[data-primary='color_15'] .deznav .metismenu > li:focus > a {
  color: #ed0b4c;
}

[data-primary='color_15'] .deznav .metismenu > li.mm-active > a {
  color: #ed0b4c;
}

[data-primary='color_15'] .deznav .metismenu ul a:hover,
[data-primary='color_15'] .deznav .metismenu ul a:focus,
[data-primary='color_15'] .deznav .metismenu ul a.mm-active {
  color: #ed0b4c;
}

@media (min-width: 767px) {
  [data-primary='color_15']
    [data-sidebar-style='modern']
    .deznav
    .metismenu
    > li
    > a:hover
    > a,
  [data-primary='color_15']
    [data-sidebar-style='modern']
    .deznav
    .metismenu
    > li
    > a:focus
    > a,
  [data-primary='color_15']
    [data-sidebar-style='modern']
    .deznav
    .metismenu
    > li
    > a:active
    > a,
  [data-primary='color_15']
    [data-sidebar-style='modern']
    .deznav
    .metismenu
    > li
    > a.mm-active
    > a {
    background-color: #fff8fa;
  }
}

[data-primary='color_15'][data-sidebar-style='overlay']
  .nav-header
  .hamburger.is-active
  .line {
  background-color: #ed0b4c;
}

[data-primary='color_15'] .nav-user {
  background: #ed0b4c;
}

[data-primary='color_15'] .sidebar-right .sidebar-right .sidebar-right-trigger {
  color: #ed0b4c;
}

[data-primary='color_15']
  .sidebar-right
  .sidebar-right
  .sidebar-right-trigger:hover {
  color: #ed0b4c;
}

[data-primary='color_15'][data-theme-version='dark']
  .pagination
  .page-item
  .page-link:hover {
  background: #ed0b4c;
  border-color: #ed0b4c;
}

[data-primary='color_15'][data-theme-version='dark']
  .pagination
  .page-item.active
  .page-link {
  background: #ed0b4c;
  border-color: #ed0b4c;
}

[data-primary='color_15'][data-theme-version='dark'] .header-left input:focus {
  border-color: #ed0b4c;
}

[data-primary='color_15'][data-theme-version='dark'] .loader__bar {
  background: #ed0b4c;
}

[data-primary='color_15'][data-theme-version='dark'] .loader__ball {
  background: #ed0b4c;
}

[data-primary='color_15'][data-theme-version='transparent']
  .header-left
  input:focus {
  border-color: #ed0b4c;
}

[data-primary='color_15'] .new-arrival-content .price {
  color: #ed0b4c;
}

[data-primary='color_15'] .chart-link a i.text-primary {
  color: #ed0b4c;
}

[data-primary='color_15'] #user-activity .nav-tabs .nav-link.active {
  background: #ed0b4c;
  border-color: #ed0b4c;
}

[data-primary='color_15'] span#counter {
  color: #ed0b4c;
}

[data-primary='color_15'] .welcome-content:after {
  background: #ed0b4c;
}

[data-primary='color_15'] .timeline-badge {
  background-color: #ed0b4c;
}

[data-primary='color_15']
  .page-timeline
  .timeline-workplan[data-primary='color_15']
  .page-timeline
  .timeline
  .timeline-badge:after {
  background-color: rgba(237, 11, 76, 0.4);
}

[data-primary='color_15'] .sk-three-bounce .sk-child {
  background-color: #ed0b4c;
}

[data-primary='color_15'] .dropdown-item.active,
[data-primary='color_15'] .dropdown-item:active {
  color: #fff;
  background-color: #ed0b4c;
}

[data-primary='color_15'] .overlay-box:after {
  background: #ed0b4c;
}

[data-primary='color_15'] .btn-primary {
  background-color: #ed0b4c;
  border-color: #ed0b4c;
}

[data-primary='color_15'] .bg-primary {
  background-color: #ed0b4c !important;
}

[data-primary='color_15'] .text-primary {
  color: #ed0b4c !important;
}

[data-primary='color_15'] .btn-primary:hover {
  background-color: #a40835;
  border-color: #a40835;
}

[data-primary='color_15'] .btn-outline-primary {
  color: #ed0b4c;
  border-color: #ed0b4c;
}

[data-primary='color_15'] .btn-outline-primary:hover {
  background-color: #ed0b4c;
  border-color: #ed0b4c;
}

[data-primary='color_15'] .btn-primary:not(:disabled):not(.disabled):active,
[data-primary='color_15'] .btn-primary:not(:disabled):not(.disabled).active,
[data-primary='color_15'] .show > .btn-primary.dropdown-toggle,
[data-primary='color_15']
  .btn-outline-primary:not(:disabled):not(.disabled):active,
[data-primary='color_15']
  .btn-outline-primary:not(:disabled):not(.disabled).active,
[data-primary='color_15'] .show > .btn-outline-primary.dropdown-toggle {
  background-color: #a40835;
  border-color: #a40835;
}

.bg-primary-alt {
  background-color: rgba(11, 42, 151, 0.1);
}

.bg-secondary-alt {
  background-color: rgba(160, 44, 250, 0.1);
}

.bg-success-alt {
  background-color: rgba(43, 193, 85, 0.1);
}

.bg-info-alt {
  background-color: rgba(30, 167, 197, 0.1);
}

.bg-warning-alt {
  background-color: rgba(255, 188, 17, 0.1);
}

.bg-danger-alt {
  background-color: rgba(249, 70, 135, 0.1);
}

.bg-light-alt {
  background-color: rgba(244, 245, 249, 0.1);
}

.bg-dark-alt {
  background-color: rgba(177, 177, 177, 0.1);
}

[data-typography='opensans'] {
  font-family: 'Open Sans', sans-serif;
}

[data-typography='poppins'] {
  font-family: 'poppins', sans-serif;
}

[data-typography='roboto'] {
  font-family: 'Roboto', sans-serif;
}

[data-typography='helvetica'] {
  font-family: 'HelveticaNeue';
}

/*$primary  : #7356f1 !default;*/

[data-theme-version='transparent'] {
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  background-position: center;
  position: relative;
  color: #fff;
}

[data-theme-version='transparent'] h1,
[data-theme-version='transparent'] h2,
[data-theme-version='transparent'] h3,
[data-theme-version='transparent'] h4,
[data-theme-version='transparent'] h5,
[data-theme-version='transparent'] h6 {
  color: #fff !important;
}

[data-theme-version='transparent'] a.link {
  color: #ddd;
}

[data-theme-version='transparent'] a.link:focus,
[data-theme-version='transparent'] a.link:hover {
  color: #1ea7c5;
}

[data-theme-version='transparent'] a {
  color: #fff;
}

[data-theme-version='transparent'] a:hover {
  color: #828690;
}

[data-theme-version='transparent'] .border-right {
  border-right: 1px solid #333a54 !important;
}

[data-theme-version='transparent'] .border-left {
  border-left: 1px solid #333a54 !important;
}

[data-theme-version='transparent'] .border-top {
  border-top: 1px solid #333a54 !important;
}

[data-theme-version='transparent'] .border-bottom {
  border-bottom: 1px solid #333a54 !important;
}

[data-theme-version='transparent'] .border {
  border: 1px solid #333a54 !important;
}

[data-theme-version='transparent'] .dropdown-menu {
  background-color: #1e2746;
}

[data-theme-version='transparent'] .dropdown-item:hover,
[data-theme-version='transparent'] .dropdown-item:focus {
  background-color: #181f39;
  color: #fff;
}

[data-theme-version='transparent'] .form-control {
  background-color: #181f39;
  border-color: #333a54;
  color: #fff;
}

[data-theme-version='transparent'] .card {
  background-color: rgba(0, 0, 0, 0.15);
}

[data-theme-version='transparent'] .header {
  background-color: rgba(0, 0, 0, 0.15);
}

[data-theme-version='transparent'] .header-left input {
  border-color: #333a54;
  color: #fff;
}

[data-theme-version='transparent'] .header-left input:focus {
  box-shadow: none;
  border-color: #0b2a97;
}

[data-theme-version='transparent'] .header-left input::placeholder {
  color: #fff;
}

[data-theme-version='transparent'] .header-right .dropdown .nav-link:hover {
  color: #fff;
}

[data-theme-version='transparent']
  .header-right
  .notification_dropdown
  .dropdown-item
  a {
  color: #fff;
}

[data-theme-version='transparent'] .nav-header,
[data-theme-version='transparent'] .nav-control {
  background-color: rgba(0, 0, 0, 0.15) !important;
}

[data-theme-version='transparent'] .nav-control {
  color: #fff;
}

[data-theme-version='transparent'] .brand-logo {
  color: #fff;
}

[data-theme-version='transparent'] .brand-logo:hover {
  color: #fff;
}

[data-theme-version='transparent'] .nav-header,
[data-theme-version='transparent'] .nav-control {
  background-color: rgba(0, 0, 0, 0.15) !important;
}

[data-theme-version='transparent'] .nav-control {
  color: #fff;
}

[data-theme-version='transparent'] .brand-logo {
  color: #fff;
}

[data-theme-version='transparent'] .brand-logo:hover {
  color: #fff;
}

[data-theme-version='transparent'] .deznav {
  background-color: rgba(0, 0, 0, 0.15) !important;
}

[data-theme-version='transparent'] .deznav .metismenu > li > a {
  color: rgba(0, 0, 0, 0.15);
}

[data-theme-version='transparent'] .deznav .metismenu > li:hover > a,
[data-theme-version='transparent'] .deznav .metismenu > li:focus > a,
[data-theme-version='transparent'] .deznav .metismenu > li.mm-active > a {
  background-color: rgba(0, 0, 0, 0.15) !important;
  color: #fff;
}

[data-theme-version='transparent'] .deznav .metismenu > li:hover > a::after,
[data-theme-version='transparent'] .deznav .metismenu > li:focus > a::after,
[data-theme-version='transparent']
  .deznav
  .metismenu
  > li.mm-active
  > a::after {
  border-color: transparent transparent #fff transparent;
}

[data-theme-version='transparent'] .deznav .metismenu > li.mm-active ul ul {
  background-color: transparent;
}

[data-theme-version='transparent'] .deznav .metismenu ul {
  background-color: rgba(0, 0, 0, 0.15);
}

[data-theme-version='transparent'] .deznav .metismenu ul a:hover,
[data-theme-version='transparent'] .deznav .metismenu ul a:focus,
[data-theme-version='transparent'] .deznav .metismenu ul a.mm-active {
  color: #fff;
}

[data-theme-version='transparent'] .deznav .metismenu a {
  color: rgba(0, 0, 0, 0.15);
}

[data-theme-version='transparent'] .deznav .metismenu ul {
  background-color: rgba(0, 0, 0, 0.15) !important;
}

[data-theme-version='transparent'] .deznav .metismenu .has-arrow:after {
  border-color: transparent transparent rgba(0, 0, 0, 0.15) transparent;
}

/*$primary  : #7356f1 !default;*/

[data-theme-version='dark'] {
  background: #181f39;
  color: #828690;
}

[data-theme-version='dark'] h1,
[data-theme-version='dark'] h2,
[data-theme-version='dark'] h3,
[data-theme-version='dark'] h4,
[data-theme-version='dark'] h5,
[data-theme-version='dark'] h6 {
  color: #fff !important;
}

[data-theme-version='dark'] a.link {
  color: #ddd;
}

[data-theme-version='dark'] a.link:focus,
[data-theme-version='dark'] a.link:hover {
  color: #1ea7c5;
}

[data-theme-version='dark'] a:hover {
  color: #fff;
}

[data-theme-version='dark'] .border-right {
  border-right: 1px solid #333a54 !important;
}

[data-theme-version='dark'] .border-left {
  border-left: 1px solid #333a54 !important;
}

[data-theme-version='dark'] .border-top {
  border-top: 1px solid #333a54 !important;
}

[data-theme-version='dark'] .border-bottom {
  border-bottom: 1px solid #333a54 !important;
}

[data-theme-version='dark'] .border {
  border: 1px solid #333a54 !important;
}

[data-theme-version='dark'] .dropdown-menu {
  background-color: #181f39;
  box-shadow: 0px 0px 0px 1px rgba(255, 255, 255, 0.1);
}

[data-theme-version='dark'] .dropdown-menu .dropdown-item.selected,
[data-theme-version='dark'] .dropdown-menu .dropdown-item.selected.active,
[data-theme-version='dark'] .dropdown-menu .dropdown-item.active,
[data-theme-version='dark'] .dropdown-menu .dropdown-item:hover,
[data-theme-version='dark'] .dropdown-menu .dropdown-item:focus {
  background-color: #1e2746;
  color: #7e7e7e;
}

[data-theme-version='dark'] .form-control {
  background-color: #181f39;
  border-color: #333a54;
  color: #fff;
}

[data-theme-version='dark'] .card {
  background-color: #1e2746;
  box-shadow: none;
}

[data-theme-version='dark'] .btn-outline-dark:hover {
  background-color: #181f39;
  border-color: #181f39;
}

[data-theme-version='dark'] .tdl-holder input[type='text'],
[data-theme-version='dark'] .tdl-holder li {
  background-color: #1e2746;
  border-color: #333a54;
}

[data-theme-version='dark'] .tdl-holder input[type='text']::placeholder {
  color: #828690;
}

[data-theme-version='dark'] .tdl-holder label:hover {
  background-color: #333a54;
  color: #fff;
}

[data-theme-version='dark'] .text-muted {
  color: #828690 !important;
}

[data-theme-version='dark'] .modal-content {
  background: #1e2746;
}

[data-theme-version='dark'] .modal-footer,
[data-theme-version='dark'] .modal-header {
  border-color: #333a54;
}

[data-theme-version='dark'] .close {
  font-weight: 400;
  color: #fff;
  text-shadow: none;
}

[data-theme-version='dark'] .close:hover {
  color: #fff;
}

[data-theme-version='dark'] .table strong {
  color: #fff;
}

[data-theme-version='dark'] .text-dark {
  color: #828690 !important;
}

[data-theme-version='dark'] .star-rating .product-review,
[data-theme-version='dark'] .new-arrival-content .item {
  color: #fff;
}

[data-theme-version='dark'] .custom-dropdown .dropdown-menu {
  border-color: #333a54;
}

[data-theme-version='dark'] .widget-stat .media > span {
  background: #333a54;
  border-color: #333a54;
  color: #fff;
}

[data-theme-version='dark'] .plus-minus-input .custom-btn {
  background: #181f39;
  border-color: #333a54;
}

[data-theme-version='dark'] .dropdown-divider,
[data-theme-version='dark'] .size-filter ul li {
  border-color: #333a54;
}

[data-theme-version='dark'] .custom-select {
  border-color: #333a54;
  color: #828690;
  background: #181f39;
}

[data-theme-version='dark'] .nav-tabs {
  border-color: #333a54;
}

[data-theme-version='dark'] .mail-list .list-group-item.active i {
  color: #fff;
}

[data-theme-version='dark'] hr {
  border-color: #333a54;
}

[data-theme-version='dark'] .grid-col {
  background: #181f39;
}

[data-theme-version='dark'] .noUi-target {
  border-color: #333a54;
  border-radius: 8px;
  box-shadow: none;
}

[data-theme-version='dark'] .noUi-target .noUi-connects {
  background: #333a54;
}

[data-theme-version='dark'] .noUi-marker-large,
[data-theme-version='dark'] .noUi-marker {
  background: #333a54;
}

[data-theme-version='dark'] .input-group-text {
  background: #333a54;
  color: #7e7e7e;
}

[data-theme-version='dark'] .note-editor.note-frame {
  border-color: #333a54;
}

[data-theme-version='dark'] .note-editor.note-frame .btn {
  color: #fff;
}

[data-theme-version='dark']
  .note-editor.note-frame
  .note-editing-area
  .note-editable {
  color: #fff;
}

[data-theme-version='dark'] .widget-media .timeline .timeline-panel {
  border-color: #333a54;
}

[data-theme-version='dark']
  .notification_dropdown
  .dropdown-menu-right
  .all-notification {
  border-color: #333a54;
}

[data-theme-version='dark'] #user-activity .nav-tabs .nav-link {
  border-color: #333a54;
}

[data-theme-version='dark'] .list-group-item-action {
  color: #7e7e7e;
}

[data-theme-version='dark'] .list-group-item-action:focus,
[data-theme-version='dark'] .list-group-item-action:hover,
[data-theme-version='dark'] .list-group-item-action:focus {
  background-color: #181f39;
  border-color: #181f39;
}

[data-theme-version='dark'] .list-group-item.active {
  color: #fff;
  border-color: #0b2a97;
}

[data-theme-version='dark'] .list-group-item.active:focus,
[data-theme-version='dark'] .list-group-item.active:hover,
[data-theme-version='dark'] .list-group-item.active:focus {
  background-color: #0b2a97;
  border-color: #0b2a97;
  color: #fff;
}

[data-theme-version='dark'] .swal2-popup {
  background: #1e2746;
}

[data-theme-version='dark'] .header {
  background-color: #1e2746;
}

[data-theme-version='dark'] .header-left input {
  background: #181f39 !important;
  border-color: transparent;
  color: #fff !important;
}

[data-theme-version='dark'] .header-left input:focus {
  box-shadow: none;
  border-color: #0b2a97;
}

[data-theme-version='dark'] .header-left input::placeholder {
  color: #fff;
}

[data-theme-version='dark'] .header-left .search_icon {
  background: #181f39 !important;
}

[data-theme-version='dark'] .header-left .search_icon i {
  color: #fff;
}

[data-theme-version='dark'] .header-right .right-sidebar a {
  color: #0b2a97;
  border-color: #333a54;
}

[data-theme-version='dark']
  .header-right
  .header-profile
  > a.nav-link
  .header-info
  strong {
  color: #fff;
}

[data-theme-version='dark'] .header-right .notification_dropdown .nav-link {
  background: #181f39;
}

[data-theme-version='dark'] .header-right .dropdown .nav-link {
  color: #fff;
}

[data-theme-version='dark'] .header-right .dropdown .nav-link:hover {
  color: #fff;
}

[data-theme-version='dark']
  .header-right
  .notification_dropdown
  .dropdown-item
  a {
  color: #fff;
}

[data-theme-version='dark'] .nav-header,
[data-theme-version='dark'] .nav-control {
  background-color: #181f39;
}

[data-theme-version='dark'] .nav-control {
  color: #fff;
}

[data-theme-version='dark'] .brand-logo {
  color: #fff;
}

[data-theme-version='dark'] .brand-logo:hover {
  color: #fff;
}

[data-theme-version='dark'] .nav-header,
[data-theme-version='dark'] .nav-control {
  background-color: #181f39;
}

[data-theme-version='dark'] .nav-control {
  color: #fff;
}

[data-theme-version='dark'] .brand-logo {
  color: #fff;
}

[data-theme-version='dark'] .brand-logo:hover {
  color: #fff;
}

[data-theme-version='dark'][data-sidebar-style='mini']
  .deznav
  .metismenu
  li
  > ul {
  background-color: #1e2746;
}

[data-theme-version='dark'] .fixed-content-box {
  background-color: #1e2746;
}

[data-theme-version='dark'] .fixed-content-box .head-name {
  background: #1e2746;
  color: #fff;
  border-color: #333a54;
}

[data-theme-version='dark'] .fixed-content-box + .header + .deznav {
  background-color: #181f39;
}

[data-theme-version='dark'] .deznav {
  background-color: #1e2746;
}

[data-theme-version='dark'] .deznav .metismenu > li > a {
  color: #b3b3b3;
}

[data-theme-version='dark'] .deznav .metismenu > li:hover > a,
[data-theme-version='dark'] .deznav .metismenu > li:focus > a,
[data-theme-version='dark'] .deznav .metismenu > li.mm-active > a {
  color: #fff;
}

[data-theme-version='dark'] .deznav .metismenu > li:hover > a::after,
[data-theme-version='dark'] .deznav .metismenu > li:focus > a::after,
[data-theme-version='dark'] .deznav .metismenu > li.mm-active > a::after {
  border-color: #fff;
}

[data-theme-version='dark'] .deznav .metismenu > li.mm-active ul ul {
  background-color: transparent;
}

[data-theme-version='dark'] .deznav .metismenu ul:after {
  background-color: #333a54;
}

[data-theme-version='dark'] .deznav .metismenu ul a:hover,
[data-theme-version='dark'] .deznav .metismenu ul a:focus,
[data-theme-version='dark'] .deznav .metismenu ul a.mm-active {
  color: #fff;
}

[data-theme-version='dark'] .deznav .metismenu ul a:before {
  background-color: #fff;
}

[data-theme-version='dark'] .deznav .metismenu a {
  color: #b3b3b3;
}

[data-theme-version='dark'] .deznav .metismenu .has-arrow:after {
  border-color: #fff;
}

[data-theme-version='dark'] .sidebar-right {
  background-color: #1d2544;
}

[data-theme-version='dark'] .sidebar-right .nav-tabs {
  background-color: #1d2544;
  border-bottom: 1px solid #181f39;
}

[data-theme-version='dark'] .sidebar-right .nav-tabs .nav-link.active {
  background-color: #181f39;
}

[data-theme-version='dark'] .footer {
  background-color: transparent;
}

[data-theme-version='dark'] .footer .copyright {
  background-color: transparent;
}

[data-theme-version='dark'] .footer .copyright p {
  color: #fff;
}

/* =============
Calendar
============= */

.app-fullcalender button {
  border-radius: 0px;
  color: #b1b1b1;
}

.app-fullcalender td {
  border-color: #f0f1f5;
}

.calendar {
  float: left;
  margin-bottom: 0;
}

.fc-view {
  margin-top: 1.875rem;
}

.fc-toolbar {
  margin-bottom: 0.3125rem;
  margin-top: 0.9375rem;
}

@media (max-width: 575.98px) {
  .fc-toolbar .fc-left {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.625rem;
    float: none;
  }
}

@media (max-width: 575.98px) {
  .fc-toolbar .fc-right {
    display: flex;
    justify-content: center;
    float: none;
    margin-bottom: 0.3125rem;
  }
}

@media (max-width: 575.98px) {
  .fc-toolbar .fc-center {
    display: flex;
    justify-content: center;
  }
  .fc-toolbar .fc-center * {
    float: none;
  }
}

.fc-toolbar h2 {
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.875rem;
  text-transform: uppercase;
}

.fc-toolbar .fc-state-active,
.fc-toolbar .ui-state-active,
.fc-toolbar .ui-state-hover,
.fc-toolbar button:focus,
.fc-toolbar button:hover {
  z-index: 0;
  box-shadow: none;
}

.fc-widget-header {
  border: 1px solid #f0f1f5;
  border-bottom: 0 !important;
}

.fc th.fc-widget-header {
  background: #f0f1f5 !important;
  font-size: 0.875rem;
  line-height: 1.25rem;
  padding: 0.625rem 0;
  text-transform: uppercase;
}

.fc-button {
  border: 1px solid #f0f1f5;
  text-transform: capitalize;
}

.fc-button.active {
  box-shadow: none !important;
}

.fc-text-arrow {
  font-family: inherit;
  font-size: 1rem;
}

.fc-event {
  border-radius: 0.125rem;
  border: none;
  cursor: move;
  font-size: 0.8125rem;
  margin: 0.3125rem 0.4375rem;
  padding: 0.3125rem;
  text-align: center;
}

.external-event {
  cursor: move;
  margin: 0.625rem 0;
  padding: 0.125rem 0;
}

.fc-basic-view td.fc-week-number span {
  padding-right: 0.3125rem;
}

.fc-basic-view td.fc-day-number {
  padding-right: 0.3125rem;
}

#drop-remove {
  margin-right: 8px;
  top: 0.1875rem;
}

#add-category .modal-dialog,
#event-modal .modal-dialog {
  max-width: 37.5rem;
}

.fc-content {
  color: #fff;
}

.fc th.fc-widget-header {
  background: transparent !important;
}

.fc-button {
  background: #fff;
}

.fc-state-hover {
  background: #fff !important;
}

.fc-state-highlight {
  background: #f2f4fa !important;
}

[data-theme-version='dark'] .fc-state-highlight {
  color: #fff !important;
}

.fc-cell-overlay {
  background: #fff !important;
}

.fc-unthemed .fc-today {
  background: #f2f4fa !important;
}

.fc-day-top {
  color: #b1b1b1 !important;
}

[data-theme-version='dark'] .fc-day-top {
  color: #fff !important;
}

.external-event {
  color: #fff;
}

[data-theme-version='dark'] .external-event {
  color: #fff !important;
}

.fc-basic-view .fc-body .fc-row {
  min-height: 1rem;
}

.fc-scroller.fc-day-grid-container {
  height: 490px !important;
}

.fc-row.fc-week.fc-widget-content.fc-rigid {
  height: 81px !important;
}

@media only screen and (max-width: 1440px) {
  .email_left_pane {
    display: none;
  }
}

#external-events .external-event:before {
  content: '';
  display: inline-block;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  margin-right: 0.9rem;
  position: relative;
  top: 2px;
}

#external-events [data-class='bg-primary'] {
  background-color: transparent !important;
  color: #b1b1b1;
}

[data-theme-version='dark'] #external-events [data-class='bg-primary'] {
  color: #fff !important;
}

#external-events [data-class='bg-primary']::before {
  background: #0b2a97;
}

#external-events [data-class='bg-success'] {
  background-color: transparent !important;
  color: #b1b1b1;
}

#external-events [data-class='bg-success']::before {
  background: #2bc155;
}

#external-events [data-class='bg-warning'] {
  background-color: transparent !important;
  color: #b1b1b1;
}

#external-events [data-class='bg-warning']::before {
  background: #ffbc11;
}

#external-events [data-class='bg-dark'] {
  background-color: transparent !important;
  color: #b1b1b1;
}

#external-events [data-class='bg-dark']::before {
  background: #b1b1b1;
}

#external-events [data-class='bg-danger'] {
  background-color: transparent !important;
  color: #b1b1b1;
}

#external-events [data-class='bg-danger']::before {
  background: #f94687;
}

#external-events [data-class='bg-info'] {
  background-color: transparent !important;
  color: #b1b1b1;
}

#external-events [data-class='bg-info']::before {
  background: #1ea7c5;
}

#external-events [data-class='bg-pink'] {
  background-color: transparent !important;
  color: #b1b1b1;
}

#external-events [data-class='bg-pink']::before {
  background: #e83e8c;
}

.fc .fc-row .fc-content-skeleton table,
.fc .fc-row .fc-content-skeleton td,
.fc .fc-row .fc-helper-skeleton td {
  border-color: #f0f1f5;
}

[data-theme-version='dark'] .fc .fc-row .fc-content-skeleton table,
[data-theme-version='dark'] .fc .fc-row .fc-content-skeleton td,
[data-theme-version='dark'] .fc .fc-row .fc-helper-skeleton td {
  border-color: #333a54;
}

[data-theme-version='dark'] .fc-unthemed .fc-content,
[data-theme-version='dark'] .fc-unthemed .fc-divider,
[data-theme-version='dark'] .fc-unthemed .fc-list-heading td,
[data-theme-version='dark'] .fc-unthemed .fc-list-view,
[data-theme-version='dark'] .fc-unthemed .fc-popover,
[data-theme-version='dark'] .fc-unthemed .fc-row,
[data-theme-version='dark'] .fc-unthemed tbody,
[data-theme-version='dark'] .fc-unthemed td,
[data-theme-version='dark'] .fc-unthemed th,
[data-theme-version='dark'] .fc-unthemed thead {
  border-color: #333a54;
}

.email-left-box {
  width: 15rem;
  float: left;
  padding: 0 1.25rem 1.25rem 1rem;
  border-top: 0;
  border-left: 0;
}

@media (min-width: 576px) and (max-width: 991.98px) {
  .email-left-box {
    width: 100%;
    padding-bottom: 0px !important;
  }
}

@media (max-width: 575.98px) {
  .email-left-box {
    width: 100%;
    float: none;
    border: none;
    padding-bottom: 30px !important;
  }
}

.email-left-box .intro-title {
  background: rgba(11, 42, 151, 0.1);
  padding: 1rem;
  margin: 1.875rem 0 1.25rem 0;
}

.email-left-box .intro-title h5 {
  margin-bottom: 0;
  color: #6a707e;
  font-size: 14px;
}

.email-left-box .intro-title h5 i {
  font-size: 0.75rem;
  position: relative;
  bottom: 1px;
}

.email-left-box .intro-title i {
  color: #0b2a97;
}

.email-right-box {
  padding-left: 15rem;
  padding-right: 1rem;
}

@media (min-width: 576px) and (max-width: 991.98px) {
  .email-right-box {
    padding-left: 0;
    margin-left: 0 !important;
    clear: both;
  }
}

@media (max-width: 575.98px) {
  .email-right-box {
    padding-left: 0;
    padding-right: 0;
  }
}

.email-right-box .right-box-border {
  border-right: 2px solid rgba(11, 42, 151, 0.1);
}

@media screen and (min-width: 649px) and (max-width: 1200px) {
  .email-right-box .right-box-padding {
    padding-left: 1.25rem;
  }
}

@media (min-width: 1700px) {
  .email-right-box .right-box-padding {
    padding-left: 0.9375rem;
  }
}

@media (min-width: 576px) and (max-width: 991.98px) {
  .email-right-box .right-box-padding {
    padding-left: 0;
  }
}

.toolbar .btn-group .btn {
  border: 0;
}

.toolbar .btn-group input {
  position: relative;
  top: 2px;
}

.read-content textarea {
  height: 150px;
  padding: 15px 20px;
}

.read-content-email {
  font-size: 0.875rem;
}

.read-content h5 {
  color: #6a707e;
}

.read-content p strong {
  color: #6a707e;
}

.read-content-body p {
  margin-bottom: 1.875rem;
}

.read-content-attachment {
  padding: 0.5rem 0;
}

.read-content-attachment h6 {
  font-size: 1.125rem;
  color: #6a707e;
}

.read-content-attachment h6 i {
  padding-right: 0.3125rem;
}

.read-content-attachment .attachment > div:not(:last-child) {
  border-right: 1px solid #dddfe1;
}

.compose-content .wysihtml5-toolbar {
  border-color: #eaeaea;
}

.compose-content .dropzone {
  background: #f2f4fa !important;
}

.compose-content h5 {
  font-size: 1.0625rem;
  color: #6a707e;
}

.compose-content h5 i {
  font-size: 1.125rem;
  transform: rotate(90deg);
}

.compose-content .dropzone {
  border: 1px dashed #dddfe1;
  min-height: 13.125rem;
  position: relative;
}

.compose-content .dropzone .dz-message {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.email-list {
  display: block;
  padding-left: 0;
}

.email-list .message {
  position: relative;
  display: block;
  height: 3.125rem;
  line-height: 3.125rem;
  cursor: default;
  transition-duration: 0.3s;
}

.email-list .message a {
  color: #828690;
}

.email-list .message-single .custom-checkbox {
  margin-top: 2px;
}

.email-list .message-single i {
  color: #89879f;
  font-size: 1.125rem;
  padding-left: 0.4rem;
}

.email-list .message:hover {
  transition-duration: 0.05s;
  background: rgba(152, 166, 173, 0.15);
}

.email-list .message .col-mail {
  float: left;
  position: relative;
}

.email-list .message .col-mail-1 {
  width: 5.625rem;
}

.email-list .message .col-mail-1 .star-toggle {
  display: block;
  float: left;
  margin-top: 1.125rem;
  font-size: 1rem;
  margin-left: 0.3125rem;
}

.email-list .message .col-mail-1 .email-checkbox {
  display: block;
  float: left;
  margin: 0.9375rem 0.625rem 0 1.25rem;
}

.email-list .message .col-mail-1 .dot {
  display: block;
  float: left;
  border: 0.4rem solid transparent;
  border-radius: 6.25rem;
  margin: 1.375rem 1.625rem 0;
  height: 0;
  width: 0;
  line-height: 0;
  font-size: 0;
}

.email-list .message .col-mail-2 {
  position: absolute;
  top: 0;
  left: 5.625rem;
  right: 0;
  bottom: 0;
}

.email-list .message .col-mail-2 .subject {
  position: absolute;
  top: 0;
  left: 0;
  right: 5.5rem;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.email-list .message .col-mail-2 .date {
  position: absolute;
  top: 0;
  right: 0;
}

.email-checkbox {
  cursor: pointer;
  height: 0.9375rem;
  width: 0.9375rem;
  position: relative;
  display: inline-block;
  border-radius: 0.1rem;
  position: relative;
  top: 0.3125rem;
  box-shadow: inset 0 0 0 0.1rem #828690;
}

.email-checkbox input {
  opacity: 0;
  cursor: pointer;
}

.email-checkbox input:checked label {
  opacity: 1;
}

.email-checkbox label {
  position: absolute;
  top: 0.3rem;
  left: 0.3rem;
  right: 0.3rem;
  bottom: 0.3rem;
  cursor: pointer;
  opacity: 0;
  margin-bottom: 0 !important;
  transition-duration: 0.05s;
}

.mail-list a {
  vertical-align: middle;
  padding: 0.625rem 0.9375rem;
  display: block;
  background: transparent;
  color: #464a53;
  font-weight: 600;
}

.mail-list .list-group-item {
  color: #6a707e;
  padding: 0.75rem 1.0625rem;
}

.mail-list .list-group-item i {
  font-size: 1rem;
  padding-right: 0.625rem;
  color: #cccccc;
}

.mail-list .list-group-item.active {
  color: #fff;
}

.mail-list .list-group-item.active i {
  color: #fff;
}

.chat-wrap {
  padding: 1.0625rem 1.875rem;
}

.chat-wrap .media .media-body h6 {
  font-size: 1.0625rem;
  color: #6a707e;
}

.chat-wrap .media .media-body p {
  font-size: 0.875rem;
}

@media (min-width: 648px) {
  .email-filter {
    padding-left: 1.25rem;
  }
}

@media (min-width: 1700px) {
  .email-filter {
    padding-left: 1.875rem;
  }
}

.email-filter .input-group-prepend i {
  font-size: 0.875rem;
  color: #89879f;
}

.email-filter .input-group-prepend .input-group-text {
  border: 0;
  border-bottom: 1px solid #dddfe1 !important;
  background: transparent;
}

.email-filter .input-group .form-control {
  padding: 0 0 0 0.3125rem;
  border: 0;
  font-size: 0.875rem;
  height: 1.875rem;
  color: #89879f;
  border-bottom: 1px solid #dddfe1;
}

.email-filter .input-group .form-control::placeholder {
  font-size: 0.875rem;
  color: #89879f;
}

.email-filter .input-group > .form-control {
  min-height: 1.875rem;
}

.single-mail {
  display: block;
  padding: 1.5625rem 0;
}

.single-mail .media {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

@media (min-width: 1700px) {
  .single-mail .media {
    padding-left: 1.875rem;
    padding-right: 1.875rem;
  }
}

.single-mail .media img {
  width: 55px;
  height: 55px;
  border-radius: 50%;
  margin-right: 0.9375rem;
}

@media (min-width: 1700px) {
  .single-mail .media img {
    margin-right: 1.875rem;
  }
}

.single-mail .media-body h6 {
  color: #abafb3;
}

.single-mail .media-body h4 {
  font-size: 1rem;
  color: #6a707e;
}

.single-mail .media-body h4 button i {
  font-size: 1.125rem;
  color: #abafb3;
  font-weight: bold;
  transform: rotate(90deg);
}

.single-mail .media-body p {
  font-size: 0.875rem;
  color: #abafb3;
}

.single-mail.active {
  background: #0b2a97;
}

.single-mail.active h6,
.single-mail.active h4,
.single-mail.active p,
.single-mail.active i {
  color: #fff !important;
}

[direction='rtl'] .email-right-box {
  padding-left: 1rem;
  padding-right: 15rem;
}

@media only screen and (max-width: 991px) {
  [direction='rtl'] .email-right-box {
    padding-left: 1rem;
    padding-right: 12rem;
  }
}

@media only screen and (max-width: 575px) {
  [direction='rtl'] .email-right-box {
    padding-left: 0;
    padding-right: 0;
  }
}

[direction='rtl'] .email-left-box {
  float: right;
}

[direction='rtl'] .email-list .message .col-mail-2 {
  right: 5.625rem;
  left: 0;
  float: right;
}

[direction='rtl'] .email-list .message .col-mail-2 .date {
  right: auto;
  left: 0;
}

[direction='rtl'] .email-list .message .col-mail-2 .subject {
  right: 0;
  left: 5.5rem;
}

.photo-content {
  position: relative;
}

.photo-content .cover-photo {
  background-size: cover;
  background-position: center;
  min-height: 250px;
  width: 100%;
}

.profile .profile-photo {
  max-width: 100px;
  position: relative;
  z-index: 1;
  margin-top: -40px;
  margin-right: 10px;
}

@media only screen and (max-width: 575px) {
  .profile .profile-photo {
    width: 80px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
  }
}

[direction='rtl'] .profile .photo-content .profile-photo {
  left: auto;
  right: 50px;
}

[direction='rtl'] .profile .profile-photo {
  left: auto;
  right: 30px;
}

@media only screen and (max-width: 1199px) {
  [direction='rtl'] .profile .profile-photo {
    right: 15px;
  }
}

@media only screen and (max-width: 575px) {
  [direction='rtl'] .profile .profile-photo {
    width: 80px;
    right: calc(50% - 40px);
    top: -100px;
  }
}

.profile-info {
  padding: 15px 20px;
}

@media only screen and (max-width: 575px) {
  .profile-info {
    padding: 0 0 20px;
    text-align: center;
  }
}

.profile-info h4 {
  color: #464a53 !important;
}

.profile-info h4.text-primary {
  color: #0b2a97 !important;
}

.profile-info p {
  color: #828690;
}

.profile-info .prf-col {
  min-width: 250px;
  padding: 10px 50px 0;
}

.profile-interest .row {
  margin: 0 -0.7px;
}

.profile-interest .row .int-col {
  padding: 0 0.7px;
}

.profile-interest .row .int-col .interest-cat {
  margin-bottom: 1.4px;
  position: relative;
  display: block;
}

.profile-interest .row .int-col .interest-cat:after {
  background: #000;
  bottom: 0;
  content: '';
  left: 0;
  opacity: 0.5;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 0;
}

.profile-interest .row .int-col .interest-cat p {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  padding: 5px;
  left: 0;
  margin: 0;
  z-index: 1;
  color: white;
  font-size: 1.2px;
}

.profile-tab .nav-item .nav-link {
  font-size: 16px;
  margin-right: 30px;
  transition: all 0.5s ease-in-out;
  border: none;
  border-bottom: 0.2px solid transparent;
  color: #828690;
}

.profile-tab .nav-item .nav-link:hover,
.profile-tab .nav-item .nav-link.active {
  border: 0;
  background: transparent;
  background: transparent;
  border-bottom: 0.2px solid #0b2a97;
  color: #0b2a97;
}

@media only screen and (max-width: 575px) {
  .profile-tab .nav-item .nav-link {
    margin-right: 0px;
  }
}

.profile-info {
  display: flex;
}

@media only screen and (max-width: 575px) {
  .profile-info {
    display: block;
  }
}

.profile-info .profile-details {
  display: flex;
  width: 100%;
}

@media only screen and (max-width: 575px) {
  .profile-info .profile-details {
    display: block;
  }
  .profile-info .profile-details .dropdown {
    position: absolute;
    top: 30px;
    right: 30px;
  }
}

.post-input {
  margin-bottom: 30px;
}

.post-input .form-control {
  height: 75px;
  font-weight: 400;
  margin: 15px 0;
}

.profile-uoloaded-post .post-title h3 {
  font-size: 24px;
}

@media only screen and (max-width: 575px) {
  .profile-uoloaded-post .post-title h3 {
    font-size: 18px;
  }
}

.profile-uoloaded-post img {
  margin-bottom: 20px;
}

.profile-uoloaded-post a h4 {
  margin-bottom: 10px;
  color: #464a53;
}

.event-chat-ryt .chat-area .chat-reciver,
.event-chat-ryt .chat-area .chat-sender {
  margin-bottom: 1.875rem;
  padding: 0;
}

.event-chat-ryt .chat-area .chat-reciver img,
.event-chat-ryt .chat-area .chat-sender img {
  border-radius: 30px;
}

.event-chat-ryt .chat-area .media {
  position: relative;
}

.event-chat-ryt .chat-area .media-body p {
  margin: 0;
  max-width: 100%;
  display: inline-block;
  position: relative;
}

.event-chat-ryt .chat-area .media-body p span {
  padding: 1rem;
  display: inline-block;
  top: 103%;
  position: relative;
  border: 1px solid #f0f1f5;
}

.event-chat-ryt .chat-reciver {
  padding: 0.5rem 1rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.event-chat-ryt .chat-reciver .media {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.event-chat-ryt .chat-reciver .media .media-body {
  margin-right: 2rem;
  text-align: right;
}

[direction='rtl'] .event-chat-ryt .chat-reciver .media .media-body {
  text-align: left;
  margin-left: 2rem;
  margin-right: auto;
}

.event-chat-ryt .chat-reciver .media .media-body p {
  background: #fff;
  margin-bottom: 0;
  border-radius: 5px 5px 0 5px;
}

.event-chat-ryt .chat-reciver .media .media-body p span {
  text-align: left;
  border: 1px solid #f0f1f5;
}

.event-chat-ryt .chat-reciver .media .media-body p span::after {
  content: '';
  width: 20px;
  height: 20px;
  border-bottom: 1px solid #f0f1f5;
  border-right: 1px solid #f0f1f5;
  position: absolute;
  right: 0;
  bottom: 0;
  background: #fff;
  -webkit-transform: rotate(-45deg) translateX(15px);
  transform: rotate(-45deg) translateX(15px);
}

[direction='rtl']
  .event-chat-ryt
  .chat-reciver
  .media
  .media-body
  p
  span::after {
  left: 0;
  right: auto;
  -webkit-transform: rotate(135deg) translateY(15px);
  transform: rotate(135deg) translateY(15px);
}

.event-chat-ryt .chat-reciver .media .media-body p .time {
  position: absolute;
  font-size: 12px;
  color: #7e7e7e;
  font-weight: 400;
  bottom: 0;
  left: -80px;
}

[direction='rtl'] .event-chat-ryt .chat-reciver .media .media-body p .time {
  right: -5rem;
  left: auto;
}

.event-chat-ryt .chat-sender {
  text-align: left;
  padding: 0.5rem 1rem;
}

.event-chat-ryt .chat-sender .media .media-body {
  margin-left: 2rem;
}

[direction='rtl'] .event-chat-ryt .chat-sender .media .media-body {
  text-align: right;
  margin-right: 2rem;
  margin-left: auto;
}

.event-chat-ryt .chat-sender .media .media-body p {
  background-color: #fff;
  margin-bottom: 0;
}

.event-chat-ryt .chat-sender .media .media-body p span::after {
  content: '';
  width: 20px;
  height: 20px;
  border-bottom: 1px solid #f0f1f5;
  border-left: 1px solid #f0f1f5;
  position: absolute;
  left: 0;
  bottom: 0;
  background: #fff;
  -webkit-transform: rotate(45deg) translateX(-15px);
  transform: rotate(45deg) translateX(-15px);
}

[direction='rtl']
  .event-chat-ryt
  .chat-sender
  .media
  .media-body
  p
  span::after {
  left: auto;
  right: 0;
  -webkit-transform: rotate(-135deg) translateY(15px);
  transform: rotate(-135deg) translateY(15px);
}

.event-chat-ryt .chat-sender .media .media-body p .time {
  position: absolute;
  font-size: 10px;
  color: #7e7e7e;
  font-weight: 400;
  bottom: 0;
  right: -5rem;
}

[direction='rtl'] .event-chat-ryt .chat-sender .media .media-body p .time {
  left: -5rem;
  right: auto;
}

.char-type {
  padding-top: 30px;
  padding-bottom: 30px;
}

.char-type form .form-control {
  height: 45px;
  padding-left: 18px;
  background: #f9f9f9;
  border-right: 0;
}

.char-type form .input-group-append i {
  color: #898989;
  font-size: 18px;
}

.char-type form .input-group-append .input-group-text {
  padding-left: 0.7rem;
  padding-right: 0.7rem;
  background: #f9f9f9;
  border-color: #f0f1f5;
  border-left: 0;
}

.char-type form .input-group-append .input-group-text:last-child {
  padding-right: 1.8rem;
}

.media-avatar {
  padding: 25px 0;
  border-bottom: 1px solid #f0f1f5;
}

.media-avatar:last-child {
  border-bottom: 0px;
}

.media-avatar p {
  margin-bottom: 0;
}

.media-avatar .avatar-status {
  position: relative;
}

.media-avatar .avatar-status i {
  position: absolute;
  right: 0;
  bottom: 0;
}

.ct-golden-section:before {
  float: none;
}

.ct-chart {
  max-height: 15.7rem;
}

.ct-chart .ct-label {
  fill: #a3afb7;
  color: #a3afb7;
  font-size: 0.75rem;
  line-height: 1;
}

.ct-grid {
  stroke: rgba(49, 58, 70, 0.1);
}

.ct-chart.simple-pie-chart-chartist .ct-label {
  color: #ffffff;
  fill: #ffffff;
  font-size: 0.625rem;
}

.ct-chart .ct-series.ct-series-a .ct-bar,
.ct-chart .ct-series.ct-series-a .ct-line,
.ct-chart .ct-series.ct-series-a .ct-point,
.ct-chart .ct-series.ct-series-a .ct-slice-donut {
  stroke: #0b2a97;
}

.ct-chart .ct-series.ct-series-b .ct-bar,
.ct-chart .ct-series.ct-series-b .ct-line,
.ct-chart .ct-series.ct-series-b .ct-point,
.ct-chart .ct-series.ct-series-b .ct-slice-donut {
  stroke: #2bc155;
}

.ct-chart .ct-series.ct-series-c .ct-bar,
.ct-chart .ct-series.ct-series-c .ct-line,
.ct-chart .ct-series.ct-series-c .ct-point,
.ct-chart .ct-series.ct-series-c .ct-slice-donut {
  stroke: #ffbc11;
}

.ct-chart .ct-series.ct-series-d .ct-bar,
.ct-chart .ct-series.ct-series-d .ct-line,
.ct-chart .ct-series.ct-series-d .ct-point,
.ct-chart .ct-series.ct-series-d .ct-slice-donut {
  stroke: #f94687;
}

.ct-chart .ct-series.ct-series-e .ct-bar,
.ct-chart .ct-series.ct-series-e .ct-line,
.ct-chart .ct-series.ct-series-e .ct-point,
.ct-chart .ct-series.ct-series-e .ct-slice-donut {
  stroke: #1ea7c5;
}

.ct-chart .ct-series.ct-series-f .ct-bar,
.ct-chart .ct-series.ct-series-f .ct-line,
.ct-chart .ct-series.ct-series-f .ct-point,
.ct-chart .ct-series.ct-series-f .ct-slice-donut {
  stroke: #b1b1b1;
}

.ct-chart .ct-series.ct-series-g .ct-bar,
.ct-chart .ct-series.ct-series-g .ct-line,
.ct-chart .ct-series.ct-series-g .ct-point,
.ct-chart .ct-series.ct-series-g .ct-slice-donut {
  stroke: #8d6e63;
}

.ct-series-a .ct-area,
.ct-series-a .ct-slice-pie {
  fill: #a02cfa;
}

.ct-series-b .ct-area,
.ct-series-b .ct-slice-pie {
  fill: #00a2ff;
}

.ct-series-c .ct-area,
.ct-series-c .ct-slice-pie {
  fill: #ff9800;
}

.ct-series-d .ct-area,
.ct-series-d .ct-slice-pie {
  fill: #ff9800;
}

.chartist-tooltip {
  position: absolute;
  display: inline-block;
  opacity: 0;
  min-width: 0.625rem;
  padding: 2px 0.625rem;
  border-radius: 3px;
  background: #313a46;
  color: #ffffff;
  text-align: center;
  pointer-events: none;
  z-index: 1;
  -webkit-transition: opacity 0.2s linear;
  -moz-transition: opacity 0.2s linear;
  -o-transition: opacity 0.2s linear;
  transition: opacity 0.2s linear;
}

.chartist-tooltip.tooltip-show {
  opacity: 1;
}

#donught_graph .ct-series.ct-series-a .ct-slice-donut {
  stroke: #3fc6d4;
}

#donught_graph .ct-series.ct-series-b .ct-slice-donut {
  stroke: #333333;
}

#donught_graph .ct-series.ct-series-c .ct-slice-donut {
  stroke: #f63465;
}

@media only screen and (max-width: 767px) {
  #pie-chart .ct-label {
    color: #fff;
    fill: #fff;
  }
}

#visitor_graph {
  height: 253px !important;
}

#user_rating_graph {
  height: 280px !important;
}

#visitorOnline {
  height: 72px !important;
}

#trendMeter {
  height: 72px !important;
}

#widget-revenue1,
#widget-revenue2,
#widget-revenue3 {
  height: 117px !important;
}

#widget-profit1,
#widget-profit2,
#widget-profit3 {
  height: 160px !important;
}

#comparison-rate {
  height: 230px !important;
}

#session_day {
  height: 175px !important;
  width: auto !important;
  margin: 0 auto;
}

#walet-status {
  height: 140px !important;
}

#bar1 {
  height: 150px !important;
}

#sold-product {
  height: 230px !important;
}

#chart-venue-expenses,
#chart-online-sale,
#chart-gross-sale {
  height: 150px !important;
}

#areaChart_3 {
  height: 295px !important;
}

.chart-point {
  display: flex;
  align-items: center;
}

.chart-point .check-point-area {
  width: 100px;
  height: 100px;
  margin-top: -10px;
  margin-left: -10px;
}

.chart-point .chart-point-list {
  margin: 0;
  padding-left: 5px;
}

.chart-point .chart-point-list li {
  list-style: none;
  font-size: 13px;
  padding: 2px 0;
}

.chart-point .chart-point-list li i {
  margin-right: 5px;
  font-size: 11px;
  position: relative;
  top: -1px;
}

.c3 {
  height: 250px;
}

.c3-legend-item {
  fill: #9fabb1;
}

.c3 .c3-axis-x path,
.c3 .c3-axis-x line,
.c3 .c3-axis-y path,
.c3 .c3-axis-y line,
.tick text {
  stroke: #fff;
}

.flot-chart {
  height: 15.7rem;
}

.tooltipflot {
  background-color: transparent;
  font-size: 1.4rem;
  padding: 0.5rem 1rem;
  color: rgba(255, 255, 255, 0.7);
  border-radius: 0.2rem;
}

.legendColorBox > div {
  border: 0 !important;
  padding: 0 !important;
}

.legendLabel {
  font-size: 0.825rem;
  padding-left: 0.5rem;
  color: #fff;
}

.flotTip {
  background: #000;
  border: 1px solid #000;
  color: #fff;
}

.legend > div {
  background: transparent !important;
}

#balance_graph {
  height: 260px;
}

.morris-hover {
  position: absolute;
  z-index: 1;
  background: #0b2a97;
  color: #fff;
}

.morris-hover .morris-hover-point {
  color: #fff !important;
  margin: 3px 0;
  text-align: center;
  padding: 0 25px;
}

.morris-hover .morris-hover-row-label {
  background-color: #b1b1b1;
  text-align: center;
  padding: 5px;
  margin-bottom: 5px;
}

.morris-hover.morris-default-style {
  border-radius: 5px;
  padding: 0;
  margin: 0;
  border: none;
  overflow: hidden;
}

svg text {
  font-weight: 600 !important;
}

#morris_donught,
#morris_donught_2,
#line_chart_2,
#morris_bar,
#morris_bar_stalked,
#morris_bar_2,
#morris_area_2,
#morris_area {
  height: 240px !important;
}

#morris_line {
  height: 278px !important;
}

#crypto-btc-card,
#crypto-eth-card,
#crypto-rpl-card,
#crypto-ltc-card {
  height: 9.375rem;
}

#daily-sales,
#comparison-rate,
#usage-chart,
#walet-status {
  width: 100%;
  display: block;
}

#daily-sales canvas,
#comparison-rate canvas,
#usage-chart canvas,
#walet-status canvas {
  max-width: 100% !important;
  width: 100% !important;
}

#sparkline-composite-chart canvas,
#composite-bar canvas,
#sparkline11 canvas,
#StackedBarChart canvas,
#spark-bar canvas,
#tristate canvas {
  height: 100px !important;
}

#sparkline11 canvas {
  width: 100px !important;
}

.easy-pie-chart {
  position: relative;
  text-align: center;
}

.easy-pie-chart .inner {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: inline-block;
}

.easy-pie-chart img {
  width: 75px;
  height: 75px;
  border-radius: 50%;
}

.easy-pie-chart canvas {
  display: block;
  margin: 0 auto;
}

#revenue-chart {
  height: 27.7rem;
}

#duration-value-axis {
  height: 27.6rem;
  width: 100%;
}

#combined-bullet {
  height: 28.125rem;
  width: 100%;
}

#zoomable-chart {
  height: 28.125rem;
  width: 100%;
}

#chartMap {
  height: 28.125rem;
  width: 100%;
}

#professional-candlesticks {
  width: 100%;
  height: 28.125rem;
}

#comparing-stock-indices {
  width: 100%;
  height: 28.125rem;
}

#multiple-panel-data {
  width: 100%;
  height: 28.125rem;
}

#depth-chart {
  width: 100%;
  height: 28.125rem;
}

.amcharts-export-menu {
  display: none;
}

.amcharts-data-set-selector-div {
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
  width: 16.875rem;
  margin: 0 auto;
}

.amcharts-data-set-selector-div select {
  border: 0;
  margin-left: 0.625rem;
  background: #ddd;
  color: #000;
}

.amChartsInputField {
  border: 0;
  background: #0b2a97;
  color: #fff;
  padding: 0.3125rem 0.9375rem;
  margin: 0 0.9375rem;
}

.amcharts-data-set-select {
  border: 0;
  background: #ddd;
  color: #000;
}

.amcharts-period-input,
.amcharts-period-input-selected {
  border: 0;
  margin-left: 0.625rem;
  background: #0b2a97;
  color: #fff;
  padding: 0.3125rem 0.9375rem;
}

.amcharts-graph-g2 .amcharts-graph-stroke {
  stroke-dasharray: 3px 3px;
  stroke-linejoin: round;
  stroke-linecap: round;
  -webkit-animation: am-moving-dashes 1s linear infinite;
  animation: am-moving-dashes 1s linear infinite;
}

@-webkit-keyframes am-moving-dashes {
  100% {
    stroke-dashoffset: -1.9375rem;
  }
}

@keyframes am-moving-dashes {
  100% {
    stroke-dashoffset: -1.9375rem;
  }
}

.lastBullet {
  -webkit-animation: am-pulsating 1s ease-out infinite;
  animation: am-pulsating 1s ease-out infinite;
}

@-webkit-keyframes am-pulsating {
  0% {
    stroke-opacity: 1;
    stroke-width: 0px;
  }
  100% {
    stroke-opacity: 0;
    stroke-width: 3.125rem;
  }
}

@keyframes am-pulsating {
  0% {
    stroke-opacity: 1;
    stroke-width: 0px;
  }
  100% {
    stroke-opacity: 0;
    stroke-width: 3.125rem;
  }
}

.amcharts-graph-column-front {
  -webkit-transition: all 0.3s 0.3s ease-out;
  transition: all 0.3s 0.3s ease-out;
}

.amcharts-graph-column-front:hover {
  fill: #496375;
  stroke: #496375;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

@-webkit-keyframes am-draw {
  0% {
    stroke-dashoffset: 500%;
  }
  100% {
    stroke-dashoffset: 0%;
  }
}

@keyframes am-draw {
  0% {
    stroke-dashoffset: 500%;
  }
  100% {
    stroke-dashoffset: 0%;
  }
}

@media only screen and (max-width: 991px) {
  .amChartsPeriodSelector > fieldset > div {
    float: none !important;
    display: block !important;
    margin-bottom: 0.625rem;
  }
}

.highcharts-root text {
  font-weight: 300 !important;
}

.highcharts-credits {
  display: none;
}

.form-control {
  border-radius: 1.25rem;
  background: #fff;
  border: 1px solid #f0f1f5;
  color: #b1b1b1;
  height: 56px;
}

.form-control:hover,
.form-control:focus,
.form-control.active {
  box-shadow: none;
  background: #fff;
  color: #b1b1b1;
}

.input-rounded {
  border-radius: 100px;
}

.input-group-text {
  background: #d7dae3;
  border: 1px solid transparent;
  min-width: 50px;
  display: flex;
  justify-content: center;
  padding: 0.532rem 0.75rem;
  border-radius: 1.25rem;
}

.input-group-text i {
  font-size: 16px;
}

.custom-file-label {
  height: 40px;
  padding: 0.5rem 0.75rem;
  border-radius: 0;
}

.input-group-prepend .btn,
.input-group-append .btn {
  z-index: 0;
}

.custom-select {
  background: none;
  border-color: #f0f1f5;
  color: #b1b1b1;
}

.custom-select:focus {
  box-shadow: none;
  border-color: #0b2a97;
  color: #0b2a97;
}

.custom-file-label:after {
  background: #656c73;
  border: 1px solid #f0f1f5;
  color: #fff;
  border-radius: 0;
}

[data-theme-version='dark'] .custom-file-label:after {
  background: #333a54;
  border-color: #333a54;
  color: #7e7e7e;
}

.custom_file_input .custom-file-label::after {
  height: 100%;
}

.form-control:disabled,
.form-control[readonly] {
  background: #fff;
  opacity: 1;
}

.custom-file-label {
  background: #fff;
  border-color: #f0f1f5;
}

[data-theme-version='dark'] .custom-file-label {
  background: #181f39;
  border-color: #333a54;
}

/*$primary  : #7356f1 !default;*/

input[type='checkbox']:after {
  content: '';
  display: block;
  width: 1rem;
  height: 1rem;
  margin-top: 0px;
  margin-left: -1px;
  border: 1px solid transparent;
  border-radius: 3px;
  background: #d4d7da;
  line-height: 1.3;
}

input[type='checkbox']:checked:after {
  width: 1rem;
  height: 1rem;
  display: block;
  content: '\f00c';
  font-family: 'FontAwesome';
  color: #fff;
  font-weight: 100;
  font-size: 12px;
  text-align: center;
  border-radius: 3px;
  background: #0b2a97;
}

.form-check-label {
  margin-left: 5px;
  margin-top: 3px;
}

.form-check-inline .form-check-input {
  margin-right: 0.625rem;
}

.custom-control-label:before,
.custom-control-label:after {
  top: 2px;
  width: 1.25rem;
  height: 1.25rem;
  border-color: #e7e7e7;
}

.rtl .custom-control-label:before,
.rtl .custom-control-label:after {
  right: -1.5rem !important;
  left: inherit;
}

.custom-control {
  line-height: normal;
}

.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #0b2a97;
}

.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: none !important;
}

.custom-control-label::before {
  background-color: transparent;
  border-color: #bebebe;
  border-width: 2px;
  border-radius: 5px !important;
}

[data-theme-version='dark'] .custom-control-label::before {
  background-color: transparent;
  border-color: #333a54;
}

.check-xs .custom-control-label:before,
.check-xs .custom-control-label:after {
  width: 18px;
  height: 18px;
}

.check-lg .custom-control-label:before,
.check-lg .custom-control-label:after {
  width: 24px;
  height: 24px;
}

.check-xl .custom-control-label:before,
.check-xl .custom-control-label:after {
  width: 28px;
  height: 28px;
}

.checkbox-info .custom-control-label::before {
  background-color: transparent;
  border-color: #a4e3f1;
}

[data-theme-version='dark'] .checkbox-info .custom-control-label::before {
  background-color: rgba(30, 167, 197, 0.1);
  border-color: transparent;
}

.checkbox-info .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #1ea7c5;
  border-color: #1ea7c5;
}

.checkbox-danger .custom-control-label::before {
  background-color: transparent;
  border-color: #fff3f7;
}

[data-theme-version='dark'] .checkbox-danger .custom-control-label::before {
  background-color: rgba(249, 70, 135, 0.15);
  border-color: transparent;
}

.checkbox-danger .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #f94687;
  border-color: #f94687;
}

.checkbox-success .custom-control-label::before {
  background-color: transparent;
  border-color: #e3f9e9;
}

[data-theme-version='dark'] .checkbox-success .custom-control-label::before {
  background-color: rgba(43, 193, 85, 0.1);
  border-color: transparent;
}

.checkbox-success
  .custom-control-input:checked
  ~ .custom-control-label::before {
  background-color: #2bc155;
  border-color: #2bc155;
}

.checkbox-warning .custom-control-label::before {
  background-color: transparent;
  border-color: #fff5dd;
}

[data-theme-version='dark'] .checkbox-warning .custom-control-label::before {
  background-color: rgba(255, 188, 17, 0.1);
  border-color: transparent;
}

.checkbox-warning
  .custom-control-input:checked
  ~ .custom-control-label::before {
  background-color: #ffbc11;
  border-color: #ffbc11;
}

.checkbox-secondary .custom-control-label::before {
  background-color: transparent;
  border-color: #f1dffe;
}

[data-theme-version='dark'] .checkbox-secondary .custom-control-label::before {
  background-color: rgba(160, 44, 250, 0.5);
  border-color: transparent;
}

.checkbox-secondary
  .custom-control-input:checked
  ~ .custom-control-label::before {
  background-color: #a02cfa;
  border-color: #a02cfa;
}

.toggle-switch {
  padding-left: 50px;
  line-height: 1.25;
}

.toggle-switch .custom-control-label {
  font-weight: 500;
}

.toggle-switch .custom-control-label:after {
  width: 24px;
  background: #959595;
  height: 24px;
  border-radius: 24px;
  top: -2px;
  left: -50px;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}

.toggle-switch .custom-control-label:before {
  border: 0;
  background: #d8d8d8;
  width: 40px;
  border-radius: 20px !important;
  height: 16px;
  left: -50px;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}

.toggle-switch .custom-control-input:checked ~ .custom-control-label:after {
  left: -43px;
  background: #0b2a97;
}

.toggle-switch .custom-control-input:checked ~ .custom-control-label:before {
  background: #d8e0fc;
}

.toggle-switch.text-right {
  padding-left: 0;
  padding-right: 50px;
}

.toggle-switch.text-right .custom-control-label:after {
  left: auto;
  right: -30px;
}

.toggle-switch.text-right .custom-control-label:before {
  left: auto;
  right: -50px;
}

.toggle-switch.text-right
  .custom-control-input:checked
  ~ .custom-control-label:after {
  left: auto;
  right: -43px;
}

.check-switch {
  padding-left: 40px;
}

.check-switch .custom-control-label {
  line-height: 30px;
  font-weight: 500;
}

.check-switch .custom-control-label:after,
.check-switch .custom-control-label:before {
  height: 1.5rem;
  width: 1.5rem;
  left: -2rem;
  border-radius: 3rem !important;
  border-color: rgba(11, 42, 151, 0.3);
}

.js-switch + .switchery {
  border-radius: 50px;
  margin-right: 4rem;
}

@media (max-width: 767.98px) {
  .js-switch + .switchery {
    margin-right: 1rem;
  }
}

.js-switch + .switchery > small {
  top: 2px;
}

.js-switch.js-switch-lg + .switchery {
  height: 2rem;
  width: 4.5rem;
}

.js-switch.js-switch-lg + .switchery > small {
  width: 1.75rem;
  height: 1.75rem;
}

.js-switch.js-switch-md + .switchery {
  height: 1.5rem;
  width: 3.5rem;
}

.js-switch.js-switch-md + .switchery > small {
  width: 1.25rem;
  height: 1.25rem;
}

.js-switch.js-switch-sm + .switchery {
  height: 1rem;
  width: 2.2rem;
}

.js-switch.js-switch-sm + .switchery > small {
  width: 0.875rem;
  height: 0.875rem;
  top: 1px;
}

.js-switch-square + .switchery {
  border-radius: 0;
}

.js-switch-square + .switchery > small {
  border-radius: 0;
  top: 2px;
}

.js-switch-square.js-switch-lg + .switchery {
  height: 2rem;
  width: 4.5rem;
}

.js-switch-square.js-switch-lg + .switchery > small {
  width: 1.75rem;
  height: 1.75rem;
}

.js-switch-square.js-switch-md + .switchery {
  height: 1.5rem;
  width: 3.5rem;
}

.js-switch-square.js-switch-md + .switchery > small {
  width: 1.25rem;
  height: 1.25rem;
}

.js-switch-square.js-switch-sm + .switchery {
  height: 1rem;
  width: 2.2rem;
}

.js-switch-square.js-switch-sm + .switchery > small {
  width: 0.875rem;
  height: 0.875rem;
  top: 1px;
}

.form-control.is-valid {
  border-color: #2bc155 !important;
  border-right: 0px !important;
}

.form-control.is-valid:focus {
  box-shadow: none;
}

.form-control.is-warning {
  border-color: #ffbc11 !important;
  border-right: 0px !important;
}

.form-control.is-warning:focus {
  box-shadow: none;
}

.form-control.is-invalid {
  border-color: #f94687 !important;
  border-right: 0px !important;
}

.form-control.is-invalid:focus {
  box-shadow: none;
}

.is-valid .input-group-prepend .input-group-text i {
  color: #2bc155;
}

.is-invalid .input-group-prepend .input-group-text i {
  color: #103ede;
}

.show-pass {
  cursor: pointer;
}

.show-pass .fa-eye {
  display: none;
}

.show-pass.active .fa-eye-slash {
  display: none;
}

.show-pass.active .fa-eye {
  display: inline-block;
}

.asColorPicker-dropdown {
  max-width: 26rem;
}

.asColorPicker-trigger {
  border: 0 none;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  width: 2.1875rem;
}

[direction='rtl'] .asColorPicker-trigger {
  right: 99%;
  left: auto;
}

.form-wizard {
  border: 0;
}

.form-wizard .nav-wizard {
  box-shadow: none !important;
  margin-bottom: 2rem;
}

.form-wizard .nav-wizard li .nav-link {
  position: relative;
}

.form-wizard .nav-wizard li .nav-link span {
  border-radius: 50px;
  width: 3rem;
  height: 3rem;
  border: 2px solid #0b2a97;
  display: block;
  line-height: 3rem;
  color: #0b2a97;
  font-size: 18px;
  margin: auto;
  background-color: #fff;
  position: relative;
  z-index: 1;
}

.form-wizard .nav-wizard li .nav-link:after {
  position: absolute;
  top: 50%;
  left: 50%;
  height: 3px;
  transform: translateY(-50%);
  background: #f1f1f1 !important;
  z-index: 0;
  width: 100%;
}

.form-wizard .nav-wizard li .nav-link.active:after {
  background: #f1f1f1 !important;
}

.form-wizard .nav-wizard li .nav-link.done:after {
  background: #0b2a97 !important;
}

.form-wizard .nav-wizard li .nav-link.done span {
  background-color: #0b2a97;
  color: #fff;
}

.form-wizard .nav-wizard li:last-child .nav-link:after {
  content: none;
}

.form-wizard .toolbar-bottom .btn {
  background-color: #0b2a97;
  border: 0;
  padding: 12px 18px;
}

.form-wizard .tab-content .tab-pane {
  padding: 0;
}

.form-wizard .emial-setup label.mailclinet {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 10rem;
  height: 10rem;
  border-radius: 50%;
  cursor: pointer;
  background-color: #eef5f9;
  text-align: center;
  margin: auto;
}

[data-theme-version='dark'] .form-wizard .emial-setup label.mailclinet {
  background-color: #181f39;
}

@media only screen and (max-width: 575px) {
  .form-wizard .emial-setup label.mailclinet {
    width: 7rem;
    height: 7rem;
  }
}

.form-wizard .emial-setup label.mailclinet .mail-icon {
  font-size: 3rem;
  display: inline-block;
  line-height: 1;
  margin-top: -1rem;
}

@media only screen and (max-width: 575px) {
  .form-wizard .emial-setup label.mailclinet .mail-icon {
    font-size: 2rem;
  }
}

.form-wizard .emial-setup label.mailclinet .mail-text {
  font-size: 1rem;
  text-align: center;
  margin-top: 0.5rem;
}

@media only screen and (max-width: 575px) {
  .form-wizard .emial-setup label.mailclinet .mail-text {
    font-size: 16px;
    line-height: 20px;
  }
}

.form-wizard .emial-setup label.mailclinet input[type='radio'] {
  display: none;
}

.form-wizard .emial-setup label.mailclinet {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 10rem;
  height: 10rem;
  border-radius: 50%;
  cursor: pointer;
  background-color: #eef5f9;
  text-align: center;
  margin: auto;
}

[data-theme-version='dark'] .form-wizard .emial-setup label.mailclinet {
  background-color: #181f39;
}

@media only screen and (max-width: 575px) {
  .form-wizard .emial-setup label.mailclinet {
    width: 7rem;
    height: 7rem;
  }
}

.form-wizard .emial-setup label.mailclinet .mail-icon {
  font-size: 3rem;
  display: inline-block;
  line-height: 1;
  margin-top: -1rem;
}

@media only screen and (max-width: 575px) {
  .form-wizard .emial-setup label.mailclinet .mail-icon {
    font-size: 2rem;
  }
}

.form-wizard .emial-setup label.mailclinet .mail-text {
  font-size: 1rem;
  text-align: center;
  margin-top: 0.5rem;
}

@media only screen and (max-width: 575px) {
  .form-wizard .emial-setup label.mailclinet .mail-text {
    font-size: 16px;
    line-height: 20px;
  }
}

.form-wizard .emial-setup label.mailclinet input[type='radio'] {
  display: none;
}

@media only screen and (max-width: 767px) {
  .form-wizard .nav-wizard {
    flex-direction: unset !important;
  }
  .form-wizard .tab-content {
    height: 100% !important;
  }
}

@media only screen and (max-width: 575px) {
  .form-wizard .nav-wizard li .nav-link {
    padding: 0;
  }
}

.note-icon-caret,
.note-popover {
  display: none;
}

.note-editor.note-frame .panel-heading {
  padding: 0.6rem 1rem 1rem;
  z-index: 1;
}

.note-editor.note-frame {
  border-color: #f0f1f5;
}

.note-editor.note-frame .note-editing-area .note-editable {
  background-color: #fff;
}

[data-theme-version='dark']
  .note-editor.note-frame
  .note-editing-area
  .note-editable {
  background-color: #181f39;
}

.note-editor.note-frame .note-statusbar {
  background-color: #fff;
}

.ql-container {
  height: 25rem;
}

#world-datamap {
  padding-bottom: 46% !important;
}

.datamaps-hoverover {
  background: #fff;
  padding: 0.3125rem;
  border-radius: 0.3125rem;
  font-family: 'Roboto' !important;
  color: #0b2a97;
  border: 1px solid rgba(11, 42, 151, 0.3);
}

@media only screen and (max-width: 1440px) {
  .world_map_card ul.list-group {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    margin-top: 35px;
  }
}

.jqvmap-zoomin,
.jqvmap-zoomout {
  height: 20px;
  width: 20px;
  line-height: 14px;
  background-color: #0b2a97;
  color: #fff;
}

.jqvmap-zoomout {
  top: 35px;
}

#world-map,
#usa {
  height: 400px;
}

@media only screen and (max-width: 991px) {
  #world-map,
  #usa {
    height: 350px;
  }
}

@media only screen and (max-width: 575px) {
  #world-map,
  #usa {
    height: 230px;
  }
}

.blockUI.blockMsg.blockPage {
  border: 0 !important;
}

#loginForm {
  cursor: auto;
}

.blockMsg {
  border: 0px !important;
  width: 20% !important;
}

.blockMsg h1 {
  font-size: 16px;
  padding: 8px 0;
  margin-bottom: 0;
}

.bootstrap-select {
  margin-bottom: 0;
}

.bootstrap-select .btn {
  border: 1px solid #f0f1f5 !important;
  background-color: transparent !important;
  font-weight: 400;
  color: #7e7e7e !important;
}

[data-theme-version='dark'] .bootstrap-select .btn {
  border-color: #333a54 !important;
}

.bootstrap-select .btn:active,
.bootstrap-select .btn:focus,
.bootstrap-select .btn:hover {
  outline: none !important;
  outline-offset: 0;
}

[data-theme-version='dark'] .bootstrap-select .btn:active,
[data-theme-version='dark'] .bootstrap-select .btn:focus,
[data-theme-version='dark'] .bootstrap-select .btn:hover {
  color: #7e7e7e !important;
}

.bootstrap-select .dropdown-menu {
  border-color: #f0f1f5 !important;
  box-shadow: 0px 0epx 40px 0px rgba(82, 63, 105, 0.1);
}

.bootstrap-select .dropdown-menu .dropdown-item {
  padding: 0.25rem 1rem;
}

[data-theme-version='dark'] .bootstrap-select .dropdown-menu {
  border-color: #f0f1f5 !important;
}

.input-group > .bootstrap-select:not(:first-child) .dropdown-toggle {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group > .bootstrap-select:not(:last-child) .dropdown-toggle {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

/*$primary  : #7356f1 !default;*/

.clipboard-btn {
  transition: all 0.1s ease-in-out;
}

.clipboard-btn:hover {
  background-color: #0b2a97;
  color: #fff;
}

.crypto-ticker {
  background: rgba(0, 0, 0, 0.5);
  margin-top: 20px;
  padding: 10px 20px;
  border-radius: 3px;
  box-shadow: 0 0 35px 0 rgba(154, 161, 171, 0.15);
}

[data-theme-version='dark'] .crypto-ticker {
  background: #1e2746;
}

#webticker-big {
  font: inherit !important;
  font-size: inherit !important;
  font-weight: normal !important;
}

#webticker-big li i {
  font-size: 18px;
  margin-right: 7px;
}

#webticker-big li p {
  margin-bottom: 0px;
  font-size: 12px;
  font-weight: 700;
}

@keyframes cd-enter-right {
  0% {
    opacity: 0;
    transform: translateX(100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0%);
  }
}

@keyframes cd-enter-left {
  0% {
    opacity: 0;
    transform: translateX(-100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0%);
  }
}

/* Light Gallery */

.lg-actions .lg-next,
.lg-actions .lg-prev,
.lg-sub-html,
.lg-toolbar {
  background-color: rgba(30, 30, 30, 0.6);
}

.lg-outer .lg-toogle-thumb,
.lg-outer .lg-thumb-outer,
.lg-outer .lg-img-wrap,
.lg-outer .lg-item {
  background-color: transparent;
}

.lg-thumb-outer.lg-grab,
.lg-toogle-thumb.lg-icon {
  background-color: rgba(30, 30, 30, 0.6);
}

.lg-backdrop {
  background-color: rgba(30, 30, 30, 0.9);
}

.lg-outer .lg-toogle-thumb,
.lg-actions .lg-next,
.lg-actions .lg-prev,
.lg-toolbar .lg-icon,
#lg-counter {
  color: #fff;
}

.lg-outer .lg-thumb-item.active,
.lg-outer .lg-thumb-item:hover {
  border-color: #0b2a97;
}

.lightimg {
  cursor: pointer;
}

.toast-success {
  background-color: #0b2a97;
}

.toast-info {
  background-color: #1ea7c5;
}

.toast-warning {
  background-color: #ffbc11;
}

.toast-error {
  background-color: #f94687;
}

#toast-container > div {
  box-shadow: none;
  border-radius: 0;
  width: auto;
  max-width: 250px;
  opacity: 1;
}

[direction='rtl'] #toast-container > div {
  padding: 15px 50px 15px 15px;
  background-position: calc(100% - 15px);
  text-align: right;
}

#toast-container > div:hover {
  box-shadow: none;
}

#toast-container .toast-title {
  margin-bottom: 5px;
  font-weight: 600;
}

#toast-container .toast-message {
  font-size: 12px;
}

#toast-container .toast-close-button {
  opacity: 1;
  font-size: 20px;
  font-weight: normal;
  text-shadow: none;
}

[direction='rtl'] .toast-top-right.demo_rtl_class {
  left: 12px;
  right: auto;
}

.jqvmap-zoomin,
.jqvmap-zoomout {
  position: absolute;
  left: 10px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  background: #000000;
  padding: 3px;
  color: white;
  width: 17px;
  height: 17px;
  cursor: pointer;
  line-height: 10px;
  text-align: center;
}

.jqvmap-zoomin {
  top: 10px;
}

.jqvmap-zoomout {
  top: 30px;
}

.ps__rail-x:hover,
.ps__rail-y:hover,
.ps__rail-x:focus,
.ps__rail-y:focus,
.ps__rail-x.ps--clicking,
.ps__rail-y.ps--clicking {
  background-color: transparent;
  opacity: 0.9;
}

.ps__rail-y:hover > .ps__thumb-y,
.ps__rail-y:focus > .ps__thumb-y,
.ps__rail-y.ps--clicking .ps__thumb-y {
  background-color: #dae2f3;
  width: 4px;
}

.ps__thumb-y {
  background-color: #dae2f3;
  width: 4px;
}

.total-average {
  position: relative;
  height: 300px;
}

.widget-chat {
  position: relative;
  height: 250px;
}

.widget-todo {
  position: relative;
  height: 210px;
}

.widget-team {
  position: relative;
  height: 285px;
}

.widget-timeline {
  position: relative;
}

.widget-comments {
  position: relative;
  height: 400px;
}

.sidebar-right-inner {
  position: relative;
  height: 100%;
}

.widget-team .ps .ps__rail-x:hover,
.widget-team .ps .ps__rail-y:hover,
.widget-team .ps .ps__rail-x:focus,
.widget-team .ps .ps__rail-y:focus,
.widget-team .ps .ps__rail-x.ps--clicking,
.widget-team .ps .ps__rail-y.ps--clicking {
  background-color: transparent !important;
  opacity: 0.9;
}

.accordion-primary .accordion__header {
  background: #0b2a97;
  border-color: #0b2a97;
  color: #fff;
  box-shadow: 0 15px 20px 0 rgba(11, 42, 151, 0.15);
}

.accordion-primary .accordion__header.collapsed {
  background: #d8e0fc;
  border-color: #d8e0fc;
  color: #211c37;
  box-shadow: none;
}

[data-theme-version='dark'] .accordion-primary .accordion__header.collapsed {
  background: rgba(11, 42, 151, 0.2);
  border-color: rgba(11, 42, 151, 0.2);
  color: #7e7e7e;
}

.accordion-primary-solid .accordion__header {
  background: #0b2a97;
  border-color: #0b2a97;
  color: #fff;
  box-shadow: 0 -10px 20px 0 rgba(11, 42, 151, 0.15);
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.accordion-primary-solid .accordion__header.collapsed {
  background: #d8e0fc;
  border-color: #d8e0fc;
  color: #211c37;
  box-shadow: none;
  border-bottom-left-radius: 1.25rem;
  border-bottom-right-radius: 1.25rem;
}

[data-theme-version='dark']
  .accordion-primary-solid
  .accordion__header.collapsed {
  background: rgba(11, 42, 151, 0.2);
  border-color: rgba(11, 42, 151, 0.2);
  color: #7e7e7e;
}

.accordion-primary-solid .accordion__body {
  border: 2px solid #0b2a97;
  border-top: none;
  box-shadow: 0 15px 20px 0 rgba(11, 42, 151, 0.15);
  border-bottom-left-radius: 1.25rem;
  border-bottom-right-radius: 1.25rem;
}

.accordion-danger .accordion__header {
  background: #f94687;
  border-color: #f94687;
  color: #fff;
  box-shadow: 0 15px 20px 0 rgba(249, 70, 135, 0.15);
}

.accordion-danger .accordion__header.collapsed {
  background: #fff3f7;
  border-color: #fff3f7;
  color: #211c37;
  box-shadow: none;
}

.accordion-danger-solid .accordion__header {
  background: #f94687;
  border-color: #f94687;
  color: #fff;
  box-shadow: 0 -10px 20px 0 rgba(249, 70, 135, 0.15);
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.accordion-danger-solid .accordion__header.collapsed {
  background: #fff3f7;
  border-color: #fff3f7;
  color: #211c37;
  box-shadow: none;
  border-bottom-left-radius: 1.25rem;
  border-bottom-right-radius: 1.25rem;
}

[data-theme-version='dark']
  .accordion-danger-solid
  .accordion__header.collapsed {
  background: rgba(249, 70, 135, 0.15);
  border-color: rgba(249, 70, 135, 0.15);
  color: #7e7e7e;
}

.accordion-danger-solid .accordion__body {
  border: 2px solid #f94687;
  border-top: none;
  box-shadow: 0 15px 20px 0 rgba(249, 70, 135, 0.15);
  border-bottom-left-radius: 1.25rem;
  border-bottom-right-radius: 1.25rem;
}

.accordion__item {
  margin-bottom: 1.25rem;
}

.accordion__header {
  padding: 1rem 1.75rem;
  border: 1px solid #f0f1f5;
  cursor: pointer;
  position: relative;
  color: #333;
  font-weight: 400;
  border-radius: 1.25rem;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}

[data-theme-version='dark'] .accordion__header {
  color: #fff;
  border-color: #333a54;
}

.accordion__header--indicator {
  font-family: 'themify';
  position: absolute;
  right: 1.5625rem;
  top: 50%;
  transform: translateY(-50%);
}

[direction='rtl'] .accordion__header--indicator {
  right: auto;
  left: 1.5625rem;
}

.accordion__header--indicator.indicator_bordered {
  display: inline-block;
  width: 25px;
  text-align: center;
  height: 25px;
  border: 1px solid #f0f1f5;
  border-radius: 50%;
  line-height: 25px;
}

.accordion__header:not(.collapsed) .accordion__header--indicator::before {
  content: '\e622';
}

.accordion__header:not(.collapsed)
  .accordion__header--indicator.style_two::before {
  content: '\e648';
}

.accordion__header.collapsed .accordion__header--indicator::before {
  content: '\e61a';
}

.accordion__header.collapsed .accordion__header--indicator.style_two::before {
  content: '\e64b';
}

.accordion__body--text {
  padding: 0.875rem 1.25rem;
}

.accordion-bordered .accordion__body {
  border: 1px solid #f0f1f5;
  border-top: none;
  border-bottom-left-radius: 1.25rem;
  border-bottom-right-radius: 1.25rem;
}

[data-theme-version='dark'] .accordion-bordered .accordion__body {
  border-color: #333a54;
}

.accordion-bordered .accordion__header.collapsed {
  border-radius: 1.25rem;
}

.accordion-bordered .accordion__header {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.accordion-no-gutter .accordion__item {
  margin-bottom: 0;
}

.accordion-no-gutter .accordion__item .accordion__header.collapsed {
  border-bottom: none;
}

.accordion-no-gutter .accordion__item:last-child .accordion__header {
  border-bottom: 1px solid #f0f1f5;
}

[data-theme-version='dark']
  .accordion-no-gutter
  .accordion__item:last-child
  .accordion__header {
  border-color: #333a54;
}

.accordion-no-gutter.accordion__bordered
  .accordion__item:not(:last-child)
  .accordion__body {
  border-bottom: none;
}

.accordion-left-indicator .accordion__header--text {
  padding-left: 2.5rem;
}

.accordion-left-indicator .accordion__header--indicator {
  right: auto;
  left: 1.5625rem;
}

.accordion-with-icon .accordion__header--text {
  padding-left: 2.5rem;
}

[direction='rtl'] .accordion-with-icon .accordion__header--text {
  padding-left: 0;
  padding-right: 2.5rem;
}

.accordion-with-icon .accordion__header--icon {
  position: absolute;
  right: auto;
  left: 1.5625rem;
  font-family: 'themify';
}

[direction='rtl'] .accordion-with-icon .accordion__header--icon {
  left: auto;
  right: 1.5625rem;
}

.accordion-with-icon .accordion__header--icon::before {
  content: '\e645';
}

.accordion-header-bg .accordion__header {
  background-color: #f4f5f9;
}

[data-theme-version='dark'] .accordion-header-bg .accordion__header {
  background-color: #181f39;
  color: #fff;
}

.accordion-header-bg .accordion__header--primary {
  background-color: #0b2a97;
  color: #fff;
  border-color: #0b2a97;
}

[data-theme-version='dark'] .accordion-header-bg .accordion__header--primary {
  background-color: #0b2a97;
}

.accordion-header-bg .accordion__header--info {
  background-color: #1ea7c5;
  color: #fff;
  border-color: #1ea7c5;
}

[data-theme-version='dark'] .accordion-header-bg .accordion__header--info {
  background-color: #1ea7c5;
}

.accordion-header-bg .accordion__header--success {
  background-color: #2bc155;
  color: #fff;
  border-color: #2bc155;
}

[data-theme-version='dark'] .accordion-header-bg .accordion__header--success {
  background-color: #2bc155;
}

.accordion-header-bg.accordion-no-gutter .accordion__header {
  border-color: transparent;
  border-radius: 0;
}

.accordion-header-bg.accordion-no-gutter
  .accordion__item:first-child
  .accordion__header {
  border-top-left-radius: 1.25rem;
  border-top-right-radius: 1.25rem;
}

.accordion-header-bg.accordion-no-gutter
  .accordion__item:last-child
  .accordion__header {
  border-bottom-left-radius: 1.25rem;
  border-bottom-right-radius: 1.25rem;
}

.accordion.accordion-no-gutter .accordion__header {
  border-radius: 0;
}

.accordion.accordion-no-gutter .accordion__header.collapsed {
  border-radius: 0;
}

.accordion.accordion-no-gutter .accordion__body {
  border-radius: 0;
}

.accordion.accordion-no-gutter .accordion__item:first-child .accordion__header {
  border-top-left-radius: 1.25rem;
  border-top-right-radius: 1.25rem;
}

.accordion.accordion-no-gutter
  .accordion__item:last-child
  .accordion__header.collapsed {
  border-bottom-left-radius: 1.25rem;
  border-bottom-right-radius: 1.25rem;
}

.accordion.accordion-no-gutter .accordion__item:last-child .accordion__body {
  border-bottom-left-radius: 1.25rem;
  border-bottom-right-radius: 1.25rem;
}

.accordion-solid-bg .accordion__header {
  border-color: transparent;
  background-color: #d8e0fc;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

[data-theme-version='dark'] .accordion-solid-bg .accordion__header {
  background-color: #181f39;
}

.accordion-solid-bg .accordion__header.collapsed {
  border-radius: 1.25rem;
}

.accordion-solid-bg .accordion__body {
  border-color: transparent;
  background-color: #d8e0fc;
  border-bottom-left-radius: 1.25rem;
  border-bottom-right-radius: 1.25rem;
}

[data-theme-version='dark'] .accordion-solid-bg .accordion__body {
  background-color: #181f39;
}

.accordion-active-header .accordion__header:not(.collapsed) {
  background-color: #1ea7c5;
  border-color: #1ea7c5;
  color: #fff;
}

.accordion-header-shadow .accordion__header {
  border: none;
  box-shadow: 0 0 0.9375rem -3px rgba(0, 0, 0, 0.3);
}

.accordion-rounded-stylish .accordion__header {
  border-top-left-radius: 0.375rem;
  border-top-right-radius: 0.375rem;
}

.accordion-rounded-stylish .accordion__body {
  border-bottom-left-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
}

.accordion-rounded .accordion__header {
  border-radius: 0.3125rem;
}

.accordion-gradient .accordion__header {
  color: #fff;
  background-image: linear-gradient(
    to right,
    rgba(186, 1, 181, 0.85) 0%,
    rgba(103, 25, 255, 0.85) 100%
  );
  border-color: transparent;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.accordion-gradient .accordion__header.collapsed {
  border-bottom-left-radius: 1.25rem;
  border-bottom-right-radius: 1.25rem;
}

.accordion-gradient .accordion__body {
  color: #fff;
  background-image: linear-gradient(
    to right,
    rgba(186, 1, 181, 0.85) 0%,
    rgba(103, 25, 255, 0.85) 100%
  );
  border-color: transparent;
}

.alert {
  border-radius: 1.25rem;
  padding: 1rem 3rem 1rem 1.5rem;
}

.alert p {
  line-height: 1.5;
}

.alert-rounded {
  border-radius: 30px;
}

.alert-primary {
  background: #d8e0fc;
  border-color: #d8e0fc;
  color: #0b2a97;
}

[data-theme-version='dark'] .alert-primary {
  background: rgba(11, 42, 151, 0.2);
  border-color: rgba(11, 42, 151, 0.2);
}

.alert-secondary {
  background: #f1dffe;
  border-color: #f1dffe;
  color: #a02cfa;
}

[data-theme-version='dark'] .alert-secondary {
  background: rgba(160, 44, 250, 0.5);
  border-color: rgba(160, 44, 250, 0.5);
  color: #7e7e7e;
}

.alert-success {
  background: #e3f9e9;
  border-color: #e3f9e9;
  color: #2bc155;
}

[data-theme-version='dark'] .alert-success {
  background: rgba(43, 193, 85, 0.1);
  border-color: rgba(43, 193, 85, 0.1);
}

.alert-warning {
  background: #fff5dd;
  border-color: #fff5dd;
  color: #ffbc11;
}

[data-theme-version='dark'] .alert-warning {
  background: rgba(255, 188, 17, 0.1);
  border-color: rgba(255, 188, 17, 0.1);
}

.alert-danger {
  background: #fff3f7;
  border-color: #fff3f7;
  color: #f94687;
}

[data-theme-version='dark'] .alert-danger {
  background: rgba(249, 70, 135, 0.15);
  border-color: rgba(249, 70, 135, 0.15);
}

.alert-info {
  background: #a4e3f1;
  border-color: #a4e3f1;
  color: #1ea7c5;
}

[data-theme-version='dark'] .alert-info {
  background: rgba(30, 167, 197, 0.1);
  border-color: rgba(30, 167, 197, 0.1);
}

.alert-dark {
  background: #f1f1f1;
  border-color: #f1f1f1;
  color: #b1b1b1;
}

[data-theme-version='dark'] .alert-dark {
  background: rgba(177, 177, 177, 0.35);
  border-color: rgba(177, 177, 177, 0.35);
  color: #7e7e7e;
}

.alert-light {
  background: #f4f5f9;
  border-color: #f4f5f9;
  color: #b1b1b1;
}

.alert-alt.alert-primary {
  border-left: 4px solid #0b2a97;
}

.alert-alt.alert-secondary {
  border-left: 4px solid #a02cfa;
}

.alert-alt.alert-success {
  border-left: 4px solid #2bc155;
}

.alert-alt.alert-warning {
  border-left: 4px solid #ffbc11;
}

.alert-alt.alert-danger {
  border-left: 4px solid #f94687;
}

.alert-alt.alert-info {
  border-left: 4px solid #1ea7c5;
}

.alert-alt.alert-dark {
  border-left: 4px solid #b1b1b1;
}

.alert-alt.alert-light {
  border-left: 4px solid #c3c8de;
}

.alert-alt.alert-primary.solid {
  border-left: 4px solid #020920 !important;
}

.alert-alt.alert-secondary.solid {
  border-left: 4px solid #5d04a3 !important;
}

.alert-alt.alert-success.solid {
  border-left: 4px solid #145927 !important;
}

.alert-alt.alert-warning.solid {
  border-left: 4px solid #916800 !important;
}

.alert-alt.alert-danger.solid {
  border-left: 4px solid #b90647 !important;
}

.alert-alt.alert-info.solid {
  border-left: 4px solid #0d4956 !important;
}

.alert-alt.alert-dark.solid {
  border-left: 4px solid #717171 !important;
}

.alert-alt.alert-light.solid {
  border-left: 4px solid #a2aacc !important;
}

.alert-dismissible.solid .close:hover {
  color: #fff;
  opacity: 1;
}

.alert.alert-primary.solid {
  background: #0b2a97;
  color: #fff;
  border-color: #0b2a97;
}

.alert.alert-secondary.solid {
  background: #a02cfa;
  color: #fff;
  border-color: #a02cfa;
}

.alert.alert-success.solid {
  background: #2bc155;
  color: #fff;
  border-color: #2bc155;
}

.alert.alert-warning.solid {
  background: #ffbc11;
  color: #fff;
  border-color: #ffbc11;
}

.alert.alert-danger.solid {
  background: #f94687;
  color: #fff;
  border-color: #f94687;
}

.alert.alert-info.solid {
  background: #1ea7c5;
  color: #fff;
  border-color: #1ea7c5;
}

.alert.alert-dark.solid {
  background: #b1b1b1;
  color: #fff;
  border-color: #b1b1b1;
}

.alert.alert-light.solid {
  background: #f4f5f9;
  color: #b1b1b1;
  border-color: #f4f5f9;
}

.alert-right-icon > span i {
  font-size: 18px;
  margin-right: 5px;
}

.alert-right-icon .close i {
  font-size: 16px;
}

.alert.alert-outline-primary {
  background: transparent;
  color: #0b2a97;
  border-color: #0b2a97;
}

.alert.alert-outline-secondary {
  background: transparent;
  color: #7e7e7e;
  border-color: #a02cfa;
}

.alert.alert-outline-success {
  background: transparent;
  color: #2bc155;
  border-color: #2bc155;
}

.alert.alert-outline-info {
  background: transparent;
  color: #1ea7c5;
  border-color: #1ea7c5;
}

.alert.alert-outline-warning {
  background: transparent;
  color: #ffbc11;
  border-color: #ffbc11;
}

.alert.alert-outline-danger {
  background: transparent;
  color: #f94687;
  border-color: #f94687;
}

.alert.alert-outline-dark {
  background: transparent;
  color: #7e7e7e;
  border-color: #b1b1b1;
}

.alert.alert-outline-light {
  background: transparent;
  color: #b1b1b1;
  border-color: #f4f5f9;
}

.alert-social {
  color: #fff;
}

.alert-social .alert-social-icon {
  align-self: center;
  margin-right: 0.9375rem;
}

.alert-social .alert-social-icon i {
  font-size: 42px;
}

.alert-social.facebook {
  background-color: #3b5998;
}

.alert-social.twitter {
  background-color: #1da1f2;
}

.alert-social.linkedin {
  background-color: #007bb6;
}

.alert-social.google-plus {
  background-color: #db4439;
}

.alert-social .close:hover {
  opacity: 1 !important;
  color: #fff !important;
}

.left-icon-big .alert-left-icon-big {
  align-self: center;
  margin-right: 0.9375rem;
}

.left-icon-big .alert-left-icon-big i {
  font-size: 35px;
  line-height: 1;
}

[direction='rtl'] .left-icon-big .alert-left-icon-big,
[direction='rtl'] .alert-social .alert-social-icon {
  margin-left: 0;
  margin-right: 0.9375rem;
}

.badge {
  line-height: 1.5;
  border-radius: 1.25rem;
  font-size: 14px;
  font-weight: 600;
  padding: 4px 10px;
  border: 1px solid transparent;
}

.badge-rounded {
  border-radius: 20px;
  padding: 3px 13px;
}

.badge-circle {
  border-radius: 100px;
  padding: 3px 7px;
}

.badge-outline-primary {
  border: 1px solid #0b2a97;
  color: #0b2a97;
}

.badge-outline-secondary {
  border: 1px solid #a02cfa;
  color: #a02cfa;
}

[data-theme-version='dark'] .badge-outline-secondary {
  color: #7e7e7e;
}

.badge-outline-success {
  border: 1px solid #2bc155;
  color: #2bc155;
}

.badge-outline-info {
  border: 1px solid #1ea7c5;
  color: #1ea7c5;
}

.badge-outline-warning {
  border: 1px solid #ffbc11;
  color: #ffbc11;
}

.badge-outline-danger {
  border: 1px solid #f94687;
  color: #f94687;
}

.badge-outline-light {
  border: 1px solid #f0f1f5;
  color: #b1b1b1;
}

[data-theme-version='dark'] .badge-outline-light {
  color: #7e7e7e;
}

.badge-outline-dark {
  border: 1px solid #b1b1b1;
  color: #b1b1b1;
}

[data-theme-version='dark'] .badge-outline-dark {
  color: #7e7e7e;
}

.badge-xs {
  font-size: 10px;
  padding: 0px 5px;
  line-height: 18px;
}

.badge-sm {
  font-size: 11px;
  padding: 5px 8px;
  line-height: 11px;
}

.badge-lg {
  font-size: 14px;
  padding: 0px 10px;
  line-height: 30px;
}

.badge-xl {
  font-size: 15px;
  padding: 0px 15px;
  line-height: 35px;
}

.badge-default {
  background: #adb6c7;
}

.badge-success {
  background-color: #2bc155;
}

.badge-info {
  background-color: #1ea7c5;
}

.badge-primary {
  background-color: #0b2a97;
}

.badge-warning {
  background-color: #ffbc11;
}

.badge-danger {
  background-color: #f94687;
}

.badge-dark {
  background-color: #b1b1b1;
}

.badge-light {
  background-color: #f4f5f9;
}

.light.badge-default {
  background: #adb6c7;
}

.light.badge-success {
  background-color: #e3f9e9;
  color: #2bc155;
}

[data-theme-version='dark'] .light.badge-success {
  background-color: rgba(43, 193, 85, 0.1);
}

.light.badge-info {
  background-color: #a4e3f1;
  color: #1ea7c5;
}

[data-theme-version='dark'] .light.badge-info {
  background-color: rgba(30, 167, 197, 0.1);
}

.light.badge-primary {
  background-color: #d8e0fc;
  color: #0b2a97;
}

[data-theme-version='dark'] .light.badge-primary {
  background-color: rgba(11, 42, 151, 0.2);
}

.light.badge-secondary {
  background-color: #f1dffe;
  color: #a02cfa;
}

[data-theme-version='dark'] .light.badge-secondary {
  background-color: rgba(160, 44, 250, 0.5);
  color: #7e7e7e;
}

.light.badge-warning {
  background-color: #fff5dd;
  color: #ffbc11;
}

[data-theme-version='dark'] .light.badge-warning {
  background-color: rgba(255, 188, 17, 0.1);
}

.light.badge-danger {
  background-color: #fff3f7;
  color: #f94687;
}

[data-theme-version='dark'] .light.badge-danger {
  background-color: rgba(249, 70, 135, 0.15);
}

.light.badge-dark {
  background-color: #f1f1f1;
  color: #b1b1b1;
}

[data-theme-version='dark'] .light.badge-dark {
  background-color: rgba(177, 177, 177, 0.35);
  color: #7e7e7e;
}

.bootstrap-label .label {
  display: inline-block;
  margin-right: 1rem;
}

.bootstrap-label .label:last-child {
  margin-right: 0;
}

.badge-demo .badge {
  margin-right: 5px;
  margin-bottom: 5px;
}

.badge-demo .badge:last-child {
  margin-right: 0;
}

.bootstrap-badge-buttons button {
  margin-right: 0.2rem;
  margin-bottom: 1rem;
}

.bootstrap-badge-buttons button:last-child {
  margin-right: 0;
}

.welcome-text span,
.welcome-text p {
  display: none;
}

.page-titles {
  margin-bottom: 25px;
  display: block;
  margin-left: -40px;
  margin-right: -40px;
  padding: 20px 40px;
  margin-top: -15px;
  background: #f9f9f9;
  display: block;
}

.page-titles [class*='col-'] {
  display: block !important;
  max-width: 100%;
  width: 100%;
  margin: 0;
}

@media only screen and (max-width: 1600px) {
  .page-titles {
    margin-left: -30px;
    margin-top: 0px;
    margin-right: -30px;
    margin-bottom: 30px;
    padding: 15px 30px;
  }
}

@media only screen and (max-width: 767px) {
  .page-titles {
    margin-left: -15px;
    margin-top: -15px;
    margin-right: -15px;
    margin-bottom: 0px;
    padding: 15px;
  }
}

.page-titles .justify-content-sm-end {
  align-items: center;
}

.page-titles h4 {
  margin-bottom: 10px;
  margin-top: 0;
  color: #000;
  font-size: 1.75rem;
  font-weight: 600;
}

.page-titles h4 span {
  font-size: 1.25rem;
}

.page-titles .breadcrumb {
  margin-bottom: 0;
  padding: 0;
  background: transparent;
  /* .breadcrumb-item + .breadcrumb-item {
    &:before {
        content: "\e606";
        font-family: 'simple-line-icons';
        // font-weight: 4;
        font-size: 10px;
    }
} */
}

.page-titles .breadcrumb li {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 18px;
}

@media only screen and (max-width: 767px) {
  .page-titles .breadcrumb li {
    font-size: 15px;
  }
}

.page-titles .breadcrumb li a {
  color: #656773;
}

.page-titles .breadcrumb li.active a {
  color: #0b2a97;
  font-weight: 600;
}

.page-titles .breadcrumb-datepicker {
  font-size: 0.75rem;
  color: #89879f;
}

.page-titles .breadcrumb-datepicker__icon {
  font-size: 0.875rem;
}

.page-titles .breadcrumb-widget .border-dark {
  border-color: #dee2e6 !important;
}

.page-titles .breadcrumb-widget h4 {
  color: #646c9a;
  font-weight: 600;
}

@media only screen and (max-width: 575px) {
  .page-titles .breadcrumb-widget {
    text-align: left !important;
    margin-bottom: 0.9375rem;
  }
}

button {
  cursor: pointer;
}

button:focus {
  outline: 0;
  box-shadow: none;
}

.main-wrapper .content-body .btn-primary {
  color: #fff;
  background-color: #6aacff !important;
  border-color: #6aacff !important;
}

.btn {
  padding: 10px 20px;
  border-radius: 1.25rem;
  font-weight: 500;
  font-size: 1rem;
}

.btn:hover,
.btn:focus,
.btn:active,
.btn.active {
  outline: 0 !important;
}

@media only screen and (max-width: 1400px) {
  .btn {
    padding: 0.625rem 1rem;
    font-size: 0.813rem;
  }
}

.btn-transparent {
  background-color: transparent;
}

.sharp {
  min-width: 40px;
  padding: 7px;
  height: 40px;
  min-height: 40px;
}

.sharp.btn-xs {
  padding: 3px;
  width: 26px;
  height: 26px;
  min-width: 26px;
  min-height: 26px;
}

.light.tp-btn {
  background-color: transparent;
}

.light.btn-default {
  background: #adb6c7;
}

.light.btn-success {
  background-color: #e3f9e9;
  border-color: #e3f9e9;
  color: #2bc155;
}

.light.btn-success g [fill] {
  fill: #2bc155;
}

[data-theme-version='dark'] .light.btn-success {
  background-color: rgba(43, 193, 85, 0.1);
  border-color: transparent;
}

.light.btn-success:hover {
  background-color: #2bc155;
  border-color: #2bc155;
  color: #fff;
}

.light.btn-success:hover g [fill] {
  fill: #fff;
}

.light.btn-info {
  background-color: #a4e3f1;
  border-color: #a4e3f1;
  color: #1ea7c5;
}

.light.btn-info g [fill] {
  fill: #1ea7c5;
}

[data-theme-version='dark'] .light.btn-info {
  background-color: rgba(30, 167, 197, 0.1);
  border-color: transparent;
}

.light.btn-info:hover {
  background-color: #1ea7c5;
  border-color: #1ea7c5;
  color: #fff;
}

.light.btn-info:hover g [fill] {
  fill: #fff;
}

.light.btn-primary {
  background-color: #d8e0fc;
  border-color: #d8e0fc;
  color: #0b2a97;
}

.light.btn-primary g [fill] {
  fill: #0b2a97;
}

[data-theme-version='dark'] .light.btn-primary {
  background-color: rgba(11, 42, 151, 0.2);
  border-color: transparent;
}

.light.btn-primary:hover {
  background-color: #0b2a97;
  border-color: #0b2a97;
  color: #fff;
}

.light.btn-primary:hover g [fill] {
  fill: #fff;
}

.light.btn-primary:hover svg path {
  fill: #fff;
}

.light.btn-secondary {
  background-color: #f1dffe;
  border-color: #f1dffe;
  color: #a02cfa;
}

.light.btn-secondary g [fill] {
  fill: #a02cfa;
}

[data-theme-version='dark'] .light.btn-secondary {
  background-color: rgba(160, 44, 250, 0.5);
  border-color: transparent;
  color: #fff;
}

.light.btn-secondary:hover {
  background-color: #a02cfa;
  border-color: #a02cfa;
  color: #fff;
}

.light.btn-secondary:hover g [fill] {
  fill: #fff;
}

.light.btn-warning {
  background-color: #fff5dd;
  border-color: #fff5dd;
  color: #ffbc11;
}

.light.btn-warning g [fill] {
  fill: #ffbc11;
}

[data-theme-version='dark'] .light.btn-warning {
  background-color: rgba(255, 188, 17, 0.1);
  border-color: transparent;
}

.light.btn-warning:hover {
  background-color: #ffbc11;
  border-color: #ffbc11;
  color: #fff;
}

.light.btn-warning:hover g [fill] {
  fill: #fff;
}

.light.btn-danger {
  background-color: #fff3f7;
  border-color: #fff3f7;
  color: #f94687;
}

.light.btn-danger g [fill] {
  fill: #f94687;
}

[data-theme-version='dark'] .light.btn-danger {
  background-color: rgba(249, 70, 135, 0.15);
  border-color: transparent;
}

.light.btn-danger:hover {
  background-color: #f94687;
  border-color: #f94687;
  color: #fff;
}

.light.btn-danger:hover g [fill] {
  fill: #fff;
}

.light.btn-danger:hover svg path {
  fill: #fff;
}

.light.btn-dark {
  background-color: #f1f1f1;
  border-color: #f1f1f1;
  color: #b1b1b1;
}

.light.btn-dark g [fill] {
  fill: #b1b1b1;
}

[data-theme-version='dark'] .light.btn-dark {
  background-color: rgba(177, 177, 177, 0.35);
  border-color: transparent;
  color: #fff;
}

.light.btn-dark:hover {
  background-color: #b1b1b1;
  border-color: #b1b1b1;
  color: #fff;
}

.light.btn-dark:hover g [fill] {
  fill: #fff;
}

.btn.tp-btn {
  background-color: transparent;
  border-color: transparent;
}

.btn.tp-btn.btn-default {
  background: #adb6c7;
}

.btn.tp-btn.btn-success {
  color: #2bc155;
}

.btn.tp-btn.btn-success g [fill] {
  fill: #2bc155;
}

.btn.tp-btn.btn-success:hover {
  background-color: #2bc155;
  border-color: #2bc155;
  color: #fff;
}

.btn.tp-btn.btn-success:hover g [fill] {
  fill: #fff;
}

.btn.tp-btn.btn-info {
  color: #1ea7c5;
}

.btn.tp-btn.btn-info g [fill] {
  fill: #1ea7c5;
}

.btn.tp-btn.btn-info:hover {
  background-color: #1ea7c5;
  border-color: #1ea7c5;
  color: #fff;
}

.btn.tp-btn.btn-info:hover g [fill] {
  fill: #fff;
}

.btn.tp-btn.btn-primary {
  color: #0b2a97;
}

.btn.tp-btn.btn-primary g [fill] {
  fill: #0b2a97;
}

.btn.tp-btn.btn-primary:hover {
  background-color: #0b2a97;
  border-color: #0b2a97;
  color: #fff;
}

.btn.tp-btn.btn-primary:hover g [fill] {
  fill: #fff;
}

.btn.tp-btn.btn-secondary {
  color: #a02cfa;
}

.btn.tp-btn.btn-secondary g [fill] {
  fill: #a02cfa;
}

.btn.tp-btn.btn-secondary:hover {
  background-color: #a02cfa;
  border-color: #a02cfa;
  color: #fff;
}

.btn.tp-btn.btn-secondary:hover g [fill] {
  fill: #fff;
}

.btn.tp-btn.btn-warning {
  color: #ffbc11;
}

.btn.tp-btn.btn-warning g [fill] {
  fill: #ffbc11;
}

.btn.tp-btn.btn-warning:hover {
  background-color: #ffbc11;
  border-color: #ffbc11;
  color: #fff;
}

.btn.tp-btn.btn-warning:hover g [fill] {
  fill: #fff;
}

.btn.tp-btn.btn-danger {
  color: #f94687;
}

.btn.tp-btn.btn-danger g [fill] {
  fill: #f94687;
}

.btn.tp-btn.btn-danger:hover {
  background-color: #f94687;
  border-color: #f94687;
  color: #fff;
}

.btn.tp-btn.btn-danger:hover g [fill] {
  fill: #fff;
}

.btn.tp-btn.btn-light {
  color: #b1b1b1;
}

.btn.tp-btn.btn-light g [fill] {
  fill: #b1b1b1;
}

.btn.tp-btn.btn-light:hover {
  background-color: #f4f5f9;
  border-color: #f4f5f9;
  color: #b1b1b1;
}

.btn.tp-btn.btn-light:hover g [fill] {
  fill: #fff;
}

.btn.tp-btn.btn-dark {
  color: #b1b1b1;
}

.btn.tp-btn.btn-dark g [fill] {
  fill: #b1b1b1;
}

.btn.tp-btn.btn-dark:hover {
  background-color: #b1b1b1;
  border-color: #b1b1b1;
  color: #fff;
}

.btn.tp-btn.btn-dark:hover g [fill] {
  fill: #fff;
}

.btn.tp-btn-light {
  background-color: transparent;
  border-color: transparent;
}

.btn.tp-btn-light.btn-success {
  color: #2bc155;
}

.btn.tp-btn-light.btn-success g [fill] {
  fill: #2bc155;
}

.btn.tp-btn-light.btn-success:hover {
  background-color: #e3f9e9;
  border-color: #e3f9e9;
  color: #2bc155;
}

.btn.tp-btn-light.btn-success:hover g [fill] {
  fill: #2bc155;
}

.btn.tp-btn-light.btn-info {
  color: #1ea7c5;
}

.btn.tp-btn-light.btn-info g [fill] {
  fill: #1ea7c5;
}

.btn.tp-btn-light.btn-info:hover {
  background-color: #a4e3f1;
  border-color: #a4e3f1;
  color: #1ea7c5;
}

.btn.tp-btn-light.btn-info:hover g [fill] {
  fill: #1ea7c5;
}

.btn.tp-btn-light.btn-primary {
  color: #0b2a97;
}

.btn.tp-btn-light.btn-primary g [fill] {
  fill: #0b2a97;
}

.btn.tp-btn-light.btn-primary:hover {
  background-color: #d8e0fc;
  border-color: #d8e0fc;
  color: #0b2a97;
}

.btn.tp-btn-light.btn-primary:hover g [fill] {
  fill: #0b2a97;
}

.btn.tp-btn-light.btn-secondary {
  color: #a02cfa;
}

.btn.tp-btn-light.btn-secondary g [fill] {
  fill: #a02cfa;
}

.btn.tp-btn-light.btn-secondary:hover {
  background-color: #f1dffe;
  border-color: #f1dffe;
  color: #a02cfa;
}

.btn.tp-btn-light.btn-secondary:hover g [fill] {
  fill: #a02cfa;
}

.btn.tp-btn-light.btn-warning {
  color: #ffbc11;
}

.btn.tp-btn-light.btn-warning g [fill] {
  fill: #ffbc11;
}

.btn.tp-btn-light.btn-warning:hover {
  background-color: #fff5dd;
  border-color: #fff5dd;
  color: #ffbc11;
}

.btn.tp-btn-light.btn-warning:hover g [fill] {
  fill: #ffbc11;
}

.btn.tp-btn-light.btn-danger {
  color: #f94687;
}

.btn.tp-btn-light.btn-danger g [fill] {
  fill: #f94687;
}

.btn.tp-btn-light.btn-danger:hover {
  background-color: #fff3f7;
  border-color: #fff3f7;
  color: #f94687;
}

.btn.tp-btn-light.btn-danger:hover g [fill] {
  fill: #fff;
}

.btn.tp-btn-light.btn-dark {
  color: #b1b1b1;
}

.btn.tp-btn-light.btn-dark g [fill] {
  fill: #b1b1b1;
}

.btn.tp-btn-light.btn-dark:hover {
  background-color: #f1f1f1;
  border-color: #f1f1f1;
  color: #b1b1b1;
}

.btn.tp-btn-light.btn-dark:hover [fill] {
  fill: #fff;
}

.shadow.btn-primary {
  -webkit-box-shadow: 0 5px 15px 0 rgba(11, 42, 151, 0.2) !important;
  box-shadow: 0 5px 15px 0 rgba(11, 42, 151, 0.2) !important;
}

.shadow.btn-secondary {
  -webkit-box-shadow: 0 5px 15px 0 rgba(160, 44, 250, 0.2) !important;
  box-shadow: 0 5px 15px 0 rgba(160, 44, 250, 0.2) !important;
}

.shadow.btn-warning {
  -webkit-box-shadow: 0 5px 15px 0 rgba(255, 188, 17, 0.2) !important;
  box-shadow: 0 5px 15px 0 rgba(255, 188, 17, 0.2) !important;
}

.shadow.btn-danger {
  -webkit-box-shadow: 0 5px 15px 0 rgba(249, 70, 135, 0.2) !important;
  box-shadow: 0 5px 15px 0 rgba(249, 70, 135, 0.2) !important;
}

.shadow.btn-info {
  -webkit-box-shadow: 0 5px 15px 0 rgba(30, 167, 197, 0.2) !important;
  box-shadow: 0 5px 15px 0 rgba(30, 167, 197, 0.2) !important;
}

.shadow.btn-success {
  -webkit-box-shadow: 0 5px 15px 0 rgba(43, 193, 85, 0.2) !important;
  box-shadow: 0 5px 15px 0 rgba(43, 193, 85, 0.2) !important;
}

.btn-xxs {
  padding: 6px 15px;
  font-size: 11px;
  line-height: 1.3;
}

.btn-xs {
  font-size: 0.75rem;
  padding: 0.438rem 1rem;
  font-weight: 600;
}

@media only screen and (max-width: 1400px) {
  .btn-xs {
    font-size: 0.75rem;
    padding: 0.25rem 0.5rem;
    font-weight: 500;
  }
}

.btn-sm,
.btn-group-sm > .btn {
  font-size: 0.813rem !important;
  padding: 0.625rem 1rem;
}

@media only screen and (max-width: 1400px) {
  .btn-sm,
  .btn-group-sm > .btn {
    padding: 0.375rem 1rem;
    font-size: 0.75rem;
  }
}

.btn-md {
  font-size: 0.813rem !important;
  padding: 0.875rem 1.25rem;
}

@media only screen and (max-width: 1400px) {
  .btn-md {
    padding: 0.5rem 1rem;
    font-size: 0.813rem;
  }
}

.btn-lg,
.btn-group-lg > .btn {
  padding: 1rem 2rem;
  font-size: 1.125rem !important;
}

@media only screen and (max-width: 1400px) {
  .btn-lg,
  .btn-group-lg > .btn {
    padding: 0.75rem 1.5rem;
    font-size: 1rem !important;
  }
}

.btn-xl {
  padding: 0.6rem 1rem;
}

.btn-xl.btn-default {
  font-weight: 600;
}

.btn-square {
  border-radius: 0;
}

.btn-rounded {
  border-radius: 30px !important;
}

.btn-icon-right {
  border-left: 1px solid white;
  display: inline-block;
  margin: -0.8rem 0 -0.8rem 1rem;
  padding: 0.4375rem 0 0.4375rem 1rem;
  margin: -1rem -0.25rem -1rem 1rem;
  padding: 1rem 0 1rem 1.25rem;
}

@media only screen and (max-width: 1400px) {
  .btn-icon-right {
    padding: 1rem 0 1rem 0.6rem;
  }
}

.btn-icon-left {
  background: #fff;
  border-radius: 10rem;
  display: inline-block;
  margin: -0.5rem 0.75rem -0.5rem -1.188rem;
  padding: 0.5rem 0.8rem 0.5rem;
  float: left;
}

@media only screen and (max-width: 1199px) {
  .btn-icon-left {
    margin: -0.5rem 0.75rem -0.5rem -0.8rem;
  }
}

[direction='rtl'] .btn-icon-left {
  margin: -0.5rem -1.5rem -0.5rem 1rem;
}

[direction='rtl'] .btn-icon-right {
  border-left: 0px solid white;
  display: inline-block;
  margin: -0.8rem 1rem -0.8rem 0;
  padding: 0.4375rem 1rem 0.4375rem 0;
  border-right: 1px solid white;
}

.toggle-dropdown::after {
  margin-left: 0.755em;
}

.social-btn-icon .btn {
  min-width: 7.5rem;
  margin-bottom: 1.5rem;
}

.social-icon .btn {
  padding: 0.7rem 1.4rem;
}

.btn-circle {
  height: 5rem;
  width: 5rem;
  border-radius: 50% !important;
}

.btn-circle-sm {
  width: 4.5rem;
  height: 4.5rem;
  font-size: 1.8rem;
}

.btn-circle-md {
  width: 6rem;
  height: 6rem;
  font-size: 2.5rem;
}

.btn-circle-md i {
  font-size: 2.4rem;
}

.btn-circle-lg {
  width: 8rem;
  height: 8rem;
  font-size: 3.2rem;
}

.btn-circle-lg i {
  font-size: 3.1rem;
}

.btn-page .btn {
  min-width: 110px;
  margin-right: 4px;
  margin-bottom: 8px;
}

.size-1 {
  min-width: 160px !important;
  font-size: 24px;
  padding: 0.68rem 0.75rem;
}

.size-2 {
  font-size: 20px;
  min-width: 130px !important;
  padding: 0.57rem 0.75rem;
}

.size-3 {
  font-size: 14px;
  min-width: 110px !important;
  padding: 0.536rem 0.75rem;
}

.size-4 {
  font-size: 14px;
  min-width: 100px !important;
}

.size-5 {
  font-size: 14px;
  min-width: 90px !important;
  padding: 0.22rem 0.75rem;
}

.size-6 {
  font-size: 13px;
  min-width: 80px !important;
  padding: 0.097rem 0.75rem;
}

.size-7 {
  font-size: 12px;
  min-width: 60px !important;
  padding: 0.001rem 0.75rem;
}

.btn-light {
  background: #f4f5f9;
  border-color: #f4f5f9;
}

.btn-light:active,
.btn-light:focus,
.btn-light:hover {
  background: white;
  border-color: white;
}

.btn-outline-primary:hover {
  color: #fff;
}

.btn-outline-light {
  color: #b1b1b1;
}

[data-theme-version='dark'] .btn-outline-light {
  color: #7e7e7e;
}

[data-theme-version='dark'] .btn-outline-secondary {
  color: #7e7e7e;
}

[data-theme-version='dark'] .btn-outline-dark {
  color: #7e7e7e;
}

.btn-dark {
  background: #b1b1b1;
  border-color: #b1b1b1;
  color: #fff;
}

.btn-dark:active,
.btn-dark:focus,
.btn-dark:hover {
  background: #989898;
  color: #fff;
  border-color: #989898;
}

.btn-group.btn-rounded .btn:first-child {
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
}

.btn-group.btn-rounded .btn:last-child {
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
}

.btn-facebook {
  background: #3b5998;
  border-color: #3b5998;
  color: #fff;
}

.btn-facebook:active,
.btn-facebook:focus,
.btn-facebook:hover {
  background: #2d4373;
  color: #fff;
  border-color: #2d4373;
}

.btn-twitter {
  background: #1da1f2;
  border-color: #1da1f2;
  color: #fff;
}

.btn-twitter:active,
.btn-twitter:focus,
.btn-twitter:hover {
  background: #0c85d0;
  color: #fff;
  border-color: #0c85d0;
}

.btn-youtube {
  background: #ff0000;
  border-color: #ff0000;
  color: #fff;
}

.btn-youtube:active,
.btn-youtube:focus,
.btn-youtube:hover {
  background: #cc0000;
  color: #fff;
  border-color: #cc0000;
}

.btn-instagram {
  background: #c32aa3;
  border-color: #c32aa3;
  color: #fff;
}

.btn-instagram:active,
.btn-instagram:focus,
.btn-instagram:hover {
  background: #992180;
  color: #fff;
  border-color: #992180;
}

.btn-pinterest {
  background: #bd081c;
  border-color: #bd081c;
  color: #fff;
}

.btn-pinterest:active,
.btn-pinterest:focus,
.btn-pinterest:hover {
  background: #8c0615;
  color: #fff;
  border-color: #8c0615;
}

.btn-linkedin {
  background: #007bb6;
  border-color: #007bb6;
  color: #fff;
}

.btn-linkedin:active,
.btn-linkedin:focus,
.btn-linkedin:hover {
  background: #005983;
  color: #fff;
  border-color: #005983;
}

.btn-google-plus {
  background: #db4439;
  border-color: #db4439;
  color: #fff;
}

.btn-google-plus:active,
.btn-google-plus:focus,
.btn-google-plus:hover {
  background: #be2d23;
  color: #fff;
  border-color: #be2d23;
}

.btn-google {
  background: #4285f4;
  border-color: #4285f4;
  color: #fff;
}

.btn-google:active,
.btn-google:focus,
.btn-google:hover {
  background: #1266f1;
  color: #fff;
  border-color: #1266f1;
}

.btn-snapchat {
  background: #fffc00;
  border-color: #fffc00;
  color: #000;
}

.btn-snapchat:active,
.btn-snapchat:focus,
.btn-snapchat:hover {
  background: #ccca00;
  color: #000;
  border-color: #ccca00;
}

.btn-whatsapp {
  background: #25d366;
  border-color: #25d366;
  color: #fff;
}

.btn-whatsapp:active,
.btn-whatsapp:focus,
.btn-whatsapp:hover {
  background: #1da851;
  color: #fff;
  border-color: #1da851;
}

.btn-tumblr {
  background: #35465d;
  border-color: #35465d;
  color: #fff;
}

.btn-tumblr:active,
.btn-tumblr:focus,
.btn-tumblr:hover {
  background: #222e3d;
  color: #fff;
  border-color: #222e3d;
}

.btn-reddit {
  background: #ff4500;
  border-color: #ff4500;
  color: #fff;
}

.btn-reddit:active,
.btn-reddit:focus,
.btn-reddit:hover {
  background: #cc3700;
  color: #fff;
  border-color: #cc3700;
}

.btn-spotify {
  background: #1ed760;
  border-color: #1ed760;
  color: #fff;
}

.btn-spotify:active,
.btn-spotify:focus,
.btn-spotify:hover {
  background: #18aa4c;
  color: #fff;
  border-color: #18aa4c;
}

.btn-yahoo {
  background: #430297;
  border-color: #430297;
  color: #fff;
}

.btn-yahoo:active,
.btn-yahoo:focus,
.btn-yahoo:hover {
  background: #2d0165;
  color: #fff;
  border-color: #2d0165;
}

.btn-dribbble {
  background: #ea4c89;
  border-color: #ea4c89;
  color: #fff;
}

.btn-dribbble:active,
.btn-dribbble:focus,
.btn-dribbble:hover {
  background: #e51e6b;
  color: #fff;
  border-color: #e51e6b;
}

.btn-skype {
  background: #00aff0;
  border-color: #00aff0;
  color: #fff;
}

.btn-skype:active,
.btn-skype:focus,
.btn-skype:hover {
  background: #008abd;
  color: #fff;
  border-color: #008abd;
}

.btn-quora {
  background: #aa2200;
  border-color: #aa2200;
  color: #fff;
}

.btn-quora:active,
.btn-quora:focus,
.btn-quora:hover {
  background: #771800;
  color: #fff;
  border-color: #771800;
}

.btn-vimeo {
  background: #1ab7ea;
  border-color: #1ab7ea;
  color: #fff;
}

.btn-vimeo:active,
.btn-vimeo:focus,
.btn-vimeo:hover {
  background: #1295bf;
  color: #fff;
  border-color: #1295bf;
}

.card {
  margin-bottom: 1.875rem;
  background-color: #fff !important;
  transition: all 0.5s ease-in-out;
  position: relative !important;
  border: 0px solid transparent !important;
  border-radius: 1.25rem !important;
  box-shadow: 0px 12px 23px 0px rgba(160, 44, 250, 0.04) !important;
  height: calc(100% - 30px);
}

@media only screen and (max-width: 575px) {
  .card {
    margin-bottom: 0.938rem;
    height: calc(100% - 0.938rem);
  }
}

.card-body {
  padding: 1.875rem;
}

@media only screen and (max-width: 575px) {
  .card-body {
    padding: 1rem;
  }
}

.card-title {
  font-size: 20px;
  font-weight: 500;
  color: #000;
  text-transform: capitalize;
}

.card-title--large {
  font-size: 1.5rem;
}

.card-title--medium {
  font-size: 1rem;
}

.card-title--small {
  font-size: 0.875rem;
}

.card-header {
  border-color: #f0f1f5;
  position: relative;
  background-color: #fff !important;
  padding: 1.5rem 1.875rem 1.25rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 20px !important;
}

@media only screen and (max-width: 575px) {
  .card-header {
    padding: 1.25rem 1rem 1.25rem;
  }
}

[data-theme-version='dark'] .card-header {
  border-color: #333a54;
}

.card-header .card-title {
  margin-bottom: 0px;
}

.card-header .subtitle {
  padding-top: 5px;
  font-size: 14px;
  line-height: 1.5;
}

.card-footer {
  border-color: #f0f1f5;
  background: transparent;
  padding: 1.25rem 1.875rem 1.25rem;
}

[data-theme-version='dark'] .card-footer {
  border-color: #333a54;
}

@media only screen and (max-width: 575px) {
  .card-footer {
    padding: 1rem;
  }
}

.transparent-card.card {
  background: transparent;
  border: 1px solid transparent;
  box-shadow: none;
}

.card-action > a {
  display: inline-block;
  width: 30px;
  height: 30px;
  line-height: 30px;
  border-radius: 5px;
  border-color: transparent;
  text-align: center;
  background: black;
  color: #fff;
  margin-right: 8px;
}

[data-theme-version='dark'] .card-action > a {
  background: #181f39;
}

.card-action > a:last-child {
  margin-right: 0;
}

.card-action > a:hover,
.card-action > a:focus {
  background: black;
}

[data-theme-version='dark'] .card-action > a:hover,
[data-theme-version='dark'] .card-action > a:focus {
  background: #181f39;
}

.card-action > a i,
.card-action > a span {
  font-size: 1rem;
}

.card-action .dropdown {
  width: 30px;
  height: 30px;
  border-radius: 5px;
  border-color: transparent;
  text-align: center;
  margin-right: 8px;
  top: -2px;
  position: relative;
  display: inline-block;
  background: black;
  color: #0b2a97;
}

[data-theme-version='dark'] .card-action .dropdown {
  background: #181f39;
}

.card-action .dropdown:hover,
.card-action .dropdown:focus {
  background: black;
}

[data-theme-version='dark'] .card-action .dropdown:hover,
[data-theme-version='dark'] .card-action .dropdown:focus {
  background: #181f39;
}

.card-action .dropdown .btn {
  padding: 0;
  line-height: 27px;
  color: #fff;
}

.card-action .dropdown .btn:focus {
  box-shadow: none;
}

.card-fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99999999;
  overflow: auto;
}

.card-loader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 999;
}

.card-loader i {
  margin: 0 auto;
  background: #092176;
  padding: 10px;
  border-radius: 50%;
  color: #fff;
  font-size: 1rem;
}

.rotate-refresh {
  -webkit-animation: mymove 0.8s infinite linear;
  animation: mymove 0.8s infinite linear;
  display: inline-block;
}

.card-header .date_picker {
  display: inline-block;
  padding: 8px;
  border: 1px solid #f0f1f5;
  cursor: pointer;
  border-radius: 0.375rem;
}

.card-header .border-0 {
  padding-bottom: 0;
}

@-webkit-keyframes mymove {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes mymove {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.dropdown-toggle:focus {
  box-shadow: none !important;
}

.dropdown-outline {
  border: 0.1rem solid #0b2a97;
}

.dropdown-menu {
  font-size: inherit;
  border: 0;
  box-shadow: 0 10px 40px 0 rgba(32, 28, 69, 0.1);
  margin-top: 0;
  border-radius: 1.25rem;
  overflow: hidden;
}

.dropdown-menu .dropdown-item {
  font-size: 16px;
  color: #7e7e7e;
  padding: 0.5rem 1.75rem;
}

@media only screen and (max-width: 1400px) {
  .dropdown-menu .dropdown-item {
    padding: 0.375rem 1rem;
    font-size: 14px;
  }
}

.dropdown-menu .dropdown-item:hover,
.dropdown-menu .dropdown-item:focus,
.dropdown-menu .dropdown-item:active,
.dropdown-menu .dropdown-item.active {
  color: #0b2a97;
  background: #f4f5f9;
}

.dropdown-menu .dropdown-item.active,
.dropdown-menu .dropdown-item:active {
  color: #0b2a97 !important;
}

[direction='rtl'] .dropdown-menu {
  right: auto !important;
}

.dropdown-toggle-split {
  padding: 0 10px;
  opacity: 0.85;
}

.dropdown-toggle-split:after {
  margin-left: 0 !important;
}

.dropdown-toggle-split:active,
.dropdown-toggle-split:focus,
.dropdown-toggle-split:hover {
  opacity: 1;
}

.dropleft .dropdown-toggle:before,
.dropright .dropdown-toggle:before,
.dropup .dropdown-toggle:after,
.dropdown-toggle:after {
  content: '\f107';
  font-family: FontAwesome;
  border: 0;
  vertical-align: middle;
  margin-left: 0.25em;
  line-height: 1;
}

.dropup .dropdown-toggle:after {
  content: '\f106';
}

.dropleft .dropdown-toggle::before {
  content: '\f104';
}

.dropright .dropdown-toggle::before {
  content: '\f105';
}

.dropright .dropdown-toggle::after {
  content: none;
}

.custom-dropdown {
  display: inline-block;
  margin-bottom: 1rem;
}

.custom-dropdown .dropdown-menu {
  border: 0px;
  min-width: 160px;
}

.card-action .custom-dropdown {
  margin: 0px;
  background: #496ff2;
}

.card-action .custom-dropdown.show,
.card-action .custom-dropdown:focus,
.card-action .custom-dropdown:hover {
  background: #0b2a97;
  color: #fff;
}

.card-action .custom-dropdown i {
  display: inline-block;
  padding-top: 9px;
}

.dropdown .dropdown-dots {
  position: relative;
  height: 5px;
  width: 5px;
  background: rgba(177, 177, 177, 0.4);
  border-radius: 5px;
  display: block;
}

.dropdown .dropdown-dots:after,
.dropdown .dropdown-dots:before {
  content: '';
  height: 5px;
  width: 5px;
  background: rgba(177, 177, 177, 0.4);
  position: absolute;
  border-radius: 5px;
}

.dropdown .dropdown-dots:after {
  right: -8px;
}

.dropdown .dropdown-dots:before {
  left: -8px;
}

.dropdown .dropdown-dots.text-white {
  background: rgba(255, 255, 255, 0.7);
}

.dropdown .dropdown-dots.text-white:after,
.dropdown .dropdown-dots.text-white:before {
  background: rgba(255, 255, 255, 0.7);
}

.grid-col {
  padding: 0.5rem !important;
  background: #f2f4fa;
}

.row.grid {
  margin-bottom: 1.5rem;
  text-align: center;
}

.row.grid .grid-col:first-child {
  text-align: left;
}

.label {
  display: inline-block;
  text-align: center;
  font-size: 0.75rem;
  padding: 0.2rem 0.8rem;
}

.label-fixed {
  width: 7.5rem;
  padding: 0.6rem 0;
}

.label-fixed-lg {
  width: 9.5rem;
  padding: 0.6rem 0;
}

.label-big {
  width: 16.8rem;
  font-size: 1.4rem;
  padding: 1.1rem 0;
}

.label-xl {
  width: 10.5rem;
  padding: 1.1rem 0;
  font-size: 1.5rem;
}

.label-lg {
  width: 9.5rem;
  padding: 1.1rem 0;
}

.label-md {
  width: 8.5rem;
  padding: 1.1rem 0;
}

.label-sm {
  width: 7.5rem;
  padding: 1.1rem 0;
}

.label-default {
  background: #adb6c7;
}

.label-primary {
  background: #0b2a97;
  color: #fff;
}

.label-secondary {
  background: #a02cfa;
  color: #fff;
}

.label-info {
  background: #1ea7c5;
  color: #fff;
}

.label-success {
  background: #2bc155;
  color: #fff;
}

.label-warning {
  background: #ffbc11;
  color: #fff;
}

.label-danger {
  background: #f94687;
  color: #fff;
}

.label-light {
  background: #f4f5f9;
  color: #000;
}

.label-dark {
  background: #b1b1b1;
  color: #fff;
}

code {
  word-break: break-word;
  padding: 2px 5px;
  border-radius: 3px;
  background: #fee9f1;
  color: #f94687;
}

[data-theme-version='dark'] code {
  background: rgba(249, 70, 135, 0.1);
}

.heading-labels {
  color: #333333;
}

.heading-labels > * {
  margin-bottom: 0.8rem;
}

.heading-labels h1 .label {
  font-size: 18px;
  font-weight: normal;
  padding: 0.4rem 0.9rem;
}

.heading-labels h2 .label {
  font-size: 16px;
  font-weight: normal;
  padding: 0.3rem 0.9rem;
}

.heading-labels h3 .label {
  font-size: 14px;
  font-weight: normal;
}

.heading-labels h4 .label {
  font-size: 12px;
  font-weight: normal;
}

.heading-labels h5 .label {
  font-size: 12px;
  font-weight: normal;
}

.heading-labels h6 .label {
  font-size: 12px;
  font-weight: normal;
}

.list-group-item {
  background-color: rgba(255, 255, 255, 0);
  border: 1px solid #f0f1f5;
  padding: 1rem 1.5rem;
  border-radius: 0;
}

[data-theme-version='dark'] .list-group-item {
  border-color: #333a54;
}

.list-group-item:first-child {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.list-group-item:last-child {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.list-group-item.disabled,
.list-group-item:disabled {
  color: #fff;
  background-color: #a02cfa;
  border-color: #a02cfa;
}

[class*='bg-'] .list-group-item {
  border-color: rgba(255, 255, 255, 0.05);
}

.bg-warning .list-group-item {
  border-color: rgba(0, 0, 0, 0.05);
}

.media img {
  border-radius: 3px;
}

.vertical-card__menu:hover {
  box-shadow: none;
}

.vertical-card__menu--image {
  text-align: center;
}

.vertical-card__menu--image img {
  width: 100%;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.vertical-card__menu--status.closed {
  background: #f94687;
}

.vertical-card__menu--status.closed:after {
  border-top: 10px solid #f94687;
}

.vertical-card__menu--status.closed .ribbon-curve {
  border-top: 11px solid #f94687;
  border-bottom: 10px solid #f94687;
}

.vertical-card__menu--desc p {
  margin-bottom: 0.625rem;
}

.vertical-card__menu--price {
  font-size: 1.25rem;
  margin: 0;
  font-weight: 700;
}

.vertical-card__menu--rating {
  font-size: 0.75rem;
}

.vertical-card__menu--title {
  font-weight: 700;
}

.vertical-card__menu--button button {
  font-size: 0.75rem;
}

.vertical-card__menu .card-footer {
  padding: 15px 20px;
  background-color: #f0f1f5;
  border-top: none;
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .vertical-card__menu .card-footer {
    padding: 15px;
  }
}

.vertical-card__menu .card-footer span {
  color: #b1b1b1;
  margin-right: 0.3125rem;
}

.horizontal-card__menu {
  box-shadow: 0 0 7px rgba(173, 173, 173, 0.32);
  display: flex;
  flex-direction: row;
}

@media (max-width: 575.98px) {
  .horizontal-card__menu {
    display: block;
  }
}

.horizontal-card__menu .card-body {
  padding: 0;
  padding: 20px 30px;
}

.horizontal-card__menu--image {
  flex-basis: 30%;
  max-width: 30%;
}

.horizontal-card__menu--image img {
  height: 100%;
  width: 100%;
}

@media (max-width: 575.98px) {
  .horizontal-card__menu--image {
    max-width: unset;
    flex-basis: 100%;
  }
}

.horizontal-card__menu--title {
  font-size: 1rem;
  font-weight: 700;
  margin-bottom: 0.3125rem;
}

.horizontal-card__menu--fav {
  margin-right: 0.5rem;
}

.horizontal-card__menu--price {
  margin: 0;
  font-size: 1rem;
  font-weight: 700;
}

.horizontal-card__menu--rating {
  font-size: 0.625rem;
}

.horizontal-card__menu--footer {
  margin-top: 10px;
}

.prev_price {
  text-decoration: line-through;
  font-size: 80%;
  opacity: 0.5;
}

.modal-header {
  padding: 1rem 1.875rem;
}

.modal-header .close {
  padding: 0.875rem 1.815rem;
  margin: 0;
  position: absolute;
  right: 0;
  float: none;
  top: 0;
  font-size: 30px;
  font-weight: 100;
}

.modal-body {
  padding: 1.875rem;
}

.modal-footer {
  padding: 1rem 1.875rem;
}

.modal-content {
  border-radius: 1.25rem;
}

.modal-backdrop {
  z-index: 10 !important;
}

.pagination .page-item .page-link:hover {
  background: #0b2a97;
  border-color: #0b2a97;
  color: #fff;
}

.pagination .page-item.active .page-link {
  background: #0b2a97;
  border-color: #0b2a97;
  color: #fff;
}

.pagination {
  margin-bottom: 20px;
}

.pagination .page-item.page-indicator .page-link {
  padding: 0.65rem 0.8rem;
  font-size: 14px;
}

.pagination .page-item.page-indicator:hover .page-link {
  color: #b1b1b1;
}

.pagination .page-item .page-link {
  text-align: center;
  z-index: 2;
  padding: 0.55rem 1rem;
  font-size: 1rem;
  background: rgba(255, 255, 255, 0.15);
  color: #b1b1b1;
  border: 1px solid #f0f1f5;
}

[data-theme-version='dark'] .pagination .page-item .page-link {
  border-color: #333a54;
  color: #828690;
  background: rgba(255, 255, 255, 0);
}

.pagination .page-item .page-link:hover i,
.pagination .page-item .page-link span {
  color: #fff;
}

.pagination .page-item .page-link:focus {
  outline: 0;
  box-shadow: none;
}

.pagination .page-item .page-link:hover {
  background: #0b2a97;
  color: #fff;
  border-color: #0b2a97;
}

.pagination .page-item.active .page-link {
  background-color: #0b2a97;
  border-color: #0b2a97;
  color: #fff;
  box-shadow: 0 10px 20px 0px rgba(11, 42, 151, 0.2);
}

[data-theme-version='dark'] .pagination .page-item.active .page-link {
  color: #fff;
}

.pagination .page-item .page-link {
  color: #b1b1b1;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}

.pagination .page-item:last-child .page-link {
  margin-right: 0;
}

[direction='rtl'] .pagination .page-item:first-child .page-link {
  margin-right: 0;
}

.pagination.no-bg li:not(.page-indicator):not(.active) .page-link {
  background: transparent;
  border-color: transparent;
}

.pagination.no-bg.pagination-primary
  li:not(.page-indicator):not(.active):hover
  .page-link {
  background: #0b2a97;
  border-color: #0b2a97;
}

[data-theme-version='dark']
  .pagination.no-bg.pagination-primary
  li:not(.page-indicator):not(.active):hover
  .page-link {
  background: #0b2a97;
  border-color: #0b2a97;
}

.pagination.no-bg.pagination-danger
  li:not(.page-indicator):not(.active):hover
  .page-link {
  background: #f94687;
  border-color: #f94687;
}

[data-theme-version='dark']
  .pagination.no-bg.pagination-danger
  li:not(.page-indicator):not(.active):hover
  .page-link {
  background: #f94687;
  border-color: #f94687;
}

.pagination.no-bg.pagination-info
  li:not(.page-indicator):not(.active):hover
  .page-link {
  background: #1ea7c5;
  border-color: #1ea7c5;
}

[data-theme-version='dark']
  .pagination.no-bg.pagination-info
  li:not(.page-indicator):not(.active):hover
  .page-link {
  background: #1ea7c5;
  border-color: #1ea7c5;
}

.pagination.no-bg.pagination-warning
  li:not(.page-indicator):not(.active):hover
  .page-link {
  background: #ffbc11;
  border-color: #ffbc11;
}

[data-theme-version='dark']
  .pagination.no-bg.pagination-warning
  li:not(.page-indicator):not(.active):hover
  .page-link {
  background: #ffbc11;
  border-color: #ffbc11;
}

.pagination-primary .page-item .page-link {
  background: #d8e0fc;
  border-color: #d8e0fc;
  color: #0b2a97;
}

[data-theme-version='dark'] .pagination-primary .page-item .page-link {
  background: rgba(11, 42, 151, 0.2);
  border-color: transparent;
  color: #0b2a97;
}

.pagination-primary .page-item:hover .page-link,
.pagination-primary .page-item.active .page-link {
  background: #0b2a97;
  border-color: #0b2a97;
  box-shadow: 0 10px 20px 0px rgba(11, 42, 151, 0.2);
}

[data-theme-version='dark'] .pagination-primary .page-item:hover .page-link,
[data-theme-version='dark'] .pagination-primary .page-item.active .page-link {
  color: #fff;
}

.pagination-danger .page-item .page-link {
  background: #fff3f7;
  border-color: #fff3f7;
  color: #f94687;
}

[data-theme-version='dark'] .pagination-danger .page-item .page-link {
  background: rgba(249, 70, 135, 0.15);
  border-color: transparent;
  color: #f94687;
}

.pagination-danger .page-item:hover .page-link,
.pagination-danger .page-item.active .page-link {
  background: #f94687;
  border-color: #f94687;
  box-shadow: 0 10px 20px 0px rgba(249, 70, 135, 0.2);
}

[data-theme-version='dark'] .pagination-danger .page-item:hover .page-link,
[data-theme-version='dark'] .pagination-danger .page-item.active .page-link {
  color: #fff;
}

.pagination-info .page-item .page-link {
  background: #a4e3f1;
  border-color: #a4e3f1;
  color: #1ea7c5;
}

[data-theme-version='dark'] .pagination-info .page-item .page-link {
  background: rgba(30, 167, 197, 0.1);
  border-color: transparent;
  color: #1ea7c5;
}

.pagination-info .page-item:hover .page-link,
.pagination-info .page-item.active .page-link {
  background: #1ea7c5;
  border-color: #1ea7c5;
  box-shadow: 0 10px 20px 0px rgba(30, 167, 197, 0.2);
}

.pagination-warning .page-item .page-link {
  background: #fff5dd;
  border-color: #fff5dd;
  color: #ffbc11;
}

[data-theme-version='dark'] .pagination-warning .page-item .page-link {
  background: rgba(255, 188, 17, 0.1);
  border-color: transparent;
  color: #ffbc11;
}

.pagination-warning .page-item:hover .page-link,
.pagination-warning .page-item.active .page-link {
  background: #ffbc11;
  border-color: #ffbc11;
  box-shadow: 0 10px 20px 0px rgba(255, 188, 17, 0.2);
}

.pagination-gutter .page-item {
  margin-right: 7px;
}

.pagination-gutter .page-item .page-link {
  border-radius: 1.25rem !important;
}

.pagination-circle .page-item {
  margin-right: 7px;
}

.pagination-circle .page-item .page-link,
.pagination-circle .page-item.page-indicator .page-link {
  width: 40px;
  height: 40px;
  padding: 0;
  line-height: 40px;
  border-radius: 50% !important;
  padding: 0;
}

.pagination.pagination-md .page-item .page-link {
  width: 30px;
  height: 30px;
  line-height: 30px;
  font-size: 14px;
}

.pagination.pagination-sm .page-item.page-indicator .page-link {
  font-size: 12px;
}

.pagination.pagination-sm .page-item .page-link {
  padding: 0;
  width: 30px;
  height: 30px;
  line-height: 30px;
  font-size: 14px;
}

.pagination.pagination-xs .page-item.page-indicator .page-link {
  font-size: 10px;
}

.pagination.pagination-xs .page-item .page-link {
  padding: 0;
  width: 25px;
  height: 25px;
  line-height: 25px;
  font-size: 12px;
}

.popover {
  border: 2px solid #a02cfa;
  min-width: 210px;
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.1);
  border-radius: 0;
}

[data-theme-version='dark'] .popover {
  background-color: #181f39;
}

.popover-header {
  background: #a02cfa;
  color: #fff;
  font-weight: 300;
  border-radius: 0;
}

.popover-header::before {
  border-bottom: 0 !important;
}

.popover-body {
  font-size: 0.75rem;
}

[data-theme-version='dark'] .popover .popover-header {
  border-color: #1e2746;
}

.bootstrap-popover-wrapper .bootstrap-popover:not(:last-child) {
  margin-right: 8px;
}

.bootstrap-popover-wrapper .bootstrap-popover {
  margin-bottom: 0.5rem;
}

.bootstrap-popover-wrapper .bootstrap-popover button:hover,
.bootstrap-popover-wrapper .bootstrap-popover button:focus {
  background: #0b2a97;
  color: #fff;
  box-shadow: none;
}

.bs-popover-top .arrow::after,
.bs-popover-auto[x-placement^='top'] .arrow::after,
.bs-popover-auto[x-placement^='top'] .arrow::after {
  bottom: 0px;
  border-top-color: #a02cfa;
}

.bs-popover-left .arrow::after,
.bs-popover-auto[x-placement^='left'] .arrow::after,
.bs-popover-auto[x-placement^='left'] .arrow::after {
  right: 0px;
  border-left-color: #a02cfa;
}

.bs-popover-right .arrow::after,
.bs-popover-auto[x-placement^='right'] .arrow::after,
.bs-popover-auto[x-placement^='right'] .arrow::after {
  left: 0px;
  border-right-color: #a02cfa;
}

.bs-popover-bottom .arrow::after,
.bs-popover-auto[x-placement^='bottom'] .arrow::after,
.bs-popover-auto[x-placement^='bottom'] .arrow::after {
  top: 0px;
  border-bottom-color: #a02cfa;
}

.note-popover {
  display: none;
}

.progress-bar {
  background-color: #0b2a97;
}

.progress-bar-primary {
  background-color: #0b2a97;
}

.progress-bar-success {
  background-color: #2bc155;
}

.progress-bar-info {
  background-color: #1ea7c5;
}

.progress-bar-danger {
  background-color: #f94687;
}

.progress-bar-warning {
  background-color: #ffbc11;
}

.progress-bar-pink {
  background-color: #e83e8c;
}

.progress {
  height: 6px;
  overflow: hidden;
  border-radius: 1.25rem;
}

[data-theme-version='dark'] .progress {
  background-color: #181f39;
}

.progress-bar {
  border-radius: 4px;
}

.progress-vertical {
  display: inline-block;
  margin-bottom: 0;
  margin-right: 2rem;
  min-height: 17rem;
  position: relative;
}

@media (max-width: 991.98px) {
  .progress-vertical {
    margin-right: 1rem;
  }
}

.progress-vertical-bottom {
  display: inline-block;
  margin-bottom: 0;
  margin-right: 2rem;
  min-height: 17rem;
  position: relative;
  transform: rotate(180deg);
}

@media (max-width: 991.98px) {
  .progress-vertical-bottom {
    margin-right: 1rem;
  }
}

.progress-animated {
  animation-duration: 5s;
  animation-name: myanimation;
  transition: all 5s ease 0s;
}

@keyframes myanimation {
  0% {
    width: 0;
  }
}

@keyframes myanimation {
  0% {
    width: 0;
  }
}

.ribbon {
  position: absolute;
  z-index: 1;
  text-transform: uppercase;
}

.ribbon__one {
  top: 15px;
  left: -11px;
  min-height: 20px;
  min-width: 52px;
  text-align: center;
  padding: 3px 10px;
  background: #3ab54b;
  color: #fff;
  font-size: 0.625rem;
}

.ribbon__one::after {
  position: absolute;
  width: 0;
  height: 0;
  border-top: 10px solid #239132;
  border-left: 11px solid transparent;
  left: 0;
  content: '';
  bottom: -10px;
}

.ribbon__two {
  width: 50px;
  height: 50px;
  display: inline-block;
  background: #ffbc11;
  line-height: 50px;
  text-align: center;
  font-size: 16px;
  color: #fff;
  right: 15px;
  top: 15px;
  border-radius: 3px;
}

.ribbon__three {
  left: -1.875rem;
  top: 0.875rem;
  width: 6.875rem;
  height: 1.5625rem;
  background-color: #f94687;
  clip-path: polygon(20% 0%, 80% 0%, 100% 100%, 0% 100%);
  transform: rotate(-45deg);
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
}

.ribbon__four {
  left: 8px;
  top: -8px;
  width: 110px;
  height: 50px;
  background-color: #0b2a97;
  z-index: auto;
  color: #fff;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ribbon__four:after {
  right: -5px;
}

.ribbon__four:before {
  left: -5px;
}

.ribbon__four:after,
.ribbon__four:before {
  z-index: -1;
  background-color: #1a49ee;
  top: 3px;
  transform: rotate(45deg);
  content: '';
  height: 10px;
  width: 10px;
  position: absolute;
}

.ribbon__five {
  left: -1.875rem;
  top: 0.625rem;
  width: 6.875rem;
  height: 1.875rem;
  background-color: #0b2a97;
  transform: rotate(-45deg);
  font-size: 0.75rem;
  color: #fff;
  padding-bottom: 0.3125rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
}

.ribbon__five::before {
  position: absolute;
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 50px 50px 50px;
  border-color: transparent transparent #0b2a97 transparent;
  left: 0.25rem;
  top: -2.8125rem;
}

.ribbon__six {
  left: 0;
  top: 1.125rem;
  width: 6.875rem;
  height: 2.375rem;
  background-color: #0b2a97;
  -webkit-clip-path: polygon(
    0 0,
    100% 0%,
    100% 0,
    85% 50%,
    100% 100%,
    100% 100%,
    0 100%
  );
  clip-path: polygon(
    0 0,
    100% 0%,
    100% 0,
    85% 50%,
    100% 100%,
    100% 100%,
    0 100%
  );
  display: flex;
  font-size: 1.25rem;
  align-items: center;
  justify-content: center;
  color: #fff;
}

.ribbon-curve {
  position: absolute;
  top: 0;
  right: -6px;
  width: 10px;
  height: 11px;
  border-top: 11px solid #3ab54b;
  border-bottom: 10px solid #3ab54b;
  border-right: 5px solid transparent;
}

/* .jsgrid-grid-body::-webkit-scrollbar,
.dataTables_scrollBody::-webkit-scrollbar,
.table-responsive::-webkit-scrollbar {
  background-color: #f5f5f5;
  width: 8px;
  height: 8px;
}

.jsgrid-grid-body::-webkit-scrollbar-track,
.dataTables_scrollBody::-webkit-scrollbar-track,
.table-responsive::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

.jsgrid-grid-body::-webkit-scrollbar-thumb,
.dataTables_scrollBody::-webkit-scrollbar-thumb,
.table-responsive::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #7e7e7e;
} */

.multi-steps > li.is-active:before,
.multi-steps > li.is-active ~ li:before {
  content: counter(stepNum);
  font-family: inherit;
  font-weight: 400;
}

.multi-steps > li.is-active:after,
.multi-steps > li.is-active ~ li:after {
  background-color: #f0f1f5;
}

.multi-steps {
  display: table;
  table-layout: fixed;
  width: 100%;
}

.multi-steps > li {
  counter-increment: stepNum;
  text-align: center;
  display: table-cell;
  position: relative;
  color: #0b2a97;
}

@media (max-width: 575.98px) {
  .multi-steps > li {
    font-size: 0.75rem;
  }
}

.multi-steps > li:before {
  content: '\2713';
  display: block;
  margin: 0 auto 4px;
  background-color: #fff;
  width: 25px;
  height: 25px;
  line-height: 22px;
  text-align: center;
  font-weight: bold;
  position: relative;
  z-index: 1;
  border-width: 2px;
  border-style: solid;
  border-color: #0b2a97;
  border-radius: 5px;
}

@media (max-width: 575.98px) {
  .multi-steps > li:before {
    width: 25px;
    height: 25px;
    line-height: 21px;
  }
}

.multi-steps > li:after {
  content: '';
  height: 2px;
  width: 100%;
  background-color: #0b2a97;
  position: absolute;
  top: 12px;
  left: 50%;
}

[direction='rtl'] .multi-steps > li:after {
  left: auto;
  right: 50%;
}

@media (max-width: 575.98px) {
  .multi-steps > li:after {
    top: 12px;
  }
}

.multi-steps > li:last-child:after {
  display: none;
}

.multi-steps > li.is-active:before {
  background-color: #fff;
  border-color: #0b2a97;
}

.multi-steps > li.is-active ~ li {
  color: #7e7e7e;
}

.multi-steps > li.is-active ~ li:before {
  background-color: #f0f1f5;
  border-color: #f0f1f5;
}

.nav-pills .nav-link {
  border-radius: 1.25rem;
  padding: 0.75rem 1.25rem;
}

.default-tab .nav-link {
  background: transparent;
  border-radius: 0px;
  font-weight: 500;
}

.default-tab .nav-link i {
  display: inline-block;
  transform: scale(1.5);
  color: #0b2a97;
}

.default-tab .nav-link:focus,
.default-tab .nav-link:hover,
.default-tab .nav-link.active {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff #ebeef6;
  border-radius: 1.25rem 1.25rem 0 0;
  color: #0b2a97;
}

[data-theme-version='dark'] .default-tab .nav-link:focus,
[data-theme-version='dark'] .default-tab .nav-link:hover,
[data-theme-version='dark'] .default-tab .nav-link.active {
  background-color: rgba(11, 42, 151, 0.2);
  border-color: transparent transparent #333a54 transparent;
}

.custom-tab-1 .nav-link {
  background: transparent;
  border-radius: 0px;
  font-weight: 500;
  border-bottom: 3px solid transparent;
  border-width: 0 0px 3px 0;
}

.custom-tab-1 .nav-link i {
  display: inline-block;
  transform: scale(1.5);
  color: #0b2a97;
}

.custom-tab-1 .nav-link:focus,
.custom-tab-1 .nav-link:hover,
.custom-tab-1 .nav-link.active {
  color: #495057;
  background-color: #fff;
  border-color: #0b2a97;
  border-radius: 0;
  color: #0b2a97;
  border-width: 0 0px 3px 0;
}

[data-theme-version='dark'] .custom-tab-1 .nav-link:focus,
[data-theme-version='dark'] .custom-tab-1 .nav-link:hover,
[data-theme-version='dark'] .custom-tab-1 .nav-link.active {
  background-color: rgba(11, 42, 151, 0.2);
}

.nav-pills.light .nav-link.active,
.nav-pills.light .show > .nav-link {
  background: #d8e0fc;
  color: #0b2a97;
  box-shadow: none;
}

[data-theme-version='dark'] .nav-pills.light .nav-link.active,
[data-theme-version='dark'] .nav-pills.light .show > .nav-link {
  background: rgba(11, 42, 151, 0.2);
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  box-shadow: 0 0 20px 0 rgba(11, 42, 151, 0.2);
}

.tooltip-wrapper button:not(:last-child) {
  margin-right: 8px;
}

.tooltip-wrapper button:hover {
  background: #0b2a97;
  color: #fff;
}

.tooltip-wrapper button {
  margin-bottom: 0.5rem;
}

.tooltip-wrapper button:focus {
  box-shadow: none;
}

.tooltip-inner {
  border-radius: 0;
  background: #333333;
  font-size: 12px;
  font-weight: 300;
  padding: 0.35rem 0.7rem;
}

.bs-tooltip-bottom .arrow::before,
.bs-tooltip-auto[x-placement^='bottom'] .arrow::before,
.bs-tooltip-auto[x-placement^='bottom'] .arrow::before {
  border-bottom-color: #333333;
}

.bs-tooltip-right .arrow::before,
.bs-tooltip-auto[x-placement^='right'] .arrow::before,
.bs-tooltip-auto[x-placement^='right'] .arrow::before {
  border-right-color: #333333;
}

.bs-tooltip-left .arrow::before,
.bs-tooltip-auto[x-placement^='left'] .arrow::before,
.bs-tooltip-auto[x-placement^='left'] .arrow::before {
  border-left-color: #333333;
}

.bs-tooltip-top .arrow::before,
.bs-tooltip-auto[x-placement^='top'] .arrow::before,
.bs-tooltip-auto[x-placement^='top'] .arrow::before {
  border-top-color: #333333;
}

.widget-timeline .timeline {
  list-style: none;
  position: relative;
}

.widget-timeline .timeline:before {
  top: 20px;
  bottom: 0;
  position: absolute;
  content: ' ';
  width: 3px;
  left: 9px;
  margin-right: -1.5px;
  background: #f4f5f9;
}

[data-theme-version='dark'] .widget-timeline .timeline:before {
  background: #333a54;
}

[direction='rtl'] .widget-timeline .timeline:before {
  left: auto;
  right: 9px;
  margin-right: auto;
  margin-left: -1.5px;
}

.widget-timeline .timeline > li {
  margin-bottom: 15px;
  position: relative;
}

.widget-timeline .timeline > li:before {
  content: ' ';
  display: table;
}

.widget-timeline .timeline > li:after {
  content: ' ';
  display: table;
  clear: both;
}

.widget-timeline .timeline > li > .timeline-panel {
  border-radius: 1.25rem;
  padding: 15px 20px;
  position: relative;
  display: block;
  margin-left: 40px;
  border-width: 1px;
  border-style: solid;
}

.widget-timeline .timeline > li > .timeline-panel span {
  font-size: 12px;
  display: block;
  margin-bottom: 5px;
  opacity: 0.8;
  letter-spacing: 1px;
}

.widget-timeline .timeline > li > .timeline-panel p {
  font-size: 14px;
}

.widget-timeline .timeline > li > .timeline-panel:after {
  content: '';
  width: 10px;
  height: 10px;
  background: inherit;
  border-color: inherit;
  border-style: solid;
  border-width: 0px 0px 1px 1px;
  display: block;
  position: absolute;
  left: -5px;
  transform: rotate(45deg);
  top: 15px;
}

.widget-timeline .timeline > li > .timeline-badge {
  border-radius: 50%;
  height: 22px;
  left: 0;
  position: absolute;
  top: 10px;
  width: 22px;
  border-width: 2px;
  border-style: solid;
  background: #fff;
  padding: 4px;
}

[data-theme-version='dark'] .widget-timeline .timeline > li > .timeline-badge {
  background-color: #1e2746;
}

.widget-timeline .timeline > li > .timeline-badge:after {
  content: '';
  width: 10px;
  height: 10px;
  border-radius: 100%;
  display: block;
}

[direction='rtl'] .widget-timeline .timeline > li > .timeline-badge {
  left: auto;
  right: 19px;
}

.widget-timeline .timeline-body > p {
  font-size: 12px;
}

.widget-timeline .timeline-badge.primary {
  border-color: #d8e0fc;
}

[data-theme-version='dark'] .widget-timeline .timeline-badge.primary {
  border-color: rgba(11, 42, 151, 0.2);
}

.widget-timeline .timeline-badge.primary:after {
  background-color: #0b2a97 !important;
  box-shadow: 0 5px 10px 0 rgba(11, 42, 151, 0.2);
}

.widget-timeline .timeline-badge.primary + .timeline-panel {
  background: #d8e0fc;
  border-color: #d8e0fc;
}

[data-theme-version='dark']
  .widget-timeline
  .timeline-badge.primary
  + .timeline-panel {
  border-color: transparent;
  background-color: rgba(11, 42, 151, 0.2);
}

.widget-timeline .timeline-badge.success {
  border-color: #e3f9e9;
}

[data-theme-version='dark'] .widget-timeline .timeline-badge.success {
  border-color: rgba(43, 193, 85, 0.1);
}

.widget-timeline .timeline-badge.success:after {
  background-color: #2bc155 !important;
  box-shadow: 0 5px 10px 0 rgba(43, 193, 85, 0.2);
}

.widget-timeline .timeline-badge.success + .timeline-panel {
  background: #e3f9e9;
  border-color: #e3f9e9;
}

[data-theme-version='dark']
  .widget-timeline
  .timeline-badge.success
  + .timeline-panel {
  background-color: rgba(43, 193, 85, 0.1);
  border-color: transparent;
}

.widget-timeline .timeline-badge.warning {
  border-color: #fff5dd;
}

[data-theme-version='dark'] .widget-timeline .timeline-badge.warning {
  border-color: rgba(255, 188, 17, 0.1);
}

.widget-timeline .timeline-badge.warning:after {
  background-color: #ffbc11 !important;
  box-shadow: 0 5px 10px 0 rgba(255, 188, 17, 0.2);
}

.widget-timeline .timeline-badge.warning + .timeline-panel {
  background: #fff5dd;
  border-color: #fff5dd;
}

[data-theme-version='dark']
  .widget-timeline
  .timeline-badge.warning
  + .timeline-panel {
  background-color: rgba(255, 188, 17, 0.1);
  border-color: transparent;
}

.widget-timeline .timeline-badge.danger {
  border-color: #fff3f7;
}

[data-theme-version='dark'] .widget-timeline .timeline-badge.danger {
  border-color: rgba(249, 70, 135, 0.15);
}

.widget-timeline .timeline-badge.danger:after {
  background-color: #f94687 !important;
  box-shadow: 0 5px 10px 0 rgba(249, 70, 135, 0.2);
}

.widget-timeline .timeline-badge.danger + .timeline-panel {
  background: #fff3f7;
  border-color: #fff3f7;
}

[data-theme-version='dark']
  .widget-timeline
  .timeline-badge.danger
  + .timeline-panel {
  background-color: rgba(249, 70, 135, 0.15);
  border-color: transparent;
}

.widget-timeline .timeline-badge.info {
  border-color: #a4e3f1;
}

[data-theme-version='dark'] .widget-timeline .timeline-badge.info {
  border-color: rgba(30, 167, 197, 0.1);
}

.widget-timeline .timeline-badge.info:after {
  background-color: #1ea7c5 !important;
  box-shadow: 0 5px 10px 0 rgba(30, 167, 197, 0.2);
}

.widget-timeline .timeline-badge.info + .timeline-panel {
  background: #a4e3f1;
  border-color: #a4e3f1;
}

[data-theme-version='dark']
  .widget-timeline
  .timeline-badge.info
  + .timeline-panel {
  background-color: rgba(30, 167, 197, 0.1);
  border-color: transparent;
}

.widget-timeline .timeline-badge.dark {
  border-color: #f1f1f1;
}

[data-theme-version='dark'] .widget-timeline .timeline-badge.dark {
  border-color: rgba(177, 177, 177, 0.35);
}

.widget-timeline .timeline-badge.dark:after {
  background-color: #b1b1b1 !important;
  box-shadow: 0 5px 10px 0 rgba(177, 177, 177, 0.2);
}

.widget-timeline .timeline-badge.dark + .timeline-panel {
  background: #f1f1f1;
  border-color: #f1f1f1;
}

[data-theme-version='dark']
  .widget-timeline
  .timeline-badge.dark
  + .timeline-panel {
  background-color: rgba(177, 177, 177, 0.35);
  border-color: transparent;
}

.widget-timeline.style-1 .timeline-panel {
  background: transparent;
}

.widget-timeline.style-1
  .timeline
  .timeline-badge.timeline-badge
  + .timeline-panel {
  background: transparent !important;
  border-style: solid;
  border-width: 0 0 0 5px;
  border-radius: 0;
  padding: 5px 10px 5px 15px;
}

.widget-timeline.style-1
  .timeline
  .timeline-badge.timeline-badge
  + .timeline-panel:after {
  border: 0;
  left: -9px;
  width: 7px;
  height: 7px;
}

.widget-timeline.style-1 .timeline .timeline-badge.primary + .timeline-panel {
  border-color: #0b2a97;
}

.widget-timeline.style-1
  .timeline
  .timeline-badge.primary
  + .timeline-panel:after {
  background: #0b2a97;
}

.widget-timeline.style-1 .timeline .timeline-badge.success + .timeline-panel {
  border-color: #2bc155;
}

.widget-timeline.style-1
  .timeline
  .timeline-badge.success
  + .timeline-panel:after {
  background: #2bc155;
}

.widget-timeline.style-1 .timeline .timeline-badge.warning + .timeline-panel {
  border-color: #ffbc11;
}

.widget-timeline.style-1
  .timeline
  .timeline-badge.warning
  + .timeline-panel:after {
  background: #ffbc11;
}

.widget-timeline.style-1 .timeline .timeline-badge.danger + .timeline-panel {
  border-color: #f94687;
}

.widget-timeline.style-1
  .timeline
  .timeline-badge.danger
  + .timeline-panel:after {
  background: #f94687;
}

.widget-timeline.style-1 .timeline .timeline-badge.info + .timeline-panel {
  border-color: #1ea7c5;
}

.widget-timeline.style-1
  .timeline
  .timeline-badge.info
  + .timeline-panel:after {
  background: #1ea7c5;
}

.widget-timeline.style-1 .timeline .timeline-badge.dark + .timeline-panel {
  border-color: #b1b1b1;
}

.widget-timeline.style-1
  .timeline
  .timeline-badge.dark
  + .timeline-panel:after {
  background: #b1b1b1;
}

#chart_widget_4 {
  height: 255px !important;
}

#chart_widget_5 .ct-series-a .ct-line,
#chart_widget_5 .ct-series-a .ct-point {
  stroke: #46ffc8;
}

#chart_widget_5 .ct-line {
  stroke-width: 1px;
}

#chart_widget_5 .ct-point {
  stroke-width: 2px;
}

#chart_widget_5 .ct-series-a .ct-area {
  fill: #43d66c;
}

#chart_widget_5 .ct-area {
  fill-opacity: 1;
}

#chart_widget_6 .ct-series-a .ct-line,
#chart_widget_6 .ct-series-a .ct-point {
  stroke: #1ea7c5;
}

#chart_widget_6 .ct-line {
  stroke-width: 2px;
}

#chart_widget_6 .ct-point {
  stroke-width: 5px;
}

#chart_widget_6 .ct-series-a .ct-area {
  fill: #1ea7c5;
}

#chart_widget_6 .ct-area {
  fill-opacity: 0.5;
}

#chart_widget_8 {
  height: 255px;
}

#chart_widget_8 .ct-series-a .ct-line,
#chart_widget_8 .ct-series-a .ct-point {
  stroke: #1ea7c5;
}

#chart_widget_8 .ct-line {
  stroke-width: 2px;
}

#chart_widget_8 .ct-point {
  stroke-width: 5px;
}

#chart_widget_8 .ct-series-a .ct-area {
  fill: #1ea7c5;
}

#chart_widget_8 .ct-area {
  fill-opacity: 0.5;
}

#chart_widget_9,
#chart_widget_10 {
  height: 250px !important;
}

#chart_widget_11 .ct-slice-donut,
#chart_widget_12 .ct-slice-donut,
#chart_widget_13 .ct-slice-donut {
  stroke-width: 25px !important;
}

#chart_widget_11 {
  height: 270px !important;
}

#chart_widget_17 {
  height: 150px !important;
}

.chart_widget_tab_one .nav-link {
  border: 1px solid #ddd;
}

.chart_widget_tab_one .nav-link.active {
  background-color: #0b2a97;
  border: 1px solid #0b2a97;
  color: #fff;
}

.chart_widget_tab_one .nav-link.active:hover {
  border: 1px solid #0b2a97;
}

.chart_widget_tab_one .nav-link:hover {
  border: 1px solid #ddd;
}

[data-theme-version='dark'] .ccc-widget > div {
  background: #1e2746 !important;
  border-color: #333a54 !important;
}

.social-icon {
  display: inline-block;
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 4px;
  text-align: center;
  background: #f9f9f9;
  margin-bottom: 0.5rem;
  font-size: 20px;
}

.social-icon i {
  color: #fff;
}

.social-icon.youtube {
  background: #ff0000;
}

.social-icon.facebook {
  background: #3b5998;
}

.social-icon.twitter {
  background: #1da1f2;
}

.social-icon2 a {
  border: 0.1rem solid #0b2a97;
  display: block;
  padding: 1rem;
  margin-top: 1.5rem;
  margin-bottom: 0.5rem;
  border-radius: 0.3rem;
  font-size: 1.6rem;
}

.social-icon2 i {
  font-size: 12px;
  color: #0b2a97;
}

.social-icon3 ul li {
  display: inline-block;
}

.social-icon3 ul li a {
  display: block;
}

.social-icon3 ul li a i {
  font-size: 1.4rem;
  padding: 1rem 0.7rem;
  color: #464a53;
}

.social-icon3 ul li a:hover i {
  color: #0b2a97;
}

.social-icons-muted ul li a i {
  color: #89879f;
}

.social-links a {
  padding: 0.5rem;
}

.widget-stat .media {
  padding: 0px 0;
  align-items: center;
}

.widget-stat .media > span {
  height: 85px;
  width: 85px;
  border-radius: 100%;
  padding: 10px 12px;
  font-size: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #464a53;
}

@media only screen and (max-width: 1400px) {
  .widget-stat .media > span {
    height: 70px;
    width: 70px;
    min-width: 70px;
  }
}

.widget-stat .media .media-body p {
  font-weight: 500;
  font-size: 16px;
  line-height: 1.5;
}

@media only screen and (max-width: 1400px) {
  .widget-stat .media .media-body p {
    font-size: 14px;
  }
}

[data-theme-version='dark'] .widget-stat .media .media-body p {
  color: #c4c9d5;
}

.widget-stat .media .media-body small,
.widget-stat .media .media-body .small {
  font-size: 75%;
}

.widget-stat .media .media-body h3 {
  font-size: 30px;
  font-weight: 600;
  margin: 0;
  line-height: 1.2;
}

.widget-stat .media .media-body h4 {
  font-size: 24px;
  display: inline-block;
  vertical-align: middle;
}

.widget-stat .media .media-body span {
  margin-left: 5px;
}

.widget-stat[class*='bg-'] .media > span {
  background-color: rgba(255, 255, 255, 0.25);
  color: #fff;
}

.widget-stat[class*='bg-'] .progress {
  background-color: rgba(255, 255, 255, 0.25) !important;
}

[direction='rtl'] .widget-stat .media .media-body span {
  margin-left: 0;
  margin-right: 10px;
}

.dez-widget-1 .card {
  background: #ffe7db;
}

.dez-widget-1 .card .card-body p {
  color: #f87533;
}

.dez-widget-1 .icon {
  float: right;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  font-size: 28px;
}

.bgl-primary {
  background: #d8e0fc;
  border-color: #d8e0fc;
}

[data-theme-version='dark'] .bgl-primary {
  background-color: rgba(11, 42, 151, 0.2);
  border-color: rgba(11, 42, 151, 0.2);
}

.bgl-secondary {
  background: #f1dffe;
  border-color: #f1dffe;
}

[data-theme-version='dark'] .bgl-secondary {
  background-color: rgba(160, 44, 250, 0.5);
  border-color: rgba(160, 44, 250, 0.5);
}

.bgl-success {
  background: #e3f9e9;
  border-color: #e3f9e9;
}

[data-theme-version='dark'] .bgl-success {
  background-color: rgba(43, 193, 85, 0.1);
  border-color: rgba(43, 193, 85, 0.1);
}

.bgl-warning {
  background: #fff5dd;
  border-color: #fff5dd;
}

[data-theme-version='dark'] .bgl-warning {
  background-color: rgba(255, 188, 17, 0.1);
  border-color: rgba(255, 188, 17, 0.1);
}

.bgl-danger {
  background: #fff3f7 !important;
  border-color: #fff3f7 !important;
}

[data-theme-version='dark'] .bgl-danger {
  background-color: rgba(249, 70, 135, 0.15);
  border-color: rgba(249, 70, 135, 0.15);
}

.bgl-info {
  background: #a4e3f1;
  border-color: #a4e3f1;
}

[data-theme-version='dark'] .bgl-info {
  background-color: rgba(30, 167, 197, 0.1);
  border-color: rgba(30, 167, 197, 0.1);
}

.bg-primary-light {
  background: rgba(216, 224, 252, 0.5);
}

[data-theme-version='dark'] .bg-primary-light {
  background-color: rgba(11, 42, 151, 0.05);
}

.bg-secondary-light {
  background: rgba(241, 223, 254, 0.5);
}

[data-theme-version='dark'] .bg-secondary-light {
  background-color: rgba(160, 44, 250, 0.05);
}

.bg-success-light {
  background: rgba(227, 249, 233, 0.5);
}

[data-theme-version='dark'] .bg-success-light {
  background-color: rgba(43, 193, 85, 0.05);
}

.bg-warning-light {
  background: rgba(255, 245, 221, 0.5);
}

[data-theme-version='dark'] .bg-warning-light {
  background-color: rgba(255, 188, 17, 0.05);
}

.bg-danger-light {
  background: rgba(255, 243, 247, 0.5);
}

[data-theme-version='dark'] .bg-danger-light {
  background-color: rgba(249, 70, 135, 0.05);
}

.bg-info-light {
  background: rgba(164, 227, 241, 0.5);
}

[data-theme-version='dark'] .bg-info-light {
  background-color: rgba(30, 167, 197, 0.05);
}

.bgl-dark {
  background: #f1f1f1;
  border-color: #f1f1f1;
}

.bgl-light {
  background: #f4f5f9;
  border-color: #f4f5f9;
}

.overlay-box {
  position: relative;
  z-index: 1;
}

.overlay-box:after {
  content: '';
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  position: absolute;
  opacity: 0.85;
  background: #0b2a97;
  z-index: -1;
}

.rating-bar {
  font-size: 13px;
}

.widget-message p {
  font-size: 14px;
  line-height: 1.5;
}

.card-list {
  overflow: unset;
  height: calc(100% - 50px);
}

.card-list .card-header {
  padding-top: 0;
  padding-bottom: 0;
}

.card-list .card-header .photo {
  overflow: hidden;
  border-radius: 5px;
  width: 100%;
}

@media only screen and (max-width: 1199px) {
  .card-list {
    height: calc(100% - 40px);
  }
}

.card-list.list-left {
  margin-top: 15px;
  margin-left: 15px;
}

.card-list.list-left .card-header {
  padding-left: 0;
}

.card-list.list-left .card-header .photo {
  margin: -15px 15px 0 -15px;
}

.card-list.list-right {
  margin-top: 15px;
  margin-right: 15px;
  text-align: right;
}

.card-list.list-right .card-header {
  padding-right: 0;
}

.card-list.list-right .card-header .photo {
  margin: -15px -15px 0 15px;
}

.card-list.list-right .card-header .photo img {
  width: 100%;
}

.card-list.list-center {
  margin-top: 15px;
}

.card-list.list-center .card-header .photo {
  margin: -15px 0 0 0;
}

.card-list .photo img {
  width: 100%;
}

.card-profile .profile-photo {
  margin-top: -20px;
}

.widget-media .timeline .timeline-panel {
  display: flex;
  align-items: flex-start;
  border-bottom: 1px solid #eaeaea;
  padding-bottom: 15px;
  margin-bottom: 15px;
}

.widget-media .timeline .timeline-panel .media {
  width: 50px;
  height: 50px;
  background: #eee;
  border-radius: 1.25rem;
  overflow: hidden;
  font-size: 20px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  align-self: start;
}

.widget-media .timeline .timeline-panel .media-primary {
  background: #d8e0fc;
  color: #0b2a97;
}

[data-theme-version='dark']
  .widget-media
  .timeline
  .timeline-panel
  .media-primary {
  background-color: rgba(11, 42, 151, 0.2);
}

.widget-media .timeline .timeline-panel .media-info {
  background: #a4e3f1;
  color: #1ea7c5;
}

[data-theme-version='dark']
  .widget-media
  .timeline
  .timeline-panel
  .media-info {
  background-color: rgba(30, 167, 197, 0.1);
}

.widget-media .timeline .timeline-panel .media-warning {
  background: #fff5dd;
  color: #ffbc11;
}

[data-theme-version='dark']
  .widget-media
  .timeline
  .timeline-panel
  .media-warning {
  background-color: rgba(255, 188, 17, 0.1);
}

.widget-media .timeline .timeline-panel .media-danger {
  background: #fff3f7;
  color: #f94687;
}

[data-theme-version='dark']
  .widget-media
  .timeline
  .timeline-panel
  .media-danger {
  background-color: rgba(249, 70, 135, 0.15);
}

.widget-media .timeline .timeline-panel .media-success {
  background: #e3f9e9;
  color: #2bc155;
}

[data-theme-version='dark']
  .widget-media
  .timeline
  .timeline-panel
  .media-success {
  background-color: rgba(43, 193, 85, 0.1);
}

.widget-media .timeline .timeline-panel .media-body p {
  font-size: 14px;
  line-height: 1.5;
}

.widget-media .timeline .timeline-panel .dropdown {
  align-self: self-end;
  margin-top: 5px;
}

.widget-media .timeline li:last-child .timeline-panel {
  margin-bottom: 0;
  border-bottom: 0;
  padding-bottom: 0;
}

.card[class*='bg-'] .timeline .timeline-panel {
  border-color: rgba(255, 255, 255, 0.2) !important;
}

@media only screen and (max-width: 1199px) {
  .table {
    font-size: 0.875rem !important;
  }
}

.table strong {
  color: #b1b1b1;
}

.table td {
  border-color: #f0f1f5;
  padding: 12px 20px;
}

[data-theme-version='dark'] .table td {
  border-color: #333a54;
}

.table.shadow-hover tbody tr:hover {
  background-color: #fff;
  box-shadow: 0 0px 30px rgba(11, 42, 151, 0.15);
}

[data-theme-version='dark'] .table.shadow-hover tbody tr:hover {
  background-color: #181f39;
}

.table.bg-secondary-hover th,
.table.bg-secondary-hover td {
  border: none;
  font-weight: 500;
}

.table.bg-secondary-hover tr:hover {
  background: #a02cfa;
}

.table.bg-secondary-hover tr:hover td,
.table.bg-secondary-hover tr:hover th {
  color: #fff !important;
}

.table.bg-info-hover th,
.table.bg-info-hover td {
  border: none;
  font-weight: 500;
}

.table.bg-info-hover tr:hover {
  background: #1ea7c5;
}

.table.bg-info-hover tr:hover td,
.table.bg-info-hover tr:hover th {
  color: #fff !important;
}

.table.bg-warning-hover th,
.table.bg-warning-hover td {
  border: none;
  font-weight: 500;
}

.table.bg-warning-hover tr:hover {
  background: #ffbc11;
}

.table.bg-warning-hover tr:hover td,
.table.bg-warning-hover tr:hover th {
  color: #fff !important;
}

.table thead th {
  border-bottom-width: 1px;
  font-size: 14px;
  font-weight: 600;
  /* padding: 20px 20px; */
  letter-spacing: 0.5px;
  border-color: #f0f1f5;
}

[data-theme-version='dark'] .table thead th {
  border-color: #333a54;
}

.table tbody tr td {
  vertical-align: middle;
  border-color: #f0f1f5;
  cursor: pointer;
}

[data-theme-version='dark'] .table tbody tr td {
  border-color: #333a54;
}

.table:not(.table-bordered) thead th {
  border-top: none;
}

.table .thead-primary th {
  background-color: #0b2a97;
  color: #fff;
}

.table .thead-info th {
  background-color: #1ea7c5;
  color: #fff;
}

.table .thead-warning th {
  background-color: #ffbc11;
  color: #fff;
}

.table .thead-danger th {
  background-color: #f94687;
  color: #fff;
}

.table .thead-success th {
  background-color: #2bc155;
  color: #fff;
}

.table.primary-table-bordered {
  border: 1px solid #f0f1f5;
}

[data-theme-version='dark'] .table.primary-table-bordered {
  border-color: #333a54;
}

.table.primary-table-bg-hover thead th {
  background-color: #09237f;
  color: #fff;
  border-bottom: none;
}

.table.primary-table-bg-hover tbody tr {
  background-color: #0b2a97;
  color: #fff;
  transition: all 0.1s ease;
}

.table.primary-table-bg-hover tbody tr:hover {
  background-color: #0d31af;
}

.table.primary-table-bg-hover tbody tr:not(:last-child) td,
.table.primary-table-bg-hover tbody tr:not(:last-child) th {
  border-bottom: 1px solid #09237f;
}

.table-responsive-tiny {
  min-width: 18.75rem;
}

.table-responsive-sm {
  min-width: 30rem !important;
}

.table-responsive-md {
  min-width: 36rem !important;
}

.table-responsive-lg {
  min-width: 60.9375rem !important;
}

.table-responsive-xl {
  min-width: 70.9375rem !important;
}

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #d8e0fc;
  color: #0b2a97;
}

[data-theme-version='dark'] .table-primary,
[data-theme-version='dark'] .table-primary > th,
[data-theme-version='dark'] .table-primary > td {
  background-color: rgba(11, 42, 151, 0.2);
}

.table-success,
.table-success > th,
.table-success > td {
  background-color: #e3f9e9;
  color: #2bc155;
}

[data-theme-version='dark'] .table-success,
[data-theme-version='dark'] .table-success > th,
[data-theme-version='dark'] .table-success > td {
  background-color: rgba(43, 193, 85, 0.1);
}

.table-info,
.table-info > th,
.table-info > td {
  background-color: #a4e3f1;
  color: #1ea7c5;
}

[data-theme-version='dark'] .table-info,
[data-theme-version='dark'] .table-info > th,
[data-theme-version='dark'] .table-info > td {
  background-color: rgba(30, 167, 197, 0.1);
}

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #fff5dd;
  color: #ffbc11;
}

[data-theme-version='dark'] .table-warning,
[data-theme-version='dark'] .table-warning > th,
[data-theme-version='dark'] .table-warning > td {
  background-color: rgba(255, 188, 17, 0.1);
}

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #fff3f7;
  color: #f94687;
}

[data-theme-version='dark'] .table-danger,
[data-theme-version='dark'] .table-danger > th,
[data-theme-version='dark'] .table-danger > td {
  background-color: rgba(249, 70, 135, 0.15);
}

.table-active,
.table-active > th,
.table-active > td {
  background-color: #f2f4fa;
}

[data-theme-version='dark'] .table-active,
[data-theme-version='dark'] .table-active > th,
[data-theme-version='dark'] .table-active > td {
  background-color: #181f39;
}

.card-table th:first-child,
.card-table td:first-child {
  padding-left: 30px;
}

@media only screen and (max-width: 575px) {
  .card-table th:first-child,
  .card-table td:first-child {
    padding-left: 15px;
  }
}

.card-table th:last-child,
.card-table td:last-child {
  padding-right: 30px;
}

@media only screen and (max-width: 575px) {
  .card-table th:last-child,
  .card-table td:last-child {
    padding-right: 15px;
  }
}

.bootgrid-header {
  padding: 0 !important;
  margin: 0;
}

@media only screen and (max-width: 575px) {
  .bootgrid-header .actionBar {
    padding: 0;
  }
  .bootgrid-header .search {
    margin: 0 0 10px 0;
  }
}

table#example {
  padding: 0rem 0 2rem 0;
}

table.dataTable {
  color: #000;
  border-radius: 1.25rem;
}

#example2_wrapper .dataTables_scrollBody {
  max-height: 33.25rem !important;
}

#employees,
#custommers {
  padding: 0.5rem 0 1rem 0;
}

.dataTables_wrapper .dataTables_paginate {
  padding: 25px 0;
}

@media only screen and (max-width: 1400px) {
  .dataTables_wrapper .dataTables_paginate {
    padding: 10px 0;
  }
}

table.dataTable thead th,
table.dataTable thead td {
  padding: 20px 15px;
  border-bottom: 1px solid #f0f1f5;
}

@media only screen and (max-width: 1400px) {
  table.dataTable thead th,
  table.dataTable thead td {
    padding: 8px 15px;
  }
}

table.dataTable tfoot th,
table.dataTable tfoot td {
  border-top: 0;
}

table.dataTable tbody td {
  background: #fff !important;
}

table.dataTable thead th {
  color: #000;
  font-size: 16px;
  white-space: nowrap;
  font-weight: 600;
}

[data-theme-version='dark'] table.dataTable thead th {
  color: #fff;
}

@media only screen and (max-width: 1400px) {
  table.dataTable thead th {
    font-size: 14px;
  }
}

table.dataTable tbody td {
  font-size: 16px;
  padding: 15px 10px;
}

@media only screen and (max-width: 1400px) {
  table.dataTable tbody td {
    padding: 8px 10px;
    font-size: 14px;
  }
}

table.dataTable tr.selected {
  color: #0b2a97;
}

table.dataTable tfoot th {
  color: #b1b1b1;
  font-weight: 600;
}

[data-theme-version='dark'] table.dataTable tfoot th {
  color: #fff;
}

.dataTables_wrapper input[type='search'],
.dataTables_wrapper input[type='text'],
.dataTables_wrapper select {
  border: 1px solid #e2e2e2;
  padding: 0.3rem 0.5rem;
  color: #715d5d;
  border-radius: 5px;
}

[data-theme-version='dark'] .dataTables_wrapper input[type='search'],
[data-theme-version='dark'] .dataTables_wrapper input[type='text'],
[data-theme-version='dark'] .dataTables_wrapper select {
  background: #181f39;
  border-color: #333a54;
  color: #fff;
}

.dataTables_wrapper .dataTables_length {
  margin-bottom: 15px;
}

.dataTables_wrapper .dataTables_length .bootstrap-select {
  width: 80px !important;
  margin: 0 5px;
}

table.dataTable.no-footer {
  border-bottom: 0;
}

.min-w850 {
  min-width: 850px;
}

.rounded-lg {
  min-width: 30px;
}

.dataTables_scroll {
  padding: 1rem 0;
}

.dataTables_scrollFoot {
  padding-top: 1rem;
}

@media (max-width: 991.98px) {
  .dataTables_wrapper .dataTables_length,
  .dataTables_wrapper .dataTables_filter {
    text-align: left;
  }
}

.dataTablesCard {
  background-color: #fff;
  box-shadow: 0px 12px 23px 0px rgba(62, 73, 84, 0.04);
  border-radius: 0;
}

.dataTablesCard tbody tr:hover {
  background: #fff !important;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.dataTablesCard tbody tr:hover td {
  background: transparent !important;
}

table.dataTable.display tbody tr:hover {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

table.dataTable.display tbody tr:hover td {
  background: transparent !important;
}

.dataTables_wrapper .dataTables_info {
  padding: 25px 0;
}

@media only screen and (max-width: 1400px) {
  .dataTables_wrapper .dataTables_info {
    padding: 8px 0;
  }
}

table.dataTable.display.white-border td {
  border-color: #fff;
}

table.dataTable.row-border tbody th,
table.dataTable.row-border tbody td,
table.dataTable.display tbody th,
table.dataTable.display tbody td {
  border-color: #eeeeee;
}

[data-theme-version='dark'] table.dataTable.row-border tbody th,
[data-theme-version='dark'] table.dataTable.row-border tbody td,
[data-theme-version='dark'] table.dataTable.display tbody th,
[data-theme-version='dark'] table.dataTable.display tbody td {
  border-color: #333a54;
}

table.dataTable thead .sorting {
  background-position: center right 15px;
}

.dataTables_wrapper .dataTables_paginate {
  border-radius: 1.25rem;
}

.dataTables_wrapper .dataTables_paginate span {
  border-radius: 1.25rem;
  display: inline-block;
  background: rgba(11, 42, 151, 0.08);
  border: 0px solid #0b2a97;
  margin: 0 0;
}

.dataTables_wrapper .dataTables_paginate span a {
  color: #fff;
  background: transparent !important;
}

.dataTables_wrapper .dataTables_paginate .paginate_button {
  border: 0 !important;
  padding: 15px 24px;
  background: transparent !important;
  border-radius: 1.25rem;
  color: #0b2a97 !important;
  font-size: 16px;
  margin: 0;
  display: inline-block;
}

@media only screen and (max-width: 1400px) {
  .dataTables_wrapper .dataTables_paginate .paginate_button {
    padding: 8px 15px;
    font-size: 14px;
  }
}

.dataTables_wrapper .dataTables_paginate .paginate_button:hover,
.dataTables_wrapper .dataTables_paginate .paginate_button.current:hover,
.dataTables_wrapper .dataTables_paginate .paginate_button.current {
  color: #fff !important;
  background: #0b2a97 !important;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.previous,
.dataTables_wrapper .dataTables_paginate .paginate_button.next {
  background: #fff !important;
  color: #0b2a97 !important;
  margin: 0 10px;
  border: 0px solid #0b2a97 !important;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.previous:hover,
.dataTables_wrapper .dataTables_paginate .paginate_button.next:hover {
  background: #0b2a97 !important;
  color: #fff !important;
}

.fooicon {
  font-size: 1.25rem;
  color: #b1b1b1;
}

.jsgrid-table .jsgrid-header-row > .jsgrid-header-cell {
  color: #b1b1b1;
}

.jsgrid-table > tbody > tr > td {
  padding: 1.2em;
}

.jsgrid-table .jsgrid-insert-row input,
.jsgrid-table .jsgrid-insert-row select,
.jsgrid-table .jsgrid-edit-row input,
.jsgrid-table .jsgrid-edit-row select {
  border: 1px solid #dddfe1;
}

.jsgrid .jsgrid-button {
  border: 0 !important;
  margin-left: 10px;
}

.error-page .error-text {
  font-size: 150px;
  line-height: 1;
  color: #a02cfa;
}

@media only screen and (max-width: 575px) {
  .error-page .error-text {
    font-size: 80px;
  }
}

.error-page h4 {
  font-size: 40px;
  margin-bottom: 5px;
  color: #a02cfa;
}

@media only screen and (max-width: 575px) {
  .error-page h4 {
    font-size: 20px;
  }
}

.error-page p {
  font-size: 16px;
}

@media only screen and (max-width: 575px) {
  .error-page p {
    font-size: 14px;
  }
}

.authincation-content {
  background: #0b2a97;
  box-shadow: 0 0 35px 0 rgba(154, 161, 171, 0.15);
  border-radius: 5px;
}

[data-theme-version='dark'] .authincation-content {
  background: #1e2746;
  box-shadow: none;
}

.welcome-content {
  background-size: cover;
  background-position: center;
  height: 100%;
  padding: 75px 50px;
  position: relative;
  z-index: 1;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.welcome-content::after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: #0b2a97;
  opacity: 0.75;
  z-index: -1;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.welcome-content .welcome-title {
  color: #fff;
  font-weight: 500;
  font-size: 20px;
}

.welcome-content p {
  color: #fff;
}

.welcome-content .brand-logo a {
  display: inline-block;
  margin-bottom: 20px;
  font-weight: 700;
  color: #fff;
  font-size: 20px;
}

.welcome-content .brand-logo a img {
  width: 100px;
}

.welcome-content .intro-social {
  position: absolute;
  bottom: 75px;
}

.welcome-content .intro-social ul {
  margin-bottom: 0;
}

.welcome-content .intro-social ul li {
  display: inline-block;
}

.welcome-content .intro-social ul li a {
  color: #fff;
  font-size: 14px;
  padding: 0px 7px;
}

.auth-form {
  padding: 50px 50px;
}

@media only screen and (max-width: 575px) {
  .auth-form {
    padding: 30px;
  }
}

.auth-form .btn {
  font-weight: 700;
}

.auth-form .page-back {
  display: inline-block;
  margin-bottom: 15px;
}

.page-timeline .timeline {
  list-style: none;
  padding: 40px 0;
  position: relative;
}

.page-timeline .timeline-workplan.page-timeline .timeline {
  padding-bottom: 0;
  margin-bottom: 0;
}

.page-timeline
  .timeline-workplan.page-timeline
  .timeline.timeline
  > li
  > .timeline-badge {
  top: 4.5rem;
}

.page-timeline .timeline-workplan.page-timeline .timeline > li {
  margin-bottom: 2.6rem;
}

@media only screen and (min-width: 1200px) and (max-width: 1650px) {
  .page-timeline .timeline-workplan.page-timeline .timeline li > p {
    max-width: 8rem;
  }
}

@media only screen and (max-width: 1199px) {
  .page-timeline .timeline-workplan.page-timeline .timeline li > p {
    max-width: 7rem;
  }
}

@media only screen and (max-width: 991px) {
  .page-timeline .timeline-workplan.page-timeline .timeline li > p {
    max-width: 100%;
  }
}

@media only screen and (max-width: 575px) {
  .page-timeline .timeline-workplan.page-timeline .timeline li > p {
    max-width: 7rem;
  }
}

.page-timeline .timeline-workplan.page-timeline .timeline::before {
  left: 20%;
  top: 6rem;
}

[direction='rtl']
  .page-timeline
  .timeline-workplan.page-timeline
  .timeline::before {
  right: 22%;
  left: auto;
}

@media only screen and (max-width: 575px) {
  .page-timeline .timeline-workplan.page-timeline .timeline::before {
    left: 22%;
  }
}

.page-timeline .timeline-workplan.page-timeline .timeline .timeline-badge {
  left: 21.4%;
  height: 0.9rem;
  width: 0.9rem;
  background-color: #0b2a97;
}

[direction='rtl']
  .page-timeline
  .timeline-workplan.page-timeline
  .timeline
  .timeline-badge {
  right: 19.2%;
  left: auto;
}

@media only screen and (min-width: 1200px) and (max-width: 1650px) {
  .page-timeline .timeline-workplan.page-timeline .timeline .timeline-badge {
    left: 22.4%;
  }
}

@media only screen and (max-width: 1199px) {
  .page-timeline .timeline-workplan.page-timeline .timeline .timeline-badge {
    left: 22.4%;
  }
}

@media only screen and (max-width: 991px) {
  .page-timeline .timeline-workplan.page-timeline .timeline .timeline-badge {
    left: 21.7%;
  }
}

@media only screen and (max-width: 767px) {
  .page-timeline .timeline-workplan.page-timeline .timeline .timeline-badge {
    left: 19.5%;
  }
}

@media only screen and (max-width: 575px) {
  .page-timeline .timeline-workplan.page-timeline .timeline .timeline-badge {
    left: 21.4%;
  }
}

.page-timeline
  .timeline-workplan.page-timeline
  .timeline
  .timeline-badge::after {
  position: absolute;
  width: 1.9rem;
  height: 1.9rem;
  background-color: rgba(11, 42, 151, 0.4);
  content: '';
  border-radius: 50%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.page-timeline .timeline-workplan.page-timeline .timeline .timeline-panel {
  width: 70%;
}

.page-timeline .timeline:before {
  top: 0;
  bottom: 0;
  position: absolute;
  content: ' ';
  width: 2px;
  background-color: #eceff2;
  left: 50%;
  margin-left: -1.5px;
}

.page-timeline .timeline > li {
  margin-bottom: 20px;
  position: relative;
}

.page-timeline .timeline > li:after,
.page-timeline .timeline > li:before {
  content: ' ';
  display: table;
}

.page-timeline .timeline > li:after {
  clear: both;
}

.page-timeline .timeline > li:after,
.page-timeline .timeline > li:before {
  content: ' ';
  display: table;
}

.page-timeline .timeline > li:after {
  clear: both;
}

.page-timeline .timeline > li > .timeline-panel {
  width: 46%;
  float: left;
  border-radius: 2px;
  position: relative;
}

.page-timeline .timeline > li > .timeline-badge {
  background-color: #f2f4fa;
  border: 1px solid #dddfe1;
  border-radius: 50%;
  color: #b1b1b1;
  height: 40px;
  left: 50%;
  line-height: 40px;
  margin-left: -13px;
  position: absolute;
  text-align: center;
  top: 30px;
  width: 40px;
  z-index: 1;
  transform: translate(-25%, -3rem);
}

@media (min-width: 576px) {
  .page-timeline .timeline > li > .timeline-badge {
    width: 50px;
    height: 50px;
    line-height: 50px;
  }
}

.page-timeline .timeline > li.timeline-inverted > .timeline-panel {
  float: right;
}

.page-timeline .timeline > li.timeline-inverted > .timeline-panel:before {
  border-left-width: 0;
  border-right-width: 15px;
  left: -15px;
  right: auto;
}

.page-timeline .timeline > li.timeline-inverted > .timeline-panel:after {
  border-left-width: 0;
  border-right-width: 14px;
  left: -14px;
  right: auto;
}

.page-timeline .timeline-title {
  margin-top: 0;
  color: inherit;
}

.page-timeline .event_time {
  font-size: 14px;
  font-weight: 600;
}

.page-timeline .event_vanue {
  font-size: 14px;
  margin: 5px 0;
  font-weight: 600;
}

.page-timeline .timeline_img {
  height: 100px;
  width: 100px;
}

.page-timeline .timeline-body > p,
.page-timeline .timeline-body > ul {
  margin-bottom: 0;
}

@media (max-width: 767px) {
  .page-timeline ul.timeline:before {
    left: 40px;
  }
  .page-timeline ul.timeline > li > .timeline-panel {
    width: calc(100% - 90px);
    width: -webkit-calc(100% - 90px);
  }
  .page-timeline ul.timeline > li > .timeline-badge {
    left: 28px;
    margin-left: 0;
    top: 16px;
  }
  .page-timeline ul.timeline > li > .timeline-panel {
    float: right;
  }
  .page-timeline ul.timeline > li > .timeline-panel:before {
    border-left-width: 0;
    border-right-width: 15px;
    left: -15px;
    right: auto;
  }
  .page-timeline ul.timeline > li > .timeline-panel:after {
    border-left-width: 0;
    border-right-width: 14px;
    left: -14px;
    right: auto;
  }
  .page-timeline .timeline_img {
    height: 30%;
    width: 30%;
  }
}

.page-timeline .timeline-timestamp {
  text-align: center;
}

.page-timeline .timeline-timestamp .badge {
  padding: 0.8rem 2rem;
  border-radius: 50px;
  font-size: 0.8125rem;
}

.full-map-area {
  position: relative;
  border-radius: 12px;
  overflow: hidden;
}

.full-map-area a {
  position: absolute;
  bottom: 30px;
  left: 30px;
}

.full-map-area i {
  background: #3e4954;
  position: absolute;
  top: 30px;
  right: 30px;
  width: 56px;
  height: 56px;
  text-align: center;
  line-height: 56px;
  font-size: 24px;
  color: #fff;
  border-radius: 56px;
}

.full-map-area img {
  width: 100%;
}

.iconbox {
  position: relative;
  padding-left: 70px;
}

.iconbox i {
  background: #0b2a97;
  width: 50px;
  height: 50px;
  line-height: 50px;
  border-radius: 50px;
  text-align: center;
  font-size: 28px;
  color: #fff;
  position: absolute;
  left: 0;
  top: 0;
}

.iconbox p {
  margin: 0;
  color: #484848;
  font-size: 18px;
  line-height: 1.3;
  font-weight: 500;
}

.iconbox small {
  margin-bottom: 5px;
  font-size: 16px;
  display: block;
}

.widget-timeline-icon li {
  padding-left: 25px;
  position: relative;
  margin-left: 11px;
  border-left: 3px solid #0b2a97;
  min-height: 80px;
  padding-top: 1px;
}

.widget-timeline-icon li .icon {
  position: absolute;
  width: 22px;
  height: 22px;
  font-size: 24px;
  color: #fff;
  text-align: center;
  line-height: 22px;
  border-radius: 22px;
  left: -13px;
  top: 0;
}

.widget-timeline-icon li:last-child {
  border-left: 3px solid transparent;
}

.plan-list .list-row {
  border-radius: 1.25rem;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}

.plan-list .list-row .list-icon {
  height: 76px;
  min-width: 76px;
  width: 76px;
  font-weight: 600;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  line-height: 20px;
  text-align: center;
  border-radius: 1.25rem;
}

@media only screen and (max-width: 767px) {
  .plan-list .list-row .list-icon {
    height: 60px;
    width: 60px;
    min-width: 60px;
    line-height: 18px;
  }
  .plan-list .list-row .list-icon p {
    font-size: 18px !important;
  }
  .plan-list .list-row .list-icon span {
    font-size: 12px !important;
  }
}

.plan-list .list-row .play-button {
  color: #0b2a97;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  background: #f4f5f9;
  margin-left: auto;
}

@media only screen and (max-width: 1600px) {
  .plan-list .list-row .play-button {
    margin-left: 0;
    margin-right: auto !important;
  }
}

@media only screen and (max-width: 1199px) {
  .plan-list .list-row .play-button {
    margin-left: auto;
    margin-right: 1rem !important;
  }
}

@media only screen and (max-width: 991px) {
  .plan-list .list-row .play-button {
    margin-left: 0;
    margin-right: auto !important;
  }
}

.plan-list .list-row .more-dropdown {
  margin-left: auto;
}

@media only screen and (max-width: 1199px) {
  .plan-list .list-row .more-dropdown {
    margin-left: auto;
  }
}

@media only screen and (max-width: 575px) {
  .plan-list .list-row .more-dropdown {
    margin-left: auto;
  }
}

.plan-list .list-row .more-button {
  height: 56px;
  width: 56px;
  display: block;
  border-radius: 1.25rem;
  line-height: 56px;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  text-align: center;
  background: #f4f5f9;
}

@media only screen and (max-width: 1400px) {
  .plan-list .list-row .more-button {
    height: 40px;
    width: 40px;
    line-height: 40px;
  }
  .plan-list .list-row .more-button svg {
    width: 4px;
  }
}

.plan-list .list-row .activities svg path,
.plan-list .list-row .activities span {
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}

.plan-list .list-row .info h4 a,
.plan-list .list-row .info span {
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}

@media only screen and (max-width: 767px) {
  .plan-list .list-row .info h4 {
    font-size: 16px !important;
  }
  .plan-list .list-row .info span {
    font-size: 13px !important;
  }
}

.plan-list .list-row:hover {
  background: #0b2a97;
}

.plan-list .list-row:hover .info h4 a,
.plan-list .list-row:hover .info span {
  color: #fff !important;
}

.plan-list .list-row:hover .play-button {
  color: #fff;
  background: #ffbc11;
}

.plan-list .list-row:hover .more-button {
  background: rgba(255, 255, 255, 0.3);
}

.plan-list .list-row:hover .more-button svg path {
  fill: #fff;
}

.plan-list .list-row:hover .activities svg path {
  fill: #fff;
}

.plan-list .list-row:hover .activities span {
  color: #fff !important;
}

.event-calender .bootstrap-datetimepicker-widget table th.picker-switch {
  width: 145px;
  font-size: 20px;
  font-weight: 500;
  color: #000;
}

.event-calender .bootstrap-datetimepicker-widget table th {
  height: 20px;
  line-height: 20px;
  width: 20px;
  font-size: 14px;
  font-weight: 400;
}

.event-calender .bootstrap-datetimepicker-widget table td.day {
  height: 46px;
  line-height: 30px;
  width: 46px;
  color: #000;
  font-weight: 500;
}

.event-calender .bootstrap-datetimepicker-widget .picker-switch td i {
  line-height: 2.5;
  height: 2.5em;
  width: 100%;
  display: none;
}

.event-calender .datepicker table tr td.today {
  background: #eeeeee !important;
  box-shadow: none;
}

.event-calender .datepicker table tr td.active.active {
  background: #eeeeee !important;
  box-shadow: none;
}

.event-calender .bootstrap-datetimepicker-widget table td.day:hover {
  box-shadow: none;
  border: 0;
  background: #ffbc11 !important;
  color: #fff !important;
}

.event-calender .bootstrap-datetimepicker-widget table td.today:before {
  content: '';
  display: inline-block;
  position: absolute;
  top: -5px;
  border: 3px solid #fff;
  right: -5px;
  height: 17px;
  width: 17px;
  background: #0b2a97;
  border-radius: 12px;
}

.event-calender .bootstrap-datetimepicker-widget table td,
.event-calender .bootstrap-datetimepicker-widget table th {
  border-radius: 10px;
}

.event-calender .bootstrap-datetimepicker-widget table td.new {
  color: #dee2e6 !important;
}

.event-calender .bootstrap-datetimepicker-widget table thead tr:first-child th {
  cursor: pointer;
  padding: 30px 10px;
}

.event-calender .bootstrap-datetimepicker-widget table td span.active {
  color: #0b2a97;
}

.event-calender .bootstrap-datetimepicker-widget .datepicker-decades .decade {
  width: 50%;
  float: left;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.date-icon {
  height: 60px;
  width: 60px;
  font-size: 24px;
  line-height: 60px;
  text-align: center;
  color: #0b2a97;
  background: rgba(11, 42, 151, 0.2);
  border-radius: 1.25rem;
}

.review-table {
  padding: 25px;
  box-shadow: none;
  border-radius: 0;
  border-bottom: 1px solid #eee;
  height: auto;
  margin-bottom: 0;
}

.review-table .star-review i {
  font-size: 20px;
}

.review-table .media-body p {
  color: #3e4954;
  font-size: 18px;
  line-height: 1.5;
}

.review-table .disease {
  border-left: 1px solid #eeeeee;
  padding-left: 20px;
}

.review-table .media-footer {
  min-width: 500px;
  margin-left: 10px;
}

@media only screen and (max-width: 1400px) {
  .review-table .media-footer {
    min-width: 273px;
    margin-left: 10px;
  }
}

@media only screen and (max-width: 991px) {
  .review-table .star-review i {
    font-size: 16px;
  }
  .review-table .custom-control {
    float: right;
    right: -20px;
  }
  .review-table .media {
    display: block !important;
  }
  .review-table .media-body p {
    font-size: 14px;
  }
  .review-table img {
    float: left;
    width: 80px;
  }
  .review-table .media-footer {
    margin-left: 0;
    margin-top: 25px;
  }
  .review-table .disease {
    border-left: 0;
    padding-left: 0;
    margin-right: 10px !important;
    margin-left: 0;
  }
}

@media only screen and (max-width: 575px) {
  .review-table .media-footer {
    flex-flow: wrap;
  }
  .review-table .media-footer .disease {
    margin-right: auto !important;
  }
  .review-table .media-footer .edit {
    margin-left: 0 !important;
  }
}

.review-tab .nav-link {
  border-radius: 0;
  font-weight: 600;
  border-bottom: 2px solid #d9d9d9;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}

.review-tab .nav-link.active {
  background: transparent;
  color: #0b2a97;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  box-shadow: none;
  border-color: #0b2a97;
}

@media only screen and (max-width: 575px) {
  .review-tab .nav-link {
    padding: 10px 16px;
    font-size: 13px;
  }
}

.man-chart {
  position: relative;
}

.man-chart svg {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.radialBar p {
  position: relative;
  z-index: 1;
}

@media only screen and (max-width: 1199px) {
  .radialBar #radialBar {
    margin-bottom: -40px;
  }
}

.mapouter iframe {
  width: 100%;
}

.activity-icon {
  height: 50px;
  width: 50px;
  min-width: 50px;
  line-height: 50px;
  text-align: center;
  border-radius: 100%;
}

.diet-search {
  width: 360px;
}

.diet-search .input-group-append {
  margin-left: -20px;
}

.diet-search .input-group-append button {
  border-top-left-radius: 1.25rem !important;
  border-bottom-left-radius: 1.25rem !important;
  font-size: 25px;
  padding: 0 20px;
  position: relative;
  z-index: 3;
}

@media only screen and (max-width: 1400px) {
  .diet-search {
    width: 300px;
  }
  .diet-search .input-group-append button {
    font-size: 18px;
  }
}

@media only screen and (max-width: 575px) {
  .diet-search {
    width: 100%;
  }
}

@media only screen and (max-width: 991px) {
  .menu-list > a > img {
    float: left;
  }
}

@media only screen and (max-width: 575px) {
  .menu-list > a > img {
    width: 80px;
  }
}

.plus-icon {
  height: 60px;
  width: 60px;
  min-width: 60px;
  display: inline-block;
  line-height: 60px;
  text-align: center;
}

.items-table tbody tr {
  padding: 0;
  border-radius: 1.25rem;
  position: relative;
  box-shadow: 0 1px 0 0 #f8f8f8;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}

.items-table tbody tr .dropdown {
  opacity: 0;
}

.items-table tbody tr.active,
.items-table tbody tr:hover {
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  z-index: 1;
}

.items-table tbody tr.active .dropdown,
.items-table tbody tr:hover .dropdown {
  opacity: 1;
}

.items-table tbody tr th,
.items-table tbody tr td {
  border: 0;
  padding: 18px 8px;
}

@media only screen and (max-width: 575px) {
  .items-table {
    width: 500px;
  }
}

.star-review i {
  font-size: 16px;
}

.star-review i.text-gray {
  color: #d9d9d9;
}

.star-review span {
  color: #000;
}

.form-head {
  /* .btn i{
line-height: 1;
transform: scale(1.3);
display: inline-block;
margin-right: 5px;
} */
  /* .btn-outline-primary{
border-color:#eee;
&:hover{
  border-color:$primary;
}
} */
}

.form-head .breadcrumb {
  background: transparent;
  padding: 0;
}

.form-head .search-area {
  max-width: 300px;
  border-radius: 0;
}

@media only screen and (max-width: 1400px) {
  .form-head .search-area {
    max-width: 250px;
  }
}

@media only screen and (max-width: 575px) {
  .form-head .search-area {
    max-width: 250px;
  }
}

.form-head .search-area .form-control {
  border: 0;
  height: 56px;
  padding: 10px 15px;
  font-size: 16px;
  background: #f1f1f1;
  border-radius: 0;
}

@media only screen and (max-width: 1400px) {
  .form-head .search-area .form-control {
    height: 41px;
    padding: 10px 15px;
    font-size: 13px;
  }
}

.form-head .search-area .input-group-append .input-group-text {
  background: #f1f1f1;
  padding: 0 5px 0 30px;
}

@media only screen and (max-width: 1400px) {
  .form-head .search-area .input-group-append .input-group-text {
    padding: 0 5px 0 30px;
  }
}

.form-head .search-area .input-group-append .input-group-text i {
  font-size: 20px;
  color: #7e7e7e;
}

@media only screen and (max-width: 1400px) {
  .form-head .search-area .input-group-append .input-group-text i {
    font-size: 18px;
  }
}

.form-head .dropdown .btn i {
  transform: scale(1.3);
  -moz-transform: scale(1.3);
  -webkit-transform: scale(1.3);
  -ms-transform: scale(1.3);
  -o-transform: scale(1.3);
  display: inline-block;
}

.form-head .dropdown:after {
  color: #0b2a97;
  font-size: 25px;
}

@media only screen and (max-width: 767px) {
  .form-head > * {
    margin-right: 5px !important;
    margin-bottom: 10px;
    vertical-align: baseline;
    margin-left: 0 !important;
  }
}

@media only screen and (max-width: 767px) {
  .form-head {
    display: block !important;
  }
}

.rounded {
  border-radius: 1.25rem !important;
}

.btn-link {
  font-weight: 500;
  font-size: 16px;
}

@media only screen and (max-width: 1400px) {
  .btn-link {
    font-size: 14px;
  }
}

.deznav .copyright {
  font-size: 14px;
  padding: 0 45px 60px;
  margin-top: 40px;
  color: #969ba0;
  margin-bottom: 40px;
}

.deznav .copyright p {
  margin-bottom: 10px;
}

.deznav .copyright strong {
  display: block;
}

@media only screen and (max-width: 1400px) {
  .deznav .copyright {
    padding: 0 25px 60px;
  }
}

@media only screen and (max-width: 767px) {
  .deznav .copyright {
    padding-bottom: 60px;
  }
}

.widget-buyer .media img {
  width: 30px;
}

.widget-buyer .media .media-body h5 {
  font-size: 14px;
  margin-bottom: 0px;
}

.widget-buyer .media .media-body p {
  font-size: 13px;
}

.recentOrderTable table {
  margin-bottom: 0;
  overflow: hidden;
}

.recentOrderTable table thead th {
  font-size: 12px;
}

.recentOrderTable table tbody td {
  color: #b1b1b1;
  font-weight: 500;
  line-height: 40px;
}

[data-theme-version='dark'] .recentOrderTable table tbody td {
  color: #828690;
}

.recentOrderTable table tbody td img {
  border-radius: 50px;
}

.recentOrderTable table tbody td .custom-dropdown i {
  padding: 0 10px;
  cursor: pointer;
}

.activity_overview .nav-tabs {
  border-bottom: 1px solid rgba(255, 255, 255, 0.2) !important;
}

.activity_overview .custom-tab-1 .nav-link {
  color: rgba(255, 255, 255, 0.5);
  padding-left: 0;
  padding-right: 0;
  margin-right: 30px;
}

.activity_overview .custom-tab-1 .nav-link:hover,
.activity_overview .custom-tab-1 .nav-link.active {
  background: transparent;
  color: #fff;
  border-color: #fff;
}

.ps .ps__rail-x:hover,
.ps .ps__rail-y:hover,
.ps .ps__rail-x:focus,
.ps .ps__rail-y:focus,
.ps .ps__rail-x.ps--clicking,
.ps .ps__rail-y.ps--clicking {
  background-color: transparent !important;
  opacity: 0.9;
}

@media only screen and (max-width: 575px) {
  .chart-link {
    display: flex;
    align-items: center;
  }
}

@media only screen and (max-width: 1199px) {
  .chart-link {
    display: flex;
    align-items: center;
  }
}

@media only screen and (min-width: 1200px) {
  .chart-link {
    display: flex;
    align-items: center;
  }
}

.chart-link .week-link {
  display: inline-block;
}

.chart-link a {
  display: inline-block;
  font-size: 12px;
  font-weight: 500;
  margin-right: 10px;
}

.chart-link a i {
  font-size: 13px;
}

.chart-link a i.text-primary {
  color: #0b2a97;
}

.chart-link a i.text-muted {
  color: #dae2f3 !important;
}

.chart-link .form-control {
  max-width: 97px;
  display: inline-block;
}

.input-group.input-group-lg .form-control,
.input-group.input-group-lg .input-group-text {
  height: 80px;
  padding: 10px 30px;
}

@media only screen and (max-width: 575px) {
  .input-group.input-group-lg .form-control,
  .input-group.input-group-lg .input-group-text {
    height: 50px;
    padding: 10px 15px;
    font-size: 14px;
  }
}

.input-group.input-group-lg .input-group-text {
  width: 200px;
  text-align: left;
}

@media only screen and (max-width: 575px) {
  .input-group.input-group-lg .input-group-text {
    width: 120px;
    padding: 10px 15px;
    height: 50px;
    font-size: 14px;
  }
}

.card-tabs .nav-tabs {
  border-bottom: 0px;
  background: #e8e8e8;
  padding: 5px;
  border-radius: 1.25rem;
  flex-wrap: unset;
}

.card-tabs .nav-tabs .nav-link {
  border-radius: 1.25rem;
  padding: 12px 20px;
  font-weight: 500;
  font-size: 16px;
  border: 0px solid #eeeeee;
  color: #000;
}

.card-tabs .nav-tabs .nav-link.active {
  background: #fff;
  color: #0b2a97;
}

@media only screen and (max-width: 1400px) {
  .card-tabs .nav-tabs .nav-link {
    padding: 8px 15px;
    font-weight: 400;
    font-size: 14px;
  }
}

.card-tabs .nav-tabs.tabs-lg .nav-link {
  padding: 23px 30px;
  font-size: 16px;
}

@media only screen and (max-width: 767px) {
  .card-tabs .nav-tabs.tabs-lg .nav-link {
    padding: 10px 15px;
    font-size: 14px;
  }
}

.card-tabs.icontabs .nav-tabs {
  background: transparent;
}

.card-tabs.icontabs .nav-tabs .nav-item .nav-link {
  height: 42px;
  width: 42px;
  line-height: 42px;
  text-align: center;
  background: rgba(137, 137, 137, 0.6);
  color: #fff;
  margin-right: 15px;
  border-radius: 50px;
  padding: 0;
}

.card-tabs.icontabs .nav-tabs .nav-item .nav-link.active {
  background: #ffbc11;
}

.card-tabs.icontabs .nav-tabs .nav-item .nav-link i {
  font-size: 25px;
  line-height: 42px;
}

.card-tabs.style-1 .nav-tabs {
  background: transparent;
}

.card-tabs.style-1 .nav-tabs .nav-link {
  font-size: 16px;
  color: #000;
  position: relative;
}

.card-tabs.style-1 .nav-tabs .nav-link:after {
  content: none;
}

.card-tabs.style-1 .nav-tabs .nav-link span {
  position: absolute;
  bottom: -4px;
  left: 50%;
  transform: translatex(-50%);
  content: '';
  height: 4px;
  width: 0;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}

.card-tabs.style-1 .nav-tabs .nav-link.active span {
  width: 100%;
}

.revenue-chart
  .apexcharts-legend.apexcharts-align-left
  .apexcharts-legend-series {
  display: flex !important;
  margin-right: 30px !important;
  margin-bottom: 30px !important;
}

.revenue-chart-bar {
  margin-bottom: -30px;
}

@media only screen and (max-width: 575px) {
  .revenue-chart-bar {
    margin-bottom: 20px;
    float: none;
    padding-left: 20px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .revenue-chart-bar {
    margin-bottom: 20px;
    float: none;
    padding-left: 20px;
  }
}

#activeUser {
  height: 215px !important;
}

#activeUser {
  height: 180px !important;
}

span#counter {
  font-size: 30px;
  font-weight: 700;
  color: #0b2a97;
}

.social-graph-wrapper {
  text-align: center;
  padding: 20px;
  position: relative;
  color: #fff;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.social-graph-wrapper.widget-facebook {
  background-color: #3b5998;
}

.social-graph-wrapper.widget-twitter {
  background-color: #1da1f2;
}

.social-graph-wrapper.widget-linkedin {
  background-color: #007bb6;
}

.social-graph-wrapper.widget-googleplus {
  background-color: #db4439;
}

.social-graph-wrapper .s-icon {
  font-size: 24px;
  position: relative;
  padding: 0 10px;
}

.recent-stats i {
  font-size: 11px;
  position: relative;
  top: -1px;
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  #lineChart_3Kk {
    height: 140px !important;
  }
}

/* add menu sidebar */

.add-menu-sidebar {
  background: #6aacff;
  padding: 20px 18px;
  display: flex;
  align-items: center;
  border-radius: 1.25rem;
  position: relative;
  margin: 50px 50px;
}

.add-menu-sidebar h5 {
  font-size: 20px;
}

.add-menu-sidebar p {
  font-size: 16px;
  font-weight: 500;
  color: #fff;
}

@media only screen and (max-width: 1400px) {
  .add-menu-sidebar {
    margin: 50px 2% 50px;
  }
  .add-menu-sidebar h5 {
    font-size: 16px;
  }
  .add-menu-sidebar p {
    font-size: 14px;
  }
  .ttm-processbox-wrapper .step_btn_cus1 {
    width: 100%;
    min-width: 140px;
    font-size: 14px;
  }
}

@media only screen and (max-width: 575px) {
  .add-menu-sidebar {
    margin: 20px 25px 20px;
  }
}

@media only screen and (max-width: 768px) {
  .pb-sm-2 {
    padding-bottom: 5px;
  }
}
@media only screen and (min-width: 768px) {
  .middle2 {
    justify-content: center;
  }
}
.cus-text {
  text-align: center !important;
}
.middle {
  justify-content: center;
}

.donut-chart-sale {
  position: relative;
}

.donut-chart-sale small {
  font-size: 16px;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  display: flex;
  align-items: center;
  top: 0;
  justify-content: center;
  font-weight: 600;
}

.profile-bx .profile-image {
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

.profile-bx .profile-image img {
  margin: 18px;
  width: 118px;
}

.search-area {
  width: 300px;
}

.search-area .form-control {
  background: #efefef !important;
  border-width: 0px;
  padding-right: 20px;
  min-height: 55px;
  border-radius: 0;
  border-top-right-radius: 1.25rem;
  border-bottom-right-radius: 1.25rem;
}

.search-area .form-control::placeholder {
  color: #757575;
}

.search-area .input-group-text {
  background: #efefef;
  border-width: 0px;
  border-top-left-radius: 1.25rem !important;
  border-bottom-left-radius: 1.25rem !important;
  padding-right: 0;
  padding-left: 20px;
}

.search-area .input-group-text i {
  font-size: 25px;
}

@media only screen and (max-width: 1600px) {
  .search-area {
    width: 230px;
  }
}

@media only screen and (max-width: 1400px) {
  .search-area .form-control {
    min-height: 45px;
  }
  .search-area .input-group-text i {
    font-size: 18px;
  }
}

@media only screen and (max-width: 991px) {
  .search-area {
    width: 200px;
  }
}

.donut-chart-sale {
  z-index: 1;
}

.donut-chart-sale .circle {
  height: 50px;
  width: 50px;
  z-index: -1;
  content: '';
  position: absolute;
  border-radius: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.donut-chart-sale small {
  font-size: 14px;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  display: flex;
  align-items: center;
  top: 0;
  justify-content: center;
  font-weight: 600;
}

.min-w24 {
  min-width: 24px;
}

.min-w30 {
  min-width: 30px;
}

.min-w32 {
  min-width: 32px;
}

.min-w42 {
  min-width: 42px;
}

.min-w46 {
  min-width: 46px;
}

.min-w50 {
  min-width: 50px;
}

.form-group.style-1 .form-control {
  border-color: transparent;
  margin-right: -45px;
  z-index: 0;
  color: #000;
  background: #f0f0f0;
  border-radius: 1.25rem;
}

.previous-transactions tbody tr td:first-child {
  width: 100px;
}

.rating-widget .rating-stars ul li i {
  font-size: 25px !important;
}

.heart {
  width: 60px;
  height: 60px;
  display: inline-block;
  cursor: pointer;
  margin: -25px -15px;
}

.heart-blast {
  background-position: -1680px 0;
  transition: background 1s steps(28);
}

.avtivity-card {
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.avtivity-card .activity-icon {
  height: 80px;
  width: 80px;
  min-width: 80px;
  display: block;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  border-radius: 100%;
  line-height: 80px;
  text-align: center;
}

.avtivity-card .title {
  font-size: 28px;
}

.avtivity-card .progress {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}

.avtivity-card .effect {
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  border-radius: 50%;
  transition: width 0.4s ease-in-out, height 0.4s ease-in-out;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: -1;
}

.avtivity-card p,
.avtivity-card .media-body span {
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}

.avtivity-card:hover .activity-icon {
  background: #fff !important;
}

.avtivity-card:hover .effect {
  width: 225%;
  height: 562px;
}

.avtivity-card:hover p {
  color: #fff;
}

.avtivity-card:hover .media-body span {
  color: #fff !important;
}

.avtivity-card:hover .progress {
  background: rgba(255, 255, 255, 0.2) !important;
}

.avtivity-card:hover .progress .progress-bar {
  background: #fff !important;
}

@media only screen and (max-width: 767px) {
  .avtivity-card .title {
    font-size: 20px;
  }
  .avtivity-card .activity-icon {
    height: 65px;
    width: 65px;
    min-width: 65px;
    line-height: 65px;
  }
  .avtivity-card .activity-icon svg {
    width: 35px;
    height: 35px;
  }
}

.testimonial-one .card {
  box-shadow: none;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  margin-bottom: 0;
}

.testimonial-one .card img {
  height: 113px;
  width: 113px;
  margin-left: auto;
  margin-bottom: 15px;
  margin-right: auto;
  border-radius: 100%;
}

.testimonial-one .card:hover {
  box-shadow: 0px 12px 23px 0px rgba(0, 0, 0, 0.1);
}

.testimonial-one .owl-nav .owl-prev,
.testimonial-one .owl-nav .owl-next {
  height: 68px;
  width: 68px;
  cursor: pointer;
  line-height: 68px;
  border-radius: 100%;
  position: absolute;
  top: 40%;
  background: #fff;
  color: #0b2a97;
  text-align: center;
  font-size: 25px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.testimonial-one .owl-nav .owl-next {
  right: -30px;
}

.testimonial-one .owl-nav .owl-prev {
  left: -30px;
}

@media only screen and (max-width: 575px) {
  .testimonial-one .owl-nav .owl-next,
  .testimonial-one .owl-nav .owl-prev {
    height: 50px;
    width: 50px;
    line-height: 50px;
    font-size: 20px;
  }
  .testimonial-one .owl-nav .owl-next {
    right: -10px;
  }
  .testimonial-one .owl-nav .owl-prev {
    left: -10px;
  }
}

.card-footer.style-1 {
  position: relative;
}

.card-footer.style-1 a {
  position: absolute;
  height: 68px;
  width: 68px;
  line-height: 68px;
  font-size: 25px;
  left: 50%;
  border-radius: 100%;
  background: #fff;
  color: #0b2a97;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  transform: translateX(-50%);
  bottom: -10px;
}

/*
===================================
Product detail
===================================*/

.produtct-detail-tag {
  display: inline-block;
}

.produtct-detail-tag a {
  font-style: 13px;
  color: #b1b1b1;
}

.product-detail-content .item-tag {
  background: #828690;
  border-radius: 6px;
  display: inline-block;
  font-size: 12px;
  margin-right: 4px;
  padding: 2px 12px;
  color: #fff;
}

.filtaring-area h4 {
  color: #1d1d1d;
  font-size: 16px;
  font-weight: 400;
  text-transform: lowercase;
}

.plus-minus-input .input-icon {
  font-size: 13px;
  color: #b1b1b1;
}

.plus-minus-input {
  display: flex;
  width: 120px;
}

.plus-minus-input .custom-btn {
  border-radius: 0;
  height: 40px;
  padding: 12px 8px;
  background: #fff;
  border: 1px solid #f0f1f5;
}

.plus-minus-input .form-control:hover,
.plus-minus-input .form-control:focus,
.plus-minus-input .form-control:active {
  border: 1px solid #f0f1f5;
}

.btn-reveal-trigger .avatar-xl {
  min-width: 30px;
}

.share-view {
  display: inline-block;
}

.share-view ul li {
  display: inline-block;
}

.share-view .share-icon {
  width: 40px;
  height: 40px;
  display: inline-block;
  border: 1px solid #f0f1f5;
  text-align: center;
  line-height: 40px;
  font-style: 16px;
  color: #f0f1f5;
  margin-right: 8px;
}

/*--------tab----------*/

.veritical-line {
  padding: 20px 30px;
  border-top: 1px solid #f0f1f5;
  border-right: 1px solid #f0f1f5;
  border-bottom: 1px solid #f0f1f5;
  position: relative;
}

.veritical-line:before {
  background: #f0f1f5;
  bottom: 0;
  content: '';
  height: 100%;
  left: -1px;
  max-height: 40%;
  position: absolute;
  width: 1px;
}

.tab-content-text p {
  color: #b1b1b1;
  font-size: 13px;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 25px;
}

.tab-item-list li a {
  background: #fff;
  border-top: 1px solid #f0f1f5;
  border-left: 1px solid #f0f1f5;
  border-right: 1px solid #f0f1f5;
  color: #b1b1b1;
  display: block;
  font-size: 16px;
  padding: 16px;
  text-transform: uppercase;
}

.tab-item-list li a:hover,
.tab-item-list li a:focus {
  background: #fff;
  color: #b1b1b1;
  border-right: 0px;
}

.tab-item-list li:last-child {
  border-bottom: 1px solid #f0f1f5;
}

.tab-list li {
  margin-bottom: 7px;
  font-size: 13px;
}

.tab-list li i {
  font-size: 13px;
  margin-right: 14px;
}

.slide-item-list {
  text-align: center;
  margin: 0 -5px;
}

.slide-item-list li {
  display: inline-block;
  flex: 0 0 25%;
  width: 25%;
  padding: 0 5px;
}

.slide-item-list li a {
  display: inline-block;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  background: transparent;
  padding-bottom: 0px;
}

.slide-item-list li a:hover,
.slide-item-list li a:focus {
  background: transparent;
}

.slide-item-list li a img {
  width: 100%;
}

.product-detail-text {
  padding-top: 28px;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 70px;
}

.star-rating .product-review {
  font-style: 13px;
  color: #b1b1b1;
  font-weight: 400;
  text-decoration: underline !important;
}

.product-detail .tab-content img {
  display: inline-block;
  width: 100%;
}

.popular-tag ul {
  margin: 0px;
  padding: 0px;
}

.popular-tag ul li {
  display: inline-block;
  padding: 8px 15px;
  background: #f8f8f8;
  font-size: 13px;
  color: #fff;
  margin-right: 10px;
  margin-bottom: 10px;
}

.size-filter ul li {
  display: inline-block;
}

.intro {
  border: 1px solid red;
  color: #1d1d1d;
}

#listResults .slider {
  margin: 25px 0;
}

#listResults .slider-box {
  width: 90%;
  margin: 25px auto;
}

#listResults input {
  width: 10%;
}

#listResults label {
  border: none;
  display: inline-block;
  margin-right: -4px;
  vertical-align: top;
  width: 30%;
}

.plus-minus-input .input-icon {
  font-size: 13px;
  color: #aaaaaa;
}

.plus-minus-input .custom-btn {
  border-radius: 0;
  height: 40px;
  padding: 8px 12px;
  background: #ffffff;
  border: 1px solid #c8c8c8;
}

.plus-minus-input .custom-btn:hover,
.plus-minus-input .custom-btn:focus,
.plus-minus-input .custom-btn.active {
  box-shadow: none;
  outline: none;
}

.plus-minus-input .form-control {
  height: 40px;
  border-top: 1px solid #c8c8c8;
  border-bottom: 1px solid #c8c8c8;
  border-left: 0px solid #c8c8c8;
  border-right: 1px solid #c8c8c8;
}

.plus-minus-input .form-control:hover,
.plus-minus-input .form-control:focus,
.plus-minus-input .form-control:active {
  border-top: 1px solid #c8c8c8;
  border-bottom: 1px solid #c8c8c8;
  border-left: 0px solid #c8c8c8;
  border-right: 0px solid #c8c8c8;
}

.new-arrival-product .new-arrivals-img-contnent {
  overflow: hidden;
}

.new-arrival-product .new-arrivals-img-contnent img {
  width: 100%;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}

.new-arrival-product:hover .new-arrivals-img-contnent img {
  transform: scale(1.5) translateY(12%);
  -moz-transform: scale(1.5) translateY(12%);
  -webkit-transform: scale(1.5) translateY(12%);
  -ms-transform: scale(1.5) translateY(12%);
  -o-transform: scale(1.5) translateY(12%);
}

/*
===================================
list view
===================================*/

.new-arrival-content .item {
  font-size: 12px;
  color: #b1b1b1;
}

.new-arrival-content h4 {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 10px;
}

.new-arrival-content h4 a {
  color: #000;
}

.new-arrival-content .price {
  font-weight: 600;
  color: #0b2a97;
  font-size: 24px;
  margin-bottom: 0;
  float: right;
}

@media only screen and (max-width: 575px) {
  .new-arrival-content .price {
    float: none;
    margin-top: 10px;
  }
}

.new-arrival-content p {
  font-size: 14px;
  color: #828690;
  margin-bottom: 6px;
  line-height: 24px;
}

.new-arrival-content .text-content {
  margin-top: 18px;
}

.new-arrival-content.text-center .price {
  float: unset;
}

.success-icon {
  color: #2bc155;
  font-size: 16px;
}

.comment-review {
  margin-bottom: 15px;
  display: table;
  width: 100%;
}

.comment-review .client-review {
  color: #828690;
  padding-right: 20px;
  text-decoration: underline !important;
  font-size: 14px;
}

.comment-review .span {
  color: #828690;
  font-size: 14px;
}

@media only screen and (max-width: 575px) {
  .comment-review {
    margin-bottom: 0;
  }
}

.star-rating li {
  display: inline-block;
}

.star-rating li i {
  color: gold;
}

.rtl {
  text-align: right;
  direction: rtl;
}

.rtl .nav {
  padding-right: 0;
}

.rtl .navbar-nav .nav-item {
  float: right;
}

.rtl .navbar-nav .nav-item + .nav-item {
  margin-right: 1rem;
  margin-left: inherit;
}

.rtl th {
  text-align: right;
}

.rtl .alert-dismissible {
  padding-right: 1.25rem;
  padding-left: 4rem;
}

.rtl .dropdown-menu {
  right: 0;
  text-align: right;
}

.rtl .checkbox label {
  padding-right: 1.25rem;
  padding-left: inherit;
}

.rtl .btn-group > .btn:first-child:not(:last-child):not(.dropdown-toggle) {
  border-radius: 0 0.75rem 0.75rem 0;
}

.rtl .btn-group > .btn:last-child:not(:first-child),
.rtl .btn-group > .dropdown-toggle:not(:first-child) {
  border-radius: 0.75rem 0 0 0.75rem;
}

.rtl .btn-group > .btn-group:last-child:not(:first-child) > .btn:first-child {
  border-radius: 0.75rem 0 0 0.75rem;
}

.rtl .custom-control-label::after,
.rtl .custom-control-label::before {
  right: 0;
  left: inherit;
}

.rtl .custom-select {
  padding: 0.375rem 0.75rem 0.375rem 1.75rem;
}

.rtl
  .input-group
  > .input-group-append:last-child
  > .btn:not(:last-child):not(.dropdown-toggle),
.rtl
  .input-group
  > .input-group-append:last-child
  > .input-group-text:not(:last-child),
.rtl .input-group > .input-group-append:not(:last-child) > .btn,
.rtl .input-group > .input-group-append:not(:last-child) > .input-group-text,
.rtl .input-group > .input-group-prepend > .btn,
.rtl .input-group > .input-group-prepend > .input-group-text {
  border-radius: 0 0.75rem 0.75rem 0;
}

.rtl .input-group > .input-group-append > .btn,
.rtl .input-group > .input-group-append > .input-group-text,
.rtl .input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.rtl
  .input-group
  > .input-group-prepend:first-child
  > .input-group-text:not(:first-child),
.rtl .input-group > .input-group-prepend:not(:first-child) > .btn,
.rtl .input-group > .input-group-prepend:not(:first-child) > .input-group-text {
  border-radius: 0.75rem 0 0 0.75rem;
}

.rtl .input-group > .custom-select:not(:first-child),
.rtl .input-group > .form-control:not(:first-child) {
  border-radius: 0.75rem 0 0 0.75rem;
}

.rtl .input-group > .custom-select:not(:last-child),
.rtl .input-group > .form-control:not(:last-child) {
  border-radius: 0 0.75rem 0.75rem 0;
}

.rtl .input-group > .custom-select:not(:last-child):not(:first-child),
.rtl .input-group > .form-control:not(:last-child):not(:first-child) {
  border-radius: 0;
}

.rtl .custom-control {
  padding-right: 1.5rem;
  padding-left: inherit;
  margin-right: inherit;
  margin-left: 1rem;
}

.rtl .custom-control-indicator {
  right: 0;
  left: inherit;
}

.rtl .custom-file-label::after {
  right: initial;
  left: -1px;
  border-radius: 0.25rem 0 0 0.25rem;
}

.rtl .radio input,
.rtl .radio-inline,
.rtl .checkbox input,
.rtl .checkbox-inline input {
  margin-right: -1.25rem;
  margin-left: inherit;
}

.rtl .list-group {
  padding-right: 0;
  padding-left: 40px;
}

.rtl .close {
  float: left;
}

.rtl .modal-header .close {
  margin: -15px auto -15px -15px;
}

.rtl .modal-footer > :not(:first-child) {
  margin-right: 0.25rem;
}

.rtl .alert-dismissible .close {
  right: inherit;
  left: 0;
}

.rtl .dropdown-toggle::after {
  margin-right: 0.255em;
  margin-left: 0;
}

.rtl .form-check-input {
  margin-right: -1.25rem;
  margin-left: inherit;
}

.rtl .form-check-label {
  padding-right: 1.25rem;
  padding-left: inherit;
}

.rtl .offset-1 {
  margin-right: 8.3333333333%;
  margin-left: 0;
}

.rtl .offset-2 {
  margin-right: 16.6666666667%;
  margin-left: 0;
}

.rtl .offset-3 {
  margin-right: 25%;
  margin-left: 0;
}

.rtl .offset-4 {
  margin-right: 33.3333333333%;
  margin-left: 0;
}

.rtl .offset-5 {
  margin-right: 41.6666666667%;
  margin-left: 0;
}

.rtl .offset-6 {
  margin-right: 50%;
  margin-left: 0;
}

.rtl .offset-7 {
  margin-right: 58.3333333333%;
  margin-left: 0;
}

.rtl .offset-8 {
  margin-right: 66.6666666667%;
  margin-left: 0;
}

.rtl .offset-9 {
  margin-right: 75%;
  margin-left: 0;
}

.rtl .offset-10 {
  margin-right: 83.3333333333%;
  margin-left: 0;
}

.rtl .offset-11 {
  margin-right: 91.6666666667%;
  margin-left: 0;
}

@media (min-width: 576px) {
  .rtl .offset-sm-0 {
    margin-right: 0;
    margin-left: 0;
  }
  .rtl .offset-sm-1 {
    margin-right: 8.3333333333%;
    margin-left: 0;
  }
  .rtl .offset-sm-2 {
    margin-right: 16.6666666667%;
    margin-left: 0;
  }
  .rtl .offset-sm-3 {
    margin-right: 25%;
    margin-left: 0;
  }
  .rtl .offset-sm-4 {
    margin-right: 33.3333333333%;
    margin-left: 0;
  }
  .rtl .offset-sm-5 {
    margin-right: 41.6666666667%;
    margin-left: 0;
  }
  .rtl .offset-sm-6 {
    margin-right: 50%;
    margin-left: 0;
  }
  .rtl .offset-sm-7 {
    margin-right: 58.3333333333%;
    margin-left: 0;
  }
  .rtl .offset-sm-8 {
    margin-right: 66.6666666667%;
    margin-left: 0;
  }
  .rtl .offset-sm-9 {
    margin-right: 75%;
    margin-left: 0;
  }
  .rtl .offset-sm-10 {
    margin-right: 83.3333333333%;
    margin-left: 0;
  }
  .rtl .offset-sm-11 {
    margin-right: 91.6666666667%;
    margin-left: 0;
  }
}

@media (min-width: 768px) {
  .rtl .offset-md-0 {
    margin-right: 0;
    margin-left: 0;
  }
  .rtl .offset-md-1 {
    margin-right: 8.3333333333%;
    margin-left: 0;
  }
  .rtl .offset-md-2 {
    margin-right: 16.6666666667%;
    margin-left: 0;
  }
  .rtl .offset-md-3 {
    margin-right: 25%;
    margin-left: 0;
  }
  .rtl .offset-md-4 {
    margin-right: 33.3333333333%;
    margin-left: 0;
  }
  .rtl .offset-md-5 {
    margin-right: 41.6666666667%;
    margin-left: 0;
  }
  .rtl .offset-md-6 {
    margin-right: 50%;
    margin-left: 0;
  }
  .rtl .offset-md-7 {
    margin-right: 58.3333333333%;
    margin-left: 0;
  }
  .rtl .offset-md-8 {
    margin-right: 66.6666666667%;
    margin-left: 0;
  }
  .rtl .offset-md-9 {
    margin-right: 75%;
    margin-left: 0;
  }
  .rtl .offset-md-10 {
    margin-right: 83.3333333333%;
    margin-left: 0;
  }
  .rtl .offset-md-11 {
    margin-right: 91.6666666667%;
    margin-left: 0;
  }
}

@media (min-width: 992px) {
  .rtl .offset-lg-0 {
    margin-right: 0;
    margin-left: 0;
  }
  .rtl .offset-lg-1 {
    margin-right: 8.3333333333%;
    margin-left: 0;
  }
  .rtl .offset-lg-2 {
    margin-right: 16.6666666667%;
    margin-left: 0;
  }
  .rtl .offset-lg-3 {
    margin-right: 25%;
    margin-left: 0;
  }
  .rtl .offset-lg-4 {
    margin-right: 33.3333333333%;
    margin-left: 0;
  }
  .rtl .offset-lg-5 {
    margin-right: 41.6666666667%;
    margin-left: 0;
  }
  .rtl .offset-lg-6 {
    margin-right: 50%;
    margin-left: 0;
  }
  .rtl .offset-lg-7 {
    margin-right: 58.3333333333%;
    margin-left: 0;
  }
  .rtl .offset-lg-8 {
    margin-right: 66.6666666667%;
    margin-left: 0;
  }
  .rtl .offset-lg-9 {
    margin-right: 75%;
    margin-left: 0;
  }
  .rtl .offset-lg-10 {
    margin-right: 83.3333333333%;
    margin-left: 0;
  }
  .rtl .offset-lg-11 {
    margin-right: 91.6666666667%;
    margin-left: 0;
  }
}

@media (min-width: 1200px) {
  .rtl .offset-xl-0 {
    margin-right: 0;
    margin-left: 0;
  }
  .rtl .offset-xl-1 {
    margin-right: 8.3333333333%;
    margin-left: 0;
  }
  .rtl .offset-xl-2 {
    margin-right: 16.6666666667%;
    margin-left: 0;
  }
  .rtl .offset-xl-3 {
    margin-right: 25%;
    margin-left: 0;
  }
  .rtl .offset-xl-4 {
    margin-right: 33.3333333333%;
    margin-left: 0;
  }
  .rtl .offset-xl-5 {
    margin-right: 41.6666666667%;
    margin-left: 0;
  }
  .rtl .offset-xl-6 {
    margin-right: 50%;
    margin-left: 0;
  }
  .rtl .offset-xl-7 {
    margin-right: 58.3333333333%;
    margin-left: 0;
  }
  .rtl .offset-xl-8 {
    margin-right: 66.6666666667%;
    margin-left: 0;
  }
  .rtl .offset-xl-9 {
    margin-right: 75%;
    margin-left: 0;
  }
  .rtl .offset-xl-10 {
    margin-right: 83.3333333333%;
    margin-left: 0;
  }
  .rtl .offset-xl-11 {
    margin-right: 91.6666666667%;
    margin-left: 0;
  }
}

@media (min-width: 1440) {
  .rtl .offset-xxl-0 {
    margin-right: 0;
    margin-left: 0;
  }
  .rtl .offset-xxl-1 {
    margin-right: 8.3333333333%;
    margin-left: 0;
  }
  .rtl .offset-xxl-2 {
    margin-right: 16.6666666667%;
    margin-left: 0;
  }
  .rtl .offset-xxl-3 {
    margin-right: 25%;
    margin-left: 0;
  }
  .rtl .offset-xxl-4 {
    margin-right: 33.3333333333%;
    margin-left: 0;
  }
  .rtl .offset-xxl-5 {
    margin-right: 41.6666666667%;
    margin-left: 0;
  }
  .rtl .offset-xxl-6 {
    margin-right: 50%;
    margin-left: 0;
  }
  .rtl .offset-xxl-7 {
    margin-right: 58.3333333333%;
    margin-left: 0;
  }
  .rtl .offset-xxl-8 {
    margin-right: 66.6666666667%;
    margin-left: 0;
  }
  .rtl .offset-xxl-9 {
    margin-right: 75%;
    margin-left: 0;
  }
  .rtl .offset-xxl-10 {
    margin-right: 83.3333333333%;
    margin-left: 0;
  }
  .rtl .offset-xxl-11 {
    margin-right: 91.6666666667%;
    margin-left: 0;
  }
}

.rtl .mr-0,
.rtl .mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.rtl .ml-0,
.rtl .mx-0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.rtl .mr-1,
.rtl .mx-1 {
  margin-right: 0 !important;
  margin-left: 0.25rem !important;
}

.rtl .ml-1,
.rtl .mx-1 {
  margin-left: 0 !important;
  margin-right: 0.25rem !important;
}

.rtl .mr-2,
.rtl .mx-2 {
  margin-right: 0 !important;
  margin-left: 0.5rem !important;
}

.rtl .ml-2,
.rtl .mx-2 {
  margin-left: 0 !important;
  margin-right: 0.5rem !important;
}

.rtl .mr-3,
.rtl .mx-3 {
  margin-right: 0 !important;
  margin-left: 1rem !important;
}

.rtl .ml-3,
.rtl .mx-3 {
  margin-left: 0 !important;
  margin-right: 1rem !important;
}

.rtl .mr-4,
.rtl .mx-4 {
  margin-right: 0 !important;
  margin-left: 1.5rem !important;
}

.rtl .ml-4,
.rtl .mx-4 {
  margin-left: 0 !important;
  margin-right: 1.5rem !important;
}

.rtl .mr-5,
.rtl .mx-5 {
  margin-right: 0 !important;
  margin-left: 3rem !important;
}

.rtl .ml-5,
.rtl .mx-5 {
  margin-left: 0 !important;
  margin-right: 3rem !important;
}

.rtl .pr-0,
.rtl .px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.rtl .pl-0,
.rtl .px-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.rtl .pr-1,
.rtl .px-1 {
  padding-right: 0 !important;
  padding-left: 0.25rem !important;
}

.rtl .pl-1,
.rtl .px-1 {
  padding-left: 0 !important;
  padding-right: 0.25rem !important;
}

.rtl .pr-2,
.rtl .px-2 {
  padding-right: 0 !important;
  padding-left: 0.5rem !important;
}

.rtl .pl-2,
.rtl .px-2 {
  padding-left: 0 !important;
  padding-right: 0.5rem !important;
}

.rtl .pr-3,
.rtl .px-3 {
  padding-right: 0 !important;
  padding-left: 1rem !important;
}

.rtl .pl-3,
.rtl .px-3 {
  padding-left: 0 !important;
  padding-right: 1rem !important;
}

.rtl .pr-4,
.rtl .px-4 {
  padding-right: 0 !important;
  padding-left: 1.5rem !important;
}

.rtl .pl-4,
.rtl .px-4 {
  padding-left: 0 !important;
  padding-right: 1.5rem !important;
}

.rtl .pr-5,
.rtl .px-5 {
  padding-right: 0 !important;
  padding-left: 3rem !important;
}

.rtl .pl-5,
.rtl .px-5 {
  padding-left: 0 !important;
  padding-right: 3rem !important;
}

.rtl .mr-auto {
  margin-right: 0 !important;
  margin-left: auto !important;
}

.rtl .ml-auto {
  margin-right: auto !important;
  margin-left: 0 !important;
}

.rtl .mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .rtl .mr-sm-0,
  .rtl .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .rtl .ml-sm-0,
  .rtl .mx-sm-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .rtl .mr-sm-1,
  .rtl .mx-sm-1 {
    margin-right: 0 !important;
    margin-left: 0.25rem !important;
  }
  .rtl .ml-sm-1,
  .rtl .mx-sm-1 {
    margin-left: 0 !important;
    margin-right: 0.25rem !important;
  }
  .rtl .mr-sm-2,
  .rtl .mx-sm-2 {
    margin-right: 0 !important;
    margin-left: 0.5rem !important;
  }
  .rtl .ml-sm-2,
  .rtl .mx-sm-2 {
    margin-left: 0 !important;
    margin-right: 0.5rem !important;
  }
  .rtl .mr-sm-3,
  .rtl .mx-sm-3 {
    margin-right: 0 !important;
    margin-left: 1rem !important;
  }
  .rtl .ml-sm-3,
  .rtl .mx-sm-3 {
    margin-left: 0 !important;
    margin-right: 1rem !important;
  }
  .rtl .mr-sm-4,
  .rtl .mx-sm-4 {
    margin-right: 0 !important;
    margin-left: 1.5rem !important;
  }
  .rtl .ml-sm-4,
  .rtl .mx-sm-4 {
    margin-left: 0 !important;
    margin-right: 1.5rem !important;
  }
  .rtl .mr-sm-5,
  .rtl .mx-sm-5 {
    margin-right: 0 !important;
    margin-left: 3rem !important;
  }
  .rtl .ml-sm-5,
  .rtl .mx-sm-5 {
    margin-left: 0 !important;
    margin-right: 3rem !important;
  }
  .rtl .pr-sm-0,
  .rtl .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .rtl .pl-sm-0,
  .rtl .px-sm-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .rtl .pr-sm-1,
  .rtl .px-sm-1 {
    padding-right: 0 !important;
    padding-left: 0.25rem !important;
  }
  .rtl .pl-sm-1,
  .rtl .px-sm-1 {
    padding-left: 0 !important;
    padding-right: 0.25rem !important;
  }
  .rtl .pr-sm-2,
  .rtl .px-sm-2 {
    padding-right: 0 !important;
    padding-left: 0.5rem !important;
  }
  .rtl .pl-sm-2,
  .rtl .px-sm-2 {
    padding-left: 0 !important;
    padding-right: 0.5rem !important;
  }
  .rtl .pr-sm-3,
  .rtl .px-sm-3 {
    padding-right: 0 !important;
    padding-left: 1rem !important;
  }
  .rtl .pl-sm-3,
  .rtl .px-sm-3 {
    padding-left: 0 !important;
    padding-right: 1rem !important;
  }
  .rtl .pr-sm-4,
  .rtl .px-sm-4 {
    padding-right: 0 !important;
    padding-left: 1.5rem !important;
  }
  .rtl .pl-sm-4,
  .rtl .px-sm-4 {
    padding-left: 0 !important;
    padding-right: 1.5rem !important;
  }
  .rtl .pr-sm-5,
  .rtl .px-sm-5 {
    padding-right: 0 !important;
    padding-left: 3rem !important;
  }
  .rtl .pl-sm-5,
  .rtl .px-sm-5 {
    padding-left: 0 !important;
    padding-right: 3rem !important;
  }
  .rtl .mr-sm-auto {
    margin-right: 0 !important;
    margin-left: auto !important;
  }
  .rtl .ml-sm-auto {
    margin-right: auto !important;
    margin-left: 0 !important;
  }
  .rtl .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
}

@media (min-width: 768px) {
  .rtl .mr-md-0,
  .rtl .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .rtl .ml-md-0,
  .rtl .mx-md-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .rtl .mr-md-1,
  .rtl .mx-md-1 {
    margin-right: 0 !important;
    margin-left: 0.25rem !important;
  }
  .rtl .ml-md-1,
  .rtl .mx-md-1 {
    margin-left: 0 !important;
    margin-right: 0.25rem !important;
  }
  .rtl .mr-md-2,
  .rtl .mx-md-2 {
    margin-right: 0 !important;
    margin-left: 0.5rem !important;
  }
  .rtl .ml-md-2,
  .rtl .mx-md-2 {
    margin-left: 0 !important;
    margin-right: 0.5rem !important;
  }
  .rtl .mr-md-3,
  .rtl .mx-md-3 {
    margin-right: 0 !important;
    margin-left: 1rem !important;
  }
  .rtl .ml-md-3,
  .rtl .mx-md-3 {
    margin-left: 0 !important;
    margin-right: 1rem !important;
  }
  .rtl .mr-md-4,
  .rtl .mx-md-4 {
    margin-right: 0 !important;
    margin-left: 1.5rem !important;
  }
  .rtl .ml-md-4,
  .rtl .mx-md-4 {
    margin-left: 0 !important;
    margin-right: 1.5rem !important;
  }
  .rtl .mr-md-5,
  .rtl .mx-md-5 {
    margin-right: 0 !important;
    margin-left: 3rem !important;
  }
  .rtl .ml-md-5,
  .rtl .mx-md-5 {
    margin-left: 0 !important;
    margin-right: 3rem !important;
  }
  .rtl .pr-md-0,
  .rtl .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .rtl .pl-md-0,
  .rtl .px-md-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .rtl .pr-md-1,
  .rtl .px-md-1 {
    padding-right: 0 !important;
    padding-left: 0.25rem !important;
  }
  .rtl .pl-md-1,
  .rtl .px-md-1 {
    padding-left: 0 !important;
    padding-right: 0.25rem !important;
  }
  .rtl .pr-md-2,
  .rtl .px-md-2 {
    padding-right: 0 !important;
    padding-left: 0.5rem !important;
  }
  .rtl .pl-md-2,
  .rtl .px-md-2 {
    padding-left: 0 !important;
    padding-right: 0.5rem !important;
  }
  .rtl .pr-md-3,
  .rtl .px-md-3 {
    padding-right: 0 !important;
    padding-left: 1rem !important;
  }
  .rtl .pl-md-3,
  .rtl .px-md-3 {
    padding-left: 0 !important;
    padding-right: 1rem !important;
  }
  .rtl .pr-md-4,
  .rtl .px-md-4 {
    padding-right: 0 !important;
    padding-left: 1.5rem !important;
  }
  .rtl .pl-md-4,
  .rtl .px-md-4 {
    padding-left: 0 !important;
    padding-right: 1.5rem !important;
  }
  .rtl .pr-md-5,
  .rtl .px-md-5 {
    padding-right: 0 !important;
    padding-left: 3rem !important;
  }
  .rtl .pl-md-5,
  .rtl .px-md-5 {
    padding-left: 0 !important;
    padding-right: 3rem !important;
  }
  .rtl .mr-md-auto {
    margin-right: 0 !important;
    margin-left: auto !important;
  }
  .rtl .ml-md-auto {
    margin-right: auto !important;
    margin-left: 0 !important;
  }
  .rtl .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
}

@media (min-width: 992px) {
  .rtl .mr-lg-0,
  .rtl .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .rtl .ml-lg-0,
  .rtl .mx-lg-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .rtl .mr-lg-1,
  .rtl .mx-lg-1 {
    margin-right: 0 !important;
    margin-left: 0.25rem !important;
  }
  .rtl .ml-lg-1,
  .rtl .mx-lg-1 {
    margin-left: 0 !important;
    margin-right: 0.25rem !important;
  }
  .rtl .mr-lg-2,
  .rtl .mx-lg-2 {
    margin-right: 0 !important;
    margin-left: 0.5rem !important;
  }
  .rtl .ml-lg-2,
  .rtl .mx-lg-2 {
    margin-left: 0 !important;
    margin-right: 0.5rem !important;
  }
  .rtl .mr-lg-3,
  .rtl .mx-lg-3 {
    margin-right: 0 !important;
    margin-left: 1rem !important;
  }
  .rtl .ml-lg-3,
  .rtl .mx-lg-3 {
    margin-left: 0 !important;
    margin-right: 1rem !important;
  }
  .rtl .mr-lg-4,
  .rtl .mx-lg-4 {
    margin-right: 0 !important;
    margin-left: 1.5rem !important;
  }
  .rtl .ml-lg-4,
  .rtl .mx-lg-4 {
    margin-left: 0 !important;
    margin-right: 1.5rem !important;
  }
  .rtl .mr-lg-5,
  .rtl .mx-lg-5 {
    margin-right: 0 !important;
    margin-left: 3rem !important;
  }
  .rtl .ml-lg-5,
  .rtl .mx-lg-5 {
    margin-left: 0 !important;
    margin-right: 3rem !important;
  }
  .rtl .pr-lg-0,
  .rtl .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .rtl .pl-lg-0,
  .rtl .px-lg-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .rtl .pr-lg-1,
  .rtl .px-lg-1 {
    padding-right: 0 !important;
    padding-left: 0.25rem !important;
  }
  .rtl .pl-lg-1,
  .rtl .px-lg-1 {
    padding-left: 0 !important;
    padding-right: 0.25rem !important;
  }
  .rtl .pr-lg-2,
  .rtl .px-lg-2 {
    padding-right: 0 !important;
    padding-left: 0.5rem !important;
  }
  .rtl .pl-lg-2,
  .rtl .px-lg-2 {
    padding-left: 0 !important;
    padding-right: 0.5rem !important;
  }
  .rtl .pr-lg-3,
  .rtl .px-lg-3 {
    padding-right: 0 !important;
    padding-left: 1rem !important;
  }
  .rtl .pl-lg-3,
  .rtl .px-lg-3 {
    padding-left: 0 !important;
    padding-right: 1rem !important;
  }
  .rtl .pr-lg-4,
  .rtl .px-lg-4 {
    padding-right: 0 !important;
    padding-left: 1.5rem !important;
  }
  .rtl .pl-lg-4,
  .rtl .px-lg-4 {
    padding-left: 0 !important;
    padding-right: 1.5rem !important;
  }
  .rtl .pr-lg-5,
  .rtl .px-lg-5 {
    padding-right: 0 !important;
    padding-left: 3rem !important;
  }
  .rtl .pl-lg-5,
  .rtl .px-lg-5 {
    padding-left: 0 !important;
    padding-right: 3rem !important;
  }
  .rtl .mr-lg-auto {
    margin-right: 0 !important;
    margin-left: auto !important;
  }
  .rtl .ml-lg-auto {
    margin-right: auto !important;
    margin-left: 0 !important;
  }
  .rtl .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
}

@media (min-width: 1200px) {
  .rtl .mr-xl-0,
  .rtl .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .rtl .ml-xl-0,
  .rtl .mx-xl-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .rtl .mr-xl-1,
  .rtl .mx-xl-1 {
    margin-right: 0 !important;
    margin-left: 0.25rem !important;
  }
  .rtl .ml-xl-1,
  .rtl .mx-xl-1 {
    margin-left: 0 !important;
    margin-right: 0.25rem !important;
  }
  .rtl .mr-xl-2,
  .rtl .mx-xl-2 {
    margin-right: 0 !important;
    margin-left: 0.5rem !important;
  }
  .rtl .ml-xl-2,
  .rtl .mx-xl-2 {
    margin-left: 0 !important;
    margin-right: 0.5rem !important;
  }
  .rtl .mr-xl-3,
  .rtl .mx-xl-3 {
    margin-right: 0 !important;
    margin-left: 1rem !important;
  }
  .rtl .ml-xl-3,
  .rtl .mx-xl-3 {
    margin-left: 0 !important;
    margin-right: 1rem !important;
  }
  .rtl .mr-xl-4,
  .rtl .mx-xl-4 {
    margin-right: 0 !important;
    margin-left: 1.5rem !important;
  }
  .rtl .ml-xl-4,
  .rtl .mx-xl-4 {
    margin-left: 0 !important;
    margin-right: 1.5rem !important;
  }
  .rtl .mr-xl-5,
  .rtl .mx-xl-5 {
    margin-right: 0 !important;
    margin-left: 3rem !important;
  }
  .rtl .ml-xl-5,
  .rtl .mx-xl-5 {
    margin-left: 0 !important;
    margin-right: 3rem !important;
  }
  .rtl .pr-xl-0,
  .rtl .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .rtl .pl-xl-0,
  .rtl .px-xl-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .rtl .pr-xl-1,
  .rtl .px-xl-1 {
    padding-right: 0 !important;
    padding-left: 0.25rem !important;
  }
  .rtl .pl-xl-1,
  .rtl .px-xl-1 {
    padding-left: 0 !important;
    padding-right: 0.25rem !important;
  }
  .rtl .pr-xl-2,
  .rtl .px-xl-2 {
    padding-right: 0 !important;
    padding-left: 0.5rem !important;
  }
  .rtl .pl-xl-2,
  .rtl .px-xl-2 {
    padding-left: 0 !important;
    padding-right: 0.5rem !important;
  }
  .rtl .pr-xl-3,
  .rtl .px-xl-3 {
    padding-right: 0 !important;
    padding-left: 1rem !important;
  }
  .rtl .pl-xl-3,
  .rtl .px-xl-3 {
    padding-left: 0 !important;
    padding-right: 1rem !important;
  }
  .rtl .pr-xl-4,
  .rtl .px-xl-4 {
    padding-right: 0 !important;
    padding-left: 1.5rem !important;
  }
  .rtl .pl-xl-4,
  .rtl .px-xl-4 {
    padding-left: 0 !important;
    padding-right: 1.5rem !important;
  }
  .rtl .pr-xl-5,
  .rtl .px-xl-5 {
    padding-right: 0 !important;
    padding-left: 3rem !important;
  }
  .rtl .pl-xl-5,
  .rtl .px-xl-5 {
    padding-left: 0 !important;
    padding-right: 3rem !important;
  }
  .rtl .mr-xl-auto {
    margin-right: 0 !important;
    margin-left: auto !important;
  }
  .rtl .ml-xl-auto {
    margin-right: auto !important;
    margin-left: 0 !important;
  }
  .rtl .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
}

@media (min-width: 1440) {
  .rtl .mr-xxl-0,
  .rtl .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .rtl .ml-xxl-0,
  .rtl .mx-xxl-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .rtl .mr-xxl-1,
  .rtl .mx-xxl-1 {
    margin-right: 0 !important;
    margin-left: 0.25rem !important;
  }
  .rtl .ml-xxl-1,
  .rtl .mx-xxl-1 {
    margin-left: 0 !important;
    margin-right: 0.25rem !important;
  }
  .rtl .mr-xxl-2,
  .rtl .mx-xxl-2 {
    margin-right: 0 !important;
    margin-left: 0.5rem !important;
  }
  .rtl .ml-xxl-2,
  .rtl .mx-xxl-2 {
    margin-left: 0 !important;
    margin-right: 0.5rem !important;
  }
  .rtl .mr-xxl-3,
  .rtl .mx-xxl-3 {
    margin-right: 0 !important;
    margin-left: 1rem !important;
  }
  .rtl .ml-xxl-3,
  .rtl .mx-xxl-3 {
    margin-left: 0 !important;
    margin-right: 1rem !important;
  }
  .rtl .mr-xxl-4,
  .rtl .mx-xxl-4 {
    margin-right: 0 !important;
    margin-left: 1.5rem !important;
  }
  .rtl .ml-xxl-4,
  .rtl .mx-xxl-4 {
    margin-left: 0 !important;
    margin-right: 1.5rem !important;
  }
  .rtl .mr-xxl-5,
  .rtl .mx-xxl-5 {
    margin-right: 0 !important;
    margin-left: 3rem !important;
  }
  .rtl .ml-xxl-5,
  .rtl .mx-xxl-5 {
    margin-left: 0 !important;
    margin-right: 3rem !important;
  }
  .rtl .pr-xxl-0,
  .rtl .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .rtl .pl-xxl-0,
  .rtl .px-xxl-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .rtl .pr-xxl-1,
  .rtl .px-xxl-1 {
    padding-right: 0 !important;
    padding-left: 0.25rem !important;
  }
  .rtl .pl-xxl-1,
  .rtl .px-xxl-1 {
    padding-left: 0 !important;
    padding-right: 0.25rem !important;
  }
  .rtl .pr-xxl-2,
  .rtl .px-xxl-2 {
    padding-right: 0 !important;
    padding-left: 0.5rem !important;
  }
  .rtl .pl-xxl-2,
  .rtl .px-xxl-2 {
    padding-left: 0 !important;
    padding-right: 0.5rem !important;
  }
  .rtl .pr-xxl-3,
  .rtl .px-xxl-3 {
    padding-right: 0 !important;
    padding-left: 1rem !important;
  }
  .rtl .pl-xxl-3,
  .rtl .px-xxl-3 {
    padding-left: 0 !important;
    padding-right: 1rem !important;
  }
  .rtl .pr-xxl-4,
  .rtl .px-xxl-4 {
    padding-right: 0 !important;
    padding-left: 1.5rem !important;
  }
  .rtl .pl-xxl-4,
  .rtl .px-xxl-4 {
    padding-left: 0 !important;
    padding-right: 1.5rem !important;
  }
  .rtl .pr-xxl-5,
  .rtl .px-xxl-5 {
    padding-right: 0 !important;
    padding-left: 3rem !important;
  }
  .rtl .pl-xxl-5,
  .rtl .px-xxl-5 {
    padding-left: 0 !important;
    padding-right: 3rem !important;
  }
  .rtl .mr-xxl-auto {
    margin-right: 0 !important;
    margin-left: auto !important;
  }
  .rtl .ml-xxl-auto {
    margin-right: auto !important;
    margin-left: 0 !important;
  }
  .rtl .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
}

.rtl .text-right {
  text-align: left !important;
}

.rtl .text-left {
  text-align: right !important;
}

@media (min-width: 576px) {
  .rtl .text-sm-right {
    text-align: left !important;
  }
  .rtl .text-sm-left {
    text-align: right !important;
  }
}

@media (min-width: 768px) {
  .rtl .text-md-right {
    text-align: left !important;
  }
  .rtl .text-md-left {
    text-align: right !important;
  }
}

@media (min-width: 992px) {
  .rtl .text-lg-right {
    text-align: left !important;
  }
  .rtl .text-lg-left {
    text-align: right !important;
  }
}

@media (min-width: 1200px) {
  .rtl .text-xl-right {
    text-align: left !important;
  }
  .rtl .text-xl-left {
    text-align: right !important;
  }
}

@media (min-width: 1440) {
  .rtl .text-xxl-right {
    text-align: left !important;
  }
  .rtl .text-xxl-left {
    text-align: right !important;
  }
}

.rtl .mx-0 {
  margin-right: auto;
  margin-left: 0 !important;
}

.rtl .mx-0 {
  margin-left: auto;
  margin-right: 0 !important;
}

.rtl .mx-1 {
  margin-right: auto;
  margin-left: 0.25rem !important;
}

.rtl .mx-1 {
  margin-left: auto;
  margin-right: 0.25rem !important;
}

.rtl .mx-2 {
  margin-right: auto;
  margin-left: 0.5rem !important;
}

.rtl .mx-2 {
  margin-left: auto;
  margin-right: 0.5rem !important;
}

.rtl .mx-3 {
  margin-right: auto;
  margin-left: 1rem !important;
}

.rtl .mx-3 {
  margin-left: auto;
  margin-right: 1rem !important;
}

.rtl .mx-4 {
  margin-right: auto;
  margin-left: 1.5rem !important;
}

.rtl .mx-4 {
  margin-left: auto;
  margin-right: 1.5rem !important;
}

.rtl .mx-5 {
  margin-right: auto;
  margin-left: 3rem !important;
}

.rtl .mx-5 {
  margin-left: auto;
  margin-right: 3rem !important;
}

.rtl .px-0 {
  padding-right: auto;
  padding-left: 0 !important;
}

.rtl .px-0 {
  padding-left: auto;
  padding-right: 0 !important;
}

.rtl .px-1 {
  padding-right: auto;
  padding-left: 0.25rem !important;
}

.rtl .px-1 {
  padding-left: auto;
  padding-right: 0.25rem !important;
}

.rtl .px-2 {
  padding-right: auto;
  padding-left: 0.5rem !important;
}

.rtl .px-2 {
  padding-left: auto;
  padding-right: 0.5rem !important;
}

.rtl .px-3 {
  padding-right: auto;
  padding-left: 1rem !important;
}

.rtl .px-3 {
  padding-left: auto;
  padding-right: 1rem !important;
}

.rtl .px-4 {
  padding-right: auto;
  padding-left: 1.5rem !important;
}

.rtl .px-4 {
  padding-left: auto;
  padding-right: 1.5rem !important;
}

.rtl .px-5 {
  padding-right: auto;
  padding-left: 3rem !important;
}

.rtl .px-5 {
  padding-left: auto;
  padding-right: 3rem !important;
}

.rtl .mr-auto {
  margin-right: 0 !important;
  margin-left: auto !important;
}

.rtl .mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.rtl .ml-auto {
  margin-right: auto !important;
  margin-left: 0 !important;
}

.rtl .mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .rtl .mx-sm-0 {
    margin-right: auto;
    margin-left: 0 !important;
  }
  .rtl .mx-sm-0 {
    margin-left: auto;
    margin-right: 0 !important;
  }
  .rtl .mx-sm-1 {
    margin-right: auto;
    margin-left: 0.25rem !important;
  }
  .rtl .mx-sm-1 {
    margin-left: auto;
    margin-right: 0.25rem !important;
  }
  .rtl .mx-sm-2 {
    margin-right: auto;
    margin-left: 0.5rem !important;
  }
  .rtl .mx-sm-2 {
    margin-left: auto;
    margin-right: 0.5rem !important;
  }
  .rtl .mx-sm-3 {
    margin-right: auto;
    margin-left: 1rem !important;
  }
  .rtl .mx-sm-3 {
    margin-left: auto;
    margin-right: 1rem !important;
  }
  .rtl .mx-sm-4 {
    margin-right: auto;
    margin-left: 1.5rem !important;
  }
  .rtl .mx-sm-4 {
    margin-left: auto;
    margin-right: 1.5rem !important;
  }
  .rtl .mx-sm-5 {
    margin-right: auto;
    margin-left: 3rem !important;
  }
  .rtl .mx-sm-5 {
    margin-left: auto;
    margin-right: 3rem !important;
  }
  .rtl .px-sm-0 {
    padding-right: auto;
    padding-left: 0 !important;
  }
  .rtl .px-sm-0 {
    padding-left: auto;
    padding-right: 0 !important;
  }
  .rtl .px-sm-1 {
    padding-right: auto;
    padding-left: 0.25rem !important;
  }
  .rtl .px-sm-1 {
    padding-left: auto;
    padding-right: 0.25rem !important;
  }
  .rtl .px-sm-2 {
    padding-right: auto;
    padding-left: 0.5rem !important;
  }
  .rtl .px-sm-2 {
    padding-left: auto;
    padding-right: 0.5rem !important;
  }
  .rtl .px-sm-3 {
    padding-right: auto;
    padding-left: 1rem !important;
  }
  .rtl .px-sm-3 {
    padding-left: auto;
    padding-right: 1rem !important;
  }
  .rtl .px-sm-4 {
    padding-right: auto;
    padding-left: 1.5rem !important;
  }
  .rtl .px-sm-4 {
    padding-left: auto;
    padding-right: 1.5rem !important;
  }
  .rtl .px-sm-5 {
    padding-right: auto;
    padding-left: 3rem !important;
  }
  .rtl .px-sm-5 {
    padding-left: auto;
    padding-right: 3rem !important;
  }
  .rtl .mr-sm-auto {
    margin-right: 0 !important;
    margin-left: auto !important;
  }
  .rtl .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .rtl .ml-sm-auto {
    margin-right: auto !important;
    margin-left: 0 !important;
  }
  .rtl .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
}

@media (min-width: 768px) {
  .rtl .mx-md-0 {
    margin-right: auto;
    margin-left: 0 !important;
  }
  .rtl .mx-md-0 {
    margin-left: auto;
    margin-right: 0 !important;
  }
  .rtl .mx-md-1 {
    margin-right: auto;
    margin-left: 0.25rem !important;
  }
  .rtl .mx-md-1 {
    margin-left: auto;
    margin-right: 0.25rem !important;
  }
  .rtl .mx-md-2 {
    margin-right: auto;
    margin-left: 0.5rem !important;
  }
  .rtl .mx-md-2 {
    margin-left: auto;
    margin-right: 0.5rem !important;
  }
  .rtl .mx-md-3 {
    margin-right: auto;
    margin-left: 1rem !important;
  }
  .rtl .mx-md-3 {
    margin-left: auto;
    margin-right: 1rem !important;
  }
  .rtl .mx-md-4 {
    margin-right: auto;
    margin-left: 1.5rem !important;
  }
  .rtl .mx-md-4 {
    margin-left: auto;
    margin-right: 1.5rem !important;
  }
  .rtl .mx-md-5 {
    margin-right: auto;
    margin-left: 3rem !important;
  }
  .rtl .mx-md-5 {
    margin-left: auto;
    margin-right: 3rem !important;
  }
  .rtl .px-md-0 {
    padding-right: auto;
    padding-left: 0 !important;
  }
  .rtl .px-md-0 {
    padding-left: auto;
    padding-right: 0 !important;
  }
  .rtl .px-md-1 {
    padding-right: auto;
    padding-left: 0.25rem !important;
  }
  .rtl .px-md-1 {
    padding-left: auto;
    padding-right: 0.25rem !important;
  }
  .rtl .px-md-2 {
    padding-right: auto;
    padding-left: 0.5rem !important;
  }
  .rtl .px-md-2 {
    padding-left: auto;
    padding-right: 0.5rem !important;
  }
  .rtl .px-md-3 {
    padding-right: auto;
    padding-left: 1rem !important;
  }
  .rtl .px-md-3 {
    padding-left: auto;
    padding-right: 1rem !important;
  }
  .rtl .px-md-4 {
    padding-right: auto;
    padding-left: 1.5rem !important;
  }
  .rtl .px-md-4 {
    padding-left: auto;
    padding-right: 1.5rem !important;
  }
  .rtl .px-md-5 {
    padding-right: auto;
    padding-left: 3rem !important;
  }
  .rtl .px-md-5 {
    padding-left: auto;
    padding-right: 3rem !important;
  }
  .rtl .mr-md-auto {
    margin-right: 0 !important;
    margin-left: auto !important;
  }
  .rtl .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .rtl .ml-md-auto {
    margin-right: auto !important;
    margin-left: 0 !important;
  }
  .rtl .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
}

@media (min-width: 992px) {
  .rtl .mx-lg-0 {
    margin-right: auto;
    margin-left: 0 !important;
  }
  .rtl .mx-lg-0 {
    margin-left: auto;
    margin-right: 0 !important;
  }
  .rtl .mx-lg-1 {
    margin-right: auto;
    margin-left: 0.25rem !important;
  }
  .rtl .mx-lg-1 {
    margin-left: auto;
    margin-right: 0.25rem !important;
  }
  .rtl .mx-lg-2 {
    margin-right: auto;
    margin-left: 0.5rem !important;
  }
  .rtl .mx-lg-2 {
    margin-left: auto;
    margin-right: 0.5rem !important;
  }
  .rtl .mx-lg-3 {
    margin-right: auto;
    margin-left: 1rem !important;
  }
  .rtl .mx-lg-3 {
    margin-left: auto;
    margin-right: 1rem !important;
  }
  .rtl .mx-lg-4 {
    margin-right: auto;
    margin-left: 1.5rem !important;
  }
  .rtl .mx-lg-4 {
    margin-left: auto;
    margin-right: 1.5rem !important;
  }
  .rtl .mx-lg-5 {
    margin-right: auto;
    margin-left: 3rem !important;
  }
  .rtl .mx-lg-5 {
    margin-left: auto;
    margin-right: 3rem !important;
  }
  .rtl .px-lg-0 {
    padding-right: auto;
    padding-left: 0 !important;
  }
  .rtl .px-lg-0 {
    padding-left: auto;
    padding-right: 0 !important;
  }
  .rtl .px-lg-1 {
    padding-right: auto;
    padding-left: 0.25rem !important;
  }
  .rtl .px-lg-1 {
    padding-left: auto;
    padding-right: 0.25rem !important;
  }
  .rtl .px-lg-2 {
    padding-right: auto;
    padding-left: 0.5rem !important;
  }
  .rtl .px-lg-2 {
    padding-left: auto;
    padding-right: 0.5rem !important;
  }
  .rtl .px-lg-3 {
    padding-right: auto;
    padding-left: 1rem !important;
  }
  .rtl .px-lg-3 {
    padding-left: auto;
    padding-right: 1rem !important;
  }
  .rtl .px-lg-4 {
    padding-right: auto;
    padding-left: 1.5rem !important;
  }
  .rtl .px-lg-4 {
    padding-left: auto;
    padding-right: 1.5rem !important;
  }
  .rtl .px-lg-5 {
    padding-right: auto;
    padding-left: 3rem !important;
  }
  .rtl .px-lg-5 {
    padding-left: auto;
    padding-right: 3rem !important;
  }
  .rtl .mr-lg-auto {
    margin-right: 0 !important;
    margin-left: auto !important;
  }
  .rtl .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .rtl .ml-lg-auto {
    margin-right: auto !important;
    margin-left: 0 !important;
  }
  .rtl .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
}

@media (min-width: 1200px) {
  .rtl .mx-xl-0 {
    margin-right: auto;
    margin-left: 0 !important;
  }
  .rtl .mx-xl-0 {
    margin-left: auto;
    margin-right: 0 !important;
  }
  .rtl .mx-xl-1 {
    margin-right: auto;
    margin-left: 0.25rem !important;
  }
  .rtl .mx-xl-1 {
    margin-left: auto;
    margin-right: 0.25rem !important;
  }
  .rtl .mx-xl-2 {
    margin-right: auto;
    margin-left: 0.5rem !important;
  }
  .rtl .mx-xl-2 {
    margin-left: auto;
    margin-right: 0.5rem !important;
  }
  .rtl .mx-xl-3 {
    margin-right: auto;
    margin-left: 1rem !important;
  }
  .rtl .mx-xl-3 {
    margin-left: auto;
    margin-right: 1rem !important;
  }
  .rtl .mx-xl-4 {
    margin-right: auto;
    margin-left: 1.5rem !important;
  }
  .rtl .mx-xl-4 {
    margin-left: auto;
    margin-right: 1.5rem !important;
  }
  .rtl .mx-xl-5 {
    margin-right: auto;
    margin-left: 3rem !important;
  }
  .rtl .mx-xl-5 {
    margin-left: auto;
    margin-right: 3rem !important;
  }
  .rtl .px-xl-0 {
    padding-right: auto;
    padding-left: 0 !important;
  }
  .rtl .px-xl-0 {
    padding-left: auto;
    padding-right: 0 !important;
  }
  .rtl .px-xl-1 {
    padding-right: auto;
    padding-left: 0.25rem !important;
  }
  .rtl .px-xl-1 {
    padding-left: auto;
    padding-right: 0.25rem !important;
  }
  .rtl .px-xl-2 {
    padding-right: auto;
    padding-left: 0.5rem !important;
  }
  .rtl .px-xl-2 {
    padding-left: auto;
    padding-right: 0.5rem !important;
  }
  .rtl .px-xl-3 {
    padding-right: auto;
    padding-left: 1rem !important;
  }
  .rtl .px-xl-3 {
    padding-left: auto;
    padding-right: 1rem !important;
  }
  .rtl .px-xl-4 {
    padding-right: auto;
    padding-left: 1.5rem !important;
  }
  .rtl .px-xl-4 {
    padding-left: auto;
    padding-right: 1.5rem !important;
  }
  .rtl .px-xl-5 {
    padding-right: auto;
    padding-left: 3rem !important;
  }
  .rtl .px-xl-5 {
    padding-left: auto;
    padding-right: 3rem !important;
  }
  .rtl .mr-xl-auto {
    margin-right: 0 !important;
    margin-left: auto !important;
  }
  .rtl .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .rtl .ml-xl-auto {
    margin-right: auto !important;
    margin-left: 0 !important;
  }
  .rtl .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
}

@media (min-width: 1440) {
  .rtl .mx-xxl-0 {
    margin-right: auto;
    margin-left: 0 !important;
  }
  .rtl .mx-xxl-0 {
    margin-left: auto;
    margin-right: 0 !important;
  }
  .rtl .mx-xxl-1 {
    margin-right: auto;
    margin-left: 0.25rem !important;
  }
  .rtl .mx-xxl-1 {
    margin-left: auto;
    margin-right: 0.25rem !important;
  }
  .rtl .mx-xxl-2 {
    margin-right: auto;
    margin-left: 0.5rem !important;
  }
  .rtl .mx-xxl-2 {
    margin-left: auto;
    margin-right: 0.5rem !important;
  }
  .rtl .mx-xxl-3 {
    margin-right: auto;
    margin-left: 1rem !important;
  }
  .rtl .mx-xxl-3 {
    margin-left: auto;
    margin-right: 1rem !important;
  }
  .rtl .mx-xxl-4 {
    margin-right: auto;
    margin-left: 1.5rem !important;
  }
  .rtl .mx-xxl-4 {
    margin-left: auto;
    margin-right: 1.5rem !important;
  }
  .rtl .mx-xxl-5 {
    margin-right: auto;
    margin-left: 3rem !important;
  }
  .rtl .mx-xxl-5 {
    margin-left: auto;
    margin-right: 3rem !important;
  }
  .rtl .px-xxl-0 {
    padding-right: auto;
    padding-left: 0 !important;
  }
  .rtl .px-xxl-0 {
    padding-left: auto;
    padding-right: 0 !important;
  }
  .rtl .px-xxl-1 {
    padding-right: auto;
    padding-left: 0.25rem !important;
  }
  .rtl .px-xxl-1 {
    padding-left: auto;
    padding-right: 0.25rem !important;
  }
  .rtl .px-xxl-2 {
    padding-right: auto;
    padding-left: 0.5rem !important;
  }
  .rtl .px-xxl-2 {
    padding-left: auto;
    padding-right: 0.5rem !important;
  }
  .rtl .px-xxl-3 {
    padding-right: auto;
    padding-left: 1rem !important;
  }
  .rtl .px-xxl-3 {
    padding-left: auto;
    padding-right: 1rem !important;
  }
  .rtl .px-xxl-4 {
    padding-right: auto;
    padding-left: 1.5rem !important;
  }
  .rtl .px-xxl-4 {
    padding-left: auto;
    padding-right: 1.5rem !important;
  }
  .rtl .px-xxl-5 {
    padding-right: auto;
    padding-left: 3rem !important;
  }
  .rtl .px-xxl-5 {
    padding-left: auto;
    padding-right: 3rem !important;
  }
  .rtl .mr-xxl-auto {
    margin-right: 0 !important;
    margin-left: auto !important;
  }
  .rtl .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .rtl .ml-xxl-auto {
    margin-right: auto !important;
    margin-left: 0 !important;
  }
  .rtl .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
}

.rtl .float-left {
  float: right !important;
}

.rtl .float-right {
  float: left !important;
}

.rtl .float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .rtl .float-sm-left {
    float: right !important;
  }
  .rtl .float-sm-right {
    float: left !important;
  }
  .rtl .float-sm-none {
    float: none !important;
  }
}

@media (min-width: 768px) {
  .rtl .float-md-left {
    float: right !important;
  }
  .rtl .float-md-right {
    float: left !important;
  }
  .rtl .float-md-none {
    float: none !important;
  }
}

@media (min-width: 992px) {
  .rtl .float-lg-left {
    float: right !important;
  }
  .rtl .float-lg-right {
    float: left !important;
  }
  .rtl .float-lg-none {
    float: none !important;
  }
}

@media (min-width: 1200px) {
  .rtl .float-xl-left {
    float: right !important;
  }
  .rtl .float-xl-right {
    float: left !important;
  }
  .rtl .float-xl-none {
    float: none !important;
  }
}

@media (min-width: 1440) {
  .rtl .float-xxl-left {
    float: right !important;
  }
  .rtl .float-xxl-right {
    float: left !important;
  }
  .rtl .float-xxl-none {
    float: none !important;
  }
}

[direction='rtl'][data-theme-version='dark'] .border,
[direction='rtl'][data-theme-version='dark'] .border-left,
[direction='rtl'][data-theme-version='dark'] .border-right {
  border-color: #333a54 !important;
}

[direction='rtl'] .text-right {
  text-align: left !important;
}

[direction='rtl'] .text-left {
  text-align: right !important;
}

[direction='rtl'] .border-right {
  border-left: 1px solid #f0f1f5 !important;
  border-right: 0 !important;
}

[direction='rtl'] .border-left {
  border-right: 1px solid #f0f1f5 !important;
  border-left: 0 !important;
}

[direction='rtl'] .dropdown-menu {
  left: auto;
}

[direction='rtl'] .dropdown-menu-right {
  left: 0;
  right: auto;
}

@media only screen and (max-width: 575px) {
  [direction='rtl'] .dropdown-menu-right {
    left: 15px;
  }
}

[direction='rtl'] .notification_dropdown .dropdown-menu-right .media > span {
  margin-left: 10px;
  margin-right: 0;
}

[direction='rtl']:not([data-container='boxed']) .nav-header {
  left: auto;
  right: 0;
}

[direction='rtl'][data-container='wide-boxed'] .nav-header {
  left: auto;
  right: 0;
}

[direction='rtl'] .nav-header {
  text-align: right;
  right: auto;
}

[direction='rtl'] .nav-header .brand-title {
  margin-left: 0;
  margin-right: 15px;
}

[direction='rtl'] .nav-header .brand-logo {
  padding-left: 0;
  padding-right: 1.75rem;
}

[data-sidebar-style='compact'][direction='rtl'] .nav-header .brand-logo {
  padding-right: 0;
}

[data-sidebar-style='compact'][direction='rtl']
  .nav-header
  .brand-logo[data-layout='horizontal'] {
  padding-right: 30px;
}

[data-sidebar-style='mini'][direction='rtl'] .nav-header .brand-logo {
  padding-right: 0;
}

[data-sidebar-style='modern'][direction='rtl'] .nav-header .brand-logo {
  padding-right: 0;
}

[data-layout='horizontal'][data-sidebar-style='modern'][direction='rtl']
  .nav-header
  .brand-logo {
  padding-right: 30px;
}

@media (max-width: 767.98px) {
  [direction='rtl'] .nav-header .brand-logo {
    padding-right: 0;
  }
}

[direction='rtl'] .nav-control {
  right: auto;
  left: -4.0625rem;
}

@media (max-width: 767.98px) {
  [direction='rtl'] .nav-control {
    left: -4.0625rem;
  }
}

@media (max-width: 575.98px) {
  [direction='rtl'] .nav-control {
    left: -4.0625rem;
  }
}

[direction='rtl'][data-sidebar-style='overlay']
  .nav-header
  .hamburger.is-active {
  right: 0;
}

[direction='rtl'][data-sidebar-style='compact'][data-layout='horizontal']
  .nav-header
  .brand-logo {
  padding-right: 30px;
}

[direction='rtl'][data-sidebar-style='modern'][data-layout='horizontal']
  .nav-header {
  width: 16rem;
}

[direction='rtl'] .header {
  padding: 0 0.9375rem;
  padding-right: 17.1875rem;
}

@media (max-width: 767.98px) {
  [direction='rtl'] .header {
    padding-right: 3.75rem;
    padding-left: 0;
  }
}

[direction='rtl'] .header .header-content {
  padding-left: 0;
  padding-right: 5.3125rem;
}

@media only screen and (max-width: 575px) {
  [direction='rtl'] .header .header-content {
    padding-right: 4.5rem;
  }
}

[data-sidebar-style='compact'][direction='rtl'] .header .header-content {
  padding-right: 1.875rem;
}

[data-sidebar-style='modern'][direction='rtl'] .header .header-content {
  padding-right: 5.3125rem;
}

[data-sidebar-style='overlay'][direction='rtl'] .header .header-content {
  padding-right: 5.3125rem;
}

[direction='rtl'] .header .nav-control {
  right: 0.4375rem;
  left: auto;
}

[direction='rtl'] .header-left .search_bar .dropdown-menu,
[direction='rtl'] .header-left .search_bar .dropdown-menu.show {
  right: 40px !important;
}

@media only screen and (max-width: 575px) {
  [direction='rtl'] .header-left .search_bar .dropdown-menu,
  [direction='rtl'] .header-left .search_bar .dropdown-menu.show {
    right: -100px !important;
  }
}

[direction='rtl'] .header-left .search_bar .search_icon {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 5rem;
  border-bottom-right-radius: 5rem;
}

@media only screen and (max-width: 575px) {
  [direction='rtl'] .header-left .search_bar .search_icon {
    border-radius: 5rem;
  }
}

[direction='rtl'][data-layout='horizontal'] .header {
  padding: 0;
  padding-right: 16rem;
}

[direction='rtl'][data-layout='horizontal'] .header .header-content {
  padding-right: 1.875rem;
  padding-left: 1.875rem;
}

[direction='rtl'][data-layout='horizontal'][data-sidebar-style='mini'] .header {
  padding-right: 6rem;
}

[direction='rtl'][data-sidebar-style='mini'] .header {
  padding: 0 0.9375rem;
  padding-right: 3.75rem;
}

[direction='rtl'][data-sidebar-style='compact'] .header {
  padding: 0 0.9375rem;
  padding-right: 9.375rem;
}

[direction='rtl'][data-sidebar-style='compact'][data-layout='horizontal']
  .header {
  padding: 0;
  padding-right: 16rem;
}

[direction='rtl'][data-sidebar-style='modern'] .header {
  padding: 0 0.9375rem;
  padding-right: 9.375rem;
}

[direction='rtl'][data-sidebar-style='modern'][data-layout='horizontal']
  .header {
  padding: 0;
  padding-right: 16rem;
}

[direction='rtl'] {
  text-align: right;
}

[direction='rtl'] .deznav {
  text-align: right;
}

[direction='rtl'] .deznav .metismenu ul {
  padding: 0 25px 0 0;
}

[direction='rtl'] .deznav .metismenu ul:after {
  left: auto;
  right: 25px;
}

[direction='rtl'] .deznav .metismenu ul a:before {
  left: auto;
  right: -5px;
}

[direction='rtl'] .deznav .metismenu li > a svg {
  margin-left: 5px;
  margin-right: 0;
}

[data-sidebar-style='compact'][direction='rtl'] .deznav .metismenu li > a svg {
  left: auto;
  margin-left: auto;
  margin-right: auto;
}

[data-sidebar-style='icon-hover'][direction='rtl']
  .deznav
  .metismenu
  li
  > a
  svg {
  margin-left: 0;
}

[direction='rtl'] .deznav .metismenu li.active > .has-arrow:after {
  transform: rotate(45deg) translateY(-50%);
}

[direction='rtl'] .deznav .metismenu .has-arrow:after {
  left: 1.5625rem;
  right: auto;
}

[data-layout='horizontal'][direction='rtl']
  .deznav
  .metismenu
  .has-arrow:after {
  left: 1.125rem;
}

[data-sidebar-style='modern'][direction='rtl']
  .deznav
  .metismenu
  .has-arrow:after {
  -webkit-transform: rotate(-45deg) translateY(-50%);
  transform: rotate(-45deg) translateY(-50%);
}

[direction='rtl'][data-sidebar-style='full'][data-layout='vertical']
  .menu-toggle
  .deznav
  .metismenu
  li
  > ul
  li:hover
  ul {
  right: 11.8125rem;
  left: 0;
}

[direction='rtl'] .list-group {
  padding-left: 0;
}

[direction='rtl'] .form-check-input {
  margin-left: -1.25rem;
  margin-right: inherit;
}

[direction='rtl'] .form-check-inline .form-check-input {
  margin-right: 0;
  margin-left: 10px;
}

[direction='rtl'] .radio input,
[direction='rtl'] .radio-inline,
[direction='rtl'] .checkbox input,
[direction='rtl'] .checkbox-inline input {
  margin-left: 0;
  margin-right: 0;
}

[direction='rtl'] .content-body {
  margin-right: 17.1875rem;
  margin-left: auto;
}

[data-sidebar-style='modern'][direction='rtl'] .content-body {
  margin-right: 9.375rem;
}

[direction='rtl'] .content-body .page-titles {
  text-align: right;
}

[direction='rtl'][data-layout='horizontal'] .content-body {
  margin-right: 0;
}

[direction='rtl'][data-layout='horizontal']
  .deznav
  .metismenu
  li
  li
  .has-arrow:after {
  -webkit-transform: rotate(-4deg) translateY(-50%);
  transform: rotate(-45deg) translateY(-50%);
}

[direction='rtl'][data-sidebar-style='mini']:not([data-layout='horizontal'])
  .content-body {
  margin-right: 3.75rem;
}

[direction='rtl'][data-sidebar-style='compact']:not([data-layout='horizontal'])
  .content-body {
  margin-right: 9.375rem;
}

[direction='rtl'][data-sidebar-style='overlay'] .content-body {
  margin-right: 0;
}

[direction='rtl'] #external-events .external-event:before {
  margin-right: 0;
  margin-left: 0.9rem;
}

[direction='rtl'] .post-input a i {
  margin-left: 15px;
  margin-right: 0;
}

@media only screen and (max-width: 1350px) and (min-width: 1200px) {
  [direction='rtl'] .content-body {
    margin-right: 14.375rem;
  }
}

[direction='rtl'] .sidebar-right {
  left: -15.625rem;
  right: auto;
  box-shadow: 2px 3px 10px 0px rgba(119, 119, 119, 0.1);
}

[direction='rtl'] .sidebar-right.show {
  left: 0;
  right: auto;
}

[direction='rtl'] .sidebar-right .sidebar-right-trigger {
  left: 100%;
  right: auto;
  border-radius: 0 5px 5px 0;
  box-shadow: 5px 3px 5px 0px rgba(119, 119, 119, 0.15);
}

[direction='rtl'] .bootstrap-select .dropdown-toggle .filter-option {
  text-align: right;
}

html[dir='rtl'] [direction='rtl'] .footer {
  padding-right: 17.1875rem;
  padding-left: 0;
}

@media (max-width: 767.98px) {
  html[dir='rtl'] [direction='rtl'] .footer {
    padding-right: 0;
  }
}

html[dir='rtl'] [direction='rtl'][data-sidebar-style='overlay'] .footer {
  padding-right: 0;
}

html[dir='rtl'] [direction='rtl'] .menu-toggle .footer {
  padding-right: 3.75rem;
}

html[dir='rtl'] [direction='rtl'][data-container='boxed'] .footer {
  padding-right: 0;
}

html[dir='rtl']
  [direction='rtl'][data-sidebar-style='mini']:not([data-layout='horizontal'])
  .footer {
  padding-right: 3.75rem;
}

html[dir='rtl']
  [direction='rtl'][data-sidebar-style='compact']:not([data-layout='horizontal'])
  .footer {
  padding-right: 9.375rem;
}

[data-theme-version='dark'] {
  background: #181f39;
  color: #828690;
}

[data-theme-version='dark'] h1,
[data-theme-version='dark'] h2,
[data-theme-version='dark'] h3,
[data-theme-version='dark'] h4,
[data-theme-version='dark'] h5,
[data-theme-version='dark'] h6 {
  color: #fff !important;
}

[data-theme-version='dark'] a.link {
  color: #ddd;
}

[data-theme-version='dark'] a.link:focus,
[data-theme-version='dark'] a.link:hover {
  color: #1ea7c5;
}

[data-theme-version='dark'] a:hover {
  color: #fff;
}

[data-theme-version='dark'] .border-right {
  border-right: 1px solid #333a54 !important;
}

[data-theme-version='dark'] .border-left {
  border-left: 1px solid #333a54 !important;
}

[data-theme-version='dark'] .border-top {
  border-top: 1px solid #333a54 !important;
}

[data-theme-version='dark'] .border-bottom {
  border-bottom: 1px solid #333a54 !important;
}

[data-theme-version='dark'] .border {
  border: 1px solid #333a54 !important;
}

[data-theme-version='dark'] .dropdown-menu {
  background-color: #181f39;
  box-shadow: 0px 0px 0px 1px rgba(255, 255, 255, 0.1);
}

[data-theme-version='dark'] .dropdown-menu .dropdown-item.selected,
[data-theme-version='dark'] .dropdown-menu .dropdown-item.selected.active,
[data-theme-version='dark'] .dropdown-menu .dropdown-item.active,
[data-theme-version='dark'] .dropdown-menu .dropdown-item:hover,
[data-theme-version='dark'] .dropdown-menu .dropdown-item:focus {
  background-color: #1e2746;
  color: #7e7e7e;
}

[data-theme-version='dark'] .form-control {
  background-color: #181f39;
  border-color: #333a54;
  color: #fff;
}

[data-theme-version='dark'] .card {
  background-color: #1e2746;
  box-shadow: none;
}

[data-theme-version='dark'] .btn-outline-dark:hover {
  background-color: #181f39;
  border-color: #181f39;
}

[data-theme-version='dark'] .tdl-holder input[type='text'],
[data-theme-version='dark'] .tdl-holder li {
  background-color: #1e2746;
  border-color: #333a54;
}

[data-theme-version='dark'] .tdl-holder input[type='text']::placeholder {
  color: #828690;
}

[data-theme-version='dark'] .tdl-holder label:hover {
  background-color: #333a54;
  color: #fff;
}

[data-theme-version='dark'] .text-muted {
  color: #828690 !important;
}

[data-theme-version='dark'] .modal-content {
  background: #1e2746;
}

[data-theme-version='dark'] .modal-footer,
[data-theme-version='dark'] .modal-header {
  border-color: #333a54;
}

[data-theme-version='dark'] .close {
  font-weight: 400;
  color: #fff;
  text-shadow: none;
}

[data-theme-version='dark'] .close:hover {
  color: #fff;
}

[data-theme-version='dark'] .table strong {
  color: #fff;
}

[data-theme-version='dark'] .text-dark {
  color: #828690 !important;
}

[data-theme-version='dark'] .star-rating .product-review,
[data-theme-version='dark'] .new-arrival-content .item {
  color: #fff;
}

[data-theme-version='dark'] .custom-dropdown .dropdown-menu {
  border-color: #333a54;
}

[data-theme-version='dark'] .widget-stat .media > span {
  background: #333a54;
  border-color: #333a54;
  color: #fff;
}

[data-theme-version='dark'] .plus-minus-input .custom-btn {
  background: #181f39;
  border-color: #333a54;
}

[data-theme-version='dark'] .dropdown-divider,
[data-theme-version='dark'] .size-filter ul li {
  border-color: #333a54;
}

[data-theme-version='dark'] .custom-select {
  border-color: #333a54;
  color: #828690;
  background: #181f39;
}

[data-theme-version='dark'] .nav-tabs {
  border-color: #333a54;
}

[data-theme-version='dark'] .mail-list .list-group-item.active i {
  color: #fff;
}

[data-theme-version='dark'] hr {
  border-color: #333a54;
}

[data-theme-version='dark'] .grid-col {
  background: #181f39;
}

[data-theme-version='dark'] .noUi-target {
  border-color: #333a54;
  border-radius: 8px;
  box-shadow: none;
}

[data-theme-version='dark'] .noUi-target .noUi-connects {
  background: #333a54;
}

[data-theme-version='dark'] .noUi-marker-large,
[data-theme-version='dark'] .noUi-marker {
  background: #333a54;
}

[data-theme-version='dark'] .input-group-text {
  background: #333a54;
  color: #7e7e7e;
}

[data-theme-version='dark'] .note-editor.note-frame {
  border-color: #333a54;
}

[data-theme-version='dark'] .note-editor.note-frame .btn {
  color: #fff;
}

[data-theme-version='dark']
  .note-editor.note-frame
  .note-editing-area
  .note-editable {
  color: #fff;
}

[data-theme-version='dark'] .widget-media .timeline .timeline-panel {
  border-color: #333a54;
}

[data-theme-version='dark']
  .notification_dropdown
  .dropdown-menu-right
  .all-notification {
  border-color: #333a54;
}

[data-theme-version='dark'] #user-activity .nav-tabs .nav-link {
  border-color: #333a54;
}

[data-theme-version='dark'] .list-group-item-action {
  color: #7e7e7e;
}

[data-theme-version='dark'] .list-group-item-action:focus,
[data-theme-version='dark'] .list-group-item-action:hover,
[data-theme-version='dark'] .list-group-item-action:focus {
  background-color: #181f39;
  border-color: #181f39;
}

[data-theme-version='dark'] .list-group-item.active {
  color: #fff;
  border-color: #0b2a97;
}

[data-theme-version='dark'] .list-group-item.active:focus,
[data-theme-version='dark'] .list-group-item.active:hover,
[data-theme-version='dark'] .list-group-item.active:focus {
  background-color: #0b2a97;
  border-color: #0b2a97;
  color: #fff;
}

[data-theme-version='dark'] .swal2-popup {
  background: #1e2746;
}

[data-theme-version='dark'] .header {
  background-color: #1e2746;
}

[data-theme-version='dark'] .header-left input {
  background: #181f39 !important;
  border-color: transparent;
  color: #fff !important;
}

[data-theme-version='dark'] .header-left input:focus {
  box-shadow: none;
  border-color: #0b2a97;
}

[data-theme-version='dark'] .header-left input::placeholder {
  color: #fff;
}

[data-theme-version='dark'] .header-left .search_icon {
  background: #181f39 !important;
}

[data-theme-version='dark'] .header-left .search_icon i {
  color: #fff;
}

[data-theme-version='dark'] .header-right .right-sidebar a {
  color: #0b2a97;
  border-color: #333a54;
}

[data-theme-version='dark']
  .header-right
  .header-profile
  > a.nav-link
  .header-info
  strong {
  color: #fff;
}

[data-theme-version='dark'] .header-right .notification_dropdown .nav-link {
  background: #181f39;
}

[data-theme-version='dark'] .header-right .dropdown .nav-link {
  color: #fff;
}

[data-theme-version='dark'] .header-right .dropdown .nav-link:hover {
  color: #fff;
}

[data-theme-version='dark']
  .header-right
  .notification_dropdown
  .dropdown-item
  a {
  color: #fff;
}

[data-theme-version='dark'] .nav-header,
[data-theme-version='dark'] .nav-control {
  background-color: #181f39;
}

[data-theme-version='dark'] .nav-control {
  color: #fff;
}

[data-theme-version='dark'] .brand-logo {
  color: #fff;
}

[data-theme-version='dark'] .brand-logo:hover {
  color: #fff;
}

[data-theme-version='dark'] .nav-header,
[data-theme-version='dark'] .nav-control {
  background-color: #181f39;
}

[data-theme-version='dark'] .nav-control {
  color: #fff;
}

[data-theme-version='dark'] .brand-logo {
  color: #fff;
}

[data-theme-version='dark'] .brand-logo:hover {
  color: #fff;
}

[data-theme-version='dark'][data-sidebar-style='mini']
  .deznav
  .metismenu
  li
  > ul {
  background-color: #1e2746;
}

[data-theme-version='dark'] .fixed-content-box {
  background-color: #1e2746;
}

[data-theme-version='dark'] .fixed-content-box .head-name {
  background: #1e2746;
  color: #fff;
  border-color: #333a54;
}

[data-theme-version='dark'] .fixed-content-box + .header + .deznav {
  background-color: #181f39;
}

[data-theme-version='dark'] .deznav {
  background-color: #1e2746;
}

[data-theme-version='dark'] .deznav .metismenu > li > a {
  color: #b3b3b3;
}

[data-theme-version='dark'] .deznav .metismenu > li:hover > a,
[data-theme-version='dark'] .deznav .metismenu > li:focus > a,
[data-theme-version='dark'] .deznav .metismenu > li.mm-active > a {
  color: #fff;
}

[data-theme-version='dark'] .deznav .metismenu > li:hover > a::after,
[data-theme-version='dark'] .deznav .metismenu > li:focus > a::after,
[data-theme-version='dark'] .deznav .metismenu > li.mm-active > a::after {
  border-color: #fff;
}

[data-theme-version='dark'] .deznav .metismenu > li.mm-active ul ul {
  background-color: transparent;
}

[data-theme-version='dark'] .deznav .metismenu ul:after {
  background-color: #333a54;
}

[data-theme-version='dark'] .deznav .metismenu ul a:hover,
[data-theme-version='dark'] .deznav .metismenu ul a:focus,
[data-theme-version='dark'] .deznav .metismenu ul a.mm-active {
  color: #fff;
}

[data-theme-version='dark'] .deznav .metismenu ul a:before {
  background-color: #fff;
}

[data-theme-version='dark'] .deznav .metismenu a {
  color: #b3b3b3;
}

[data-theme-version='dark'] .deznav .metismenu .has-arrow:after {
  border-color: #fff;
}

[data-theme-version='dark'] .sidebar-right {
  background-color: #1d2544;
}

[data-theme-version='dark'] .sidebar-right .nav-tabs {
  background-color: #1d2544;
  border-bottom: 1px solid #181f39;
}

[data-theme-version='dark'] .sidebar-right .nav-tabs .nav-link.active {
  background-color: #181f39;
}

[data-theme-version='dark'] .footer {
  background-color: transparent;
}

[data-theme-version='dark'] .footer .copyright {
  background-color: transparent;
}

[data-theme-version='dark'] .footer .copyright p {
  color: #fff;
}

.deznav .metismenu > li {
  display: flex;
  flex-direction: column;
  padding: 0 0 0 40px;
}

.inner_slider_box {
  position: relative;
  background: #0b2a97;
  display: flex;
  padding: 42px 0 56px 32px;
  color: #ffffff;
  border-radius: 24px;
  margin-bottom: 20px;
  height: 400px;
}

.inner_slider_box p {
  color: inherit;
  margin-bottom: 1rem;
}

.inner_slider_box::before,
.inner_slider_box::after {
  content: '';
  position: absolute;
  background: #cfc8ff;
  border-radius: 24px;
}

.inner_slider_box::before {
  top: 8px;
  left: 8px;
  right: 8px;
  bottom: -8px;
  z-index: -1;
}

.inner_slider_box::after {
  top: 16px;
  left: 20px;
  right: 20px;
  bottom: -16px;
  z-index: -2;
}

.btn-txt:hover {
  color: #0b2a97;
}

.cus_owl_theme {
  position: relative;
}

.cus_owl_theme .owl-nav {
  display: none;
}

.cus_owl_theme .owl-dots {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 30px;
}

.nav-header .logo-abbr {
  margin-right: 5px;
}

.nav-header .brand-logo {
  font-size: 2rem;
  color: #0b2a97;
}

.db_lft_grid .card {
  height: calc(100% - 15px);
}

.db_lft_grid .widget-stat .media .media-body p {
  font-weight: 600;
}

.number_box {
  font-size: 170px;
  line-height: 1;
  color: rgba(58, 48, 48, 0.11);
  display: inline-block;
  position: absolute;
  z-index: 0;
  left: 0;
  font-weight: 500;
  top: 9px;
  margin-top: -25px;
  font-family: 'Poppins', sans-serif;
}

.widget-stat .media .media-body h4 {
  color: #000;
}

.user_main_icon_box svg {
  color: #0b2a97 !important;
}

.user_main_icon_box img {
  width: 50px;
  height: 50px;
  background: #fff;
  border-radius: 50%;
  margin-right: 10px;
}

.vehicles_sec .widget-stat .media > span {
  height: 100px;
  width: 100px;
}

/*---------signup-step-------------*/

.bg-color {
  background-color: #333;
}

.signup-step-container {
  padding: 150px 0px;
  padding-bottom: 60px;
}

.wizard .nav-tabs {
  position: relative;
  margin-bottom: 0;
  border-bottom-color: transparent;
}

.wizard > div.wizard-inner {
  position: relative;
  margin-bottom: 50px;
  text-align: left;
}

.connecting-line {
  height: 2px;
  background: #e0e0e0;
  position: absolute;
  width: 80%;
  margin: 0 auto;
  left: 0;
  top: 40px;
  z-index: 1;
}

.wizard .nav-tabs > li.active > a,
.wizard .nav-tabs > li.active > a:hover,
.wizard .nav-tabs > li.active > a:focus {
  color: #555555;
  cursor: default;
  border: 0;
  border-bottom-color: transparent;
}

span.round-tab {
  width: 80px;
  height: 80px;
  line-height: 30px;
  display: inline-block;
  border-radius: 50%;
  background: #0b2a97;
  z-index: 2;
  position: relative;
  left: 0;
  text-align: center;
  font-size: 16px;
  color: #fff;
  font-weight: 500;
  border: 1px solid #0b2a97;
  display: flex;
  justify-content: center;
  align-items: center;
}

span.round-tab i {
  color: #555555;
}

.wizard li.active span.round-tab {
  background: #0b2a97;
  color: #fff;
  border-color: #0b2a97;
}

.wizard li.active span.round-tab i {
  color: #5bc0de;
}

.wizard .nav-tabs > li.active > a i {
  color: #0db02b;
}

.wizard .nav-tabs > li {
  width: 20%;
}

.wizard li:after {
  content: ' ';
  position: absolute;
  left: 46%;
  opacity: 0;
  margin: 0 auto;
  bottom: 0px;
  border: 5px solid transparent;
  border-bottom-color: red;
  transition: 0.1s ease-in-out;
}

.wizard .nav-tabs > li a {
  width: 30px;
  height: 30px;
  margin: 20px auto;
  border-radius: 100%;
  padding: 0;
  background-color: transparent;
  position: relative;
  top: 0;
}

.wizard .nav-tabs > li a i {
  position: absolute;
  top: -15px;
  font-style: normal;
  font-weight: 400;
  white-space: nowrap;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 12px;
  font-weight: 700;
  color: #000;
}

.wizard .nav-tabs > li a:hover {
  background: transparent;
}

.wizard .tab-pane {
  position: relative;
  padding-top: 20px;
}

.wizard h3 {
  margin-top: 0;
}

.range_slider_txt {
  display: inline-block;
  border-radius: 50%;
  z-index: 2;
  text-align: center;
  font-size: 16px;
  color: #0a0a0a;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 115px;
  margin-top: 10px;
}

.height-40 {
  height: 40px;
}

.leads_row .widget-stat .media > span {
  height: 100px;
  width: 100px;
}

.leads_row .widget-stat .media > span img {
  width: 100%;
}

.cus_container {
  padding: 0 10px !important;
}

.inner_img_slider {
  display: flex;
  justify-content: center;
  align-items: center;
}

.item_box {
  position: relative;
}

.cross_icon {
  position: absolute;
  z-index: 1;
  right: 32px;
  top: 10px;
  width: 40px;
  height: 40px;
  background: #fff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cross_icon i {
  color: #0b2a97;
}

.gp_btn {
  background: #0b2a97 !important;
  border-radius: 15px !important;
  color: #fff;
  padding: 3px 11px !important;
  font-size: 14px;
  border: 1px solid #0b2a97 !important;
  transition: 0.25s;
  margin-top: 10px;
}

.gp_btn:hover {
  border: 1px solid #0b2a97 !important;
  background: #fff !important;
  color: #0b2a97;
}

.form-control,
.form-control:hover,
.form-control:focus,
.form-control.active {
  border: 1px solid #c1c1c1;
  color: #1a1a1a;
}

a {
  cursor: pointer;
}

/* emi Calculator design start here  */

.emi_row .widget-stat {
  border-radius: 5px !important;
}

.emi_row .form-control,
.emi_row .input-group-text {
  border-radius: 5px;
  padding: 0.5rem 0.75rem;
}

.mo_input {
  border-left: 1px solid #c1c1c1;
}

.emi_row .bg-custom-blue {
  background-color: #6aacff !important;
  border-radius: 0px !important;
}
.emi_row .bg-custom-red {
  background-color: red !important;
  border-radius: 3px !important;
  padding: 15px 10px;
}

.color_9d {
  color: #6aacff;
}

.border_bottom_cus {
  border-bottom: 1px solid #ddd;
}

.p-cus {
  padding: 10px 1.875rem !important;
}

.w-260 {
  width: 100%;
  max-width: 260px !important;
}

.emi_cal_txt {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

ul.nav.nav-tabs {
  border: none;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  background: #0b2a97;
  color: #fff;
  border-radius: 0.25rem !important;
  margin-bottom: 0;
}

li.nav-item {
  margin-right: 5px;
}

.nav-tabs .nav-link {
  border: 1px solid #e9ecef;
  border-radius: 0.25rem !important;
  margin-bottom: 0;
}

.input_cus_radio {
  transform: scale(1.5);
}

.custom-file-label {
  height: 55px;
  padding: 15px;
  border-radius: 0;
}

.custom-file-label:after {
  background: #656c73;
  border: 1px solid #f0f1f5;
  color: #fff;
  border-radius: 0;
  height: 55px;
  display: flex;
  align-items: center;
}

.two_btns_ps {
  position: absolute;
  right: 20px;
}

.emi_two_btns_ps {
  position: absolute;
  right: 20px;
  top: 0px;
}

.two_btns_ps button,
.emi_two_btns_ps button {
  border-radius: 10px;
}

#root,
#main-wrapper {
  /* overflow-x: hidden !important;
overflow-y: scroll; */
  display: flex;
  flex-direction: column;
  overflow: inherit;
}

html,
#root,
#main-wrapper {
  height: 100%;
}

.content-body {
  flex: 1 1 auto;
}

.widget-stat.signup_card {
  background: #fff !important;
}

.widget-stat.signup_card .col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
  color: #0b2a97;
  font-weight: 600;
}

.widget-stat.signup_card button {
  width: 100%;
  background: #0b2a97;
  color: #fff;
  padding: 10px 0 !important;
  border-radius: 10px;
  font-weight: 600;
  font-size: 14px;
}

.cus_td {
  padding: 0px !important;
  border-top: none !important;
}

.vs-hi {
  visibility: hidden !important;
  display: none;
}

[aria-expanded='true'] i.fa.fa-plus {
  display: none;
}

[aria-expanded='false'] i.fa.fa-minus {
  display: none;
}

.icon_show {
  display: flex;
}

.table_cus_52 {
  table-layout: fixed;
}

/* .two_btns_ps .btn-primary {
color: #fff;
background-color: #007bff;
border-color: #007bff;
} */

.steps {
  border-color: transparent;
  border-style: none solid;
  border-width: 0 1px;
  margin: 0 0 35px !important;
  position: relative;
  width: 100%;
  top: -9px;
}

.marker,
.tick {
  text-align: center;
}

.tick {
  color: #828282;
  font-size: 11px;
  height: 11px;
  left: 0;
  line-height: 11px;
  position: absolute;
}

.tick-1 {
  margin-left: -0.3%;
}

.tick-2 {
  margin-left: 23%;
}

.tick-3 {
  margin-left: 49%;
}

.tick-4 {
  margin-left: 73%;
}

.tick-5 {
  margin-left: 98%;
}

/* emi Calculator design end here  */

.table_th th span {
  position: relative;
  display: inline-block;
}

.table_th th span:after {
  content: '\f107';
  position: absolute;
  right: -20px;
  top: 0;
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  align-items: center;
  font-size: 18px;
  bottom: 0;
}

@media screen and (max-width: 1490px) and (min-width: 1200px) {
  .admin-dash-box [class|='col'] {
    flex: 0 0 33.33%;
    max-width: 33.33%;
  }
}

@media (min-width: 992px) and (max-width: 1300px) {
  [data-sidebar-position='fixed'][data-layout='vertical'] .nav-header,
  [data-sidebar-style='mini'] .deznav {
    width: 14rem;
  }
  [data-sidebar-style='mini'] .deznav .nav-text {
    display: inline-block;
  }
  .deznav .metismenu > li a > i {
    font-size: 1.2rem;
    display: inline-block;
    vertical-align: middle;
    position: relative;
    top: 0;
    height: auto;
    width: auto;
    border-radius: 1.25rem;
    line-height: 1;
    text-align: center;
    transition: all 0.5s;
    margin-right: 10px;
    width: 25px;
  }
  [data-sidebar-style='mini'] .content-body {
    margin-left: 14rem;
  }
  [data-sidebar-style='mini'] .nav-control,
  [data-layout='horizontal'] .nav-control,
  [data-sidebar-style='mini'] .nav-header .hamburger {
    display: block;
  }
  [data-sidebar-style='mini'] .header {
    width: 100%;
    padding-left: 16rem;
  }
  .nav-control {
    cursor: pointer;
    position: absolute;
    right: 1.938rem;
    text-align: center;
    top: 50%;
    transform: translateY(-59%);
    z-index: 9999;
    font-size: 1.4rem;
    padding: 2px 0.5rem 0;
    border-radius: 2px;
  }
  [data-sidebar-style='mini'] .nav-header .nav-control .hamburger .line {
    background-color: #0b2a97 !important;
    background-color: #0b2a97 !important;
  }
  [data-sidebar-style='mini'] .deznav .metismenu > li {
    padding: 2px 0px 2px 6px;
  }
}

.min-height-cus {
  min-height: 70px;
}

.download-icon i {
  color: #0b2a97;
  font-size: 20px;
}

.w-30 {
  width: 30%;
}

.custom-wizard.wizard .nav-tabs > li {
  width: 25% !important;
}

.glyphicon {
  border-radius: 50%;
  border: 2px #ddd solid;
  padding: 20px;
  font-size: 24px;
  background-color: #fff;
  color: #999;
}

.glyphicon:hover {
  border-radius: 50%;
  color: #fff;
  background-color: #ddd;
}

.line {
  max-width: 55%;
  min-height: 2px;
  background-color: #ddd;
  margin-top: 70px;
  margin-bottom: -35px;
  margin-left: auto;
  margin-right: auto;
}

.ttm-processbox-wrapper {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  position: relative;
}

.ttm-processbox-wrapper .ttm-processbox {
  position: relative;
  display: block;
  text-align: center;
  width: 180px;
  height: 180px;
  background: #fff;
  padding: 15px;
  border-radius: 50%;
  margin: auto;
  box-shadow: 0 10px 50px 0 rgba(29, 79, 206, 0.1);
  -webkit-box-shadow: 0 10px 50px 0 rgba(29, 79, 206, 0.1);
  -moz-box-shadow: 0 10px 50px 0 rgba(29, 79, 206, 0.1);
  transition: all 500ms ease;
}

.ttm-processbox-wrapper .ttm-processbox .process-num {
  margin-right: 5px;
  font-size: 16px;
  font-weight: 500;
  background-color: #000;
  color: #fff;
  width: 46px;
  height: 46px;
  line-height: 46px;
  border-radius: 50%;
  border: none;
  display: block;
  text-align: center;
  position: absolute;
  left: auto;
  right: 30px;
  top: 20px;
  z-index: 10;
}

.ttm-processbox-wrapper .featured-content {
  position: relative;
  border-radius: 50%;
  padding: 55px 15px 60px 15px;
  width: 150px;
  height: 150px;
  border: 6px solid #e8ecef;
}

.ttm-processbox-wrapper .ttm-processbox .featured-title h5 {
  font-size: 20px;
  line-height: 27px;
  margin-bottom: 15px;
}

.ttm-processbox-wrapper .ttm-processbox:hover {
  box-shadow: 0 -10px 39px 0 rgba(8, 18, 109, 0.1);
  -webkit-box-shadow: 0 -10px 39px 0 rgba(8, 18, 109, 0.1);
  -moz-box-shadow: 0 -10px 39px 0 rgba(8, 18, 109, 0.1);
  transform: translateY(-10px);
}

.ttm-processbox-wrapper.style1 .ttm-processbox {
  position: relative;
  display: block;
  text-align: center;
  width: 218px;
  height: 132px;
  background: #fff;
  padding: 0px 0 0;
  border-radius: 0;
  margin: 0 auto;
  box-shadow: 0 0px 300px 0 rgb(0 0 0 / 18%);
  transition: all 500ms ease;
}

.ttm-processbox-wrapper.style1 .ttm-processbox:hover {
  transform: translateY(-10px);
}

.ttm-processbox-wrapper.style1 .ttm-processbox:after {
  content: '';
  position: absolute;
  bottom: -60.8675px;
  left: 0;
  width: 0;
  height: 0;
  border-left: 110px solid transparent;
  border-right: 110px solid transparent;
  border-top: 60.8675px solid #fff;
}

.ttm-processbox-wrapper.style1 .ttm-processbox:before {
  content: '';
  position: absolute;
  top: -60.8675px;
  left: 0;
  width: 0;
  height: 0;
  border-left: 110px solid transparent;
  border-right: 110px solid transparent;
  border-bottom: 60.8675px solid #fff;
}

.ttm-processbox-wrapper.style1 .ttm-icon {
  height: 64px;
  width: 64px;
  line-height: 64px;
  margin-bottom: 11px;
}

.ttm-processbox-wrapper.style1 .ttm-icon i {
  font-size: 70px;
}

.ttm-processbox-wrapper.style1 .featured-content {
  position: relative;
  border-radius: 0;
  padding: 0px 0px 10px;
  width: 100%;
  height: auto;
  border: 0;
}

.ttm-processbox-wrapper.style1:hover .featured-icon {
  position: relative;
  z-index: 111;
  background-color: #fff;
}

.ttm-processbox-wrapper.style1 .process-num {
  color: #f5faff;
  position: absolute;
  top: -35px;
  right: -25px;
  font-size: 90px;
  line-height: 0;
  font-weight: 600;
  width: auto;
  height: auto;
  margin-right: 0;
  z-index: -1;
}

.ttm-progress-bar {
  position: relative;
}

.ttm-progress-bar {
  margin: 0 0 15px 0;
}

.ttm-progress-bar:not(:last-child) {
  margin-bottom: 30px;
}

.ttm-progress-bar .progress .progress-bar.progress-bar-color-bar_black {
  background-color: #414141;
}

.ttm-progress-bar h4 {
  top: -35px;
  left: 0;
  font-size: 17px;
  color: #5d6576;
  font-weight: 400;
  margin-bottom: 10px;
  letter-spacing: 1.2px;
}

.ttm-progress-bar i {
  position: absolute;
  font-size: 21px;
}

.ttm-progress-bar .progress {
  background-color: #c0c0c1;
  border-radius: 0;
  height: 7px;
}

.ttm-progress-bar .progress-parcent {
  position: absolute;
  right: 0;
  color: #0a0a0a;
  z-index: 3;
  text-shadow: none;
  font-size: 17px;
  padding: 2px 0;
  border-radius: 0;
  top: 0;
}

.ttm-icon-left-true.ttm-progress-bar h4 {
  margin-left: 35px;
}

.ttm-progress-bar.ttm-icon-left-true {
  margin-bottom: 24px;
}

.ttm-processbox-wrapper .ttm-processbox:hover .featured-content {
  border-color: #0b2a97 !important;
}

.step_btn_cus {
  display: block;
  width: 180px;
  color: #6aacff;
  text-align: center;
  margin: 5px auto;
  padding: 10px;
  border-radius: 10px;
  font-size: 18px;
  font-weight: 500;
}

.step_btn_cus1 {
  background: #0b2a97;
  width: 180px;
  display: block;
  text-align: center;
  margin: 5px auto 0;
  color: #fff;
  padding: 5px;
  border-radius: 5px;
  border: 1px solid #0b2a97 !important;
}

.step_btn_cus1:hover {
  background: #fff !important;
  color: #0b2a97;
  border: 1px solid #0b2a97 !important;
}

.ttm-processbox-wrapper .ttm-processbox .process-num {
  background-color: #0b2a97;
}

.ttm-processbox-wrapper .ttm-processbox:hover .process-num {
  background-color: #0b0c26;
}

.deznav .deznav-scroll {
  overflow-y: scroll;
}

/* .deznav .deznav-scroll::-webkit-scrollbar {
  display: none;
} */

.deznav .deznav-scroll {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.line {
  max-width: 100%;
  margin-right: 0;
}

.table_car_img {
  width: 100%;
  max-width: 31px;
}

/* 2april design strat here */

.otp_form > div {
  justify-content: center;
}

.otp_form div span {
  color: #0b2a97;
}

.bg-custom-blue {
  padding: 15px;
}

.bg-custom-blue button {
  padding: 5px 15px;
  border-radius: 3px;
  font-size: 14px;
  color: #0b2a97;
}

.bg-custom-blue > h4 {
  font-size: 18px;
}
.bg-custom-red > h4 {
  font-size: 18px;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: '';
}

.deznav .metismenu > li:hover > a {
  background: #0b2a97;
  color: #fff;
  border-radius: 0 !important;
  border-top-left-radius: 1.25rem !important;
  border-bottom-left-radius: 1.25rem !important;
  position: unset;
}

.shutter-in-vertical {
  -webkit-transition: color 300ms;
  transition: color 300ms;
  position: relative;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -moz-osx-font-smoothing: grayscale;
}

.shutter-in-vertical:before {
  content: '';
  position: absolute;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #fff;
  -webkit-transform: scaleY(1);
  transform: scaleY(1);
  -webkit-transform-origin: 50%;
  transform-origin: 50%;
  -webkit-transition-property: transform;
  transition-property: transform;
  -webkit-transition: 300ms ease-out;
  transition: 300ms ease-out;
  border-radius: 15px;
}

.shutter-in-vertical:hover:before {
  -webkit-transform: scaleY(0);
  transform: scaleY(0);
}

.shutter-out-vertical {
  -webkit-transition: color 300ms;
  transition: color 300ms;
  position: relative;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -moz-osx-font-smoothing: grayscale;
}

.shutter-out-vertical:before {
  content: '';
  position: absolute;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #2098d1;
  -webkit-transform: scaleY(0);
  transform: scaleY(0);
  -webkit-transform-origin: 50%;
  transform-origin: 50%;
  -webkit-transition-property: transform;
  transition-property: transform;
  -webkit-transition: 300ms ease-out;
  transition: 300ms ease-out;
}

.shutter-out-vertical:hover:before {
  -webkit-transform: scaleY(1);
  transform: scaleY(1);
}

.shutter-in-vertical {
  background-color: #cfc8ff40 !important;
}

.custom-check-design input[type='checkbox'] {
  visibility: hidden;
  display: none;
  position: absolute;
}

.custom-check-design input[type='checkbox'] + .check-label::before {
  content: '';
  border: 1px solid #0b2a97;
  width: 25px;
  height: 25px;
  display: inline-block;
  position: absolute;
  left: 0;
  border-radius: 2px;
}

.custom-check-design input[type='checkbox'] + .check-label {
  padding-left: 30px;
  position: relative;
}

.custom-check-design input[type='checkbox']:checked + .check-label::after {
  opacity: 1;
}

.custom-check-design input[type='checkbox'] + .check-label::after {
  content: '';
  display: block;
  position: absolute;
  top: 4px;
  left: 10px;
  width: 6px;
  height: 14px;
  border: 1px solid #0b2a97;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  opacity: 0;
}

.custom-file-label {
  border: 1px solid #c1c1c1;
  border-radius: 5px;
}

.custom-file-label::after {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.btn-achor {
  width: 85px;
  height: 85px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto auto 20px;
}

.btn-call {
  background: #fff3f7;
  border-color: #fff3f7;
}

.btn-call i {
  color: #dd3c4b;
  font-size: 30px;
}

.btn-location i {
  color: #2bc155;
  font-size: 30px;
}

.btn-email i {
  color: #f90;
  font-size: 30px;
}

.textarea_box {
  width: 100%;
  min-height: 200px;
  resize: none;
  border-radius: 5px !important;
  border: 1px solid #e2e2e2;
}

.theme-btn {
  width: 100%;
  max-width: 190px;
}

.custom-file {
  height: auto !important;
}

.doc_image {
  width: 100%;
  max-width: 80px;
}

.modal-backdrop.show {
  opacity: 0.3;
}

.hdoc-bg {
  background: #0b2a97;
  padding: 10px;
  color: #fff;
  border-radius: 5px;
}

.close {
  position: absolute;
  right: 21px;
  top: 20px;
  z-index: 99;
}

.borderless td,
.borderless th {
  border: none !important;
}

.ms-btn {
  cursor: pointer;
}

.pi-chart-box svg {
  width: 100%;
  max-width: 350px;
}

.tp-cus-btn {
  width: 100%;
  max-width: 115px;
}

@media screen and (max-width: 1600px) {
  .ttm-processbox-wrapper .ttm-processbox {
    width: 160px;
    height: 160px;
  }
  .ttm-processbox-wrapper .featured-content {
    position: relative;
    border-radius: 50%;
    padding: 55px 15px 60px 15px;
    width: 130px;
    height: 130px;
    border: 6px solid #e8ecef;
  }
  .ttm-processbox-wrapper .ttm-processbox .process-num {
    font-size: 16px;
    line-height: 36px;
    height: 36px;
    width: 36px;
  }
  .ttm-processbox-wrapper .featured-content {
    position: relative;
    border-radius: 50%;
    padding: 44px 15px 60px 15px;
    width: 130px;
    height: 130px;
    border: 6px solid #e8ecef;
  }
  .ttm-processbox-wrapper.justify-content-center {
    justify-content: space-around !important;
  }
  .user_main_icon_box {
    width: 40px;
    height: 40px;
  }
  .shutter-in-vertical h5 {
    font-size: 16px;
  }
  .deznav .metismenu > li > a {
    font-size: 14px !important;
    padding: 0.813rem 0.8rem 0.813rem 0.875rem !important;
  }
  .ttm-processbox h6 {
    font-size: 14px;
  }
}

@media screen and (max-width: 1310px) {
  .ttm-processbox-wrapper.justify-content-center {
    justify-content: space-around !important;
  }
  .theme-px-0 {
    padding: 0px !important;
  }
}

@media screen and (max-width: 1200px) {
  .ttm-processbox-wrapper .ttm-processbox {
    width: 140px;
    height: 140px;
  }
  .ttm-processbox-wrapper .ttm-processbox .process-num {
    font-size: 12px;
    line-height: 36px;
  }
  .ttm-processbox-wrapper .featured-content {
    width: 110px;
    height: 110px;
  }
  .featured-title h6,
  .featured-title p {
    font-size: 12px;
  }
  .ttm-processbox-wrapper .ttm-processbox .process-num {
    top: 10px;
  }
  .ttm-processbox-wrapper .featured-content {
    padding: 30px 15px 30px 15px;
  }
}

@media screen and (max-width: 1050px) {
  .ttm-processbox-wrapper .ttm-processbox {
    width: 125px;
    height: 125px;
    padding: 8px;
  }
  .ttm-processbox-wrapper .ttm-processbox .process-num {
    top: 0px;
    right: 10px;
  }
}

@media screen and (min-width: 768px) {
  .ttm-processbox-wrapper:before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    height: 100%;
    display: block;
    background-image: url(/assets/images/proccess-box-arrow.png);
    top: -35px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100% 100%;
  }
  .ttm-processbox-wrapper2::before {
    top: -21px !important;
  }
}

@media screen and (min-width: 993px) {
  .header .header-content {
    padding-left: 1.313rem;
  }
  .nav-header .nav-control {
    display: none !important;
  }
}

@media screen and (max-width: 992px) {
  [data-sidebar-style='mini'] .nav-header .logo-abbr {
    display: block;
    width: 100%;
  }
  .table_cus_52 {
    table-layout: auto;
  }
  .nav-header .brand-logo {
    justify-content: flex-end;
  }
  .nav-header .logo-abbr {
    max-width: 85px;
  }
  [data-sidebar-style='mini'] .nav-header .nav-control {
    z-index: 1;
  }
  [data-sidebar-style='overlay'] .deznav {
    left: -100%;
  }
  .deznav.menu-toggle-show {
    left: 0px;
  }
  .deznav .metismenu > li {
    display: flex;
    flex-direction: column;
    padding: 0 0 10px 10px;
  }
  .deznav {
    box-shadow: 0px 10px 10px rgba(120, 130, 140, 0.13);
  }
  [data-sidebar-style='mini'] .deznav {
    width: 18rem;
  }
  .deznav .metismenu > li a > i {
    font-size: 1.25rem;
    padding: 0 0.75rem 0 0;
    margin-right: 0px;
    width: 40px;
  }
  [data-sidebar-style='mini'] .nav-control,
  [data-layout='horizontal'] .nav-control {
    display: block;
  }
  [data-sidebar-style='mini'] .nav-header .hamburger {
    display: block !important;
  }
  [data-sidebar-style='mini'] .nav-header .nav-control .hamburger {
    left: 1rem !important;
  }
  [data-sidebar-style='mini'] .nav-header .nav-control .hamburger .line {
    background-color: #0b2a97 !important;
  }
  .deznav {
    left: -100%;
    transition: ease 0.25s;
  }
  [data-sidebar-style='mini'] .content-body {
    margin-left: 0 !important;
  }
  [data-sidebar-style='mini'] .nav-header .brand-logo {
    justify-content: flex-end;
  }
  .piechat-box svg {
    width: 100%;
    max-width: 250px;
  }
  .header-left .dashboard_bar {
    display: none;
  }
  [data-sidebar-position='fixed'][data-layout='vertical'] .nav-header {
    width: 55%;
    display: flex;
    align-items: center;
  }
  .nav-header .nav-control {
    position: absolute;
    transform: initial;
    top: auto;
    right: auto;
    left: 10px;
  }
  [data-sidebar-style='mini'] .deznav .metismenu > li {
    padding: 2px 0px 2px 15px;
  }
}

@media screen and (max-width: 768px) {
  .ttm-processbox-wrapper .step_btn_cus1 {
    width: 100%;
    max-width: 140px;
    min-width: inherit;
  }
  .dataTables_info,
  .dataTables_paginate {
    text-align: center;
  }
  .table td {
    padding: 12px 10px;
  }
}

@media screen and (max-width: 767px) {
  .ttm-processbox-wrapper .ttm-processbox {
    width: 270px;
    height: 270px;
    padding: 20px;
  }
  .ttm-processbox-wrapper .featured-content {
    padding: 90px 15px 60px 15px;
    width: 230px;
    height: 230px;
  }
  .featured-title h6,
  .featured-title p {
    font-size: 16px;
  }
  .ttm-processbox-wrapper .ttm-processbox .process-num {
    top: 15px;
    right: 50px;
    height: 54px;
    width: 54px;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .widget-media .timeline .timeline-panel {
    position: relative;
    padding: 0px 0px 30px;
  }
  .timeline-panel .time {
    position: absolute;
    right: 0;
    bottom: 10px;
  }
}

@media screen and (max-width: 578px) {
  .inner_slider_box {
    height: auto !important;
  }
  .owl-carousel .owl-item img {
    display: block;
    width: 100% !important;
    max-width: 190px;
    margin: 0 auto;
    height: 250px;

  }
  .mw-100 {
    width: 100%;
    max-width: 100%;
  }
  .emi-tabs .nav-tabs .nav-item {
    width: 48%;
    margin-bottom: 10px;
  }
  .ttm-processbox-wrapper .ttm-processbox {
    width: 270px;
    height: 270px;
    padding: 20px;
  }
  .ttm-processbox-wrapper .featured-content {
    padding: 90px 15px 60px 15px;
    width: 230px;
    height: 230px;
  }
  .featured-title h6,
  .featured-title p {
    font-size: 16px;
  }
  .ttm-processbox-wrapper .ttm-processbox .process-num {
    top: 15px;
    right: 50px;
    height: 54px;
    width: 54px;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  [data-sidebar-position='fixed'][data-layout='vertical'] .nav-header {
    width: 60%;
  }
  .inner_slider_box {
    padding: 42px 0 26px 32px;
  }
  .inner_img_slider {
    display: none;
  }
  .table td {
    padding: 12px 15px;
  }
  .theme-mw-100 {
    max-width: 100% !important;
  }
  .dataTables_wrapper .dataTables_paginate span {
    width: 100%;
  }
  .tab-box-cus > ul li {
    margin-bottom: 10px !important;
    width: 48%;
  }
  .emi_cal_txt {
    justify-content: center;
  }
  .emi-cal-box {
    display: flex;
    justify-content: center;
  }
  .pi-chart-box svg {
    width: 100%;
    max-width: 300px;
  }
  .emi_two_btns_ps {
    position: inherit;
    left: auto;
    top: auto;
    display: flex;
    justify-content: flex-end;
  }
  .custom-check-design .w-110 {
    width: 40%;
  }
}

.error {
  margin-bottom: 20px;
  color: red;
  font-size: 16px;
  margin-top: 13px;
  /* padding: inherit; */
}

.successMag {
  color: green;
  font-size: 18px;
  margin-left: 10px;
  margin-top: 6px;
}

.content-body {
  min-height: auto !important;
}

/* .size {
padding-left: 100em;
padding-top: 12px;
} */

/* .download-icon i {
color: #0b2a97;
font-size: 20px;
} */

.badge {
  cursor: pointer;
}

.emi-colr-box {
  width: 20px;
  height: 20px;
  background: #c13c37;
  margin-left: 10px;
}

.emi-colr-box1 {
  width: 20px;
  height: 20px;
  background: #e38627;
  margin-left: 10px;
}

.emi-colr-box2 {
  width: 30px;
  height: 30px;
  background: #6a2135;
  margin-left: 10px;
}

.mo_input_selected {
  border-left: 1px solid #c1c1c1;
  color: #fff;
  background-color: #0062cc;
}

.custom-file-label {
  border: 1px solid #c1c1c1;
  border-radius: 5px;
  height: 57px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.form-control-range {
  padding: 4px 0px;
}

.emi_row .yr-sel {
  border-bottom-right-radius: 0px;
  border-top-right-radius: 0px;
}

.emi_row .mon-sel {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

.ms-form .tick-1 {
  margin-left: -1.3%;
}

.ms-form .tick-2 {
  margin-left: 22%;
}

.ms-form .tick-3 {
  margin-left: 48%;
}

.ms-form .tick-4 {
  margin-left: 72%;
}

.ms-form .tick-5 {
  margin-left: 97%;
}

.pi-chart-box svg {
  fill: #fff !important;
}

.table tbody tr td {
  white-space: nowrap;
}

.check-label {
  line-height: 25px;
  margin-left: 5px;
}

.bgl-blue {
  background: #e2e8fd;
}

.bgl-pink {
  background: #f4e1f7;
}

.bgl-yellow {
  background: #fdf8e2;
}

select.form-control {
  display: inline-block;
  width: 100%;
  padding: 10px 40px 10px 10px;
  font-weight: 400;
  line-height: 1.5;
  color: #6f7172;
  vertical-align: middle;
  background: #fff url('/assets/images/down-arrow.svg') no-repeat right 0.75rem
    center/18px 35px !important;
  background-color: rgb(255, 255, 255);
  background-color: rgba(0, 0, 0, 0);
  background-color: rgba(0, 0, 0, 0);
  background-color: #fff;
  border: 1px solid #c1c1c1;
  border-radius: 0.25rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.left-sub-tile {
  text-align: center;
  display: block;
  width: 100%;
  max-width: 100%;
  color: #808080;
  font-weight: 500;
}

.cus-header-left {
  flex-direction: column;
  justify-content: center;
}

.input-main-box {
  position: relative;
  margin-right: 25px;
}

.input-main-box input {
  width: 100%;
  padding: 8px 15px !important;
}

.input-main-box i {
  position: absolute;
  right: 11px;
  top: 50%;
  transform: translateY(-50%);
}

.deal-slider .inner_slider_box {
  height: 460px;
}

.bgl-new {
  background: #ffff002e;
}

.bg-dark-cus {
  background-color: #0b2a97 !important;
  border-radius: 5px;
  padding: 3px 14px;
  border: 1px solid #0b2a97 !important;
  transition: 0.25s;
  font-size: 14px;
}

.bg-dark-cus:hover {
  border: 1px solid #0b2a97 !important;
  background: #fff !important;
  color: #0b2a97 !important;
}

.x_car_offer_main_boxes_wrapper {
  background: #ffffff;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  box-shadow: 0px 0 8px rgb(0 0 0 / 10%);
  margin-top: 30px;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -moz-transition: all 0.5s;
  transition: all 0.5s;
  padding: 0px 0px 5px;
  overflow: hidden;
}

.x_car_offer_img,
.x_car_offer_price {
  text-align: center;
}

.holder {
  padding: 13px 16px 16px;
}

.holder .title {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 0;
}

.holder .price {
  margin: 2px 0 0;
  font-size: 18px;
  line-height: 24px;
  margin: 5px 0 0;
  position: relative;
}

.holder .buttonHolder {
  margin-top: 16px;
}

.x_car_offer_img,
.x_car_offer_price {
  text-align: center;
}

.primaryButton:hover,
.btn-col-cus {
  border: 1px solid #6aacff;
  background: #6aacff;
  color: #fff;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 36px;
  height: 40px;
  width: 100%;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  cursor: pointer;
  display: inline-block;
  text-align: center;
  max-width: 245px;
  transition: all 0.4s ease;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  padding: 0 5px;
}

.primaryButton,
.btn-col-cus:hover {
  border: 1px solid #6aacff;
  background: #fff;
  color: #6aacff;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 36px;
  height: 40px;
  width: 100%;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  cursor: pointer;
  display: inline-block;
  text-align: center;
  max-width: 245px;
  transition: all 0.4s ease;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  padding: 0 5px;
}

.x_car_offer_main_boxes_wrapper:hover .x_car_offer_img {
  -webkit-animation: movebounce 2.9s linear infinite;
  animation: movebounce 2.9s linear infinite;
}

.link-txt {
  transition: 0.25s;
}

.link-txt:hover {
  color: #0b2a97;
}

.cus-update-btn {
  border-radius: 5px;
  padding: 5px 10px;
  background-color: #fff;
  transition: 0.7s;
}

.cus-update-btn:hover {
  color: #fff;
  border: 1px solid #fff;
  background: transparent;
}

.add-cus-menu-sidebar {
  position: absolute;
  bottom: 0;
}

.list-adds .media img {
  width: 100%;
  max-width: 100px;
  padding: 10px;
}

.list-adds .media {
  border-bottom: 1px solid #eee;
  padding-bottom: 20px;
}

.list-adds .media-body {
  display: flex;
  align-items: center;
}

.list-adds .media-body a {
  width: 100%;
  max-width: 110px;
  padding: 7px;
}

.bg-light-cus {
  background-color: #fff !important;
  border-radius: 5px;
  padding: 3px 14px;
  border: 1px solid #fff !important;
  transition: 0.25s;
  font-size: 14px;
}

.bg-light-cus:hover {
  border: 1px solid #fff !important;
  background: #0b2a97 !important;
  color: #fff !important;
}

.cus-img-vehicle {
  width: 100%;
  max-width: 100%;
  min-height: 150px;
  object-fit: cover;
}

.inner_img_slider img {
  width: 100%;
  max-width: 150px !important;
}

.customer-dash-user {
  position: absolute !important;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 0 !important;
}

.react-datepicker-wrapper {
  width: 100%;
}

.tabsWrap .nav-tabs {
  margin-bottom: 15px;
}

.tabsWrap .nav-tabs .nav-item .nav-link {
  margin-bottom: 5px;
  text-align: center;
}

@media (min-width: 1200px) and (max-width: 1499.98px) {
  .tabsWrap .nav-tabs .nav-item .nav-link {
    font-size: 15px;
    padding: 8px 10px;
  }
  .paddingg {
    padding: 20px;
  }
}

@media (max-width: 1199.98px) {
  .tabsWrap .nav-tabs .nav-item {
    width: 49%;
  }
}

@media (max-width: 767.98px) {
  .tabsWrap .nav-tabs .nav-item {
    width: 100%;
    margin-right: 0;
  }
}
@media (min-width: 767.98px) {
  .h1_tag_cum {
    font-size: 32px;
  }
  .h1_tag {
    font-size: 25px;
  }
}
@media (max-width: 767.98px) {
  .h1_tag {
    font-size: 20px;
  }
  .h1_tag_cum {
    font-size: 25px;
  }
}

.tox-notifications-container {
  display: none !important;
}
.bb {
  /* border: 2px solid red; */
  /* width: 100vw; */
  display: flex;
  align-content: space-around;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
/* .active {
  color: white !important;
} */
.Toastify__toast-container {
  z-index: 999999999999 !important;
}
.swal2-container {
  z-index: 99999999 !important;
}
@media screen and (max-width: 540px) {
  .serch {
    margin-top: 10vh;
    z-index: 9;
  }
  .btn_cus {
    padding: 10px 30px;
    margin: 5px 0px;
  }
  .cus_h1_text {
    font-size: 15px !important;
    text-align: center;
  }

  .cus_d_none_sm {
    display: none;
  }
  .offset_1_sm {
    margin-left: 8.3333333333%;
  }
}

.back_blue_color_white {
  background-color: #6aacff;
  color: white;
}
.padding_10 {
  padding: 10%;
}
.img_cus_padding_border {
  padding: 20px;
  border: 1px solid #6aacff;
}
.cus_btn_center {
  display: flex;
  align-content: center;
  justify-content: center;
}
@media screen and (min-width: 768px) {
  .serch {
    margin-top: 30vh;
    z-index: 9;
  }
}
.slider-section {
  position: relative !important;
}

.padding_serch_content {
  padding-top: 20vh;
}
.innerimg {
  height: 100%;
  position: relative;
  background: url('http://192.168.18.10:3001/upload/1632344951449-5.png');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  margin: 0 5vw;
}
.img {
  width: 100%;
  height: 300px;
  min-height: 200px;
  position: relative;
}
.hr {
  border-top: 2px !important;
}

@media screen and (min-width: 480px) {
  .coln-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .navbar-brand img {
    width: 100%;
    max-width: 180px;
  }
  .marginSpace {
    margin: 120px 0px;
  }
}
@media screen and (max-width: 480px) {
  .navbar-brand img {
    width: 100%;
    max-width: 180px;
  }
  .hide_in_sm {
    display: none;
  }
}
/* .RFS-StepContainer{
  overflow-x: auto ;
} */
.overflow {
  /* display: none !important; */
  overflow-x: auto;
}
.cus-text {
  font-weight: 500;
  font-size: initial;
}
.center_text {
  display: flex;
  text-align: center;
  justify-content: center;
}
.center_text_bg {
  width: 80%;
  margin-left: 10%;
  padding: 50px !important;
  color: white;
  border-radius: 10px !important;
  background-color: #0eccfc;
}
.eye {
  position: absolute;
  left: 90%;
  bottom: 20px;
}
@media screen and (max-width: 480px) {
  .btn-light {
    background-color: #f8f9fa3d !important;
  }
 
  .img_main {
    width: 50vw;
  }
  .img_main img {
    width: 100%;
    height: auto;
  }
  .text_insm {
    font-size: 18px !important;
  }
  .marginSpace {
    margin: 55px 0px !important;
  }
  h1_tag {
    font-size: 25px !important;
  }
  h1_tag_cum {
    font-size: 33px !important;
  }
}
.navSticky {
  position: fixed;
  z-index: 99999999999999999999;
  width: 100%;
  background-color: white;
}
.loaderimgmain {
  position: fixed;
  width: 100%;
  height: 100vh;
  background: #ffffff6e;
  padding-top: 54vh;
  z-index: 9999999;
  display: flex;
  align-content: center;
  justify-content: center;
}
.loaderimg {
  width: 50px;
  height: 50px;
}
.searchWrapper {
  padding: 8px 12px !important;
  box-shadow: 0px 0 8px rgb(64 24 157 / 46%) !important;
}
.mt70 {
  margin-top: 70px;
}
.shad {
  box-shadow: 0px 0 8px rgb(64 24 157 / 46%);
}
.responsiveCardinner {
  min-width: 636px;
}
.responsiveCardouter {
  overflow-x: auto;
}
@media screen and (min-width: 768px) {
  .imga-respons {
    height: 250px;
    width: 350px;
  }
}